<script setup lang="ts">
  import { onBeforeMount } from 'vue';
  import { useUser } from '@/entities/user';
  import { SettingsWrapper } from '@/features/user-settings';

  const { currentUser, getSelf } = useUser();

  onBeforeMount(async () => {
    await getSelf();
  });
</script>

<template>
  <template v-if="currentUser">
    <settings-wrapper :username="currentUser.username">
      <router-view :username="currentUser.username" :user="currentUser" />
    </settings-wrapper>
  </template>
</template>
