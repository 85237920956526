import type { ContentView } from 'dfx/edge/edge.did';
import { htmlToText, stripHTMLPrefix } from '@/shared/lib';

export const generatePostTitle = (post: ContentView) => {
  if (post.title) {
    return post.title;
  }
  const htmlBody = stripHTMLPrefix(post.body);

  const textBody = htmlToText(htmlBody);

  const MAX_TITLE_LENGTH = 60;
  return textBody.substring(0, MAX_TITLE_LENGTH);
};
