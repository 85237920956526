<script setup lang="ts">
  import type { SolanaMarketplaceType } from '@/features/marketplace/types';
  import { withDefaults } from 'vue';

  withDefaults(
    defineProps<{
      type: SolanaMarketplaceType;
    }>(),
    {
      type: 'magic-eden',
    },
  );

  // We don't need internationalization since these names are recognizable in english
  const marketplaceText = {
    'magic-eden': 'Magic Eden',
    tensor: 'Tensor',
  };
</script>

<template>
  <div
    class="flex flex-col bg-[#10141c] border border-gray-820 rounded-xl px-8 md:px-10 lg:px-20 py-8 nft-embed"
  >
    <div class="flex justify-between items-center">
      <div class="flex gap-x-2 items-center">
        <base-icon :name="type" size="w-10 h-10" />
        <span class="font-medium hidden sm:flex">{{
          marketplaceText[type]
        }}</span>
      </div>
      <div class="flex items-center gap-x-4">
        <div class="rounded-full w-20 h-10 loading-content" />
        <div class="rounded-full w-20 h-10 loading-content" />
      </div>
    </div>
    <div class="loading-content w-full aspect-square rounded-2xl my-6" />
    <div class="flex justify-between">
      <div class="flex flex-col gap-y-2">
        <div class="h-8 w-48 loading-content rounded-xl" />
        <div class="h-6 w-24 loading-content rounded-xl" />
      </div>
      <div
        class="w-28 h-10 loading-content rounded-xl flex items-center justify-center"
      ></div>
    </div>
  </div>
</template>
