import type { MaybeRef, Ref } from 'vue';
import type { Principal } from '@dfinity/principal';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { convertResponseSolanaNFT } from '../lib/convert-response';
import { getSolanaNFTsQueryKey } from './keys';

export const useGetSolanaNFTsQuery = (
  principal: Ref<Principal | undefined>,
  addresses: Ref<string[]>,
  enabled: MaybeRef<boolean> = true,
) => {
  return useQuery({
    queryKey: getSolanaNFTsQueryKey(principal, addresses),
    queryFn: async () => {
      if (!principal.value || !addresses.value.length) return [];
      return (
        await Promise.all(
          addresses.value.map((address) =>
            dscvrApi.wallet.getUserNFTs(principal.value!, 'solana', address),
          ),
        )
      ).flat();
    },
    select: convertResponseSolanaNFT,
    initialData: [],
    enabled,
  });
};
