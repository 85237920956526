import { ref, type Ref } from 'vue';
import { DateTime } from 'luxon';

export const useTimeElapsedCounter = (
  timeElapsed: Ref<string> = ref('00:00:00'),
) => {
  const timer = ref<NodeJS.Timer | null>(null);
  /**
   *
   * @param startedAtTime
   */
  function setTimeElapsedCounter(startedAtTime: string) {
    const event = setInterval(() => {
      const nowDateTime = DateTime.now();
      const startedAtDateTime = DateTime.fromISO(startedAtTime);
      timeElapsed.value = nowDateTime
        .diff(startedAtDateTime, ['hours', 'minutes', 'seconds'])
        .toFormat('hh:mm:ss');
    }, 1000);
    timer.value = event;
  }

  /**
   *
   * @param endsAtTime
   */
  function setTimeElapsedCounterEnds(endsAtTime: string) {
    const event = setInterval(() => {
      const nowDateTime = DateTime.now();
      const endsAtDateTime = DateTime.fromISO(endsAtTime);
      timeElapsed.value = endsAtDateTime
        .diff(nowDateTime, ['hours', 'minutes', 'seconds'])
        .toFormat('hh:mm:ss');
    }, 1000);
    timer.value = event;
  }

  /**
   *
   */
  function cleanupTimeElapsedCounter() {
    if (timer.value) {
      clearInterval(timer.value);
    }
  }

  return {
    timeElapsed,
    setTimeElapsedCounter,
    setTimeElapsedCounterEnds,
    cleanupTimeElapsedCounter,
  };
};
