import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import type { CommentViewNode } from './types';
import type { ContentTreeView, ContentView } from 'dfx/edge/edge.did';

export const useCommentStore = defineStore('comment', () => {
  const commentHash = ref<Map<bigint, CommentViewNode>>(new Map());
  const commentTree: Ref<CommentViewNode[]> = ref([]);
  const flattenComments: Ref<CommentViewNode[]> = ref([]);
  const isUpdating = ref(false);

  const getCommentById = (id: bigint | undefined) => {
    if (id) {
      return commentHash.value.get(id);
    }
    return undefined;
  };

  const convertContentView = (
    source: ContentView,
    parent?: CommentViewNode,
  ) => {
    const newNode: CommentViewNode = {
      ...source,
      idAsString: source.id.toString(),
      children: [],
      updated_at: BigInt(0),
      parent,
      depth: parent ? parent.depth + 1 : 0,
    };
    commentHash.value.set(source.id, newNode);
    return newNode;
  };

  const convertContentTreeView = (
    source: ContentTreeView,
    parent?: CommentViewNode,
  ) => {
    const newNode: CommentViewNode = {
      ...source,
      idAsString: source.id.toString(),
      children: [],
      parent: parent,
      depth: parent ? parent.depth + 1 : 0,
    };
    commentHash.value.set(source.id, newNode);

    newNode.children = source.children.map((child) =>
      convertContentTreeView(child, newNode),
    );
    return newNode;
  };

  const detachTree = (node: CommentViewNode) => {
    node.children.forEach(detachTree);
    commentHash.value.delete(node.id);
  };

  const hydrateNode = (
    node: CommentViewNode,
    source: ContentTreeView | ContentView,
  ) => {
    if (node.body !== source.body) {
      node.body = source.body;
    }
    node.upvotes = source.upvotes;
    node.downvotes = source.downvotes;
    node.is_upvoter = source.is_upvoter;
    node.is_downvoter = source.is_downvoter;
  };

  const detachNonExistingChildren = (
    node: CommentViewNode,
    source: ContentTreeView,
  ) => {
    const nodesToRemove = node.children.filter(
      (child) => !source.children.some((c) => c.id === child.id),
    );
    nodesToRemove.forEach(detachTree);
  };

  const upsertNode = (source: ContentTreeView) => {
    const existingNode = getCommentById(source.id);
    if (!existingNode) {
      const parent = getCommentById(source.parent_id[0]);
      const newNode = convertContentTreeView(source, parent);
      return { isNew: true, node: newNode };
    }

    hydrateNode(existingNode, source);
    detachNonExistingChildren(existingNode, source);
    existingNode.children = source.children.map((c) => {
      const { node } = upsertNode(c);
      return node;
    });

    return { isNew: true, node: existingNode };
  };

  // we don't delete comments, only append, to prevent the scroll going up
  const appendRecentTree = (source: ContentTreeView) => {
    const existingNode = getCommentById(source.id);
    if (!existingNode) {
      const parent = getCommentById(source.parent_id[0]);
      const newNode = convertContentTreeView(source, parent);
      // TODO: for now we insert at the bottom, but we should insert in the right place when the new comments behavior is implemented
      if (parent) {
        parent.children.push(newNode);
      } else {
        commentTree.value.push(newNode);
      }
      return newNode;
    }

    hydrateNode(existingNode, source);
    source.children.forEach(appendRecentTree);

    return existingNode;
  };

  const flattenNode = <T extends { children: T[] }>(node: T): T[] => {
    const flattenChildren = node.children.map(flattenNode).flat();
    return [node, ...flattenChildren];
  };

  const flattenTree = () => {
    flattenComments.value = commentTree.value.map(flattenNode).flat();
  };

  const appendNewComment = (source: ContentView) => {
    const parent = getCommentById(source.parent_id[0]);
    const newNode = convertContentView(source, parent);
    if (parent) {
      // TODO: for now we insert at the top, but we should insert in the right place when the new comments behavior is implemented
      parent.children.unshift(newNode);
    } else {
      // TODO: for now we insert at the top, but we should insert in the right place when the new comments behavior is implemented
      commentTree.value.unshift(newNode);
    }
    flattenTree();
    return newNode;
  };

  const appendPage = (sources: ContentTreeView[]) => {
    const page = sources
      .map((source) => upsertNode(source))
      .filter(({ isNew }) => isNew)
      .map(({ node }) => node);
    commentTree.value = commentTree.value.concat(page);
    flattenTree();
  };

  const clearFeed = () => {
    commentHash.value = new Map();
    commentTree.value = [];
    flattenComments.value = [];
  };

  const setFeed = (sources: ContentTreeView[]) => {
    clearFeed();

    commentTree.value = sources.map((source) => convertContentTreeView(source));
    flattenTree();
  };

  // sources contains new or updated root element with their full tree
  const appendRecentComments = (sources: ContentTreeView[]) => {
    sources.forEach((s) => appendRecentTree(s));
    flattenTree();
  };

  const removeComment = (id: bigint) => {
    const existingNode = getCommentById(id);
    if (existingNode) {
      detachTree(existingNode);
      if (existingNode.parent) {
        existingNode.parent.children = existingNode.parent.children.filter(
          (n) => n.id !== existingNode.id,
        );
      } else {
        commentTree.value = commentTree.value.filter(
          (n) => n.id !== existingNode.id,
        );
      }
      flattenTree();
    }
  };

  const updateComment = (source: ContentView) => {
    const existingNode = getCommentById(source.id);
    if (existingNode) {
      hydrateNode(existingNode, source);
    }
  };

  const setNSFWComment = (id: bigint, isNSFW: boolean) => {
    const existingNode = getCommentById(id);
    if (existingNode) {
      existingNode.is_nsfw = isNSFW;
    }
  };

  const toggleTreeHide = (node: CommentViewNode, hide = true) => {
    node.hidden = hide;
    if (!hide && node.hideChildren) {
      return;
    }
    node.children.forEach((n) => toggleTreeHide(n, hide));
  };

  const toggleChildren = (id: bigint) => {
    const existingNode = getCommentById(id);
    if (existingNode) {
      existingNode.hideChildren = !existingNode.hideChildren;
      existingNode.children.forEach((n) =>
        toggleTreeHide(n, existingNode.hideChildren),
      );
    }
  };

  return {
    isUpdating,
    commentTree,
    flattenComments,
    appendNewComment,
    updateComment,
    setNSFWComment,
    removeComment,
    appendPage,
    appendRecentComments,
    clearFeed,
    setFeed,
    getCommentById,
    toggleChildren,
  };
});
