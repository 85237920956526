import { computed, type Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import { useQuery } from '@tanstack/vue-query';
import { getUserByIdKey } from './keys';
import type { UserView } from 'dfx/edge/edge.did';

export const useGetUserRankQuery = (user: Ref<UserView>) => {
  const userId = computed(() => user.value.id.toText());
  return useQuery({
    queryKey: [...getUserByIdKey(userId), 'rank'],
    queryFn: async () => {
      const data = await dscvrIcApi.user.getUserRank(user.value.id);
      return data[0][0];
    },
  });
};
