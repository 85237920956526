<script setup lang="ts">
  import { onMounted, onUnmounted, ref } from 'vue';
  import { enrichVideoUrl } from '@/utils';
  import { fetchMedia } from '@/shared/lib';
  import { uniqueId } from 'lodash-es';
  import { useRegisterPlayback } from '../../../model/composables/use-register-playback';
  import { useEventListener } from '@vueuse/core';

  defineProps<{
    src: string;
  }>();

  const emit = defineEmits<{
    (e: 'loaded'): void;
  }>();

  const isVertical = ref(false);
  const video = ref<HTMLVideoElement | null>(null);
  const id = uniqueId();
  const { pauseEveryOtherVideo, setMediaPlayback, unsetMediaPlayback } =
    useRegisterPlayback();
  useEventListener(video, 'playing', () => pauseEveryOtherVideo(id));
  /**
   *
   * @param event
   */
  function onLoadedMetadata(event: Event) {
    emit('loaded');
    const video = event.target as HTMLVideoElement;
    isVertical.value = (video.videoHeight || 0) > (video.videoWidth || 0);
  }

  onMounted(() => {
    if (video.value) {
      setMediaPlayback(id, {
        element: video.value,
        pause: () => {
          if (video.value) {
            video.value.pause();
          }
        },
      });
    }
  });

  onUnmounted(() => {
    unsetMediaPlayback(id);
  });
</script>

<template>
  <video
    ref="video"
    :src="enrichVideoUrl(fetchMedia(src))"
    :class="{ vertical: isVertical }"
    :muted="false"
    playsinline
    controls
    webkit-playsInline
    controlsList="nodownload nofullscreen noremoteplayback"
    class="max-h-160"
    @loadedmetadata="onLoadedMetadata"
    @error="emit('loaded')"
    @click.prevent
  />
</template>

<style scoped>
  .vertical {
    height: 100%;
  }
</style>
