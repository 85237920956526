import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ActionTypes } from '@/store';
import { useToast } from '@/shared/model';
import type { PortalView, RoleView } from 'dfx/edge/edge.did';
import { PermissionFlags, hasPermission } from '@/shared/lib';
import { NFT_SAURON_ROLE } from '@/common';
import { dscvrApi, solApi } from '@/shared/api';
import { findMarketplacePage } from '@/entities/token';

export function useNFTGating() {
  const store = useStore();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });
  const NFT_TYPE_EXT = 'ext';

  const nftSauronPortalRoles = computed<RoleView[]>(
    () => store.getters['gating/nftSauronPortalRoles'],
  );

  const nftGatingPortalSlug = computed<string>(
    () => store.getters['gating/portalSlug'],
  );

  const entrepotCollections = computed<
    dscvrApi.multichainGating.EntrepotCollectionItem[]
  >(() => {
    return store.getters['nfts/entrepotCollections'];
  });

  const icpNftCollections = computed<dscvrApi.multichainGating.ICPGateDto[]>(
    () => {
      return store.getters['gating/icpNftCollections'];
    },
  );

  const multichainNftCollections = computed<
    dscvrApi.multichainGating.MultichainGateDto[]
  >(() => {
    return store.getters['gating/multichainNftCollections'];
  });

  const allNftCollections = computed<dscvrApi.multichainGating.NftGateDto[]>(
    () => {
      return [...multichainNftCollections.value, ...icpNftCollections.value];
    },
  );

  const marketplaceMultichainCollections = computed(() =>
    multichainNftCollections.value.filter(
      (collection) => collection.showMarketplaceListings,
    ),
  );

  const marketplaceICPCollections = computed(() =>
    icpNftCollections.value.filter(
      (collection) =>
        collection.type === NFT_TYPE_EXT &&
        entrepotCollections.value.some(
          (entrepotCollection) =>
            entrepotCollection.id === collection?.canisterId,
        ),
    ),
  );

  const marketplaceNftCollections = computed<
    dscvrApi.multichainGating.NftGateDto[]
  >(() => {
    return [
      ...marketplaceMultichainCollections.value,
      ...marketplaceICPCollections.value,
    ];
  });

  const nftSauronCustomRole = computed<RoleView | undefined>(() =>
    nftSauronPortalRoles.value.find((role) => role.name === NFT_SAURON_ROLE),
  );

  const isNftSauronSetup = computed(() => {
    if (nftSauronPortalRoles.value.length) {
      return nftSauronPortalRoles.value.some((role) =>
        hasPermission(role.permissions, PermissionFlags.MANAGE_MEMBERS),
      );
    }
    return false;
  });

  const isGatedNftCollection = (
    portalView: PortalView,
    collectionGate: dscvrApi.multichainGating.MultichainGateDto,
  ) => {
    return (
      !hasPermission(portalView.perm, PermissionFlags.VIEW_CONTENT) &&
      collectionGate.roleId &&
      collectionGate.nftCollectionAddress
    );
  };

  const loadSauronPortalRoles = (portalView: PortalView) => {
    return store.dispatch(`gating/${ActionTypes.GET_NFT_SAURON_PORTAL_ROLES}`, {
      id: portalView.id,
    });
  };

  const setupNftWatcher = async (
    portalView: PortalView,
    portalRoles: RoleView[],
  ): Promise<boolean> => {
    const response = await store.dispatch(
      `gating/${ActionTypes.GATING_SETUP_WATCHER}`,
      { portalView, portalRoles },
    );

    if (response) {
      showToast({
        title: t('setupNftWatcherSuccess'),
        type: 'success',
        durationSeconds: 5,
      });
    }
    return response;
  };

  const fetchNftGatedCollections = async (slug: string, reset = true) => {
    await dscvrApi.verify.verifyUser();
    return await store.dispatch(
      `gating/${ActionTypes.GET_GATING_NFT_COLLECTIONS}`,
      {
        slug,
        reset,
      },
    );
  };

  const fetchEntrepotCollections = (hideLoading = false) => {
    return store.dispatch(`nfts/${ActionTypes.LIST_ENTREPOT_NFT_COLLECTIONS}`, {
      hideLoading,
    });
  };

  const validateMintAddress = (address: string): boolean => {
    return /[1-9A-HJ-NP-Za-km-z]{32,44}/.test(address);
  };

  const getNftCollectionDetailsByAddress = async (
    mintAddress: string,
  ): Promise<dscvrApi.multichainGating.MultichainCollectionDetailDto[]> => {
    const isValidFormat = validateMintAddress(mintAddress);
    if (!isValidFormat) {
      return [];
    }
    const response =
      await dscvrApi.multichainGating.getNftCollectionDetailsByAddress(
        mintAddress,
      );

    if (response?.status === 200 && response?.data?.data?.length) {
      return response.data.data;
    }
    return [];
  };

  const getNftCollectionListings = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
    page = 1,
    pageSize = 1,
    sort: solApi.collection.ListingSortType = 'listPrice',
    sortDirection: 'asc' | 'desc' = 'asc',
  ): Promise<solApi.collection.MagicEdenAuctionData[]> => {
    const magicEdenMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'magiceden',
    );
    if (!magicEdenMarketplacePage) {
      return [];
    }
    const collectionId = magicEdenMarketplacePage.marketplace_collection_id;
    return await solApi.collection.getListings(
      collectionId,
      page,
      pageSize,
      sort,
      sortDirection,
    );
  };

  const getNftCollectionStats = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
  ): Promise<solApi.collection.MagicEdenCollectionsStats | undefined> => {
    const magicEdenMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'magiceden',
    );
    if (!magicEdenMarketplacePage) {
      return undefined;
    }
    const collectionId = magicEdenMarketplacePage.marketplace_collection_id;
    return await solApi.collection.getStats(collectionId);
  };

  const getMultichainCollectionDetails = async (
    collection: dscvrApi.multichainGating.MultichainGateDto,
  ) => {
    const details = await getNftCollectionDetailsByAddress(
      collection.nftCollectionAddress,
    );
    if (!details.length) {
      return null;
    }
    const detail = details[0];
    const stats = await getNftCollectionStats(detail);

    if (!stats) {
      console.log('Error getting stats for collection', detail.name);
      return null;
    }

    let openExternalUrl: string | undefined = undefined;
    const content = await getNftCollectionListings(detail);

    if (!content.length) {
      const magicEdenMarketplacePage = findMarketplacePage(
        detail.marketplace_pages,
        'magiceden',
      );
      if (magicEdenMarketplacePage) {
        openExternalUrl = magicEdenMarketplacePage.collection_url;
      }
    }

    return {
      collection,
      detail,
      stats,
      openExternalUrl,
    };
  };

  return {
    allNftCollections,
    entrepotCollections,
    fetchEntrepotCollections,
    fetchNftGatedCollections,
    getMultichainCollectionDetails,
    getNftCollectionDetailsByAddress,
    getNftCollectionListings,
    getNftCollectionStats,
    icpNftCollections,
    isGatedNftCollection,
    isNftSauronSetup,
    loadSauronPortalRoles,
    marketplaceICPCollections,
    marketplaceMultichainCollections,
    marketplaceNftCollections,
    multichainNftCollections,
    NFT_TYPE_EXT,
    nftGatingPortalSlug,
    nftSauronCustomRole,
    nftSauronPortalRoles,
    setupNftWatcher,
    validateMintAddress,
  };
}
