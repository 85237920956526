import { computed, type Ref } from 'vue';
import { compact } from 'lodash-es';
import { dscvrApi } from '@/shared/api';

type StateChangeDescription = {
  prefix?: string;
  description: string;
  prefixColor?: string;
};

export const useTransactionScan = (
  scanResponse: Ref<dscvrApi.blowfish.ScanTransactionsSolana200Response>,
) => {
  const convertChange = (
    change: dscvrApi.blowfish.ExpectedStateChange,
  ): StateChangeDescription | undefined => {
    if (!change.humanReadableDiff) return undefined;
    if (!change.suggestedColor) {
      return {
        description: change.humanReadableDiff,
      };
    }

    const segments = change.humanReadableDiff.split(/\s(.*)/s);
    const prefixColor =
      change.suggestedColor === 'CREDIT'
        ? 'text-green-300'
        : change.suggestedColor === 'DEBIT'
        ? 'text-red-350'
        : undefined;
    return {
      prefix: segments[0],
      description: segments[1],
      prefixColor,
    };
  };

  const stateChangeDescriptions = computed(() => {
    const stateChanges = scanResponse.value.aggregated.expectedStateChanges;
    return Object.entries(stateChanges).reduce(
      (acum, [address, changes]) => {
        const changeList = compact(changes.map(convertChange));
        return changeList.length
          ? [...acum, { address, changes: changeList }]
          : acum;
      },
      [] as {
        address: string;
        changes: StateChangeDescription[];
      }[],
    );
  });

  const warnings = computed(() => {
    const aggregatedWarnings = scanResponse.value.aggregated.warnings.map(
      (warning) =>
        dscvrApi.blowfish.WARNING_OVERRIDES[warning.kind] || warning.message,
    );
    if (aggregatedWarnings.length > 0) {
      return aggregatedWarnings;
    }
    const perTransactionErrors = compact(
      scanResponse.value.perTransaction.map(
        (transaction) => transaction.error?.humanReadableError,
      ),
    );
    return perTransactionErrors;
  });

  const warningsType = computed(() => {
    if (warnings.value.length) {
      return 'NONE';
    }
    return scanResponse.value.aggregated.action;
  });

  return {
    stateChangeDescriptions,
    warnings,
    warningsType,
  };
};
