import { edgeActor } from '../base';
import type {
  PersonalizedFeedV1Query,
  ContentViewPage,
  PagedQuery,
  ActionResultGetContentPaged,
  ActionResultContent,
  CreateContent,
  Tag,
  PollView,
} from 'dfx/edge/edge.did';
import type {
  ReactionParams,
  PinContentParams,
  NsfwContentParams,
  UpdateContentParams,
  ContentPollVoteParams,
  ContentPollQuadraticVoteParams,
  SetContentRankParams,
} from './types';
export type {
  ReactionParams,
  PinContentParams,
  NsfwContentParams,
  UpdateContentParams,
  ContentPollVoteParams,
  ContentPollQuadraticVoteParams,
  SetContentRankParams,
};

export const getFollowingFeed = (query: PersonalizedFeedV1Query) => {
  return edgeActor()
    .personalized_feed_v1(query)
    .then((res) => res) as Promise<{ Ok: ContentViewPage } | { Err: string }>;
};

export const getForYouFeed = (query: PersonalizedFeedV1Query) => {
  return edgeActor()
    .personalized_feed_v1(query)
    .then((res) => res) as Promise<{ Ok: ContentViewPage } | { Err: string }>;
};

export const listContent = (query: PagedQuery) => {
  return edgeActor()
    .list_content(query)
    .then((res) => res) as Promise<ContentViewPage>;
};

export const getUserContent = (username: string, query: PagedQuery) => {
  return edgeActor()
    .get_user_content(username, query)
    .then((res) => res) as Promise<ActionResultGetContentPaged>;
};

export const getContentById = (contentId: bigint) => {
  return edgeActor()
    .get_content(contentId)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const reactToContent = (params: ReactionParams) => {
  return edgeActor()
    .react_to_content(params.contentId, params.contentReaction)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const pinUserContent = (params: PinContentParams) => {
  return edgeActor().pin_user_content(params.contentId, params.isPinned);
};

export const contentPinSet = (params: PinContentParams) => {
  return edgeActor().content_pin_set(params.contentId, params.isPinned);
};

export const contentNsfwSet = (params: NsfwContentParams) => {
  return edgeActor().content_nsfw_set(params.contentId, params.isNsfw);
};

export const createContent = (content: CreateContent) => {
  return edgeActor()
    .create_content(content)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const deleteContent = (contentId: bigint) => {
  return edgeActor()
    .delete_content(contentId)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const removeContent = (contentId: bigint) => {
  return edgeActor()
    .remove_content(contentId)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const searchTags = (tag: string) => {
  return edgeActor()
    .search_tags(tag)
    .then((res) => res) as Promise<Tag[]>;
};

export const updateContent = (params: UpdateContentParams) => {
  return edgeActor().update_content(
    params.id,
    params.body,
    params.tags,
    params.disableComments,
    params.isNsfw,
    params.updatePoll,
  );
};

export const contentPollVote = (params: ContentPollVoteParams) => {
  return edgeActor()
    .content_poll_vote(params.pollId, params.choiceId)
    .then((res) => res) as Promise<[] | [PollView]>;
};

export const contentPollQuadraticVote = (
  params: ContentPollQuadraticVoteParams,
) => {
  return edgeActor()
    .content_poll_quadratic_vote(params.pollId, params.choices)
    .then((res) => res) as Promise<[] | [PollView]>;
};

export const getContentRanks = (contentId: bigint) => {
  return edgeActor()
    .get_content_ranks(contentId)
    .then((res) => res) as Promise<[] | [[bigint, bigint]]>;
};

export const setContentRank = (params: SetContentRankParams) => {
  return edgeActor().set_content_rank([[params.contentId, params.rank]]);
};
