<script lang="ts" setup>
  import { TERMS } from '@/common';
  import { uniqueId } from 'lodash-es';
  import { computed } from 'vue';

  const links = computed(() => [
    {
      id: uniqueId(),
      label: 'FAQ',
      to: 'https://dscvr.notion.site/',
      target: '_blank',
    },
    {
      id: uniqueId(),
      label: 'Terms',
      to: {
        name: TERMS,
      },
    },
    {
      id: uniqueId(),
      label: 'Privacy',
      to: {
        name: TERMS,
      },
    },
    {
      id: uniqueId(),
      label: 'Careers',
      to: 'https://dscvr.notion.site/DSCVR-Job-Board-d69f0e591558472eb4a994ba1a076900',
      target: '_blank',
    },
  ]);
</script>

<template>
  <div class="flex flex-col gap-2 mt-2">
    <div class="flex gap-3">
      <base-button
        v-for="link in links"
        :key="link.id"
        :to="link.to"
        :target="link.target"
        variant="link"
        class="text-gray-500 text-sm"
      >
        {{ link.label }}
      </base-button>
    </div>
    <div class="text-gray-500 text-sm">©2023 DSCVR</div>
  </div>
</template>
