import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useReactToContentMutation = () => {
  return useMutation({
    mutationKey: ['react-to-content-mutation'],
    mutationFn: async (params: dscvrIcApi.post.ReactionParams) => {
      const data = await dscvrIcApi.post.reactToContent(params);
      if (data.result.length) {
        return data.result[0].reaction_counts;
      }
      throw new Error(data.message);
    },
  });
};
