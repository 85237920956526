import axios from 'axios';
import TokenService from './token';
import { config } from '@/shared/lib';

export const apiInstance = axios.create({
  baseURL: `${config.DSCVR_API_BASE}/api`,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (config.headers && token)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
