import { useToast } from '../../model/composables/use-toast';
import { useI18n } from 'vue-i18n';
import { useClipboardStore } from '../store/clipboard/store';

export const useClipboardToast = () => {
  const clipboardStore = useClipboardStore();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });
  const defaultToastMessage = t('copiedToClipboard');

  const copyToClipboard = async (
    text: string,
    toastMessage: string = defaultToastMessage,
  ) => {
    await clipboardStore.clipboard.copy(text);

    showToast({
      type: 'success',
      durationSeconds: 5,
      title: toastMessage,
    });
  };

  return {
    copyToClipboard,
  };
};
