<script lang="ts" setup>
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useRoute } from 'vue-router';
  import { PORTAL_CONTENT } from '@/common';
  import { getPortalIcon } from '@/entities/portal';
  import { EmptyFavoritePortals } from '@/widgets/left-sidebar/components';

  defineProps<{
    favorites?: PortalView[];
  }>();

  defineEmits<{
    (e: 'closeMenu'): void;
  }>();

  const route = useRoute();

  const isSelectedPortal = (portal: Pick<PortalView, 'slug'>) =>
    portal.slug === route.params.slug;
</script>

<template>
  <template v-if="favorites?.length">
    <base-button
      v-for="favorite in favorites"
      :key="Number(favorite.id)"
      variant="link"
      :to="{
        name: PORTAL_CONTENT,
        params: { slug: favorite.slug },
      }"
      custom-classes="flex items-center p-2 hover:text-white rounded-l-xl"
      :class="[
        isSelectedPortal(favorite)
          ? 'font-semibold bg-gradient-to-r from-indigo-850 via-transparent to-transparent hover:bg-transparent'
          : 'font-medium hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785',
      ]"
      @click="$emit('closeMenu')"
    >
      <img
        :src="getPortalIcon(favorite)"
        class="rounded-lg shadow-lg size-8"
        :alt="`${favorite.name}'s icon`"
      />
      <span
        class="mx-2 text-sm font-medium leading-5 text-gray-300 truncate"
        :title="favorite.name"
      >
        {{ favorite.name }}
      </span>
      <base-icon v-if="favorite.is_nsfw" name="eighteen-plus" size="size-4" />
    </base-button>
  </template>
  <div v-else class="px-2 mt-2 text-gray-400">
    <empty-favorite-portals />
  </div>
</template>
