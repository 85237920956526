import { computed } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import type { UserPlatformPair } from 'dfx/edge/edge.did';

export function useUserIntegrations() {
  const store = useStore();

  const currentUserPairedPlatforms = computed<UserPlatformPair[]>(() => {
    return store.state.auth.pairedPlatforms;
  });

  const getCurrentUserPairedPlatforms = () => {
    return store.dispatch(`auth/${ActionTypes.GET_PAIRED_PLATFORMS}`);
  };

  const getCurrentUserPlatformUsername = (
    platformName: string,
  ): string | undefined => {
    const platforms = currentUserPairedPlatforms.value;
    for (let i = 0; i < platforms.length; i++) {
      const p = platforms[i];

      if (
        Object.prototype.hasOwnProperty.call(p.kind, platformName) &&
        p.is_paired
      ) {
        return p.platform_username;
      }
    }

    return undefined;
  };

  const unpair = (platformName: string) => {
    return store
      .dispatch(`auth/${ActionTypes.UNPAIR_PLATFORM}`, {
        [platformName]: null,
      })
      .then(() => {
        return getCurrentUserPairedPlatforms();
      });
  };

  return {
    currentUserPairedPlatforms,
    getCurrentUserPairedPlatforms,
    getCurrentUserPlatformUsername,
    unpair,
  };
}
