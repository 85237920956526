<script setup lang="ts">
  import { ref } from 'vue';
  import LootboxItemBase from './LootboxItemBase.vue';
  import LootboxItemCampaignPage from './LootboxItemCampaignPage.vue';
  import { useDialog } from '@/shared/model';
  import { dscvrApi } from '@/shared/api';
  import {
    type CampaignMetaPage,
    type LootboxDialogContentItem,
    LootboxDialogContentItemType,
  } from '@/entities/lootbox';

  const props = defineProps<{
    lootboxId: string;
    lootboxPages: LootboxDialogContentItem[];
  }>();

  const currentIndex = ref(0);

  const { closeDialog } = useDialog();

  const computeModalTitle = () => {
    if (props.lootboxPages.length > 1) {
      return `(${currentIndex.value + 1}/${props.lootboxPages.length})`;
    }
    return '';
  };

  const next = () => {
    if (currentIndex.value < props.lootboxPages.length - 1) {
      currentIndex.value += 1;
    } else {
      closeDialog();
    }
  };
</script>

<template>
  <section class="text-md p-2">
    <div v-if="computeModalTitle">Lootbox {{ computeModalTitle() }}</div>
    <div v-for="(page, index) in lootboxPages" :key="index">
      <lootbox-item-base
        v-if="
          index === currentIndex &&
          page.type === LootboxDialogContentItemType.Lootbox
        "
        :lootbox-item="(page.item as dscvrApi.lootbox.ILootboxItem)"
        :show-campaign="true"
        @claimed="next"
      />
      <lootbox-item-campaign-page
        v-if="
          index === currentIndex &&
          page.type === LootboxDialogContentItemType.CampaignMetaPage
        "
        :campaign-meta-page="(page.item as CampaignMetaPage)"
        :is-at-end="currentIndex === lootboxPages.length - 1"
        @next="next"
      />
    </div>
  </section>
</template>
