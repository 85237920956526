<script setup lang="ts">
  import { createSocietyRsActorFromAgent, pairWallet } from '@/utils/dfinity';
  import { plug } from '@/utils/plug';
  import { trackEvent } from '@/utils/tracker';
  import { PairButton } from '@/entities/user-setting';
  import { useIcUnpairWalletMutation } from '@/entities/wallets';

  const props = defineProps<{
    isPaired: boolean;
    plugAddress: string;
  }>();

  const emit = defineEmits<{
    (event: 'unpair-start', value: boolean): void;
    (event: 'pair-start', value: boolean): void;
  }>();

  const unpair = async () => {
    emit('unpair-start', true);

    await useIcUnpairWalletMutation({ Plug: null });

    emit('unpair-start', false);
  };

  const login = async () => {
    if (!window.ic?.plug) {
      window.open('https://plugwallet.ooo/', '_blank');

      return;
    }
    trackEvent('user_settings_action', 'wallet_pair', 'plug');

    emit('pair-start', true);
    try {
      const connected = await plug.connect();

      if (connected) {
        const agent = await plug.getAgent();
        const plugDscvrActor = await createSocietyRsActorFromAgent(agent);
        await pairWallet(
          { Plug: null },
          await window.ic.plug.agent.getPrincipal(),
          plugDscvrActor,
        );
      }
    } catch (error) {
      console.error(error);
    }
    emit('pair-start', false);
  };
  const togglePair = () => {
    if (props.isPaired) {
      unpair();
    } else {
      login();
    }
  };
</script>

<template>
  <pair-button
    :connected-id="isPaired ? plugAddress : undefined"
    @toggle="togglePair"
  >
    <base-icon name="plug" size="size-10 min-h-10" />
    <template #name>Plug</template>
    <template #connection>
      {{ $t('copyPrincipal') }}: {{ plugAddress }}
    </template>
  </pair-button>
</template>
