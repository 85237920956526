import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyWalletsQueryKey } from './keys';

export const usePairWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['pair-wallet-mutation'],
    mutationFn: async (payload: dscvrApi.wallet.LinkWalletDto) =>
      dscvrApi.wallet.pairWallet(payload),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: getMyWalletsQueryKey,
      }),
  });
};
