<script setup lang="ts">
  import { watch } from 'vue';
  import { useVModel } from '@vueuse/core';
  import { useBreakpoint } from '@/shared/model';
  import type { ViewType } from '../types';

  const props = defineProps<{
    modelValue: ViewType;
  }>();

  const { isSmallerThanLg } = useBreakpoint();

  const emits = defineEmits<{
    (e: 'update:modelValue'): void;
  }>();

  const viewType = useVModel(props, 'modelValue', emits);

  watch(
    () => isSmallerThanLg.value,
    (value) => {
      if (value && viewType.value === 'table') {
        viewType.value = 'list';
      }
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <div class="flex gap-1">
    <base-button
      :variant="viewType === 'table' ? 'secondary' : 'tertiary'"
      size="x-small"
      class="hidden lg:block"
      @click="viewType = 'table'"
    >
      <base-icon name="vuesax-twotone-task" size="w-5 h-5" />
    </base-button>
    <base-button
      :variant="viewType === 'list' ? 'secondary' : 'tertiary'"
      size="x-small"
      @click="viewType = 'list'"
    >
      <base-icon name="list-outline" size="w-5 h-5" />
    </base-button>
    <base-button
      :variant="viewType === 'grid' ? 'secondary' : 'tertiary'"
      size="x-small"
      @click="viewType = 'grid'"
    >
      <base-icon name="vuesax-linear-element-3" size="w-5 h-5" />
    </base-button>
  </div>
</template>
