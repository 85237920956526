<template>
  <form class="text-gray-100 w-full mt-2" @submit.prevent="onSubmit">
    <div>
      <error-display
        :errors="errors"
        :local-errors="localErrors"
        :error-message="errorMessage"
      />

      <div class="flex gap-3 w-full">
        <label class="inline flex-grow">
          <template v-if="disabled">
            <span
              class="block w-full text-white px-3 py-2 bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl"
            >
              {{ username }}
            </span>
          </template>
          <template v-else>
            <input
              v-model="localUsername"
              type="text"
              class="block w-full text-white bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl"
              placeholder="Username"
              :disabled="disabled"
              @keyup="usernameChanged"
            />
          </template>
        </label>

        <div class="flex items-center text-white">
          <base-button
            variant="secondary"
            size="small"
            class="w-24"
            :disabled="isDisabled || disabled"
          >
            Update
          </base-button>
        </div>
      </div>
      <div class="w-full justify-between flex text-sm text-gray-400 mt-2">
        <span>{{ `dscvr.one/u/${username}` }}</span>
        <span>
          {{ $t('requiresFifty') }}
          <base-icon name="dscvr" size="size-5" class="inline mb-1" />
        </span>
      </div>
    </div>
  </form>
</template>

<script>
  import ErrorDisplay from '../ErrorDisplay.vue';

  export default {
    name: 'update-icp-username-form',
    components: {
      ErrorDisplay,
    },
    props: {
      errors: {
        type: Array,
        default: () => [],
      },
      errorMessage: {
        type: String,
        default: '',
      },
      username: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update-username'],
    data() {
      return {
        localErrors: [],
        isDisabled: true,
        localUsername: this.username,
      };
    },
    watch: {
      username: {
        handler(newUsername) {
          this.localUsername = newUsername;
        },
        // force eager callback execution
        immediate: true,
      },
    },
    methods: {
      isValid() {
        const usernameRegex = /^[A-Za-z0-9_-]+$/;

        this.localErrors = [];

        if (!usernameRegex.test(this.localUsername)) {
          this.localErrors.push({
            field: 'Username',
            errors: [
              "can be upper or lower case A-z, underscores '_' and dashes '-'.",
            ],
          });
        }

        if (this.localUsername.length < 2 || this.localUsername.length > 24) {
          this.localErrors.push({
            field: 'Username',
            errors: ['min length is 2 and max length is 24'],
          });
        }

        return this.localErrors.length == 0;
      },
      usernameChanged() {
        this.isDisabled = this.username === this.newUsername;
      },
      onSubmit() {
        if (this.isValid()) {
          this.$emit('update-username', {
            username: this.localUsername,
            auth_token: '000000000',
          });
        }
      },
    },
  };
</script>
