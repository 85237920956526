import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { feedKey } from '@/shared/model';

export const useContentPinSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['content-pin-set-mutation'],
    mutationFn: (params: dscvrIcApi.post.PinContentParams) =>
      dscvrIcApi.post.contentPinSet(params),
    onSettled: () => {
      queryClient.resetQueries({
        queryKey: [feedKey],
      });
      window.scrollTo(0, 0);
    },
  });
};
