import {
  POST_IMAGE_MAX_UPLOAD_SIZE_MB,
  POST_VIDEO_MAX_UPLOAD_SIZE_MB,
  POST_IMAGE_ACCEPTED_MIME_TYPES,
  POST_MEDIA_ACCEPTED_MIME_TYPES,
  POST_VIDEO_ACCEPTED_MIME_TYPES,
} from '@/common/constants';

/**
 *
 */
export function useFile() {
  /**
   *
   * @param imgBase64
   */
  function base64ToFile(imgBase64: string): Promise<Blob> {
    return fetch(imgBase64).then((response) => response.blob());
  }

  /**
   *
   * @param file
   */
  function fileToBase64(file: Blob): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   *
   * @param file
   */
  function getFileSizeInMB(file: Blob): number {
    return parseFloat((file.size / 1024 / 1024).toFixed(4));
  }

  /**
   *
   * @param file
   */
  function validateFile(
    file: Blob,
    acceptedTypes = POST_MEDIA_ACCEPTED_MIME_TYPES,
  ): boolean {
    const fileSize = getFileSizeInMB(file);
    if (!acceptedTypes.includes(file.type)) {
      return false;
    }

    if (POST_IMAGE_ACCEPTED_MIME_TYPES.includes(file.type)) {
      if (!(fileSize <= POST_IMAGE_MAX_UPLOAD_SIZE_MB)) {
        return false;
      }
    }

    if (POST_VIDEO_ACCEPTED_MIME_TYPES.includes(file.type)) {
      if (!(fileSize <= POST_VIDEO_MAX_UPLOAD_SIZE_MB)) {
        return false;
      }
    }

    return true;
  }

  return {
    base64ToFile,
    fileToBase64,
    validateFile,
  };
}
