<template>
  <div v-if="portalView" class="flex flex-col items-center sm:block mt-9">
    <widget-social-links
      :links="links"
      @update-links="(socialLinks) => (validLinks = socialLinks)"
    />

    <error-display
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <div class="w-full text-left mt-8">
      <h2 class="text-lg font-semibold text-white">Rules</h2>
    </div>
    <div class="flex justify-between gap-2 items-center">
      <div class="flex-grow">
        <p class="text-sm text-gray-400">
          Fill in to add rules for your portal. They will display in the right
          sidebar.
        </p>
      </div>
      <div>
        <button
          class="btn-tertiary text-sm font-medium outline-none px-4 py-2.5 rounded-md flex min-w-max"
          @click="startAddRule"
        >
          Add Rule
        </button>
      </div>
    </div>

    <p v-show="!rules" class="p-3 mt-3 text-center text-small">
      No added rules
    </p>

    <draggable
      v-if="rules && rules.length > 0"
      v-model="rules"
      item-key="id"
      class="w-full my-5 overflow-y-auto thin-scrollbar overflow-x-hidden"
      delay="400"
      :delay-on-touch-only="true"
    >
      <template #item="{ element, index }">
        <div
          :key="index"
          class="relative flex items-center py-3 text-white border-b border-gray-600"
        >
          <base-icon
            name="draggable"
            size="w-4 h-4"
            class="hidden mr-5 md:flex"
          />
          <div>
            <h3 class="break-all sm:break-word">
              {{ element.name }}
            </h3>
            <p class="break-all sm:break-word">
              {{ element.desc }}
            </p>
          </div>
          <div class="flex items-center ml-auto pl-3">
            <div
              class="btn-tertiary flex items-center px-5 py-2 text-sm bg-gray-700 rounded-lg cursor-pointer"
              @click="startEditRule(index)"
            >
              <base-icon name="pencil" size="w-4 h-4" class="mr-2" />
              Edit
            </div>
            <div
              class="btn-tertiary flex items-center px-5 py-2 h-9 ml-2 text-sm bg-gray-700 rounded-lg cursor-pointer"
              @click="openRemoveRuleDialog(index)"
            >
              <base-icon name="delete" size="w-4 h-4" />
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
  import ErrorDisplay from './ErrorDisplay.vue';
  import { ActionTypes } from '../store/action-types';
  import Draggable from 'vuedraggable';
  import WidgetSocialLinks from './WidgetSocialLinks.vue';
  import { useConfirmationDialog } from '@/shared/ui/confirmation';
  import { usePortalDialog, getPortalBySlugKey } from '@/entities/portal';
  import { useQueryClient } from '@tanstack/vue-query';

  export default {
    name: 'portal-settings-details',
    components: {
      WidgetSocialLinks,
      ErrorDisplay,
      Draggable,
    },
    props: {
      portalView: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    setup() {
      const { openConfirmationDialog, closeDialog } = useConfirmationDialog();
      const { openCreateEditRuleDialog } = usePortalDialog();
      const queryClient = useQueryClient();

      return {
        openConfirmationDialog,
        openCreateEditRuleDialog,
        closeDialog,
        queryClient,
      };
    },
    data() {
      return {
        selectedLinkId: null,
        localErrors: [],
        errorMessage: '',
        errors: [],
        links: [],
        rules: [],
      };
    },
    created() {
      if (this.portalView && this.portalView.info[0]) {
        this.links = [...this.portalView.info[0].links];
        if (this.portalView.info[0].rules.length > 0) {
          this.rules = [...this.portalView.info[0].rules[0]];
        }
      }
    },
    methods: {
      openRemoveRuleDialog(removeRuleKey) {
        this.openConfirmationDialog({
          headerTitle: this.$t('removeRule'),
          contentSlots: {
            default: { template: this.$t('removeRuleLegend') },
            submitLabel: { template: this.$t('remove') },
          },
          submit: () => this.removeRule(removeRuleKey),
        });
      },
      validateRule(newRule) {
        this.localErrors = [];
        if (newRule.name == null || newRule.name.length < 1)
          this.localErrors.push('Rule Title field is required!');

        if (newRule.name == null || newRule.desc.length < 1)
          this.localErrors.push('Rule Description field is required!');

        if (this.localErrors.length > 0) return false;
        return true;
      },
      addEditRule(rule, key = null) {
        this.localErrors = [];
        if (this.validateRule(rule)) {
          if (key != null) {
            this.rules[key] = rule;
          } else {
            this.rules.push(rule);
          }
          this.closeDialog();
        }
      },
      removeRule(key) {
        this.closeDialog();
        this.rules.splice(key, 1);
      },
      startAddRule() {
        this.openCreateEditRuleDialog(this.addEditRule);
      },
      startEditRule(key) {
        this.openCreateEditRuleDialog(
          (rule) => this.addEditRule(rule, key),
          this.rules[key],
        );
      },
      async save() {
        // save rules
        await this.$store.dispatch(
          `portals/${ActionTypes.UPDATE_PORTAL_INFO_RULES}`,
          {
            portal_id: this.portalView.id,
            rules: [this.rules],
          },
        );

        const response = await this.$store.dispatch(
          `portals/${ActionTypes.UPDATE_PORTAL_INFO_LINKS}`,
          {
            portal_id: this.portalView.id,
            links: this.validLinks,
          },
        );

        if (response.status === 'happy') {
          this.queryClient.cancelQueries({
            queryKey: [...getPortalBySlugKey(this.portalView.slug)],
          });
          this.queryClient.setQueryData(
            [...getPortalBySlugKey(this.portalView.slug)],
            response.result[0],
          );
        }
      },
    },
  };
</script>

<style scoped>
  .divider {
    width: 100%;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #374151;
  }
</style>
