import {
  getStats,
  type GetStatsParams,
} from '@/shared/api/sol-api/tensor/collection';

export const useGetTensorCollectionStatsQuery = async (
  params: GetStatsParams,
) => {
  return getStats(params);
};
