import { dscvrIcApi } from '@/shared/api';
import type { UserPortalHighlight } from 'dfx/edge/edge.did';
import { useMutation } from '@tanstack/vue-query';

export const useSetFavoritePortalsMutation = () => {
  return useMutation({
    mutationKey: ['set-favorite-portals-mutation'],
    mutationFn: (portalOrdinals: UserPortalHighlight[]) =>
      dscvrIcApi.portal.setFavoritePortals(portalOrdinals),
  });
};
