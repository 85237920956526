import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const GimluckRewardExtension = Extension.create({
  name: 'gimluckRewardExtension',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        filterTransaction: (transaction, state) => {
          let deletionAllowed = true;
          transaction.steps.forEach((step) => {
            const stepMap = step.getMap();
            stepMap.forEach((oldStart, oldEnd, _, __) => {
              // we are in a state of deletion/replacement
              if (oldEnd > oldStart) {
                const from = oldStart;
                const to = oldEnd;
                state.doc.nodesBetween(from, to, (node, _) => {
                  if (node.type.name === 'gimluckRewardNode') {
                    deletionAllowed = false;
                  }
                });
              }
            });
          });
          return deletionAllowed;
        },
      }),
    ];
  },
});
