<script setup lang="ts">
  import { computed } from 'vue';
  import { FieldWrapper } from '@/shared/ui/fields';
  import { dscvrApi } from '@/shared/api';
  import { useClipboardToast } from '@/shared/model';
  import { useNFTGatingSetup } from '../../model/composables/use-nft-gating-setup';
  import EntrepotItem from './EntrepotItem.vue';
  import type { PortalView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    item: dscvrApi.multichainGating.NftGateDto;
    portalView: PortalView;
  }>();

  const emit = defineEmits<{
    (e: 'deleted'): void;
  }>();

  const { copyToClipboard } = useClipboardToast();
  const {
    portalRoles,
    nftTypeOptions,
    entrepotCollections,
    NFT_TYPE_EXT,
    deleteNftCollection,
  } = useNFTGatingSetup(props.portalView);

  const icpItem = computed(() => ('network' in props.item ? null : props.item));
  const multichainItem = computed(() =>
    'network' in props.item ? props.item : null,
  );

  const isNFTTypeEXT = computed(() => icpItem.value?.type === NFT_TYPE_EXT);

  const nftTypeName = computed(
    () =>
      nftTypeOptions.find((item) => item.value === icpItem.value?.type)?.label,
  );

  const rolItem = computed(() => {
    if (!props.item.roleId) {
      return undefined;
    }
    return portalRoles.value?.find(
      (role) => role.id === BigInt(props.item.roleId!),
    );
  });

  const collectionItem = computed(() => {
    if (!isNFTTypeEXT.value || !icpItem.value?.canisterId) {
      return undefined;
    }
    return entrepotCollections.value.find(
      (collection) => collection.id === icpItem.value!.canisterId!,
    );
  });

  const remove = async () => {
    const response = await deleteNftCollection(props.item);
    if (response) {
      emit('deleted');
    }
  };

  const copyCollectionAddress = () => {
    if (multichainItem.value?.nftCollectionAddress) {
      copyToClipboard(multichainItem.value?.nftCollectionAddress);
    }
  };
</script>

<template>
  <div class="px-8 pb-5">
    <div class="flex flex-col gap-4">
      <div>
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('itemId') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ item.id }}</span>
        </field-wrapper>
      </div>

      <div v-if="multichainItem">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('collectionAddress') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400 flex-1 truncate">{{
            multichainItem.nftCollectionAddress
          }}</span>
          <base-button variant="custom" @click="copyCollectionAddress">
            <base-icon
              name="copy-documents"
              size="w-5 h-5"
              class="text-gray-400"
            />
          </base-button>
        </field-wrapper>
      </div>

      <div v-if="icpItem">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('nftType') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ nftTypeName }}</span>
        </field-wrapper>
      </div>

      <div v-if="multichainItem || !collectionItem">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('collectionName') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ item.name }}</span>
        </field-wrapper>
      </div>

      <div v-if="icpItem && !collectionItem">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('canisterPrincipalId') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ icpItem.canisterId }}</span>
        </field-wrapper>
      </div>

      <div v-if="icpItem && collectionItem">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('nftCollection') }}
        </label>
        <field-wrapper>
          <entrepot-item :item="collectionItem" class="text-gray-400" />
        </field-wrapper>
      </div>

      <div>
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('nftHolderRole') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ rolItem?.name }}</span>
        </field-wrapper>
      </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex justify-end">
        <base-button
          variant="primary"
          size="small"
          class="w-auto capitalize"
          @click="remove"
        >
          {{ $t('delete') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
