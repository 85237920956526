<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useVModel } from '@vueuse/core';
  import { debounce } from 'lodash';

  const DEBOUNCE_MS = 300;

  const props = defineProps<{
    modelValue: string;
  }>();

  const emit = defineEmits<{
    (e: 'update:modelValue', query: string): void;
  }>();

  const inputRef = ref<HTMLInputElement>();
  const spanRef = ref<HTMLSpanElement>();
  const queryModel = useVModel(props, 'modelValue', emit);

  const updateQuery = debounce(() => {
    queryModel.value = inputRef.value?.value || '';
  }, DEBOUNCE_MS);

  const setInputWidth = () => {
    if (inputRef.value && spanRef.value) {
      spanRef.value.textContent = inputRef.value.value;
      inputRef.value.style.width = `${spanRef.value.clientWidth}px`;
    }
  };

  const change = () => {
    setInputWidth();
    updateQuery();
  };

  onMounted(() => {
    setInputWidth();
  });
</script>

<template>
  <form
    class="relative flex items-center gap-1 bg-gray-990 hover:bg-gray-blue-900 rounded-full text-gray-400 p-2.5 border border-gray-40 border-opacity-70 transition-all text-xs group"
    @click="inputRef?.focus()"
  >
    <base-icon name="search" size="w-5 h-5 min-w-5" />
    <input
      ref="inputRef"
      class="flex-1 md:min-w-14 max-w-37 p-0 mr-8 focus:mr-8 bg-transparent border-none outline-none text-white font-normal focus:outline-none focus:border-none placeholder-transparent sm:placeholder-transparent md:placeholder-gray-400 hover:placeholder-opacity-50 focus:placeholder-transparent"
      :class="{ '!mr-0 focus:!mr-8 sm:!mr-8': !inputRef?.value.length }"
      :placeholder="$t('search')"
      :model-value="queryModel"
      @input="change"
    />
    <span ref="spanRef" class="absolute -top-10 invisible" />
  </form>
</template>
