import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useAddPortalRoleMutation = () => {
  return useMutation({
    mutationKey: ['add-portal-role-mutation'],
    mutationFn: (params: dscvrIcApi.portal.AddPortalRoleMutationParams) =>
      dscvrIcApi.portal.addPortalRole(params),
  });
};
