<script setup lang="ts">
  import { computed } from 'vue';
  import { fetchMedia, SOLANA_WALLET_NAMES } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { dscvrApi } from '@/shared/api';
  import { useConnectSolanaWallets } from '../model/composables/use-connect-solana-wallets';
  import { convertWalletTypeToWalletName } from '../lib/solana/settings';

  const props = withDefaults(
    defineProps<{
      id: string;
      size?: string;
    }>(),
    {
      size: 'size-8',
    },
  );

  const { wallets } = useConnectSolanaWallets();

  const baseIconName = computed(() => {
    if (
      props.id === dscvrApi.wallet.WALLET_TYPES.metamask ||
      props.id === dscvrApi.wallet.WALLET_TYPES.matrica
    ) {
      return props.id;
    }
    return undefined;
  });

  const iconUrl = computed(() => {
    const id =
      convertWalletTypeToWalletName(props.id as dscvrApi.wallet.WalletType) ??
      props.id;
    if (id === SOLANA_WALLET_NAMES.Phantom) {
      return fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/solana/phantom.svg`);
    }
    const wallet = wallets.value.find((wallet) => id === wallet.id);
    if (wallet) {
      return wallet.adapter.icon;
    }
    return undefined;
  });
</script>

<template>
  <base-icon v-if="baseIconName" :name="baseIconName" :size="size" />
  <img v-else-if="iconUrl" v-lazy="iconUrl" :class="size" :alt="id" />
</template>
