<script setup lang="ts">
  import { SEARCH } from '@/common';
  import { trackEvent } from '@/utils/tracker';
  import type { Tag } from 'dfx/edge/edge.did';
  import { useRouter } from 'vue-router';

  defineProps<{
    tags: Tag[];
  }>();

  const router = useRouter();

  const onClickTag = (tag: Tag) => {
    trackEvent('post_action', 'view tag', tag.name);
    router.push({
      name: SEARCH,
      query: { q: 'tag:' + tag.name },
    });
  };
</script>

<template>
  <div class="flex flex-wrap gap-1">
    <div v-for="tag in tags" :key="tag.name">
      <base-button
        variant="custom"
        custom-classes="block px-2 py-1 text-xs bg-indigo-900 rounded-full text-white"
        @click="onClickTag(tag)"
      >
        #{{ tag.name }}
      </base-button>
    </div>
  </div>
</template>
