import { Actor, AnonymousIdentity, HttpAgent } from '@dfinity/agent';
import { idlFactory as edge_idl } from '../../dfx/edge/edge.did.js';
import { AuthClient, LocalStorage } from '@/auth-client';
import { Ed25519KeyIdentity } from '@dfinity/identity';
import { Usergeek } from 'usergeek-ic-js';
import { config } from '@/shared/lib';
import { AUTH_RESPONSE_PARAM } from '@/entities/auth';
import { IcHttpRequestSigner } from './ic-http-request-signer';

const KEY_LOCALSTORAGE_KEY = 'ic-identity';
//const KEY_LOCALSTORAGE_KEY_PROD = "ic-identity-prod";
const DEBUG_USER_ACTIVE = 'dscvr-debug-user-active';
const TEST_REPLICA_SETTINGS = 'dscvr-test-replica-settings';
const DEFAULT_TEST_REPLICA_SETTINGS = {
  url: 'replica1.stg.dscvr.cloud',
  canisterId: 'rrkah-fqaaa-aaaaa-aaaaq-cai',
  enable: false,
};

// If we're running updates on the edge then return the IDL that corresponds
// to the canister running on the edge, where all update methods are marked
// as query methods.
export function societyRsIdl() {
  return edge_idl;
}

/**
 *
 */
export function getEnv() {
  return {
    url: config.IC_URL,
    canisterId: config.CANISTER_ID,
    edgeUrl: config.EDGE_URL,
    societyUrl: config.EDGE_URL,
  };
}

/**
 * Creates a society_rs actor from an identity
 */
export async function createSocietyRsActorFromIdentity(id) {
  const agent = new HttpAgent({
    host: getEnv().societyUrl,
    identity: id,
  });

  return await createSocietyRsActorFromAgent(agent);
}

/**
 * Creates a society_rs actor from an agent
 */
export async function createSocietyRsActorFromAgent(agent) {
  await agent.fetchRootKey();
  return Actor.createActor(societyRsIdl(), {
    agent,
    canisterId: getEnv().canisterId,
  });
}

/**
 * Helper method to pair a wallet
 */
export async function pairWallet(wallet, walletPrincipal, walletActor) {
  const walletStr = Object.keys(wallet)[0];
  console.log(
    `Pairing ${walletStr} ${walletPrincipal.toText()} -> DSCVR ${client
      .getPrincipal()
      .toText()}`,
  );
  console.log(
    `DSCVR->${walletStr}`,
    await client.actor.user_wallet_pair_foreign(wallet, walletPrincipal),
  );
  console.log(
    `${walletStr}->DSCVR`,
    await walletActor.user_wallet_pair_dscvr(client.getPrincipal()),
  );
}

export class ICPClient {
  constructor() {
    this.debug = import.meta.env.DEV;
    this.shim = import.meta.env.VITE_ENABLE_PROD_LOGIN === 'true';
    this.enviro = getEnv();
    this.testReplicaSettings = config.ENABLE_DEBUG
      ? this.initTestReplicaSettings(this.enviro)
      : DEFAULT_TEST_REPLICA_SETTINGS;
  }

  initTestReplicaSettings() {
    const settingsJson = localStorage.getItem(TEST_REPLICA_SETTINGS);
    if (settingsJson) {
      const settings = JSON.parse(settingsJson);
      if (settings && settings.enable && settings.url && settings.canisterId) {
        return settings;
      }
    }
    return DEFAULT_TEST_REPLICA_SETTINGS;
  }

  async setTestReplicaSettings(url, canisterid, enable) {
    if (
      this.testReplicaSettings.enable != enable ||
      this.testReplicaSettings.url != url
    ) {
      this.testReplicaSettings.url = url;
      this.testReplicaSettings.canisterId = canisterid;
      this.testReplicaSettings.enable = enable;
      const json = JSON.stringify(this.testReplicaSettings);
      if (!this.testReplicaSettings.enable) {
        localStorage.removeItem(TEST_REPLICA_SETTINGS);
      }
      console.log(`setTestReplicaSettings ${json}`);
      await this.createActor(this.identity);

      // wait until successful agent creation before saving test replica settings
      if (this.testReplicaSettings.enable) {
        localStorage.setItem(TEST_REPLICA_SETTINGS, json);
      }
      alert(
        `Test replica ${
          this.testReplicaSettings.enable ? 'enabled' : 'disabled'
        }.`,
      );
    }
  }

  async createAuthClient() {
    // Log this to ensure we're only creating the auth client once.
    console.log('Creating auth client');
    const authClientOptions = {
      idleOptions: {
        disableIdle: true,
      },
      storage: new LocalStorage(),
      keyType: 'Ed25519',
    };

    if (localStorage.getItem(DEBUG_USER_ACTIVE)) {
      return await AuthClient.create({
        identity: getIdentity(),
        ...authClientOptions,
      });
    } else {
      return await AuthClient.create({
        ...authClientOptions,
        redirectContext: {
          param: AUTH_RESPONSE_PARAM,
          search: window.location.search,
        },
      });
    }
  }

  async getOrCreateAuthClient() {
    if (this.authClient) {
      return this.authClient;
    }

    this.authClient = await this.createAuthClient();
    return this.authClient;
  }

  async create() {
    await this.getOrCreateAuthClient();
    const identity = this.authClient.getIdentity();
    if (identity && !identity.getPrincipal().isAnonymous()) {
      await this.setIdentity(identity);
    } else {
      await this.createActor(new AnonymousIdentity());
    }
  }

  async getIdentity() {
    await this.getOrCreateAuthClient();
    try {
      return await this.authClient.getDelegation();
    } catch (_e) {
      return this.authClient.getIdentity();
    }
  }

  logout() {
    if (this.debug) {
      console.log('logout this.debug', this.debug);
      localStorage.removeItem(KEY_LOCALSTORAGE_KEY);
      this.authClient.logout();
    }
    localStorage.removeItem(DEBUG_USER_ACTIVE);
    this.authClient.logout({ returnTo: '/' });
    location.reload();
  }

  getPrincipal() {
    if (this.debug && !this.shim) {
      return getIdentity().getPrincipal();
    }
    return this.identity.getPrincipal();
  }

  async setIdentity(identity) {
    if (identity && !identity.getPrincipal().isAnonymous()) {
      if (this.debug && !this.shim) {
        await this.createActor(getIdentity());
      } else {
        await this.createActor(identity);
      }
    }
  }

  async createDebugActorFromJSON(keys) {
    const identity = Ed25519KeyIdentity.fromJSON(JSON.stringify(keys));
    localStorage.setItem(KEY_LOCALSTORAGE_KEY, JSON.stringify(keys));
    localStorage.setItem(DEBUG_USER_ACTIVE, true);
    return await this.createActor(identity);
  }

  async createActor(identity) {
    let agent = null;
    const enableTestReplica = this.testReplicaSettings.enable;
    const testReplicaUrl = this.testReplicaSettings.url;
    const testReplicaCanister = this.testReplicaSettings.canisterId;

    this.identity = identity;
    console.log('Creating agent');
    agent = new HttpAgent({
      host: enableTestReplica ? testReplicaUrl : this.enviro.societyUrl,
      identity: identity,
    });

    if (identity && !this.debug) {
      try {
        Usergeek.init({
          apiKey: '013C01135FEECD217507C332F95A47E2',
          host: 'https://h5aet-waaaa-aaaab-qaamq-cai.raw.ic0.app',
        });
        Usergeek.setPrincipal(identity.getPrincipal());
        if (!identity.getPrincipal().isAnonymous()) {
          Usergeek.trackSession();
        }
      } catch (error) {
        console.error('Usergeek', error);
      }
    }
    await agent.fetchRootKey();

    this.actor = Actor.createActor(societyRsIdl(), {
      agent,
      canisterId: enableTestReplica
        ? testReplicaCanister
        : this.enviro.canisterId,
    });

    if (this.enviro.edgeUrl && this.enviro.edgeUrl != '') {
      console.log(`Initializing edge agent ${this.enviro.edgeUrl}`);
      const edgeAgent = new HttpAgent({
        host: this.enviro.edgeUrl,
        identity: identity,
      });
      await edgeAgent.fetchRootKey();
      this.edgeActor = Actor.createActor(edge_idl, {
        agent: edgeAgent,
        canisterId: this.enviro.canisterId,
      });

      this.signer = new IcHttpRequestSigner({
        host: enableTestReplica ? testReplicaUrl : this.enviro.societyUrl,
        identity: identity,
        canisterId: this.enviro.canisterId,
        idl: edge_idl,
      });
    }
  }
}
export const client = new ICPClient();

/**
 *
 */
function createIdentity() {
  const key = Ed25519KeyIdentity.generate();
  console.log('createIdentity');
  localStorage.setItem(KEY_LOCALSTORAGE_KEY, JSON.stringify(key));
  return key;
}

/**
 *
 */
export function getIdentity() {
  const maybeIdentityStorage = localStorage.getItem(KEY_LOCALSTORAGE_KEY);
  if (maybeIdentityStorage) {
    try {
      const key = Ed25519KeyIdentity.fromJSON(maybeIdentityStorage);
      return key;
    } catch (e) {
      console.log('getIdentity catch (e)', e);
      localStorage.removeItem(KEY_LOCALSTORAGE_KEY);
    }
  }
  return createIdentity();
}
