<script setup lang="ts">
  import { ref, useSlots } from 'vue';

  withDefaults(
    defineProps<{
      cardClass?: string;
      overlayBackdropClass?: string;
      overlayClass?: string;
    }>(),
    {
      cardClass:
        'rounded-xl bg-gray-950 border border-gray-785 border-opacity-50',
      overlayBackdropClass: 'px-2.5 pb-4 pt-6 bg-black bg-opacity-60',
      overlayClass: 'flex-col justify-between items-start animate-up',
    },
  );

  const isOverlayShown = ref(false);
  const shouldOverlayContentAppear = ref(false);
  const slots = useSlots();

  const showOverlay = () => {
    if (slots.overlay) {
      isOverlayShown.value = true;
    }
  };
  const hideOverlay = () => {
    isOverlayShown.value = false;
    shouldOverlayContentAppear.value = false;
  };
</script>

<template>
  <article
    class="flex flex-col overflow-hidden"
    :class="cardClass"
    @click="showOverlay"
    @mouseenter="showOverlay"
    @mouseleave="hideOverlay"
  >
    <slot name="header" />
    <section class="relative flex-1 w-full overflow-hidden">
      <slot />
      <transition
        enter-from-class="opacity-0"
        enter-active-class="transition-all duration-300"
        enter-to-class="opacity-100"
        @after-enter="shouldOverlayContentAppear = true"
      >
        <div
          v-if="isOverlayShown"
          class="absolute inset-0"
          :class="overlayBackdropClass"
        >
          <div
            v-if="shouldOverlayContentAppear"
            class="flex size-full"
            :class="overlayClass"
          >
            <slot name="overlay" />
          </div>
        </div>
      </transition>
    </section>
    <slot name="footer" />
  </article>
</template>

<style scoped>
  .animate-up {
    animation: 0.3s show-up ease-out;
  }

  @keyframes show-up {
    0% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0);
    }
  }
</style>
