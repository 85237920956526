// TODO: fix FSD
import { type Ref, computed, ref } from 'vue';
import type { ContentReaction, ContentView } from 'dfx/edge/edge.did';
import { trackEvent, trackIndexedPostAction } from '@/utils/tracker';
import { useReactToContentMutation } from '../../api/use-react-to-content.mutation';
import { useRoute } from 'vue-router';
import { useUser } from '@/entities/user';
import type { ReactionType, ReactionCount } from '../../types';
import { contentReactionToReactionType } from '../../lib/content-reaction-to-reaction-type';
import { reactionTypeToContentReaction } from '../../lib/reaction-type-to-content-reaction';
import { reactionTypeToIconName } from '../../lib/reaction-type-to-icon-name';
import { setReactionCount } from '../../lib/set-reaction-count';
import { useAuth } from '@/entities/auth';

export const useReaction = (content: Ref<ContentView>) => {
  const route = useRoute();
  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const { mutate } = useReactToContentMutation();

  const contentReaction = ref<ContentReaction | undefined>(
    content.value.is_reactor.length > 0
      ? content.value.is_reactor[0]
      : undefined,
  );
  const reactionCount = ref<ReactionCount[]>(
    content.value.reaction_counts.length > 0
      ? (content.value.reaction_counts as ReactionCount[])
      : [[{ Like: null }, BigInt(0)]],
  );

  const react = async (
    action: 'post_action' | 'comment_action',
    reactionType?: ReactionType,
  ) => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
      return;
    }

    if (!reactionType) {
      trackEvent(action, 'reset');
      if (action === 'post_action') {
        trackIndexedPostAction(route.name, content.value.id, 'reaction_reset');
      }
    } else {
      trackEvent(action, reactionType);
      if (action === 'post_action') {
        trackIndexedPostAction(route.name, content.value.id, 'reacted');
      }
    }
    const newContentReaction = reactionTypeToContentReaction(reactionType);
    mutate({
      contentId: content.value.id,
      contentReaction:
        newContentReaction !== undefined ? [newContentReaction] : [],
    });
    const newReactionCount = setReactionCount(
      reactionCount.value,
      newContentReaction,
      contentReaction.value as ContentReaction,
    );
    reactionCount.value = newReactionCount;
    contentReaction.value = newContentReaction;
  };

  const reactionType = computed(() =>
    contentReaction.value
      ? contentReactionToReactionType(contentReaction.value)
      : undefined,
  );

  const reactionEmoji = computed(() =>
    reactionType.value ? reactionTypeToIconName(reactionType.value) : null,
  );

  return {
    contentReaction,
    react,
    reactionType,
    reactionCount,
    reactionEmoji,
  };
};
