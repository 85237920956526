import { getAvatarUrl } from '../../../avatar';
import { NFT } from './nft';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';

const idlFactory = ({ IDL }) =>
  IDL.Service({
    get_airdrops: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat64, IDL.Bool))],
      ['query'],
    ),
    transfer_to: IDL.Func([IDL.Principal, IDL.Nat64], [IDL.Bool], []),
    user_tokens: IDL.Func([IDL.Principal], [IDL.Vec(IDL.Nat64)], ['query']),
  });

export class ICDrip {
  constructor(cansiterId) {
    this.cansiterId = cansiterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.cansiterId, this.identity);
  }

  async checkAirdrop() {
    return await this.actor.get_airdrops();
  }

  async get_my_tokens() {
    return this.get_tokens(this.identity.getPrincipal());
  }

  async getTokens(pid) {
    const tokens = await this.actor.user_tokens(pid);
    const results = [];
    for (let i = 0; i < tokens.length; i++) {
      const tokenUrl = `https://${this.cansiterId}.raw.ic0.app/?tokenId=${tokens[i]}`;
      const nft = new NFT({
        tokenUrl: tokenUrl,
        tokenDisplayUrl: getAvatarUrl(tokenUrl),
        cid: this.canisterId,
        tokenIndex: tokens[i],
        tokenHash: '',
      });
      results.push(nft);
    }
    return results;
  }

  async hasAirdrop() {
    const drops = await this.actor.get_airdrops();
    if (drops.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async transfer(wallet_address, token_id) {
    return await this.actor.transfer_to(
      Principal.fromText(wallet_address),
      token_id,
    );
  }
}
