import type { PortalSortField } from '../../model/portal';
import type { PortalSortBy } from 'dfx/edge/edge.did';

export const getPortalSortBy = (
  field: PortalSortField,
): [] | [PortalSortBy] => {
  if (field === 'contentCount') {
    return [{ ContentCount: null }];
  }
  if (field === 'memberCount') {
    return [{ MemberCount: null }];
  }
  if (field === 'name') {
    return [{ Name: null }];
  }
  return [];
};
