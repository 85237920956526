import type { MaybeRef, Ref } from 'vue';
import type { Principal } from '@dfinity/principal';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { convertResponseSolanaFT } from '../lib/convert-response';
import { getSolanaFTsQueryKey } from './keys';

export const useGetSolanaFTsQuery = (
  principal: Ref<Principal | undefined>,
  enabled: MaybeRef<boolean> = true,
) => {
  return useQuery({
    queryKey: getSolanaFTsQueryKey(principal),
    queryFn: async () => {
      if (!principal.value) return undefined;
      return dscvrApi.wallet.getUserFTs(principal.value);
    },
    select: convertResponseSolanaFT,
    enabled,
  });
};
