import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyWalletsQueryKey } from './keys';

export const usePairWalletDeepLinkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['pair-wallet-deep-link-mutation'],
    mutationFn: async (payload: dscvrApi.wallet.LinkWalletMobileDto) =>
      dscvrApi.wallet.pairWalletDeepLink(payload),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: getMyWalletsQueryKey,
      }),
  });
};
