<script setup lang="ts">
  import { computed } from 'vue';
  import { MutationTypes } from '@/store/mutation-types';
  import {
    useClipboardToast,
    type TranslatedContentView,
  } from '@/shared/model';
  import { useUser, useUserRole } from '@/entities/user';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { IS_HIDDEN, USER_SETTINGS_PREFERENCES } from '@/common';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { lang } from '@/utils';
  import { useConfirmationDialog } from '@/shared/ui/confirmation';
  import { useI18n } from 'vue-i18n';
  import { useAuth } from '@/entities/auth';

  const { t } = useI18n({ useScope: 'global' });
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { copyToClipboard } = useClipboardToast();
  const { openConfirmationDialog, closeDialog } = useConfirmationDialog();

  const props = defineProps<{
    content: ContentView;
  }>();

  const emits = defineEmits<{
    (
      e: 'translate-content',
      params: { content: TranslatedContentView; lang: string },
    ): void;
    (e: 'remove-content', content: ContentView): void;
    (e: 'delete-content', content: ContentView): void;
    (e: 'edit-content', content: ContentView): void;
    (
      e: 'pin-content',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (
      e: 'pin-content-to-profile',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (e: 'set-nsfw', params: { content: ContentView; isNsfw: boolean }): void;
  }>();

  const { isAdmin, isContentModerator, isContentOwner } = useUserRole(
    props.content,
  );
  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();

  const isPinned = computed(
    () => props.content.is_pinned || props.content.is_author_pinned,
  );

  const isUserProfile = computed(() => {
    return (
      route.name === 'user-content' &&
      route.params?.username === currentUser.value?.username
    );
  });

  const removeContent = () => {
    if (confirm(t('removeConfirmationMessage'))) {
      emits('remove-content', props.content);
    }
  };

  const deleteContent = () => {
    if (confirm(t('deleteConfirmationMessage'))) {
      emits('delete-content', props.content);
    }
  };

  const report = () => {
    store.commit(MutationTypes.SET_REPORT_MODAL, {
      value: true,
      content_id: props.content.id,
    });
  };

  const translate = (content: ContentView) => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else if (lang.getTranslationLang() === '0') {
      openConfirmationDialog({
        headerTitle: t('setLanguage'),
        dialogContentClasses: 'max-w-md rounded-xl',
        contentSlots: {
          default: {
            template: `
                <p class="mb-1">
                  {{ $t('noLanguage') }}
                </p>
                <p>
                  {{ $t('selectLanguage') }}
                </p>
              `,
          },
          submitLabel: { template: t('gotToSettings') },
        },
        submit: () => {
          closeDialog();
          router.push({
            name: USER_SETTINGS_PREFERENCES,
            params: {
              username: currentUser.value.username,
            },
          });
        },
      });
    } else {
      emits('translate-content', { content, lang: lang.getTranslationLang() });
    }
  };

  const copyLink = async () => {
    const { origin, href } = new URL(route.path, window.location.origin);
    let uri = href;
    if (props.content && props.content?.content_type === 'post') {
      uri = `${origin}/post/${props.content.id.toString()}`;
    }
    copyToClipboard(uri);
  };
</script>

<template>
  <base-dropdown
    v-if="currentUser"
    append-to-body
    placement="bottom-end"
    custom-content-classes="flex flex-col shadow-lg w-60 bg-gray-785 rounded-xl cursor-default"
  >
    <template #button="{ toggle }">
      <span
        id="options-menu"
        class="flex btn-comment-actions group"
        aria-expanded="true"
        aria-haspopup="true"
        @click.stop="toggle"
      >
        <base-icon
          name="ellipsis"
          size="size-5"
          class="block text-gray-400 group-hover:text-white"
        />
      </span>
    </template>
    <template #content="{ hide, isVisible, isShown }">
      <div
        v-if="isVisible || isShown"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div role="none">
          <span
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            @click.stop="hide(), copyLink()"
          >
            <base-icon
              name="copy-documents"
              size="size-5"
              class="mr-3.5 m-1.5 text-indigo-300"
            />Copy link
          </span>
          <span
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            @click.stop="hide(), translate(content)"
          >
            <base-icon
              name="translate"
              size="size-5"
              class="mr-3.5 m-1.5 text-indigo-300"
            />Translate
          </span>
          <span
            v-if="!IS_HIDDEN"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
          >
            <base-icon
              name="bookmark-new"
              size="size-5"
              class="mr-3.5 m-1.5 text-indigo-300"
            />Bookmark
          </span>
          <div class="w-[80%] h-[1px] bg-white/[0.12] mx-auto" />
          <span
            v-if="isAdmin || isContentOwner"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            role="menuitem"
            @click.stop="hide(), emits('edit-content', content)"
          >
            <base-icon
              name="pencil"
              class="mr-3.5 m-1.5 text-indigo-300"
              size="size-5"
            />Edit
          </span>

          <span
            v-if="isAdmin || isContentOwner || isContentModerator"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            role="menuitem"
            @click.stop="
              hide(),
                !isUserProfile
                  ? emits('pin-content', { content, isPinned: !isPinned })
                  : emits('pin-content-to-profile', {
                      content,
                      isPinned: !isPinned,
                    })
            "
          >
            <base-icon
              :name="!isPinned ? 'pin' : 'unpin'"
              class="mr-3.5 m-1.5 text-indigo-300"
              size="size-5"
            />
            <span v-if="!isPinned">
              {{ isUserProfile ? 'Pin to Profile' : 'Pin' }}
            </span>
            <span v-else>
              {{ isUserProfile ? 'Unpin to Profile' : 'Unpin' }}
            </span>
          </span>

          <span
            v-if="isAdmin"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            role="menuitem"
            @click.stop="
              hide(), emits('set-nsfw', { content, isNsfw: !content.is_nsfw })
            "
          >
            <base-icon
              :name="content.is_nsfw ? 'eye' : 'eye-off'"
              class="mr-3.5 m-1.5 text-indigo-300"
              size="size-5"
            />
            <span>{{ content.is_nsfw ? 'Mark as safe' : 'Mark as NSFW' }}</span>
          </span>

          <span
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            role="menuitem"
            @click.stop="hide(), report()"
          >
            <base-icon
              name="flag"
              class="mr-3.5 m-1.5 text-[#F87171]"
              size="size-5"
            />
            Report
          </span>

          <div
            v-if="isAdmin || isContentModerator"
            class="w-[80%] h-[1px] bg-white/[0.12] mx-auto"
          />

          <span
            v-if="isAdmin || isContentModerator"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            role="menuitem"
            @click.stop="hide(), removeContent()"
          >
            <base-icon
              name="remove-mod"
              class="mr-3.5 m-1.5 text-[#F87171]"
              size="size-5"
            />
            Remove
          </span>

          <span
            v-if="isContentOwner"
            class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
            @click.stop="hide(), deleteContent()"
          >
            <base-icon
              name="trash"
              class="mr-3.5 m-1.5 text-[#F87171]"
              size="size-5"
            />Delete
          </span>
        </div>
      </div>
    </template>
  </base-dropdown>
</template>
