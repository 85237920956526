<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import ContentDialog from '../../ui/Content.vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { useOnboarding } from '../../model/composables/use-onboarding';
  import { OnboardingLootboxStep, OnboardingStep } from '@/shared/model';
  import ContinueButton from '../ContinueButton.vue';

  const { t } = useI18n({
    useScope: 'global',
  });

  const { onboardingState } = useOnboarding();
</script>

<template>
  <content-dialog class="py-8">
    <template #title>
      <span class="text-2xl">{{ t('onboarding.missingSteps.title') }}</span>
    </template>
    <div
      class="flex flex-col gap-4 border border-white border-opacity-8 rounded-lg p-6 text-gray-200"
    >
      <div
        v-if="
          !onboardingState.completedSteps.includes(
            OnboardingStep.DISCOVER_USERS,
          )
        "
        class="flex gap-4 justify-center items-center"
      >
        <img
          :src="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/users.gif`)
          "
          class="w-10.5 h-auto"
        />
        <div class="flex flex-col gap-1">
          <div class="font-bold">
            {{ t('onboarding.missingSteps.user.title') }}
          </div>
          <div class="text-sm">
            {{ t('onboarding.missingSteps.user.description') }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !onboardingState.completedSteps.includes(OnboardingStep.JOIN_PORTALS)
        "
        class="flex gap-4 justify-center items-center"
      >
        <img
          :src="
            fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/black-hole.gif`,
            )
          "
          class="size-10.5"
        />
        <div class="flex flex-col gap-1">
          <div class="font-bold">
            {{ t('onboarding.missingSteps.portal.title') }}
          </div>
          <div class="text-sm">
            {{ t('onboarding.missingSteps.portal.description') }}
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <continue-button
        :current-step="OnboardingLootboxStep.COMPLETE_ONBOARDING_STEPS"
      >
        {{ t('onboarding.missingSteps.completeOnboarding') }}
      </continue-button>
    </template>
  </content-dialog>
</template>
