<script setup lang="ts">
  import { useI18n } from 'vue-i18n';

  withDefaults(
    defineProps<{
      shown: boolean;
    }>(),
    {
      shown: false,
    },
  );

  const { t } = useI18n({
    useScope: 'global',
  });
</script>

<template>
  <div v-if="shown" class="absolute bottom-[30px] right-[5px]">
    <p
      class="bg-[#6366F1] bg-opacity-50 px-2 py-1 rounded-full backdrop-blur-[30px] text-xs spacing-[0.06px]"
    >
      {{ t('lastUsed') }}
    </p>
  </div>
</template>
