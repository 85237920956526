<script lang="ts" setup>
  import { onMounted, useSlots, ref, onUnmounted } from 'vue';
  import { computed } from 'vue';
  import type { LoaderProps } from './types';

  const props = withDefaults(defineProps<LoaderProps>(), {
    variant: 'gray',
    size: 'size-4',
    animation: 'animate-spin',
    borderWidth: 'border-4',
  });

  const emits = defineEmits<{
    (e: 'done'): void;
  }>();

  const slots = useSlots();
  const timeoutId = ref<NodeJS.Timeout | null>(null);

  const loaderColors = computed(() => {
    if (props.variant === 'rainbow') {
      return 'border-[#f6f] border-t-[#0e0] border-r-[#0dd] border-b-[#f90]';
    }
    // implicit gray return
    return 'border-white border-t-slate-800 border-opacity-50';
  });

  onMounted(() => {
    if (props.durationSeconds && props.durationSeconds > 0) {
      timeoutId.value = setTimeout(() => {
        emits('done');
      }, props.durationSeconds * 1000);
    }
  });

  onUnmounted(() => {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }
  });
</script>

<template>
  <div
    class="absolute transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center justify-center"
    :class="[top ? top : 'top-1/2', left ? left : 'left-1/2', wrapperClasses]"
  >
    <slot v-if="slots.prefix" name="prefix" />
    <div
      :class="[
        'rounded-full',
        variant === 'custom' ? customClasses : loaderColors,
        borderWidth,
        size,
        animation,
      ]"
    />
    <slot v-if="slots.suffix" name="suffix" />
  </div>
</template>
