import { textBeforeBeginTagRegExp, textBeforeEndTagRegExp } from '@/utils';
import { computed, type Ref } from 'vue';

export interface MapPlainTextState {
  htmlResult: string;
  remainingHtml: string;
  openTags: string[];
}

interface MapPlainTextOptions {
  replace: (plainText: string, state: MapPlainTextState) => string | undefined;
}

function initState(html: string): MapPlainTextState {
  return {
    htmlResult: '',
    remainingHtml: html,
    openTags: [],
  };
}

function appendPlainText(
  mapState: MapPlainTextState,
  options: MapPlainTextOptions,
  plainText: string,
  fullMatch?: RegExpExecArray,
) {
  const mappedText = options.replace(plainText, { ...mapState });
  const newPlainText = mappedText || plainText;
  const matchedHtmlIndex = fullMatch ? fullMatch.index : 0;
  const matchedHtml = fullMatch ? fullMatch[0] : plainText;

  mapState.htmlResult += newPlainText + matchedHtml.substring(plainText.length);
  mapState.remainingHtml = mapState.remainingHtml.substring(
    matchedHtmlIndex + matchedHtml.length,
  );
}

function mapHtml(
  html: string,
  options: MapPlainTextOptions,
): MapPlainTextState {
  const mapState = initState(html);

  while (mapState.remainingHtml.length > 0) {
    const textBeforeBeginTagMatch = textBeforeBeginTagRegExp.exec(
      mapState.remainingHtml,
    );
    if (textBeforeBeginTagMatch) {
      const plainText = textBeforeBeginTagMatch.groups?.plainText || '';
      const tag = textBeforeBeginTagMatch.groups?.tag || '';
      const selfClosed = textBeforeBeginTagMatch.groups?.selfClosed || '';

      appendPlainText(mapState, options, plainText, textBeforeBeginTagMatch);

      if (!selfClosed) {
        mapState.openTags.push(tag);
      }

      continue;
    }

    const textBeforeEndTagMatch = textBeforeEndTagRegExp.exec(
      mapState.remainingHtml,
    );

    if (textBeforeEndTagMatch) {
      const plainText = textBeforeEndTagMatch?.groups?.plainText || '';

      appendPlainText(mapState, options, plainText, textBeforeEndTagMatch);

      mapState.openTags.pop();
      continue;
    }

    // remainingHtml is pure text
    appendPlainText(mapState, options, mapState.remainingHtml);
    break;
  }

  return mapState;
}

export function useMapPlainText(
  html: Ref<string>,
  options: MapPlainTextOptions,
) {
  const resultHtml = computed(() => {
    return mapHtml(html.value, options).htmlResult;
  });

  return {
    resultHtml,
  };
}
