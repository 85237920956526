import type { Ref } from 'vue';
import type { ContentView } from 'dfx/edge/edge.did';
import { useUser } from '@/entities/user';
import { useAuth } from '@/entities/auth';
import { usePostDialog } from '@/features/post';

export const useRepost = (content: Ref<ContentView>) => {
  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();

  const repost = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
      return;
    }
    const initialContent = `<p></p><preview type="post" id="${content.value.id}"></preview><p></p>`;
    openCreateEditPostDialog({ initialContent });
  };

  return {
    repost,
  };
};
