<script setup lang="ts">
  import { ref, computed, onMounted, watch } from 'vue';
  import { useUser } from '@/entities/user';
  import { useElementBounding } from '@vueuse/core';
  import { AuthManager, useAuth } from '@/entities/auth';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';
  import { useInvite } from '../model/composables/use-invite';
  import { client } from '@/utils';
  import { useToast } from '@/shared/model';
  import { useI18n } from 'vue-i18n';

  const props = defineProps<{
    referent: PortalView | UserView;
    bannerBgUrl: string | undefined;
    code?: string;
  }>();

  const { onLoggedIn } = useInvite();
  const { t } = useI18n({ useScope: 'global' });
  const { authEntity } = useAuth();
  const { coverImageGradient, isLoggedIn } = useUser();
  const { showToast } = useToast();

  const isUser = computed(() => {
    return 'username' in props.referent;
  });

  const scrollableLogin = ref<HTMLElement | null>(null);
  const { height } = useElementBounding(scrollableLogin);
  const baseClasses =
    'relative lg:w-1/2 flex flex-col px-4 md:px-8 lg:px-12 py-2 items-center justify-center overflow-y-auto thin-scrollbar scrollbar-transparent';
  const scrollableLoginClasses = computed(() => {
    if (
      scrollableLogin.value &&
      scrollableLogin.value.scrollHeight - 56 > height.value
    ) {
      return `${baseClasses} pt-44`;
    }
    return baseClasses;
  });

  const isLogin = computed(() => authEntity.value.form.type === 'login');

  watch(
    isLoggedIn,
    (isLoggedIn) => {
      if (isLoggedIn) {
        if (props.code) {
          onLoggedIn(props.referent, props.code);
        } else {
          showToast({
            type: 'error',
            title: t('portalInvite.invalidReferralCode'),
            durationSeconds: 5,
          });
        }
      }
    },
    { immediate: true },
  );

  onMounted(async () => {
    authEntity.value.referral_code = props.code;
    if ('slug' in props.referent) {
      authEntity.value.portal_slug = props.referent.slug;
    }
    if ('username' in props.referent) {
      authEntity.value.referring_username = props.referent.username;
    }
    // If we have a redirect response then do not reset the authEntity
    // since we would have restored the context.
    const authClient = await client.getOrCreateAuthClient();
    if (!authClient.redirectResponse()) {
      authEntity.value.form = {
        name: 'login-signup',
        type: 'signup',
      };
    }
  });
</script>

<template>
  <div class="relative md:w-3/4 m-auto top-1/2 -translate-y-1/2 w-full">
    <div class="flex items-center gap-x-4 pl-4 md:pl-8 mb-4 md:mb-0">
      <base-icon name="dscvr-with-label" size="w-32 h-10" />
      <base-icon name="close" size="w-4 h-4" class="text-gray-400" />
      <div
        class="flex justify-center items-center border-0.5 border-white border-opacity-12 p-1 rounded-md"
      >
        <base-icon name="chain-solana" size="w-6 h-6" />
      </div>
    </div>
    <div
      class="max-h-[calc(100vh-7rem)] relative flex flex-col lg:flex-row bg-white/5 rounded-3xl shadow-xl mx-2 md:mx-8 mt-8 overflow-hidden"
    >
      <div class="absolute inset-0 -z-1 size-full backdrop-blur-xl" />
      <div
        class="flex lg:hidden flex-col w-full justify-center items-center pt-6 pb-2 text-sm font-semibold gap-y-2"
      >
        <span class="text-gray-400">
          {{ t('portalInvite.invitedToJoin') }}
        </span>
        <div class="flex items-center gap-x-2">
          <slot name="mobile-avatar" />
          <span>
            <slot name="name" />
          </span>
        </div>
      </div>
      <div
        class="hidden lg:block relative shadow-xl lg:w-1/2 overflow-hidden rounded-l-3xl p-8 xl:p-14 bg-cover bg-center"
        :style="{ backgroundImage: bannerBgUrl ? `url(${bannerBgUrl})` : '' }"
        :class="bannerBgUrl ? '' : `${coverImageGradient}`"
      >
        <div
          class="h-full w-full backdrop-blur absolute top-0 left-0 bg-black/50 overflow-hidden rounded-l-3xl"
        />
        <div class="flex gap-x-4 relative">
          <slot name="avatar" />
          <div class="flex flex-col justify-between py-1">
            <span class="text-lg font-bold">
              <slot name="name" />
            </span>
            <div class="flex gap-x-4">
              <div class="flex gap-x-2">
                <base-icon name="users" class="text-blue-500" />
                <div class="flex gap-x-1">
                  <span class="font-semibold">
                    <slot name="membersOrFollowers" />
                  </span>
                  <span class="hidden xl:block">
                    {{ isUser ? 'followers' : 'members' }}
                  </span>
                </div>
              </div>
              <div class="flex gap-x-2">
                <base-icon name="posts" class="text-pink-500" />
                <div class="flex gap-x-1">
                  <span class="font-semibold">
                    <slot name="contentCount" />
                  </span>
                  <span class="hidden xl:block">{{ t('posts') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider my-9 w-2/3 relative" />
        <div
          class="flex flex-col justify-between relative overflow-y-auto thin-scrollbar"
        >
          <div class="flex flex-col gap-y-6 py-8">
            <p class="text-lg">
              {{
                !isUser
                  ? t('portalInvite.joinPortal')
                  : t('portalInvite.followUser')
              }}
            </p>
            <p class="text-[2.25rem] py-4">
              {{ t('portalInvite.invited') }}
            </p>
            <p class="w-5/6">
              {{ t('portalInvite.embraceFuture') }}
            </p>
          </div>
        </div>
        <div class="text-lg relative">
          <p>
            {{
              !isLogin
                ? t('signUp.alreadyHaveAccount')
                : t('signUp.dontHaveAccount')
            }}
          </p>
          <base-button
            variant="link"
            class="font-semibold"
            @click="authEntity.form.type = isLogin ? 'signup' : 'login'"
          >
            {{ !isLogin ? t('login') : t('signup') }}
          </base-button>
        </div>
      </div>
      <div ref="scrollableLogin" :class="scrollableLoginClasses">
        <auth-manager />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .divider {
    height: 0.125rem;
    border-radius: 12.5rem;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.12) -2.56%,
      rgba(255, 255, 255, 0) 110.58%
    );
  }
</style>
