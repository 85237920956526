<script setup lang="ts">
  import { computed, watch } from 'vue';
  import { useForm } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import * as yup from 'yup';
  import { TextFormField } from '@/shared/ui/form-fields';
  import { isValidHttpUrl } from '@/shared/lib';
  import type { FrameFormPayload } from '../types';
  import { IS_HIDDEN } from '@/common';

  interface FormFields {
    url?: string;
    post_id?: string;
  }

  const props = defineProps<{
    formValues: FrameFormPayload;
  }>();

  const emit = defineEmits<{
    (e: 'submit', value: FrameFormPayload): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });

  const schema = yup.object({
    url: yup
      .string()
      .required(t('frame.frameUrlErrorRequired'))
      .test({
        name: 'url',
        message: t('invalidUrlError'),
        test: (value) => {
          if (!value) {
            return false;
          }
          return isValidHttpUrl(value);
        },
      }),
    post_id: yup.string().test({
      name: 'bigint',
      message: t('frame.postIdErrorInvalid'),
      test: (value) => {
        if (!value) {
          return true;
        }
        try {
          const bigintValue = BigInt(value);
          return bigintValue.toString() === value.trim();
        } catch (e) {
          return false;
        }
      },
    }),
  });

  const initialValues = computed(() => {
    return {
      url: props.formValues.url || '',
      post_id: props.formValues.postId?.toString() || '',
    };
  });

  const { handleSubmit, errors, setValues } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });

  const submit = handleSubmit((values) => {
    emit('submit', {
      url: values.url!,
      postId: values.post_id ? BigInt(values.post_id) : undefined,
    });
  });

  watch(
    () => props.formValues,
    (newValue) => {
      setValues(newValue);
    },
  );
</script>

<template>
  <div>
    <form class="flex flex-col md:flex-row gap-4" @submit="submit">
      <text-form-field
        name="url"
        :label="$t('frame.frameUrl')"
        autocomplete="off"
        :error="errors.url"
      />
      <!-- TODO: we will hide this for the moment, will include on MVP 3 -->
      <text-form-field
        v-if="!IS_HIDDEN"
        name="post_id"
        :label="$t('frame.postId')"
        autocomplete="off"
        :error="errors.post_id"
      />

      <base-button variant="primary" size="small" class="w-auto h-11 mt-0.5">
        {{ $t('frame.debug') }}
      </base-button>
    </form>
  </div>
</template>
