export const ABOUT = 'about';
export const ALL = 'all';
export const ARCADE = 'arcade';
export const CREATE_POST_PORTAL = 'create-post-portal';
export const EXPLORE = 'explore';
export const EXPLORE_PEOPLE = 'explore-people';
export const EXPLORE_PEOPLE_FEATURED = 'explore-people-featured';
export const EXPLORE_PORTALS = 'explore-portals';
export const EXPLORE_PORTALS_FEATURED = 'explore-portals-featured';
export const DEBUG = 'debug';
export const ECLIPSE_SIGNUP = 'eclipse-signup';
export const FOLLOW = 'follow';
export const FRAMES_VALIDATOR = 'frames-validator';
export const HOME = 'home';
export const INVITE = 'invite';
export const JOIN = 'join';
export const MAIN = 'main';
export const MATRICA_OAUTH_REDIRECT = 'matrica-oauth-redirect';
export const MIGRATE = 'migrate';
export const MOBILE_WALLET_PAIRING = 'mobile-wallet-pairing';
export const MOD_PAGE = 'mod-page';
export const NFT = 'nft';
export const PORTAL_PAGE = 'portal-page';
export const PORTAL_CONTENT = 'portal-content';
export const PORTAL_MARKETPLACE = 'portal-marketplace';
export const PORTAL_ABOUT = 'portal-about';
export const PORTAL_SETTINGS_PAGE = 'portal-settings-page';
export const PORTAL_SETTINGS_PAGE_BANS = 'portal-settings-page.bans';
export const PORTAL_SETTINGS_PAGE_DETAILS = 'portal-settings-page.details';
export const PORTAL_SETTINGS_PAGE_GATES = 'portal-settings-page.gates';
export const PORTAL_SETTINGS_PAGE_MEMBERS = 'portal-settings-page.members';
export const PORTAL_SETTINGS_PAGE_OVERVIEW = 'portal-settings-page.overview';
export const PORTAL_SETTINGS_PAGE_ROLES = 'portal-settings-page.roles';
export const PORTAL_SETTINGS_PAGE_ROLES_DETAILS =
  'portal-settings-page.roleDetails';
export const PORTALS = 'portals';
export const POST_DETAIL = 'post-detail';
export const POST_DETAIL_COMMENT = 'post-detail-comment';
export const POST_DETAIL_COMMENT_NO_TITLE = 'post-detail-comment-no-title';
export const POST_DETAIL_ID_ONLY = 'post-detail-id-only';
export const PROPOSAL_CREATE_PAGE = 'proposal-create-page';
export const REPORTS = 'reports';
export const SEARCH = 'search';
export const TERMS = 'terms';
export const USER = 'user';
export const USER_WALLETS = 'user-wallets';
export const USER_CONTENT = 'user-content';
export const USER_MANAGE_PORTALS = 'user-manage-portals';
export const USER_NOTIFICATIONS = 'user-notifications';
export const USER_PORTALS = 'user-portals';
export const USER_SETTINGS = 'user-settings';
export const USER_SETTINGS_INTEGRATIONS = 'user-settings-integrations';
export const USER_SETTINGS_PEOPLE = 'user-settings-people';
export const USER_SETTINGS_PORTALS = 'user-settings-portals';
export const USER_SETTINGS_PORTALS_FAVORITES =
  'user-settings-portals-favorites';
export const USER_SETTINGS_PORTALS_MANAGED = 'user-settings-portals-managed';
export const USER_SETTINGS_PREFERENCES = 'user-settings-preferences';
export const USER_SETTINGS_NOTIFICATIONS = 'user-settings-notifications';
export const USER_SETTINGS_PROFILE = 'user-settings-profile';
export const TWITTER_OAUTH_REDIRECT = 'twitter-oauth-redirect';
