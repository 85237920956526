<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-row w-full p-3 border-b border-[#333B47]/[0.5]">
      <ul class="flex flex-row w-full gap-x-3">
        <li
          v-for="(tab, index) in tabList"
          :key="index"
          class="text-sm font-semibold cursor-pointer px-4 py-2"
          :class="{
            'bg-white/[0.12] rounded-md text-white': index === activeTab,
            'text-white/[0.6]': index !== activeTab,
          }"
        >
          <label :for="`${_uid}${index}`" class="cursor-pointer" v-text="tab" />
          <input
            :id="`${_uid}${index}`"
            v-model="activeTab"
            type="radio"
            class="hidden"
            :name="`${_uid}-tab`"
            :value="index"
          />
        </li>
      </ul>
      <div
        class="w-8 h-8 rounded-md bg-white/[0.08] flex items-center justify-center cursor-pointer"
        @click="$emit('close')"
      >
        <base-icon name="close" />
      </div>
    </div>

    <template v-for="(tab, index) in tabList">
      <div v-if="index === activeTab" :key="index">
        <slot :name="`tabPanel-${index}`" />
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      tabList: {
        type: Array,
        required: true,
      },
      inlineClose: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    data() {
      return {
        activeTab: 0,
      };
    },
  };
</script>

<style scoped>
  .close {
    @apply flex self-center justify-center p-1 mr-4 transition-all duration-300 bg-gray-700 rounded-md cursor-pointer items-center hover:bg-gray-785;
  }
</style>
