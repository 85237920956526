import type { Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';
import { useQuery } from '@tanstack/vue-query';

export const useGetRandomizedRecommendedUsersQuery = (
  query: Ref<RandomizedRecommendationQuery>,
) => {
  return useQuery({
    queryKey: ['randomized-recommended-users', query],
    queryFn: () => dscvrIcApi.user.getRandomizedRecommendedUsers(query.value),
    staleTime: 1000 * 60 * 5,
  });
};
