<template>
  <div>
    <label
      v-if="errors || errorMessage || localErrors"
      class="block text-center text-red-400"
    >
      <div v-if="errorMessage">
        {{ errorMessage }}
      </div>

      <div v-if="errors">
        <li v-for="value in errors" :key="value" class="list-none">
          {{ transformError(value) }}
        </li>
      </div>

      <div v-if="localErrors">
        <li v-for="value in localErrors" :key="value" class="list-none">
          {{ transformError(value) }}
        </li>
      </div>
    </label>
  </div>
</template>

<script>
  //[ { "field": "title", "errors": [ "min length is 1 and max length is 256" ] } ]
  export default {
    name: 'error-display',
    props: {
      errors: {
        type: Object,
        default: () => null,
      },
      errorMessage: {
        type: String,
        default: '',
      },
      localErrors: {
        type: Object,
        default: () => null,
      },
    },
    methods: {
      transformError(data) {
        if (typeof data == 'string') return data;

        return data['field']?.length
          ? data['field'] + ': ' + data['errors'].join(' ')
          : data['errors'].join(' ');
      },
    },
  };
</script>
