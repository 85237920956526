import removeMd from 'remove-markdown';

export const buildMetaTitle = (title: string): string => {
  return `DSCVR - ${title}`;
};

export const getUrls = (text: string): string[] => {
  const regex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const str = text;
  let m;
  const results: string[] = [];
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    m.forEach((match) => {
      results.push(match);
    });
  }
  return results;
};

export const removeMarkdown = (text: string): string => {
  return removeMd(text, {
    stripListLeaders: true, // strip list leaders (default: true)
    listUnicodeChar: '', // char to insert instead of stripped list leaders (default: '')
    gfm: true, // support GitHub-Flavored Markdown (default: true)
    useImgAltText: true, // replace images with alt-text, if present (default: true)
  });
};
