<script lang="ts" setup>
  import { watch, ref } from 'vue';
  import { useStreak } from '../../model/composables/use-streak';
  import { useStreakStore } from '../../model/store';
  import { useTippy } from 'vue-tippy';
  import { useI18n } from 'vue-i18n';
  import { useElementBounding } from '@vueuse/core';
  import { storeToRefs } from 'pinia';

  const { t } = useI18n({ useScope: 'global' });
  const {
    showDailyStreakDialog,
    streakCount,
    showClaimButton,
    activeStreak,
    updateActiveStreakWindow,
  } = useStreak();
  const streakStore = useStreakStore();

  const { activeStreakButtonPosition } = storeToRefs(streakStore);
  const highlightButton = ref(false);
  const activeStreakButton = ref<HTMLElement>();

  useElementBounding(activeStreakButton);
  watch(streakCount, (count) => {
    if (count >= BigInt(0)) {
      updateActiveStreakWindow();
    }
  });

  watch(
    () => activeStreak.value?.next_claim_at,
    (nextClaimAt) => {
      if (nextClaimAt !== undefined && nextClaimAt >= BigInt(0)) {
        streakStore.setStreakCountdown(nextClaimAt);
      }
    },
  );

  const { top, left, right } = useElementBounding(activeStreakButton);

  watch(
    [top, left, right],
    ([newTop, newLeft, newRight]) => {
      activeStreakButtonPosition.value = {
        top: newTop,
        right: newRight,
        left: newLeft,
      };
    },
    { immediate: true },
  );

  useTippy(activeStreakButton, {
    content: t('streak.activeButton.tooltipContent'),
    placement: 'bottom-end',
    theme: 'blue',
    maxWidth: 139,
    offset: [0, 10],
    hideOnClick: 'toggle',
    zIndex: 65,
  });
</script>

<template>
  <div
    ref="activeStreakButton"
    class="relative flex justify-center items-center"
    @mouseover="highlightButton = true"
    @mouseleave="highlightButton = false"
  >
    <base-button
      variant="tertiary"
      size="x-small"
      @click="showDailyStreakDialog"
    >
      <base-icon
        :name="
          showClaimButton || highlightButton
            ? 'vuesax-bold-flash'
            : 'vuesax-outline-flash'
        "
        size="w-5 h-5"
        :class="{
          'animate-pulse animate-duration-1000': false || showClaimButton,
        }"
      />
      <template v-if="streakCount > 0"> {{ Number(streakCount) }}d </template>
    </base-button>
  </div>
</template>

<style>
  .streak-modal {
    background: linear-gradient(
      66deg,
      #131720 -2.38%,
      #0a0c11 83.14%,
      #000 113.97%
    );
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.4);
  }
</style>
