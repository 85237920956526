import { ref, type MaybeRef, unref, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import {
  getDominantColors,
  rgbToHex,
  generateComplementaryColors,
} from '@/utils/images/gradient';
import { toPng } from 'html-to-image';
import type { PortalView, UserView } from 'dfx/edge/edge.did';
import { PORTAL_CONTENT, USER_CONTENT } from '@/common';
import { useDialog } from '@/shared/model';
import PortalInviteDialog from '../../components/PortalInviteDialog.vue';
import UserInviteDialog from '../../components/UserInviteDialog.vue';

export const useInvite = () => {
  const store = useStore();
  const router = useRouter();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const gradientBg = ref<string[]>([]);

  const setGradientToWindow = () => {
    document.documentElement.style.setProperty(
      '--color-from',
      gradientBg.value[0],
    );
    document.documentElement.style.setProperty(
      '--color-to',
      gradientBg.value[1],
    );
  };

  const setGradient = async (portalCoverPhoto?: string | null) => {
    if (!portalCoverPhoto) {
      gradientBg.value = generateComplementaryColors();
      setGradientToWindow();
    } else {
      getDominantColors(portalCoverPhoto).then((colors) => {
        gradientBg.value = colors.map((color) => rgbToHex(color));
        setGradientToWindow();
      });
    }
  };

  const downloadDivAsImage = async (
    shareCard: HTMLElement | null,
    name: string,
  ) => {
    if (!shareCard) return;
    const dataUrl = await toPng(shareCard);
    const link = document.createElement('a');
    link.download = `DSCVR-${name}.png`;
    link.href = dataUrl;
    link.click();
  };

  const onLoggedIn = async (
    referentRef: MaybeRef<UserView | PortalView>,
    referralCode: string,
  ) => {
    const referent = unref(referentRef);
    if ('username' in referent) {
      try {
        await store
          .dispatch(`users/${ActionTypes.ACCEPT_USER_INVITE}`, referralCode)
          .finally(() => {
            router.push({
              name: USER_CONTENT,
              params: { username: referent.username },
            });
          });
      } catch (error) {
        console.log(error);
      }
    } else if ('slug' in referent) {
      try {
        await store
          .dispatch(`portals/${ActionTypes.ACCEPT_PORTAL_INVITE}`, {
            slug: referent.slug,
            referral_code: referralCode,
          })
          .finally(() => {
            router.push({
              name: PORTAL_CONTENT,
              params: { slug: referent.slug },
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const openInvitePortalDialog = (portalView: PortalView) => {
    openConfiguredDialog({
      content: {
        component: PortalInviteDialog,
        props: {
          portalView,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} h-auto md:max-w-[32.5rem] pb-8 overflow-y-auto pt-16 px-8 pb-8`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openInviteUserDialog = (user: UserView) => {
    openConfiguredDialog({
      content: {
        component: UserInviteDialog,
        props: {
          user,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} md:max-w-[32.5rem] pb-8 overflow-y-auto pt-16 px-8 pb-8`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  return {
    setGradient,
    downloadDivAsImage,
    onLoggedIn,
    openInvitePortalDialog,
    openInviteUserDialog,
    closeDialog,
  };
};
