<script setup lang="ts">
  import { computed } from 'vue';

  const props = defineProps<{
    variant: 'play' | 'pay';
  }>();

  const emit = defineEmits<{
    (e: 'play'): void;
  }>();

  const classes = computed(() => [
    'px-2 py-2 md:py-1 ring-inset ring ring-white ring-opacity-5 transition-all duration-300 ease-in-out text-white font-semibold text-sm flex items-center gap-1 h-fit w-fit rounded-xl md:rounded-3xl',
    props.variant === 'play'
      ? 'bg-gradient-to-br from-blue-400 via-fuchsia-400 to-orange-400  hover:from-blue-500 hover:via-fuchsia-500 hover:to-orange-500 active:to-orange-600 active:from-blue-600 active:via-fuchsia-600'
      : 'bg-gradient-to-bl from-emerald-400 via-emerald-400 to-indigo-400 hover:from-emerald-500 hover:via-emerald-500 hover:to-indigo-500 active:from-emerald-600 active:via-emerald-600 active:to-indigo-600',
  ]);

  const icon = computed(() =>
    props.variant === 'play' ? 'play-game' : 'star-coin',
  );
</script>

<template>
  <base-button
    variant="custom"
    :custom-classes="classes.join(' ')"
    @click="emit('play')"
  >
    <base-icon :name="icon" />
    <span class="hidden md:block">{{ variant }}</span>
  </base-button>
</template>
