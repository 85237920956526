<template>
  <div class="flex flex-col w-full text-white items">
    <template v-if="searchedUsers?.length">
      <button
        v-for="(user, index) in searchedUsers"
        :key="index"
        class="py-2 pl-2 pr-20 rounded-md hover:bg-opacity-60 hover:bg-gray-600"
        :class="{
          'is-selected': index === selectedIndex,
          'pl-3': index !== selectedIndex,
        }"
        @click="selectUser(index)"
      >
        <div class="flex flex-row align-center">
          <user-avatar v-if="user" :item="user" size="w-6 h-6" class="mr-2" />
          <span class="username">{{ user.username }}</span>
        </div>
      </button>
    </template>
    <div v-else-if="isLoading" class="px-10 py-3">
      <div class="item spinner" />
    </div>
    <div v-else class="px-10 item">No Results</div>
  </div>
</template>

<script>
  import { ActionTypes } from '@/store/action-types';
  import { UserAvatar } from '@/shared/ui/user-avatar';

  export default {
    name: 'user-list',
    components: { UserAvatar },
    props: {
      command: {
        type: Function,
        required: true,
      },
      query: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        selectedIndex: 0,
        searchedUsers: [],
        debounceTimer: null,
        isLoading: false,
      };
    },
    watch: {
      searchedUsers() {
        this.selectedIndex = 0;
      },
      query(newQuery) {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(async () => {
          this.isLoading = true;
          await Promise.resolve(this.searchUsers(newQuery)).then((res) => {
            this.searchedUsers = res;
            this.isLoading = false;
          });
        }, 700);
      },
    },
    methods: {
      onKeyDown({ event }) {
        if (event.key === 'ArrowUp') {
          this.upHandler();
          return true;
        }
        if (event.key === 'ArrowDown') {
          this.downHandler();
          return true;
        }
        if (event.key === 'Enter') {
          this.enterHandler();
          return true;
        }
        return false;
      },
      upHandler() {
        this.selectedIndex =
          (this.selectedIndex + this.searchedUsers.length - 1) %
          this.searchedUsers.length;
      },
      downHandler() {
        this.selectedIndex =
          (this.selectedIndex + 1) % this.searchedUsers.length;
      },
      enterHandler() {
        this.selectUser(this.selectedIndex);
      },
      selectUser(index) {
        const user = this.searchedUsers[index];
        if (user) {
          this.command({ id: user.username });
        }
      },
      async searchUsers(query, qMinLength = 2) {
        if (query && query.length > qMinLength) {
          return await this.$store.dispatch(
            `auth/${ActionTypes.GET_SEARCHED_USERS}`,
            query,
          );
        }
      },
    },
  };
</script>
