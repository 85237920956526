import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { CreateICPUser } from 'dfx/edge/edge.did';

export const useCreateReferredUserMutation = () => {
  return useMutation({
    mutationKey: ['create-referred-user-mutation'],
    mutationFn: (params: CreateICPUser) =>
      dscvrIcApi.auth.createReferredUser(params),
  });
};
