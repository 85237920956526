<script lang="ts" setup>
  import { onMounted } from 'vue';
  import { useAuth } from '../model/composables/use-auth';

  const { authManager, authEntity } = useAuth();

  onMounted(() => {
    authEntity.value.visible = true;
    authEntity.value.context = 'page';
  });
</script>

<template>
  <component :is="authManager.content?.component" />
</template>
