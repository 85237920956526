<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useElementBounding } from '@vueuse/core';
  import { usePersistentStore } from '@/store';
  import { CreatePortalButton } from '@/widgets/create-portal-button';
  import { useUser } from '@/entities/user';
  import { HeaderDropdown, HotSpots, FavoritePortals } from './components';
  import { FeaturedPortals } from '@/widgets/featured-portals';
  import { fetchMedia } from '@/shared/lib';
  import {
    ARCADE,
    DSCVR_STATIC_ASSETS_CDN_URL,
    USER_SETTINGS_PORTALS,
  } from '@/common';
  import { TradeWithJupiter } from '@/widgets/trade-with-jupiter';
  import { useBreakpoint } from '@/shared/model';
  import { usePortalUserStatus } from '@/entities/portal';

  const scrollableRef = ref<HTMLElement | null>(null);
  const scrollContentRef = ref<HTMLElement | null>(null);
  const { isLoggedIn, currentUser } = useUser();
  const uiFeatureStore = usePersistentStore();
  const { height: scrollableHeight } = useElementBounding(scrollableRef);
  const { height: scrollContentHeight } = useElementBounding(scrollContentRef);
  const { isSmallerThanLg } = useBreakpoint();
  const { favorites, isFavoritesLoading } = usePortalUserStatus();

  const isScrollOverflown = computed(() => {
    return scrollableHeight.value < scrollContentHeight.value;
  });
</script>

<template>
  <div
    v-if="!isSmallerThanLg"
    class="flex flex-col lg:w-[250px] sticky top-24 h-screen--6"
  >
    <div class="flex items-center pl-3 mb-4">
      <header-dropdown
        v-if="isLoggedIn"
        v-model="uiFeatureStore.leftSideBar.contentType"
      />
      <h3 v-else class="text-lg font-semibold">
        <span class="capitalize">{{ $t('featuredPortals') }}</span>
      </h3>
    </div>
    <div
      ref="scrollableRef"
      class="relative max-h-[680px] overflow-y-auto no-scrollbar"
    >
      <div ref="scrollContentRef">
        <featured-portals
          v-if="!isLoggedIn && favorites"
          :favorites="favorites"
        />
        <hot-spots
          v-else-if="uiFeatureStore.leftSideBar.contentType === 'HotSpots'"
        />
        <favorite-portals
          v-else-if="!isFavoritesLoading && favorites"
          :favorites="favorites"
        />
      </div>
    </div>
    <div v-if="isScrollOverflown" class="w-full h-0">
      <div class="relative h-4 bg-transparent shadow-top-light"></div>
    </div>
    <div class="flex flex-col justify-center gap-8 pl-2 pr-4 mt-6">
      <base-button
        v-if="isLoggedIn"
        variant="link"
        size="x-small"
        additional-classes="!gap-2 !px-0 group"
        :to="{
          name: USER_SETTINGS_PORTALS,
          params: { username: currentUser.username },
        }"
      >
        <div
          class="flex items-center justify-center w-8 h-8 rounded-lg shadow-lg bg-gray-820 group-hover:bg-gray-950 group-active:bg-gray-980 group-focus:border group-focus:border-white group-focus:border-opacity-40 ease-transition-all"
        >
          <base-icon name="plus" size="w-4 h-4" />
        </div>
        <span class="flex-1">{{ $t('favoritePortals.addToFavorites') }}</span>
      </base-button>
      <create-portal-button variant="secondary" size="small" class="w-full">
        <base-icon name="vuesax-outline-magic-star" size="w-4 h-4" />
        <span class="font-medium">{{ $t('createPortal') }}</span>
      </create-portal-button>
    </div>
    <trade-with-jupiter />
    <div class="p-4 mt-4 border border-opacity-50 border-gray-785 rounded-2xl">
      <base-button
        :to="{
          name: ARCADE,
        }"
        variant="link"
        class="relative w-full group"
      >
        <img
          :src="
            fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/arcade/dscvr-arcade-block.png`,
            )
          "
          alt="DSCVR Arcade"
        />
        <div
          class="flex items-center justify-center ease-transition-all rounded-xl bg-white bg-opacity-8 group-hover:bg-opacity-12 group-focus:border group-focus:border-white group-focus:border-opacity-8 group-active:bg-opacity-6 group-disabled:bg-opacity-6 backdrop-blur gap-1 px-3 py-[7px] text-sm leading-[18px] absolute -translate-x-1/2 bottom-6 left-1/2"
        >
          <base-icon name="vuesax-linear-game" size="size-4" />
          <span class="tracking-[0.14px]">{{ $t('play') }}</span>
        </div>
      </base-button>
    </div>
  </div>
</template>
