import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { LITE_YOUTUBE_REGEX } from '@/common';

export const LiteYoutube = Extension.create({
  name: 'liteYoutubeExtension',
  addProseMirrorPlugins(): Plugin<any>[] {
    return [
      new Plugin({
        props: {
          transformPasted: (slice, view) => {
            const src = slice.content.firstChild?.textContent;
            if (src && LITE_YOUTUBE_REGEX.test(src)) {
              const { from, to } = view.state.selection;
              const { liteYoutube } = view.state.schema.nodes;
              const node = liteYoutube.create({
                src,
              });
              const newTransaction = view.state.tr.replaceRangeWith(
                from,
                to,
                node,
              );
              view.dispatch(newTransaction);
            }
            return slice;
          },
        },
      }),
    ];
  },
});
