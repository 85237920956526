<script setup lang="ts">
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useClipboardToast } from '@/shared/model';
  import { useChainBehavior } from '@/entities/user-setting';
  import type { WalletOption } from '@/entities/wallets';
  import WalletsDropdown from './WalletsDropdown.vue';
  import WalletActions from './WalletActions.vue';
  import WalletOptionItem from '../ui/WalletOptionItem.vue';

  defineProps<{
    walletOptions: WalletOption[];
    isLoading: boolean;
    selectedWalletOption?: WalletOption;
  }>();

  defineEmits<{
    (e: 'add-wallet'): void;
    (e: 'select-wallet', option: WalletOption): void;
  }>();

  const { copyToClipboard } = useClipboardToast();
  const { isSolanaUxChainEnabled } = useChainBehavior();
</script>

<template>
  <div class="flex justify-between items-center relative mx-4 sm:mx-auto">
    <div>
      <wallets-dropdown
        v-if="walletOptions.length > 1"
        :selected-wallet-option="selectedWalletOption"
        :wallet-options="walletOptions"
        :allow-add-wallet="isSolanaUxChainEnabled"
        @add-wallet="$emit('add-wallet')"
        @select-wallet="$emit('select-wallet', $event)"
      />
      <template v-else-if="walletOptions.length === 1">
        <base-button
          variant="custom"
          custom-classes="flex items-center space-x-2"
        >
          <wallet-option-item :icon="selectedWalletOption?.icon">
            {{ selectedWalletOption?.label || $t('wallets.allWallets') }}
            <template #subtitle v-if="selectedWalletOption?.subtitle">
              {{ selectedWalletOption.subtitle }}
            </template>
          </wallet-option-item>
          <base-tooltip v-if="selectedWalletOption?.address" content="Copy">
            <button
              type="button"
              class="flex items-center justify-center size-8 rounded-md hover:bg-white hover:bg-opacity-16"
              @click.stop="
                copyToClipboard(
                  selectedWalletOption.address,
                  $t('wallets.addressCopied'),
                )
              "
            >
              <base-icon name="outlined-copy" size="size-4" />
            </button>
          </base-tooltip>
        </base-button>
      </template>
    </div>
    <wallet-actions v-if="!isLoading" @add-wallet="$emit('add-wallet')" />
  </div>
</template>
