<script setup lang="ts">
  import { computed } from 'vue';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import {
    useTwitterOauth,
    useGetMyTwitterProfileQuery,
    PairButton,
  } from '@/entities/user-setting';
  import { useToast } from '@/shared/model';
  import { useUnlinkTwitterMutation } from '@/entities/user-setting/api/use-unlink-twitter.mutation';
  import { useI18n } from 'vue-i18n';
  import { trackEvent } from '@/utils/tracker';

  const { data: twitterProfile, isLoading } = useGetMyTwitterProfileQuery();
  const { mutateAsync: mutateUnlinkTwitter } = useUnlinkTwitterMutation();
  const { t } = useI18n({ useScope: 'global' });

  const { getTwitterAuthUrl } = useTwitterOauth();

  const { showToast } = useToast();

  const twitterUsername = computed(() => {
    return twitterProfile.value?.data
      ? `@${twitterProfile.value.data.username}`
      : '';
  });

  const isPaired = computed(() => {
    return !!twitterUsername.value;
  });

  const handlePairing = async () => {
    if (!isPaired.value) {
      trackEvent('user_settings_action', 'social_link_initiate', 'twitter');
      const url = await getTwitterAuthUrl();
      window.location.href = url;
    } else {
      // TODO: make this a dialog "are you sure you want to unlink your X (Twitter) account?"
      await mutateUnlinkTwitter(undefined, {
        onError: () => {
          trackEvent('user_settings_action', 'social_unlink_error', 'twitter');
          showToast({
            description: t('twitter.unlinkError'),
            type: 'error',
          });
        },
        onSuccess: () => {
          trackEvent('user_settings_action', 'social_unlink', 'twitter');
          showToast({
            description: t('twitter.unlinkSuccess'),
            type: 'success',
          });
        },
      });
    }
  };
</script>

<template>
  <div class="relative w-full">
    <overlay-loader v-if="isLoading" />
    <pair-button :connected-id="twitterUsername" @toggle="handlePairing">
      <base-icon name="social-x" size="size-10" class="inline" />
      <template #name>X (Twitter)</template>
      <template #connection>
        {{ $t('settings.username') }}: {{ twitterUsername }}
      </template>
    </pair-button>
  </div>
</template>
