<script setup lang="ts">
  import { ref } from 'vue';
  import { useBreakpoint } from '@/shared/model';
  import { AppHeader, SidebarMenu } from '@/widgets/app-header';
  import { AppFooter } from '@/widgets/app-footer';

  const { isSmallerThanLg } = useBreakpoint();

  const userDropdownOpened = ref(false);
  const menuDrawerOpened = ref(false);
</script>

<template>
  <app-header
    v-model:user-dropdown-opened="userDropdownOpened"
    v-model:menu-drawer-opened="menuDrawerOpened"
  />
  <sidebar-menu v-if="isSmallerThanLg" v-model:opened="menuDrawerOpened" />
  <slot />
  <app-footer v-model:menu-drawer-opened="menuDrawerOpened" />
</template>
