import { useDialog } from '@/shared/model';
import { dscvrApi } from '@/shared/api';
import ReportDialogContent from '../../components/ReportDialogContent.vue';
import AlreadyReportedDialogContent from '../../components/AlreadyReportedDialogContent.vue';
import TransactionErrorDialogContent from '../../components/TransactionErrorDialogContent.vue';
import TransactionSuccessDialogContent from '../../components/TransactionSuccessDialogContent.vue';
import type { ReportTransactionPayload } from '../../types';

export const useTransactionDialog = () => {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openReportModal = (signedTx: string) => {
    return new Promise<ReportTransactionPayload | undefined>((resolve) => {
      openConfiguredDialog({
        content: {
          component: ReportDialogContent,
          props: {
            signedTx,
          },
          emits: {
            report: (payload: ReportTransactionPayload) => {
              resolve(payload);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-125`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
        emit: () => {
          resolve(undefined);
        },
      });
    });
  };

  const openAlreadyReportedWarningModal = (reportCount: number) => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: AlreadyReportedDialogContent,
          props: {
            reportCount,
          },
          emits: {
            continue: () => {
              resolve(true);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-125`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
        emit: () => {
          resolve(false);
        },
      });
    });
  };

  const openTransactionErrorModal = (
    scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response,
  ) => {
    openConfiguredDialog({
      content: {
        component: TransactionErrorDialogContent,
        props: { scanResponse },
        emits: {
          close: () => {
            closeDialog();
          },
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-125`,
        closeOnClickOutside: false,
        showCloseButton: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
        closeOnClickOutside: false,
        showHeader: false,
      },
    });
  };

  const openTransactionSuccessModal = (signedTx: string) => {
    return new Promise<ReportTransactionPayload | undefined>((resolve) => {
      openConfiguredDialog({
        content: {
          component: TransactionSuccessDialogContent,
          props: {
            signedTx,
          },
          emits: {
            report: async () => {
              const result = await openReportModal(signedTx);
              resolve(result);
            },
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-125`,
          showCloseButton: false,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
          showHeader: false,
        },
        emit: () => {
          resolve(undefined);
        },
      });
    });
  };

  return {
    openReportModal,
    openAlreadyReportedWarningModal,
    openTransactionErrorModal,
    openTransactionSuccessModal,
  };
};
