import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPortalBySlugKey } from './keys';
import type { PortalView, MemberListItemView } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';

export const useGetPortalMemberMemosQuery = (
  portalView: Ref<PortalView>,
  memberView: Ref<MemberListItemView | undefined>,
  enabled: Ref<boolean>,
) => {
  const portalSlug = computed(() => portalView.value.slug);

  return useQuery({
    queryKey: [...getPortalBySlugKey(portalSlug), 'memberMemos'],
    queryFn: () => {
      if (!memberView.value) return;
      return dscvrIcApi.portal.getPortalMemberMemos(
        portalView.value.id,
        memberView.value.id,
      );
    },
    enabled,
  });
};
