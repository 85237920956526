import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useTokenGiftUserMutation = () => {
  return useMutation({
    mutationKey: ['token-gift-user-mutation'],
    mutationFn: (params: dscvrIcApi.user.TokenGiftUserParams) =>
      dscvrIcApi.user.tokenGiftUser(params),
  });
};
