import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { ethers } from 'ethers';
import { dscvrApi } from '@/shared/api';

export const useWalletStore = defineStore('wallet', () => {
  const currentPairingWallet = ref<dscvrApi.wallet.WalletType>();
  const providerEthers: Ref<ethers.providers.Web3Provider | undefined> = ref(); // this is "provider" for Ethers.js

  return {
    currentPairingWallet,
    providerEthers,
  };
});
