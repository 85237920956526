import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyWalletsQueryKey } from './keys';

export const useGetMyWalletsQuery = (enabled: Ref<boolean>) => {
  return useQuery({
    queryKey: getMyWalletsQueryKey,
    queryFn: async () => {
      const response = await dscvrApi.wallet.getMyWallets();
      return response ?? [];
    },
    enabled,
    initialData: [],
  });
};
