import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useAwardPostMutation = () => {
  return useMutation({
    mutationKey: ['award-post-mutation'],
    mutationFn: (params: dscvrIcApi.igc.AwardPostMutationParams) =>
      dscvrIcApi.igc.awardPost(params),
  });
};
