import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'virtual:svg-icons-register';
import VueGtag from 'vue-gtag';
import mitt from 'mitt';
import store, { ActionTypes } from '@/store';
import { devtools } from './devtools';
import { client } from '@/utils/dfinity';
import { sanitizeSettings, fromNow as dscvrFromNow, lang } from '@/utils';
import App from './App.vue';
import { createHead } from '@unhead/vue';
import './index.css';
import { createI18n } from 'vue-i18n';
import { en, es, zh, vi } from '../../locales';
import VueLazyLoad from 'vue3-lazyload';
import Vue3Sanitize from 'vue-3-sanitize';
import { BaseIcon } from '@/shared/ui/base-icon';
import { router } from './router';
import { getInitialAvatar, config } from '@/shared/lib';
import SolanaWallets from 'solana-wallets-vue';
import { Blank, Default, Profile, Settings, Development } from '@/layouts';
import {
  BLANK_LAYOUT,
  PROFILE_LAYOUT,
  SETTINGS_LAYOUT,
  DEFAULT_LAYOUT,
  DEVELOPMENT_LAYOUT,
} from '@/common';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { BaseButton } from '@/shared/ui/base-button';
import {
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query';
import { queryClient } from '@/shared/api';
import { getSolanaWalletOptions } from '@/entities/wallets';
import { createGtm } from '@gtm-support/vue-gtm';
import { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID } from '@/common';
import * as pkg from '../../package.json' assert { type: 'json' };
import type { RouteLocationNormalizedLoaded } from 'vue-router';

const emitter = mitt();

const i18n = createI18n({
  legacy: false,
  locale: lang.getQueryLang(),
  fallbackLocale: 'en',
  globalInjection: true,
  messages: { en, es, zh, vi },
});

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.mixin({
  methods: {
    fromNow(val: bigint, shortHand?: boolean) {
      return dscvrFromNow(val, shortHand);
    },
    fromNowSeconds(val: bigint) {
      return dscvrFromNow(val);
    },
    base64(str: string) {
      return window.btoa(str);
    },
    getAvatarIcon(username: string) {
      //get intials
      //https://ui-avatars.com/api/?background=2564eb&color=fff&name=Supernova
      return getInitialAvatar({
        name: username,
        size: 512,
        initial_bg: '#374151',
        initial_fg: '#fff',
        initial_size: 0,
        initial_weight: 400,
        initial_font_family: 'Inter',
      });
    },
    isValidUrl(url: string) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url,
      );
    },
    isImageUrl(url: string) {
      try {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      } catch {
        return false;
      }
    },
    isDataImage(url: string) {
      try {
        return url.includes('data:image');
      } catch {
        return false;
      }
    },
    isMarkDownImageUrl(url: string) {
      try {
        return url.match(/\.(jpeg|jpg|gif|png)\)$/) != null;
      } catch {
        return false;
      }
    },
    removeMarkdown(url: string) {
      const urlMatch = url.match(/\[([^\]]+)\]\(([^)]+(jpeg|jpg|gif|png))\)/);
      if (urlMatch) {
        return urlMatch[2];
      } else {
        return url;
      }
    },
    isFleekImage(url: string) {
      try {
        return (
          url.match(/fleek/) != null || url.match(/ipfs.dscvr.one/) != null
        );
      } catch {
        return false;
      }
    },
    checkShareIt(route: RouteLocationNormalizedLoaded) {
      const shareIt = {
        text: route.query.text,
        url: route.query.url,
        title: route.query.title,
        portal: route.query.portal,
      };

      if (shareIt.text != undefined || shareIt.url != undefined) {
        return shareIt;
      } else {
        return null;
      }
    },
  },
});

app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event: Event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (binding.value instanceof Function) {
          binding.value(event);
        }
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.component('base-icon', BaseIcon);
app.component('base-button', BaseButton);

// Layouts component
app.component(BLANK_LAYOUT, Blank);
app.component(DEFAULT_LAYOUT, Default);
app.component(PROFILE_LAYOUT, Profile);
app.component(SETTINGS_LAYOUT, Settings);
app.component(DEVELOPMENT_LAYOUT, Development);

app.use(store);
app.use(i18n);
app.use(
  VueGtag,
  {
    appName: `${pkg.name}-v${pkg.version}`,
    config: {
      id: GOOGLE_ANALYTICS_ID,
    },
  },
  router,
);
app.use(
  createGtm({
    id: GOOGLE_TAG_MANAGER_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    debug: import.meta.env.DEV, // Whether or not display console logs debugs (optional)
  }),
);
app.use(devtools);
app.use(VueLazyLoad, {});
app.use(Vue3Sanitize, sanitizeSettings);
app.use(VueQueryPlugin, {
  queryClient: queryClient,
} as VueQueryPluginOptions);

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
app.use(pinia);

await lang.setLanguage();
// wait for thc client to be created since we need it for core functionality for the rest of
// the startup
await client.create();
navigator.serviceWorker?.getRegistrations().then(function (registrations) {
  registrations.forEach(function (v) {
    v.unregister();
  });
});

app.use(createHead());

app.use(SolanaWallets, getSolanaWalletOptions());
app.use(VueVirtualScroller);

// need to wait for getting the user in order to gard the routes
await store.dispatch(`auth/${ActionTypes.GET_SELF}`);
app.use(router);
app.mount('#app');

Sentry.init({
  app,
  enabled: config.SENTRY_ENVIRONMENT !== 'local',
  dsn: config.SENTRY_URL,
  environment: config.SENTRY_ENVIRONMENT,
  tracePropagationTargets: [
    /^https:\/\/dscvr-frontend-staging\.onrender\.com\//,
    /^https:\/\/dscvr-frontend-beta-staging\.onrender\.com\//,
    /^https:\/\/dscvr\.one/,
  ],
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: Number(config.SENTRY_SAMPLE_RATE), // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: Number(config.SENTRY_SESSION_RATE), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: Number(config.SENTRY_ERROR_RATE), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
