<script setup lang="ts">
  import { computed } from 'vue';
  import { SOLANA_FM_PREFIX, DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia, shortenString } from '@/shared/lib';

  const props = defineProps<{
    signedTx: string;
  }>();

  defineEmits<{
    (e: 'close'): void;
    (e: 'report'): void;
  }>();

  const transactionDetailsUrl = computed(() => {
    return `${SOLANA_FM_PREFIX}${props.signedTx}`;
  });
</script>

<template>
  <div class="absolute w-[130%] -left-[15%] -top-[100%] md:-top-[160%] -z-1">
    <img
      v-lazy="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/frames/confetti.gif`)"
      alt="Party"
    />
  </div>
  <div
    class="flex flex-col items-center gap-10 p-6 pt-10 bg-gray-975 rounded-t-2xl md:bg-transparent md:border-none"
  >
    <div class="flex flex-col items-center gap-2">
      <h2 class="font-bold text-2xl">
        {{ $t('walletTransaction.transactionSuccessTitle') }}
      </h2>
      <base-button
        variant="link"
        size="small"
        target="_blank"
        additional-classes="hover:underline text-blue-400"
        :to="transactionDetailsUrl"
      >
        <span class="font-bold">{{ $t('frame.transactionId') }}:</span>
        <span>{{ shortenString(signedTx) }}</span>
      </base-button>
      <p class="font-bold text-xl text-gray-400">
        <span>{{ $t('walletTransaction.transactionSuccessMessage') }}</span>
      </p>
    </div>
    <div class="flex flex-col min-w-60 gap-2">
      <base-button variant="primary" size="small" @click="$emit('close')">
        <span>{{ $t('walletTransaction.great') }}</span>
      </base-button>
      <base-button
        variant="tertiary"
        size="small"
        additional-classes="text-red-350"
        @click="$emit('report')"
      >
        {{ $t('walletTransaction.reportTransaction') }}
      </base-button>
    </div>
  </div>
</template>
