<script lang="ts" setup>
  import type { PhantomReward } from 'dfx/edge/edge.did';
  import { useI18n } from 'vue-i18n';
  import { useUser } from '@/entities/user';
  import { usePostDialog } from '../post';

  const props = defineProps<{
    reward: PhantomReward;
  }>();

  const { t } = useI18n({
    useScope: 'global',
  });

  const { currentUser } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();

  const onShare = () => {
    const initialContent = `<p></p><embedded-gimluck-reward points="${Number(
      props.reward.reward,
    ).toString()}" username="${currentUser.value.username}" loss-message="${
      props.reward.loss_message.length
        ? props.reward.loss_message[0].toString()
        : ''
    }"></embedded-gimluck-reward>`;
    openCreateEditPostDialog({ initialContent });
  };
</script>

<template>
  <base-button variant="primary" size="medium" @click="onShare">
    <base-icon name="share" size="size-5" />
    <span>{{ t('share') }}</span>
  </base-button>
</template>
