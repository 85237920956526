import type { MaybeRef, Ref } from 'vue';
import type { Principal } from '@dfinity/principal';
import { useQuery } from '@tanstack/vue-query';
import { tokenManager } from '@/utils';
import type { IcpNonFungibleToken } from '../types';
import { convertResponseIcpNFT } from '../lib/convert-response';
import { getIcpNFTsQueryKey } from './keys';

export const useGetIcpNFTsQuery = (
  principal: Ref<Principal | undefined>,
  enabled: MaybeRef<boolean> = true,
) => {
  return useQuery({
    queryKey: getIcpNFTsQueryKey(principal),
    queryFn: async (): Promise<IcpNonFungibleToken[]> => {
      if (!principal.value) return [];
      return tokenManager.getUserNFTs(principal.value);
    },
    select: convertResponseIcpNFT,
    initialData: [],
    enabled,
  });
};
