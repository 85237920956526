import axios, { type AxiosResponse } from 'axios';
import type {
  TranslateQueryParams,
  TranslateDto,
  IframelyResponse,
} from './types';
export type { TranslateQueryParams };

const BASE_URL = 'https://enrich.dscvr.one';

export const translate = (
  params: TranslateQueryParams,
): Promise<AxiosResponse<TranslateDto>> => {
  return axios
    .post(`${BASE_URL}/translate`, {
      text: params.text,
      lang: params.lang,
    })
    .then((res) => res.data);
};

export const enrichUrl = (url: string): Promise<IframelyResponse> => {
  return axios
    .get(
      `https://enrich.dscvr.one/process?url=${url}&lang=${navigator.language}&host=${location.host}`,
    )
    .then((res) => res.data);
};
