import { client } from '../utils/dfinity';

export default {
  async get_next_reward() {
    return await client.actor.get_next_reward();
  },
  async claim_reward() {
    return await client.actor.claim_reward();
  },
};
