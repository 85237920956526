import Image from '@tiptap/extension-image';

const ExtendedImage = Image.extend({
  addAttributes() {
    return {
      loading: {
        default: 'lazy',
      },
      src: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'v-lazy': `{ src: '${attributes.src}', loading: '', error: '', delay: 333 }`,
            src: attributes.src,
          };
        },
      },
      alt: {
        default: null,
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ['img', HTMLAttributes];
  },
});

export default ExtendedImage;
