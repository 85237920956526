<script setup lang="ts">
  import { useBreakpoint } from '@/shared/model';
  import type { UserView } from 'dfx/edge/edge.did';
  import { USER_CONTENT } from '@/common';
  import { useUser } from '../model/composables/use-user';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { coverGradients } from '../lib/cover-gradients';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  defineProps<{
    user: UserView;
  }>();

  const { getFormattedTokens } = useUser();
  const { isSmallerThanSm } = useBreakpoint();
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: USER_CONTENT,
      params: { username: user.username },
    }"
    custom-classes="flex flex-col justify-between portrait w-full rounded-xl p-4 drop-shadow-light border border-gray-800 relative overflow-hidden group before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/10 before:to-black/90 before:z-1 max-h-60"
  >
    <div
      class="absolute bg-cover bg-center inset-0 group-hover:-inset-4 transition-all duration-500"
      :class="{
        'group-hover:hue-rotate-180': !user.cover_photo[0],
      }"
    >
      <cover-photo :src="user.cover_photo[0]" :gradients="coverGradients" />
    </div>
    <div class="flex justify-end z-1">
      <follow-user-button
        size="x-small"
        :user="user"
        follow-class="drop-shadow-light"
      />
    </div>
    <div class="flex items-center z-1 gap-2">
      <user-avatar
        v-if="user"
        :item="user"
        :size="isSmallerThanSm ? 'w-8 h-8' : 'w-10 h-10'"
      />
      <div class="flex flex-col min-w-0 gap-2 text-white">
        <span class="font-medium leading-5 truncate" :title="user.username">
          {{ user.username }}
        </span>
        <div class="flex items-center gap-2">
          <base-icon name="dscvr" size="w-3 h-3" />
          <span class="text-xs">
            {{ getFormattedTokens(user.rights, true) }}
          </span>
        </div>
      </div>
    </div>
  </base-button>
</template>

<style scoped>
  .portrait {
    aspect-ratio: 1 / 1.5;
  }
</style>
