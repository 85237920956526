<script setup lang="ts">
  import { computed, toRef } from 'vue';
  import { useRoute } from 'vue-router';
  import {
    EXPLORE_PORTALS_FEATURED,
    PORTAL_CONTENT,
    EXPLORE_PEOPLE_FEATURED,
    USER_CONTENT,
  } from '@/common/route-names';
  import {
    useUser,
    useGetRandomizedRecommendedUsersQuery,
  } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import {
    getPortalIcon,
    useGetRandomizedRecommendedPortalsQuery,
  } from '@/entities/portal';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';

  const props = withDefaults(
    defineProps<{
      count?: number;
    }>(),
    {
      count: 5,
    },
  );

  const route = useRoute();
  const { currentUser } = useUser();
  const { contentPreset } = useChainBehavior();

  const queryParams = computed<RandomizedRecommendationQuery>(() => ({
    filter_tags: [['hotspot']],
    count: props.count,
    content_preset: contentPreset.value,
  }));

  const { data: peopleHotspots } =
    useGetRandomizedRecommendedUsersQuery(queryParams);

  const { data: portalHotspots } =
    useGetRandomizedRecommendedPortalsQuery(queryParams);

  const portals = computed(() =>
    portalHotspots.value?.map((portal) => ({
      portal,
      isSelected: route.params.slug === portal.slug,
    })),
  );

  const users = computed(() =>
    peopleHotspots.value?.map((user) => ({
      user,
      isSelected:
        user.username.toLowerCase() ===
        (route.params.username as string)?.toLowerCase(),
    })),
  );
</script>

<template>
  <article class="flex flex-col gap-9 pt-0.5 pr-5">
    <section>
      <header
        class="mb-5 ml-2 text-gray-400 group"
        :class="{
          'hover:text-white': currentUser,
        }"
      >
        <base-button
          variant="link"
          :to="{
            name: EXPLORE_PORTALS_FEATURED,
          }"
          class="flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <base-icon name="outline-portal" size="w-5 h-5" />
            <span>{{ $t('portals') }}</span>
          </div>
          <base-icon
            name="vuesax-linear-arrow-right"
            class="invisible group-hover:visible"
          />
        </base-button>
      </header>
      <ul class="flex flex-col gap-2">
        <template v-if="portals && !portals.length">
          <li
            v-for="i in count"
            :key="i"
            class="flex gap-2.5 items-center w-full p-2"
          >
            <div
              class="w-8 h-8 bg-gray-600 rounded-lg bg-opacity-40 animate-pulse"
            ></div>
            <div
              class="flex-1 h-6 bg-gray-600 rounded-md bg-opacity-40 animate-pulse"
            ></div>
          </li>
        </template>
        <template v-else>
          <li v-for="{ portal, isSelected } in portals" :key="portal.slug">
            <base-button
              variant="link"
              :to="{
                name: PORTAL_CONTENT,
                params: { slug: portal.slug },
              }"
              custom-classes="flex gap-2.5 items-center hover:text-white rounded-l-xl p-2"
              class="font-medium text-gray-300 hover:text-white hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
              :class="{
                'font-semibold bg-gradient-to-r from-indigo-850 via-transparent to-transparent hover:bg-transparent':
                  isSelected,
              }"
            >
              <img
                :src="getPortalIcon(portal)"
                class="rounded-lg shadow-lg size-8"
              />
              <span
                class="text-sm font-medium leading-5 break-all truncate"
                :title="portal.name"
              >
                {{ portal.name }}
              </span>
              <base-icon
                v-if="portal.is_nsfw"
                name="eighteen-plus"
                size="w-4 h-4"
              />
            </base-button>
          </li>
        </template>
      </ul>
    </section>

    <section>
      <header class="mb-5 ml-2 text-gray-400 group hover:text-white">
        <base-button
          variant="link"
          :to="
            currentUser
              ? {
                  name: EXPLORE_PEOPLE_FEATURED,
                  params: { username: currentUser.username },
                }
              : undefined
          "
          :class="{
            'cursor-default': !currentUser,
          }"
          class="flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <base-icon name="vuesax-linear-profile-2user" size="w-5 h-5" />
            <span>{{ $t('people') }}</span>
          </div>
          <base-icon
            name="vuesax-linear-arrow-right"
            class="invisible"
            :class="{
              'group-hover:visible': currentUser,
            }"
          />
        </base-button>
      </header>
      <ul class="flex flex-col gap-2">
        <template v-if="portals && !portals.length">
          <li
            v-for="i in count"
            :key="i"
            class="flex gap-2.5 items-center w-full p-2"
          >
            <div
              class="w-8 h-8 bg-gray-600 rounded-full bg-opacity-40 animate-pulse"
            ></div>
            <div
              class="flex-1 h-6 bg-gray-600 rounded-md bg-opacity-40 animate-pulse"
            ></div>
          </li>
        </template>
        <template v-else>
          <li v-for="{ user, isSelected } in users" :key="user.username">
            <base-button
              variant="link"
              :to="{
                name: USER_CONTENT,
                params: { username: user.username },
              }"
              custom-classes="flex items-center hover:text-white rounded-l-xl p-2"
              class="font-medium text-gray-300 hover:text-white hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
              :class="{
                'font-semibold bg-gradient-to-r from-indigo-850 via-transparent to-transparent hover:bg-transparent':
                  isSelected,
              }"
            >
              <user-avatar
                v-if="user"
                :item="user"
                size="w-8 h-8"
                class="mr-2"
              />
              <span
                class="text-sm font-medium leading-5 break-all truncate"
                :title="user.username"
              >
                {{ user.username }}
              </span>
            </base-button>
          </li>
        </template>
      </ul>
    </section>
  </article>
</template>
