<script setup lang="ts">
  import { computed, toRefs, onMounted } from 'vue';
  import { uniq, compact } from 'lodash-es';
  import { useEmbeddedMention, type ComputedEmbed } from '@/composables';
  import { HorizontalSlide } from '@/components/shared/horizontal-slide';
  import {
    EmbeddedPortal,
    EmbeddedPost,
    EmbeddedNft,
    EmbeddedStreak,
    EmbeddedGimluckReward,
  } from '@/components/tiptap';
  import { EmbeddedFrame } from '@/features/frame';
  import { EmbeddedCanvas } from '@/features/canvas';
  import { config } from '@/shared/lib';

  const props = defineProps<{
    embedList: ComputedEmbed[];
  }>();

  const { embedList } = toRefs(props);
  const { portalsHash, fetchPortals } = useEmbeddedMention();

  const uniquePortals = computed(() =>
    getUniqueRefs(embedList.value.filter(({ type }) => type === 'portal')),
  );

  /**
   *
   * @param embedded
   */
  function getUniqueRefs(embedded: ComputedEmbed[]): string[] {
    return compact(
      uniq(
        embedded.map(({ ref }) => {
          if (ref) {
            return ref;
          }
          return undefined;
        }),
      ),
    );
  }

  onMounted(() => {
    fetchPortals(uniquePortals.value);
  });
</script>

<template>
  <div @click.stop>
    <div v-if="embedList.length === 1">
      <template v-if="embedList[0].type === 'post'">
        <embedded-post :content-id="embedList[0].ref" @click.stop />
      </template>
      <template v-else-if="embedList[0].type === 'portal'">
        <embedded-portal
          v-if="portalsHash.has(embedList[0].ref)"
          :portal-view="portalsHash.get(embedList[0].ref)!"
          @click.stop
        />
      </template>
      <template v-else-if="embedList[0].type === 'nft'">
        <embedded-nft :nft-id="embedList[0].ref" @click.stop />
      </template>
      <template v-else-if="embedList[0].type === 'streak'">
        <embedded-streak
          :days="embedList[0].days"
          :multiplier="embedList[0].multiplier"
          :points="embedList[0].points"
          :username="embedList[0].username"
        />
      </template>
      <template v-else-if="embedList[0].type === 'gimluck'">
        <embedded-gimluck-reward
          :points="embedList[0].points"
          :username="embedList[0].username"
          :loss-message="embedList[0].lossMessage"
        />
      </template>
      <template
        v-else-if="config.ENABLE_FRAMES && embedList[0].type === 'frame'"
      >
        <embedded-frame :url="embedList[0].url" />
      </template>
      <template v-else-if="embedList[0].type === 'canvas'">
        <embedded-canvas :url="embedList[0].url" />
      </template>
    </div>
    <horizontal-slide
      v-else-if="embedList.length > 1"
      centered
      :count="embedList.length"
      class="h-[280px] sm:h-[326px] lg:h-[434px] xl:h-[500px]"
      item-width-class="squared xl:w-[700px]"
    >
      <template
        v-for="(embed, index) in embedList"
        :key="`item-${index + 1}`"
        #[`item-${index+1}`]
      >
        <div class="flex justify-center size-full">
          <template v-if="embed.type === 'post'">
            <embedded-post :content-id="embed.ref" @click.stop />
          </template>
          <template v-else-if="embed.type === 'portal'">
            <embedded-portal
              v-if="portalsHash.has(embed.ref)"
              :portal-view="portalsHash.get(embed.ref)!"
              @click.stop
            />
          </template>
          <template v-else-if="embed.type === 'nft'">
            <embedded-nft :nft-id="embed.ref" @click.stop />
          </template>
          <template v-else-if="embed.type === 'streak'">
            <embedded-streak
              :days="embed.days"
              :multiplier="embed.multiplier"
              :points="embed.points"
              :username="embed.username"
            />
          </template>
          <template v-else-if="embed.type === 'gimluck'">
            <embedded-gimluck-reward
              :points="embed.points"
              :username="embed.username"
              :loss-message="embed.lossMessage"
            />
          </template>
          <template v-else-if="config.ENABLE_FRAMES && embed.type === 'frame'">
            <embedded-frame :url="embed.url" in-carousel />
          </template>
          <template v-else-if="embed.type === 'canvas'">
            <embedded-canvas :url="embed.url" in-carousel />
          </template>
        </div>
      </template>
    </horizontal-slide>
  </div>
</template>
