<script setup lang="ts">
  import { computed } from 'vue';
  import { getFallbackIcon } from '@/shared/lib';
  import type { LiveChatMessage } from '../model/types';

  interface LiveChatMessageProps {
    message: LiveChatMessage;
  }

  const props = defineProps<LiveChatMessageProps>();

  const sentByCurrentUser = computed(() => {
    return props.message?.senderName === 'You';
  });

  const userIconUrl = computed(() => {
    if (!props.message.sender) {
      return getFallbackIcon(props.message.senderName || '');
    }
    if (!props.message.sender.icon)
      return getFallbackIcon(props.message.sender.username);
    return props.message.sender.icon;
  });
</script>

<template>
  <div>
    <div
      v-if="message.sender"
      class="flex py-1 items-center mb-1"
      :class="{
        'justify-end text-right': sentByCurrentUser,
        'justify-start text-left': !sentByCurrentUser,
      }"
    >
      <img
        v-show="!sentByCurrentUser"
        class="rounded-full w-8 h-8 border-2 border-gray-750"
        :alt="message.sender.username"
        :src="userIconUrl"
      />
      <div v-if="!sentByCurrentUser" class="text-xs ml-2">
        {{ message.sender.username }}
      </div>
      <div class="text-xs text-gray-300 pl-2">
        {{ message.formattedTime }}
      </div>
    </div>
    <div
      :class="{
        'flex justify-end': sentByCurrentUser,
        'flex justify-start': !sentByCurrentUser,
      }"
    >
      <div
        class="max-w-[67%] p-4"
        :class="{
          'bg-[#639CF1] bg-opacity-60 text-white rounded-tl-[24px] rounded-tr-[24px] rounded-bl-[24px] p-3 max-w-xs':
            sentByCurrentUser,
          'bg-[#13171F] text-gray-100 rounded-bl-[24px] rounded-tr-[24px] rounded-br-[24px] p-3 max-w-xs':
            !sentByCurrentUser,
        }"
      >
        <p class="text-xs max-w-full break-words tracking-wide">
          {{ message.message }}
        </p>
      </div>
    </div>
  </div>
</template>
