import { createStore } from 'vuex';
import { auth } from '@/store/modules/auth';
import { users } from '@/store/modules/users';
import { root } from '@/store/modules/root';
import portals from './modules/portals';
import posts from './modules/posts';
import reports from './modules/reports';
import nfts from './modules/nfts';
import gating from './modules/gating';
import ubi from './modules/ubi';
import arcade from './modules/arcade';

// TODO: add typings for all states
// import type { AuthState } from '@/store/modules/auth';
// interface State {
//   auth: AuthState;
// }
// export default createStore<State>({

export default createStore({
  modules: {
    auth,
    portals,
    posts,
    reports,
    gating,
    ubi,
    nfts,
    users,
    arcade,
  },
  ...root,
});

export { ActionTypes } from './action-types';
export { MutationTypes } from './mutation-types';
export * from './stores';
