import { computed, ref, type Ref } from 'vue';
import type { Principal } from '@dfinity/principal';
import { dscvrApi } from '@/shared/api';
import { useDevice } from '@/shared/model';
import { generateMobileDeeplink } from '../../lib/deep-linking';
import { useVerifyUserMutation } from '../../api/use-verify-user.mutation';
import { useGetOrCreateUserMutation } from '../../api/use-get-or-create-user.mutation';
import { useGetMyOtpCodeQuery } from '../../api/use-get-my-otp-code.query';

export const useGenerateMobileDeeplink = (
  principal: Ref<Principal | undefined>,
) => {
  const walletType = ref<dscvrApi.wallet.WalletType>();
  const nonce = ref<string>();
  const otpGenerationEnabled = ref(false);

  const { mutateAsync: verifyUser } = useVerifyUserMutation();
  const { mutateAsync: getOrCreateUser } = useGetOrCreateUserMutation();
  const { data: otpCode } = useGetMyOtpCodeQuery(otpGenerationEnabled);

  const { isMobileOrTablet } = useDevice();

  const deepLink = computed(() => {
    if (
      !walletType.value ||
      !otpCode.value ||
      !nonce.value ||
      !principal.value
    ) {
      return undefined;
    }
    return generateMobileDeeplink(walletType.value, {
      dscvrPk: principal.value.toText(),
      nonce: nonce.value,
      otpCode: otpCode.value,
    });
  });

  const restartGeneration = async (value: dscvrApi.wallet.WalletType) => {
    if (!isMobileOrTablet.value) {
      throw new Error('Cannot generate deeplink on desktop');
    }
    walletType.value = value;
    await verifyUser();
    nonce.value = await getOrCreateUser();
    otpGenerationEnabled.value = true;
  };

  return {
    restartGeneration,
    deepLink,
  };
};
