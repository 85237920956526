<script setup lang="ts">
  import { RowItem } from './components';
  import { useI18n } from 'vue-i18n';
  import type { UserView } from 'dfx/edge/edge.did';
  import {
    BaseTableView,
    BaseTableHeaderCell,
    type SortColumnDirection,
    useSorting,
  } from '@/shared/ui/base-table-view';
  import { userSortFieldTypes, type UserSortField } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';

  const props = defineProps<{
    users: UserView[];
    loading: boolean;
    sortBy: UserSortField;
    sortDirection: SortColumnDirection;
  }>();

  const emit = defineEmits<{
    (e: 'update:sortBy', value: UserSortField): void;
    (e: 'update:sortDirection', value: SortColumnDirection): void;
    (e: 'sortChanged'): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const { sortFields, changeSort } = useSorting(userSortFieldTypes, {
    defaultField: props.sortBy,
    defaultDirection: props.sortDirection,
    onChange: (field, direction) => {
      emit('update:sortBy', field);
      emit('update:sortDirection', direction);
      emit('sortChanged');
    },
  });

  const sortColumns: {
    label: string;
    widthClass: string;
    sortField?: UserSortField;
    icon?: string;
  }[] = [
    {
      label: t('user'),
      widthClass: 'w-50',
      sortField: 'name',
    },
    {
      label: t('about'),
      widthClass: 'w-full',
    },
    {
      label: t('followers'),
      widthClass: 'w-28',
      sortField: 'followingCount',
    },
    {
      label: t('points'),
      widthClass: 'w-28',
      sortField: 'pointCount',
      icon: 'dscvr',
    },
    {
      label: t('streak.streak'),
      widthClass: 'w-28',
      sortField: 'activeStreak',
    },
    {
      label: '',
      widthClass: 'w-28',
    },
  ];
</script>

<template>
  <base-table-view :items="users">
    <template #header>
      <template v-for="sortColumn in sortColumns" :key="sortColumn.sortField">
        <base-table-header-cell
          v-if="sortColumn.sortField"
          :class="sortColumn.widthClass"
          :sort-active="sortFields.get(sortColumn.sortField)?.active"
          :sort-direction="sortFields.get(sortColumn.sortField)?.direction"
          @update:sort-direction="changeSort(sortColumn.sortField, $event)"
        >
          <template #sortLabel>
            <template v-if="sortColumn.icon">
              <div class="flex gap-2 items-center">
                <base-icon :name="sortColumn.icon" size="w-4 h-4" />
                {{ sortColumn.label }}
              </div>
            </template>
            <template v-else>
              {{ sortColumn.label }}
            </template>
          </template>
        </base-table-header-cell>
        <base-table-header-cell v-else :class="sortColumn.widthClass">
          {{ sortColumn.label }}
        </base-table-header-cell>
      </template>
    </template>
    <template #item="{ item }">
      <row-item :user="item" />
    </template>
  </base-table-view>

  <div v-if="loading" class="relative w-full h-48">
    <loader variant="rainbow" border-width="border" size="w-10 h-10" />
  </div>
</template>
