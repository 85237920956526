import { isEqual } from 'lodash';
import type { ContentReaction } from 'dfx/edge/edge.did';
import type { ReactionCount } from '../types';

export const setReactionCount = (
  reactionCount: ReactionCount[],
  newContentReaction: ContentReaction | undefined,
  previousContentReaction: ContentReaction | undefined,
): ReactionCount[] => {
  let found = false;
  const reactionCountCopy: ReactionCount[] = reactionCount.map((reaction) => {
    const [currentContentReaction, count] = reaction;
    let updatedCount = count;
    const isNewContentReaction = isEqual(
      newContentReaction,
      currentContentReaction,
    );
    const isOldContentReaction = isEqual(
      currentContentReaction,
      previousContentReaction,
    );
    if (isOldContentReaction) {
      updatedCount = count > 0n ? count - 1n : 0n;
    }
    if (isNewContentReaction) {
      found = true;
      updatedCount = count + 1n;
    }
    return [currentContentReaction, updatedCount];
  });

  if (!found && newContentReaction) {
    reactionCountCopy.push([newContentReaction, 1n]);
  }

  return reactionCountCopy;
};
