<script setup lang="ts">
  import { computed, onMounted, ref, type Ref } from 'vue';
  import { useAsyncState, useScroll } from '@vueuse/core';
  import { dscvrApi, solApi } from '@/shared/api';
  import { useNFTGating } from '@/entities/nft-gate';
  import { useUser } from '@/entities/user';
  import { Loader } from '@/shared/ui/loader';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useWalletPairing } from '@/entities/wallets';
  import { usePairDialog } from '@/features/wallets';
  import MagicEdenNftCollectionItemCard from './MagicEdenNftCollectionItemCard.vue';
  import { useMagicEdenNft } from '../model/composables/use-magic-eden-nft';
  import { useAuth } from '@/entities/auth';
  import type { PortalView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    portalView: PortalView;
    collectionGate: dscvrApi.multichainGating.MultichainGateDto;
    collectionDetail: dscvrApi.multichainGating.MultichainCollectionDetailDto;
    stats: solApi.collection.MagicEdenCollectionsStats;
  }>();

  defineEmits<{
    (e: 'back'): void;
  }>();

  const PAGE_SIZE = 20;
  const page = ref<number>(0);
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { openAddWalletDialog } = usePairDialog();
  const { openBuyNftDialog } = useMagicEdenNft();
  const { getNftCollectionListings, isGatedNftCollection } = useNFTGating();
  const { arrivedState } = useScroll(document, {
    onScroll: () => {
      if (
        arrivedState.bottom &&
        !isLoading.value &&
        page.value < totalPages.value
      ) {
        loadNextPage();
      }
    },
  });

  const listings: Ref<solApi.collection.MagicEdenAuctionData[]> = ref([]);
  const purchasedItems: Ref<Map<string, boolean>> = ref(new Map());
  const sortColumnDirection = ref<'asc' | 'desc'>('asc');

  const totalPages = computed(() => {
    return Math.ceil(props.stats.listedCount / PAGE_SIZE);
  });

  const { isLoading, execute: loadNextPage } = useAsyncState(async () => {
    page.value++;
    const result = await getNftCollectionListings(
      props.collectionDetail,
      page.value,
      PAGE_SIZE,
      'listPrice',
      sortColumnDirection.value,
    );
    listings.value = [...listings.value, ...result];
    return listings.value;
  }, []);

  const showBuyDialog = (listing: solApi.collection.MagicEdenAuctionData) => {
    openBuyNftDialog({
      listing,
      purchased: () => purchasedItems.value.set(listing.pdaAddress, true),
    });
  };

  const openBuyDialogModal = async (
    item: solApi.collection.MagicEdenAuctionData,
  ) => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    } else if (!firstPairedSolanaWallet.value) {
      const added = await openAddWalletDialog();
      if (added) {
        showBuyDialog(item);
      }
    } else {
      showBuyDialog(item);
    }
  };

  const sort = (sortDirection: 'asc' | 'desc') => {
    sortColumnDirection.value = sortDirection;
    page.value = 0;
    listings.value = [];
    loadNextPage();
  };

  onMounted(() => {
    fetchPairedWallets();
  });
</script>

<template>
  <div class="flex flex-col w-full">
    <div
      class="flex flex-col gap-4 sm:gap-0 sm:flex-row items-start sm:items-center justify-between mb-2 px-4 lg:p-0"
    >
      <base-button
        variant="custom"
        custom-classes="flex gap-1.5 text-base font-medium items-center md:flex-1 w-full sm:w-auto"
        @click="$emit('back')"
      >
        <base-icon
          name="outlined-arrow-left"
          size="w-5 h-5"
          class="text-gray-300"
        />
        <span>{{ collectionDetail.name }}</span>
        <span
          v-if="isGatedNftCollection(portalView, collectionGate)"
          class="hidden sm:block ml-4 text-gray-300 text-sm"
        >
          {{ $t('marketplaceCollectionItemLegend') }}
        </span>
      </base-button>
      <div class="flex items-center w-full sm:w-auto">
        <span class="sm:hidden text-gray-300 text-sm flex-1">{{
          $t('marketplaceCollectionItemLegend')
        }}</span>

        <base-dropdown
          append-to-body
          custom-content-classes="flex flex-col border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden "
        >
          <template #button="{ isVisible, toggle }">
            <base-button variant="tertiary" size="small" @click="toggle">
              <base-icon name="vuesax-bulk-arrow-3" size="w-5 h-5" />
              <span class="capitalize">{{ $t('sort') }}</span>
              <base-icon
                :name="isVisible ? 'chevron-up-filled' : 'chevron-down-filled'"
                size="w-5 h-5"
              />
            </base-button>
          </template>
          <template #content="{ hide }">
            <base-button
              variant="custom"
              size="small"
              :custom-classes="
                sortColumnDirection == 'asc'
                  ? 'w-full px-4 py-3 text-left text-white font-bold'
                  : 'w-full px-4 py-3 text-left text-gray-300'
              "
              @click="hide(), sort('asc')"
            >
              Price asc
            </base-button>
            <base-button
              variant="custom"
              size="small"
              :custom-classes="
                sortColumnDirection == 'desc'
                  ? 'w-full px-4 py-3 text-left text-white font-bold'
                  : 'w-full px-4 py-3 text-left text-gray-300'
              "
              @click="hide(), sort('desc')"
            >
              Price desc
            </base-button>
          </template>
        </base-dropdown>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
      <magic-eden-nft-collection-item-card
        v-for="item in listings"
        :key="item.pdaAddress"
        :item="item"
        :purchased="purchasedItems.get(item.pdaAddress)"
        @buy="openBuyDialogModal(item)"
      />
    </div>
    <div v-if="isLoading" class="relative w-full min-h-32">
      <loader variant="rainbow" border-width="border" size="w-10 h-10" />
    </div>
  </div>
</template>
