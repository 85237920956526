<script setup lang="ts">
  import { computed, ref, watch, type Ref } from 'vue';
  import Draggable from 'vuedraggable';
  import { useBreakpoint } from '@/shared/model';
  import type { DraggableChangeEvent } from '@/shared/model';
  import { Drawer } from '@/shared/ui/drawer';
  import { Badge } from '@/shared/ui/badge';
  import { usePortalUserStatus } from '@/entities/portal';
  import { JoinedPortalListItem } from '.';
  import type { PortalView } from 'dfx/edge/edge.did';

  const TOP_COUNT = 10;

  const props = defineProps<{
    portals: PortalView[];
    filtered: boolean;
  }>();

  const mobileDragEnabled = ref(false);
  const mobileDragChanged = ref(false);
  const localSortedPortals: Ref<PortalView[]> = ref([]);

  const { isSmallerThanSm } = useBreakpoint();
  const { updateSortedFavoritePortals } = usePortalUserStatus();

  const topFavorites = computed(() =>
    props.filtered
      ? localSortedPortals.value
      : localSortedPortals.value.slice(0, TOP_COUNT),
  );

  const otherFavorites = computed(() =>
    props.filtered ? [] : localSortedPortals.value.slice(TOP_COUNT),
  );

  const movePortal = (portal: PortalView, index: number) => {
    localSortedPortals.value = localSortedPortals.value.filter(
      (p) => p.slug !== portal.slug,
    );
    localSortedPortals.value.splice(index, 0, portal);
    if (!mobileDragEnabled.value) {
      updateSortedFavoritePortals(localSortedPortals.value);
    } else {
      mobileDragChanged.value = true;
    }
  };

  const topChanged = (event: DraggableChangeEvent<PortalView>) => {
    if (event.added) {
      movePortal(event.added.element, event.added.newIndex);
    } else if (event.moved) {
      movePortal(event.moved.element, event.moved.newIndex);
    }
  };

  const otherChanged = (event: DraggableChangeEvent<PortalView>) => {
    if (event.added) {
      movePortal(
        event.added.element,
        topFavorites.value.length + event.added.newIndex,
      );
    } else if (event.moved) {
      movePortal(
        event.moved.element,
        topFavorites.value.length + event.moved.newIndex,
      );
    }
  };

  const applyChanges = () => {
    mobileDragChanged.value = false;
    mobileDragEnabled.value = false;
    updateSortedFavoritePortals(localSortedPortals.value);
  };

  watch(
    () => props.portals,
    (value) => {
      localSortedPortals.value = value;
    },
    { immediate: true },
  );

  watch(
    () => props.filtered,
    () => {
      mobileDragChanged.value = false;
      mobileDragEnabled.value = false;
    },
  );
</script>

<template>
  <transition
    enter-active-class="animate-flip-down animate-duration-300"
    leave-active-class="animate-flip-down animate-duration-300 animate-reverse"
  >
    <section
      v-if="!filtered && isSmallerThanSm && !mobileDragEnabled"
      class="flex justify-end gap-2 mb-6"
    >
      <base-button
        variant="secondary"
        size="x-small"
        @click="mobileDragEnabled = true"
      >
        <base-icon name="vuesax-broken-arrow-swap" size="w-6 h-6" />
        {{ $t('favoritePortals.reorder') }}
      </base-button>
    </section>
  </transition>

  <h3
    v-if="!filtered"
    class="flex items-center gap-2 text-sm text-gray-400 mb-6"
  >
    {{ $t('favoritePortals.topFavorited') }}
    <badge>{{ $t('top') }} {{ topFavorites.length }}</badge>
  </h3>
  <draggable
    item-key="slug"
    group="a"
    class="flex flex-col gap-4"
    ghost-class="opacity-50"
    tag="ul"
    :list="topFavorites"
    :handle="mobileDragEnabled ? '.mobile-drag-handle' : '.drag-handle'"
    :animation="250"
    @change="topChanged"
  >
    <template #item="{ element: portal }">
      <li>
        <joined-portal-list-item
          animate-unfavorite
          :key="portal.slug"
          :draggable="
            !props.filtered && (!isSmallerThanSm || mobileDragEnabled)
          "
          :portal-view="portal"
          :class="{ 'mobile-drag-handle': mobileDragEnabled }"
        />
      </li>
    </template>
  </draggable>

  <template v-if="otherFavorites.length">
    <h3 class="flex items-center gap-2 text-sm text-gray-400 mb-6 mt-10">
      {{ $t('favoritePortals.otherFavorites') }}
      <badge>{{ otherFavorites.length }}</badge>
    </h3>
    <draggable
      item-key="slug"
      group="a"
      class="flex flex-col gap-4"
      ghost-class="opacity-50"
      tag="ul"
      :list="otherFavorites"
      :handle="mobileDragEnabled ? '.mobile-drag-handle' : '.drag-handle'"
      :animation="250"
      @change="otherChanged"
    >
      <template #item="{ element: portal }">
        <li>
          <joined-portal-list-item
            animate-unfavorite
            :key="portal.slug"
            :draggable="
              !props.filtered && (!isSmallerThanSm || mobileDragEnabled)
            "
            :portal-view="portal"
            :class="{ 'mobile-drag-handle': mobileDragEnabled }"
          />
        </li>
      </template>
    </draggable>
  </template>

  <drawer
    direction="bottom"
    content-classes="bg-gray-975 p-4 min-h-30 justify-center items-center"
    :show-header="false"
    :close-on-click-outside="false"
    :show-backdrop="false"
    :opened="mobileDragEnabled"
  >
    <base-button
      v-if="mobileDragChanged"
      variant="primary"
      size="small"
      additional-classes="capitalize"
      @click="applyChanges"
    >
      {{ $t('favoritePortals.applyChanges') }}
    </base-button>
    <base-button
      v-else
      variant="secondary"
      size="small"
      @click="mobileDragEnabled = false"
    >
      {{ $t('cancel') }}
    </base-button>
  </drawer>
</template>
