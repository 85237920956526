import anime from 'animejs/lib/anime.es.js';
import party from 'party-js';

export const animateLootboxItem = (displayRef: HTMLImageElement) => {
  anime({
    targets: displayRef,
    translateY: 0,
    direction: 'alternate',
    easing: 'easeInOutExpo',
    rotate: 360,
    duration: 1000,
    scale: 1.8,
    begin: () => {
      party.sparkles(displayRef, {
        count: party.variation.range(5, 30),
        size: party.variation.range(0.5, 3),
        speed: 300,
        lifetime: party.variation.range(0.1, 0.5),
      });
    },
  });
};
