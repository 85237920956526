export class NFT {
  constructor(args) {
    if (!Array.isArray(args)) {
      args = [args];
    }

    Object.assign(this, ...args);
  }

  addRegistryData(args) {
    if (!Array.isArray(args)) {
      args = [args];
    }

    Object.assign(this, ...args);
  }

  getUniqueId() {
    return `${this.cid}:${this.tokenIndex}`;
  }
}
