import { storeToRefs } from 'pinia';
import { useBreakpointsStore } from '../store/breakpoints/store';

export const useBreakpoint = () => {
  const breakpointsStore = useBreakpointsStore();
  const {
    breakpoints,
    isSmallerThanXl,
    isSmallerThanLg,
    isSmallerThanMd,
    isSmallerThanSm,
    isGreaterThanSm,
  } = storeToRefs(breakpointsStore);

  return {
    breakpoints,
    isSmallerThanXl,
    isSmallerThanLg,
    isSmallerThanMd,
    isSmallerThanSm,
    isGreaterThanSm,
  };
};
