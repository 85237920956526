<script setup lang="ts">
  import { computed, onMounted, ref, watch, type Ref } from 'vue';
  import { useSticky } from '@/composables';
  import {
    useDocumentScroll,
    userSortFieldTypes,
    type UserSortField,
  } from '@/shared/model';
  import { BaseCollectionView } from '@/shared/ui/base-collection-view';
  import SearchForm from './SearchForm.vue';
  import SortSwitch from './SortSwitch.vue';
  import ViewSwitch from './ViewSwitch.vue';
  import type { ViewType } from '../types';
  import {
    UserGridItemLandscape,
    UserListItem,
    UserTableView,
    useUser,
  } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { EXPLORE_PEOPLE_FEATURED } from '@/common';
  import { type SortColumnDirection } from '@/shared/ui/base-table-view';
  import { getSortDirection, getUserSortBy } from '@/shared/lib';

  const INITIAL_PAGES = BigInt(5);
  const props = withDefaults(
    defineProps<{
      showSearch: boolean;
      pageSize?: number;
    }>(),
    {
      pageSize: 8,
    },
  );

  defineEmits<{
    (e: 'back'): void;
  }>();

  const stickyRef = ref<HTMLElement | null>(null);
  const contentRef = ref<HTMLElement | null>(null);
  const viewType = ref<ViewType>('table');
  const sortBy: Ref<UserSortField> = ref('pointCount');
  const sortDirection: Ref<SortColumnDirection> = ref('desc');
  const loading = ref(false);
  const loadingFirstTime = ref(false);

  const { isStickyActive } = useSticky(stickyRef, contentRef);
  const {
    allUsersPaginated,
    allUsersPaginatedCurrentPage: currentPage,
    fetchAllUsersPaginated,
  } = useUser();

  useDocumentScroll({
    bottomReached: () => loadMore(),
  });
  const { contentPreset } = useChainBehavior();

  const itemComponent = computed(() =>
    viewType.value === 'grid' ? UserGridItemLandscape : UserListItem,
  );

  const getSortByLabel = (by: UserSortField) => {
    switch (by) {
      case 'pointCount':
        return 'DSCVR points';
      case 'name':
        return 'Name';
      case 'followingCount':
        return 'Following';
      case 'activeStreak':
        return 'Streak';
    }
  };

  const loadMore = async () => {
    loading.value = true;
    await fetchAllUsersPaginated({
      page: currentPage.value + 1n,
      page_size: BigInt(props.pageSize),
      chain_filter: contentPreset.value,
      sort_by: getUserSortBy(sortBy.value),
      sort_direction: getSortDirection(sortDirection.value),
    });
    loading.value = false;
  };

  const loadFirstPage = async (scrollUp = false) => {
    if (scrollUp) {
      document.documentElement.scrollTo({ top: 0 });
    }
    loading.value = true;
    loadingFirstTime.value = true;
    try {
      await fetchAllUsersPaginated(
        {
          page: 0n,
          page_size: BigInt(props.pageSize),
          chain_filter: contentPreset.value,
          sort_by: getUserSortBy(sortBy.value),
          sort_direction: getSortDirection(sortDirection.value),
        },
        INITIAL_PAGES,
      );
    } finally {
      loading.value = false;
      loadingFirstTime.value = false;
    }
  };

  const changeSort = (by: UserSortField, direction: SortColumnDirection) => {
    sortBy.value = by;
    sortDirection.value = direction;
    loadFirstPage(true);
  };

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        loadFirstPage(true);
      }
    },
  );

  onMounted(() => {
    if (allUsersPaginated.value.length === 0) {
      loadFirstPage();
    }
  });
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      ref="stickyRef"
      class="flex justify-center w-full sticky top-18 bg-gray-975 z-40"
    >
      <div
        class="flex justify-between w-full py-8 max-w-screen-lg mx-4 relative flex-wrap"
        :class="{
          'shadow-bottom': viewType !== 'table' && isStickyActive,
        }"
      >
        <h2 class="text-xl font-bold text-white flex gap-1 items-center">
          <base-button
            variant="custom"
            size="x-small"
            :to="{ name: EXPLORE_PEOPLE_FEATURED }"
          >
            <base-icon name="chevron-left" size="size-6" />
            <span class="capitalize text-xl">{{ $t('allPeople') }}</span>
          </base-button>
        </h2>
        <div class="flex justify-center items-center gap-2 md:gap-4">
          <transition
            enter-active-class="animate-fade-left"
            leave-active-class="animate-fade animate-reverse animate-duration-200"
          >
            <search-form v-if="showSearch" small class="hidden md:flex" />
          </transition>

          <sort-switch
            v-if="viewType !== 'table'"
            :types="userSortFieldTypes"
            :sort-by="sortBy"
            :sort-direction="sortDirection"
            @sort="changeSort"
          >
            <template #item="{ by }">
              {{ getSortByLabel(by) }}
            </template>
          </sort-switch>
          <view-switch
            :title="$t('allPortals')"
            v-model="viewType"
            @back="$emit('back')"
          />
        </div>
      </div>
    </div>
    <div ref="contentRef" class="flex flex-col w-full max-w-screen-lg px-4">
      <user-table-view
        v-if="viewType === 'table'"
        :loading="loading"
        :users="loadingFirstTime ? [] : allUsersPaginated"
        v-model:sort-by="sortBy"
        v-model:sort-direction="sortDirection"
        @sort-changed="loadFirstPage(true)"
      />
      <base-collection-view
        v-else
        :loading="loading"
        :items="loadingFirstTime ? [] : allUsersPaginated"
        :type="viewType"
      >
        <template #item="{ item }">
          <component :is="itemComponent" :user="item" />
        </template>
      </base-collection-view>
    </div>
  </div>
</template>
