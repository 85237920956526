import { actor } from '../base';
import type { ActiveStreakWindowResult } from 'dfx/edge/edge.did';

export const getActiveWindow = () => {
  return actor()
    .get_active_streak_window()
    .then((res) => res) as Promise<ActiveStreakWindowResult>;
};

export const claimDailyStreak = () => {
  return actor()
    .claim_daily_streak()
    .then((res) => res) as Promise<ActiveStreakWindowResult>;
};

export const setPromoShown = () => {
  return actor().set_streak_promo_shown();
};
