import { useInfiniteQuery } from '@tanstack/vue-query';
import { userPostsListKey, getFeedByNameKey } from '@/shared/model';
import type { MaybeRef, Ref } from 'vue';
import type { PersonalizedFeedV1Query } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';
import { hasNextPage } from '@/shared/model';

export const useUserPostsListQuery = (
  username: Ref<string> | undefined,
  queryParams: Ref<PersonalizedFeedV1Query>,
  enabled?: MaybeRef<boolean>,
) => {
  return useInfiniteQuery({
    queryKey: [...getFeedByNameKey(userPostsListKey), username],
    queryFn: async (ctx) => {
      // We override the page parameter with the current page number using the one provided by vue-query
      // this is a temporary hack until backend creates a cursor based pagination
      queryParams.value.query.page = BigInt(ctx.pageParam as number);
      if (!username) return null;
      const data = await dscvrIcApi.post.getUserContent(
        username.value,
        queryParams.value.query,
      );
      return data.result ? data.result[0] : null;
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, __) =>
      hasNextPage(allPages[0]?.total_pages, lastPageParam),
    enabled,
  });
};
