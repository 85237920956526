import { computed, type Ref } from 'vue';
import { ethers } from 'ethers';
import { isMetamaskInstalled, changeEvmNetwork } from '../../lib/evm/metamask';
import { WalletNotInstalledError } from '../types/error';
import { useWalletStore } from '../store';
import { POLYGON_CHAIN_ID } from '../../lib/evm/settings';

export const useConnectEvmWallets = () => {
  const walletStore = useWalletStore();

  const providerEthers: Ref<ethers.providers.Web3Provider | undefined> =
    computed(() => walletStore.providerEthers);

  const cleanUpProvider = () => {
    if (walletStore.providerEthers)
      walletStore.providerEthers.removeAllListeners();
  };

  const connectProvider = async () => {
    const isMetaMask = isMetamaskInstalled();
    if (!isMetaMask) {
      throw new WalletNotInstalledError();
    }

    if (!walletStore.providerEthers) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await changeEvmNetwork(POLYGON_CHAIN_ID);
      walletStore.providerEthers = provider;
      // request accounts from ethers provider
      if (window.ethereum && window.ethereum.request) {
        await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
      }
    }
  };

  return {
    providerEthers,
    cleanUpProvider,
    connectProvider,
  };
};
