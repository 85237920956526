<script lang="ts" setup>
  import { useWindowScroll } from '@vueuse/core';
  import { computed } from 'vue';

  const { y } = useWindowScroll();
  const isGrayOut = computed(() => {
    return y.value > 25;
  });
</script>
<template>
  <div
    class="absolute py-[3px] px-1 font-bold text-[6px] text-gray-900 -top-3 -right-4 rounded leading-none"
    :class="[!isGrayOut ? 'linear' : 'bg-white bg-opacity-80']"
  >
    <div
      class="absolute w-2 h-2 top-full left-[5px] badge-arrow"
      :class="[!isGrayOut ? 'linear' : 'bg-white bg-opacity-80']"
    />
    BETA
  </div>
</template>

<style scoped>
  .linear,
  .linear::before {
    background: linear-gradient(270deg, #00c9ff 0%, #92fe9d 100%);
  }
  .badge-arrow {
    clip-path: polygon(0 0, 0 60%, 60% 0);
  }
</style>
