<script lang="ts" setup>
  import { computed } from 'vue';
  import type { TabProps } from './types';

  const props = withDefaults(defineProps<TabProps>(), {
    type: 'underlined',
    direction: 'horizontal',
    tabsClasses: '',
    contentClasses: '',
  });

  const classes = computed(() => {
    const typeClasses = {
      nav: '',
    };
    if (props.type === 'underlined') {
      if (props.direction === 'horizontal') {
        typeClasses.nav = 'flex flex-row -mb-px gap-x-6';
      }
      if (props.direction === 'vertical') {
        typeClasses.nav = 'flex flex-col space-y-1';
      }
    }
    if (props.type === 'pills') {
      if (props.direction === 'horizontal') {
        typeClasses.nav = 'flex space-x-2';
      }
      if (props.direction === 'vertical') {
        typeClasses.nav = 'flex flex-col space-y-1';
      }
    }
    return typeClasses;
  });
</script>

<template>
  <section :class="{ 'flex flex-row space-x-4': direction === 'vertical' }">
    <!-- Tab Item(s) -->
    <section :class="[tabsClasses]">
      <nav :class="classes.nav" aria-label="Tabs">
        <slot
          name="tabs"
          :base-color="baseColor"
          :on-color="onColor"
          :off-color="offColor"
        />
      </nav>
    </section>
    <!-- Tab Item Content -->
    <section :class="[contentClasses]">
      <slot name="content" />
    </section>
  </section>
</template>
