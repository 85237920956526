<script lang="ts" setup>
  import { onBeforeMount, ref } from 'vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { useI18n } from 'vue-i18n';
  import { useTimeoutFn } from '@vueuse/core';
  import { useClaimPhantomRewardMutation } from '../../api/use-claim-phantom-reward.mutation';
  import { useGimluck } from '../../model/composables/use-gimluck';
  import ContentDialog from '../../ui/Content.vue';
  import { useGlobalStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import type { PhantomReward, PhantomClaimRequest } from 'dfx/edge/edge.did';
  import type { GimluckRewardCard } from '../../types';

  const props = defineProps<{ claimRequest: PhantomClaimRequest }>();

  const { t } = useI18n({
    useScope: 'global',
  });
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);
  const { openGimluckLosingDialog, openGimluckWinningDialog } = useGimluck();
  const { mutateAsync: claimPhantomRewardAsyncMutation } =
    useClaimPhantomRewardMutation();

  const cards = ref<GimluckRewardCard[]>([]);

  const isWinner = ref(false);
  const isRevealing = ref(false);

  const revealCardsSequentially = () => {
    if (isRevealing.value) return;
    isRevealing.value = true;

    let currentIndex = 0;

    const revealNextCard = () => {
      if (currentIndex < cards.value.length) {
        cards.value[currentIndex].show = true;
        currentIndex++;
        start();
      } else {
        isRevealing.value = false;
        if (isWinner.value) {
          openGimluckWinningDialog(cards.value[0]);
        } else {
          openGimluckLosingDialog(cards.value[0]);
        }
      }
    };

    const { start } = useTimeoutFn(revealNextCard, 500);
  };
  const losingCombination = ref<number[]>([]);

  const generateRandomValues = () => {
    const randomValues = [20, 40, 80, 120];
    const result: number[] = [];

    while (result.length < 4) {
      const randomValue =
        randomValues[Math.floor(Math.random() * randomValues.length)];

      if (result.filter((val) => val === randomValue).length < 3) {
        result.push(randomValue);
      }
    }

    return result;
  };

  const fillCards = (reward: PhantomReward) => {
    for (let i = 0; i <= 3; i++) {
      const randomValue = reward.reward.length
        ? Number(reward.reward[0])
        : losingCombination.value[i];
      cards.value.push({
        reward,
        value: randomValue,
        show: false,
      });
    }
  };

  onBeforeMount(async () => {
    losingCombination.value = generateRandomValues();
    await claimPhantomRewardAsyncMutation(props.claimRequest, {
      onSuccess: (data) => {
        if ('Ok' in data) {
          if (data.Ok.reward.length > 0) {
            isWinner.value = true;
          }
          fillCards(data.Ok);
          if (globalLiveData.value) {
            globalLiveData.value = {
              ...globalLiveData.value,
              phantom_claimable_at: data.Ok.timestamp,
            };
          }
        }
      },
    });
  });
</script>

<template>
  <content-dialog>
    <template #title>
      {{ t('gimluck.dialogs.spinner.title') }}
    </template>
    <template #description>
      {{ t('gimluck.dialogs.spinner.description') }}
    </template>
    <template #content>
      <div
        v-if="cards && cards.length"
        class="flex justify-center items-center gap-6"
      >
        <div
          v-for="card in cards"
          :key="card.value"
          class="relative gimluck-card flex justify-center items-center rounded-xl w-[90px] h-30 font-extrabold text-4xl"
        >
          <div
            class="flex justify-center items-center rounded-xl bg-black z-1 size-full"
          >
            <template v-if="card.show">
              <transition name="fade">
                <span
                  class="text-transparent bg-clip-text bg-gradient-to-tr from-cyan-500 via-purple-500 to-orange-500"
                >
                  {{ card.value }}
                </span>
              </transition>
            </template>
            <template v-else>
              <img
                :src="
                  fetchMedia(
                    `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/card-mask.png`,
                  )
                "
                class="w-[90px] h-30 z-1"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #button>
      <base-button
        variant="primary"
        size="medium"
        :disabled="isRevealing"
        @click="revealCardsSequentially"
      >
        {{ t('gimluck.dialogs.spinner.button') }}
      </base-button>
    </template>
  </content-dialog>
</template>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
