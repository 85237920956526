import {
  appendObjectToSearchParams,
  config,
  isValidHttpUrl,
} from '@/shared/lib';
import type { RequestInitCfPropertiesImage } from '@cloudflare/workers-types';

export const transformUrl = (
  url: string,
  transformation: RequestInitCfPropertiesImage = {},
): string => {
  if (!isValidHttpUrl(url)) {
    return url;
  }
  if (url.startsWith(config.MEDIA_WORKER_URL)) {
    return url;
  }

  const urlObject = new URL('/fetch', config.MEDIA_WORKER_URL);
  appendObjectToSearchParams(urlObject, {
    media: url,
    ...transformation,
  });
  // Sort the search params to ensure the URL is consistent for browser caching.
  urlObject.searchParams.sort();
  return urlObject.toString();
};
