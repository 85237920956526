<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import {
    HOME,
    USER_CONTENT,
    USER_SETTINGS_INTEGRATIONS,
    USER_SETTINGS_PEOPLE,
    USER_SETTINGS_PORTALS,
    USER_SETTINGS_PREFERENCES,
    USER_SETTINGS_NOTIFICATIONS,
    USER_SETTINGS_PROFILE,
    POST_DETAIL,
    POST_DETAIL_COMMENT,
    POST_DETAIL_COMMENT_NO_TITLE,
    POST_DETAIL_ID_ONLY,
    ALL,
    FOLLOW,
    SEARCH,
    PORTAL_PAGE,
    PORTAL_CONTENT,
    PORTAL_MARKETPLACE,
    PORTAL_ABOUT,
  } from '@/common';
  import type { ComputedRef } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  type TabItem = {
    short: string;
    icon: string;
  };
  type TranslationBackTo = 'backToProfile' | 'backToFeeds' | 'backToPost';

  const props = defineProps<{
    username: string;
  }>();

  const route = useRoute();
  const router = useRouter();

  const currentTab = ref(USER_SETTINGS_PROFILE);

  const tabList = computed<Record<string, TabItem>>(() => ({
    [USER_SETTINGS_PROFILE]: {
      short: 'User Profile',
      icon: 'person',
    },
    [USER_SETTINGS_PREFERENCES]: {
      short: 'Preferences',
      icon: 'preference-settings',
      name: USER_SETTINGS_PREFERENCES,
    },
    [USER_SETTINGS_NOTIFICATIONS]: {
      short: 'Notifications',
      icon: route.name === USER_SETTINGS_NOTIFICATIONS ? 'bell-filled' : 'bell',
    },
    [USER_SETTINGS_INTEGRATIONS]: {
      short: 'Integrations',
      icon: 'integrations',
    },
    [USER_SETTINGS_PORTALS]: {
      short: 'Portals',
      icon: 'outline-portal',
    },
    [USER_SETTINGS_PEOPLE]: {
      short: 'People',
      icon: 'user-group',
    },
  }));

  const translationBackTo: ComputedRef<TranslationBackTo> = computed(() => {
    let value: TranslationBackTo = 'backToProfile';
    const backPageUrl = router.options.history.state.back;
    if (!backPageUrl) {
      return value;
    }
    const backPage = router.resolve({ path: `${backPageUrl}` });
    if (backPage?.name) {
      if (
        [
          POST_DETAIL,
          POST_DETAIL_COMMENT,
          POST_DETAIL_COMMENT_NO_TITLE,
          POST_DETAIL_ID_ONLY,
        ].includes(backPage.name.toString())
      ) {
        value = 'backToPost';
      } else if (
        [
          HOME,
          ALL,
          FOLLOW,
          SEARCH,
          PORTAL_PAGE,
          PORTAL_CONTENT,
          PORTAL_MARKETPLACE,
          PORTAL_ABOUT,
        ].includes(backPage.name.toString())
      ) {
        value = 'backToFeeds';
      }
      return value;
    }

    return value;
  });

  onMounted(() => {
    if (!props.username) {
      router.push({ name: HOME });
      return;
    }

    const currentRouteName = route.matched[2]?.name || route.name;
    if (tabList.value.hasOwnProperty(currentRouteName as string)) {
      currentTab.value = currentRouteName as string;
    }
  });

  const back = () => {
    if (translationBackTo.value === 'backToProfile') {
      router.push({
        name: USER_CONTENT,
        username: props.username,
      } as RouteLocationRaw);
    } else {
      router.back();
    }
  };

  const setTab = (tab: string) => {
    currentTab.value = tab;
    router.push({
      name: tab,
      username: props.username,
    } as RouteLocationRaw);
  };
</script>

<template>
  <div class="grid w-full grid-cols-1 px-2 lg:grid-cols-5 lg:px-0 lg:gap-x-12">
    <div class="relative hidden col-span-1 lg:flex">
      <div class="fixed ml-6 text-sm font-medium text-gray-400">
        <div
          class="w-full pb-4 pr-12 mb-6 transition-all duration-300 border-b border-gray-700 cursor-pointer hover:text-white"
          @click="back"
        >
          <base-icon
            name="arrow-left"
            size="w-5 h-5"
            class="inline-block mr-2"
          />
          {{ $t(translationBackTo) }}
        </div>
        <div
          v-for="(tab, routeName) in tabList"
          :key="routeName"
          class="flex flex-col"
        >
          <button
            :index="routeName"
            class="py-4 text-left transition-all duration-300 hover:text-white"
            :class="{ 'text-white': routeName === route.name }"
            @click="setTab(routeName)"
          >
            <base-icon
              :name="tab.icon"
              class="inline-block mr-2"
              size="w-5 h-5"
            />
            {{ tab.short }}
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col px-2 pt-4 lg:hidden">
      <div
        class="w-full pb-4 pr-12 transition-all duration-300 cursor-pointer hover:text-white"
        @click="back"
      >
        <base-icon name="arrow-left" class="inline-block mr-2" />
        {{ $t(translationBackTo) }}
      </div>
      <base-dropdown
        append-to-body
        match-content-width
        theme="gray"
        custom-classes="mt-2 mb-6"
        custom-content-classes="w-full"
        :distance="10"
        :skidding="0"
        tag="div"
      >
        <template #button="{ toggle, isVisible }">
          <base-button
            variant="secondary"
            size="medium"
            additional-classes="w-full"
            @click="toggle"
          >
            {{ tabList[currentTab].short }}
            <base-icon
              name="chevron-down-filled"
              size="size-4"
              class="transform transition-all"
              :class="{
                ' rotate-180': isVisible,
              }"
            />
          </base-button>
        </template>
        <template #content>
          <base-button
            v-for="(tab, routeName) in tabList"
            :key="routeName"
            variant="custom"
            rounded="rounded-none"
            size="full"
            :class="{ 'bg-gray-700': routeName === currentTab }"
            @click="setTab(routeName)"
          >
            {{ tab.short }}
          </base-button>
        </template>
      </base-dropdown>
    </div>

    <div class="w-full max-w-screen-lg col-span-4 px-2 mb-32 sm:rounded-xl">
      <slot :username="username" />
    </div>
  </div>
</template>
