import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { EmbeddedStreakEditor } from '@/components/tiptap';

export const StreakNode = Node.create({
  name: 'streakNode',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      days: {
        default: '0',
      },
      multiplier: {
        default: '0',
      },
      points: {
        default: '0',
      },
      username: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'embedded-streak',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['embedded-streak', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(EmbeddedStreakEditor);
  },
});
