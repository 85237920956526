import { contentKey } from '@/shared/model';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useContentNsfwSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['content-nsfw-set-mutation'],
    mutationFn: (params: dscvrIcApi.post.NsfwContentParams) =>
      dscvrIcApi.post.contentNsfwSet(params),
    onSettled: () => {
      queryClient.resetQueries({
        queryKey: [contentKey],
      });
      window.scrollTo(0, 0);
    },
  });
};
