<script setup lang="ts">
  import { liveChatModel } from '@/entities/live';
  const { toggleRaiseHand, isCurrentUserHandRaised } = liveChatModel.use();
</script>

<template>
  <div
    class="cursor-pointer flex items-center justify-center px-2 py-1 cursor-pointer group rounded-r-full"
    @click="toggleRaiseHand"
  >
    <base-icon
      name="emoji-waving-hand"
      size="w-9 h-9"
      :class="{
        'transition-all duration-200 group-hover:scale-[200%] group-hover:drop-shadow-[0_8px_16px_rgba(0,0,0,0.4)] relative z-1 group-hover:z-10':
          !isCurrentUserHandRaised,
      }"
    />
    <span
      v-if="!isCurrentUserHandRaised"
      class="ml-4 text-gray-400 group-hover:text-white"
      >Speak</span
    >
    <span v-else class="ml-4">Raised</span>
  </div>
</template>
