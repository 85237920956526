<script setup lang="ts">
  import { computed } from 'vue';
  import type { IconName } from '@/shared/ui/base-icon';
  import { contentReactionToReactionType } from '../lib/content-reaction-to-reaction-type';
  import { reactionTypeToIconName } from '../lib/reaction-type-to-icon-name';
  import type { ReactionCount, ReactionType } from '../types.ts';

  const props = defineProps<{
    reactionCounts: ReactionCount[];
  }>();

  const count = computed(() =>
    props.reactionCounts.reduce((acc, [_, count]) => {
      return acc + count;
    }, 0n),
  );

  const reactions = computed(() => {
    const result = props.reactionCounts
      .filter(([_, count]) => count > 0n)
      .sort((a, b) => Number(b[1] - a[1]))
      .map(
        ([content]) => contentReactionToReactionType(content) as ReactionType,
      );
    if (result.length === 1) {
      return result;
    }
    // TODO: We remove dislikes from the list of reactions but keep it in the count which
    // makes the user believes their dislike was a like. We should fix this.
    return result
      .filter((reactionType) => reactionType !== 'dislike')
      .slice(0, 3);
  });

  const reverseEmojis = computed<IconName[]>(() =>
    reactions.value.map(reactionTypeToIconName).reverse(),
  );
</script>

<template>
  <div class="flex items-center py-2 cursor-auto">
    <template v-if="count">
      <ul class="flex flex-row-reverse text-2xl -mt-0.5">
        <li
          v-for="reactionEmoji in reverseEmojis"
          :key="reactionEmoji"
          class="-mr-1.5 relative"
        >
          <base-icon :name="reactionEmoji" size="w-6 h-6" />
        </li>
      </ul>
      <span
        class="ml-3 mr-1 text-sm tracking-wide005 font-medium text-gray-400"
      >
        {{ count }}
      </span>
    </template>
  </div>
</template>
