<template>
  <section class="w-full">
    <div
      class="max-w-screen-lg p-5 text-white bg-gray-950 shadow-lg post-item sm:rounded-xl"
    >
      <div class="mb-4 text-2xl font-bold leading-7">
        Create Proposal: SNS-1
      </div>
      <ProposalCreateForm :neuron-id="neuronId" />
    </div>
  </section>
</template>

<script>
  import ProposalCreateForm from '../components/sns/ProposalCreateForm.vue';
  export default {
    name: 'proposal-create-page',
    components: { ProposalCreateForm },
    props: {
      neuronId: {
        type: String,
        default: null,
      },
    },
  };
</script>
