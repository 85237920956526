<script lang="ts" setup>
  import { OnboardingLootboxStep, OnboardingStep } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import { useOnboardingStateMachine } from '../model/composables/use-onboarding-state-machine';
  import { useOnboardingLootboxStateMachine } from '../model/composables/use-onboarding-lootbox-state-machine';
  import { useOnboarding } from '../model/composables/use-onboarding';

  const props = withDefaults(
    defineProps<{
      currentStep: OnboardingStep | OnboardingLootboxStep;
      disabled?: boolean;
      onCustomAction?: () => void;
      variant?: 'primary' | 'link';
    }>(),
    {
      disabled: false,
      variant: 'primary',
    },
  );

  const { t } = useI18n({
    useScope: 'global',
  });
  const { states: originalStateMachine } = useOnboardingStateMachine();
  const { states: lootboxStateMachine } = useOnboardingLootboxStateMachine();
  const { isOnboardingLootboxStep } = useOnboarding();

  const handleContinue = async () => {
    if (props.onCustomAction) {
      await props.onCustomAction();
    }
    if (isOnboardingLootboxStep(props.currentStep)) {
      lootboxStateMachine.value[props.currentStep].on('continue');
    } else {
      originalStateMachine.value[props.currentStep].on('continue');
    }
  };
</script>

<template>
  <div
    v-if="disabled"
    class="flex items-center justify-center w-full py-3.5 bg-indigo-700 rounded-xl opacity-40"
  >
    <slot>
      {{ t('continue') }}
    </slot>
  </div>
  <base-button
    v-else
    :variant="variant"
    :class="{
      'text-indigo-300': variant === 'link',
    }"
    size="full"
    @click="handleContinue"
  >
    <slot>
      {{ t('continue') }}
    </slot>
  </base-button>
</template>
