<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import ContentDialog from '../../ui/Content.vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import type { GimluckRewardCard } from '../../types';
  import ShareButton from '../../ShareButton.vue';

  defineProps<{ card: GimluckRewardCard }>();

  const { t } = useI18n({
    useScope: 'global',
  });
</script>

<template>
  <content-dialog>
    <template #title>
      {{ t('gimluck.dialogs.winner.title') }}
    </template>
    <template #description>
      {{ t('gimluck.dialogs.winner.description.youWon') }} {{ card.value }}
      {{ t('gimluck.dialogs.winner.description.points') }}
    </template>
    <template #content>
      <div
        class="relative gimluck-card flex justify-center items-center w-[162px] h-[216px] font-extrabold"
      >
        <div class="absolute -bottom-10 left-1/2 -translate-x-1/2 w-[200%] z-1">
          <img
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/celebration.gif`,
              )
            "
            alt="celebration"
          />
        </div>
        <div
          class="flex justify-center items-center rounded-xl bg-black z-1 size-full"
        >
          <span
            class="text-[80px] font-extrabold text-transparent bg-clip-text bg-gradient-to-tr from-cyan-500 via-purple-500 to-orange-500"
          >
            {{ card.value }}
          </span>
        </div>
      </div>
    </template>
    <template #button>
      <share-button :reward="card.reward" />
    </template>
  </content-dialog>
</template>
