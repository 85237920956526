import {
  getListings,
  type GetListingParams,
} from '@/shared/api/sol-api/tensor/collection';

export const useGetTensorCollectionListingsQuery = async (
  params: GetListingParams,
) => {
  return getListings(params);
};
