<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { useUserIntegrations } from '@/entities/user';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import PairPlatformModal from '@/components/PairPlatformModal.vue';
  import { SingleLinkFrame } from './components';
  import { useTwitterOauth } from '@/entities/user-setting/model/composables/use-twitter-oauth';
  import { useUnlinkTwitterMutation } from '@/entities/user-setting/api/use-unlink-twitter.mutation';
  import { useGetMyTwitterProfileQuery } from '@/entities/user-setting/api/use-get-my-twitter-profile.query';
  import { useToast } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import { trackEvent } from '@/utils/tracker';

  const {
    unpair,
    getCurrentUserPlatformUsername,
    getCurrentUserPairedPlatforms,
  } = useUserIntegrations();

  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const { getTwitterAuthUrl } = useTwitterOauth();
  const { data: twitterProfile, isLoading: isTwitterLoading } =
    useGetMyTwitterProfileQuery();
  const { mutateAsync: mutateUnlinkTwitter } = useUnlinkTwitterMutation();

  const discordUsername = computed(() => {
    return getCurrentUserPlatformUsername('Discord');
  });

  const isPaired = computed(() => discordUsername.value !== undefined);
  const isTwitterPaired = computed(() => twitterUsername.value !== '');
  const showModal = ref(false);
  const isLoadingLocal = ref(false);
  const twitterUsername = computed(() => {
    return twitterProfile.value?.data
      ? `@${twitterProfile.value.data.username}`
      : '';
  });

  const isLoading = computed(
    () => isLoadingLocal.value || isTwitterLoading.value,
  );

  const refresh = () => {
    isLoadingLocal.value = true;
    getCurrentUserPairedPlatforms().finally(
      () => (isLoadingLocal.value = false),
    );
  };

  const handlePairing = () => {
    if (isPaired.value) {
      isLoadingLocal.value = true;
      unpair('Discord').then(() => (isLoadingLocal.value = false));
    } else showModal.value = true;
  };

  const handleTwitterPairing = async () => {
    if (isTwitterPaired.value) {
      await mutateUnlinkTwitter(undefined, {
        onError: () => {
          trackEvent(
            'user_profile_settings_action',
            'social_unpair_error',
            'twitter',
          );
          showToast({
            description: t('twitter.unlinkError'),
            type: 'error',
          });
        },
        onSuccess: () => {
          trackEvent(
            'user_profile_settings_action',
            'social_unpair',
            'twitter',
          );
          showToast({
            description: t('twitter.unlinkSuccess'),
            type: 'success',
          });
        },
      });
    } else {
      trackEvent(
        'user_profile_settings_action',
        'social_pair_initiate',
        'twitter',
      );
      const twitterOauthUrl = await getTwitterAuthUrl();
      window.location.href = twitterOauthUrl;
    }
  };

  onMounted(() => {
    refresh();
  });
</script>

<template>
  <div>
    <pair-platform-modal
      :is-open="showModal"
      @close-modal="(showModal = false), refresh()"
    />
    <div class="relative w-full">
      <overlay-loader v-if="isLoading" />
      <single-link-frame
        class="animate-fade-left"
        icon="social-discord-desaturated"
        name="Discord"
        :button-text="isPaired ? $t('unpair') : $t('pair')"
        @submit="handlePairing"
      >
        <input
          class="block w-full bg-transparent rounded-xl p-2 truncate text-right"
          disabled
          :value="
            discordUsername ??
            'Pair your Discord account to add username to your profile'
          "
          :title="discordUsername"
        />
      </single-link-frame>
      <single-link-frame
        class="animate-fade-left pt-4"
        icon="social-twitter-desaturated"
        name="X (Twitter)"
        :button-text="isTwitterPaired ? $t('unpair') : $t('pair')"
        @submit="handleTwitterPairing"
      >
        <input
          class="block w-full bg-transparent rounded-xl p-2 truncate text-right"
          disabled
          :value="twitterUsername || $t('twitter.unpairedSocialPairingText')"
          :title="twitterUsername"
        />
      </single-link-frame>
    </div>
  </div>
</template>
