<script lang="ts" setup>
  import { computed } from 'vue';
  import { roleGradients } from '../../../entities/role/lib/role-gradients';

  const props = withDefaults(
    defineProps<{
      color?: number;
      rounded?: string;
      size?: string;
    }>(),
    {
      rounded: 'rounded-sm',
      size: 'size-4',
    },
  );

  const gradientClasses = computed(() => {
    if (props.color === undefined || props.color === 13158600) {
      return 'box-border border border-gray-500 bg-indigo-400 bg-opacity-20';
    }

    const gradient = roleGradients.get(props.color);
    return gradient ? [gradient.via, gradient.from, gradient.to] : undefined;
  });
</script>

<template>
  <div
    class="rotate-45 bg-gradient-to-b"
    :class="[rounded, size, gradientClasses]"
  ></div>
</template>
