import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { sortFavoritePortals } from '../lib/favorite-portals';
import { favoritePortalsKey, portalUserStatusKey } from './keys';
import { useChainBehavior } from '@/entities/user-setting';

export const useGetFavoritePortalsQuery = () => {
  const { contentPreset } = useChainBehavior();

  return useQuery({
    queryKey: [portalUserStatusKey, favoritePortalsKey],
    queryFn: async () => {
      const portals = await dscvrIcApi.portal.getFavoritePortals(
        contentPreset.value,
      );

      return sortFavoritePortals(portals);
    },
    staleTime: 1000 * 60 * 5,
  });
};
