import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { EmbeddedFrameEditor } from '@/features/frame';

export const FrameNode = Node.create({
  name: 'frame',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      url: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'embedded-frame',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['embedded-frame', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(EmbeddedFrameEditor);
  },
});
