import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import type { PlaybackElement } from './types';

export const useMediaStore = defineStore('media', () => {
  const mediaPlaybackHashMap = ref(new Map<string, PlaybackElement>());
  const mediaPlaybackHash = computed(() => mediaPlaybackHashMap.value);

  const setMediaPlayback = (id: string, payload: PlaybackElement) => {
    mediaPlaybackHashMap.value.set(id, payload);
  };

  const unsetMediaPlayback = (id: string) => {
    mediaPlaybackHashMap.value.delete(id);
  };

  return {
    mediaPlaybackHash,
    setMediaPlayback,
    unsetMediaPlayback,
  };
});
