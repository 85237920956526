import { computed } from 'vue';
import { OnboardingStep, type OnboardingState } from '@/shared/model';
import { useSetOnboardingStateMutation } from '../../api/use-set-onboarding-state.mutation';
import { useOnboarding } from './use-onboarding';
import { config } from '@/shared/lib';
import type { OnboardingEventLabel } from '../../types';

export const useOnboardingStateMachine = () => {
  const { mutate: setOnboardingStateMutation } =
    useSetOnboardingStateMutation();
  const { openConfiguredOnboardingDialog, onboardingState } = useOnboarding();

  const onInit = (state: OnboardingState) => {
    onboardingState.value = state;
    openConfiguredOnboardingDialog(state.currentStep);
  };

  const onEvent = (
    event: OnboardingEventLabel,
    currentStep: OnboardingStep,
  ) => {
    if (event === 'continue') {
      onContinue(currentStep);
    } else {
      onSkip(currentStep);
    }
  };

  const onContinue = (currentStep: OnboardingStep) => {
    const target = states.value[currentStep].target;
    onboardingState.value = {
      currentStep: target,
      completedSteps: [...onboardingState.value.completedSteps, currentStep],
    };
    setOnboardingStateMutation(onboardingState.value);
    openConfiguredOnboardingDialog(target);
  };

  const onSkip = (currentStep: OnboardingStep) => {
    const target = states.value[currentStep].target;
    onboardingState.value = {
      ...onboardingState.value,
      currentStep: target,
    };
    setOnboardingStateMutation(onboardingState.value);
    openConfiguredOnboardingDialog(target);
  };

  const states = computed<{
    [key in OnboardingStep]: {
      target: OnboardingStep;
      on: (event: OnboardingEventLabel) => void;
    };
  }>(() => ({
    [OnboardingStep.INTRODUCTORY]: {
      target: OnboardingStep.INTERESTS,
      on: (event) => onEvent(event, OnboardingStep.INTRODUCTORY),
    },
    [OnboardingStep.INTERESTS]: {
      target: OnboardingStep.DISCOVER_USERS,
      on: (event) => onEvent(event, OnboardingStep.INTERESTS),
    },
    [OnboardingStep.DISCOVER_USERS]: {
      target: OnboardingStep.JOIN_PORTALS,
      on: (event) => onEvent(event, OnboardingStep.DISCOVER_USERS),
    },
    [OnboardingStep.JOIN_PORTALS]: {
      target: OnboardingStep.REWARD_DSCVR_POINTS,
      on: (event) => onEvent(event, OnboardingStep.JOIN_PORTALS),
    },
    [OnboardingStep.REWARD_DSCVR_POINTS]: {
      target: OnboardingStep.CLAIM_STREAK,
      on: (event) => onEvent(event, OnboardingStep.REWARD_DSCVR_POINTS),
    },
    [OnboardingStep.CLAIM_STREAK]: {
      target: config.ENABLE_ONBOARDING_LOOTBOX
        ? OnboardingStep.CLAIM_LOOTBOX
        : OnboardingStep.DONE,
      on: (event) => onEvent(event, OnboardingStep.CLAIM_STREAK),
    },
    [OnboardingStep.CLAIM_LOOTBOX]: {
      target: OnboardingStep.DONE,
      on: (event) => onEvent(event, OnboardingStep.CLAIM_LOOTBOX),
    },
    [OnboardingStep.DONE]: {
      target: OnboardingStep.DONE,
      on: (event) => onEvent(event, OnboardingStep.DONE),
    },
  }));

  return {
    onInit,
    onContinue,
    onSkip,
    states,
  };
};
