import { Principal } from '@dfinity/principal';
import { nftRegistry } from './nftRegistry';
import { C3 } from './standards/nft/c3';
import { C3v2 } from './standards/nft/c3v2';
import { C3v3 } from './standards/nft/c3v3';
import { DIP721 } from './standards/nft/dip721';
import { DIP721V2 } from './standards/nft/dip721v2';
import { ICDrip } from './standards/nft/drip';
import { EXT as NFTEXT } from './standards/nft/ext';
import { SBT } from './standards/nft/sbt';
import { DIP20 } from './standards/token/dip20';
import { DIP20Land } from './standards/token/dip20land';
import { EXT as TEXT } from './standards/token/ext';
import { ICRC1 } from './standards/token/icrc1';
import { Token } from './standards/token/token';
import { tokenRegistry } from './tokenRegistry';

// prettier-ignore
const NFT_STANDARD_MAP = {
  "ext": NFTEXT,
  "c3": C3,
  "c3v2": C3v2,
  "c3v3": C3v3,
  "dip721v1": DIP721,
  "dip721v2": DIP721V2,
  "drip": ICDrip,
  "sbt": SBT
};

// prettier-ignore
const TOKEN_STANDARD_MAP = {
  "ext": TEXT,
  "dip20": DIP20,
  "dip20land": DIP20Land,
  "icrc1": ICRC1
};

class TokenManager {
  constructor() {
    this.debug = import.meta.env.DEV;
  }

  async getStandardNFTs(standard, registryItem, principal) {
    let tokens = [];
    try {
      tokens = await standard.getTokens(principal);
      tokens.map((t) => {
        t.addRegistryData(registryItem);
      });
    } catch (error) {
      console.log(error);
    }

    return tokens;
  }

  isValidPrincipal(val) {
    try {
      Principal.fromText(val);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  validateTokenTransferAddress(nft, to_address) {
    if (nft.standard == 'ext') {
      if (this.isValidPrincipal(to_address)) {
        return true;
      } else {
        if (to_address.length == 64) {
          return true;
        }
      }
    } else {
      return this.isValidPrincipal(to_address);
    }
    return false;
  }

  validateNFTTransferAddress(nft, to_address) {
    if (nft.standard == 'ext') {
      if (this.isValidPrincipal(to_address)) {
        return true;
      } else {
        if (to_address.length == 64) {
          return true;
        }
      }
    } else {
      return this.isValidPrincipal(to_address);
    }
    return false;
  }

  async transferNFT(nft, to_address) {
    if (nft.standard in NFT_STANDARD_MAP) {
      const standard = new NFT_STANDARD_MAP[nft.standard](nft.cid);
      await standard.create();
      return await standard.transfer(to_address, nft.tokenIndex);
    }
    return false;
  }

  async transferToken(nft, to_address, amount) {
    const ext = new TOKEN_STANDARD_MAP[nft.standard](nft.cid);
    await ext.create();
    return await ext.transfer(to_address, amount);
  }

  async tipToken(token, to_address, amount, contentId) {
    const std = new TOKEN_STANDARD_MAP[token.standard](token.cid);
    await std.create();
    return await std.tip(to_address, amount, contentId);
  }

  async getUserTokenBalance(token, principal) {
    const ext = new TOKEN_STANDARD_MAP[token.standard](token.cid);
    await ext.create();
    const results = await ext.balance(principal);
    const std = await this.getTokenStandard(token.cid);
    std.balance = results.balance;
    return std;
  }

  async getUserTokens(principal) {
    const results = [];
    const tokenCanisters = await tokenRegistry.list();

    await Promise.all(
      tokenCanisters.map(async (tc) => {
        try {
          const std = new TOKEN_STANDARD_MAP[tc.standard](tc.cid);
          await std.create();
          const userTokens = await std.balance(principal);
          if (userTokens.balance > 0) {
            userTokens.addRegistryData(tc);
            results.push(userTokens);
          }
        } catch (error) {
          console.log('getUserTokens', error);
        }
      }),
    );

    return results;
  }
  async getTokenStandard(canisterId) {
    const tokenCanisters = await tokenRegistry.list();
    for (let i = 0; i < tokenCanisters.length; i++) {
      const tc = tokenCanisters[i];
      if (tc.cid == canisterId) {
        const token = new Token({
          balance: 0,
        });
        token.addRegistryData(tc);
        return token;
      }
    }
    return null;
  }

  async getUserNFTs(principal) {
    const tokenCanisters = await nftRegistry.list();
    const userTokenPromise = [];
    for (let i = 0; i < tokenCanisters.length; i++) {
      try {
        const tc = tokenCanisters[i];
        const std = new NFT_STANDARD_MAP[tc.standard](tc.cid);
        await std.create();
        userTokenPromise.push(this.getStandardNFTs(std, tc, principal));
      } catch (error) {
        console.log('Error Loading Canister', tc.standard, tc.cid);
      }
    }
    return (await Promise.all(userTokenPromise)).flat();
  }
}

export const tokenManager = new TokenManager();
