import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useGetGlobalLiveDataQuery = (enabled: Ref<boolean>) => {
  return useQuery({
    queryKey: ['global-live-data'],
    queryFn: dscvrIcApi.auth.getGlobalLiveData,
    refetchInterval: 1000 * 10,
    enabled,
  });
};
