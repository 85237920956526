<template>
  <div class="relative">
    <div class="grid grid-cols-1 gap-4 mt-4">
      <div class="relative rounded-lg">
        <overlay-loader
          v-if="isStoicLoading"
          class="!rounded-lg overflow-hidden"
        />
        <pair-stoic-button
          :is-paired="isStoicPaired"
          :stoic-address="stoicAddress"
          @pair-start="(val) => setPairing(val, 'stoic')"
          @unpair-start="(val) => setPairing(val, 'stoic')"
        />
      </div>
      <div class="relative rounded-lg">
        <overlay-loader
          v-if="isPlugLoading"
          class="!rounded-lg overflow-hidden"
        />
        <pair-plug-button
          :is-paired="isPlugPaired"
          :plug-address="plugAddress"
          @pair-start="(val) => setPairing(val, 'plug')"
          @unpair-start="(val) => setPairing(val, 'plug')"
        />
      </div>

      <div class="relative rounded-lg">
        <overlay-loader
          v-if="isInfinityLoading"
          class="!rounded-lg overflow-hidden"
        />
        <pair-infinity-swap-button
          :is-paired="isInfinitySwapPaired"
          :infinity-swap-address="infinitySwapAddress"
          @pair-start="(val) => setPairing(val, 'infinity')"
          @unpair-start="(val) => setPairing(val, 'infinity')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { client } from '@/utils/dfinity';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import PairStoicButton from './PairStoicButton.vue';
  import PairPlugButton from './PairPlugButton.vue';
  import PairInfinitySwapButton from './PairInfinitySwapButton.vue';

  export default {
    name: 'paired-wallets',
    components: {
      OverlayLoader,
      PairStoicButton,
      PairPlugButton,
      PairInfinitySwapButton,
    },
    data() {
      return {
        isPlugLoading: false,
        isStoicLoading: false,
        isInfinityLoading: false,
        isStoicPaired: false,
        stoicAddress: '',
        isPlugPaired: false,
        plugAddress: '',
        isInfinitySwapPaired: false,
        infinitySwapAddress: '',
      };
    },
    async mounted() {
      await this.refreshPairs();
    },
    methods: {
      async setPairing(val, wallet) {
        if (wallet == 'stoic') {
          this.isStoicLoading = val;
        } else if (wallet == 'plug') {
          this.isPlugLoading = val;
        } else if (wallet == 'infinity') {
          this.isInfinityLoading = val;
        }

        if (!val) {
          await this.refreshPairs();
        }
      },
      async refreshPairs() {
        const wallets = await client.actor.get_paired_wallets();

        this.isStoicPaired = false;
        this.isPlugPaired = false;
        this.isInfinitySwapPaired = false;
        for (let i = 0; i < wallets.length; i++) {
          if (wallets[i].is_paired) {
            if ('Stoic' in wallets[i].kind) {
              this.isStoicPaired = true;
              this.stoicAddress = wallets[i].wallet_id.toText();
            }

            if ('Plug' in wallets[i].kind) {
              this.isPlugPaired = true;
              this.plugAddress = wallets[i].wallet_id.toText();
            }

            if ('InfinitySwap' in wallets[i].kind) {
              this.isInfinitySwapPaired = true;
              this.infinitySwapAddress = wallets[i].wallet_id.toText();
            }
          }
        }
      },
    },
  };
</script>
