import { computed, unref, type MaybeRef, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { compact, uniqBy } from 'lodash-es';
import type { ChainOption } from '@/utils';
import { shortenString } from '@/shared/lib';
import type { IconName } from '@/shared/ui/base-icon';
import { useUser } from '@/entities/user';
import { useChainBehavior } from '@/entities/user-setting';
import { useWalletPairing, type WalletOption } from '@/entities/wallets';
import {
  useGetIcpFTsQuery,
  useGetIcpNFTsQuery,
  useGetSolanaFTsQuery,
  useGetSolanaNFTsQuery,
  type FungibleToken,
  type NonFungibleToken,
} from '@/entities/token';

type Options = {
  chain?: Ref<ChainOption | undefined>;
  enableFTs: MaybeRef<boolean>;
  enableNFTs: MaybeRef<boolean>;
};

export const useWalletOptions = (
  { chain, enableFTs, enableNFTs }: Options = {
    enableFTs: true,
    enableNFTs: true,
  },
) => {
  const { t } = useI18n({ useScope: 'global' });
  const { currentUserPrincipal } = useUser();
  const { selectedChainType } = useChainBehavior();
  const { pairedWallets, isFetchingPairedWallets, fetchPairedWallets } =
    useWalletPairing();

  const defaultChain = computed(
    () => chain?.value?.chain ?? selectedChainType.value,
  );

  const shouldIncludeICP = computed(
    () => !!defaultChain.value && ['icp', 'all'].includes(defaultChain.value),
  );

  const shouldIncludeSolana = computed(
    () => !!defaultChain.value && ['sol', 'all'].includes(defaultChain.value),
  );

  const allWalletsOption = computed<WalletOption>(() => ({
    id: '0',
    label: t('wallets.allWallets'),
    chain: 'all',
    type: 'all',
    icon: 'wallet-2',
    address: null,
  }));

  const dscvrIdOption = computed<WalletOption>(() => {
    const address = currentUserPrincipal.value?.toText();

    return {
      id: address,
      label: 'DSCVR ID',
      type: 'dscvr-id',
      chain: 'icp',
      subtitle: shortenString(address),
      icon: 'multi-wallet-dscvr-id',
      address: address,
    };
  });

  const walletOptions = computed(() => {
    let result: WalletOption[] = [];
    if (shouldIncludeICP.value) {
      result.push(dscvrIdOption.value);
    }

    if (shouldIncludeSolana.value) {
      result = uniqBy(
        result.concat(
          pairedWallets.value.map((wallet) => ({
            id: wallet.address,
            label: wallet.walletType.name,
            chain: 'sol',
            type: wallet.walletType.slug,
            subtitle: shortenString(wallet.address),
            icon: `multi-wallet-${wallet.walletType.slug}` as IconName,
            address: wallet.address,
          })),
        ),
        'address',
      );
    }

    if (result.length > 1) {
      return [allWalletsOption.value, ...result];
    }
    return result;
  });

  const fetchWalletOptions = async () => {
    if (shouldIncludeSolana.value) {
      await fetchPairedWallets();
    }
  };

  const walletAddresses = computed(() =>
    compact(walletOptions.value.map((w) => w.address)),
  );

  const enableIcpFts = computed(
    () => shouldIncludeICP.value && unref(enableFTs),
  );

  const enableIcpNFTs = computed(
    () => shouldIncludeICP.value && unref(enableNFTs),
  );

  const enableSolanaFts = computed(
    () => shouldIncludeSolana.value && unref(enableFTs),
  );

  const enableSolanaNFTs = computed(
    () =>
      shouldIncludeSolana.value &&
      walletOptions.value.some(({ chain }) => chain === 'sol') &&
      unref(enableNFTs),
  );

  const { data: icpFTs, isFetching: isIcpFTsLoading } = useGetIcpFTsQuery(
    currentUserPrincipal,
    enableIcpFts,
  );
  const { data: icpNFTs, isFetching: isIcpNFTsLoading } = useGetIcpNFTsQuery(
    currentUserPrincipal,
    enableIcpNFTs,
  );
  const { data: solanaFTs, isFetching: isSolanaFTsFetching } =
    useGetSolanaFTsQuery(currentUserPrincipal, enableSolanaFts);
  const { data: solanaNFTs, isFetching: isSolanaNFTsFetching } =
    useGetSolanaNFTsQuery(
      currentUserPrincipal,
      walletAddresses,
      enableSolanaNFTs,
    );

  const isSolanaFTsLoading = computed(
    () => isSolanaFTsFetching.value || isFetchingPairedWallets.value,
  );

  const isSolanaNFTsLoading = computed(
    () => isSolanaNFTsFetching.value || isFetchingPairedWallets.value,
  );

  const isFTsLoading = computed(
    () => isIcpFTsLoading.value || isSolanaFTsLoading.value,
  );

  const isNFTsLoading = computed(
    () =>
      isIcpNFTsLoading.value ||
      isSolanaNFTsLoading.value ||
      isFetchingPairedWallets.value,
  );

  const fungibleTokens = computed<FungibleToken[]>(() => {
    return [...(solanaFTs.value ?? []), ...icpFTs.value];
  });

  const nonFungibleTokens = computed<NonFungibleToken[]>(() => {
    return [...solanaNFTs.value, ...icpNFTs.value];
  });

  const isWalletOptionsLoading = computed(() => isFetchingPairedWallets.value);

  const hasWalletOptions = computed(
    () =>
      walletOptions.value.filter(
        (option) => option.id !== allWalletsOption.value.id,
      ).length > 0,
  );

  return {
    allWalletsOption,
    walletOptions,
    hasWalletOptions,
    isWalletOptionsLoading,
    isFTsLoading,
    fungibleTokens,
    isNFTsLoading,
    nonFungibleTokens,
    fetchWalletOptions,
    walletAddresses,
  };
};
