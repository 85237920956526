import { client as icClientInstance } from '@/utils/dfinity';
import type { _SERVICE } from 'dfx/edge/edge.did';

export const edgeActor = () => {
  if (!icClientInstance.edgeActor) {
    throw new Error('EdgeActor is not defined');
  }
  return icClientInstance.edgeActor as unknown as _SERVICE;
};
export const actor = () => {
  if (!icClientInstance.actor) {
    throw new Error('Actor is not defined');
  }
  return icClientInstance.actor as unknown as _SERVICE;
};
export const signer = () => {
  if (!icClientInstance.signer) {
    throw new Error('Signer is not defined');
  }
  return icClientInstance.signer;
};
