<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useQueryClient } from '@tanstack/vue-query';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { ResponsiveOverlayCard } from '@/shared/ui/responsive-overlay-card';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useShareDialog } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import type { IcpNonFungibleToken } from '../types';
  import { useTokenDialog } from '../model/composables/use-token-dialog';
  import {
    tokenIcpQueryKey,
    tokenNonFungibleQueryKey,
    tokenQueryKey,
  } from '../api/keys';

  const props = defineProps<{
    token: IcpNonFungibleToken;
  }>();

  defineEmits<{
    (e: 'make-pfp'): void;
  }>();

  const queryClient = useQueryClient();
  const { openShareDialog } = useShareDialog();
  const { openNonFungieTokenTransferDialog, closeDialog } = useTokenDialog();
  const isImageError = ref(false);

  const isYumiMarketplace = computed(() => props.token?.marketplace === 'yumi');

  const fetchImage = (urlString: string, width: number, quality: number) => {
    try {
      const url = new URL(urlString);
      let pathname = url.pathname;
      pathname = pathname
        .replace(/width=\d+/, `width=${width}`)
        .replace(/quality=\d+/, `quality=${quality}`);
      url.pathname = pathname;
      return url.toString();
    } catch (error) {
      console.error('Invalid URL:', error);
      return '';
    }
  };

  const openTransferDialog = async () => {
    const success = await openNonFungieTokenTransferDialog(props.token);
    if (success) {
      queryClient.invalidateQueries({
        queryKey: [tokenQueryKey, tokenIcpQueryKey, tokenNonFungibleQueryKey],
      });
      closeDialog();
    }
  };
</script>
<template>
  <responsive-overlay-card class="group">
    <div class="size-full aspect-square">
      <iframe
        v-if="isYumiMarketplace"
        :src="token.tokenUrl"
        class="h-full aspect-square"
      />
      <img
        v-else-if="token.tokenDisplayUrl"
        v-lazy="{
          src: fetchMedia(fetchImage(token.tokenDisplayUrl, 512, 100)),
          error: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
          ),
        }"
        :alt="token.name"
        @error="isImageError = true"
        class="aspect-square"
      />
      <span class="absolute bottom-4 left-4 font-bold" v-if="isImageError">
        {{ $t('wallets.failedToLoad') }}
      </span>
      <div
        class="absolute top-6 left-6 drop-shadow-lighter z-1 group-hover:drop-shadow-heavy"
      >
        <div
          class="flex justify-center items-center rounded-full bg-gray-900 size-10"
        >
          <base-icon name="chain-icp" size="size-5" />
        </div>
      </div>
    </div>
    <template #footer>
      <footer class="flex justify-between items-center w-full p-6 font-medium">
        <span>{{ token.name }}</span>
        <span>#{{ token.tokenIndex }}</span>
      </footer>
    </template>
    <template #overlay>
      <div class="flex justify-end items-center w-full">
        <base-dropdown
          v-if="token.marketplace_url || token.canTransfer || token.canSetPFP"
          placement="left-start"
          custom-content-classes="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden drop-shadow-light"
          :distance="-5"
        >
          <template #button="{ toggle }">
            <base-button variant="custom" size="small" @click="toggle">
              <base-icon
                name="vertical-dots"
                size="size-5"
                class="text-white"
              />
            </base-button>
          </template>
          <template #content="{ hide }">
            <ul class="flex flex-col">
              <li
                v-if="token.marketplace_url"
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), openShareDialog(token.marketplace_url)"
              >
                <span class="whitespace-nowrap">
                  {{ $t('share') }}
                </span>
              </li>
              <li
                v-if="token.canTransfer"
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), openTransferDialog()"
              >
                <span class="whitespace-nowrap">
                  {{ $t('transfer') }}
                </span>
              </li>
              <li
                v-if="!isImageError && token.canSetPFP"
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), $emit('make-pfp')"
              >
                <span class="whitespace-nowrap">
                  {{ $t('nftAirdrop.setAsPFP') }}
                </span>
              </li>
            </ul>
          </template>
        </base-dropdown>
      </div>

      <base-button
        v-if="token.marketplace_url"
        variant="glass"
        size="small"
        rounded="rounded-full"
        class="ml-3.5"
        target="_blank"
        :to="token.marketplace_url"
      >
        <base-icon name="export" size="size-5" />
        <span>entrepot.app</span>
      </base-button>
    </template>
  </responsive-overlay-card>
</template>
