import axios from 'axios';

const defaultParams = {
  canGift: false,
  canClaim: false,
  canTransfer: true,
  canSetPFP: true,
};

class NFTRegistry {
  constructor() {
    this.debug = import.meta.env.DEV;
  }

  async list() {
    const data = [
      ...this.c3List(),
      ...(await this.extList()),
      ...this.altExtList(),
      ...this.dripList(),
      ...this.dip721v1List(),
      ...this.dip721v2List(),
      ...this.sbtList(),
    ];

    return data.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.cid === thing.cid),
    );
  }

  sbtList() {
    return [
      {
        name: 'SNS-1 SBT',
        cid: 'cfnba-iiaaa-aaaab-qahoq-cai',
        standard: 'sbt',
        ...defaultParams,
        canTransfer: false,
      },
      {
        name: 'DSCVR OG 22',
        cid: 'e4cao-6iaaa-aaaab-qah2q-cai',
        standard: 'sbt',
        ...defaultParams,
        canTransfer: false,
      },
    ];
  }

  dip721v1List() {
    return [
      {
        name: 'DSCVR OG Badge 2021',
        cid: 's6hwe-laaaa-aaaab-qaeba-cai',
        standard: 'dip721v1',
        ...defaultParams,
        canTransfer: false,
      },
    ];
  }

  dip721v2List() {
    return [
      {
        name: 'ICP Community Conference 2023',
        cid: 'ftzkw-oiaaa-aaaal-aciga-cai',
        standard: 'dip721v2',
        ...defaultParams,
        canTransfer: false,
      },
    ];
  }

  dripList() {
    return [];
  }

  c3List() {
    return [
      {
        name: 'World Epoch',
        cid: 'cec6g-lyaaa-aaaah-qc4lq-cai',
        claimImage: 'https://imgur.com/pvtgVam.png',
        standard: 'c3v2',

        ...defaultParams,

        canSetPFP: false,
      },
      {
        name: 'ICMetaCatNFT',
        cid: 'k3wif-yyaaa-aaaah-qc2ca-cai',
        claimImage: 'https://imgur.com/pvtgVam.png',
        standard: 'c3',

        ...defaultParams,
      },
      {
        name: 'ICityNFT',
        cid: 'q5gez-uaaaa-aaaah-qcyuq-cai',
        claimImage: 'https://i.imgur.com/Sz1E5AJ.png',
        standard: 'c3',

        ...defaultParams,
      },
      {
        name: 'IC Lions',
        cid: 'ygenm-saaaa-aaaah-qczaq-cai',
        claimImage: 'https://i.imgur.com/f5R6m1V.png',
        standard: 'c3',

        ...defaultParams,
      },
      {
        name: 'DfinityGang',
        cid: '2x3lw-tiaaa-aaaah-qcvca-cai',
        claimImage: 'https://i.imgur.com/MbwK9NL.png',
        standard: 'c3',

        ...defaultParams,
      },
      {
        name: 'Avocado Research',
        cid: 'bjcsj-rqaaa-aaaah-qcxqq-cai',
        standard: 'c3v3',

        ...defaultParams,
      },
    ];
  }

  async extList() {
    const results = await axios.get('https://api.dscvr.one/api/token/list');
    const transformedData = results.data.data
      .filter((x) => x.enabled == true)
      .map((x) => {
        return {
          standard: 'ext',
          name: x.name,
          cid: x.canisterId,

          ...defaultParams,
          marketplace: x.marketplace,
          marketplace_url: x.marketplace_url,
          canGift: true,
        };
      });

    //remove dupes by cid
    return transformedData.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.cid === thing.cid),
    );
  }
  altExtList() {
    return [
      {
        name: 'Baby Arof Army',
        cid: 'zgt7n-zqaaa-aaaag-qawka-cai',
        standard: 'ext',

        ...defaultParams,
      },
      {
        name: 'Plethora Items',
        cid: '4qmvs-qyaaa-aaaal-ab2rq-cai',
        standard: 'ext',

        ...defaultParams,
      },
      {
        name: 'Starfish',
        cid: 'vcpye-qyaaa-aaaak-qafjq-cai',
        standard: 'ext',

        ...defaultParams,
      },
      {
        name: 'BABY AROF',
        cid: 'auggb-4iaaa-aaaag-qatja-cai',
        standard: 'ext',

        ...defaultParams,
      },
      {
        name: 'IC Ghosts NFT',
        cid: 'xzcnc-myaaa-aaaak-abk7a-cai',
        standard: 'ext',

        ...defaultParams,
      },
    ];
  }
}

export const nftRegistry = new NFTRegistry();
