<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useVModel } from '@vueuse/core';
  import PostFriends from '../PostFriends.vue';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { useBreakpoint } from '@/shared/model';

  const props = defineProps<{
    content: ContentView;
    modelValue: boolean;
    enabled: boolean;
  }>();

  const emits = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanMd } = useBreakpoint();
  const showRelevantComments = useVModel(props, 'modelValue', emits);

  const maxNamesCountOrIconsCount = computed(() =>
    isSmallerThanMd.value ? 1 : 3,
  );
  const othersText = computed(() => {
    if (props.content.friends.length <= 2) {
      return t('postFeature.replied');
    }
    const othersCount = props.content.friends.length - 2;
    return `(${othersCount}) ${t('postFeature.replied')}`;
  });
</script>

<template>
  <div class="flex justify-end w-full py-2">
    <post-friends
      v-if="content.friends.length > 0"
      :item="content"
      :friends="content.friends"
      :max-icons-count="maxNamesCountOrIconsCount"
      :max-names-count="maxNamesCountOrIconsCount"
      class="md:flex-1"
      :class="props.enabled ? 'cursor-pointer' : 'cursor-auto'"
      @click.stop.prevent="
        props.enabled && (showRelevantComments = !showRelevantComments)
      "
    >
      <span class="text-gray-400 lowercase">{{ othersText }}</span>
    </post-friends>
    <button
      v-if="props.enabled"
      type="button"
      class="flex justify-end items-center text-gray-400 gap-1 text-sm"
      @click.stop.prevent="showRelevantComments = !showRelevantComments"
    >
      <span class="hidden md:block">
        {{ showRelevantComments ? $t('hide') : $t('show') }}
      </span>
      <base-icon
        :name="
          showRelevantComments ? 'chevron-up-filled' : 'chevron-down-filled'
        "
        size="size-4"
      />
    </button>
  </div>
</template>
