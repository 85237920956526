<script setup lang="ts">
  import { useUser } from '@/entities/user';
  import { usePostDialog } from '@/features/post';
  import { useAuth } from '@/entities/auth';

  defineProps<{
    slug?: string;
  }>();

  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();

  const createPost = () => {
    return isLoggedIn.value
      ? openCreateEditPostDialog()
      : showLoginSignUpDialog();
  };
</script>

<template>
  <div class="grid justify-center grid-cols-1 gap-2 py-4">
    <p>
      Wait,
      <span v-if="slug" class="primary-text-500">
        {{ slug }}
      </span>
      <span v-else> this place </span>
      is empty!
    </p>

    <div v-if="slug" class="inline-flex justify-center w-full">
      <base-button
        variant="primary"
        size="full"
        class="gap-2"
        @click="createPost"
      >
        <base-icon name="pencil" size="w-4 h-4" />
        <span>{{ $t('createPost') }}</span>
      </base-button>
    </div>
    <div>
      think <span class="rainbow-text">CrEaTiVely</span> and listen to the
      voices in your brain!
    </div>
  </div>
</template>
