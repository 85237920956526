<template>
  <input
    :value="modelValue"
    :placeholder="placeholder"
    :type="type"
    class="block w-full text-sm font-medium text-white placeholder-gray-400 bg-gray-900 border border-transparent rounded-lg purple-caret focus:ring-0 focus:border-indigo-600 hover:bg-gray-700"
    :class="[outline]"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
  export default {
    name: 'default-input',
    props: {
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: '',
      },
      isError: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {};
    },
    computed: {
      outline() {
        return this.getBorder();
      },
    },
    methods: {
      getBorder() {
        return this.isError ? 'border border-red-500 focus:border-red-500' : '';
      },
    },
  };
</script>
