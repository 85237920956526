<script lang="ts" setup>
  import { ref } from 'vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useI18n } from 'vue-i18n';
  import ContinueButton from '../ContinueButton.vue';
  import { OnboardingStep } from '@/shared/model';
  import { useClaimOnboardingRewardMutation } from '../../api/use-claim-onboarding-reward.mutation';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { promiseTimeout } from '@vueuse/core';

  const { t } = useI18n({
    useScope: 'global',
  });
  useGtm()?.trackEvent({ event: 'Points_Claimed_Step_5' });
  const { mutate: claimOnboardingRewardMutation } =
    useClaimOnboardingRewardMutation();

  const isClaimed = ref(false);

  const onClickClaim = async () => {
    isClaimed.value = true;
    claimOnboardingRewardMutation();
    await promiseTimeout(3000);
  };
</script>

<template>
  <div
    v-show="isClaimed"
    class="absolute bottom-full md:bottom-10 left-1/2 -translate-x-1/2 w-[128%] -z-1"
  >
    <img
      :src="
        fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/confettis.gif`, {
          width: 634,
          height: 634,
          fit: 'contain',
        })
      "
    />
  </div>
  <div class="relative flex flex-col gap-6 text-center">
    <div class="text-2xl leading-7 font-bold tracking-[0.32px]">
      {{ t('onboarding.rewards.title') }}
    </div>
    <div class="text-gray-400 text-sm">
      {{ t('onboarding.rewards.description') }}
    </div>
    <continue-button
      :current-step="OnboardingStep.REWARD_DSCVR_POINTS"
      :disabled="isClaimed"
      :on-custom-action="onClickClaim"
    >
      {{
        isClaimed
          ? t('onboarding.rewards.claimed')
          : t('onboarding.rewards.claimNow')
      }}
    </continue-button>
  </div>
</template>
