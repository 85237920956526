<script lang="ts" setup>
  import { ref, toRef } from 'vue';
  import { PORTAL_CONTENT } from '@/common';
  import {
    getPortalIcon,
    PortalTooltip,
    useGetPortalBySlugQuery,
  } from '@/entities/portal';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { fromNow } from '@/utils';
  import { UserTooltip } from '@/entities/user';
  import { useRouter } from 'vue-router';
  import { useShareDialog } from '@/shared/model';
  import { useI18n } from 'vue-i18n';

  const props = withDefaults(
    defineProps<{
      content: ContentView;
      isThreaded?: boolean;
      showPortalTooltip?: boolean;
    }>(),
    {
      isThreaded: false,
      showPortalTooltip: true,
    },
  );

  const emit = defineEmits<{
    (e: 'clicked-user-tooltip'): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const router = useRouter();
  const { openSharePortalProfile } = useShareDialog();
  const { data: portalView } = useGetPortalBySlugQuery(
    toRef(() => props.content.portal.slug),
    true,
  );

  const onClickPortalIcon = () => {
    emit('clicked-user-tooltip');
    router.push({
      name: PORTAL_CONTENT,
      params: {
        slug: props.content.portal.slug,
      },
    });
  };
</script>

<template>
  <div class="flex gap-4" v-if="portalView">
    <template v-if="showPortalTooltip">
      <base-button
        variant="link"
        custom-classes="relative flex items-start justify-center ease-transition-all rounded-xl bg-transparent"
        @click="onClickPortalIcon"
      >
        <img
          v-lazy="getPortalIcon(portalView)"
          alt="Portal icon"
          class="w-12 h-12 rounded-lg min-w-12"
        />
      </base-button>
    </template>
    <div class="flex flex-col">
      <div v-if="showPortalTooltip" class="font-semibold tracking-[0.16px]">
        <portal-tooltip
          :portal-view="portalView"
          @share="openSharePortalProfile"
        />
      </div>
      <div class="flex flex-wrap items-center gap-1.5">
        <user-tooltip
          :class="{
            '-ml-9': showPortalTooltip,
          }"
          :user="content.owner"
          :color="content.owner_color"
          avatar-size="md"
          :portal-info-view="content.portal"
          @clicked-user-tooltip="emit('clicked-user-tooltip')"
        />
        <template v-if="isThreaded">
          <span class="text-gray-400 text-sm">
            {{ t('repliedTo') }}
            <span class="text-white">
              <strong class="truncate">
                {{ content.root_parent_content[0]?.owner.username }}
              </strong>
              's post
            </span>
          </span>
        </template>
        <span class="text-gray-400 text-sm">
          {{ fromNow(content.created_at) }}
        </span>
      </div>
    </div>
  </div>
</template>
