<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { shortenString } from '@/shared/lib';
  import { useDevice } from '@/shared/model';
  import { dscvrApi } from '@/shared/api';
  import { useUser } from '@/entities/user';
  import { PairButton } from '@/entities/user-setting';
  import {
    isMetamaskInstalled,
    useGenerateMobileDeeplink,
    useWalletPairing,
    usePairEvmWallet,
    WalletIcon,
  } from '@/entities/wallets';

  const props = defineProps<{
    walletType: dscvrApi.wallet.WalletType;
  }>();

  const emit = defineEmits<{
    (event: 'paired'): void;
    (event: 'unpaired'): void;
  }>();

  const { isMobileOrTablet } = useDevice();
  const { currentUserPrincipal } = useUser();

  const {
    currentPairingWallet,
    isFetchingPairedWallets,
    pairedWallets,
    unpairWallet,
    waitForMobileWalletToPair,
  } = useWalletPairing();
  const { deepLink, restartGeneration } =
    useGenerateMobileDeeplink(currentUserPrincipal);
  const { connectAndLinkWallet } = usePairEvmWallet();

  const pairedWallet = computed(() =>
    pairedWallets.value.find(
      (wallet) => wallet.walletType.slug === props.walletType,
    ),
  );

  const mainWalletAddress = computed(() => {
    return pairedWallet.value?.address;
  });

  const isWalletPairing = computed(
    () => currentPairingWallet.value === props.walletType,
  );

  const isReady = computed(() => {
    const isDeepLinkPending =
      !pairedWallet.value && isMobileOrTablet.value && !deepLink.value;
    return (
      !isWalletPairing.value &&
      !isFetchingPairedWallets.value &&
      !isDeepLinkPending
    );
  });

  const deepLinkWindowOpened = async (deepLinkWindow: Window | null) => {
    // regenerate to invalidate previous OTP
    restartGeneration(props.walletType);
    const paired = await waitForMobileWalletToPair(props.walletType);
    // This closes a new tab left empty on IOS tablet
    if (deepLinkWindow) {
      deepLinkWindow.close();
    }
    if (paired) {
      emit('paired');
    }
  };

  const togglePair = () => {
    if (!pairedWallet.value) {
      // For now is hardcoded to Metamask
      const isInstalled = isMetamaskInstalled();

      if (isMobileOrTablet.value && !isInstalled) {
        if (deepLink.value) {
          // Important: window.open is very restrictive on mobile browsers, it's not reliable
          // and using an anchor `<a href="..." ` could solve the problem but does not work with some applications
          const deepLinkWindow = window.open(deepLink.value);
          deepLinkWindowOpened(deepLinkWindow);
        }
      } else {
        connectAndLinkWallet();
      }
    } else {
      unpairWallet(mainWalletAddress.value!);
      emit('unpaired');
    }
  };

  onMounted(async () => {
    if (isMobileOrTablet.value) {
      await restartGeneration(props.walletType);
    }
  });
</script>

<template>
  <pair-button
    :is-loading="!isReady"
    :connected-id="mainWalletAddress"
    @toggle="togglePair"
  >
    <wallet-icon id="metamask" size="size-10" />
    <template #name>
      <span class="capitalize">{{ walletType }} (Polygon)</span>
    </template>
    <template #connection>
      {{ $t('userSettings.address') }}:
      {{ shortenString(mainWalletAddress || '') }}
    </template>
  </pair-button>
</template>
