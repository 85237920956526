<script setup lang="ts">
  import type { UserView } from 'dfx/edge/edge.did';
  import { USER_CONTENT } from '@/common';
  import { ClampLines } from '@/shared/ui/clamp-lines';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { formatToUnits } from '@/shared/lib';
  import { useUser } from '../model/composables/use-user';
  import StreakCount from './StreakCount.vue';
  import { coverGradients } from '../lib/cover-gradients';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  defineProps<{
    user: UserView;
  }>();

  const { getCreationDate, getFormattedTokens } = useUser();
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: USER_CONTENT,
      params: { username: user.username },
    }"
    custom-classes="flex flex-col md:flex-row items-center gap-6 md:gap-10 w-full group rounded-xl p-6 sm:p-0 border border-gray-800 sm:border-none overflow-hidden"
  >
    <div class="relative flex gap-10 w-full md:w-auto">
      <div
        class="hidden md:flex justify-center items-center bg-cover bg-center rounded-lg w-16 h-16 md:w-52 md:h-52 relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/10 before:to-black/90 before:z-2"
      >
        <user-avatar
          v-if="user"
          :item="user"
          size="w-21 h-21 min-w-21"
          class="z-5"
        />
        <cover-photo
          :src="user.cover_photo[0]"
          :gradients="coverGradients"
          class="absolute z-1"
        />
      </div>
      <user-avatar
        v-if="user"
        :item="user"
        size="w-16 h-16 min-w-16"
        class="md:hidden"
      />
      <div
        class="flex md:flex-col justify-center items-center gap-3.5 w-full md:w-40"
      >
        <div
          class="flex flex-col justify-center gap-3.5 flex-1 md:flex-initial md:w-40"
        >
          <span class="text-lg font-semibold leading-5 truncate text-white">
            {{ user.username }}
          </span>
          <div class="flex justify-start gap-4 flex-wrap">
            <div class="flex items-center gap-1">
              <base-icon name="dscvr" size="w-4 h-4" />
              <span class="font-medium text-sm">
                {{ getFormattedTokens(user.rights, true) }}
              </span>
            </div>
            <div class="flex items-center gap-1">
              <base-icon
                name="vuesax-linear-notification-status"
                size="w-4 h-4"
              />
              <span class="font-medium text-sm">
                {{ formatToUnits(user.num_posts) }}
              </span>
              <span class="lowercase hidden sm:block">{{ $t('posts') }}</span>
            </div>
          </div>
          <streak-count :count="Number(user.active_streak)" />
        </div>
        <div class="flex sm:justify-start sm:self-start md:hidden">
          <follow-user-button size="x-small" :user="user" />
        </div>
      </div>
    </div>
    <div class="hidden sm:flex items-center w-full md:w-72">
      <clamp-lines
        :text="
          user.bio
            ? user.bio
            : `${$t('memberSince')} ${getCreationDate(user.created_at)}`
        "
        :lines="4"
      />
    </div>
    <div class="hidden md:flex gap-3.5 flex-1 w-full justify-start">
      <follow-user-button size="x-small" :user="user" />
    </div>
  </base-button>
</template>
