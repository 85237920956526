<script lang="ts" setup>
  import { useUserLinks } from '@/widgets/app-header';
  import type { UserMenuProps, UserEmit } from '@/widgets/app-header';
  import { useBreakpoint } from '@/shared/model';

  const props = defineProps<UserMenuProps>();

  const emit = defineEmits<UserEmit>();

  const { otherLinks, customLinksClasses, logout } = useUserLinks(props, emit);
  const { isSmallerThanLg } = useBreakpoint();
</script>

<template>
  <template v-for="link in otherLinks" :key="link.label">
    <base-button
      variant="link"
      :custom-classes="customLinksClasses"
      :to="link.route"
      target="_blank"
      @click="emit('update:opened', false)"
    >
      <span>
        {{ link.label }}
      </span>
    </base-button>
  </template>
  <base-button
    v-if="isSmallerThanLg"
    variant="link"
    :custom-classes="`${customLinksClasses} text-red-300`"
    @click="logout"
  >
    Logout
  </base-button>
</template>
