import { computed } from 'vue';
import { useOnboarding } from './use-onboarding';
import { OnboardingLootboxStep, OnboardingStep } from '@/shared/model';
import { useSetOnboardingStateMutation } from '../../api/use-set-onboarding-state.mutation';
import type { OnboardingEventLabel } from '../../types';

export const useOnboardingLootboxStateMachine = () => {
  const { mutate: setOnboardingStateMutation } =
    useSetOnboardingStateMutation();
  const {
    openConfiguredOnboardingLootboxDialog,
    onboardingState,
    isUserDiscoverStepMissing,
    isUserJoinPortalsStepMissing,
  } = useOnboarding();

  const onEvent = (
    event: OnboardingEventLabel,
    currentStep: OnboardingLootboxStep,
    missingOnboardingStep?: OnboardingStep,
  ) => {
    if (event === 'continue') {
      onContinue(currentStep, missingOnboardingStep);
    } else {
      onSkip(currentStep);
    }
  };

  const onOpenDialog = (target: OnboardingLootboxStep) => {
    openConfiguredOnboardingLootboxDialog(target);
  };

  const onContinue = (
    currentStep: OnboardingLootboxStep,
    missingOnboardingStep?: OnboardingStep,
  ) => {
    if (missingOnboardingStep) {
      onboardingState.value = {
        ...onboardingState.value,
        completedSteps: [
          ...onboardingState.value.completedSteps,
          missingOnboardingStep,
        ],
      };
      console.log('onboardingState.value', onboardingState.value);
      setOnboardingStateMutation(onboardingState.value);
    }
    onOpenDialog(states.value[currentStep].target);
  };

  const onSkip = (currentStep: OnboardingLootboxStep) => {
    onOpenDialog(states.value[currentStep].target);
  };

  const states = computed<{
    [key in OnboardingLootboxStep]: {
      target: OnboardingLootboxStep;
      on: (event: OnboardingEventLabel) => void;
    };
  }>(() => ({
    [OnboardingLootboxStep.MISSING_STEPS]: {
      target: OnboardingLootboxStep.COMPLETE_ONBOARDING_STEPS,
      on: (event) => onEvent(event, OnboardingLootboxStep.MISSING_STEPS),
    },
    [OnboardingLootboxStep.COMPLETE_ONBOARDING_STEPS]: {
      target: isUserDiscoverStepMissing.value
        ? OnboardingLootboxStep.MISSING_DISCOVER_USERS
        : isUserJoinPortalsStepMissing.value
        ? OnboardingLootboxStep.MISSING_JOIN_PORTALS
        : OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED,
      on: (event) =>
        onEvent(event, OnboardingLootboxStep.COMPLETE_ONBOARDING_STEPS),
    },
    [OnboardingLootboxStep.MISSING_DISCOVER_USERS]: {
      target: isUserJoinPortalsStepMissing.value
        ? OnboardingLootboxStep.MISSING_JOIN_PORTALS
        : OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED,
      on: (event) =>
        onEvent(
          event,
          OnboardingLootboxStep.MISSING_DISCOVER_USERS,
          OnboardingStep.DISCOVER_USERS,
        ),
    },
    [OnboardingLootboxStep.MISSING_JOIN_PORTALS]: {
      target: OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED,
      on: (event) =>
        onEvent(
          event,
          OnboardingLootboxStep.MISSING_JOIN_PORTALS,
          OnboardingStep.JOIN_PORTALS,
        ),
    },
    [OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED]: {
      target: OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED,
      on: (event) =>
        onEvent(event, OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED),
    },
    [OnboardingLootboxStep.MISSING_WALLET]: {
      target: OnboardingLootboxStep.MISSING_WALLET,
      on: (event) => onEvent(event, OnboardingLootboxStep.MISSING_WALLET),
    },
    [OnboardingLootboxStep.DONE]: {
      target: OnboardingLootboxStep.DONE,
      on: (event) => onEvent(event, OnboardingLootboxStep.DONE),
    },
  }));

  return {
    states,
  };
};
