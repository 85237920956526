<script setup lang="ts">
  import { computed, onMounted, ref, type Ref } from 'vue';
  import { useForm } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import * as yup from 'yup';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { FieldWrapper, SelectFieldItem } from '@/shared/ui/fields';
  import {
    SelectFormField,
    TextFormField,
    CheckboxFormField,
  } from '@/shared/ui/form-fields';
  import {
    useNFTGatingSetup,
    MultichainCollectionPreview,
  } from '@/features/gating';
  import type { RoleView, PortalView } from 'dfx/edge/edge.did';
  import { dscvrApi } from '@/shared/api';
  import { config } from '@/shared/lib';

  interface FormFields {
    collection_address?: string;
    role?: RoleView | string;
    new_role_name?: string;
    show_in_marketplace?: boolean;
  }

  const props = defineProps<{
    item: dscvrApi.multichainGating.MultichainGateDto;
    portalView: PortalView;
  }>();

  const emit = defineEmits<{
    (e: 'saved'): void;
  }>();

  const {
    portalRoles,
    roleOptions,
    fetchPortalRoles,
    updateNftCollection,
    validateMintAddress,
    getNftCollectionDetailsByAddress,
    validateRoleName,
    createNewRole,
  } = useNFTGatingSetup(props.portalView);
  const { t } = useI18n({ useScope: 'global' });

  const schema = yup.object({
    collection_address: yup
      .string()
      .required(t('collectionAddressErrorRequired'))
      .test({
        name: 'collection_address',
        message: t('collectionAddressErrorInvalid'),
        test: async (value) => {
          if (!value || !validateMintAddress(value)) {
            multichainNFTCollection.value = [];
            return false;
          }
          multichainNFTCollection.value =
            await getNftCollectionDetailsByAddress(value);
          return multichainNFTCollection.value.length > 0;
        },
      }),
    role: yup.mixed<RoleView | string>().required(t('roleErrorRequired')),
    new_role_name: yup.string().when('roleErrorRequired', {
      is: (role?: RoleView | string) => role && typeof role === 'string',
      then: () =>
        yup
          .string()
          .required(t('roleNameErrorRequired'))
          .max(20, t('roleNameErrorLength'))
          .test({
            name: 'new_role_name',
            message: t('roleNameErrorDuplicated'),
            test: (value) => {
              if (!value) {
                return false;
              }
              return validateRoleName(value);
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
    show_in_marketplace: yup.boolean().notRequired().default(true),
  });

  const multichainNFTCollection: Ref<
    dscvrApi.multichainGating.MultichainCollectionDetailDto[] | undefined
  > = ref();

  const initialValues = computed(() => ({
    collection_address: props.item.nftCollectionAddress,
    role: props.item.roleId
      ? portalRoles.value?.find(
          (role) => role.id.toString() === props.item.roleId,
        )
      : undefined,
    show_in_marketplace: props.item.showMarketplaceListings,
  }));

  const { handleSubmit, errors, controlledValues } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });

  const isNewRole = computed(
    () =>
      controlledValues.value.role &&
      typeof controlledValues.value.role === 'string',
  );

  const getRequest = async (
    values: FormFields,
  ): Promise<dscvrApi.multichainGating.UpdateMultichainGateDto | undefined> => {
    let roleId: string;
    if (typeof values.role === 'string') {
      const newRole = await createNewRole(values.new_role_name!);
      if (!newRole) {
        return;
      }
      roleId = newRole.id.toString();
    } else {
      roleId = values.role!.id!.toString();
    }

    return {
      portalSlug: props.portalView.slug,
      name: multichainNFTCollection.value![0].name,
      nftCollectionAddress: values.collection_address,
      roleId,
      showMarketplaceListings: values.show_in_marketplace,
      rules: [],
    };
  };

  const submit = handleSubmit(async (values) => {
    const request = await getRequest(values);
    if (!request) {
      return;
    }
    const response = await updateNftCollection(props.item.id, request);
    if (response) {
      emit('saved');
    }
    if (typeof values.role === 'string') {
      fetchPortalRoles();
    }
  });

  onMounted(async () => {
    multichainNFTCollection.value = await getNftCollectionDetailsByAddress(
      props.item.nftCollectionAddress,
    );
  });
</script>

<template>
  <form class="mt-6" @submit="submit">
    <div class="flex flex-col gap-10">
      <div class="-mt-6">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('itemId') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ item.id }}</span>
        </field-wrapper>
      </div>

      <div class="-mt-6">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('network') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ item.network.name }}</span>
        </field-wrapper>
      </div>

      <div>
        <text-form-field
          name="collection_address"
          :label="$t('collectionAddress')"
          autocomplete="off"
          :error="errors.collection_address"
        >
          <template #suffix>
            <base-icon
              v-if="multichainNFTCollection?.length"
              name="filled-check-circle"
              size="w-5 h-5"
              class="text-green-300"
            />
            <base-tooltip v-else :content="$t('collectionAddressLegend')">
              <base-icon
                name="filled-exclamation-triangle"
                size="w-5 h-5"
                class="text-orange-300"
              />
            </base-tooltip>
          </template>
        </text-form-field>
      </div>

      <div class="-mt-6">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('collectionName') }}
        </label>
        <field-wrapper>
          <multichain-collection-preview
            v-if="multichainNFTCollection?.length"
            :item="multichainNFTCollection[0]"
            class="text-gray-400 flex-1"
          />
          <span
            v-else-if="!multichainNFTCollection"
            class="text-gray-400 flex-1 ml-10"
            >{{ item.name }}</span
          >
          <span v-else class="text-gray-400 flex-1 ml-10">&nbsp;</span>

          <base-icon
            v-if="multichainNFTCollection?.length"
            name="filled-check-circle"
            size="w-5 h-5"
            class="text-green-300"
          />
          <base-icon
            v-else
            name="filled-exclamation-triangle"
            size="w-5 h-5"
            class="text-orange-300"
          />
        </field-wrapper>
      </div>

      <div>
        <select-form-field
          name="role"
          :items="roleOptions"
          :label="$t('nftHolderRole')"
          :error="errors.role"
        >
          <template #label="{ item: roleOption }">
            <template v-if="typeof roleOption === 'string'">
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ roleOption }}</span>
            </template>
            <span v-else>{{ roleOption.name }}</span>
          </template>
          <template #item="{ item: roleOption }">
            <select-field-item v-if="typeof roleOption === 'string'" separator>
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ roleOption }}</span>
            </select-field-item>
            <select-field-item v-else>
              <span>{{ roleOption.name }}</span>
            </select-field-item>
          </template>
        </select-form-field>
      </div>

      <div v-if="isNewRole">
        <text-form-field
          name="new_role_name"
          :label="$t('newRoleName')"
          autocomplete="off"
          :error="errors.new_role_name"
        />
      </div>

      <div v-if="config.ENABLE_MARKETPLACE_TAB" class="-mt-4">
        <checkbox-form-field name="show_in_marketplace" variant="toggle">
          {{ $t('showInMarketplace') }}
        </checkbox-form-field>
      </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex justify-end">
        <base-button variant="primary" size="small" class="w-auto">
          {{ $t('update') }}
        </base-button>
      </div>
    </div>
  </form>
</template>
