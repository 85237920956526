import { apiInstance } from '../base';
import type {
  ILootbox,
  ILootboxItem,
  ILootboxItemClaim,
  ILootboxOpen,
  LootboxAvailable,
  LootboxTargetResult,
} from './types';
import { LootboxOpenStatus, LootboxItemType } from './types';

export type {
  ILootbox,
  ILootboxItem,
  ILootboxItemClaim,
  ILootboxOpen,
  LootboxAvailable,
  LootboxTargetResult,
};
export { LootboxOpenStatus, LootboxItemType };

const BASE_URL = '/lootboxes';

export const getOpenedLootboxesItems = () => {
  return apiInstance
    .get<{ data: ILootboxItem[] }>(`${BASE_URL}/opened`)
    .then((response) => response.data.data);
};

export const claimLootboxItem = (lootboxId: string, tokenType: number) => {
  return apiInstance
    .post<{ data: ILootboxItemClaim }>(
      `${BASE_URL}/claim/${lootboxId}/${tokenType}`,
    )
    .then((response) => response.data.data);
};

export const getClaimableLootbox = () => {
  return apiInstance
    .get<{ data: LootboxAvailable }>(
      `${BASE_URL}/first?path=${window.location.pathname}`,
    )
    .then((response) => response.data.data);
};

// This will only work for staging backend and is disabled in production
export const deleteLootboxItem = (lootboxItemId: string) => {
  return apiInstance.delete(`${BASE_URL}/target-item/${lootboxItemId}`);
};

export const getGlobalClaimableLootboxById = (id: string) => {
  return apiInstance
    .get<{ data: LootboxAvailable }>(`${BASE_URL}/global/${id}`)
    .then((response) => response.data.data);
};

export const getLootboxTarget = (id: string) => {
  return apiInstance
    .get<{ data: LootboxAvailable | null }>(`${BASE_URL}/target/${id}`)
    .then((response) => response.data.data);
};

export const openLootbox = (id: string) => {
  return apiInstance
    .post<{ data: ILootboxOpen }>(`${BASE_URL}/open/${id}`)
    .then((response) => response.data.data);
};
