import sanitize from 'sanitize-html';
import { useDialog } from '@/shared/model';
import type { ContentView } from 'dfx/edge/edge.did';
import { sanitizeSettings } from '@/utils';
import { useUser } from '@/entities/user';
import CreatePostDialogContent from '../../components/CreatePostDialogContent.vue';

export const usePostDialog = () => {
  const { isLoggedIn } = useUser();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openCreateEditPostDialog = (options?: {
    editPost?: ContentView;
    initialContent?: string;
  }) => {
    return new Promise<ContentView | undefined>((resolve) => {
      openConfiguredDialog({
        content: {
          component: CreatePostDialogContent,
          props: options,
          emits: {
            postCreated: (post: ContentView) => {
              resolve(post);
              closeDialog();
            },
            postUpdated: (post: ContentView) => {
              resolve(post);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} p-6 min-w-160 !w-auto`,
          showCloseButton: false,
          closeOnClickOutside: false,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} !rounded-none h-full p-6`,
          contentClasses: '!overflow-visible flex-1',
          showHeader: false,
          closeOnClickOutside: false,
        },
        emit: () => {
          resolve(undefined);
        },
      });
    });
  };

  const openCreatePostDialog = (content: string) => {
    if (!isLoggedIn.value || !content.trim()) return;

    const initialContent = sanitize(content, sanitizeSettings);
    return openCreateEditPostDialog({
      initialContent,
    });
  };

  return {
    openCreateEditPostDialog,
    openCreatePostDialog,
    closeDialog,
  };
};
