<script setup lang="ts">
  import { onMounted } from 'vue';
  import ToggleButton from '@/components/buttons/ToggleButton.vue';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { usePreferenceSettings } from '@/entities/user-setting';

  const {
    languageSelectionDropdown,
    closeLanguageSelectionDropdown,
    languageOptions,
    language,
    getLanguage,
    setLanguage,
    translationSelectionDropdown,
    closeTranslationSelectionDropdown,
    translationOptions,
    translation,
    getTranslation,
    setTranslation,
    portalSortSelectionDropdown,
    closePortalSortSelectionDropdown,
    portalSortOptions,
    portalSort,
    getPortalSort,
    setPortalSort,
    nsfw,
    isNsfwLoading,
    getNSFW,
    setNSFW,
  } = usePreferenceSettings();

  const changeNSFW = async (value: boolean) => {
    await setNSFW(value);
  };

  onMounted(() => {
    getLanguage();
    getTranslation();
    getPortalSort();
    getNSFW();
  });
</script>

<template>
  <section class="flex flex-col space-y-8">
    <!-- Language Dropdown -->
    <div class="relative">
      <label
        for="languageSelectionDropdown"
        class="block text-base font-semibold leading-5"
      >
        {{ $t('selectSiteLanguage') }}
      </label>
      <base-button
        id="languageSelectionDropdown"
        variant="custom"
        custom-classes="mt-2 flex !justify-start relative w-full rounded-xl border-0 bg-black/32 cursor-pointer py-2 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-[#333B4780] focus:ring-1 focus:ring-inset focus:ring-[#333B4780] sm:text-sm sm:leading-6 transition-all duration-300 hover:bg-gray-950 disabled:opacity-75"
        @click="languageSelectionDropdown = !languageSelectionDropdown"
      >
        <template v-if="language">
          <span class="block truncate">
            {{ language.label }}
          </span>
        </template>
        <template v-else>
          <span class="block text-gray-400 truncate">
            {{ $t('selectSitePrimaryLanguage') }}
          </span>
        </template>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <base-icon name="chevron-down" size="h-4 w-4" class="text-gray-400" />
        </span>
      </base-button>
      <base-dropdown
        match-content-width
        v-model:opened="languageSelectionDropdown"
        placement="bottom-end"
        custom-content-classes="md:min-w-[638px] max-h-56 w-full !overflow-auto scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 rounded-xl bg-[#1c212e] text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
      >
        <template #content>
          <div
            v-for="(languageOption, idx) in languageOptions"
            :key="idx"
            class="cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-785"
            :class="{
              'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
                language && language.id === languageOption.id,
            }"
            @click="
              setLanguage(languageOption);
              closeLanguageSelectionDropdown();
            "
          >
            <div class="flex items-center">
              <span class="truncate">
                {{ languageOption.label }}
              </span>
            </div>
          </div>
        </template>
      </base-dropdown>
    </div>
    <!-- Translation Dropdown -->
    <div class="relative">
      <label
        for="translationSelectionDropdown"
        class="block text-base font-semibold leading-5"
      >
        {{ $t('selectSiteTranslatedLanguage') }}
      </label>
      <base-button
        id="translationSelectionDropdown"
        variant="custom"
        custom-classes="mt-2 flex !justify-start relative w-full rounded-xl border-0 bg-black/32 cursor-pointer py-2 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-[#333B4780] focus:ring-1 focus:ring-inset focus:ring-[#333B4780] sm:text-sm sm:leading-6 transition-all duration-300 hover:bg-gray-950 disabled:opacity-75"
        @click="translationSelectionDropdown = !translationSelectionDropdown"
      >
        <template v-if="translation">
          <span class="block truncate">
            {{ translation.label }}
          </span>
        </template>
        <template v-else>
          <span class="block text-gray-400 truncate">
            {{ $t('selectSiteTranslatedPrimaryLanguage') }}
          </span>
        </template>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <base-icon name="chevron-down" size="h-4 w-4" class="text-gray-400" />
        </span>
      </base-button>
      <base-dropdown
        match-content-width
        v-model:opened="translationSelectionDropdown"
        placement="bottom-end"
        custom-content-classes="md:min-w-[638px] max-h-56 w-full !overflow-auto scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 rounded-xl bg-[#1c212e] text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
      >
        <template #content>
          <div
            v-for="(availableTranslation, idx) in translationOptions"
            :key="idx"
            class="cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-785"
            :class="{
              'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
                translation && translation.id === availableTranslation.id,
            }"
            @click="
              setTranslation(availableTranslation);
              closeTranslationSelectionDropdown();
            "
          >
            <div class="flex items-center">
              <span class="truncate">
                {{ availableTranslation.label }}
              </span>
            </div>
          </div>
        </template>
      </base-dropdown>
    </div>
    <!-- Portal Sorting Dropdown -->
    <div class="!mt-20 relative">
      <label
        for="portalSortSelectionDropdown"
        class="block text-base font-semibold leading-5"
      >
        {{ $t('selectSitePortalSorting') }}
      </label>
      <base-button
        id="portalSortSelectionDropdown"
        variant="custom"
        custom-classes="mt-2 flex !justify-start relative w-full rounded-xl border-0 bg-black/32 cursor-pointer py-2 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-[#333B4780] focus:ring-1 focus:ring-inset focus:ring-[#333B4780] sm:text-sm sm:leading-6 transition-all duration-300 hover:bg-gray-950 disabled:opacity-75"
        @click="portalSortSelectionDropdown = !portalSortSelectionDropdown"
      >
        <template v-if="portalSort">
          <span class="block capitalize truncate">
            {{ portalSort.title }}
          </span>
        </template>
        <template v-else>
          <span class="block text-gray-400 truncate">
            Select portal sorting order
          </span>
        </template>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <base-icon name="chevron-down" size="h-4 w-4" class="text-gray-400" />
        </span>
      </base-button>
      <base-dropdown
        match-content-width
        v-model:opened="portalSortSelectionDropdown"
        placement="bottom-end"
        custom-content-classes="md:min-w-[638px] max-h-56 w-full !overflow-auto scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 rounded-xl bg-[#1c212e] text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
      >
        <template #content>
          <div
            v-for="(option, idx) in portalSortOptions"
            :key="idx"
            class="cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-785"
            :class="{
              'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
                portalSort && portalSort.id === option.id,
            }"
            @click="
              setPortalSort(option);
              closePortalSortSelectionDropdown();
            "
          >
            <div class="flex items-center">
              <span class="capitalize truncate">
                {{ option.title }}
              </span>
            </div>
          </div>
        </template>
      </base-dropdown>
    </div>
    <!-- Adult toggle -->
    <div class="relative flex items-center justify-between w-full">
      <overlay-loader rounded v-if="isNsfwLoading" />
      <div class="text-base leading-5">
        <span class="font-semibold">Adult content</span>
        <div class="font-normal mt-1 text-gray-400 text-sm tracking-[.005em]">
          Allow NSFW (Not Safe for Work) content to show up in your feed.
        </div>
      </div>
      <div>
        <toggle-button :checked="nsfw" @changed="changeNSFW" />
      </div>
    </div>
  </section>
</template>
