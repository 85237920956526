<template>
  <a
    v-if="link"
    :href="link"
    :target="target"
    :class="[classes, color, rounding, size, extraClasses]"
  >
    <slot />
    <div v-if="hasText">{{ text }}</div>
  </a>
  <button
    v-else
    :class="[classes, color, rounding, size, extraClasses]"
    :disabled="disabled"
    @click="handleButton"
  >
    <overlay-loader v-if="hasLoader && isLoading" :rounded="true" />

    <div v-if="hasIcon || isIcon">
      <slot />
    </div>

    <div v-if="hasText">
      {{ text }}
    </div>
  </button>
</template>

<script>
  import OverlayLoader from '../loaders/OverlayLoader.vue';

  export default {
    name: 'default-button',
    components: { OverlayLoader },
    props: {
      type: {
        type: String,
        default: 'button',
      },
      text: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      routeName: {
        type: String,
        default: '',
      },
      routeParams: {
        type: Object,
        default: () => null,
      },
      variant: {
        type: String,
        default: 'primary',
      },
      dimensions: {
        type: String,
        default: 'md',
      },
      hasText: {
        type: Boolean,
        default: true,
      },
      hasIcon: {
        type: Boolean,
        default: false,
      },
      isIcon: {
        type: Boolean,
        default: false,
      },
      hasLoader: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      rounding: {
        type: String,
        default: 'rounded-lg',
      },
      link: {
        type: String,
        default: null,
      },
      target: {
        type: String,
        default: '_blank',
      },
      extraClasses: {
        type: String,
        default: 'w-full',
      },
    },
    data() {
      return {
        classes:
          'px-5 inline-flex items-center justify-center whitespace-nowrap gap-1 capitalize relative leading-normal font-medium text-white text-center cursor-pointer focus:outline-none',
        size: this.getWidth(),
        color: this.getColor(),
      };
    },
    methods: {
      handleButton() {
        if (this.routeName)
          this.$router.push({
            name: this.routeName,
            params: this.routeParams,
          });
      },
      getColor() {
        switch (this.variant) {
          case 'primary':
            return 'btn-primary';
          case 'secondary':
            return 'btn-secondary';
          case 'tertiary':
            return 'btn-tertiary';
          case 'destructive':
            return 'btn-destructive';
          case 'none':
            return '';
          default:
            break;
        }
      },
      getWidth() {
        switch (this.dimensions) {
          case 'xs':
            return 'text-xs py-2';
          case 'sm':
            return 'text-sm py-2.5';
          case 'md':
            return 'text-base py-3';
          case 'lg':
            return 'text-base py-3.5';
          default:
            break;
        }
      },
    },
  };
</script>
