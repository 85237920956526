<script setup lang="ts">
  import { computed } from 'vue';
  import type { FrameActionPayload } from '@/features/frame';
  import StackTraceItem from './StackTraceItem.vue';

  const props = defineProps<{
    log: FrameActionPayload[];
  }>();

  const sortedLog = computed(() => [...props.log].reverse());
</script>

<template>
  <ul class="flex flex-col gap-6">
    <li
      v-for="(action, index) in sortedLog"
      :key="index"
      class="animate-flip-down animate-ease-in-out animate-duration-300"
    >
      <stack-trace-item
        :action="action"
        :action-index="sortedLog.length - index"
      />
    </li>
  </ul>
</template>
