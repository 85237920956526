import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import {
  sendVerificationEmailKey,
  verificationEmailStatusKey,
  validateVerificationEmailTokenKey,
} from './keys';
import type { EmailVerificationError } from 'dfx/edge/edge.did';

export const useSendVerificationEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['send-verification-email-mutation'],
    mutationFn: async () => {
      const data = await dscvrIcApi.userSettings.sendVerificationEmail();
      if ('Ok' in data) {
        return data.Ok;
      } else {
        throw data.Err.length > 0 ? data.Err[0] : data.Err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: sendVerificationEmailKey,
      });
      queryClient.invalidateQueries({
        queryKey: verificationEmailStatusKey,
      });
      queryClient.invalidateQueries({
        queryKey: validateVerificationEmailTokenKey,
      });
    },
    onError: (err: EmailVerificationError) => {
      throw err;
    },
  });
};
