<script lang="ts" setup>
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useUserLinks } from '@/widgets/app-header';
  import type { UserMenuProps, UserEmit } from '@/widgets/app-header';
  import { UserMenu, OtherMenu } from '@/widgets/app-header/components';

  const props = defineProps<UserMenuProps>();

  const emit = defineEmits<UserEmit>();
  const { isOpened } = useUserLinks(props, emit);
</script>

<template>
  <base-dropdown
    v-model:opened="isOpened"
    placement="bottom-end"
    :distance="15"
    :skidding="12"
    append-to-body
    custom-content-classes="border border-white border-opacity-16 bg-gray-950"
  >
    <template #content>
      <div class="flex flex-col w-60">
        <user-menu v-model:opened="isOpened" />
        <div class="m-auto h-px w-[200px] bg-gray-625" />
        <other-menu v-model:opened="isOpened" />
      </div>
    </template>
  </base-dropdown>
</template>
