<script lang="ts" setup>
  import { useMainMenu } from '@/composables';
  import { BetaBadge } from '@/components/widgets/beta-badge';
  import { useBetaFeatures } from '@/composables';

  const { isBetaEnabled } = useBetaFeatures();
  const { mainMenuLinks } = useMainMenu();
</script>

<template>
  <nav class="flex items-center justify-center text-gray-400 gap-x-7">
    <template v-for="link in mainMenuLinks" :key="link.name">
      <base-button
        v-if="
          !link.isHidden && !(link.isBeta && !isBetaEnabled) && !link.mobileOnly
        "
        variant="link"
        :to="{ name: link.name }"
        class="hover:text-white transition-colors duration-300 ease-in-out"
        :class="{
          'text-white': link.isHighlighted,
        }"
      >
        <base-icon :name="link.icon" size="w-6 h-6" />
        <span class="relative text-sm pl-3">
          <template v-if="link.name === 'home'">
            <beta-badge />
          </template>
          {{ link.label }}
        </span>
      </base-button>
    </template>
  </nav>
</template>
