import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useDialog } from '@/shared/model';
import { useConfirmationDialog } from '@/shared/ui/confirmation';
import type { PortalRule, PortalView } from 'dfx/edge/edge.did';
import { PORTAL_CONTENT } from '@/common';
import { trackEvent } from '@/utils';
import CreateEditRuleFields from '../../components/CreateEditRuleFields.vue';
import KickBanMemberFields from '../../components/KickBanMemberFields.vue';
import VerifyPhoneDialogContent from '../../components/VerifyPhoneDialogContent.vue';
import { TitleHeader } from '@/shared/ui/base-dialog';
import CreatePortalForm from '@/components/forms/CreatePortalForm.vue';

export function usePortalDialog() {
  const { t } = useI18n({ useScope: 'global' });
  const router = useRouter();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { openConfirmationDialog } = useConfirmationDialog();

  const openCreateEditRuleDialog = (
    submit: (rule: PortalRule) => void,
    editingRule?: PortalRule,
  ) => {
    openConfiguredDialog({
      content: {
        component: CreateEditRuleFields,
        props: {
          editingRule,
        },
        emits: {
          close: closeDialog,
          submit,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: editingRule ? t('editRule') : t('addRule'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openKickBanDialog = (
    submit: (reason: string) => void,
    ban: boolean,
  ) => {
    openConfiguredDialog({
      content: {
        component: KickBanMemberFields,
        props: {
          ban,
        },
        emits: {
          close: closeDialog,
          submit,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: ban ? t('banMember') : t('kickMember'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const navigateToPortal = (portal: PortalView) => {
    router.push({
      name: PORTAL_CONTENT,
      params: { slug: portal.slug },
    });
  };

  const openCreatePortalDialog = () => {
    openConfiguredDialog({
      content: {
        component: CreatePortalForm,
        emits: {
          createPortal: (portal: PortalView) => {
            closeDialog();
            navigateToPortal(portal);
          },
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: t('createPortal'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[438px]`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
      emit: () => {
        trackEvent('create_portal_action', 'open_modal');
      },
    });
  };

  const openVerifyPhoneDialog = () => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: VerifyPhoneDialogContent,
          emits: {
            submit: () => {
              closeDialog();
              resolve(true);
            },
          },
        },
        header: {
          component: TitleHeader,
          props: {
            title: t('verifyPhone'),
          },
          emits: {
            close: () => {
              closeDialog();
              resolve(false);
            },
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
      });
    });
  };

  const openLeaveDialog = async () => {
    const confirmed = await openConfirmationDialog({
      headerTitle: t('leavePortal'),
      contentSlots: {
        default: { template: t('leavePortalLegend') },
        submitLabel: { template: t('leave') },
      },
    });

    closeDialog();
    return confirmed;
  };

  return {
    openCreateEditRuleDialog,
    openKickBanDialog,
    openCreatePortalDialog,
    openVerifyPhoneDialog,
    openLeaveDialog,
    closeDialog,
  };
}
