import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFeedStore = defineStore('feed', () => {
  const savedScrollPosition = ref(new Map<string, number>());

  return {
    savedScrollPosition,
  };
});
