<script setup lang="ts">
  const props = defineProps<{
    isDisabled: boolean;
    shareUrl: string;
  }>();

  const buttonClasses =
    'w-full whitespace-nowrap min-h-8 px-3 py-2 flex gap-x-2 items-center !rounded-full font-medium text-gray-300 hover:bg-gray-820 hover:text-white ease-transition-all';

  const openItemDetails = () => {
    window.open(props.shareUrl, '_blank');
  };
</script>

<template>
  <base-button
    :custom-classes="buttonClasses"
    variant="custom"
    :disabled="isDisabled"
    @click="openItemDetails"
    ><base-icon name="tag-right" size="w-6 h-6" />Offer
  </base-button>
</template>

<style scoped lang="scss"></style>
