<script setup lang="ts">
  import { computed, ref, toRefs } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { Drawer } from '@/shared/ui/drawer';
  import type { FrameActionPayload } from '@/features/frame';
  import { useBreakpoint, useClipboardToast } from '@/shared/model';
  import { FRAMES_MAX_RESPONSE_TIME_MS } from '@/common';
  import StackTraceItemProp from './StackTraceItemProp.vue';
  import { useFrameAction } from '../composables/use-frame-action';

  const props = defineProps<{
    action: FrameActionPayload;
  }>();

  const showMaxResponseTimeLegend = ref(false);
  const { action } = toRefs(props);

  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanLg } = useBreakpoint();
  const { copyToClipboard } = useClipboardToast();
  const { durationSeconds, requestJson } = useFrameAction(action);

  const stackTraceItemProps = computed(() => {
    let props: { name: string; value: string | number | bigint | undefined }[] =
      [];
    if (action.value.type !== 'intro') {
      props = props.concat([
        {
          name: t('frame.buttonIndex'),
          value: action.value?.request?.body.untrustedData.buttonIndex,
        },
        {
          name: t('frame.inputText'),
          value: action.value?.request?.body.untrustedData.inputText,
        },
        {
          name: t('frame.address'),
          value: action.value?.request?.body.untrustedData.address,
        },
        {
          name: t('frame.transactionId'),
          value: action.value?.request?.body.untrustedData.transactionId,
        },
        {
          name: t('frame.state'),
          value: action.value?.request?.body.untrustedData.state,
        },
      ]);
    }
    props = props.concat([
      {
        name: t('frame.postId'),
        value: action.value?.request?.body.untrustedData.contentId,
      },
      {
        name: t('frame.timestamp'),
        value: action.value?.request?.body.untrustedData.timestamp,
      },
      {
        name: t('frame.trustedMessage'),
        value: action.value?.request?.body.trustedData.messageBytes,
      },
    ]);

    return props;
  });
</script>

<template>
  <div class="flex flex-col gap-4 lg:gap-2 text-sm lg:text-base">
    <div v-if="action.error" class="text-red-500">
      {{ action.error.message }}
    </div>
    <h4 class="flex gap-4 items-center mb-4">
      <span class="font-bold text-lg">{{ $t('frame.request') }}</span>

      <base-button
        v-if="requestJson"
        variant="secondary"
        size="x-small"
        @click="copyToClipboard(requestJson!, $t('copied'))"
      >
        <base-icon name="copy-documents" size="size-4" />
        <span>JSON</span>
      </base-button>
    </h4>

    <stack-trace-item-prop :name="$t('duration')">
      <div class="flex gap-2 items-center">
        <span>{{ durationSeconds }} secs</span>
        <template v-if="durationSeconds > FRAMES_MAX_RESPONSE_TIME_MS / 1000">
          <base-tooltip
            v-if="!isSmallerThanLg"
            append-to-body
            :content="$t('frame.maxResponseTimeLegend')"
            :max-width="300"
          >
            <base-icon
              name="filled-exclamation-triangle"
              size="size-5"
              class="text-orange-300"
            />
          </base-tooltip>
          <template v-else>
            <base-button
              variant="tertiary"
              @click="showMaxResponseTimeLegend = true"
            >
              <base-icon
                name="filled-exclamation-triangle"
                size="size-4"
                class="text-orange-300"
              />
            </base-button>
            <drawer
              direction="bottom"
              custom-classes="w-full max-h-full mt-2 overflow-y-auto thin-scrollbar shadow-lg bg-gray-950 rounded-t-md focus:outline-none"
              v-model:opened="showMaxResponseTimeLegend"
            >
              <div class="flex flex-col items-center justify-center px-6 pb-10">
                {{ $t('frame.maxResponseTimeLegend') }}
              </div>
            </drawer>
          </template>
        </template>
      </div>
    </stack-trace-item-prop>

    <stack-trace-item-prop
      v-for="item in stackTraceItemProps"
      :key="item.name"
      v-bind="item"
    />

    <template v-if="action.customHeaders">
      <stack-trace-item-prop
        v-for="[key, value] of Object.entries(action.customHeaders)"
        :key="key"
        :name="key"
        :value="value"
      />
    </template>
  </div>
</template>
