<script setup lang="ts">
  import { useSlots } from 'vue';
  import type { IconName } from '@/shared/ui/base-icon';

  defineProps<{
    icon?: IconName;
  }>();

  const slots = useSlots();
</script>
<template>
  <base-icon v-if="icon" :name="icon" size="size-8" />
  <span class="text-2xl font-bold leading-7">
    <slot />
    <span
      v-if="slots.subtitle"
      class="text-base font-medium leading-5 text-gray-400"
    >
      <slot name="subtitle" />
    </span>
  </span>
</template>
