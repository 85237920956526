<template>
  <div
    ref="contact"
    class="bg"
    :style="{
      backgroundImage: `url(${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/background/circle-bg@3x.jpg`,
        { quality: 100 },
      )})`,
    }"
  >
    <div class="px-6 pt-24 pb-12 text-center">
      <div class="pb-2 font-semibold text-base text-[#818CF8] leading-5">
        Experience why we are the largest Web3 social media platform!
      </div>
      <h1 class="title-header">Join us</h1>
    </div>
    <div
      class="flex items-center justify-center h-56 join-content"
      :style="{
        backgroundImage: `url(${fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/background/background.jpg`,
          { quality: 100 },
        )})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div class="flex flex-col gap-4 sm:flex-row">
        <button
          class="w-32 h-12 text-white bg-indigo-600 rounded-md focus:outline-none hover:bg-indigo-500"
          @click="signup"
        >
          Get started
        </button>
        <button
          data-tf-slider="ibCBNICS"
          data-tf-opacity="100"
          data-tf-size="100"
          data-tf-iframe-props="title=Contact us"
          data-tf-transitive-search-params
          class="w-32 h-12 rounded-md grey-button focus:outline-none"
        >
          Contact us
        </button>
      </div>
    </div>
  </div>
  <div class="p-6 bg-black sm:p-14">
    <div
      class="flex flex-col justify-between gap-8 m-auto sm:flex-row footer-main"
    >
      <div class="flex flex-col gap-8 w-44">
        <base-icon name="dscvr-with-label" size="w-[137px] h-[40px]" />
        <div class="flex hidden gap-8 sm:flex w-36">
          <a href="https://x.com/DSCVR1" target="_blank">
            <base-icon name="twitter" size="size-5" />
          </a>
          <a href="https://www.linkedin.com/company/dscvrone/" target="_blank">
            <base-icon name="linkedin" size="size-5" />
          </a>
          <a href="https://www.instagram.com/dscvr_one/" target="_blank">
            <base-icon name="instagram" size="size-5" />
          </a>
        </div>
        <div class="hidden text-base sm:flex">&copy;2023 DSCVR</div>
      </div>
      <div class="flex gap-32">
        <div class="flex flex-col gap-4">
          <h5 class="text-base font-semibold">Company</h5>
          <a
            href="https://dscvr.notion.site/DSCVR-Job-Board-d69f0e591558472eb4a994ba1a076900"
            class="text-sm text-gray-400"
            target="__blank"
          >
            Jobs
          </a>
          <router-link
            :to="{ name: PORTAL_CONTENT, params: { slug: 'dscvr' } }"
            class="text-sm text-gray-400"
            target="__blank"
          >
            Community
          </router-link>
          <!-- <router-link
            to="/about"
            class="text-sm text-gray-400"
          >
            Press
          </router-link> -->
          <div
            data-tf-slider="ibCBNICS"
            data-tf-opacity="100"
            data-tf-size="100"
            data-tf-iframe-props="title=Contact us"
            data-tf-transitive-search-params
            class="text-sm text-gray-400 cursor-pointer"
          >
            Contact us
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h5 class="text-base font-semibold">Policies</h5>
          <router-link :to="{ name: TERMS }" class="text-sm text-gray-400">
            Terms
          </router-link>
          <router-link :to="{ name: TERMS }" class="text-sm text-gray-400">
            Privacy
          </router-link>
          <router-link :to="{ name: TERMS }" class="text-sm text-gray-400">
            Cookies
          </router-link>
          <router-link :to="{ name: TERMS }" class="text-sm text-gray-400">
            Content
          </router-link>
        </div>
      </div>
      <div class="flex justify-between sm:hidden">
        <div class="text-base">&copy;2023 DSCVR</div>
        <div class="flex gap-8">
          <a href="https://x.com/DSCVR1" target="_blank">
            <base-icon name="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/dscvrone/" target="__blank">
            <base-icon name="linkedin" />
          </a>
          <a href="https://www.instagram.com/dscvr_one/" target="__blank">
            <base-icon name="instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { fetchMedia } from '@/shared/lib';
  import {
    PORTAL_CONTENT,
    TERMS,
    ABOUT,
    DSCVR_STATIC_ASSETS_CDN_URL,
  } from '@/common';
  import { useAuth } from '@/entities/auth';

  export default {
    name: 'join-us',
    setup() {
      const { showLoginSignUpDialog } = useAuth();
      return {
        showLoginSignUpDialog,
        fetchMedia,
      };
    },
    data() {
      return {
        ABOUT,
        DSCVR_STATIC_ASSETS_CDN_URL,
        PORTAL_CONTENT,
        TERMS,
      };
    },
    methods: {
      scrollToContact() {
        this.$refs.contact.scrollIntoView({ behavior: 'smooth' });
      },
      async signup() {
        this.showLoginSignUpDialog('signup');
      },
    },
  };
</script>

<style scoped>
  .bg {
    background: #000000;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .title-header {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
  }
  .grey-button {
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(16px);
  }
  .footer-main {
    max-width: 982px;
  }
</style>
