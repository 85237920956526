export const reactions = [
  'like',
  'haha',
  'clap',
  'fire',
  'care',
  'wow',
  'sad',
  'angry',
  'dislike',
] as const;
