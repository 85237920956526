<script setup lang="ts">
  import { useBreakpoint } from '@/shared/model';
  import { useInvite } from '@/features/invite';
  import type { UserView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    user: UserView;
  }>();

  const { openInviteUserDialog } = useInvite();
  const { isSmallerThanMd } = useBreakpoint();
</script>

<template>
  <base-button
    size="small"
    :variant="isSmallerThanMd ? 'glass' : 'custom'"
    :custom-classes="
      isSmallerThanMd
        ? undefined
        : 'gap-x-2 flex items-center min-h-8 rounded-2xl hover:bg-gray-820 ease-transition-all px-4 py-2 justify-center'
    "
    @click="openInviteUserDialog(user)"
  >
    <base-icon name="outlined-share" size="w-5 h-5" />
    {{ $t('share') }}
  </base-button>
</template>
