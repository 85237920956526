<script lang="ts" setup>
  import { ref, computed, onMounted, onBeforeUnmount, toRef, watch } from 'vue';
  import { ActionTypes } from '@/store/action-types';
  import DropDownButton from '@/components/buttons/DropDownButton.vue';
  import { reduce } from 'lodash-es';
  import {
    PermissionFlags,
    hasPermissionGroup,
    PermissionGroups,
  } from '@/shared/lib';
  import {
    HOME,
    PORTAL_CONTENT,
    PORTAL_SETTINGS_PAGE_BANS,
    PORTAL_SETTINGS_PAGE_GATES,
    PORTAL_SETTINGS_PAGE_MEMBERS,
    PORTAL_SETTINGS_PAGE_OVERVIEW,
    PORTAL_SETTINGS_PAGE_ROLES,
    PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
  } from '@/common';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { useGetPortalBySlugQuery } from '@/entities/portal';

  type TabItem = {
    label: string;
    routeName: string;
    childRouteNames: string[];
    permission: number;
    icon: string;
    activeIcon: string;
  };

  const props = defineProps<{
    slug: string;
  }>();

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const { data: portalView } = useGetPortalBySlugQuery(
    toRef(() => props.slug),
    true,
  );

  const settings = ref<TabItem[]>([
    {
      label: 'Settings',
      routeName: PORTAL_SETTINGS_PAGE_OVERVIEW,
      childRouteNames: [],
      permission: PermissionFlags.MODERATE_CONTENT,
      icon: 'settings',
      activeIcon: 'cog',
    },
    {
      label: 'Roles',
      routeName: PORTAL_SETTINGS_PAGE_ROLES,
      childRouteNames: [PORTAL_SETTINGS_PAGE_ROLES_DETAILS],
      permission: PermissionFlags.MANAGE_ROLES,
      icon: 'outline-crown',
      activeIcon: 'crown',
    },
    {
      label: 'Members',
      routeName: PORTAL_SETTINGS_PAGE_MEMBERS,
      childRouteNames: [],
      permission: PermissionFlags.MANAGE_MEMBERS,
      icon: 'outline-follows',
      activeIcon: 'follows',
    },
    {
      label: 'Bans',
      routeName: PORTAL_SETTINGS_PAGE_BANS,
      childRouteNames: [],
      permission: PermissionFlags.MANAGE_MEMBERS | PermissionFlags.BAN_MEMBER,
      icon: 'outline-block',
      activeIcon: 'block',
    },
    {
      label: 'Gates',
      routeName: PORTAL_SETTINGS_PAGE_GATES,
      childRouteNames: [],
      permission: PermissionFlags.MANAGE_MEMBERS,
      icon: 'outline-lock',
      activeIcon: 'lock',
    },
  ]);
  const currentTab = ref(PORTAL_SETTINGS_PAGE_OVERVIEW);

  const currentRouteName = computed(() => route.name as string);

  const filteredSettings = computed(() => {
    return settings.value.filter(
      (setting) => (setting.permission & Number(portalView.value?.perm)) > 0,
    );
  });

  const mobileDropdownSettingsMenu = computed(() => {
    return reduce(
      filteredSettings.value,
      (result, value) => ({
        ...result,
        [value.label.toLowerCase()]: {
          short: value.label,
          icon: value.icon,
          name: value.routeName,
        },
      }),
      {},
    );
  });

  const setTab = (tab: string) => {
    currentTab.value = tab;
    router.push({ name: tab });
  };

  const showHidePostsNavbar = (enabled: { enabled: boolean }) => {
    store.dispatch(`posts/${ActionTypes.SHOW_HIDE_POSTS_NAVBAR}`, enabled);
  };

  onMounted(() => {
    showHidePostsNavbar({ enabled: false });
  });

  watch(
    portalView,
    (portalView) => {
      if (portalView) {
        if (portalView === null) {
          router.replace({ name: HOME });
        } else {
          if (
            !hasPermissionGroup(
              portalView.perm,
              PermissionGroups.PORTAL_MANAGER,
            )
          ) {
            router.replace({
              name: PORTAL_CONTENT,
              params: { slug: portalView.slug },
            });
          }
        }
      }
    },
    { immediate: true },
  );
  onBeforeUnmount(() => {
    showHidePostsNavbar({ enabled: true });
  });
</script>

<template>
  <div
    v-if="portalView"
    class="flex flex-col justify-center w-full gap-6 md:flex-row"
  >
    <div class="flex flex-col px-2 pt-4 md:hidden">
      <base-button
        variant="link"
        custom-classes=" flex gap-2 w-full pb-4 transition-all duration-300 cursor-pointer hover:text-white"
        :to="{
          name: PORTAL_CONTENT,
          params: { slug: portalView.slug },
        }"
      >
        <base-icon name="arrow-left" />
        Back to {{ portalView.slug }}
      </base-button>
      <drop-down-button
        class="mt-2 mb-6"
        :items="mobileDropdownSettingsMenu"
        @set-tab="(tab: string) => setTab(tab)"
      />
    </div>
    <aside
      class="flex-col justify-between flex-grow-0 flex-shrink-0 order-1 hidden w-68 transform md:flex"
    >
      <div class="text-sm bg-gray-950 shadow-lg md:rounded-lg">
        <router-link
          :to="{ name: PORTAL_CONTENT, params: { slug: portalView.slug } }"
          class="inline-flex items-center px-6 pt-4 mb-5 mr-2 text-lg font-bold text-white cursor-pointer portalSettingsHeading"
        >
          <base-icon name="back" size="w-5 h-5" />
          <span class="ml-2 capitalize">{{ portalView?.slug }} </span>
        </router-link>
        <router-link
          v-for="(setting, index) in filteredSettings"
          :key="setting.routeName"
          :to="{ name: setting.routeName, query: $route.query }"
          class="block py-3 cursor-pointer hover:bg-gray-700"
          :class="{
            'is-selected pl-1 text-white':
              setting.routeName === currentRouteName ||
              setting.childRouteNames.includes(currentRouteName),
            'pl-2 text-gray-300':
              setting.routeName !== currentRouteName &&
              !setting.childRouteNames.includes(currentRouteName),
            'rounded-b-xl': index === settings.length - 1,
          }"
        >
          <div class="flex items-center">
            <div class="inline-flex ml-4 leading-5">
              <base-icon
                v-if="
                  setting.routeName === currentRouteName ||
                  setting.childRouteNames.includes(currentRouteName)
                "
                :name="setting.activeIcon"
                size="w-5 h-6"
                class="mr-2"
              />
              <base-icon
                v-else
                :name="setting.icon"
                size="w-5 h-6"
                class="mr-2"
              />

              <span class="flex items-center font-medium">{{
                setting.label
              }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </aside>

    <section class="order-2 grow max-w-[932px]">
      <div class="w-full text-md">
        <div class="w-full">
          <div class="w-full p-4 mb-8 bg-gray-950 shadow-md md:rounded-lg">
            <router-view :slug="slug" :portal-view="portalView" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
