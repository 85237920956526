<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useNotificationStore } from '../../model/store/store';

  const notificationStore = useNotificationStore();
  const { unreadNotificationCount } = storeToRefs(notificationStore);
</script>

<template>
  <base-button
    variant="link"
    class="relative text-gray-400 hover:text-white"
    @click.stop="notificationStore.openDrawer"
  >
    <base-icon
      name="bell"
      size="size-5"
      class="hover:text-white transition-colors duration-300 ease-in-out"
    />
    <div
      v-if="unreadNotificationCount > BigInt(0)"
      class="flex items-center justify-center min-w-3 min-h-3 rounded-full border border-pink-500 font-semibold bg-pink-500 text-xxs text-white -top-1 -right-1 absolute"
    >
      {{ unreadNotificationCount }}
    </div>
  </base-button>
</template>
