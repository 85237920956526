<script setup lang="ts" generic="T extends CollectionItem">
  import { ref } from 'vue';
  import { useSticky } from '@/composables';
  import { type CollectionItem, getCollectionItemKey } from '@/shared/lib';

  withDefaults(
    defineProps<{
      items: T[];
      topClass?: string;
      backgroundClass?: string;
    }>(),
    {
      topClass: 'top-44',
      backgroundClass: 'bg-gray-975',
    },
  );

  const stickyRef = ref<HTMLElement | null>(null);
  const contentRef = ref<HTMLElement | null>(null);
  const { isStickyActive } = useSticky(stickyRef, contentRef);
</script>

<template>
  <div class="sticky z-40 h-0" :class="topClass">
    <div
      v-if="isStickyActive"
      class="w-[calc(100%+2rem)] -mx-4 h-12 drop-shadow-lg"
      :class="backgroundClass"
    ></div>
  </div>
  <table
    class="table-fixed w-[calc(100%+5.5rem)] border-separate -mx-11 -mt-11"
    :class="backgroundClass"
    :style="{ borderSpacing: '2.75rem 2.75rem' }"
  >
    <thead>
      <tr
        ref="stickyRef"
        class="sticky z-40"
        :class="`${topClass} ${backgroundClass}`"
      >
        <slot name="header" />
      </tr>
    </thead>
    <tbody ref="contentRef">
      <tr v-for="(item, index) in items" :key="getCollectionItemKey(item)">
        <slot name="item" :item="item" :index="index" />
      </tr>
    </tbody>
  </table>
</template>
