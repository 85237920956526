<script lang="ts" setup>
  import { useStreak } from '../../model/composables/use-streak';
  import HeaderDialog from '../../ui/HeaderDialog.vue';
  import { useDialog } from '@/shared/model';

  const { closeDialog } = useDialog();
  const { showDailyStreakDialog } = useStreak();
</script>

<template>
  <header-dialog>
    <template #title>
      <base-button
        variant="custom"
        custom-classes="flex gap-4 items-center"
        @click="showDailyStreakDialog"
      >
        <base-icon
          name="outlined-chevron-left"
          size="w-5 h-5"
          class="text-gray-300"
        />
        <span class="text-2xl font-bold tracking-[-0.12px] text-gray-300">
          {{ $t('back') }}
        </span>
      </base-button>
    </template>
    <template #close>
      <base-button variant="link" @click.stop="closeDialog">
        <base-icon name="x" size="w-5 h-5" />
      </base-button>
    </template>
  </header-dialog>
</template>
