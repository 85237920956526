import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
// initial state
const state = () => ({
  isLoading: false,
  revealedNSFWContent: [],
});

// getters
const getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
  revealedNSFWContent: (state) => {
    return state.revealedNSFWContent;
  },
};

// actions
const actions = {
  [ActionTypes.SHOW_HIDE_POSTS_NAVBAR]({ commit }, data) {
    commit(MutationTypes.SET_IS_NAVBAR_VISIBLE, data.enabled);
  },
  [ActionTypes.SET_IS_LOADING]({ commit }, show) {
    commit(MutationTypes.SET_IS_LOADING, show);
  },
  [ActionTypes.REVEAL_NSFW_POST]({ commit }, postId) {
    commit(MutationTypes.REVEAL_NSFW_POST, postId);
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_IS_NAVBAR_VISIBLE](state, val) {
    state.isNavbarVisible = val;
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
  [MutationTypes.REVEAL_NSFW_POST](state, postId) {
    state.revealedNSFWContent.push(postId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
