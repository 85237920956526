<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import type { PortalRule } from 'dfx/edge/edge.did';

  const props = defineProps<{
    editingRule?: PortalRule;
  }>();

  defineEmits<{
    (e: 'close'): void;
    (e: 'submit', value: PortalRule): void;
  }>();

  const rule = ref<PortalRule>({
    name: '',
    desc: '',
  });

  onMounted(() => {
    if (props.editingRule) {
      rule.value = { ...props.editingRule };
    }
  });
</script>
<template>
  <div class="flex flex-col gap-4 p-6">
    <div>
      <div class="text-xs mt-4 mb-2 ml-1">{{ $t('ruleTitle') }}</div>
      <input
        v-model="rule.name"
        type="text"
        class="block w-full text-white bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
        required
        :placeholder="`${$t('ruleTitle')}...`"
        @keyup.enter="$emit('submit', rule)"
      />
      <div class="text-xs mt-4 mb-2 ml-1">{{ $t('ruleDescription') }}</div>
      <textarea
        v-model="rule.desc"
        :placeholder="`${$t('ruleDescription')}...`"
        class="block w-full bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
        rows="3"
        required
      />
    </div>

    <div class="flex justify-end gap-2 pt-8 w-full">
      <base-button
        variant="tertiary"
        size="medium"
        @click.stop="$emit('close')"
      >
        {{ $t('cancel') }}
      </base-button>
      <base-button
        variant="primary"
        size="medium"
        @click.stop="$emit('submit', rule)"
      >
        {{ editingRule ? $t('edit') : $t('add') }}
      </base-button>
    </div>
  </div>
</template>
