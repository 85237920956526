import { computed, type Ref } from 'vue';
import { useHead, useSeoMeta } from '@unhead/vue';
import type { ContentView } from 'dfx/edge/edge.did';
import { fetchMedia, stripHTMLPrefix } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import { isImageUrl, removeMarkdown, getUrls } from '@/utils';
import { generatePostTitle } from '../../lib/generate-post-title';
import { isSelfPost } from '../../lib/is-self-post';

export const useMetaPost = (post: Ref<ContentView | undefined>) => {
  const postTitle = computed(() => {
    if (!post.value) return undefined;

    return generatePostTitle(post.value);
  });

  const ogTitle = computed(() => {
    if (!post.value) return '';

    if (!isSelfPost(post.value)) {
      return postTitle.value
        ? `${postTitle.value} in ${post.value.portal.name}`
        : `Post by ${post.value.owner.username} in ${post.value.portal.name}`;
    }

    return postTitle.value
      ? `${postTitle.value} by ${post.value.owner.username}`
      : `Post by ${post.value.owner.username}`;
  });

  const title = computed(() =>
    ogTitle.value ? `${ogTitle.value} - DSCVR` : '',
  );

  const description = computed(() =>
    post.value ? removeMarkdown(stripHTMLPrefix(post.value.body)) : '',
  );
  const truncatedDescription = computed(() =>
    description.value.length > 150
      ? description.value.slice(0, 150) + ' ... '
      : description.value,
  );

  const ogDescription = computed(() => {
    if (!post.value) {
      return '';
    }
    return `${truncatedDescription.value} posted by ${post.value.owner.username}`;
  });

  const imageUrl = computed(() => {
    if (post.value) {
      if (isImageUrl(post.value.url)) {
        return post.value.url;
      }
      const results = getUrls(post.value.body).filter((x) => isImageUrl(x));
      if (results.length > 0) {
        return results[0];
      }
    }
    return null;
  });

  const twitterCard = computed(() =>
    imageUrl.value ? 'summary_large_image' : 'summary',
  );

  const ogImage = computed(
    () =>
      imageUrl.value ??
      fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/common/apple-touch-icon-120x120.png`,
      ),
  );

  useHead({
    title: () => title.value,
    meta: [
      { name: 'title', content: title },
      { name: 'description', content: truncatedDescription },
    ],
  });

  useSeoMeta({
    description,
    ogDescription,
    ogType: 'website',
    ogTitle,
    ogImage,
    twitterCard,
    twitterImage: ogImage,
    twitterSite: '@DSCVR1',
  });

  return {
    postTitle,
    ogTitle,
  };
};
