import party from 'party-js';

/**
 *
 * @param sparkleEmitter
 * @param maxCount
 */
export function playSparkles(sparkleEmitter, maxCount = 80) {
  party.confetti(sparkleEmitter, {
    count: party.variation.range(40, maxCount),
    size: party.variation.range(0.25, 1.5),
    spread: 50,
    color: party.variation.gradientSample(
      party.Gradient.simple(
        party.Color.fromHex('#8800ff'),
        party.Color.fromHex('#ff8800'),
      ),
    ),
    speed: 400,
    lifetime: party.variation.range(0.1, 0.5),
  });
}
