<template>
  <button
    class="inline-block w-full px-5 h-full text-sm font-medium leading-normal text-center text-white transition duration-500 transform rounded-md shadow-lg md:rounded-lg focus:outline-none disabled:opacity-50 md:w-auto"
    type="submit"
    :disabled="isDisabled || !formReady"
    :class="{
      'cursor-default': isDisabled,
      'hover:bg-indigo-600': !isDisabled,
      'bg-gray-700': !formReady,
      'bg-indigo-600': formReady,
    }"
  >
    {{ text }}
  </button>
</template>

<script>
  export default {
    name: 'form-button',
    props: {
      text: {
        type: String,
        default: '',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      formReady: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
