import { computed, type Ref } from 'vue';
import type { MemberKind, PortalView } from 'dfx/edge/edge.did';
import { getPortalBySlugKey, portalMembersByStatusKey } from './keys';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useGetPortalMembersByStatusQuery = (
  portalView: Ref<PortalView>,
  memberKind: Ref<MemberKind>,
) => {
  const portalSlug = computed(() => portalView.value.slug);

  return useQuery({
    queryKey: [
      ...getPortalBySlugKey(portalSlug),
      portalMembersByStatusKey,
      memberKind,
    ],
    queryFn: () =>
      dscvrIcApi.portal.getPortalMembersByStatus(
        portalView.value.id,
        memberKind.value,
      ),
  });
};
