import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useSetContentRankMutation = () => {
  return useMutation({
    mutationKey: ['set-content-rank-mutation'],
    mutationFn: (params: dscvrIcApi.post.SetContentRankParams) =>
      dscvrIcApi.post.setContentRank(params),
  });
};
