<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useMatrica } from '@/entities/wallets';
  import { USER_SETTINGS_INTEGRATIONS } from '@/common';
  import { useUser } from '@/entities/user';
  import {
    useOauth2QueryParams,
    useOauth2State,
    useToast,
  } from '@/shared/model';

  const { linkMatricaWallet, refreshMatricaWallets } = useMatrica();
  const { getLocalOauth2State } = useOauth2State();
  const { t } = useI18n();
  const router = useRouter();
  const { currentUser } = useUser();
  const { showToast } = useToast();
  const MATRICA_ERROR_MESSAGE = t('wallets.matrica.redirectErrorMessage');

  onMounted(async () => {
    const { code, state, error } = useOauth2QueryParams();
    if (error.value) {
      showToast({
        title: MATRICA_ERROR_MESSAGE,
        type: 'error',
      });
      return;
    }
    if (code.value && state.value) {
      if (state.value !== getLocalOauth2State()) {
        showToast({
          title: MATRICA_ERROR_MESSAGE,
          type: 'error',
        });
        return;
      }
      // Link wallet
      try {
        await linkMatricaWallet(code.value as string);
        // sync wallet
        await refreshMatricaWallets();
        // redirect back to settings page
        router.push({
          name: USER_SETTINGS_INTEGRATIONS,
          params: { username: currentUser.value.username },
        });
      } catch (error) {
        showToast({
          title: MATRICA_ERROR_MESSAGE,
          type: 'error',
        });
      }
    }
  });
</script>

<template>
  <div class="flex items-center justify-center h-screen p-4 text-2xl font-bold">
    <div>{{ $t('redirecting') }}</div>
  </div>
</template>
