<script lang="ts" setup>
  import { useStreak } from '../../model/composables/use-streak';
  import { fetchMedia, formatZeroPadding } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useBreakpoint } from '@/shared/model';

  const { isSmallerThanMd } = useBreakpoint();
  const {
    hasActiveStreak,
    isPoweredUp,
    totalStreakCount,
    highestStreakCount,
    lifetimeAirdropMultiplier,
  } = useStreak();
</script>

<template>
  <div class="flex w-full">
    <div
      class="w-1/4 md:w-1/3 flex flex-col justify-center items-center text-sm md:text-base md:gap-2 font-medium text-gray-300"
    >
      <template v-if="isSmallerThanMd">
        <base-icon name="chart-up" size="size-5" class="mb-1.5" />
        <span>{{ highestStreakCount ?? '--' }}</span>
        <span>{{ $t('streak.highest') }}</span>
      </template>
      <template v-else>
        <p
          class="font-bold text-xxxl bg-clip-text text-transparent bg-gradient-to-b from-gray-400 to-gray-300"
        >
          {{ highestStreakCount ?? '--' }}
        </p>
        <div class="flex items-center gap-2">
          <base-icon name="chart-up" size="size-5" />
          <span>{{ $t('streak.highestStreak') }}</span>
        </div>
      </template>
    </div>

    <div class="w-1/2 md:w-1/3 flex justify-center items-center relative">
      <div
        v-if="isPoweredUp"
        class="absolute top-0 left-1/2 transform -translate-x-1/2 size-full max-w-[512px] bg-center bg-cover"
        :style="{
          backgroundImage: `url('${fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/sparkle.gif`,
          )}')`,
        }"
      />
      <div
        class="flex justify-center items-center my-10 w-2/3 max-w-50 md:w-50 p-1 squared rounded-2xl rotate-45 bg-gray-975 overflow-hidden"
      >
        <div
          class="absolute w-[150%] squared -z-1 animate-spin-slow bg-gradient-to-t to-[#00ECBC] from-[#007ADF]"
        />
        <div
          class="flex flex-col justify-center items-center rounded-xl w-full squared bg-gray-975"
        >
          <div class="p-3 -rotate-45">
            <div
              v-if="hasActiveStreak"
              class="flex flex-col justify-center items-center gap-2 md:gap-4"
              :class="{
                'animate-fade-down animate-once': isPoweredUp,
              }"
            >
              <span
                class="text-xxxl md:text-[42px] leading-8 md:leading-10 md:font-bold bg-clip-text text-transparent bg-gradient-to-b from-gray-400 to-gray-300"
              >
                {{ formatZeroPadding(totalStreakCount) }}
              </span>
              <div class="flex gap-1 items-center">
                <base-icon
                  name="vuesax-bold-flash"
                  size="size-5 md:size-6"
                  class="flex-none"
                />
                <span class="text-sm md:text-base">
                  {{ `${$t('streak.streak')}!` }}
                </span>
              </div>
            </div>
            <div
              v-else
              class="flex flex-col md:flex-row justify-center items-center gap-1"
            >
              <base-icon
                name="vuesax-bold-flash"
                size="size-6"
                class="flex-none"
              />
              <span
                class="text-gray-30 leading-5 text-sm text-center md:text-base md:text-left"
              >
                {{
                  isSmallerThanMd
                    ? $t('streak.activeDialog.longStreaksEarnMore')
                    : $t('streak.activeDialog.unbrokenStreak')
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-1/4 md:w-1/3 flex flex-col justify-center items-center text-sm md:text-base md:gap-2 font-medium text-gray-300"
    >
      <template v-if="isSmallerThanMd">
        <base-icon name="filled-airdrop3" size="size-4" class="mb-1.5" />
        <span>{{ lifetimeAirdropMultiplier ?? '--' }}</span>
        <span>{{ $t('streak.earned') }}</span>
      </template>
      <template v-else>
        <p
          class="font-bold text-xxxl bg-clip-text text-transparent bg-gradient-to-b from-gray-400 to-gray-300"
        >
          {{ lifetimeAirdropMultiplier ?? '--' }}
        </p>
        <div class="flex items-center gap-2">
          <base-icon name="filled-airdrop3" size="size-5" />
          <span>{{ $t('streak.totalEarned') }}</span>
        </div>
      </template>
    </div>
  </div>
</template>
