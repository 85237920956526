export const lazyLoading = {
  name: 'image',
  level: 'inline',
  renderer(token) {
    const html = this.parser.renderer.image(
      token.href,
      token.title,
      token.text,
    );

    return html.replace(
      /^<img /,
      `<img v-lazy="{ src: '${token.href}', loading: '', error: '', delay: 333 }" `,
    );
  },
};
