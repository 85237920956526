<script setup lang="ts">
  import { onMounted, ref, provide, type Ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { config as appConfig } from '@/shared/lib';
  import { useUser } from '@/entities/user';
  import {
    EmbeddedFrame,
    useCreateFrameMutation,
    type FrameActionPayload,
  } from '@/features/frame';
  import { Loader } from '@/shared/ui/loader';
  import { FRAMES_SPEC_URL, FRAME_EXAMPLES, HOME } from '@/common';
  import FrameForm from './components/FrameForm.vue';
  import StackTrace from './components/StackTrace.vue';
  import type { FrameFormPayload } from './types';

  const mockContent = ref<Pick<ContentView, 'id'>>();
  const framePayload: Ref<FrameFormPayload> = ref({ url: '' });
  const actionLog: Ref<FrameActionPayload[]> = ref([]);

  const route = useRoute();
  const router = useRouter();
  const { currentUserPrincipal } = useUser();
  const {
    data: frameAppInfo,
    isPending: isCreatingFrame,
    mutate: createFrameMutation,
  } = useCreateFrameMutation();

  const loadPayload = async (payload: FrameFormPayload) => {
    framePayload.value = payload;
    mockContent.value = framePayload.value.postId
      ? { id: framePayload.value.postId }
      : undefined;
    actionLog.value = [];
    router.replace({
      query: {
        url: framePayload.value.url || undefined,
        postId: framePayload.value.postId?.toString(),
      },
    });
    provide('content', mockContent);
    createFrameMutation({
      url: framePayload.value.url,
      userPrincipal: currentUserPrincipal.value?.toText(),
    });
  };

  onMounted(async () => {
    if (!appConfig.ENABLE_FRAMES) {
      router.push({ name: HOME });
      return;
    }
    const url = (route.query.url as string) || '';
    const postIdParam = (route.query.postId as string) || '';
    let postId: bigint | undefined;
    try {
      postId = postIdParam ? BigInt(postIdParam) : undefined;
    } catch {}
    loadPayload({ url, postId });
  });
</script>

<template>
  <div class="flex flex-col lg:grid lg:grid-cols-2 gap-6 p-4 w-full min-h-160">
    <div class="flex flex-col gap-10">
      <div class="flex justify-between">
        <h1 class="text-xl sm:text-xxxl font-bold text-white text-center">
          {{ $t('frame.framesValidator') }}
        </h1>
      </div>

      <frame-form :form-values="framePayload" @submit="loadPayload" />

      <embedded-frame
        v-if="frameAppInfo"
        is-debug
        :url="frameAppInfo.url"
        @action="($event) => actionLog.push($event)"
      />
      <div v-else-if="isCreatingFrame" class="relative w-full h-48">
        <loader variant="rainbow" border-width="border" size="size-10" />
      </div>

      <ul class="flex flex-col gap-2 min-w-0">
        <li v-for="example in FRAME_EXAMPLES" :key="example">
          <base-button
            variant="custom"
            custom-classes="text-left underline whitespace-nowrap truncate"
            @click="loadPayload({ url: example })"
          >
            {{ example }}
          </base-button>
        </li>
      </ul>
    </div>
    <div class="flex flex-col gap-6 bg-black bg-opacity-10 py-4">
      <div class="flex justify-end px-4">
        <base-button
          variant="link"
          additional-classes="underline text-indigo-500 gap-1"
          target="_blank"
          :to="FRAMES_SPEC_URL"
        >
          {{ $t('frame.specs') }}
          <base-icon name="vuesax-linear-export" size="size-4" />
        </base-button>
      </div>
      <stack-trace v-if="actionLog.length" :log="actionLog" />
    </div>
  </div>
</template>
