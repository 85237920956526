import { useCanvasStore } from '@/shared/model';
import { storeToRefs } from 'pinia';
import { useUser } from '@/entities/user';
import { useCreateCanvasMutation } from '../../api/use-create-canvas.mutation';

export const useValidateAndCreateCanvas = () => {
  const canvasStore = useCanvasStore();
  const { savedUrl } = storeToRefs(canvasStore);
  const { currentUserPrincipal } = useUser();
  const { mutateAsync: createCanvasAsyncMutation } = useCreateCanvasMutation();

  const validateAndCreateCanvas = async (url: string) => {
    const canvasInfo = await createCanvasAsyncMutation({
      url,
      userPrincipal: currentUserPrincipal.value?.toText(),
    });
    if (!canvasInfo) {
      return false;
    }

    savedUrl.value.push(url);
    return true;
  };

  return {
    validateAndCreateCanvas,
  };
};
