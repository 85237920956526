import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { usePersistentStore } from '@/store';
import type { AuthEntity, AccountType } from '../..';

export const useAuthStore = defineStore('auth', () => {
  const authEntity = ref<AuthEntity>({
    context: 'dialog',
    form: {
      name: 'login-signup',
      type: 'login',
    },
    visible: false,
  });

  const persistentStore = usePersistentStore();
  const { lastLoginOption } = storeToRefs(persistentStore);

  const getLastUsedLoginOption = (accountType: AccountType): boolean => {
    return lastLoginOption.value === accountType;
  };
  const setLastLoginOption = (accountType: AccountType): void => {
    lastLoginOption.value = accountType;
  };
  const clearLastLoginOption = (): void => {
    if (lastLoginOption.value !== null) {
      lastLoginOption.value = null;
    }
  };

  return {
    authEntity,
    getLastUsedLoginOption,
    setLastLoginOption,
    clearLastLoginOption,
  };
});
