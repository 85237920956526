<script lang="ts" setup>
  import { useForm, type InvalidSubmissionHandler } from 'vee-validate';
  import { computed, ref } from 'vue';
  import { TextFormField } from '@/shared/ui/form-fields';
  import * as yup from 'yup';
  import { commonInnerClasses } from '../../lib/commonInnerClasses';
  import { useBreakpoint, useToast, type AccountType } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import { useAuth } from '../../model/composables/use-auth';
  import type { CreateICPUser } from 'dfx/edge/edge.did';

  interface FormFields {
    email: string;
    account_type?: AccountType;
    username: string;
    referral_code?: string;
    portal_slug?: string;
    referring_username?: string;
  }

  const { authEntity, createUser, createReferredUser } = useAuth();
  const { t } = useI18n({ useScope: 'global' });

  const { showToast } = useToast();
  const { isSmallerThanMd } = useBreakpoint();

  const isSubmitDisabled = ref(false);
  const usernameValidRegExp = /^[A-Za-z0-9_-]+$/;
  const maxUsernameCharacters = 24;
  const minUsernameCharacters = 2;

  const schema = yup.object({
    account_type: yup.string().required(),
    email: yup.string().email('Please enter a valid email').required(),
    username: yup
      .string()
      .required()
      .min(minUsernameCharacters, 'min length is 2 ')
      .max(maxUsernameCharacters, 'max length is 24')
      .matches(
        usernameValidRegExp,
        'can be upper or lower case A-z, underscores "_" and dashes "-".',
      ),
    referral_code: yup.string(),
    portal_slug: yup.string(),
    referring_username: yup.string(),
  });

  const isContextPage = computed(() => authEntity.value.context === 'page');
  const isGoogleSignup = computed(
    () => authEntity.value.accountType === 'google',
  );
  const isCredentialSignup = computed(
    () => authEntity.value.accountType === 'credentials',
  );

  const initialValues = computed(() => {
    return {
      email: authEntity.value.email ?? '',
      account_type: authEntity.value.accountType,
      username: authEntity.value.username ?? '',
      referral_code: authEntity.value.referral_code ?? '',
      portal_slug: authEntity.value.portal_slug ?? '',
      referring_username: authEntity.value.referring_username ?? '',
    };
  });

  const { handleSubmit } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });
  const onInvalidSubmit: InvalidSubmissionHandler<FormFields> = ({
    values,
  }) => {
    if (!values.username) {
      showToast({
        type: 'error',
        title: 'Username is required',
        durationSeconds: 3,
      });
    } else if (!values.username.match(usernameValidRegExp)) {
      showToast({
        type: 'error',
        title:
          'Username must contain only letters, numbers, underscores, or dashes',
        durationSeconds: 3,
      });
    } else if (
      values.username.length < minUsernameCharacters ||
      values.username.length > maxUsernameCharacters
    ) {
      showToast({
        type: 'error',
        title: `Username must be greater than ${minUsernameCharacters} characters and less than ${maxUsernameCharacters} characters`,
        durationSeconds: 3,
      });
    }

    if (!values.email) {
      showToast({
        type: 'error',
        title: 'Email address is required',
        durationSeconds: 4,
      });
    }
  };

  const onSubmit = handleSubmit((values) => {
    const params: CreateICPUser = {
      auth_token: '',
      username: values.username,
      email: [values.email],
      referral_code: values.referral_code ? [values.referral_code] : [],
      portal_slug: values.portal_slug ? [values.portal_slug] : [],
      primary_chain: [{ Solana: null }],
      referring_username: values.referring_username
        ? [values.referring_username]
        : [],
      meta: [],
    };
    if (authEntity.value.context === 'page') {
      createReferredUser(params);
    } else {
      createUser(params);
    }
  }, onInvalidSubmit);
</script>

<template>
  <div
    class="flex flex-col items-center justify-start gap-8 thin-scrollbar scrollbar-transparent overflow-y-auto h-full"
    :class="[isContextPage ? 'p-0 md:p-8 ' : 'p-8']"
  >
    <div class="flex flex-col items-center justify-center gap-2">
      <div v-if="!isContextPage" class="flex gap-2 items-center">
        <base-icon name="dscvr-x-solana" size="w-[134px] h-7" />
      </div>
      <h2 class="text-2xl text-center font-bold">
        {{ t('auth.registrationForm.createAccount') }}
      </h2>
      <div
        v-if="!isContextPage || (isContextPage && !isSmallerThanMd)"
        class="text-gray-400 text-center pt-2"
      >
        {{ t('signUp.thrilledRegistration') }}
      </div>
    </div>
    <form class="flex flex-col gap-8 w-full" @submit="onSubmit">
      <template v-if="isCredentialSignup">
        <div
          class="flex gap-2 items-center bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl px-4 py-3 w-full [&_*]:cursor-default opacity-50"
        >
          <div
            class="flex gap-2 bg-transparent appearance-none border-0 grow p-0"
          >
            {{ authEntity.username }}
          </div>
        </div>
      </template>
      <text-form-field
        v-else
        type="text"
        name="username"
        id="username"
        label="Username"
        :inner-classes="commonInnerClasses"
      />
      <!-- allowing email temporary -->
      <template v-if="isGoogleSignup">
        <div
          class="flex gap-2 items-center bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl px-4 py-3 w-full [&_*]:cursor-default opacity-50"
        >
          <div
            class="flex gap-2 bg-transparent appearance-none border-0 grow p-0"
          >
            {{ authEntity.email }}
          </div>
          <base-icon name="colored-google" size="w-5 h-5" />
        </div>
      </template>
      <text-form-field
        v-else
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        label="Email"
        :inner-classes="commonInnerClasses"
      >
        <template #icon>
          <div
            class="flex items-center justify-center p-1 rounded-full bg-email"
          >
            <base-icon name="filled-mail" size="w-3 h-3" />
          </div>
        </template>
      </text-form-field>

      <base-button
        variant="primary"
        type="submit"
        size="full"
        class="w-[86.8%] mx-auto mt-4"
        :disabled="isSubmitDisabled"
      >
        {{ t('auth.registrationForm.createAccount') }}
      </base-button>
    </form>
  </div>
</template>

<style scoped>
  .bg-email {
    background: linear-gradient(315deg, #764ba2 0%, #667eea 100%);
  }
</style>
