import type { AccountType } from '@/shared/model';
export const AUTH_ENTITY_PARAM = 'auth_entity';
export const AUTH_RESPONSE_PARAM = 'auth_response';

export const NFID_AUTH_URL =
  'https://nfid.one/authenticate/?applicationName=dscvr.one&applicationLogo=https%3A%2F%2Ffe-assets.dscvr.one%2Fimages%2Fcommon%2Fdscvr-logo.png#authorize';

export enum InterruptPayloadKind {
  INTERRUPT_PASSWORD_RESET = 'interrupt-password-reset',
  INTERRUPT_IDENTITY_REDIRECT = 'interrupt-identity-redirect',
  INTERRUPT_WALLET_ALREADY_PAIRED = 'interrupt-wallet-already-paired',
}

export const IDENTITY_AUTH_ROUTES: AccountType[] = [
  'google',
  'solana-wallets',
  'worldcoin',
];
