// TODO: Move this store to pinia and cleanup the code (FSD)
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { uniqueId } from 'lodash-es';
import { NOTIFICATIONS_PAGE_SIZE } from '@/common';
import { useNotificationSettingStore } from '../../../user';
import { useReadNotificationMutation } from '../../api/use-read-notification.mutation';
import { useReadAllNotificationMutation } from '../../api/use-read-all-notification.mutation';
import { useNotificationQuery } from '../../api/use-notification.query';
import type {
  AppNotificationPayload,
  NotificationPageQuery,
  TabItem,
} from '../../types';
import { useGlobalStore } from '@/shared/model';
import { useGetGlobalLiveDataQuery } from '../../../auth';

export const useNotificationStore = defineStore('notification', () => {
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);
  const { refetch: refetchGlobalLiveDataQuery } = useGetGlobalLiveDataQuery(
    ref(false),
  );
  const isOpen = ref<boolean>(false);
  const isPortalAdmin = ref<boolean>(false);
  const notificationSettingStore = useNotificationSettingStore();

  const state = ref({
    notifications: [] as AppNotificationPayload[],
    num_pages: BigInt(1),
    page: BigInt(-1),
    active_tab: 'all',
  });

  const isEmpty = computed(() => state.value.notifications.length === 0);

  const unreadNotificationCount = computed(
    () => globalLiveData.value?.unread_notification_count ?? BigInt(0),
  );

  const tabItems = ref([
    {
      id: uniqueId(),
      title: 'All',
      disabled: false,
    },
    {
      id: uniqueId(),
      title: 'Mentions',
      disabled: false,
    },
  ]);
  const activeTabItem = ref(tabItems.value[0]);

  const openDrawer = async () => {
    if (!isOpen.value) {
      resetState();
      isOpen.value = true;
      await fetchNotifications();
      await markNotificationsAsRead();
    }
  };

  const closeDrawer = async () => {
    if (isOpen.value) {
      await markNotificationsAsRead();
      resetState();
      isOpen.value = false;
      setTab(tabItems.value[0], false);
    }
  };
  const resetState = () => {
    state.value.notifications = [];
    state.value.page = BigInt(-1);
    state.value.num_pages = BigInt(1);
  };

  const setTab = async (e: TabItem, refresh: boolean) => {
    activeTabItem.value = e;
    if (refresh) {
      await fetchNotifications();
    }
  };

  const fetchNotifications = async () => {
    // for first page fetch double the elements
    const activeTab = activeTabItem.value.title;
    const prevActiveTab = state.value.active_tab;
    const activeTabChanged = activeTab !== prevActiveTab;

    let page = activeTabChanged ? BigInt(0) : state.value.page + BigInt(1);
    let total = activeTabChanged ? BigInt(1) : state.value.num_pages;

    if (page >= total) {
      return;
    }
    try {
      const include_types: NotificationPageQuery['include_types'] =
        activeTab === 'Mentions'
          ? [[{ CommentMention: null }, { PostMention: null }]]
          : [];
      const payload: NotificationPageQuery = {
        page,
        page_size: NOTIFICATIONS_PAGE_SIZE,
        include_types,
        grouping_enabled: [notificationSettingStore.groupNotifications],
      };
      const n = await useNotificationQuery(payload);
      if ('Ok' in n) {
        const newNotifications = n.Ok.notification_page.notifications;
        const stateNotifications = activeTabChanged
          ? []
          : state.value.notifications;
        newNotifications.forEach((read) => {
          const unread = stateNotifications.find(
            (o) => o.notification_id === read.notification_id,
          );
          if (!unread) {
            stateNotifications.push(read);
          } else {
            unread.read_at = read.read_at;
          }
        });
        state.value.page = n.Ok.notification_page.page;
        state.value.num_pages = n.Ok.notification_page.num_pages;
        state.value.active_tab = activeTab;
        state.value.notifications = stateNotifications;
      }
    } catch (error) {
      state.value.notifications = [];
      state.value.page = BigInt(-1);
      state.value.num_pages = BigInt(1);
      state.value.active_tab = activeTab;
      console.error(error);
    }
  };

  const markNotificationsAsRead = async () => {
    const ids = state.value.notifications
      .filter((n) => n.read_at && n.read_at.length === 0)
      .flatMap((n) => {
        const ids = n.notification_group[0]?.ids[0];
        return ids ? Array.from(ids) : [n.notification_id];
      });
    await useReadNotificationMutation(ids);
    await refetchGlobalLiveDataQuery();
  };

  const markAllNotificationsAsRead = async () => {
    await useReadAllNotificationMutation();
  };

  return {
    state,
    isEmpty,
    isOpen,
    isPortalAdmin,
    tabItems,
    activeTabItem,
    resetState,
    setTab,
    openDrawer,
    closeDrawer,
    fetchNotifications,
    unreadNotificationCount,
    markNotificationsAsRead,
    markAllNotificationsAsRead,
  };
});
