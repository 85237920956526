import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { uniqueId } from 'lodash-es';
import { useBreakpoint } from '@/shared/model';
import { useNotificationSettingStore } from '@/entities/user';
import { useNotificationStore } from '@/entities/notification';

export const useNotification = () => {
  // Notification store
  const notificationStore = useNotificationStore();
  const { isOpen, activeTabItem, tabItems, isEmpty } =
    storeToRefs(notificationStore);

  // Notification Settings store
  const notificationSettingStore = useNotificationSettingStore();
  const { inAppNotifications } = storeToRefs(notificationSettingStore);

  const contentRef = ref<HTMLElement | null>(null);
  const { isSmallerThanLg } = useBreakpoint();

  const drawerClasses = computed(() => {
    const containerClass = isSmallerThanLg.value ? 'w-screen' : 'w-[548px]';
    return `${containerClass} h-screen bg-gray-blue-900 divide-y divide-gray-800`;
  });

  watch(
    () => isOpen.value,
    async (open) => {
      if (!open) {
        notificationStore.resetState();
        document.documentElement.style.overflow = 'auto';
      } else {
        await notificationSettingStore.getUserSettings();
        document.documentElement.style.overflow = 'hidden';
      }
    },
    { immediate: true },
  );

  watch(
    () => activeTabItem.value,
    (tabItem, oldTabItem) => {
      if (tabItem !== oldTabItem) {
        contentRef.value?.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
      }
    },
  );

  watch(
    () => inAppNotifications.value,
    (settings) => {
      if (settings?.mentions) {
        if (settings.mentions.length === 0) {
          tabItems.value = tabItems.value.filter(
            (tab) => tab.title !== 'Mentions',
          );
        } else {
          if (tabItems.value.find((tab) => tab.title === 'Mentions')) return;
          tabItems.value.push({
            id: uniqueId(),
            title: 'Mentions',
            disabled: false,
          });
        }
      }
    },
    { immediate: true },
  );

  return {
    isOpen,
    tabItems,
    activeTabItem,
    isEmpty,
    contentRef,
    drawerClasses,
    closeDrawer: notificationStore.closeDrawer,
    setTab: notificationStore.setTab,
  };
};
