export const opperators = {
  '*': (n1, n2) => n1 * n2,
  '/': (n1, n2) => n1 / n2,
  '+': (n1, n2) => n1 + n2,
  '-': (n1, n2) => n1 - n2,
};

export const isMobile = () => screen.width <= 640;

/**
 *
 */
export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}

/**
 *
 * @param min
 * @param max
 */
export function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/**
 *
 * @param fn
 * @param delay
 * @param retries
 */
export function callWithRetry(fn, delay = 1000, retries = 5) {
  return fn().catch((err) => {
    console.error(err);
    if (retries > 1) {
      return new Promise((resolve) => {
        //get a random opperator and either add or subtract from the new delay then call the function again
        const randAddOrSub =
          Object.values(opperators)[Math.round(Math.random()) + 2];
        const jitterDelay = () =>
          Math.round(
            randAddOrSub(
              delay * 2,
              (delay * Math.round(Math.random() * 100)) / 100,
            ),
          );

        setTimeout(
          () => resolve(callWithRetry(fn, jitterDelay(), retries - 1)),
          delay,
        );
      });
    }
    return Promise.reject(err);
  });
}

/**
 *
 * @param px
 */
export function pxToNumber(px) {
  return Number(px.replace('px', ''));
}

/**
 *
 */
export function getBreakpoints() {
  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  };

  const windowWidth = window.innerWidth;

  let breakpoint = '';
  if (windowWidth <= breakpoints.sm) {
    breakpoint = 'smAndSmaller';
  } else if (windowWidth <= breakpoints.md) {
    breakpoint = 'mdAndSmaller';
  } else if (windowWidth <= breakpoints.lg) {
    breakpoint = 'lgAndSmaller';
  } else if (windowWidth <= breakpoints.xl) {
    breakpoint = 'xlAndSmaller';
  } else {
    breakpoint = 'xxlAndLarger';
  }
  return breakpoint;
}

/**
 *
 * @param matches
 * @param href
 */
export function matchesLink(matches, href) {
  return matches.some((match) => href.startsWith(match));
}
