import { computed, type Ref } from 'vue';
import { useSocialPresence } from '@/composables';
import { useToast } from '@/shared/model';
import type { SocialNetwork, SocialNetworkType } from '@/utils';
import { isValidUrl } from '@/utils';

interface SocialLinksOptions {
  pinnedSocialNetworks?: SocialNetworkType[];
  genericWebsitesLimit?: number;
  validateUsername?: boolean;
  validateGroup?: boolean;
}

export function useSocialLinks(
  links: Ref<string[]>,
  options?: SocialLinksOptions,
) {
  const {
    availableSocialNetworks,
    matchLinks,
    getSocialNetwork,
    findLinkUsername,
    findLinkGroup,
  } = useSocialPresence();
  const { showToast } = useToast();

  const matchedSocialNetworks = computed(() => matchLinks(links.value));

  const pinnedSocialNetworks = computed(() =>
    (options?.pinnedSocialNetworks ?? []).map((type) => {
      const matched = matchedSocialNetworks.value.find(
        ({ socialNetwork }) => socialNetwork.type === type,
      );
      return matched
        ? matched
        : {
            link: '',
            socialNetwork: getSocialNetwork(type)!,
          };
    }),
  );

  const unpinnedSocialNetworks = computed(() =>
    matchedSocialNetworks.value.filter(
      ({ socialNetwork }) =>
        !(options?.pinnedSocialNetworks ?? []).includes(socialNetwork.type),
    ),
  );

  const genericLimitReached = computed(() => {
    const limit = options?.genericWebsitesLimit ?? 10;
    const genericLinks = matchedSocialNetworks.value.filter(
      ({ socialNetwork }) => socialNetwork.type === 'Website',
    );
    return genericLinks.length >= limit;
  });

  const nonGenericLimitReached = computed(() => {
    const nonGenericLinks = matchedSocialNetworks.value.filter(
      ({ socialNetwork }) => socialNetwork.type !== 'Website',
    );
    return nonGenericLinks.length >= availableSocialNetworks.value.length;
  });

  const limitReached = computed(() => {
    return genericLimitReached.value && nonGenericLimitReached.value;
  });

  const socialNetworkExists = (newSocialNetwork: SocialNetwork) => {
    if (newSocialNetwork.type === 'Website') return true;
    return !matchedSocialNetworks.value.some(
      ({ socialNetwork }) => socialNetwork.type === newSocialNetwork.type,
    );
  };

  const appendSocialNetwork = (
    newLink: string,
    newSocialNetwork: SocialNetwork,
    prepend = true,
  ): boolean => {
    if (!isValidUrl(newLink, true)) {
      showToast({
        type: 'error',
        title: 'Invalid url format',
        durationSeconds: 5,
      });
      return false;
    }
    const exists = matchedSocialNetworks.value.find(
      ({ link }) => link === newLink,
    );
    if (exists) {
      showToast({
        type: 'error',
        title: `This link already exists for ${exists.socialNetwork.type}`,
        durationSeconds: 5,
      });
      return false;
    }
    if (!socialNetworkExists(newSocialNetwork)) {
      showToast({
        type: 'error',
        title: `You can only add one link per social network ${newSocialNetwork.type}`,
        durationSeconds: 5,
      });
      return false;
    }

    if (
      options?.validateUsername &&
      newSocialNetwork.matchesUser &&
      newSocialNetwork.matchesUser.length
    ) {
      const username = findLinkUsername(newLink, newSocialNetwork);
      if (!username) {
        showToast({
          type: 'error',
          title: `Invalid link. Social Network ${newSocialNetwork.type} requires a user. i.e "${newSocialNetwork.example}"`,
          durationSeconds: 5,
        });
        return false;
      }
    }

    if (
      options?.validateGroup &&
      newSocialNetwork.matchesGroup &&
      newSocialNetwork.matchesGroup.length
    ) {
      const groupValue = findLinkGroup(newLink, newSocialNetwork);
      if (!groupValue) {
        showToast({
          type: 'error',
          title: `Invalid link. Social Network ${newSocialNetwork.type} requires a ${newSocialNetwork.groupName}. i.e "${newSocialNetwork.example}"`,
          durationSeconds: 5,
        });
        return false;
      }
    }

    if (newSocialNetwork.type === 'Website' && genericLimitReached.value) {
      const limit = options?.genericWebsitesLimit ?? 10;
      showToast({
        type: 'error',
        title: `You can only add ${limit} generic website links`,
        durationSeconds: 5,
      });
      return false;
    }

    if (prepend) {
      links.value = [newLink, ...links.value];
    } else {
      links.value = [...links.value, newLink];
    }
    return true;
  };

  return {
    matchedSocialNetworks,
    limitReached,
    getSocialNetwork,
    pinnedSocialNetworks,
    unpinnedSocialNetworks,
    appendSocialNetwork,
    matchLinks,
  };
}
