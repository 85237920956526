import { generateString } from '@/shared/lib';

const OAUTH2_LOCALSTORAGE_KEY = 'oauth2-state';

export const useOauth2State = () => {
  const generateOauth2State = () => {
    return generateString(9);
  };

  const getLocalOauth2State = () => {
    return localStorage.getItem(OAUTH2_LOCALSTORAGE_KEY);
  };

  const setLocalOauth2State = (state: string) => {
    localStorage.setItem(OAUTH2_LOCALSTORAGE_KEY, state);
  };

  const generateAndStoreOauth2State = () => {
    const state = generateOauth2State();
    setLocalOauth2State(state);
    return state;
  };
  return {
    generateOauth2State,
    getLocalOauth2State,
    setLocalOauth2State,
    generateAndStoreOauth2State,
  };
};
