import { useNFTGating } from '@/entities/nft-gate/model/composables/use-nft-gating';
import { useGetMECollectionListingsQuery } from '@/features/marketplace/api/magic-eden/use-get-me-collection-listings.query';
import { useGetMECollectionStatsQuery } from '@/features/marketplace/api/magic-eden/use-get-me-collection-stats.query';
import { useGetTensorCollectionListingsQuery } from '@/features/marketplace/api/tensor/use-get-tensor-collection-listings.query';
import type { dscvrApi, solApi } from '@/shared/api';
import { useGetTensorCollectionStatsQuery } from '@/features/marketplace/api/tensor/use-get-tensor-collection-stats.query';
import { useTensorStore } from '../store';
import { storeToRefs } from 'pinia';
import { findMarketplacePage } from '@/entities/token';

// Solana or Tensor
export const useSolanaListings = () => {
  const { getNftCollectionDetailsByAddress } = useNFTGating();
  const { setTensorCollectionIdMap } = useTensorStore();
  const { tensorCollectionIdMap } = storeToRefs(useTensorStore());

  // Tensor
  const getTensorNftCollectionListings = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
    limit = 1,
    cursor: string | undefined = undefined,
    sort = 'ListingPriceAsc',
  ): Promise<solApi.tensor.CursorDataResponse<solApi.tensor.TensorNft[]>> => {
    const tensorMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'tensor',
    );
    if (!tensorMarketplacePage) {
      return { data: [], page: { hasMore: false } };
    }
    const collectionSlug = tensorMarketplacePage.marketplace_collection_id;

    const collId = tensorCollectionIdMap.value.get(collectionSlug);
    if (!collId) {
      return { data: [], page: { hasMore: false } };
    }
    try {
      return await useGetTensorCollectionListingsQuery({
        collectionId: collId,
        limit,
        cursor,
        sort,
      });
    } catch {
      return {
        data: [],
        page: { hasMore: false },
      };
    }
  };

  const getTensorCollectionStats = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
  ) => {
    const tensorMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'tensor',
    );
    if (!tensorMarketplacePage) {
      return undefined;
    }
    const collectionSlug = tensorMarketplacePage.marketplace_collection_id;
    try {
      const response = await useGetTensorCollectionStatsQuery({
        collectionSlug: collectionSlug,
      });
      setTensorCollectionIdMap(collectionSlug, response.data.collId);
      return response.data.stats;
    } catch {
      return undefined;
    }
  };

  // Magic Eden
  const getMagicEdenNftCollectionListings = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
    page = 1,
    pageSize = 1,
    sort: solApi.collection.ListingSortType = 'listPrice',
    sortDirection: 'asc' | 'desc' = 'asc',
  ): Promise<solApi.collection.MagicEdenAuctionData[]> => {
    const magicEdenMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'magiceden',
    );
    if (!magicEdenMarketplacePage) {
      return [];
    }
    const collectionId = magicEdenMarketplacePage.marketplace_collection_id;
    return useGetMECollectionListingsQuery({
      collectionId,
      page,
      pageSize,
      sort,
      sortDirection,
    });
  };

  const getMagicEdenCollectionStats = async (
    collection: dscvrApi.multichainGating.MultichainCollectionDetailDto,
  ): Promise<solApi.collection.MagicEdenCollectionsStats | undefined> => {
    const magicEdenMarketplacePage = findMarketplacePage(
      collection.marketplace_pages,
      'magiceden',
    );
    if (!magicEdenMarketplacePage) {
      return undefined;
    }
    const collectionId = magicEdenMarketplacePage.marketplace_collection_id;
    return await useGetMECollectionStatsQuery(collectionId);
  };

  const getMagicEdenCollectionDetails = async (
    detail: dscvrApi.multichainGating.MultichainCollectionDetailDto,
  ) => {
    const stats = await getMagicEdenCollectionStats(detail);
    if (!stats) {
      console.log('Error getting stats for collection', detail.name);
      return null;
    }
    let openExternalUrl: string | undefined = undefined;
    const content = await getMagicEdenNftCollectionListings(detail);

    const magicEdenMarketplacePage = findMarketplacePage(
      detail.marketplace_pages,
      'magiceden',
    );
    if (magicEdenMarketplacePage) {
      openExternalUrl = magicEdenMarketplacePage.collection_url;
    }
    return { stats, openExternalUrl, noListings: content.length === 0 };
  };

  const getTensorCollectionDetails = async (
    detail: dscvrApi.multichainGating.MultichainCollectionDetailDto,
  ) => {
    const stats = await getTensorCollectionStats(detail);
    if (!stats) {
      console.log('Error getting stats for collection', detail.name);
      return null;
    }
    let openExternalUrl: string | undefined = undefined;
    const content = await getTensorNftCollectionListings(detail);

    const tensorMarketplacePage = findMarketplacePage(
      detail.marketplace_pages,
      'tensor',
    );
    if (tensorMarketplacePage) {
      openExternalUrl = tensorMarketplacePage.collection_url;
    }
    return { stats, openExternalUrl, noListings: content.data.length === 0 };
  };

  const getMultichainCollectionDetails = async (
    collection: dscvrApi.multichainGating.MultichainGateDto,
  ) => {
    const details = await getNftCollectionDetailsByAddress(
      collection.nftCollectionAddress,
    );
    if (!details.length) {
      return null;
    }
    const detail = details[0];

    const magicEdenCollectionDetails = await getMagicEdenCollectionDetails(
      detail,
    );
    const tensorCollectionDetails = await getTensorCollectionDetails(detail);

    if (!magicEdenCollectionDetails && !tensorCollectionDetails) {
      return null;
    }

    return {
      collection,
      detail,
      statsMagicEden: magicEdenCollectionDetails?.stats,
      statsTensor: tensorCollectionDetails?.stats,
      openExternalUrlMagicEden: magicEdenCollectionDetails?.openExternalUrl,
      openExternalUrlTensor: tensorCollectionDetails?.openExternalUrl,
      noListingsTensor: tensorCollectionDetails?.noListings,
      noListingsMagicEden: magicEdenCollectionDetails?.noListings,
    };
  };

  return {
    getMagicEdenNftCollectionListings,
    getMagicEdenCollectionStats,
    getMultichainCollectionDetails,
    getTensorNftCollectionListings,
  };
};
