import { client } from '../utils/dfinity';
import axios from 'axios';

export default {
  listCollections() {
    return client.actor.nft_list_collections();
  },

  listEntrpotCollections() {
    return axios.get(
      `https://us-central1-entrepot-api.cloudfunctions.net/api/collections`,
    );
  },
};
