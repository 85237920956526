import { unref, type MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { isValidHttpUrl } from '@/shared/lib';
import { dscvrApi } from '@/shared/api';
import { getCanvasQueryKey } from './keys';
import { parseAppInfo } from '../lib/parse-app-info';

export const useGetCanvasQuery = (url: MaybeRef<string | undefined>) => {
  return useQuery({
    queryKey: getCanvasQueryKey(url),
    queryFn: async () => {
      const urlValue = unref(url);
      if (!urlValue || !isValidHttpUrl(urlValue)) return;

      const appInfo = await dscvrApi.proxy.getEmbeddedApp(urlValue);

      if (!appInfo || appInfo.type !== 'canvas') {
        return;
      }

      return appInfo.data;
    },
    select: parseAppInfo,
    enabled: () => !!unref(url),
  });
};
