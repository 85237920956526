import { computed } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import type { PortalView, UserView } from 'dfx/edge/edge.did';

export type UsersHash = Map<string, UserView>;

export type PortalsHash = Map<string, PortalView>;

/**
 *
 */
export function useEmbeddedMention() {
  const store = useStore();
  const usersHash = computed<UsersHash>(
    () => store.getters['auth/mentionsHash'],
  );
  const portalsHash = computed<PortalsHash>(
    () => store.getters['portals/mentionsHash'],
  );

  /**
   *
   * @param usernames
   */
  function fetchUsers(usernames: string[]) {
    usernames.forEach((username) =>
      store.dispatch(`auth/${ActionTypes.GET_MENTION}`, username),
    );
  }

  /**
   *
   * @param portalSlugs
   */
  function fetchPortals(portalSlugs: string[]) {
    portalSlugs.forEach((slug) =>
      store.dispatch(`portals/${ActionTypes.GET_MENTION}`, slug),
    );
  }

  return {
    usersHash,
    portalsHash,
    fetchPortals,
    fetchUsers,
  };
}
