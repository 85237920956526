export class Token {
  constructor(args) {
    Object.assign(this, args);
  }

  addRegistryData(args) {
    Object.assign(this, args);
  }

  toDisplay() {
    const displayBalance = Number(
      Number(Number(this.balance) / 10 ** Number(this.decimals)).toFixed(4),
    );

    if (displayBalance == 0 && this.balance > 0) {
      return Number(Number(this.balance) / 10 ** Number(this.decimals)).toFixed(
        Number(this.decimals),
      );
    } else {
      return displayBalance;
    }
  }

  isScientificNotation(number) {
    return number.toString().indexOf('e') !== -1;
  }

  getScientificNotation(number) {
    return Math.abs(number.toString().split('e')[1]);
  }

  getFeeDisplay() {
    const feeDisplay = this.toDisplayAmount(this.fee);
    if (this.isScientificNotation(feeDisplay)) {
      return feeDisplay.toFixed(this.getScientificNotation(feeDisplay));
    }
    return feeDisplay;
  }

  toDisplayAmount(amount) {
    return Number(amount) / 10 ** Number(this.decimals);
  }

  hasEnoughToTip() {
    return (
      this.toDisplayAmount(this.balance) - this.getFeeDisplay() >
      (this.defaultAmounts.length > 0 ? this.defaultAmounts[0] : 0)
    );
  }

  toBaseAmount(amount) {
    return Math.floor(Number(amount) * 10 ** Number(this.decimals));
  }
}
