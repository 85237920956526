export const twitterKey = 'twitterKey';

export const getMyTwitterProfileKey = [
  twitterKey,
  'get-my-twitter-profile',
] as const;

export const verificationEmailKey = 'verification-email';
export const sendVerificationEmailKey = [
  verificationEmailKey,
  'send-verification-email',
];
export const verificationEmailStatusKey = [
  verificationEmailKey,
  'verification-email-pending-status',
];
export const validateVerificationEmailTokenKey = [
  verificationEmailKey,
  'validate-verification-email-token',
];
