import { computed, type Ref } from 'vue';
import type { UserView } from 'dfx/edge/edge.did';
import { useQuery } from '@tanstack/vue-query';
import { getUserByIdKey } from './keys';
import { dscvrApi } from '@/shared/api';

export const useGetUsersByPkQuery = (user: Ref<UserView>) => {
  const userId = computed(() => user.value.id.toText());

  return useQuery({
    queryKey: [...getUserByIdKey(userId), 'pk'],
    queryFn: () => dscvrApi.users.getUserByPk(userId.value),
  });
};
