import type { Principal } from '@dfinity/principal';
import { apiInstance } from '../base';
import type {
  GetMyWalletsDto,
  LinkWalletDto,
  LinkWalletMobileDto,
  UserWallet,
  Wallet,
  SolanaFungiblesResponse,
  SolanaNonFungiblesResponse,
  PatchWalletStatus,
} from './types';

export type {
  Wallet,
  WalletType,
  NetworkType,
  LinkWalletDto,
  LinkWalletMobileDto,
  SolanaFungiblesResponse,
  SolanaFungiblesResponseWalletToken,
  SolanaNonFungiblesResponseWallet,
  SolanaNonFungiblesResponseWalletToken,
  PatchWalletStatus,
} from './types';
export { WALLET_TYPES } from './types';

const BASE_URL = '/wallets';

export const pairWallet = (dto: LinkWalletDto) => {
  return apiInstance
    .post<{ data: Wallet }>(`${BASE_URL}/me/link`, dto)
    .then((response) => response.data.data);
};

export const pairWalletDeepLink = (dto: LinkWalletMobileDto) => {
  return apiInstance
    .post<{ data: Wallet }>(`${BASE_URL}/mobile/link`, dto)
    .then((response) => response.data.data);
};

export const unpairWallet = (address: string): Promise<void> => {
  return apiInstance
    .delete(`${BASE_URL}/me/${address}`)
    .then((response) => response.data.data);
};

export const getMyWallets = (dto: GetMyWalletsDto = {}) => {
  return apiInstance
    .get<{ data: Wallet[] }>(`${BASE_URL}/me`, {
      params: dto,
    })
    .then((response) => response.data.data);
};

export const getUserWallets = (dscvrPk: string, network = 'solana') => {
  return apiInstance
    .get<{ data: Wallet[] }>(`${BASE_URL}/user/${dscvrPk}`, {
      params: { network },
    })
    .then((response) => response.data.data);
};

export const getUserByWalletAddress = (
  address: string,
): Promise<UserWallet | null> => {
  return apiInstance
    .get<{ data: UserWallet }>(`${BASE_URL}/${address}`, {
      validateStatus: (status) => [200, 404].includes(status),
    })
    .then((response) => (response.status === 404 ? null : response.data.data));
};

export const getUserNFTs = (
  principal: Principal | string,
  network = 'solana',
  address?: string,
) => {
  return apiInstance
    .get<SolanaNonFungiblesResponse>(`${BASE_URL}/user/${principal}/nfts`, {
      params: { network, address },
    })
    .then((response) => response.data.data);
};

export const getUserFTs = (principal: Principal | string) => {
  return apiInstance
    .get<SolanaFungiblesResponse>(
      `${BASE_URL}/user/${principal}/solana-fungibles`,
    )
    .then((response) => response.data);
};

export const updateWalletStatus = (
  address: string,
  status: PatchWalletStatus,
) => {
  return apiInstance
    .patch<{ data: Wallet }>(`${BASE_URL}/me/${address}`, status)
    .then((response) => response.data.data);
};
