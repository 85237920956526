<template>
  <div v-if="portalView">
    <div class="flex-row sm:flex">
      <div class="pt-6 pl-4 mr-4 -my-4 -ml-4 roles-sidebar">
        <router-link
          :to="{ name: PORTAL_SETTINGS_PAGE_ROLES }"
          class="flex items-center pr-4 mb-2 text-white cursor-pointer"
        >
          <base-icon name="chevron-left" size="w-4 h-4" class="ml-2 mr-2" />
          <span>Back</span>
        </router-link>
        <div>
          <base-button
            variant="custom"
            v-for="role in portalRolesDto"
            :key="role.id"
            :to="{
              name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
              params: { roleId: role.id },
            }"
            class="flex items-center p-2 pr-4 mt-2 text-white"
            rounded="rounded-none"
            :class="{
              'primary-bg-600': editedRole != null && role.id == editedRole.id,
              'pointer-events-none opacity-50':
                nftSauronCustomRole?.id &&
                nftSauronCustomRole.id.toString() === role.id.toString(),
            }"
          >
            <diamond :color="role.color" size="size-3" class="mr-2" />
            <svg
              v-show="role.is_locked"
              class="w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                class="text-gray-500 fill-current"
                d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z"
              />
            </svg>
            <div
              class="w-24 overflow-hidden text-sm overflow-ellipsis whitespace-nowrap"
            >
              {{ role.name }}
            </div>
          </base-button>
        </div>
      </div>
      <div class="w-full mt-4 md:mt-0">
        <div
          v-if="editedRole && editedRole.id"
          class="pt-1 text-lg font-bold text-white"
        >
          Edit Role - {{ editedRole.name }}
        </div>
        <div v-else class="pt-4 text-lg font-bold text-white sm:pt-1">
          New Role
        </div>

        <error-display
          class="mt-3"
          :errors="errors"
          :error-message="errorMessage"
          :local-errors="localErrors"
        />

        <div class="mb-5">
          <nav
            class="flex flex-col justify-around w-full border-b border-gray-700 sm:flex-row whitespace-nowrap"
          >
            <button
              class="block py-4 hover:text-white focus:outline-none"
              :class="{
                'text-gray-600': activeTab != tabs.DISPLAY,
                'text-white': activeTab == tabs.DISPLAY,
                'font-medium': activeTab == tabs.DISPLAY,
                'border-b-2': activeTab == tabs.DISPLAY,
                'border-white': activeTab == tabs.DISPLAY,
              }"
              @click="activeTab = tabs.DISPLAY"
            >
              Display
            </button>
            <button
              class="block py-4 hover:text-white focus:outline-none"
              :class="{
                'text-gray-600': activeTab != tabs.PERMISSIONS,
                'text-white': activeTab == tabs.PERMISSIONS,
                'font-medium': activeTab == tabs.PERMISSIONS,
                'border-b-2': activeTab == tabs.PERMISSIONS,
                'border-white': activeTab == tabs.PERMISSIONS,
              }"
              @click="activeTab = tabs.PERMISSIONS"
            >
              Permissions
            </button>
            <button
              class="block py-4 focus:outline-none"
              :class="{
                'text-gray-600': activeTab != tabs.MEMBERS,
                'text-white': activeTab == tabs.MEMBERS,
                'font-medium': activeTab == tabs.MEMBERS,
                'border-b-2': activeTab == tabs.MEMBERS,
                'border-white': activeTab == tabs.MEMBERS,
                'hover:text-white': isEditedRoleCustom,
                'cursor-default': !isEditedRoleCustom,
              }"
              @click="
                isEditedRoleCustom == true ? (activeTab = tabs.MEMBERS) : ''
              "
            >
              Manage Members
            </button>
          </nav>
        </div>

        <div class="display" :class="{ hidden: activeTab != tabs.DISPLAY }">
          <div class="mb-6">
            <label
              for="roleNameInput"
              class="block font-semibold text-white"
              :class="{
                'opacity-50':
                  (!isEditedRoleCustom && !isEditedRoleDefault) ||
                  editedRole.is_locked,
              }"
            >
              Role name
            </label>
            <input
              id="roleNameInput"
              v-model="editedRole.name"
              type="text"
              :disabled="
                (isEditedRoleCustom || isEditedRoleDefault) &&
                !editedRole.is_locked
                  ? null
                  : 'disabled'
              "
              class="block w-full mt-1 text-white bg-gray-900 border-none rounded-md disabled:opacity-50 focus:bg-gray-700 focus:ring-0"
              maxlength="20"
              required="true"
              minlength="1"
              placeholder=""
              @keypress="roleDataOrPermissionsModified = true"
            />
          </div>
          <div class="mb-6">
            <label
              class="block font-semibold text-white"
              :class="{
                'opacity-50':
                  (!isEditedRoleCustom && !isEditedRoleDefault) ||
                  editedRole.is_locked,
              }"
            >
              Role color
            </label>
            <span
              class="block mb-4 text-xs text-gray-400"
              :class="{
                'opacity-50':
                  (!isEditedRoleCustom && !isEditedRoleDefault) ||
                  editedRole.is_locked,
              }"
            >
              Members use the color of the highest role they have on the roles
              list
            </span>
            <div class="grid grid-cols-8 gap-6 content-center md:grid-cols-11">
              <diamond
                v-for="color in roleColorList.slice(
                  0,
                  roleColorList.length - 1,
                )"
                :key="color"
                :color="color"
                rounded="rounded"
                size="size-8"
                class="cursor-pointer"
                :class="{
                  'border-2 border-white': editedRole.color === color,
                  'opacity-50':
                    (!isEditedRoleCustom && !isEditedRoleDefault) ||
                    editedRole.is_locked,
                }"
                @click="
                  (isEditedRoleCustom || isEditedRoleDefault) &&
                  !editedRole.is_locked
                    ? onClickedColor(color)
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div
          class="permissions"
          :class="{ hidden: activeTab != tabs.PERMISSIONS }"
        >
          <div class="mb-6">
            <input
              v-model="searchedPermission"
              type="text"
              class="block w-full mt-1 text-white bg-gray-900 border-none rounded-md focus:bg-gray-700 focus:ring-0"
              maxlength="12"
              placeholder="Search permissions..."
            />
          </div>
          <div class="mb-6">
            <div
              v-for="permission in filteredPermissions"
              :key="permission.flag"
              class="py-3 text-white border-b border-gray-700"
            >
              <div class="flex">
                <div
                  class="opacity-50"
                  :class="{
                    'opacity-100':
                      !editedRole.is_locked &&
                      editedRolePermissions.containsFlag(permission.flag),
                  }"
                >
                  {{ permission.name }}
                </div>
                <div class="ml-auto">
                  <!-- toggle -->
                  <div
                    class="relative flex items-center justify-center w-full toggle"
                    @click="
                      !editedRole.is_locked ? changePermission(permission) : ''
                    "
                  >
                    <!-- input -->
                    <input id="toggle" type="checkbox" class="sr-only" />
                    <!-- line -->
                    <div
                      class="block h-8 bg-gray-600 rounded-full w-14 line"
                      :class="{
                        'bg-pink-600':
                          !editedRole.is_locked &&
                          editedRolePermissions.containsFlag(permission.flag),
                      }"
                    ></div>
                    <!-- dot -->
                    <div
                      class="absolute w-6 h-6 transition transform bg-white rounded-full dot top-1 left-1"
                      :class="{
                        'translate-x-full': editedRolePermissions.containsFlag(
                          permission.flag,
                        ),
                      }"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="mt-1 text-xs opacity-50"
                :class="{
                  'opacity-100':
                    !editedRole.is_locked &&
                    editedRolePermissions.containsFlag(permission.flag),
                }"
              >
                {{ permission.description }}
              </div>
            </div>
          </div>
        </div>
        <div class="members" :class="{ hidden: activeTab != tabs.MEMBERS }">
          <div
            class="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center h-screen bg-gray-900 min-w-screen animated fadeIn faster o utline-none focus:outline-none bg-opacity-20"
            :class="{ hidden: !showAddMembersPopup }"
          >
            <div class="absolute inset-0 z-0 bg-black opacity-80"></div>
            <div
              class="relative w-full max-w-lg p-5 mx-auto my-auto bg-gray-950 shadow-lg rounded-xl"
            >
              <!--content-->
              <div>
                <div class="flex items-center justify-between px-4">
                  <span>
                    <!-- filler -->
                  </span>
                  <span class="text-lg font-semibold"> Add Members </span>
                  <base-icon
                    name="close"
                    class="cursor-pointer"
                    @click="
                      (temporaryMembersToAdd = []),
                        (showAddMembersPopup = false)
                    "
                  />
                </div>
                <!--body-->
                <div class="justify-center flex-auto p-5 text-center">
                  <input
                    v-model="searchedMember"
                    type="text"
                    class="block w-full mt-1 text-white bg-gray-900 border-0 border-transparent rounded-md memberSearch focus:bg-gray-700 focus:ring-0"
                    maxlength="12"
                    placeholder="Search members..."
                  />
                  <div class="mt-3 overflow-y-auto thin-scrollbar h-52">
                    <div
                      v-for="member in filteredMembers"
                      :key="member.id"
                      class="flex items-center justify-between py-4 text-white border-b border-gray-700 cursor-pointer"
                      @click="addRemoveMemberTemporary(member)"
                    >
                      <div class="inline-flex ml-1">
                        <user-avatar
                          v-if="member.user"
                          :item="member.user"
                          size="size-6"
                          class="mr-3"
                        />
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6 mr-3"
                        >
                          <path
                            class="text-white fill-current"
                            d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"
                          />
                        </svg>
                        <div>{{ member.user.username }}</div>
                      </div>

                      <input
                        v-if="temporaryMembersToAdd.indexOf(member) != -1"
                        type="checkbox"
                        class="bg-gray-700 rounded form-checkbox"
                        checked
                      />
                      <input
                        v-else
                        type="checkbox"
                        class="bg-gray-700 rounded form-checkbox"
                      />
                    </div>
                  </div>
                </div>
                <!--footer-->
                <div class="flex float-right p-3 mt-2">
                  <div>
                    <button
                      class="ml-3 text-xs btn-submit-gray whitespace-nowrap"
                      @click="
                        (temporaryMembersToAdd = []),
                          (showAddMembersPopup = false)
                      "
                    >
                      Close
                    </button>
                  </div>
                  <div>
                    <button
                      class="ml-3 text-xs btn-submit whitespace-nowrap"
                      @click="
                        addAllTemporaryMembers(), (showAddMembersPopup = false)
                      "
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col items-center float-right mt-2 mb-6 text-center sm:flex-row justify-center"
          >
            <button
              class="ml-3 text-xs btn-submit whitespace-nowrap"
              :class="{
                'opacity-50': editedRole.is_locked,
                'pointer-events-none': editedRole.is_locked,
              }"
              @click="showAddMembersPopup = true"
            >
              Add member
            </button>
          </div>

          <div
            class="flex items-center justify-between w-full py-1 my-4 bg-gray-900 rounded-lg flex-nowrap"
          >
            <base-icon
              name="search"
              size="w-5 h-5"
              class="ml-2 cursor-pointer"
            />
            <input
              v-model="searchedMember"
              type="text"
              class="block w-full text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
              placeholder="Search Members..."
            />
          </div>

          <p
            v-show="!filteredAddedMembers"
            class="p-3 text-center text-small"
            :class="{
              'opacity-50': editedRole.is_locked,
              'pointer-events-none': editedRole.is_locked,
            }"
          >
            No added members
          </p>
          <div
            v-for="member in filteredAddedMembers"
            :key="member.id"
            class="flex items-center py-3 text-white border-b border-gray-700"
            :class="{
              'opacity-50': editedRole.is_locked,
              'pointer-events-none': editedRole.is_locked,
            }"
          >
            <user-avatar
              v-if="member.user"
              :item="member.user"
              size="w-8 h-8"
              class="mr-3"
            />
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mr-3">
              <path
                class="text-white fill-current"
                d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"
              />
            </svg>
            <div>{{ member.user.username }}</div>
            <div
              class="p-1 ml-auto bg-gray-700 rounded-lg"
              @click="removeMember(member)"
            >
              <base-icon name="close" />
            </div>
          </div>
        </div>
        <div class="mt-6 text-right">
          <button
            :class="{
              'opacity-50': editedRole.is_locked || !editedRole.name,
              'pointer-events-none': editedRole.is_locked || !editedRole.name,
            }"
            class="text-xs btn-submit whitespace-nowrap"
            @click="onSubmit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, ref, toRef, watch } from 'vue';
  import ErrorDisplay from './ErrorDisplay.vue';
  import { ActionTypes } from '../store/action-types';
  import { PermissionBuilder, Permissions } from '../utils/permissions';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import {
    PORTAL_SETTINGS_PAGE_ROLES,
    PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
    ROLE_KIND_FIELD_CUSTOM,
    ROLE_KIND_FIELD_DEFAULT,
  } from '@/common';
  import { useBetaFeatures } from '@/composables';
  import { useToast } from '@/shared/model';
  import { useNFTGating } from '@/entities/nft-gate';
  import { roleGradients } from '@/entities/role';
  import { Diamond } from '@/shared/ui/diamond';
  import {
    useGetPortalMembersQuery,
    useGetPortalRolesQuery,
    useGetRoleMembersQuery,
    useAddPortalRoleMutation,
    getPortalBySlugKey,
    useUpdatePortalRoleMutation,
  } from '@/entities/portal';
  import { uniqBy } from 'lodash-es';
  import { useQueryClient } from '@tanstack/vue-query';

  export default {
    name: 'portal-settings-role-details',
    components: {
      ErrorDisplay,
      UserAvatar,
      Diamond,
    },
    props: {
      roleId: {
        type: String,
        default() {
          return null;
        },
      },
      portalView: {
        type: Object,
        default() {
          return null;
        },
      },
      roles: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        PORTAL_SETTINGS_PAGE_ROLES,
        PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
        tabs: {
          DISPLAY: 1,
          PERMISSIONS: 2,
          MEMBERS: 3,
        },
        activeTab: 1,
        roleGradients,
        editedRole: {
          name: 'New Role',
          permissions: 0,
          color: 15079755,
          icon_url: null,
          ordinal: this.portalRolesDto?.length ?? 1,
          is_locked: false,
          kind: {},
        },
        roleDataOrPermissionsModified: false,
        editedRolePermissions: new PermissionBuilder(),
        searchedPermission: null,

        searchedMember: null,
        searchedAddedMember: null,
        showAddMembersPopup: false,

        temporaryMembersToAdd: [],
        newMemberIdsToAdd: [],
        newMemberIdsToRemove: [],

        localErrors: [],
        errorMessage: '',
        errors: [],
      };
    },
    setup(props) {
      const { isBetaEnabled } = useBetaFeatures();
      const { nftSauronCustomRole, loadSauronPortalRoles } = useNFTGating();
      const { showToast } = useToast();
      const queryClient = useQueryClient();

      const currentPortalRoleMembers = ref([]);

      const queryParams = computed(() => ({
        includes_roles: false,
        page_size: BigInt(5000),
        page_start: BigInt(0),
        portal_id: props.portalView.id,
        role_ids: [],
      }));
      const { data: roleMembersDto } = useGetRoleMembersQuery(
        toRef(() => props.roleId),
      );
      const { data: membersDto } = useGetPortalMembersQuery(
        toRef(() => props.portalView),
        queryParams,
      );
      const { data: portalRolesDto } = useGetPortalRolesQuery(
        toRef(() => props.portalView),
      );

      const { mutate: addPortalRoleMutation } = useAddPortalRoleMutation();
      const { mutate: updatePortalRoleMutation } =
        useUpdatePortalRoleMutation();

      const members = computed(() => {
        const pages = membersDto.value?.pages ?? [];
        const membersList = pages.flatMap((page) => page);
        return uniqBy(membersList, 'id');
      });

      watch(roleMembersDto, (roleMembers) => {
        if (roleMembers) {
          currentPortalRoleMembers.value = [...roleMembers];
        }
      });

      return {
        isBetaEnabled,
        nftSauronCustomRole,
        loadSauronPortalRoles,
        showToast,
        members,
        portalRolesDto,
        currentPortalRoleMembers,
        addPortalRoleMutation,
        updatePortalRoleMutation,
        queryClient,
      };
    },
    computed: {
      permissions() {
        if (this.isBetaEnabled) {
          return Permissions;
        }

        return Permissions.filter((permission) => !permission.beta);
      },
      isEditedRoleCustom() {
        return (
          !this.editedRole.id ||
          (this.editedRole.kind &&
            ROLE_KIND_FIELD_CUSTOM in this.editedRole.kind)
        );
      },
      isEditedRoleDefault() {
        return (
          this.editedRole && ROLE_KIND_FIELD_DEFAULT in this.editedRole.kind
        );
      },
      nonAddedMembers() {
        return this.members?.filter(
          (member) =>
            !this.currentPortalRoleMembers?.find(
              (roleMember) => roleMember.id === member.id,
            ),
        );
      },
      filteredMembers() {
        return this.nonAddedMembers?.filter(
          (member) =>
            !this.searchedMember ||
            member.user.username
              .toLowerCase()
              .includes(this.searchedMember.toLowerCase()),
        );
      },
      filteredAddedMembers() {
        return this.currentPortalRoleMembers?.filter(
          (member) =>
            !this.searchedAddedMember ||
            member.user.username
              .toLowerCase()
              .includes(this.searchedAddedMember.toLowerCase()),
        );
      },
      filteredPermissions() {
        if (this.searchedPermission == null) return this.permissions;
        return this.permissions.filter((permission) =>
          permission.name
            .toLowerCase()
            .includes(this.searchedPermission.toLowerCase()),
        );
      },
      roleColorList() {
        return Array.from(this.roleGradients.keys());
      },
    },
    watch: {
      portalRolesDto: {
        handler(roles) {
          const roleId = this.$route.params.roleId;
          const role = roles?.find((role) => role.id == roleId);
          if (role) {
            this.editedRole = {
              id: role.id,
              name: role.name,
              permissions: role.permissions,
              ordinal: role.ordinal,
              color: role.color,
              icon_url: role.icon_url,
              is_locked: role.is_locked,
              kind: role.kind,
            };
            this.editedRolePermissions = new PermissionBuilder(
              PermissionBuilder.getFromValue(role ? role.permissions : 0),
            );
          }
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      this.validateRoleEdition();
    },
    activated() {
      this.validateRoleEdition();
    },
    methods: {
      async validateRoleEdition() {
        if (this.portalView) {
          await this.loadSauronPortalRoles(this.portalView);
          const isSauronRole =
            this.nftSauronCustomRole?.id &&
            this.nftSauronCustomRole?.id.toString() === this.roleId;
          if (isSauronRole) {
            this.$router.push({
              name: PORTAL_SETTINGS_PAGE_ROLES,
            });
            showToast({
              message: 'You cannot edit this role',
              type: 'error',
            });
          }
        }
      },
      onClickedColor(color) {
        this.roleDataOrPermissionsModified = true;
        this.editedRole.color = color;
      },
      changePermission(permission) {
        this.roleDataOrPermissionsModified = true;
        this.editedRolePermissions.containsFlag(permission.flag)
          ? this.editedRolePermissions.removeFlag(permission.flag)
          : this.editedRolePermissions.addFlag(permission.flag);
      },
      addRemoveMemberTemporary(member) {
        const index = this.temporaryMembersToAdd.indexOf(member);
        if (index == -1) this.temporaryMembersToAdd.push(member);
        else this.temporaryMembersToAdd.splice(index, 1);
      },
      addAllTemporaryMembers() {
        for (let i = 0; i < this.temporaryMembersToAdd.length; i++) {
          this.addMember(this.temporaryMembersToAdd[i]);
        }
      },
      removeMember(member) {
        const index = this.currentPortalRoleMembers.indexOf(member);
        if (index !== -1) {
          this.currentPortalRoleMembers.splice(index, 1);

          // remove from added list if necessary
          const addedIndex = this.newMemberIdsToAdd.indexOf(member.id);
          if (addedIndex !== -1) {
            this.newMemberIdsToAdd.splice(addedIndex);
          }

          // add to remove list
          this.newMemberIdsToRemove.push(member.id);
          this.members.push(member);
        }
      },
      addMember(member) {
        const index = this.members.indexOf(member);
        if (index !== -1) {
          this.members.splice(index, 1);

          // remove from removed list if neccessary
          const addedIndex = this.newMemberIdsToRemove.indexOf(member.id);
          if (addedIndex !== -1) {
            this.newMemberIdsToRemove.splice(addedIndex);
          }
          // add to added list
          this.newMemberIdsToAdd.push(member.id);
          this.currentPortalRoleMembers.push(member);
        }
      },
      submitMembers() {
        // members-related submissions
        if (this.editedRole.id) {
          if (this.newMemberIdsToAdd.length) {
            const members = [];
            for (let i = 0; i < this.newMemberIdsToAdd.length; i++) {
              members.push({
                memberId: this.newMemberIdsToAdd[i],
                roleId: this.editedRole.id,
              });
            }
            this.$store
              .dispatch(`portals/${ActionTypes.ADD_PORTAL_MEMBER_ROLE}`, {
                portal_id: this.portalView.id,
                members,
              })
              .then(() => {
                this.newMemberIdsToAdd = [];
                this.temporaryMembersToAdd = [];
              });
          }
          if (this.newMemberIdsToRemove.length) {
            const members = [];
            for (let i = 0; i < this.newMemberIdsToRemove.length; i++) {
              members.push({
                memberId: this.newMemberIdsToRemove[i],
                roleId: this.editedRole.id,
              });
            }
            this.$store
              .dispatch(`portals/${ActionTypes.REMOVE_PORTAL_MEMBER_ROLE}`, {
                portal_id: this.portalView.id,
                members,
              })
              .then(() => {
                this.newMemberIdsToRemove = [];
              });
          }
        }
      },
      onSubmit() {
        // only submit members if base role data has not changed and it is not a new role
        if (this.editedRole.id && this.roleDataOrPermissionsModified == false) {
          // submit members
          this.submitMembers();
          return;
        }
        this.roleDataOrPermissionsModified = false;

        // flatten permissions to int and add it to the role
        this.editedRole.permissions = this.editedRolePermissions.compute();
        const roleObject = {
          name: this.editedRole.name,
          permissions: this.editedRole.permissions,
          ordinal: this.editedRole.ordinal,
          color: this.editedRole.color,
          icon_url: '',
          kind: this.editedRole.kind,
        };
        // edit role
        if (this.editedRole.id) {
          roleObject.id = this.editedRole.id;
          this.updatePortalRoleMutation(roleObject, {
            onSuccess: async (data) => {
              if (data.status === 'happy') {
                await this.queryClient.cancelQueries({
                  queryKey: [
                    ...getPortalBySlugKey(this.portalView.slug),
                    'roles',
                  ],
                });
                this.queryClient.setQueryData(
                  [...getPortalBySlugKey(this.portalView.slug), 'roles'],
                  (oldData) => {
                    oldData.map((role) =>
                      role.id === roleObject.id ? data.result[0] : role,
                    );
                  },
                );
              } else {
                this.errorMessage = data.message;
                this.errors = data.errors[0];
              }
            },
          });

          // submit members
          this.submitMembers();
        }
        // add role
        else {
          this.addPortalRoleMutation(
            {
              portalId: this.portalView.id,
              role: roleObject,
            },
            {
              onSuccess: async (data) => {
                if (data?.status === 'happy') {
                  this.editedRole.id = data.result[0].id;
                  await this.queryClient.cancelQueries({
                    queryKey: [
                      ...getPortalBySlugKey(this.portalView.slug),
                      'roles',
                    ],
                  });
                  this.queryClient.setQueryData(
                    [...getPortalBySlugKey(this.portalView.slug), 'roles'],
                    (oldData) => {
                      return oldData.concat(data.result);
                    },
                  );
                  this.$router.push({
                    name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
                    params: { roleId: this.editedRole.id },
                  });
                  // submit members
                  this.submitMembers();
                } else {
                  this.errorMessage = data.message;
                  this.errors = data.errors[0];
                }
              },
            },
          );
        }
      },
    },
  };
</script>

<style>
  .roles-sidebar {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .form-checkbox:checked {
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .form-checkbox:hover,
  .form-checkbox:checked:focus,
  .form-checkbox:checked:hover {
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }
</style>
