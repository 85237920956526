import type { Gradient } from '@/shared/model';

export const coverGradients = new Map<number, Gradient>([
  [
    1,
    {
      direction: 'bg-gradient-to-br',
      from: 'from-pink-500',
      via: 'via-red-500',
      to: 'to-yellow-500',
    },
  ],
  [
    2,
    {
      direction: 'bg-gradient-to-br',
      from: 'from-green-200',
      via: 'via-green-300',
      to: 'to-blue-500',
    },
  ],
  [
    3,
    {
      direction: 'bg-gradient-to-br',
      from: 'from-rose-500',
      via: 'via-pink-500',
      to: 'to-indigo-400',
    },
  ],
  [
    4,
    {
      direction: 'bg-gradient-to-br',
      from: 'from-blue-300',
      via: 'via-green-200',
      to: 'to-yellow-300',
    },
  ],
  [
    5,
    {
      direction: 'bg-gradient-to-tl',
      from: 'from-green-300',
      via: 'via-blue-500',
      to: 'to-purple-600',
    },
  ],
  [
    6,
    {
      direction: 'bg-gradient-to-br',
      from: 'from-rose-400',
      via: 'via-fuchsia-500',
      to: 'to-indigo-500',
    },
  ],
  [
    7,
    {
      direction: 'bg-[conic-gradient(at_top,_var(--tw-gradient-stops))]',
      from: 'from-green-300',
      to: 'to-purple-400',
    },
  ],
  [
    8,
    {
      direction: 'bg-[conic-gradient(at_left,_var(--tw-gradient-stops))]',
      from: 'from-green-300',
      via: 'via-yellow-300',
      to: 'to-pink-300',
    },
  ],
]);
