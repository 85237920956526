import type {
  PortalHiglights,
  PortalView,
  UserPortalHighlight,
} from 'dfx/edge/edge.did';

export const sortFavoritePortals = (
  response: PortalHiglights[],
): PortalView[] => {
  if (response.length === 0) {
    return [];
  }

  const [{ portals = [], ordinals = [] }] = response;

  const sortedPortals = ordinals
    .sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
    .map(({ portal_id }) => {
      return portals.find((item) => item.id === portal_id)!;
    });

  return sortedPortals;
};

export const getPortalsOrdinals = (
  portals: PortalView[],
): UserPortalHighlight[] => {
  const ordinals = portals.map((portal, index) => ({
    portal_id: portal.id,
    ordinal: BigInt(index),
  }));

  return ordinals;
};
