<script setup lang="ts">
  import { computed } from 'vue';
  import LootboxItemSolNft from './LootboxItemSolNft.vue';
  import LootboxItemSolSpl from './LootboxItemSolSpl.vue';
  import LootboxItemDSCVRPoints from './LootboxItemDSCVRPoints.vue';
  import LootboxDisplayText from './LootboxDisplayText.vue';
  import LootboxItemClaim from './LootboxItemClaim.vue';
  import { dscvrApi } from '@/shared/api';
  import { useFormatMarkdownContent } from '@/composables';

  const props = withDefaults(
    defineProps<{
      lootboxItem: dscvrApi.lootbox.ILootboxItem;
      showCampaign?: boolean;
    }>(),
    {
      showCampaign: false,
    },
  );

  defineEmits<{
    (e: 'claimed', item: dscvrApi.lootbox.ILootboxItem): void;
  }>();

  const { compiledMarkdownAsHTML } = useFormatMarkdownContent();

  const lootboxItemBody = computed(() => {
    if (props.lootboxItem.campaign && props.showCampaign) {
      return compiledMarkdownAsHTML(props.lootboxItem.campaign.text);
    }
    return '';
  });

  const computedTokenType = computed(() => {
    switch (props.lootboxItem.tokenType) {
      case dscvrApi.lootbox.LootboxItemType.SOL_NFT ||
        dscvrApi.lootbox.LootboxItemType.IC_EXT_NFT ||
        dscvrApi.lootbox.LootboxItemType.SOL_TARGETED_NFT:
        return LootboxItemSolNft;
      case dscvrApi.lootbox.LootboxItemType.SOL_SPL ||
        dscvrApi.lootbox.LootboxItemType.ICRC:
        return LootboxItemSolSpl;
      case dscvrApi.lootbox.LootboxItemType.DSCVR_POINTS:
        return LootboxItemDSCVRPoints;
      default:
        break;
    }
    return LootboxItemSolNft;
  });
</script>

<template>
  <div
    class="relative bg-gray-950 border border-gray-785 border-opacity-80 rounded-xl group overflow-hidden ease-transition-all"
  >
    <div>
      <component :is="computedTokenType" :lootbox-item="lootboxItem" />
    </div>
  </div>
  <lootbox-item-claim
    v-if="showCampaign || !lootboxItem.isClaimed"
    :lootbox-item="lootboxItem"
    @claimed="$emit('claimed', lootboxItem)"
  />
  <div class="mt-5" v-if="lootboxItem.campaign && showCampaign">
    <lootbox-display-text :body="lootboxItemBody" />
  </div>
</template>
