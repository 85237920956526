<script setup lang="ts">
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useVModel } from '@vueuse/core';
  import type { SocialNetwork } from '@/utils';
  import { SingleLinkInput, SingleLinkDisplay } from './components';
  import { useSocialLinks } from './model';
  import { useConfirmationDialog } from '@/shared/ui/confirmation';

  const props = withDefaults(
    defineProps<{
      modelValue: string[];
      legend?: string;
      validateUsername?: boolean;
    }>(),
    {
      legend: 'Show off your social media presence in the right sidebar.',
      validateUsername: false,
    },
  );

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string[]): void;
  }>();

  const links = useVModel(props, 'modelValue', emit);
  const {
    pinnedSocialNetworks,
    unpinnedSocialNetworks,
    limitReached,
    appendSocialNetwork,
  } = useSocialLinks(links, {
    pinnedSocialNetworks: ['Twitter'],
    validateUsername: props.validateUsername,
    validateGroup: !props.validateUsername,
  });
  const { openConfirmationDialog, closeDialog } = useConfirmationDialog();
  const { t } = useI18n({ useScope: 'global' });
  const showAddLinkInput = ref<boolean>(true);

  const removeLinkType = (linkType: string) => {
    closeDialog();
    if (linkType) {
      links.value = links.value.filter((link) => link !== linkType);
    }
  };

  const appendLink = (
    newLink: string,
    newSocialNetwork: SocialNetwork,
    prepend = true,
  ) => {
    if (appendSocialNetwork(newLink.trim(), newSocialNetwork, prepend)) {
      showAddLinkInput.value = false;
    }
  };

  const openRemoveLinkDialog = (link: string) => {
    openConfirmationDialog({
      headerTitle: t('removeLink'),
      contentSlots: {
        default: { template: t('removeLinkLegend') },
        submitLabel: { template: t('remove') },
      },
      submit: () => removeLinkType(link),
    });
  };
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="flex justify-between gap-2 items-end w-full">
      <div class="flex-grow">
        <h2 class="mb-2 text-base font-bold text-white">Social Links</h2>
        <p class="text-sm text-gray-400">
          {{ legend }}
        </p>
      </div>

      <base-button
        variant="secondary"
        size="small"
        :disabled="showAddLinkInput || limitReached"
        @click="showAddLinkInput = true"
      >
        <base-icon name="plus" size="w-5 h-5" />
        <span>Add Link</span>
      </base-button>
    </div>
    <div class="flex flex-col gap-4 my-6 w-full">
      <single-link-input
        v-if="showAddLinkInput && !limitReached"
        @submit="appendLink"
      />
      <slot></slot>
      <single-link-display
        v-for="item in unpinnedSocialNetworks"
        :key="item.link"
        :link="item.link"
        :matched-social-network="item.socialNetwork"
        @remove="openRemoveLinkDialog(item.link)"
      />
      <template
        v-for="item in pinnedSocialNetworks"
        :key="item.socialNetwork.type"
      >
        <single-link-display
          v-if="item.link"
          :link="item.link"
          :matched-social-network="item.socialNetwork"
          @remove="openRemoveLinkDialog(item.link)"
        />
        <single-link-input
          v-else
          :default-social-network="item.socialNetwork"
          @submit="
            (link, socialNetwork) => appendLink(link, socialNetwork, false)
          "
        />
      </template>
    </div>
  </div>
</template>
