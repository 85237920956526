<script setup lang="ts">
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { useAuth } from '@/entities/auth';

  const { showLoginSignUpDialog } = useAuth();

  const signup = () => {
    showLoginSignUpDialog();
  };
</script>

<template>
  <div class="relative">
    <video
      class="absolute z-[-1] w-full h-full top-0 left-0 object-cover"
      :autoplay="true"
      :loop="true"
      :muted="true"
      :poster="
        fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/hero.png`, {
          quality: 100,
        })
      "
    >
      <source
        :src="
          fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/video.mp4`, {
            quality: 100,
          })
        "
        type="video/mp4"
      />
    </video>
    <div class="flex flex-col items-center justify-center">
      <h1
        class="text-[44px] font-bold sm:text-[56px] leading-[54px] sm:leading-[72px] pt-30 text-center sm:pt-48 sm:max-w-[840px] px-6 sm:px-0"
      >
        Pushing the boundaries of Web3 social media
      </h1>
      <p class="px-6 m-auto mt-6 title-content sm:px-0">
        Explore portals related to your favorite topics, create content to earn
        rewards and airdrops, and join tokenized communities.
      </p>
      <div
        class="flex flex-col items-center justify-center w-full gap-4 px-6 pb-6 mt-8 sm:flex-row"
      >
        <button
          class="w-full h-12 py-2 font-medium text-white bg-indigo-600 rounded-md sm:w-32 focus:outline-none hover:bg-indigo-500"
          @click="signup"
        >
          Get started
        </button>
        <button
          data-tf-slider="ibCBNICS"
          data-tf-opacity="100"
          data-tf-size="100"
          data-tf-iframe-props="title=Contact us"
          data-tf-transitive-search-params
          class="w-full h-12 rounded-md grey-button sm:w-32 focus:outline-none"
        >
          Contact us
        </button>
      </div>
    </div>
    <div class="pb-20 pt-36">
      <h1 class="mb-6 font-medium text-center text-gray-500 uppercase">
        Featured in
      </h1>
      <div class="flex justify-between px-8 sm:px-0 sm:block">
        <div
          class="flex flex-col items-center justify-between gap-12 sm:flex-row sm:justify-center sm:mb-8"
        >
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/techcrunch.png`, {
                quality: 100,
              })
            "
            alt="Techcrunch"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/yahoo.png`, {
                quality: 100,
              })
            "
            alt="Yahoo"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/binsider.png`, {
                quality: 100,
              })
            "
            alt="Business Insider"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/techtimes.png`, {
                quality: 100,
              })
            "
            alt="Tech Times"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/hero/seekingalpha.png`,
                { quality: 100 },
              )
            "
            alt="Seeking Alpha"
          />
        </div>
        <div
          class="flex flex-col items-center justify-between gap-12 sm:flex-row sm:justify-center"
        >
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/benzinga.png`, {
                quality: 100,
              })
            "
            alt="Benzinga"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/ap.png`, {
                quality: 100,
              })
            "
            alt="AP"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/bankless.png`, {
                quality: 100,
              })
            "
            alt="Bankless"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/hero/beincrypto.png`, {
                quality: 100,
              })
            "
            alt="BeInCrypto"
          />
          <img
            class="w-auto"
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/hero/marketwatch.png`,
                { quality: 100 },
              )
            "
            alt="MarketWatch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .title {
    max-width: 850px;
    text-align: center;
  }
  .title-header {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
  }
  .title-content {
    max-width: 592px;
    line-height: 24px;
    text-align: center;
    color: #d1d5db;
  }
  .landing-title .actions {
    margin-top: 32px;
  }
  .grey-button {
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(16px);
  }
</style>
