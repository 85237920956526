<script setup lang="ts">
  import { useNotificationStore } from '../../model/store/store';
  import { useUser } from '@/entities/user';
  import { USER_NOTIFICATIONS } from '@/common';

  const notificationStore = useNotificationStore();
  const { currentUser } = useUser();
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: USER_NOTIFICATIONS,
      params: { username: currentUser.username },
    }"
    @click="notificationStore.closeDrawer"
  >
    {{ $t('notifications.viewAllNotifications') }}
  </base-button>
</template>
