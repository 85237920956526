import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { EmbeddedGimluckRewardEditor } from '@/components/tiptap';

export const GimluckRewardNode = Node.create({
  name: 'gimluckRewardNode',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      lossMessage: {
        default: '',
      },
      points: {
        default: '0',
      },
      username: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'embedded-gimluck-reward',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['embedded-gimluck-reward', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(EmbeddedGimluckRewardEditor);
  },
});
