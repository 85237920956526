<template>
  <div
    class="flex justify-center items-center rounded-full bg-gray-900 size-10 shadow-lighter"
  >
    <div class="relative">
      <base-icon name="chain-solana" size="size-5" />
      <div
        class="flex justify-center items-center absolute -bottom-4 -right-4 rounded-full size-6 bg-indigo-950 border-2 border-white border-opacity-12"
      >
        <base-icon name="colored-loot" size="size-4" />
      </div>
    </div>
  </div>
</template>
