import { type Ref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPortalBySlugKey } from './keys';
import type { PortalView } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';

export const useGetPortalChainTypeQuery = (portalView: Ref<PortalView>) => {
  const slug = computed(() => portalView.value.slug);
  return useQuery({
    queryKey: [...getPortalBySlugKey(slug), 'chainType'],
    queryFn: async () => {
      const response = await dscvrIcApi.portal.getPortalChainType(
        portalView.value.id,
      );
      if ('Ok' in response && response.Ok.length > 0) {
        return response.Ok[0];
      }
    },
  });
};
