import { ref, type Ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { compact } from 'lodash-es';
import { useNFTGating } from '@/entities/nft-gate';
import { useUser } from '@/entities/user';
import type { SolanaMarketplaceType } from '../../types';
import { PORTAL_CONTENT } from '@/common';
import { config } from '@/shared/lib';
import { useSolanaListings } from './use-solana-listings';
import type { GeneralMarketplaceCollection } from '../../types';
import { dscvrApi } from '@/shared/api';

export function useNFTMarketplace(portal: string) {
  const selectedCollection = ref<GeneralMarketplaceCollection>();
  const {
    marketplaceMultichainCollections,
    marketplaceICPCollections,
    marketplaceNftCollections,
    entrepotCollections,
    fetchNftGatedCollections,
  } = useNFTGating();
  const { getMultichainCollectionDetails } = useSolanaListings();
  const router = useRouter();
  const route = useRoute();
  const { isLoggedIn } = useUser();

  const isLoadingDetails = ref(false);
  const hasGatedCollections = computed(() => {
    return marketplaceNftCollections.value.length > 0;
  });

  const multichainCollections: Ref<GeneralMarketplaceCollection[]> = ref([]);

  const icpCollections: Ref<
    {
      collection: dscvrApi.multichainGating.ICPGateDto;
      entrepotCollection: dscvrApi.multichainGating.EntrepotCollectionItem;
    }[]
  > = ref([]);

  const fetchCollectionsDetails = async () => {
    isLoadingDetails.value = true;
    const multichainCollectionResponses = compact(
      await Promise.all(
        marketplaceMultichainCollections.value.map(async (collection) => {
          const response = await getMultichainCollectionDetails(collection);
          // if a collection is empty on both marketplaces we don't show it
          if (
            !response?.statsMagicEden?.listedCount &&
            !response?.statsTensor?.numListed
          ) {
            return undefined;
          }
          return response;
        }),
      ),
    );

    multichainCollections.value = multichainCollectionResponses.flatMap((r) => {
      const collections = [];

      if (r?.statsTensor?.numListed) {
        collections.push({ type: 'tensor' as SolanaMarketplaceType, ...r });
      }

      // Duplicate collections to Deal with Magic Eden and Tensor
      if (r?.statsMagicEden?.listedCount) {
        collections.push({ type: 'magic-eden' as SolanaMarketplaceType, ...r });
      }

      return collections;
    });

    icpCollections.value = compact(
      marketplaceICPCollections.value.map((collection) => {
        const entrepotCollection = entrepotCollections.value.find(
          (entrepotCollection) =>
            entrepotCollection.id === collection?.canisterId,
        );
        if (!entrepotCollection) {
          return undefined;
        }
        return {
          collection,
          entrepotCollection,
        };
      }),
    );
    isLoadingDetails.value = false;
  };

  const redirectToContent = () => {
    router.push({
      name: PORTAL_CONTENT,
      params: {
        slug: portal,
      },
    });
  };

  const clearCollection = () => {
    selectedCollection.value = undefined;
    router.push({
      query: {
        name: undefined,
        marketplace: undefined,
      },
    });
  };

  const selectCollection = (collection: GeneralMarketplaceCollection) => {
    selectedCollection.value = collection;
    router.push({
      query: {
        name: collection.collection.name,
        marketplace: collection.type,
      },
    });
  };

  watch(
    () => route.fullPath,
    () => {
      if (!route.query.name) {
        selectedCollection.value = undefined;
      } else {
        selectedCollection.value = multichainCollections.value.find(
          (item) =>
            item.collection.name === route.query.name &&
            item.type === route.query.marketplace,
        );
      }
    },
  );

  onMounted(async () => {
    if (!isLoggedIn.value || !config.ENABLE_MARKETPLACE_TAB) {
      redirectToContent();
    } else {
      await fetchNftGatedCollections(portal, false);
      if (!hasGatedCollections.value) {
        redirectToContent();
        return;
      }
      await fetchCollectionsDetails();
      if (route.query.name) {
        selectedCollection.value = multichainCollections.value.find(
          (item) =>
            item.collection.name === route.query.name &&
            item.type === route.query.marketplace,
        );
      }
    }
  });

  return {
    selectedCollection,
    multichainCollections,
    icpCollections,
    isLoadingDetails,
    hasGatedCollections,
    selectCollection,
    clearCollection,
    redirectToContent,
  };
}
