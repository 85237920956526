<script lang="ts" setup>
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { Banner } from '@/shared/ui/banner';
  import { useStorage } from '@vueuse/core';

  defineProps<{
    banner: any[];
  }>();

  const disabledSolanaMobileBannerFlag = useStorage(
    'disabled-solana-mobile-banner-flag-2',
    false,
  );
</script>

<template>
  <banner
    :hide-banner="disabledSolanaMobileBannerFlag"
    variant="colored"
    size="small"
    position="relative"
    :custom-classes="{
      background: 'bg-cover',
      text: 'text-white',
      border: '',
    }"
    :style="{
      backgroundImage: `url('${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/banner/bg-solana-mobile-banner.png`,
      )}')`,
    }"
    border="rounded-xl"
    :timed="false"
    inner-height="h-full"
    outer-width="w-[95%]"
    class="mb-3"
    :show-close-button="false"
    z-index="z-1"
    @close="disabledSolanaMobileBannerFlag = true"
  >
    <template #photo>
      <div class="absolute right-2">
        <img
          :src="
            fetchMedia(banner[0].image, {
              width: 100,
              height: 100,
              fit: 'cover',
              quality: 75,
            })
          "
          class="size-25"
          alt="Image banner"
        />
      </div>
    </template>
    <template #title>
      <div class="flex flex-col w-56 text-sm font-normal">
        <span class="font-semibold">{{ banner[0].copy_first }}</span>
        <span>{{ banner[0].copy_second }}</span>
      </div>
    </template>
    <template #button>
      <base-button
        variant="custom"
        custom-classes="flex items-center justify-center w-40 ease-transition-all gap-1 py-[7px] text-sm leading-[18px] rounded-full bg-white bg-opacity-8 hover:bg-opacity-12 border border-white border-opacity-8 bg-opacity-6 backdrop-blur shadow-[0px_4px_16px_0px_rgba(0, 0, 0, 0.40)]"
        :to="banner[0].cta_link"
        target="_blank"
      >
        <span>{{ banner[0].cta_label }}</span>
      </base-button>
    </template>
  </banner>
</template>
