import { computed, reactive, watch } from 'vue';
import { hms } from '@/entities/live/model';
import {
  selectDevices,
  selectLocalMediaSettings,
} from '@100mslive/hms-video-store';
import {} from '@100mslive/vue';

interface AudioSettingsState {
  selectedLocalAudioInputDevice?: string | null;
  selectedLocalAudioOutputDevice?: string | null;
  localAudioInputDevices: MediaDeviceInfo[];
  localAudioOutputDevices: MediaDeviceInfo[];
}

const state = reactive<AudioSettingsState>({
  selectedLocalAudioInputDevice: null,
  selectedLocalAudioOutputDevice: null,
  localAudioInputDevices: [],
  localAudioOutputDevices: [],
});

export const use = () => {
  /**
   *
   */
  function getLocalAudioDevices() {
    const options = hms.hmsStore.getState(selectDevices);
    const selected = hms.hmsStore.getState(selectLocalMediaSettings);
    state.localAudioInputDevices = options.audioInput;
    state.localAudioOutputDevices = options.audioOutput;
    state.selectedLocalAudioInputDevice = selected.audioInputDeviceId;
    state.selectedLocalAudioOutputDevice = selected.audioOutputDeviceId;
    return { options, selected };
  }

  /**
   *
   * @param deviceId
   */
  function selectAudioInputDevice(deviceId: string) {
    hms.hmsActions.setAudioSettings({ deviceId });
    setTimeout(() => {
      getLocalAudioDevices();
    }, 100);
  }

  /**
   *
   * @param deviceId
   */
  function selectAudioOutputDevice(deviceId: string) {
    hms.hmsActions.setAudioOutputDevice(deviceId);
    setTimeout(() => {
      getLocalAudioDevices();
    }, 100);
  }

  const selectedLocalAudioInputDevice = computed(() => {
    return state.selectedLocalAudioInputDevice;
  });

  const selectedLocalAudioOutputDevice = computed(() => {
    return state.selectedLocalAudioOutputDevice;
  });

  const localAudioInputDevices = computed(() => {
    return state.localAudioInputDevices;
  });
  const localAudioOutputDevices = computed(() => {
    return state.localAudioOutputDevices;
  });

  return {
    selectedLocalAudioInputDevice,
    selectedLocalAudioOutputDevice,
    localAudioInputDevices,
    localAudioOutputDevices,

    getLocalAudioDevices,
    selectAudioInputDevice,
    selectAudioOutputDevice,
  };
};
