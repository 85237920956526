<script lang="ts" setup>
  import { config } from '@/shared/lib';
  import { PublicKey } from '@solana/web3.js';
  import { trackEvent } from '@/utils';

  const onClick = () => {
    const publicKey = config.JUPITER_ACCOUNT_PUBLIC_KEY;
    const platformFeeAndAccounts = publicKey
      ? {
          feeBps: config.JUPITER_FEE_BPS,
          referralAccount: new PublicKey(publicKey),
        }
      : undefined;

    window.Jupiter.init({
      endpoint: config.JUPITER_RPC_ENDPOINT,
      strictTokenList: false,
      platformFeeAndAccounts,
      onSuccess: (payload) => {
        if ('inputAddress' in payload.swapResult) {
          trackEvent(
            'jupiter_swap',
            payload.swapResult.inputAddress.toString(),
            payload.swapResult.outputAddress.toString(),
          );
        }
      },
    });
  };
</script>

<template>
  <base-button
    variant="link"
    custom-classes="flex justify-center gap-2 border border-gray-785 border-opacity-50 rounded-2xl mt-8 p-4"
    @click="onClick"
  >
    <base-icon name="jupiter-cat" size="w-[22px] h-6" />
    <span
      class="flex-none font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#00D17D] to-[#FD8] tracking-[0.14px]"
    >
      Trade with Jupiter
    </span>
  </base-button>
</template>
