<script setup lang="ts">
  import { computed, onMounted, ref, watch, type Ref } from 'vue';
  import { EXPLORE_PEOPLE_FEATURED } from '@/common';
  import { usePeopleRecommendation, UserSpotlightItem } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';

  const currentIndex: Ref<number> = ref(0);

  const { contentPreset } = useChainBehavior();
  const { spotlightUsers, fetchSpotlightUsers } =
    usePeopleRecommendation(contentPreset);

  const currentItem = computed(() => spotlightUsers.value[currentIndex.value]);
  const isDecrementDisabled = computed(() => currentIndex.value <= 0);
  const isIncrementDisabled = computed(
    () => currentIndex.value >= spotlightUsers.value.length - 1,
  );

  const decrementIndex = () => {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1;
    }
  };

  const incrementIndex = () => {
    if (currentIndex.value < spotlightUsers.value.length - 1) {
      currentIndex.value += 1;
    }
  };

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        currentIndex.value = 0;
        fetchSpotlightUsers();
      }
    },
  );

  onMounted(() => {
    currentIndex.value = 0;
    fetchSpotlightUsers();
  });
</script>

<template>
  <article class="flex flex-col gap-4 py-4">
    <header class="flex justify-between">
      <h3 class="text-gray-100 text-lg font-semibold">
        {{ $t('spotlight') }}
      </h3>
      <div class="flex">
        <base-button
          variant="link"
          :to="{ name: EXPLORE_PEOPLE_FEATURED }"
          class="mr-1.5"
        >
          <span class="text-indigo-300 font-medium">{{ $t('explore') }}</span>
        </base-button>
        <base-button
          v-if="spotlightUsers.length > 1"
          variant="custom"
          @click="decrementIndex"
          :disabled="isDecrementDisabled"
          class="mr-3"
        >
          <base-icon
            name="chevron-left"
            size="w-6 h-6"
            :class="isDecrementDisabled ? 'text-gray-400' : 'text-white'"
          />
        </base-button>
        <base-button
          v-if="spotlightUsers.length > 1"
          variant="custom"
          @click="incrementIndex"
          :disabled="isIncrementDisabled"
        >
          <base-icon
            name="chevron-right"
            size="w-6 h-6"
            :class="isIncrementDisabled ? 'text-gray-400' : 'text-white'"
          />
        </base-button>
      </div>
    </header>
    <section v-if="currentItem">
      <user-spotlight-item :user="currentItem" />
    </section>
  </article>
</template>
