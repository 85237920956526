<script setup lang="ts">
  import { computed } from 'vue';
  import type { ComputedMedia } from '@/composables/types';
  import { HorizontalSlide } from '@/components/shared/horizontal-slide';
  import MediaItem from './MediaItem.vue';
  import { SizeCapture } from '@/entities/content';

  const props = defineProps<{
    mediaList: ComputedMedia[];
  }>();

  const filteredMediaList = computed(() =>
    props.mediaList.filter(({ src }) => src && src !== 'undefined'),
  );
  const onlyOne = computed(() => filteredMediaList.value.length === 1);
</script>

<template>
  <div>
    <size-capture
      v-if="onlyOne"
      class="flex justify-center items-center"
      type="summary-media"
      :id="filteredMediaList[0].src"
      v-slot="{ contentReady }"
    >
      <media-item :media="filteredMediaList[0]" single @loaded="contentReady" />
    </size-capture>
    <horizontal-slide
      v-else-if="filteredMediaList.length > 0"
      centered
      :count="filteredMediaList.length"
      class="h-[280px] sm:h-[326px] lg:h-[434px] xl:h-[500px]"
      item-width-class="squared xl:w-[700px]"
    >
      <template
        v-for="(media, index) in filteredMediaList"
        :key="`item-${index + 1}`"
        #[`item-${index+1}`]
      >
        <media-item :media="media" />
      </template>
    </horizontal-slide>
  </div>
</template>
