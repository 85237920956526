/**
 * Singleton. Registers promises as they are fired. When a promise is completed you should check in the .then function
 * using the isPromiseLatest() function and do state modifications only if it is the latest promise
 */
class CancellablePromise {
  constructor() {
    if (CancellablePromise._instance) {
      return CancellablePromise._instance;
    }
    this.promises = [];
    CancellablePromise._instance = this;
  }
  registerPromise(key, promise) {
    this.promises[key] = promise;
  }
  isPromiseLatest(key, currentPromise) {
    return this.promises[key] === currentPromise;
  }
}
export const cancellablePromise = new CancellablePromise();
