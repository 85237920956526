<script lang="ts" setup>
  import { defineAsyncComponent, ref } from 'vue';
  import { useStorage } from '@vueuse/core';
  import { Banner } from '@/shared/ui/banner';
  import { useDialog } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  const disabledPwaBlockFlag = useStorage('disabled-pwa-block-flag', false);

  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const pwaDialog = () =>
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () => import('./components/DialogContent.vue'),
        ),
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/ImageHeader.vue'),
        ),
        props: {
          src: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/pwa/pwa-dialog-iphone.png`,
          ),
          alt: '',
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] min-h-[753px]`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
</script>

<template>
  <banner
    :hide-banner="disabledPwaBlockFlag"
    show-close-button
    position="relative"
    variant="colored"
    :timed="false"
    size="medium"
    inner-height="h-full"
    outer-width="w-full"
    :custom-classes="{
      background: 'bg-cover',
      text: 'text-white',
      border: '',
    }"
    :style="{
      backgroundImage: `url('${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/pwa/bg-pwa-mobile-banner.png`,
      )}')`,
    }"
    z-index="z-1"
    @close="disabledPwaBlockFlag = true"
  >
    <template #photo>
      <div class="absolute w-[162px] h-[162px] right-6 top-5">
        <img
          :src="
            fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/pwa/pwa-block-iphone-banner.png`,
            )
          "
          alt="PWA iphone"
        />
      </div>
    </template>
    <template #title>
      The wait is over <br />
      Get it on your phone!
    </template>
    <template #button>
      <base-button
        variant="white"
        size="x-small"
        rounded="rounded-full"
        class="mt-2"
        @click="pwaDialog"
      >
        Install now
      </base-button>
    </template>
  </banner>
</template>
