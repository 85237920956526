import { computed } from 'vue';
import { uniqueId } from 'lodash-es';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import type { ToastMessage } from '@/shared/ui/toast';

export const useToast = () => {
  const store = useStore();
  const showToast = (toast: Partial<ToastMessage>) => {
    const iconName =
      toast.type === 'success'
        ? 'filled-check-circle'
        : toast.type === 'info'
        ? 'filled-info'
        : toast.type === 'warning'
        ? 'filled-exclamation-triangle'
        : 'exclamation-circle-filled';

    store.dispatch(ActionTypes.ADD_TOAST, {
      id: uniqueId(),
      iconName,
      ...toast,
    });
  };

  const removeToast = (toast: ToastMessage) => {
    store.dispatch(ActionTypes.REMOVE_TOAST, toast);
  };

  const toasts = computed(() => store.getters.toasts);

  return {
    showToast,
    removeToast,
    toasts,
  };
};
