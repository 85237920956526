import { computed } from 'vue';
import { useDialog } from '@/shared/model';
import AwardSelectionDialogContent from '../../components/awards-selection-dialog/Content.vue';
import AwardSelectionDialogHeader from '../../components/awards-selection-dialog/Header.vue';
import PurchaseDialogContent from '../../components/purchase-dialog/Content.vue';
import PurchaseDialogHeader from '../../components/purchase-dialog/Header.vue';
import SuccessfulPaymentDialogContent from '../../components/successful-payment-dialog/Content.vue';
import { TitleHeader } from '@/shared/ui/base-dialog';
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from '@/shared/model';
import { storeToRefs } from 'pinia';
import { useConfirmationDialog } from '@/shared/ui/confirmation';
import type { ContentView } from 'dfx/edge/edge.did';

export const useIgc = (contentView?: ContentView) => {
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);
  const { openConfiguredDialog, closeDialog, dialogDefaults } = useDialog();
  const { openConfirmationDialog } = useConfirmationDialog();
  const { t } = useI18n({ useScope: 'global' });

  const sparksCount = computed(() =>
    globalLiveData.value ? Number(globalLiveData.value.igc_holdings) : 0,
  );

  const openConfiguredAwardsSelectionDialog = () => {
    openConfiguredDialog({
      content: {
        component: AwardSelectionDialogContent,
        props: {
          contentView,
        },
      },
      header: {
        component: AwardSelectionDialogHeader,
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} relative w-full max-w-[975px] p-8 streak-modal overflow-y-auto thin-scrollbar scrollbar-transparent`,
      },
      drawer: {
        customClasses:
          'w-full px-2 pt-2 pb-10 border border-gray-785 border-opacity-50 rounded-t-2xl streak-modal',
      },
    });
  };

  const openConfiguredPurchaseDialog = (showBackButton?: boolean) => {
    openConfiguredDialog({
      content: {
        component: PurchaseDialogContent,
      },
      header: {
        component: PurchaseDialogHeader,
        props: {
          contentView,
          showBackButton,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} relative flex flex-col max-w-[570px] pb-10`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openConfiguredSuccessfulPaymentDialog = () => {
    openConfiguredDialog({
      content: {
        component: SuccessfulPaymentDialogContent,
      },
      header: {
        component: TitleHeader,
        props: {
          title: t('igc.successfulPaymentDialog.title'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} relative flex flex-col max-w-[570px] pb-10`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openConfiguredFailurePaymentDialog = () => {
    openConfirmationDialog({
      headerTitle: t('igc.failurePaymentDialog.title'),
      contentSlots: {
        default: {
          template: `<div class="text-lg">${t(
            'igc.failurePaymentDialog.description',
          )}</div>`,
        },
        submitLabel: { template: t('igc.failurePaymentDialog.retry') },
      },
      submit: openConfiguredPurchaseDialog,
    });
  };

  return {
    openConfiguredAwardsSelectionDialog,
    openConfiguredPurchaseDialog,
    openConfiguredSuccessfulPaymentDialog,
    openConfiguredFailurePaymentDialog,
    sparksCount,
  };
};
