import { dscvrApi } from '@/shared/api';
import {
  LootboxDialogContentItemType,
  type CampaignMeta,
  type LootboxDialogContentItem,
} from '../types';

export const buildMetaObject = (
  lootboxItem: dscvrApi.lootbox.ILootboxItem,
): CampaignMeta | null => {
  if (!lootboxItem.campaign) {
    return null;
  }
  try {
    const metaData = JSON.parse(lootboxItem.campaign.meta);
    return metaData as CampaignMeta;
  } catch (e) {
    console.error('Failed to parse campaign meta', e);
  }
  return null;
};

export const buildLootboxCampaign = (
  lootbox: dscvrApi.lootbox.ILootbox,
): LootboxDialogContentItem[] => {
  const lootboxPages: LootboxDialogContentItem[] = [];

  for (let i = 0; i < lootbox.items.length; i++) {
    lootboxPages.push({
      type: LootboxDialogContentItemType.Lootbox,
      item: lootbox.items[i],
    });

    const meta = buildMetaObject(lootbox.items[i]);
    if (meta) {
      meta.pages.forEach((page) => {
        lootboxPages.push({
          type: LootboxDialogContentItemType.CampaignMetaPage,
          item: page,
        });
      });
    }
  }

  return lootboxPages;
};

const getLootboxItemClaimedDate = (item: dscvrApi.lootbox.ILootboxItem) => {
  return item.openedAt ? new Date(item.openedAt).getTime() : -1;
};

export const sortClaimedItemsByDateDesc = (
  items: dscvrApi.lootbox.ILootboxItem[],
) => {
  return [...items].sort(
    (a, b) => getLootboxItemClaimedDate(b) - getLootboxItemClaimedDate(a),
  );
};
