<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { isEqual } from 'lodash-es';
  import { useIgc } from '../../model/composables/use-igc';
  import { contentKey, useToast } from '@/shared/model';
  import { useGetPurchasableAwardsQuery } from '../../api/use-get-purchasable-awards.query';
  import { useAwardPostMutation } from '../../api/use-award-post.mutation';
  import type { AwardView, ContentView } from 'dfx/edge/edge.did';
  import { useQueryClient } from '@tanstack/vue-query';
  import { fetchMedia } from '@/shared/lib';

  const props = defineProps<{
    contentView: ContentView;
  }>();

  const postAwardViews = computed(() => props.contentView.post_awards);
  const queryClient = useQueryClient();
  const { data: purchasableAwards, isLoading } = useGetPurchasableAwardsQuery();
  const { mutate: awardPostMutation } = useAwardPostMutation();
  const { showToast } = useToast();
  const { openConfiguredPurchaseDialog, sparksCount } = useIgc(
    props.contentView,
  );
  const { t } = useI18n({
    useScope: 'global',
  });

  const selectedAward = ref<AwardView | null>(null);

  const onGiveAward = () => {
    if (!selectedAward.value) {
      showToast({
        title: t('igc.awardDialog.errorAwarding'),
        type: 'error',
        durationSeconds: 3,
      });
      return;
    }
    if (
      sparksCount.value <= 0 ||
      (selectedAward.value && sparksCount.value < selectedAward.value.cost)
    ) {
      openConfiguredPurchaseDialog(true);
    } else {
      awardPostMutation(
        {
          contentId: props.contentView.id,
          awardId: selectedAward.value.id,
        },
        {
          onSuccess: (data) => {
            if ('Ok' in data) {
              queryClient.invalidateQueries({
                queryKey: [contentKey],
              });
              showToast({
                title: t('igc.awardDialog.successAwardingPost'),
                type: 'success',
                durationSeconds: 3,
              });
            } else {
              showToast({
                title: t('igc.awardDialog.errorAwardingPost'),
                type: 'error',
                durationSeconds: 3,
              });
            }
          },
        },
      );
    }
  };
</script>

<template>
  <div class="flex flex-col gap-4 max-w-[925px]">
    <div class="text-2xl font-bold tracking-[-0.12px]">
      {{ t('igc.awardDialog.topTitle') }}
    </div>
    <div v-if="postAwardViews.length" class="flex gap-8 flex-wrap w-full">
      <template
        v-for="postAwardView in postAwardViews"
        :key="postAwardView.award_view.id.toString()"
      >
        <div class="relative">
          <img
            :src="fetchMedia(postAwardView.award_view.icon_url)"
            class="size-26"
            alt="award"
          />
          <div
            class="absolute bottom-0 -right-4 h-8 flex items-end text-lg font-medium"
          >
            <base-icon
              name="close"
              size="size-3"
              class="text-gray-300 h-full"
            />
            <span class="h-full text-gray-300">
              {{ postAwardView.count }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="flex justify-center w-full text-xl">
      {{ t('igc.awardDialog.noAwards') }}
    </div>
    <div class="h-px w-full bg-gray-785 bg-opacity-50" />
    <div class="flex flex-col gap-7">
      <div class="text-2xl font-bold tracking-[-0.12px]">
        {{ t('igc.awardDialog.bottomTitle') }}
      </div>
      <div
        v-if="!isLoading"
        class="flex justify-center items-center gap-4 flex-wrap"
      >
        <base-button
          v-for="purchasableAward in purchasableAwards"
          :key="purchasableAward.id.toString()"
          variant="custom"
          custom-classes="relative flex justify-center items-center rounded-xl border border-gray-785 border-opacity-50 size-[150px]"
          @click="selectedAward = purchasableAward"
        >
          <div
            v-if="isEqual(selectedAward, purchasableAward)"
            class="glowing-effect-orange z-1"
          />
          <div
            class="relative flex flex-col items-center justify-center gap-1 bg-gray-975 rounded-xl z-2 size-full p-5"
          >
            <img
              :src="fetchMedia(purchasableAward.icon_url)"
              alt="award"
              class="size-full"
            />
            <div class="flex items-center gap-1">
              <base-icon name="sparks" size="size-5" />
              <span class="font-medium">
                {{ purchasableAward.cost }}
              </span>
            </div>
          </div>
        </base-button>
      </div>
    </div>
    <div class="self-center pt-6">
      <base-button variant="primary" size="medium" @click="onGiveAward">
        {{ t('igc.awardDialog.button') }}
      </base-button>
    </div>
  </div>
</template>
