<script setup lang="ts">
  import { computed } from 'vue';
  import { useBreakpoint } from '@/shared/model';
  import {
    GridItemPortrait,
    useGetRandomizedRecommendedPortalsQuery,
  } from '@/entities/portal';
  import { useChainBehavior } from '@/entities/user-setting';
  import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';

  const props = withDefaults(
    defineProps<{
      count?: number;
    }>(),
    {
      count: 3,
    },
  );

  const { contentPreset } = useChainBehavior();
  const { isSmallerThanSm } = useBreakpoint();

  const queryParams = computed<RandomizedRecommendationQuery>(() => ({
    filter_tags: [['feed-recs']],
    count: props.count,
    content_preset: contentPreset.value,
  }));

  const { data: portalFeedRecommendations } =
    useGetRandomizedRecommendedPortalsQuery(queryParams);

  const list = computed(() => {
    return isSmallerThanSm.value
      ? portalFeedRecommendations.value?.slice(0, 2)
      : portalFeedRecommendations.value;
  });
</script>

<template>
  <ul class="grid grid-cols-2 sm:grid-cols-3 gap-4 py-0 mt-4">
    <li v-for="portal in list" :key="portal.slug">
      <grid-item-portrait :portal-view="portal" hide-label />
    </li>
  </ul>
</template>
