import axios from 'axios';
import { config, fetchMedia } from '@/shared/lib';

const nuclear = {
  0: 'gejyc-aakor-uwiaa-aaaaa-deaet-qaqca-aaaaa-a',
  1: 'iykld-fqkor-uwiaa-aaaaa-deaet-qaqca-aaaaa-q',
  2: '34o6a-lakor-uwiaa-aaaaa-deaet-qaqca-aaaab-a',
  3: 'vannb-oqkor-uwiaa-aaaaa-deaet-qaqca-aaaab-q',
  4: 'gz7ek-gikor-uwiaa-aaaaa-deaet-qaqca-aaaac-a',
  5: 'if4xl-dykor-uwiaa-aaaaa-deaet-qaqca-aaaac-q',
  6: '3byci-nikor-uwiaa-aaaaa-deaet-qaqca-aaaad-a',
  7: 'v53rj-iykor-uwiaa-aaaaa-deaet-qaqca-aaaad-q',
  8: 'h7eas-mqkor-uwiaa-aaaaa-deaet-qaqca-aaaae-a',
  9: 'jdhtt-jakor-uwiaa-aaaaa-deaet-qaqca-aaaae-q',
  10: '2hdgq-hqkor-uwiaa-aaaaa-deaet-qaqca-aaaaf-a',
  11: 'u3avr-cakor-uwiaa-aaaaa-deaet-qaqca-aaaaf-q',
  12: 'hcs42-kykor-uwiaa-aaaaa-deaet-qaqca-aaaag-a',
  13: 'j6rp3-pikor-uwiaa-aaaaa-deaet-qaqca-aaaag-q',
  14: '22v2y-bykor-uwiaa-aaaaa-deaet-qaqca-aaaah-a',
  15: 'ugwjz-eikor-uwiaa-aaaaa-deaet-qaqca-aaaah-q',
  16: 'fssjc-zakor-uwiaa-aaaaa-deaet-qaqca-aaaai-a',
  17: 'lor2d-4qkor-uwiaa-aaaaa-deaet-qaqca-aaaai-q',
  18: 'ykvpa-sakor-uwiaa-aaaaa-deaet-qaqca-aaaaj-a',
  19: 'www4b-xqkor-uwiaa-aaaaa-deaet-qaqca-aaaaj-q',
  20: 'fpevk-7ikor-uwiaa-aaaaa-deaet-qaqca-aaaak-a',
};

export const isNuclearNFT = (url) => {
  if (!url) return false;
  return (
    url.includes('5vssd-yaaaa-aaaam-qasoq-cai') ||
    url.includes('5stux-vyaaa-aaaam-qasoa-cai')
  );
};

const getNuclearThumbnailUrl = (url) => {
  const uri = new URL(url);
  uri.searchParams.set('type', 'thumbnail');
  const subdomain = uri.host.split('.')[0];

  uri.searchParams.set('tokenid', nuclear[uri.searchParams.get('index')]);
  uri.host = uri.host.replace(subdomain, '5stux-vyaaa-aaaam-qasoa-cai');
  uri.searchParams.delete('index');
  return uri.href;
};

// just a local variable to know if an ajax is fired once to not fire again
let extNftCanistersAjaxExecuting = false;
const isGenericSVG = (url) => {
  // config - sync interval for the list of EXT NFT Canisters
  const extNftCanistersSyncInterval = 60 * 60 * 1 * 1000; // 1 hour in milliseconds

  // check when was the last time we have synced the list with EXT NFT Canisters
  const extNftCanistersSyncedDate = localStorage.getItem(
    'ext_nft_canisters_synced_date',
  );

  // if nothing is synced at all or too much time has passed from the last sync - sync the list
  if (
    extNftCanistersSyncedDate === null ||
    Date.now() - extNftCanistersSyncedDate > extNftCanistersSyncInterval
  ) {
    // check if am ajax query is already executing
    if (!extNftCanistersAjaxExecuting) {
      extNftCanistersAjaxExecuting = true;

      // fetch the list
      axios
        .get(`${config.DSCVR_API_BASE}/api/token/list`)
        .then((response) => {
          // set the last sync time to the current time and save the list into local storage
          const ext_tokens = response.data.data.filter(
            (item) => item.standard == 'ext',
          );
          localStorage.setItem('ext_nft_canisters', JSON.stringify(ext_tokens));
          localStorage.setItem('ext_nft_canisters_synced_date', Date.now());
          extNftCanistersAjaxExecuting = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }

  // check if there is a local storage list with EXT NFT canisters
  let localStorageExtNftCanistersList =
    localStorage.getItem('ext_nft_canisters');
  if (localStorageExtNftCanistersList !== null) {
    // if there is, compare the URL to that list and return
    localStorageExtNftCanistersList = JSON.parse(
      localStorage.getItem('ext_nft_canisters'),
    );
    if (localStorageExtNftCanistersList.length > 0) {
      const findResults =
        localStorageExtNftCanistersList.filter((e) =>
          url.includes(e.canisterId),
        ).length > 0;

      if (findResults) {
        return true;
      }
    }
  }

  // if there is no local storage list, compare to a backup list of canisters and return
  //console.log("@@@@@@@@@@@@@@@@@ fetched from backup list"); // TODO: delete print once sure everything is working
  return (
    url.includes('ugdkf-taaaa-aaaak-acoia-cai') ||
    url.includes('736xk-wqaaa-aaaam-qazta-cai') ||
    url.includes('cxlb5-5qaaa-aaaam-qaxya-cai') ||
    url.includes('sobtd-xiaaa-aaaam-qay3q-cai') ||
    url.includes('ndnrn-yaaaa-aaaam-qaw5a-cai') ||
    url.includes('52hdt-syaaa-aaaam-qaz6q-cai') ||
    url.includes('6wqte-zyaaa-aaaam-qazuq-cai') ||
    url.includes('24ark-haaaa-aaaam-qazoa-cai') ||
    url.includes('arttq-uaaaa-aaaam-qaxva-cai') ||
    url.includes('cchps-gaaaa-aaaak-qasaa-cai') ||
    url.includes('v2awd-oaaaa-aaaam-qayia-cai') ||
    url.includes('4mm3y-hiaaa-aaaam-qaz3q-cai') ||
    url.includes('z6vmv-xqaaa-aaaam-qazfa-cai') ||
    url.includes('fpimp-wyaaa-aaaam-qa3gq-cai') ||
    url.includes('hfswq-niaaa-aaaam-qaxgq-cai') ||
    url.includes('3rove-iiaaa-aaaam-qazjq-cai') ||
    url.includes('nwkaa-ziaaa-aaaam-qaw6q-cai') ||
    url.includes('odyji-baaaa-aaaam-qa22a-cai') ||
    url.includes('l2cqd-oaaaa-aaaam-qawja-cai') ||
    url.includes('oy5vn-3yaaa-aaaam-qa2yq-cai') ||
    url.includes('p4q27-cyaaa-aaaam-qa26q-cai') ||
    url.includes('jh76l-5iaaa-aaaam-qawgq-cai') ||
    url.includes('2mgdj-caaaa-aaaam-qavba-cai') ||
    url.includes('3ilm3-3aaaa-aaaam-qavha-cai') ||
    url.includes('qkzeu-xiaaa-aaaam-qau2q-cai') ||
    url.includes('3toq6-byaaa-aaaam-qavfq-cai') ||
    url.includes('2vd2w-riaaa-aaaam-qazpq-cai') ||
    url.includes('itud2-biaaa-aaaam-qa2pq-cai') ||
    url.includes('m6fha-siaaa-aaaam-qa2vq-cai') ||
    url.includes('hqvh5-maaaa-aaaam-qaxfa-cai') ||
    url.includes('shcy7-baaaa-aaaam-qay2a-cai') ||
    url.includes('vigb2-cqaaa-aaaam-qayla-cai') ||
    url.includes('k4p2l-6qaaa-aaaam-qa2da-cai') ||
    url.includes('legpi-qqaaa-aaaam-qa2ha-cai') ||
    url.includes('rxrsz-5aaaa-aaaam-qaysa-cai') ||
    url.includes('kojns-saaaa-aaaam-qa2aa-cai') ||
    url.includes('mfa3f-iqaaa-aaaam-qa2xa-cai') ||
    url.includes('rmuo4-hyaaa-aaaam-qayqq-cai') ||
    url.includes('i2xig-xaaaa-aaaam-qa2oa-cai') ||
    url.includes('zot6w-sqaaa-aaaam-qavka-cai') ||
    url.includes('eagn3-qaaaa-aaaam-qaxna-cai') ||
    url.includes('yrdz3-2yaaa-aaaah-qcvpa-cai') ||
    url.includes('gtb2b-tiaaa-aaaah-qcxca-cai') ||
    url.includes('jntyp-yiaaa-aaaah-qcr3q-cai') ||
    url.includes('tde7l-3qaaa-aaaah-qansa-cai') ||
    url.includes('5h2fc-zaaaa-aaaah-qcnjq-cai') ||
    url.includes('sbcwr-3qaaa-aaaam-qamoa-cai') ||
    url.includes('crt3j-mqaaa-aaaah-qcdnq-cai') ||
    url.includes('iohft-haaaa-aaaam-qar4q-cai') ||
    url.includes('skjpp-haaaa-aaaae-qac7q-cai') ||
    url.includes('kt2l3-uiaaa-aaaam-qarta-cai') ||
    url.includes('zvycl-fyaaa-aaaah-qckmq-cai') ||
    url.includes('j3c2x-ciaaa-aaaak-aanxa-cai') ||
    url.includes('2l7rh-eiaaa-aaaah-qcvaa-cai') ||
    url.includes('6wghg-7yaaa-aaaam-qagra-cai') ||
    url.includes('nbg4r-saaaa-aaaah-qap7a-cai') ||
    url.includes('bapzn-kiaaa-aaaam-qaiva-cai') ||
    url.includes('rw623-hyaaa-aaaah-qctcq-cai') ||
    url.includes('3bqt5-gyaaa-aaaah-qcvha-cai') ||
    url.includes('jeghr-iaaaa-aaaah-qco7q-cai') ||
    url.includes('rr6wy-jqaaa-aaaak-aaiqa-cai') ||
    url.includes('6km5p-fiaaa-aaaah-qczxa-cai') ||
    url.includes('pnpu4-3aaaa-aaaah-qcceq-cai') ||
    url.includes('3db6u-aiaaa-aaaah-qbjbq-cai') ||
    url.includes('dv6u3-vqaaa-aaaah-qcdlq-cai') ||
    url.includes('cnxby-3qaaa-aaaah-qcdpq-cai') ||
    url.includes('2glp2-eqaaa-aaaak-aajoa-cai') ||
    url.includes('oeee4-qaaaa-aaaak-qaaeq-cai') ||
    url.includes('6kk5l-syaaa-aaaaj-qau2q-cai') ||
    url.includes('unssi-hiaaa-aaaah-qcmya-cai') ||
    url.includes('poyn6-dyaaa-aaaah-qcfzq-cai') ||
    url.includes('bxdf4-baaaa-aaaah-qaruq-cai') ||
    url.includes('46sy3-aiaaa-aaaah-qczza-cai') ||
    url.includes('owuqd-dyaaa-aaaah-qapxq-cai') ||
    url.includes('dknxi-2iaaa-aaaah-qceuq-cai') ||
    url.includes('jjeno-oyaaa-aaaak-aanua-cai') ||
    url.includes('uvxj2-ayaaa-aaaak-aapqa-cai') ||
    url.includes('4mupc-myaaa-aaaah-qcz2a-cai') ||
    url.includes('gevsk-tqaaa-aaaah-qaoca-cai') ||
    url.includes('llt6y-2iaaa-aaaam-qarxa-cai') ||
    url.includes('jqa6g-6aaaa-aaaah-qc2iq-cai') ||
    url.includes('zqpvh-hqaaa-aaaah-qczfq-cai') ||
    url.includes('tco7x-piaaa-aaaam-qamiq-cai') ||
    url.includes('lxmol-ciaaa-aaaak-abpdq-cai') ||
    url.includes('eejma-naaaa-aaaak-aalda-cai') ||
    url.includes('aseks-myaaa-aaaam-qaqia-cai') ||
    url.includes('4gbxl-byaaa-aaaak-aafuq-cai') ||
    url.includes('cdvmq-aaaaa-aaaah-qcdoq-cai') ||
    url.includes('6tjeq-waaaa-aaaah-qcvzq-cai') ||
    url.includes('3mttv-dqaaa-aaaah-qcn6q-cai') ||
    url.includes('zhibq-piaaa-aaaah-qcvka-cai') ||
    url.includes('ehr7n-3yaaa-aaaam-qaiia-cai') ||
    url.includes('4odun-ayaaa-aaaak-aarbq-cai') ||
    url.includes('bzsui-sqaaa-aaaah-qce2a-cai') ||
    url.includes('tx72y-iaaaa-aaaam-qatoq-cai') ||
    url.includes('p5jg7-6aaaa-aaaah-qcolq-cai') ||
    url.includes('t2eok-gyaaa-aaaah-qclwq-cai') ||
    url.includes('n6bj5-ryaaa-aaaan-qaaqq-cai') ||
    url.includes('eb7r3-myaaa-aaaah-qcdya-cai') ||
    url.includes('rw7qm-eiaaa-aaaak-aaiqq-cai') ||
    url.includes('4fxe6-2qaaa-aaaah-qcz3q-cai') ||
    url.includes('dhiaa-ryaaa-aaaae-qabva-cai') ||
    url.includes('2s2iy-xaaaa-aaaah-qczoq-cai') ||
    url.includes('ri5pt-5iaaa-aaaan-qactq-cai') ||
    url.includes('eosur-nqaaa-aaaam-qaijq-cai') ||
    url.includes('dcbuw-wyaaa-aaaam-qapfq-cai') ||
    url.includes('v3b6z-ziaaa-aaaam-qam4q-cai') ||
    url.includes('po6n2-uiaaa-aaaaj-qaiua-cai') ||
    url.includes('dhyds-jaaaa-aaaao-aaiia-cai') ||
    url.includes('nges7-giaaa-aaaaj-qaiya-cai') ||
    url.includes('xphpx-xyaaa-aaaah-qcmta-cai') ||
    url.includes('qjyju-jqaaa-aaaam-qamca-cai') ||
    url.includes('6wih6-siaaa-aaaah-qczva-cai') ||
    url.includes('2iiif-4qaaa-aaaah-qcs5a-cai') ||
    url.includes('px5ub-qqaaa-aaaah-qcjxa-cai') ||
    url.includes('q6hjz-kyaaa-aaaah-qcama-cai') ||
    url.includes('gikg4-eaaaa-aaaam-qaieq-cai') ||
    url.includes('t64re-6iaaa-aaaam-qatpa-cai') ||
    url.includes('s36wu-5qaaa-aaaah-qcyzq-cai') ||
    url.includes('zzk67-giaaa-aaaaj-qaujq-cai') ||
    url.includes('y5prr-fiaaa-aaaam-qagga-cai') ||
    url.includes('4zv6l-2aaaa-aaaaj-qauua-cai') ||
    url.includes('zejmq-rqaaa-aaaah-qcnsq-cai') ||
    url.includes('k4qsa-4aaaa-aaaah-qbvnq-cai') ||
    url.includes('4wiph-kyaaa-aaaam-qannq-cai') ||
    url.includes('3cjkh-tqaaa-aaaam-qan6a-cai') ||
    url.includes('vvvht-eyaaa-aaaam-qatya-cai') ||
    url.includes('jkkkb-6aaaa-aaaam-qar2q-cai') ||
    url.includes('r75rh-rqaaa-aaaah-qctda-cai') ||
    url.includes('lcgbg-kaaaa-aaaam-qaota-cai') ||
    url.includes('ctt6t-faaaa-aaaah-qcpbq-cai') ||
    url.includes('i5a2q-4yaaa-aaaam-qafla-cai') ||
    url.includes('7sfim-liaaa-aaaah-qczta-cai') ||
    url.includes('5stux-vyaaa-aaaam-qasoa-cai') ||
    url.includes('qbc6i-daaaa-aaaah-qcywq-cai') ||
    url.includes('5t24r-yqaaa-aaaaj-qauta-cai') ||
    url.includes('6eaq7-tiaaa-aaaam-qagsa-cai') ||
    url.includes('btggw-4aaaa-aaaah-qcdgq-cai') ||
    url.includes('qjwjm-eaaaa-aaaah-qctga-cai') ||
    url.includes('6imyv-myaaa-aaaah-qcv3a-cai') ||
    url.includes('erpx2-pyaaa-aaaah-qcqsq-cai') ||
    url.includes('r3t2l-paaaa-aaaam-qau7a-cai') ||
    url.includes('5pyge-yaaaa-aaaah-qcz4q-cai') ||
    url.includes('wlea5-diaaa-aaaam-qatra-cai') ||
    url.includes('dylar-wyaaa-aaaah-qcexq-cai') ||
    url.includes('t2mog-myaaa-aaaal-aas7q-cai') ||
    url.includes('ahl3d-xqaaa-aaaaj-qacca-cai') ||
    url.includes('xcep7-sqaaa-aaaah-qcukq-cai') ||
    url.includes('tgwaz-xyaaa-aaaah-qcura-cai') ||
    url.includes('4fcza-biaaa-aaaah-abi4q-cai') ||
    url.includes('j3dqa-byaaa-aaaah-qcwfa-cai') ||
    url.includes('ircsc-oyaaa-aaaam-qajga-cai') ||
    url.includes('tx72y-iaaaa-aaaam-qatoq-cai') ||
    url.includes('skjpp-haaaa-aaaae-qac7q-cai') ||
    url.includes('zvycl-fyaaa-aaaah-qckmq-cai') ||
    url.includes('xzxhy-oiaaa-aaaah-qclnq-cai') ||
    url.includes('unssi-hiaaa-aaaah-qcmya-cai') ||
    url.includes('bzsui-sqaaa-aaaah-qce2a-cai') ||
    url.includes('ugdkf-taaaa-aaaak-acoia-cai') ||
    url.includes('cxlb5-5qaaa-aaaam-qaxya-cai') ||
    url.includes('shcy7-baaaa-aaaam-qay2a-cai') ||
    url.includes('rxrsz-5aaaa-aaaam-qaysa-cai') ||
    url.includes('3rove-iiaaa-aaaam-qazjq-cai') ||
    url.includes('k4p2l-6qaaa-aaaam-qa2da-cai') ||
    url.includes('xgket-maaaa-aaaam-qatwq-cai') ||
    url.includes('xzxhy-oiaaa-aaaah-qclnq-cai') ||
    url.includes('ce2k4-aiaaa-aaaam-qa4ka-cai') ||
    url.includes('xdlh5-iiaaa-aaaam-qa72a-cai')
  );
};

export const getThumbnailedUrl = (url) => {
  const uri = new URL(url);
  uri.searchParams.set('type', 'thumbnail');
  return uri.href;
};

const getICWhalesThumbnailedUrl = (url) => {
  const uri = new URL(url);
  return `https://2tvxo-eqaaa-aaaai-acjla-cai.raw.ic0.app/?&type=thumbnail&tokenid=${uri.searchParams.get(
    'tokenid',
  )}`;
};

export const getAvatarUrlWithoutCache = (url) => {
  return getAvatarUrl(url, 0, false);
};

export const getAvatarUrl = (url, size = 128, cache = true) => {
  const width = size > 0 ? { width: size } : '';
  if (url && isGenericSVG(url)) {
    if (isNuclearNFT(url)) {
      return fetchMedia(getNuclearThumbnailUrl(url));
    }
    if (cache) {
      return fetchMedia(getThumbnailedUrl(url), { quality: 75, width });
    } else {
      return fetchMedia(getThumbnailedUrl(url));
    }
  }
  if (url.includes('2tvxo-eqaaa-aaaai-acjla-cai')) {
    return fetchMedia(getICWhalesThumbnailedUrl(url));
  }
  if (cache && url.includes('e4cao-6iaaa-aaaab-qah2q-cai')) {
    return fetchMedia(url, { quality: 75, width });
  }

  return fetchMedia(url);
};

export const parseNFTUrl = (nftUrl) => {
  try {
    const url = new URL(nftUrl);
    const nft = {
      nft_id: '',
      canister_id: url.host.split('.')[0],
    };

    if (nft.canister_id == 's6hwe-laaaa-aaaab-qaeba-cai') {
      nft.nft_id = url.pathname.replace('/', '');
      return nft;
    }

    //ext
    if (url.searchParams.get('tokenid')) {
      nft.nft_id = url.searchParams.get('tokenid');
    } else if (url.searchParams.get('index')) {
      nft.nft_id = url.searchParams.get('index');
    }

    if (!nft.nft_id) {
      const urlPaths = url.pathname.split('/');
      //hazel
      if (urlPaths[1] == 'nft') {
        nft.nft_id = parseInt(urlPaths[2]).toString();
      }
      //Punk
      else if (urlPaths[1].toLocaleLowerCase() == 'token') {
        nft.nft_id = parseInt(urlPaths[2]).toString();
      }
      //nft.nft_id = urlPaths[1];
    }

    if (!nft.nft_id || !nft.canister_id) {
      return null;
    } else {
      return nft;
    }
  } catch (error) {
    return null;
  }
};
