<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  const props = withDefaults(
    defineProps<{
      context: string;
      showLength?: number;
    }>(),
    {
      context: '',
      showLength: 100,
    },
  );

  const { t } = useI18n({ useScope: 'global' });

  const showLess = ref(true);

  const text = computed(() => {
    return showLess.value
      ? props.context.slice(0, props.showLength)
      : props.context;
  });
  const activateReadMore = () => {
    showLess.value = false;
  };
  const checkShowLess = () => {
    showLess.value = props.context.length > props.showLength;
  };

  watch(() => props.context, checkShowLess, { immediate: true });
</script>

<template>
  <p class="text-sm text-gray-300">
    {{ text }}

    <a
      v-if="showLess"
      class="font-bold text-indigo-300"
      @click="activateReadMore"
    >
      {{ t('readMore') }}...
    </a>
  </p>
</template>
