<script lang="ts" setup>
  import { computed } from 'vue';
  import type { TranslatedContentView } from '@/shared/model';
  import { usePost } from '@/entities/post';

  const props = defineProps<{
    content: TranslatedContentView;
  }>();

  const { isPostDetailPage } = usePost();

  const title = computed(() =>
    props.content.translatedTitle
      ? props.content.translatedTitle
      : props.content.title,
  );
</script>

<template>
  <div class="flex flex-wrap items-baseline mb-2">
    <component
      :is="isPostDetailPage ? 'h1' : 'h2'"
      class="px-4 mt-2 font-extrabold break-words cursor-pointer lg:px-0"
      :class="isPostDetailPage ? 'text-xl' : 'text-lg'"
      :title="title"
    >
      {{ title }}
    </component>
  </div>
</template>
