import { fromNow as momentFromNow } from '@/shared/lib';

export function fromNow(val: bigint, shortHand?: boolean) {
  return momentFromNow(Number(String(val).slice(0, 13)), shortHand);
}

export const trackSeconds = () => {
  let seconds = 0;
  let intervalId: ReturnType<typeof setInterval>;

  const startTracking = () => {
    intervalId = setInterval(() => {
      seconds++;
    }, 1000); // Update every 1 second (1000 milliseconds)
  };

  const stopTracking = () => {
    clearInterval(intervalId);
    seconds = 0; // Reset seconds count
  };

  const getSecondsElapsed = () => seconds;

  return { startTracking, stopTracking, getSecondsElapsed: () => seconds };
};
