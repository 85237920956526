<script setup lang="ts">
  import { ref, watch } from 'vue';

  const props = defineProps<{ gameUrl: string }>();

  const contentRef = ref<HTMLElement>();

  /*
   * Centain games would not be playable by keyboard input
   * so we need to focus and interact with the iframe to make it functional
   */
  const focusIframe = () => {
    setTimeout(() => {
      contentRef.value?.focus();
      contentRef.value?.click();
    }, 1500);
  };

  watch(
    () => props.gameUrl,
    (open) => {
      if (open) {
        focusIframe();
      }
    },
  );
</script>

<template>
  <div ref="contentRef">
    <iframe
      ref="dialogContent"
      fullscreen="1"
      :src="gameUrl"
      class="game-content"
      @click="focusIframe"
    />
  </div>
</template>

<style scoped>
  .game-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }
</style>
