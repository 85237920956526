<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import { useDialog } from '@/shared/model';
  import { useRoute } from 'vue-router';
  import { useQueryClient } from '@tanstack/vue-query';

  const route = useRoute();
  const { t } = useI18n({
    useScope: 'global',
  });
  const { closeDialog } = useDialog();

  const quantity = route.query.quantity as string;
  const queryClient = useQueryClient();

  const onClose = () => {
    queryClient.resetQueries({
      queryKey: ['global-live-data'],
    });
    closeDialog();
  };
</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="text-lg text-center">
      {{ t('igc.successfulPaymentDialog.description') }}
    </div>
    <div
      class="relative flex items-center justify-center w-full max-w-max mx-auto"
    >
      <div class="glowing-effect-orange z-1" />
      <div
        class="relative flex justify-center items-center gap-2 rounded-xl w-full p-4 bg-gray-975 z-2"
      >
        <base-icon name="sparks" size="size-12" class="flex-none" />
        <span
          class="text-3xl text-transparent bg-clip-text bg-gradient-to-t to-[#F9D423] from-[#E14FAD] font-semibold"
        >
          {{ quantity }}
        </span>
      </div>
    </div>
    <div class="flex justify-center">
      <base-button variant="primary" size="medium" @click="onClose">
        {{ t('close') }}
      </base-button>
    </div>
  </div>
</template>
