import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';
import type { CreateICPUser } from 'dfx/edge/edge.did';

export const useCreateIcpUserMutation = () => {
  return useMutation({
    mutationKey: ['create-icp-user-mutation'],
    mutationFn: (params: CreateICPUser) =>
      dscvrIcApi.auth.createIcpUser(params),
  });
};
