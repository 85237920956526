import {
  useElementBounding,
  type MaybeComputedElementRef,
  type MaybeElement,
} from '@vueuse/core';
import { computed } from 'vue';

export function useSticky(
  stickyRef: MaybeComputedElementRef<MaybeElement>,
  contentRef: MaybeComputedElementRef<MaybeElement>,
) {
  const { top, height } = useElementBounding(stickyRef);
  const { top: contentTop } = useElementBounding(contentRef);
  const THREADSHOLD = 2;
  const isStickyActive = computed(() => {
    return contentTop.value + THREADSHOLD < top.value + height.value;
  });

  return {
    isStickyActive,
  };
}
