import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDialog, useGlobalStore } from '@/shared/model';
import { ImageHeader } from '@/shared/ui/base-dialog';
import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import SpinnerContent from '../../dialogs/spinner/Content.vue';
import WinnerContent from '../../dialogs/winner/Content.vue';
import LoserContent from '../../dialogs/loser/Content.vue';
import type { GimluckRewardCard } from '../../types';
import type { PhantomClaimRequest } from 'dfx/edge/edge.did';

export const useGimluck = () => {
  const { openConfiguredDialog, dialogDefaults, closeDialog } = useDialog();
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);

  const nextClaimAt = computed(
    () => globalLiveData.value?.phantom_claimable_at,
  );

  const openGimluckDialog = (claimRequest: PhantomClaimRequest) => {
    openConfiguredDialog({
      content: {
        component: SpinnerContent,
        props: {
          claimRequest,
        },
      },
      header: {
        component: ImageHeader,
        slots: {
          media: {
            template: `<img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-big.gif`,
            )}" alt="Gimluck" class="w-[200px] h-auto mx-auto pt-8" />`,
          },
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] pb-8`,
      },
    });
  };

  const openGimluckWinningDialog = (card: GimluckRewardCard) => {
    openConfiguredDialog({
      content: {
        component: WinnerContent,
        props: {
          card,
        },
      },
      header: {
        component: ImageHeader,
        slots: {
          media: {
            template: `<img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-big.gif`,
            )}" alt="Gimluck" class="w-[200px] h-auto mx-auto pt-8" />`,
          },
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] pb-8`,
      },
    });
  };

  const openGimluckLosingDialog = (card: GimluckRewardCard) => {
    openConfiguredDialog({
      content: {
        component: LoserContent,
        props: {
          card,
        },
      },
      header: {
        component: ImageHeader,
        slots: {
          media: {
            template: `<img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-big.gif`,
            )}" alt="Gimluck" class="w-[200px] h-auto mx-auto pt-8" />`,
          },
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] pb-8`,
      },
    });
  };

  return {
    nextClaimAt,
    openGimluckDialog,
    openGimluckLosingDialog,
    openGimluckWinningDialog,
  };
};
