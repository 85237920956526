import { client } from './dfinity';

export class PlugClient {
  constructor() {
    this.debug = import.meta.env.DEV;
    this.enviro = {
      host: import.meta.env.VITE_EDGE_URL,
      canisterId: import.meta.env.VITE_CANISTER_ID,
    };
  }

  async getIdentity() {
    this.authClient = await client.getOrCreateAuthClient();
    return await this.authClient.getIdentity();
  }

  async login() {
    if (!window.ic?.plug) {
      window.open('https://plugwallet.ooo/', '_blank');
      return;
    }

    const connected = await this.connect();
    if (connected) {
      await this.getAgent();
    }
  }

  async connect() {
    // This "almost" works, however plug seems to be ignoring the host wallet and redirecting
    // to ic0.app anyways :(
    return await window.ic.plug.requestConnect({
      whitelist: [this.enviro.canisterId],
      host: this.enviro.host,
    });
  }

  async isPlugConnected() {
    if (!window.ic?.plug) {
      return false;
    }
    return await window.ic.plug.isConnected();
  }

  async getAgent() {
    if (!window.ic.plug.agent) {
      await window.ic.plug.createAgent({
        whitelist: [this.enviro.canisterId],
        host: this.enviro.host,
      });
    }
    return window.ic.plug.agent;
  }
}
export const plug = new PlugClient();
