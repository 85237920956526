import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useSetUserPortalJoinedMutation = () => {
  return useMutation({
    mutationKey: ['set-user-portal-joined-mutation'],
    mutationFn: (params: { portalIds: bigint[] }) =>
      dscvrIcApi.onboarding.setUserPortalJoined(params.portalIds),
  });
};
