import { usePkce, useOauth2State, useOauth2Nonce } from '@/shared/model';
import { config } from '@/shared/lib/config';
import {
  TWITTER_AUTHORIZATION_URL,
  TWITTER_OAUTH_SCOPES,
} from '@/common/constants';

export const useTwitterOauth = () => {
  const { generateAndStorePkce } = usePkce();
  const { generateAndStoreOauth2State } = useOauth2State();
  const { generateAndStoreOauth2Nonce } = useOauth2Nonce();

  const getLocalPkceVerifier = async (): Promise<string> => {
    const result = await generateAndStorePkce();
    return result.code_challenge;
  };

  const getTwitterAuthUrl = async (): Promise<string> => {
    const pkceChallenge = await getLocalPkceVerifier();
    const state = generateAndStoreOauth2State();
    const nonce = generateAndStoreOauth2Nonce();
    const redirectUri = `${window.location.origin}/oauth2/twitter/callback`;
    const authUrl = `${TWITTER_AUTHORIZATION_URL}?client_id=${
      config.TWITTER_CLIENT_ID
    }&response_type=code&response_mode=query&scope=${encodeURIComponent(
      TWITTER_OAUTH_SCOPES,
    )}&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&code_challenge=${pkceChallenge}&code_challenge_method=S256&state=${state}&nonce=${nonce}`;
    return authUrl;
  };

  return {
    getTwitterAuthUrl,
  };
};
