<script lang="ts" setup>
  import { computed, toRef } from 'vue';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { isFleekImage } from '@/shared/lib';
  import { POST_DETAIL } from '@/common';
  import { useRoute } from 'vue-router';
  import { useEnrichUrlQuery } from '@/entities/post';

  const props = defineProps<{
    content: ContentView;
  }>();

  const route = useRoute();
  const content = toRef(props, 'content');

  const { data: iframelyData } = useEnrichUrlQuery(content);

  const url = computed(() => props.content.url);

  const onFleek = computed(() => url.value && isFleekImage(url.value));

  const isInDetail = computed(() => route.name === POST_DETAIL);

  const isComment = computed(() => props.content.content_type == 'comment');

  const iframely = computed(() => iframelyData.value?.html);

  const getUrlSubPath = (path: string) => {
    try {
      const url = new URL(path);
      const outpath =
        url.pathname.length > 6
          ? url.pathname.slice(0, 6) + '...'
          : url.pathname.slice(0, 6);
      const outhost = url.hostname.startsWith('www.')
        ? url.hostname.replace('www.', '')
        : url.hostname;

      return outhost + outpath;
    } catch {
      if (path) return path.slice(0, 24);

      return path;
    }
  };
</script>

<template>
  <div
    v-if="url && !iframely"
    class="px-0 mb-2 text-sm text-blue-400 md:px-4 hover:underline"
  >
    <img
      v-if="onFleek"
      v-lazy="url"
      alt="Image"
      class="mb-4 rounded-xl"
      :class="{ 'cropped-image': !isInDetail }"
    />
    <a v-else target="_blank" :href="url">{{ getUrlSubPath(url) }}</a>
  </div>

  <div v-if="url && iframely" class="w-full my-2 link-meta">
    <span
      class="iframely"
      :class="{
        'preview-mask-iframely': !isComment,
        'cropped-enriched-image-container': !isInDetail,
      }"
      v-html="$sanitize(iframely)"
    />
  </div>
</template>
