<template>
  <div class="flex gap-2">
    <slot name="icon" />
    <div class="flex gap-1 tracking-[0.14px] font-medium">
      <span class="text-blue-300">
        <slot name="points" />
      </span>
      <span class="capitalize">
        <slot name="label" />
      </span>
    </div>
  </div>
</template>
