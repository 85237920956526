import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { getUserByIdKey } from './keys';
import type { UserSelfView } from 'dfx/edge/edge.did';

export const useGetOnboardingInterestsQuery = (user: Ref<UserSelfView>) => {
  const userId = computed(() => user.value.id.toText());
  return useQuery({
    queryKey: [...getUserByIdKey(userId), 'interests'],
    queryFn: dscvrIcApi.onboarding.getOnboardingInterests,
  });
};
