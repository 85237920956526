<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useQueryClient } from '@tanstack/vue-query';
  import { useToast } from '@/shared/model';
  import { dscvrApi } from '@/shared/api';
  import {
    useClaimLootboxItemMutation,
    lootboxClaimQueryKey,
  } from '@/entities/lootbox';

  const props = defineProps<{
    lootboxItem: dscvrApi.lootbox.ILootboxItem;
  }>();

  const emit = defineEmits<{
    (e: 'claimed'): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const { mutateAsync: claimLootboxItem, isPending: isClaiming } =
    useClaimLootboxItemMutation();

  const claimButtonLabel = computed(() => {
    return props.lootboxItem.isClaimed
      ? t('claimed')
      : isClaiming.value
      ? `${t('claiming')}...`
      : t('claim');
  });

  const claimLootboxItemClick = async () => {
    if (
      props.lootboxItem.tokenType ===
      dscvrApi.lootbox.LootboxItemType.SOL_TARGETED_NFT
    ) {
      emit('claimed');
    } else {
      await claimLootboxItem(
        {
          lootboxId: props.lootboxItem.id,
          tokenType: props.lootboxItem.tokenType,
        },
        {
          onSettled: () =>
            queryClient.invalidateQueries({
              queryKey: [lootboxClaimQueryKey],
            }),
        },
      );
      emit('claimed');
    }
    showToast({
      title: t('claimed'),
      description: t('claimedDescription'),
      type: 'success',
      durationSeconds: 3,
    });
  };
</script>

<template>
  <div class="flex flex-col items-center mt-4">
    <base-button
      variant="primary"
      size="medium"
      :class="{
        'animate-pulse': isClaiming,
      }"
      :disabled="lootboxItem.isClaimed || isClaiming"
      class="backdrop-blur select-none mt-2"
      @click="claimLootboxItemClick"
    >
      {{ claimButtonLabel }}
    </base-button>
  </div>
</template>
