/**
 *
 */
async function getCloudflareJSON() {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 500);
    const data = await fetch('https://one.one.one.one/cdn-cgi/trace', {
      signal: controller.signal,
    })
      .then((res) => {
        return res.text();
      })
      .finally(() => clearTimeout(timeoutId));
    const arr = data
      .trim()
      .split('\n')
      .map((e) => e.split('='));
    return Object.fromEntries(arr);
  } catch (err) {
    return {};
  }
}

export const trace = getCloudflareJSON;
