import type { MaybeRef, Ref } from 'vue';
import type { Principal } from '@dfinity/principal';
import { useQuery } from '@tanstack/vue-query';
import { tokenManager } from '@/utils';
import type { IcpFungibleToken } from '../types';
import { convertResponseIcpFT } from '../lib/convert-response';
import { getIcpFTsQueryKey } from './keys';

export const useGetIcpFTsQuery = (
  principal: Ref<Principal | undefined>,
  enabled: MaybeRef<boolean> = true,
) => {
  return useQuery({
    queryKey: getIcpFTsQueryKey(principal),
    queryFn: async (): Promise<IcpFungibleToken[]> => {
      if (!principal.value) return [];
      return tokenManager.getUserTokens(principal.value);
    },
    select: convertResponseIcpFT,
    initialData: [],
    enabled,
  });
};
