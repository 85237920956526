<script lang="ts" setup>
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { useClipboardToast } from '@/shared/model';

  defineProps<{
    connectedId?: string;
    isLoading?: boolean;
  }>();

  defineEmits<{
    (e: 'toggle'): void;
  }>();

  const { copyToClipboard } = useClipboardToast();
</script>

<template>
  <div class="relative w-full">
    <overlay-loader v-if="isLoading" />
    <div class="flex flex-col border rounded-xl bg-gray-950 border-gray-750">
      <div
        class="flex items-center justify-between p-5 gap-x-2"
        :class="{
          'bg-black bg-opacity-15': connectedId,
        }"
      >
        <div
          class="flex items-center overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <slot />
          <div
            class="flex flex-col w-full ml-3 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <div class="flex whitespace-normal">
              <p
                class="mr-2 font-semibold leading-5 text-base tracking-[0.16px]"
              >
                <slot name="name" />
              </p>
              <div
                v-if="connectedId"
                class="bg-green-400 inline-flex items-center justify-center rounded-full size-5"
              >
                <base-icon name="check" size="size-3" class="text-black" />
              </div>
            </div>
            <div v-if="connectedId" class="flex items-center mt-2 gap-x-1">
              <span
                class="overflow-hidden text-sm tracking-[0.14px] leading-4.5 font-medium text-gray-400 text-ellipsis whitespace-nowrap"
              >
                <slot name="connection" />
              </span>
              <base-button
                variant="custom"
                @click="copyToClipboard(connectedId!)"
              >
                <base-icon name="outlined-copy" size="size-4" />
              </base-button>
            </div>
          </div>
        </div>
        <base-button
          variant="custom"
          size="medium"
          rounded="rounded-xl"
          class="h-10 text-sm w-21"
          :class="
            connectedId
              ? 'bg-green-600 bg-opacity-24 text-green-400'
              : 'bg-gray-700 text-white'
          "
          @click="$emit('toggle')"
        >
          <span class="font-medium align-middle">
            {{ connectedId ? $t('paired') : $t('pair') }}
          </span>
        </base-button>
      </div>
    </div>
  </div>
</template>
