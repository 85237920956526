import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';

export const usePrepareTipTxMutation = () => {
  return useMutation({
    mutationKey: ['prepare-tip-tx-mutation'],
    mutationFn: (payload: dscvrApi.tip.GetTipTxPayload) =>
      dscvrApi.tip.prepareTipTx(payload),
  });
};
