import { useDialog } from '@/shared/model';
import MediaEmbedDialogContent from '../../components/MediaEmbedDialogContent.vue';
import UploadArea from '../../components/UploadArea.vue';
import { TitleHeader } from '@/shared/ui/base-dialog';

export function useFileUploadDialog() {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openEditorMediaEmbedDialog = (
    uploadFiles: (files: FileList) => void,
    pasteImage: (src: string) => void,
  ) => {
    openConfiguredDialog({
      content: {
        component: MediaEmbedDialogContent,
        emits: {
          uploadFiles,
          pasteImage,
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} lg:w-full mx-8 max-w-screen-md`,
      },
      drawer: {
        showHeader: false,
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openFileUploadDialog = (
    filesUploaded: (files: FileList) => void,
    options: {
      accept?: string;
      multiple?: boolean;
      dragMessage?: string;
      title?: string;
    },
  ) => {
    openConfiguredDialog({
      content: {
        component: UploadArea,
        props: {
          accept: options.accept,
          multiple: options.multiple,
          dragMessage: options.dragMessage,
        },
        emits: {
          filesUploaded,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: options.title,
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} lg:w-full mx-8 max-w-screen-md`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  return {
    openEditorMediaEmbedDialog,
    openFileUploadDialog,
    closeDialog,
  };
}
