<script setup lang="ts">
  import { computed, onMounted, reactive, ref } from 'vue';
  import { liveChatModel } from '@/entities/live';
  import { getPortalIcon } from '@/entities/portal';
  import type { PortalView } from 'dfx/edge/edge.did';

  interface PortalSelect {
    id: string;
    value: string;
    name: string;
    avatar: string;
  }

  const props = withDefaults(
    defineProps<{
      portalView: PortalView;
      descriptionLengthLimit?: number;
    }>(),
    {
      descriptionLengthLimit: 150,
    },
  );

  const emit = defineEmits<{
    (e: 'close'): void;
  }>();

  const { createRoom, isLoading } = liveChatModel.use();

  // State
  const selectedPortal = ref<PortalSelect | null>(null);

  // Todo: Store this in localStorage so we can persist it
  const form = reactive({
    name: '',
    description: '',
    portalSlug: '',
    startNow: true,
    isRecordingEnabled: false,
  });

  const formValid = computed(() => {
    if (!form.name) {
      return false;
    }
    if (!form.description) {
      return false;
    }
    if (form.description.length > props.descriptionLengthLimit) {
      return false;
    }
    if (!form.portalSlug) {
      return false;
    }
    return true;
  });

  function getPortalSelectData(portal: PortalView): PortalSelect {
    return {
      id: portal.slug,
      value: portal.slug,
      name: portal.name,
      avatar: getPortalIcon(portal)!,
    };
  }

  function resetForm(): void {
    form.name = '';
    form.description = '';
    form.portalSlug = '';
    form.startNow = true;
    form.isRecordingEnabled = false;
  }

  async function submit() {
    if (!formValid.value) {
      return;
    }
    try {
      await createRoom(form, () => emit('close'));
      resetForm();
      emit('close');
    } catch (e) {
      console.log(e);
    }
  }

  const isDescriptionCharLimitReached = computed(() => {
    return form.description.length >= props.descriptionLengthLimit;
  });

  onMounted(() => {
    resetForm();

    selectedPortal.value = getPortalSelectData(props.portalView);
    form.portalSlug = props.portalView.slug;
  });
</script>

<template>
  <div class="md:min-w-[500px] my-1.5 mx-3 px-6 pb-8 z-20">
    <!-- Create Space Wizard -->
    <div class="flex justify-center items-center">
      <h1 class="font-bold py-2 text-lg">Create your live space</h1>
    </div>
    <div
      v-if="isLoading"
      class="absolute inset-0 z-10 w-full h-full pt-16 pl-4 bg-black bg-opacity-60 md:rounded-xl"
    >
      <span class="spinner-big"></span>
    </div>
    <form class="text-gray-400 pt-6" @submit.prevent="submit">
      <label for="name">What do you want to talk about?</label>
      <input
        v-model="form.name"
        autocomplete="off"
        name="name"
        class="block w-full py-2 px-3 my-4 bg-black bg-opacity-30 border-gray-700 border-opacity-40 border rounded-2xl focus:ring-0"
        placeholder="Name your space"
      />
      <div class="relative">
        <textarea
          v-model="form.description"
          class="block w-full p-2 px-3 my-2 bg-black bg-opacity-30 border-gray-700 border-opacity-40 border rounded-2xl focus:ring-0 min-h-36"
          placeholder="What would you like to talk about?"
        />
        <span
          class="absolute right-2 bottom-2 text-sm text-gray-600"
          :class="{ 'text-red-600': isDescriptionCharLimitReached }"
          >{{ form.description.length }} / {{ descriptionLengthLimit }}</span
        >
      </div>

      <div class="flex justify-end mt-9 mr-4 lg:mr-0">
        <base-button
          type="button"
          variant="custom"
          size="small"
          class="mr-4"
          @click="emit('close')"
        >
          Cancel
        </base-button>

        <base-button
          variant="primary"
          size="small"
          class="text-white"
          :disabled="!formValid"
        >
          Continue
        </base-button>
      </div>
    </form>
  </div>
</template>

<style scoped>
  input:focus,
  textarea:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
</style>
