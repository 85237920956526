import { dscvrIcApi } from '@/shared/api';
import { validateVerificationEmailTokenKey } from './keys';
import { useQuery } from '@tanstack/vue-query';
import type { Ref } from 'vue';

export const useValidateVerificationEmailTokenQuery = (token: Ref<string>) => {
  return useQuery({
    queryKey: validateVerificationEmailTokenKey,
    queryFn: async () => {
      if (!token.value) return false;
      const data = await dscvrIcApi.userSettings.verifyEmailAddressToken(
        token.value,
      );
      return 'Ok' in data || false;
    },
    refetchOnMount: false,
    enabled: false,
  });
};
