import portalService from '../../services/portals';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
import { cancellablePromise } from '../../utils/cancellable_promise';
import { trackEvent, trackGAEvent } from '../../utils/tracker';
import { PermissionGroups, hasPermissionGroup } from '@/shared/lib';

// initial state
const state = () => ({
  isLoading: 0,
  all: [],
  mentionsHash: new Map(),
  currentPortal: null,
  currentPortalRoles: [],
  currentPortalMembers: [],
  currentPortalMembersByStatus: [],
  currentPortalRoleMembers: [],
  currentPortalMemberMemos: [],
});

// getters
const getters = {
  portals: (state) => {
    return state.all;
  },
  mentionsHash: (state) => {
    return state.mentionsHash;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  currentPortal: (state) => {
    return state.currentPortal;
  },
  currentPortalRoles: (state) => {
    return state.currentPortalRoles;
  },
  currentPortalMembers: (state) => {
    return state.currentPortalMembers;
  },
  currentPortalMembersByStatus: (state) => {
    return state.currentPortalMembersByStatus;
  },
  currentPortalRoleMembers: (state) => {
    return state.currentPortalRoleMembers;
  },
  currentPortalMemberMemos: (state) => {
    return state.currentPortalMemberMemos;
  },
};

/**
 *
 * @param roles
 */
function sort_roles(roles) {
  return roles.sort((role1, role2) => {
    return Number(role1.ordinal - role2.ordinal);
  });
}

// actions
const actions = {
  [ActionTypes.GET_PORTAL_ROLES]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_ROLES, null);
    const promise = portalService.getPortalRoles(id);
    cancellablePromise.registerPromise(ActionTypes.GET_PORTAL_ROLES, promise);
    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_ROLES,
            promise,
          )
        ) {
          commit(MutationTypes.SET_CURRENT_PORTAL_ROLES, sort_roles(result));
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_ROLES ${error}`);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  async [ActionTypes.GET_PORTAL_MEMBERS]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    const max_page_size = 5000;
    let current_page = 0;
    let current_members = [];

    for (let i = 0; i < 10; i++) {
      const results = await portalService.getPortalMembers(
        id,
        max_page_size,
        current_page,
      );

      current_members = current_members.concat(results);
      current_page += results.length;

      if (results.length == 0) {
        break;
      }
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.CREATE_PORTAL]({ commit }, portal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .createPortal(portal)
      .then((result) => {
        // commit(MutationTypes.ADD_PORTAL, portal)
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          trackGAEvent('create_portal_success', {
            portal_name: portal.name,
            is_nsfw: portal.is_nsfw,
          });
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL]({ commit }, portal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortal(portal)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_ICON]({ commit }, portalIcon) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalIcon(portalIcon)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_COVER_PHOTO](
    { commit },
    { portal_id, icon_url },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoCoverPhoto(portal_id, icon_url)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_RULES]({ commit }, { portal_id, rules }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoRules(portal_id, rules)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_LINKS]({ commit }, { portal_id, links }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoLinks(portal_id, links)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.SET_PORTAL_PHONE_VERIFY]({ commit }, update) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .requiresPhone(update)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ADD_PORTAL_ROLE]({ commit }, { portal_id, role }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    trackEvent('portal_action', 'create_role');
    return portalService
      .addPortalRole(portal_id, role)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.DELETE_PORTAL_ROLE]({ commit }, role_id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .deletePortalRole(role_id)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_ROLES_ORDINALS](
    { commit },
    { portal_id, ordinals },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalRolesOrdinals(portal_id, ordinals)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ADD_PORTAL_MEMBER_ROLE](
    { commit },
    { portal_id, members, no_load },
  ) {
    trackEvent('portal_action', 'set_member_role');
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    const membersList = members.map(({ memberId, roleId }) => [
      memberId,
      roleId,
    ]);
    return portalService
      .addPortalMemberRole(portal_id, membersList)
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.REMOVE_PORTAL_MEMBER_ROLE](
    { commit },
    { portal_id, members, no_load },
  ) {
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    const membersList = members.map(({ memberId, roleId }) => [
      memberId,
      roleId,
    ]);
    return portalService
      .removePortalMemberRole(portal_id, membersList)
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);

        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },

  [ActionTypes.GET_MENTION]({ commit, state }, name) {
    if (state.mentionsHash.has(name)) {
      return;
    }
    portalService.getPortal(name).then((response) => {
      if (
        response &&
        response.status === 'happy' &&
        response.result.length > 0
      ) {
        commit(MutationTypes.SET_MENTION, response.result[0]);
      }
    });
  },
  [ActionTypes.SET_MENTION]({ commit }, portal) {
    commit(MutationTypes.SET_MENTION, portal);
  },
  [ActionTypes.SET_CURRENT_PORTAL]: ({ commit }, portal) =>
    commit(MutationTypes.SET_CURRENT_PORTAL, portal),
  [ActionTypes.ACCEPT_PORTAL_INVITE]({}, query) {
    return portalService.accept_portal_invite(query).then((response) => {
      return response;
    });
  },
};

// mutations
const mutations = {
  [MutationTypes.UPDATE_PORTAL](state, portal) {
    state.all = state.all.map((item) =>
      item.id === portal.id ? portal : item,
    );
    if (state.currentPortal && state.currentPortal.id == portal.id) {
      state.currentPortal = portal;
    }
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    if (val == true) state.isLoading++;
    else state.isLoading--;

    if (state.isLoading < 0) state.isLoading = 0;
  },
  [MutationTypes.SET_CURRENT_PORTAL](state, portal) {
    state.currentPortal = portal;
    if (portal) {
      state.all = state.all.map((ele) => (ele.id === portal.id ? portal : ele));
    }
  },
  [MutationTypes.SET_CURRENT_PORTAL_ROLES](state, roles) {
    state.currentPortalRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_ROLE_MEMBERS](state, members) {
    if (members == null) state.currentPortalRoleMembers = [];
    else state.currentPortalRoleMembers = members;
  },
  [MutationTypes.SET_MENTION](state, portal) {
    const mentionsHash = state.mentionsHash;
    mentionsHash.set(portal.slug, portal);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
