import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyWalletsQueryKey } from './keys';

export const useUnpairWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['unpair-wallet-mutation'],
    mutationFn: (address: string) => dscvrApi.wallet.unpairWallet(address),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: getMyWalletsQueryKey,
      }),
  });
};
