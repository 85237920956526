import { computed, type Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes } from '@/store/action-types';
import type { ContentPreset } from '@/shared/model';
import userService from '@/services/users';
import type { UserView } from 'dfx/edge/edge.did';
import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';
import { USER_CONTENT } from '@/common';

export const usePeopleRecommendation = (contentPreset: Ref<ContentPreset>) => {
  const store = useStore();
  const router = useRouter();

  const peopleExploreRecommendations = computed<UserView[]>(
    () => store.getters['users/exploreRecommendations'],
  );

  const spotlightUsers = computed<UserView[]>(
    () => store.getters['users/spotlightUsers'],
  );

  const fetchPeopleExploreRecommendations = (count: number) => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['explore-featured']],
      count,
      content_preset: contentPreset.value,
    };
    return store.dispatch(
      `users/${ActionTypes.GET_EXPLORE_RECOMENDATIONS}`,
      query,
    );
  };

  const fetchSpotlightUsers = () => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['spotlight']],
      count: 100, // since the endpoint don't accept unlimited count, we just set to a high number
      content_preset: contentPreset.value,
    };
    return store.dispatch(`users/${ActionTypes.GET_SPOTLIGHT_USERS}`, query);
  };

  const openRandomUser = async () => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['explore-featured']],
      count: 1,
      content_preset: contentPreset.value,
    };
    const result = await userService.getRandomizedRecommendedUsers(query);
    if (result.length > 0) {
      router.push({
        name: USER_CONTENT,
        params: { username: result[0].username },
      });
    }
  };
  return {
    peopleExploreRecommendations,
    spotlightUsers,
    fetchPeopleExploreRecommendations,
    fetchSpotlightUsers,
    openRandomUser,
  };
};
