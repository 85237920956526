<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, type Ref, toRefs } from 'vue';
  import type { BaseCarouselProps } from './types';

  const props = withDefaults(defineProps<BaseCarouselProps>(), {
    slideDuration: 5000,
  });
  const { slides, overlay, slideDuration } = toRefs(props);

  const current: Ref<number> = ref(0);
  const showArrows: Ref<boolean> = ref(false);
  const intervalId: Ref<undefined | ReturnType<typeof setInterval>> =
    ref(undefined);

  const emit = defineEmits<{ (e: 'slideInteraction', link: string): void }>();

  onMounted(() => {
    start();
  });
  onBeforeUnmount(() => {
    stop();
  });

  const next = () => {
    if (current.value < slides.value.length - 1) {
      current.value += 1;
    } else {
      current.value = 0;
    }
  };
  const prev = () => {
    if (current.value > 0) {
      current.value -= 1;
    } else {
      current.value = slides.value.length - 1;
    }
  };
  const goTo = (index: number) => {
    current.value = index;
  };
  const handleMouseEnter = () => {
    showArrows.value = true;
    stop();
  };
  const handleMouseLeave = () => {
    showArrows.value = false;
    start();
  };
  const slideInteraction = (link: string) => {
    emit('slideInteraction', link);
  };

  const start = () => {
    intervalId.value = setInterval(() => {
      if (current.value < slides.value.length - 1) {
        current.value += 1;
      } else {
        current.value = 0;
      }
    }, slideDuration.value);
  };
  const stop = () => {
    clearInterval(intervalId.value);
  };
</script>

<template>
  <div
    class="relative overflow-hidden h-[316px]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      v-for="(slide, index) in slides"
      :key="index"
      class="slide absolute w-full h-full top-4 left-0 bg-cover bg-center duration-1000 ease-in-out flex justify-center items-center"
      :class="{ active: current === index }"
      :style="{ backgroundImage: `url(${slide.background})` }"
    >
      <div
        v-if="overlay"
        class="bg-black bg-opacity-60 w-full h-full absolute"
      />
      <div
        class="flex flex-col mx-10 sm:mx-0 opacity-0 duration-[1500ms] ease-in z-10"
        :class="{ 'opacity-100': current === index }"
      >
        <h1
          class="text-2xl sm:text-[42px] leading-8 sm:leading-[52px] font-bold text-center"
        >
          {{ slide.title }}
        </h1>
        <p v-if="slide.subtitle" class="text-base sm:text-lg text-center mb-3">
          {{ slide.subtitle }}
        </p>
        <div class="flex items-center mt-6 mx-auto gap-4">
          <base-button
            variant="custom"
            rounded="rounded-full"
            class="bg-black bg-blur-md bg-opacity-40 p-2 ring-1 ring-inset ring-white ring-opacity-20 hover:bg-gray-500 hover:bg-opacity-40"
            @click="slideInteraction(slide.primaryButton.link)"
          >
            <base-icon :name="slide.primaryButton.icon" class="w-5 h-5 mr-2" />
            {{ slide.primaryButton.text }}
          </base-button>
          <base-button
            variant="link"
            class="hover:text-indigo-400"
            @click="slideInteraction(slide.secondaryButton.link)"
          >
            {{ slide.secondaryButton.text }}
          </base-button>
        </div>
      </div>
    </div>

    <base-icon
      v-if="slides.length > 1"
      name="carousel-left"
      class="prev absolute left-1/2 top-36 fill-white -translate-x-[35rem] cursor-pointer duration-500 z-10 hover:opacity-100"
      size="h-16 w-16"
      :class="{
        'opacity-0': !showArrows || current == 0,
        'opacity-40': showArrows,
      }"
      @click="prev"
    />
    <base-icon
      v-if="slides.length > 1"
      name="carousel-right"
      class="next absolute left-1/2 top-36 fill-white translate-x-[30rem] cursor-pointer duration-500 z-10 hover:opacity-100"
      size="h-16 w-16"
      :class="{
        'opacity-0': !showArrows || current == slides.length - 1,
        'opacity-40': showArrows,
      }"
      @click="next"
    />

    <div
      v-if="slides.length > 1"
      class="flex items-center absolute bottom-8 gap-2 left-1/2 -translate-x-1/2 z-10"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="h-1 bg-white rounded-full cursor-pointer ease-in-out duration-300 hover:w-11 hover:h-1.5"
        :class="[current === index ? 'w-11 opacity-100' : 'w-4 opacity-50']"
        @click="goTo(index)"
      />
    </div>
  </div>
</template>

<style>
  .btn-started {
    background: rgba(29, 29, 33, 0.64);
    transition: all 2s ease-out;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .btn-started:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.02),
        rgba(255, 255, 255, 0.02)
      ),
      rgba(29, 29, 33, 0.64);
  }
  .slide {
    opacity: 0;
    z-index: 0;
  }
  .active {
    opacity: 1;
    z-index: 1;
  }
</style>
