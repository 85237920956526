import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { OnboardingStep, type OnboardingState } from './types';

export const useOnboardingStore = defineStore('onboarding', () => {
  // we initialize the onboarding state with the last step (DONE)
  // to avoid showing the onboarding dialog to non-logged in users
  const onboardingState = ref<OnboardingState>({
    currentStep: OnboardingStep.DONE,
    completedSteps: [OnboardingStep.DONE],
  });

  const isUiLocked = computed(
    () =>
      !onboardingState.value.completedSteps.includes(
        OnboardingStep.CLAIM_STREAK,
      ) && !onboardingState.value.completedSteps.includes(OnboardingStep.DONE),
  );

  return {
    isUiLocked,
    onboardingState,
  };
});
