<script lang="ts" setup>
  import { compact } from 'lodash-es';
  import type { BaseButtonProps, ButtonVariant } from './types';
  import { computed, ref, type Ref } from 'vue';

  const props = withDefaults(defineProps<BaseButtonProps>(), {
    disabled: false,
  });

  defineEmits<{
    (e: 'click', event: MouseEvent): void;
  }>();

  const elementRef: Ref<HTMLElement | undefined> = ref();

  const classes = computed(() => {
    const genericClasses =
      'flex items-center justify-center ease-transition-all';
    const variantClasses: Record<ButtonVariant, string> = {
      dark: '',
      destructive:
        'bg-red-600 hover:bg-red-500 active:bg-red-700 disabled:bg-red-700 focus:bg-red-600 focus:ring-white focus:ring-1 transition-colors duration-300 ease-in-out',
      glass:
        'bg-white bg-opacity-8 hover:bg-opacity-12 focus:border focus:border-white focus:border-opacity-8 active:bg-opacity-6 disabled:bg-opacity-6 backdrop-blur',
      light:
        'bg-gray-700 border border-white border-opacity-8 hover:bg-gray-600 active:bg-gray-500 focus:bg-gray-700',
      link: 'bg-transparent',
      primary:
        'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 disabled:bg-indigo-700 focus:bg-indigo-600 focus:ring-white focus:ring-1 transition-colors duration-300 ease-in-out',
      secondary:
        'bg-gray-820 hover:bg-gray-950 active:bg-gray-980 focus:border focus:border-white focus:border-opacity-40',
      tertiary:
        'bg-transparent hover:bg-white hover:bg-opacity-6 active:bg-white active:bg-opacity-3 focus:bg-transparent disabled:bg-white disabled:bg-opacity-3 transition-colors duration-300 ease-in-out',
      quaternary:
        'bg-transparent border border-gray-785 border-opacity-50 focus:bg-transparent disabled:bg-white disabled:bg-opacity-3 transition-colors duration-300 ease-in-out',
      white:
        'bg-white text-gray-800 hover:bg-gray-100 focus:bg-white active:bg-gray-200',
      custom: '',
    };
    const disabledClasses =
      props.variant === 'glass'
        ? 'disabled:cursor-not-allowed disabled:pointer-events-none'
        : 'disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-70';
    const sizeClasses = {
      full: 'w-full py-3.5',
      medium: 'gap-2 px-5 py-3.5 text-base leading-5',
      small: 'gap-1.5 px-3.5 py-2.5 text-sm leading-[18px]',
      'x-small': 'gap-1 px-3 py-[7px] text-sm leading-[18px]',
    };

    return compact([
      genericClasses,
      props.additionalClasses ?? '',
      props.rounded ?? 'rounded-xl',
      props.disabled ? disabledClasses : '',
      variantClasses[props.variant],
      props.size ? sizeClasses[props.size] : '',
    ]);
  });

  const attributeLink = computed(() =>
    typeof props.to === 'string' ? 'href' : 'to',
  );

  const focus = () => {
    elementRef.value?.focus();
  };

  defineExpose({
    focus,
  });
</script>

<template>
  <component
    ref="elementRef"
    v-bind="{ ...$attrs, ...props }"
    :is="to ? (typeof to === 'string' ? 'a' : 'RouterLink') : 'button'"
    :[attributeLink]="to"
    :class="customClasses ?? classes"
    :disabled="disabled"
    @click.stop="$emit('click', $event)"
  >
    <slot />
  </component>
</template>
