<script setup lang="ts">
  import { ref } from 'vue';
  import { POST_MEDIA_ACCEPTED_MIME_TYPES } from '@/common';

  withDefaults(
    defineProps<{
      accept?: string;
      multiple?: boolean;
    }>(),
    {
      accept: POST_MEDIA_ACCEPTED_MIME_TYPES.join(','),
      multiple: true,
    },
  );

  const emit = defineEmits<{
    (e: 'files-uploaded', files: FileList): void;
  }>();

  const inputRef = ref<HTMLInputElement | null>(null);

  const filesUploaded = () => {
    if (inputRef.value?.files) {
      emit('files-uploaded', inputRef.value?.files);
    }
  };
</script>

<template>
  <label for="files-upload" class="cursor-pointer">
    <base-icon name="upload-new" size="w-8 h-8" />
  </label>
  <input
    id="files-upload"
    ref="inputRef"
    :accept="accept"
    :multiple="multiple"
    class="hidden"
    type="file"
    name="filesUpload"
    @change="filesUploaded"
  />
</template>
