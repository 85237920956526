import { type Ref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPostByIdKey } from './keys';
import { dscvrApi } from '@/shared/api';

export const useGetPostViewsQuery = (postId: Ref<bigint>) => {
  const postIdAsString = computed(() => postId.value.toString());

  return useQuery({
    queryKey: [...getPostByIdKey(postIdAsString), 'views'],
    queryFn: () => dscvrApi.post.getPostView(postIdAsString.value),
    enabled: false,
  });
};
