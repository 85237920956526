<script setup lang="ts">
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { ResponsiveOverlayCard } from '@/shared/ui/responsive-overlay-card';
  import { useShareDialog, useToast } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import type { SolanaNonFungibleToken } from '../types';
  import {
    createMagicEdenNftUrl,
    findMarketplacePage,
  } from '../lib/marketplace';
  import { parseNftName } from '../lib/extend-token';

  const props = defineProps<{
    token: SolanaNonFungibleToken;
  }>();

  defineEmits<{
    (e: 'make-pfp'): void;
  }>();

  const isImageError = ref(false);

  const { t } = useI18n({ useScope: 'global' });
  const { openShareDialog } = useShareDialog();
  const { showToast } = useToast();

  const share = () => {
    const marketplacePage = findMarketplacePage(
      props.token.collection.marketplace_pages,
      ['magiceden', 'tensor'],
    );
    if (marketplacePage) {
      openShareDialog(createMagicEdenNftUrl(props.token.contract_address));
      return;
    }

    showToast({
      title: t('wallets.sharingError'),
      type: 'error',
      durationSeconds: 3,
    });
  };
</script>
<template>
  <responsive-overlay-card class="group">
    <div class="size-full">
      <img
        v-lazy="{
          src: fetchMedia(token.image_url),
          error: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
          ),
        }"
        :alt="token.name"
        @error="isImageError = true"
        class="aspect-square"
      />
      <span class="absolute bottom-4 left-4 font-bold" v-if="isImageError">
        {{ $t('wallets.failedToLoad') }}
      </span>

      <div
        class="absolute top-6 left-6 drop-shadow-lighter z-1 group-hover:drop-shadow-heavy"
      >
        <div
          class="flex justify-center items-center rounded-full bg-gray-900 size-10"
        >
          <base-icon name="solana" size="size-5" />
        </div>
      </div>
    </div>
    <template #footer>
      <footer class="flex justify-between items-center w-full p-6 font-medium">
        <span>{{ parseNftName(token.name).name }}</span>
        <span>{{ parseNftName(token.name).number }}</span>
      </footer>
    </template>
    <template #overlay>
      <div class="flex justify-end items-center w-full">
        <base-dropdown
          placement="left-start"
          custom-content-classes="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden drop-shadow-light"
          :distance="-5"
        >
          <template #button="{ toggle }">
            <base-button variant="custom" size="small" @click="toggle">
              <base-icon
                name="vertical-dots"
                size="size-5"
                class="text-white"
              />
            </base-button>
          </template>
          <template #content="{ hide }">
            <ul class="flex flex-col">
              <li
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), share()"
              >
                <span class="whitespace-nowrap">
                  {{ $t('share') }}
                </span>
              </li>
              <li
                v-if="!isImageError"
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), $emit('make-pfp')"
              >
                <span class="whitespace-nowrap">
                  {{ $t('nftAirdrop.setAsPFP') }}
                </span>
              </li>
            </ul>
          </template>
        </base-dropdown>
      </div>

      <base-button
        variant="glass"
        size="small"
        rounded="rounded-full"
        class="ml-3.5"
        target="_blank"
        :to="createMagicEdenNftUrl(token.contract_address)"
      >
        <base-icon name="branded-magic-eden" size="size-5" />
        <span>magiceden.io</span>
      </base-button>
    </template>
  </responsive-overlay-card>
</template>
