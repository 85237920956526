import type { MaybeRef } from 'vue';

export const lootboxClaimQueryKey = 'lootbox-claim';
export const getClaimedLootboxItemsQueryKey = [
  lootboxClaimQueryKey,
  'get-claimed-lootbox-items',
];
export const getClaimableLootboxQueryKey = [
  lootboxClaimQueryKey,
  'get-claimable-lootbox',
];
export const getLootboxTargetQueryKey = (id: MaybeRef<string | undefined>) => [
  'get-lootbox-target',
  id,
];
