<template>
  <div class="flex flex-col items-center justify-center gap-4 px-8 pb-8 w-full">
    <div class="font-semibold tracking-[-0.12px] text-2xl">
      <slot name="title" />
    </div>
    <div class="text-center text-gray-400 leading-6 tracking-[0.08px]">
      <slot />
    </div>
    <section class="flex flex-col w-full gap-4 px-8">
      <slot name="actions" />
    </section>
  </div>
</template>
