import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useAddMemberRoleMutation = () => {
  return useMutation({
    mutationKey: ['add-member-role-mutation'],
    mutationFn: (params: dscvrIcApi.portal.AddRemoveMemberRoleMutationParams) =>
      dscvrIcApi.portal.addMemberRole(params),
  });
};
