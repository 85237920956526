<template>
  <div
    v-if="$slots['cover-image']"
    class="flex justify-around items-center relative w-full h-[72px] px-4"
  >
    <div class="absolute top-0 left-0 w-full h-full -z-1">
      <slot name="cover-image" />
    </div>
    <div class="flex items-center gap-2 min-w-0">
      <slot name="photo" />
      <span class="text-xl font-bold min-w-0 truncate">
        <slot name="name" />
      </span>
    </div>
    <div class="flex gap-2">
      <slot name="actions" />
    </div>
  </div>
</template>
