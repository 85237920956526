<script lang="ts" setup>
  // TODO: fix FSD
  import { MainMenu } from '@/widgets/main-menu';
  import { Notifications } from '@/features/notifications';
  import { useUser } from '@/entities/user';
  import SearchBar from '@/components/bars/SearchBar.vue';
  import { HOME, FEED_PAGES } from '@/common';
  import { useBreakpoint, useDocumentScroll } from '@/shared/model';
  import { CreatePostButton } from '@/features/post';
  import { UserDropdown, SolanaBanner } from './components';
  import { UserSummary } from '@/features/user';
  import { computed, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { ActiveStreakButton } from '@/entities/streak';
  import { useAuth } from '@/entities/auth';
  import { IgcButton } from '@/entities/igc';

  defineProps<{
    userDropdownOpened: boolean;
    menuDrawerOpened: boolean;
  }>();

  defineEmits<{
    (e: 'update:userDropdownOpened', value: boolean): void;
    (e: 'update:menuDrawerOpened', value: boolean): void;
  }>();

  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const route = useRoute();
  const { isSmallerThanLg } = useBreakpoint();
  const { y } = useDocumentScroll();

  const isHomePage = computed(() => route.name === HOME);

  const hasSolanaBanner = computed(() => isHomePage.value && !isLoggedIn.value);

  const isSolanaBannerMinimized = ref(false);

  const isHeaderVisible = ref(true);

  watch(y, (currentY, previousY) => {
    if (
      !isSmallerThanLg.value ||
      !isLoggedIn.value ||
      !FEED_PAGES.includes(route.name as string)
    ) {
      isHeaderVisible.value = true;
    } else {
      // 56 is header's pixels height
      if (currentY > previousY && currentY >= 56) {
        isHeaderVisible.value = false;
      }
      if (currentY < previousY) {
        isHeaderVisible.value = true;
      }
    }
  });
</script>

<template>
  <header
    class="fixed z-40 w-full h-14 md:h-18 transition-all duration-200 ease-out"
    :class="{
      'blur-effect': !hasSolanaBanner,
      '-translate-y-full': !isHeaderVisible,
    }"
  >
    <div
      v-if="hasSolanaBanner"
      class="absolute top-0 left-0 w-full h-full blur-effect -z-1"
      :class="[isSolanaBannerMinimized ? 'opacity-100' : 'opacity-0']"
      :style="{
        transition: 'opacity 0.5s',
      }"
    />
    <div class="mx-auto my-auto flex max-w-home py-1 px-2 gap-2 h-full">
      <div class="flex items-center gap-3 flex-1">
        <base-button
          v-if="isSmallerThanLg"
          variant="link"
          @click="$emit('update:menuDrawerOpened', !menuDrawerOpened)"
        >
          <base-icon name="hamburger" size="size-8" />
        </base-button>
        <base-button
          v-if="!isSmallerThanLg"
          variant="link"
          :to="{ name: HOME }"
        >
          <base-icon name="dscvr-with-label" size="md:w-[91px]" />
        </base-button>
        <search-bar v-if="!isSmallerThanLg" />
      </div>
      <div
        v-if="!isSmallerThanLg"
        class="flex items-center justify-center flex-1"
      >
        <main-menu />
      </div>
      <div class="flex justify-end items-center gap-4 lg:flex-1">
        <create-post-button
          v-if="isLoggedIn && !isSmallerThanLg"
          variant="primary"
          size="x-small"
        >
          <base-icon name="plus" size="size-4" />
        </create-post-button>
        <div v-if="!isLoggedIn" class="flex gap-3">
          <base-button
            variant="tertiary"
            :size="isSmallerThanLg ? 'x-small' : 'small'"
            @click="showLoginSignUpDialog()"
          >
            Log in
          </base-button>
          <base-button
            variant="primary"
            :size="isSmallerThanLg ? 'x-small' : 'small'"
            @click="showLoginSignUpDialog('signup')"
          >
            {{ $t('signup') }}
          </base-button>
        </div>
        <igc-button v-if="isLoggedIn" />
        <active-streak-button v-if="isLoggedIn" />
        <notifications v-if="!isSmallerThanLg && isLoggedIn" />
        <template v-if="!isSmallerThanLg && isLoggedIn">
          <base-button
            variant="link"
            :class="{
              'border border-gray-785 border-opacity-40 p-2': !isSmallerThanLg,
            }"
            custom-classes="rounded-full w-max"
            @click="$emit('update:userDropdownOpened', !userDropdownOpened)"
          >
            <user-summary :show-details="!isSmallerThanLg" />
            <user-dropdown
              v-if="!isSmallerThanLg && isLoggedIn"
              :opened="userDropdownOpened"
              @update:opened="$emit('update:userDropdownOpened', $event)"
            />
          </base-button>
        </template>
      </div>
    </div>
  </header>
  <solana-banner
    v-if="hasSolanaBanner"
    @minimized="isSolanaBannerMinimized = $event"
  />
</template>
