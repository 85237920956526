<template>
  <div>
    <div
      v-if="isOpen"
      class="overflow-x-hidden overflow-y-auto thin-scrollbar fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <div
          class="border-0 shadow-lg relative flex flex-col w-full bg-gray-950 outline-none focus:outline-none rounded-xl"
        >
          <!--header-->
          <div
            class="flex items-start justify-between p-5 border-b border-0 border-gray-300 rounded-t"
          >
            <h3 class="text-3xl font-semibold text-white text-center w-full">
              Discord Pair
            </h3>

            <button
              class="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="closeModal()"
            >
              <span
                class="bg-transparent h-6 w-6 text-white text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>

          <!--body-->
          <div class="p-6 flex-auto relative">
            <p class="my-4 text-white text-lg leading-relaxed text-center">
              Give this pair code to the DSCVR Discord bot. This code expires
              every 30 seconds.
            </p>

            <div
              class="border text-center bg-gray-900 font-bold border-gray-800 p-4 break-words"
            >
              {{ verifyCode }}

              <span
                class="cursor-pointer rounded-full hover:bg-gray-900 p-2"
                @click="copyPairCode"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  />
                </svg>
              </span>
            </div>

            <area-loader
              v-if="isLoading"
              :rounded="false"
              class="rounded-full"
            />
          </div>

          <!--footer-->
          <div
            class="flex items-center justify-end p-6 border-t border-0 border-gray-300 rounded-b"
          >
            <button
              class="text-white bg-transparent border border-solid border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style="transition: all 0.15s ease"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isOpen"
      class="opacity-50 fixed inset-0 z-40 bg-black"
      @click="closeModal()"
    />
  </div>
</template>

<script>
  import { client } from '../utils/dfinity';
  import { useClipboard } from '@/composables';
  import AreaLoader from './AreaLoader.vue';

  export default {
    name: 'pair-platform-modal',
    components: { AreaLoader },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      platformKind: {
        type: Object,
        default: () => null,
      },
    },
    emits: ['close-modal'],
    data() {
      return {
        invalidTransfer: false,
        isLoading: false,
        verifyCode: '',
        refreshInterval: null,
      };
    },
    watch: {
      isOpen: async function (newVal) {
        if (newVal) {
          this.startCodeRefresh();
        } else {
          clearInterval(this.refreshInterval);
        }
      },
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      async startCodeRefresh() {
        this.isLoading = true;

        await this.refreshCode();

        this.isLoading = false;

        this.refreshInterval = setInterval(() => {
          this.refreshCode();
        }, 5000);
      },
      copyPairCode() {
        const { toClipboard } = useClipboard();
        toClipboard(this.verifyCode);
      },
      async refreshCode() {
        console.log(client.getPrincipal().toText());

        const code = await client.actor.get_platform_pair_code({
          Discord: null,
        });

        if (code.length > 0) {
          this.verifyCode = this.base64(
            `${client.getPrincipal().toText()}:${code[0]}`,
          );
        } else {
          await client.actor.create_platform_pairing({
            Discord: null,
          });
          await this.refreshCode();
        }
      },
    },
  };
</script>
