<script lang="ts" setup>
  import { computed, onUnmounted, ref, watch } from 'vue';
  import { extractYoutubeIdFromUrl } from '@/shared/lib/strings';
  import { IFRAME_SANDBOX_RESTRICTIONS } from '@/common';
  import { attachAttributesToUrl } from '@/utils';
  import YoutubePlayer from 'youtube-player';
  import { useRegisterPlayback } from '@/features/post';
  import { uniqueId } from 'lodash-es';

  const props = defineProps<{
    src: string;
  }>();

  const emit = defineEmits<{
    (e: 'iframe-loaded', iframe: HTMLIFrameElement): void;
  }>();

  const id = uniqueId();
  const { pauseEveryOtherVideo, setMediaPlayback, unsetMediaPlayback } =
    useRegisterPlayback();
  const showYoutubePlayer = ref(false);
  const youtubeIframe = ref<HTMLIFrameElement | null>(null);

  const videoId = computed(() => extractYoutubeIdFromUrl(props.src));

  const youtubeSrc = computed(() =>
    attachAttributesToUrl(
      `https://www.youtube.com/embed/${videoId.value}`,
      `autoplay=1&enablejsapi=1&origin=${window.location.origin}`,
    ),
  );

  watch(youtubeIframe, (value) => {
    if (value) {
      const player = YoutubePlayer(value);
      player.on('stateChange', (event: any) => {
        if (event.data === YT.PlayerState.PLAYING) {
          pauseEveryOtherVideo(id);
        }
      });
      setMediaPlayback(id, {
        element: player,
        pause: () => player.pauseVideo(),
      });
    }
  });

  onUnmounted(() => {
    unsetMediaPlayback(id);
  });
</script>

<template>
  <div
    v-if="!showYoutubePlayer"
    class="relative flex justify-center items-center w-full aspect-video"
  >
    <base-button
      variant="custom"
      additional-classes="size-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-black/60 youtube-button bg-cover bg-center"
      :style="{
        backgroundImage: `url('https://i.ytimg.com/vi/${videoId}/sddefault.jpg')`,
      }"
      @click="showYoutubePlayer = true"
    >
      <svg height="100%" version="1.1" viewBox="0 0 68 48" class="w-17 h-12">
        <path
          d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
          fill="#212121"
          fill-opacity="0.8"
          class="youtube-play-button"
        />
        <path d="M 45,24 27,14 27,34" fill="#fff" />
      </svg>
    </base-button>
  </div>
  <iframe
    v-else
    ref="youtubeIframe"
    class="relative w-full aspect-video"
    :sandbox="IFRAME_SANDBOX_RESTRICTIONS"
    :src="youtubeSrc"
    frameborder="0"
    allowfullscreen
    allow="autoplay; encrypted-media"
  />
</template>

<style scoped>
  .youtube-button {
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  }
  .youtube-button:hover .youtube-play-button {
    transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1),
      fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
    fill: #f00;
    fill-opacity: 1;
  }
</style>
