import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useContentPollQuadraticVoteMutation = (
  params: dscvrIcApi.post.ContentPollQuadraticVoteParams,
) => {
  return useMutation({
    mutationKey: ['content-poll-quadratic-vote-mutation'],
    mutationFn: () => dscvrIcApi.post.contentPollQuadraticVote(params),
  });
};
