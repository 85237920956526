<script lang="ts" setup>
  import { onMounted, toRef } from 'vue';
  import type { WalletName } from '@solana/wallet-adapter-base';
  import { useDevice } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';
  import { WalletNotFoundError } from '../../model/types/error';
  import { convertWalletNameToWalletType } from '../../lib/solana/settings';
  import { useGenerateMobileDeeplink } from '../../model/composables/use-generate-mobile-deeplink';
  import type { Principal } from '@dfinity/principal';

  const props = defineProps<{
    principal: Principal;
    walletName: string;
  }>();

  const emit = defineEmits<{
    (e: 'pairing', deepLinkWindow: Window | null): void;
  }>();

  const { isMobileOrTablet } = useDevice();
  const { deepLink, restartGeneration } = useGenerateMobileDeeplink(
    toRef(props, 'principal'),
  );

  const onPair = () => {
    if (!deepLink.value) {
      throw new Error('Mobile deeplink not generated');
    }
    // Important: window.open is very restrictive on mobile browsers, it's not reliable
    // and using an anchor `<a href="..." ` could solve the problem but does not work with some applications
    const deepLinkWindow = window.open(deepLink.value);
    emit('pairing', deepLinkWindow);
  };

  onMounted(async () => {
    if (isMobileOrTablet.value) {
      const walletType = convertWalletNameToWalletType(
        props.walletName as WalletName,
      );
      if (!walletType) {
        throw new WalletNotFoundError();
      }
      await restartGeneration(walletType);
    }
  });
</script>

<template>
  <div class="flex flex-col justify-center items-center gap-4 pt-8 pb-10 px-6">
    <div class="font-semibold tracking-[-0.12px] text-2xl">
      {{ $t('wallets.sendingToWallet.title') }}
    </div>
    <div class="text-gray-400 tracking-[0.14px] text-center">
      {{ $t('wallets.sendingToWallet.description') }}
    </div>

    <div v-if="!deepLink" class="relative w-full h-13">
      <loader variant="rainbow" border-width="border" size="size-10" />
    </div>
    <base-button v-else variant="primary" size="full" @click="onPair">
      {{ $t('wallets.sendingToWallet.takeMeToMyWallet') }}
    </base-button>
  </div>
</template>
