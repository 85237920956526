import { computed, type Ref } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import type { PortalMemberQuery, PortalView } from 'dfx/edge/edge.did';
import { getPortalBySlugKey } from './keys';
import { dscvrIcApi } from '@/shared/api';
import { convertBigIntToNumber } from '@/shared/lib';
import { hasNextPage } from '@/shared/model';

export const useGetPortalMembersQuery = (
  portalView: Ref<PortalView>,
  queryParams: Ref<PortalMemberQuery>,
) => {
  const portalSlug = computed(() => portalView.value.slug);
  const filters = computed(() => ({
    portal_id: Number(portalView.value.id),
    role_ids: convertBigIntToNumber(queryParams.value.role_ids),
    username_filter: queryParams.value.username_filter,
    filter_order: queryParams.value.filter_order,
  }));

  return useInfiniteQuery({
    queryKey: [...getPortalBySlugKey(portalSlug), 'members', filters],
    queryFn: async (ctx) => {
      queryParams.value.page_start = BigInt(ctx.pageParam);

      const data = await dscvrIcApi.portal.getPortalMembers(queryParams.value);
      if ('Ok' in data) {
        return data.Ok;
      }
      return null;
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, __) =>
      hasNextPage(allPages[0]?.pages, lastPageParam),
  });
};
