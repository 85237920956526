<script lang="ts" setup>
  import { Tabs } from '@/shared/ui/tabs';
  import { computed, ref, type Ref, onBeforeMount } from 'vue';
  import { useI18n } from 'vue-i18n';
  import Referrals from './Referrals.vue';
  import { useUser } from '../../model/composables/use-user';
  import type { SocialSphereTab } from '@/shared/model';
  import UserList from './UserList.vue';
  import type {
    ReferralRewardView,
    ReferralCapacitorView,
    UserView,
  } from 'dfx/edge/edge.did';
  import { USER_CONTENT, USER_SETTINGS_PEOPLE } from '@/common';
  import { useRoute } from 'vue-router';
  import { compact } from 'lodash-es';
  import { pluralize } from '@/shared/lib';

  const props = withDefaults(
    defineProps<{
      selectedTab?: SocialSphereTab;
      showReferralTab?: boolean;
    }>(),
    {
      selectedTab: 'referrals',
      showReferralTab: false,
    },
  );

  const emit = defineEmits<{
    (e: 'update:selected-tab', value: SocialSphereTab): void;
  }>();

  const route = useRoute();

  const {
    userProfile,
    getFollowers,
    getFollowing,
    getBlocked,
    getReferralCommunity,
    loadUserProfileByUsername,
    currentUser,
    isCurrentUserProfile,
  } = useUser();
  const { t } = useI18n({ useScope: 'global' });

  const currentPage = ref(0);
  const totalPages = ref(0n);
  const users: Ref<UserView[]> = ref([]);
  const referrals: Ref<ReferralRewardView[]> = ref([]);
  const capacitor: Ref<ReferralCapacitorView | undefined> = ref();
  const loading = ref(false);

  const isUserSettingsPeoplePage = computed(
    () => route.name === USER_SETTINGS_PEOPLE,
  );
  const profile = computed(() =>
    route.name === USER_CONTENT ? userProfile.value : currentUser.value,
  );
  const numberOfReferrals = computed(() => referrals.value.length ?? 0);
  const tabItems = computed<{ title: string; key: SocialSphereTab }[]>(() => {
    if (!profile.value) return [];
    return compact([
      {
        title: `${profile.value.followers} ${pluralize(
          profile.value.followers,
          t('follower'),
          t('followers'),
        )}`,
        key: 'followers',
      },
      {
        title: `${profile.value.following} ${t('following')}`,
        key: 'following',
      },
      isUserSettingsPeoplePage.value && {
        title: t('blocked'),
        key: 'blocked',
      },
      (isUserSettingsPeoplePage.value ||
        props.showReferralTab ||
        isCurrentUserProfile.value) && {
        title: `${numberOfReferrals.value} ${pluralize(
          numberOfReferrals.value,
          t('referral'),
          t('referrals'),
        )}`,
        key: 'referrals',
      },
    ]);
  });

  const loadUserList = async (tab: SocialSphereTab, page: number) => {
    let result;
    loading.value = true;
    if (tab === 'followers') {
      result = await getFollowers(profile.value, page);
    } else if (tab === 'following') {
      result = await getFollowing(profile.value, page);
    } else if (tab === 'blocked') {
      result = await getBlocked();
    } else if (tab === 'referrals') {
      result = await getReferralCommunity({
        page_size: BigInt(500),
        page: BigInt(page),
        sort: [
          {
            Name: null,
          },
        ],
      });
    }
    if (result) {
      if (tab === 'referrals') {
        capacitor.value = result.capacitor;
        referrals.value = result.referrals;
      } else {
        users.value = result.users;
        totalPages.value = result.total_pages;
      }
      loading.value = false;
    }
  };

  const onUpdateCurrentPage = (tab: SocialSphereTab, page: number) => {
    currentPage.value = page;
    loadUserList(tab, page);
  };

  const onClickTab = (tab: SocialSphereTab) => {
    currentPage.value = 0;
    emit('update:selected-tab', tab);
    loadUserList(tab, currentPage.value);
  };

  onBeforeMount(async () => {
    if (profile.value === null) {
      await loadUserProfileByUsername(route.params.username as string);
    }
    await loadUserList(props.selectedTab, currentPage.value);
    if (isCurrentUserProfile.value || isUserSettingsPeoplePage.value) {
      loadUserList('referrals', currentPage.value);
    }
  });
</script>

<template>
  <tabs
    v-if="tabItems.length && profile"
    off-color="border-transparent text-gray-400"
    on-color="border-white text-white"
    content-classes="relative"
  >
    <template #tabs="{ onColor, offColor }">
      <base-button
        v-for="tab in tabItems"
        :key="tab.key"
        variant="link"
        custom-classes="flex items-end w-fit border-b-2 pb-2"
        :class="[tab.key === selectedTab ? onColor : offColor]"
        @click.stop="onClickTab(tab.key)"
      >
        <span class="font-medium">
          {{ tab.title }}
        </span>
      </base-button>
    </template>
    <template #content>
      <template
        v-if="
          selectedTab === 'following' ||
          selectedTab === 'followers' ||
          selectedTab === 'blocked'
        "
      >
        <div
          class="relative mt-2 min-h-10"
          :class="{
            'h-98 overflow-y-auto scrollbar-none': !isUserSettingsPeoplePage,
          }"
        >
          <user-list
            v-if="!loading"
            :current-page="currentPage"
            :tab-value="selectedTab"
            :loading="loading"
            :total-pages="totalPages"
            :users="users"
            @load-user-list="onUpdateCurrentPage(selectedTab, $event)"
          />
        </div>
        <div class="absolute bottom-0 left-0 w-full h-18 inner-shadow z-5" />
      </template>
      <template v-if="selectedTab === 'referrals'">
        <referrals
          v-if="capacitor && referrals && !loading"
          :capacitor="capacitor"
          :referrals="referrals"
          :total-pages="totalPages"
          :loading="loading"
          :current-page="currentPage"
        />
      </template>
    </template>
  </tabs>
</template>

<style scoped>
  .inner-shadow {
    box-shadow: 0px -25px 20px -20px rgba(19, 23, 32, 1) inset;
  }
</style>
