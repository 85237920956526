import type { dscvrApi } from '@/shared/api';
import type { CanvasAppInfo } from '../types';

export const parseAppInfo = (
  appInfo?: dscvrApi.proxy.CanvasInfo,
): CanvasAppInfo | undefined => {
  if (!appInfo) return;
  return {
    originalUrl: appInfo.url,
    proxyUrl: appInfo.proxy_url,
    previewImageUrl: appInfo.tags['og:image'],
    autoStart: appInfo.auto_start,
  };
};
