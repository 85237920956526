export const WARNING_OVERRIDES: { [key: string]: string } = {
  RELIABLE_SIMULATION_NOT_POSSIBLE:
    'This dApp could be malicious. Do not proceed unless you are certain this is safe',
  TRUSTED_BLOCKLIST_DOMAIN: 'This website is very likely to be a scam.',
};

export type {
  ActionEnum,
  BlowfishSimulationErrorKindEnum,
  ExpectedStateChange,
  ScanTransactionsSolana200Response,
  ScanTransactionsSolanaResponse,
  SolanaProtocolTrustLevelEnum,
  SolanaSimulationError,
  SolanaSimulationProgramErrorKindEnum,
  SolanaSimulationTransactionErrorKindEnum,
  WarningInnerKindEnum,
  WarningInnerSeverityEnum,
} from './types';
