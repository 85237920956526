<script setup lang="ts">
  import { useDevice, useClipboardToast } from '@/shared/model';
  import { shortenString } from '@/shared/lib';

  const props = withDefaults(
    defineProps<{
      name: string;
      isPaired: boolean;
      walletAddresses: string[];
      showSyncButton: boolean;
      pairedText: string;
      unpairedText: string;
    }>(),
    {
      isPaired: false,
      showSyncButton: false,
      pairedText: 'Unpair',
      unpairedText: 'Pair',
      walletAddresses: () => {
        return [];
      },
    },
  );

  const { isMobile } = useDevice();
  const { copyToClipboard } = useClipboardToast();

  const emit = defineEmits<{
    (e: 'on-unpair'): void;
    (e: 'on-pair'): void;
    (e: 'on-sync'): void;
  }>();

  const handleButton = () => {
    if (props.isPaired) unpair();
    else pair();
  };

  const unpair = () => {
    emit('on-unpair');
  };

  const pair = () => {
    emit('on-pair');
  };

  const syncButton = () => {
    emit('on-sync');
  };
</script>

<template>
  <div class="flex flex-col border rounded-xl bg-gray-950 border-gray-750">
    <div
      class="flex items-center justify-between p-5 gap-x-2"
      :class="{
        'bg-black bg-opacity-15': isPaired,
      }"
    >
      <div
        class="flex items-center overflow-hidden text-ellipsis whitespace-nowrap"
      >
        <slot />
        <div
          class="flex flex-col w-full ml-3 overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <div class="flex whitespace-normal">
            <p class="mr-2 font-semibold leading-5 text-base tracking-[0.16px]">
              {{ name }}
            </p>
            <div
              v-if="isPaired"
              :class="isPaired ? 'bg-green-400' : 'bg-gray-400'"
              class="inline-flex items-center justify-center rounded-full size-5"
            >
              <base-icon name="check" size="size-3" class="text-black" />
            </div>
          </div>
          <div v-if="isPaired" class="mt-1">
            <div
              class="flex w-full h-full"
              v-for="address in walletAddresses"
              :key="address"
            >
              <span
                v-if="!isMobile"
                class="overflow-hidden text-sm tracking-[0.14px] leading-4.5 font-medium text-gray-400 text-ellipsis whitespace-nowrap"
              >
                {{ $t('wallets.address') }}: {{ shortenString(address) }}
              </span>
              <base-button
                variant="custom"
                class="ml-1"
                @click="copyToClipboard(address)"
              >
                <base-icon name="outlined-copy" size="size-4" />
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="space-y-2 text-right lg:flex lg:items-end lg:space-x-2">
        <base-button
          v-if="isPaired && showSyncButton"
          variant="custom"
          custom-classes="px-4 min-w-20 h-10 text-sm bg-gray-700 bg-opacity-70 hover:bg-opacity-100 transition-all duration-300 rounded-xl"
          @click="syncButton"
        >
          <base-icon name="reload" class="inline md:pr-2" />
          <span class="hidden font-medium align-right md:inline">
            {{ $t('wallets.refreshWallets') }}
          </span>
        </base-button>
        <base-button
          class="h-10 text-sm w-21"
          variant="custom"
          :class="
            isPaired
              ? 'bg-green-600 bg-opacity-24 text-green-400'
              : 'bg-gray-700 text-white'
          "
          @click="handleButton"
        >
          <span class="font-medium align-middle">
            {{ isPaired ? pairedText : unpairedText }}
          </span>
        </base-button>
      </div>
    </div>
  </div>
</template>
