import { HMSReactiveStore } from '@100mslive/hms-video-store';

// Create a new instance of the store as shared Singleton
const hmsInstance = new HMSReactiveStore();
hmsInstance.triggerOnSubscribe(); // optional, recommended
const hmsActions = hmsInstance.getActions();
const hmsStore = hmsInstance.getStore();
const hmsNotifications = hmsInstance.getNotifications();

export { hmsInstance, hmsActions, hmsStore, hmsNotifications };
