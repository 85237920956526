import type { AxiosPromise } from 'axios';
import { apiInstance } from '../base';
import type { ResponseObject } from '../types';
import type {
  GetMultichainGatesQuery,
  CreateMultichainGateDto,
  UpdateMultichainGateDto,
  MultichainGateDto,
  MultichainCollectionDetailDto,
  ICPGateDto,
  NftGateDto,
  EntrepotCollectionItem,
  MultichainCollectionMarketPlacePage,
} from './types';
export type {
  CreateMultichainGateDto,
  UpdateMultichainGateDto,
  MultichainGateDto,
  MultichainCollectionDetailDto,
  ICPGateDto,
  NftGateDto,
  EntrepotCollectionItem,
  MultichainCollectionMarketPlacePage,
};

const BASE_URL = '/multichain-gates';

export const listNftCollections = (
  query: GetMultichainGatesQuery,
): AxiosPromise<ResponseObject<MultichainGateDto[]>> => {
  return apiInstance.get(`${BASE_URL}`, { params: query });
};

export const createNftCollection = (
  createDto: CreateMultichainGateDto,
): AxiosPromise<ResponseObject<MultichainGateDto>> => {
  return apiInstance.post(`${BASE_URL}`, createDto);
};

export const updateNftCollection = (
  id: string,
  updateDto: UpdateMultichainGateDto,
): AxiosPromise<ResponseObject<MultichainGateDto>> => {
  return apiInstance.put(`${BASE_URL}/${id}`, updateDto);
};

export const deleteNftCollection = (
  id: string,
): AxiosPromise<ResponseObject<MultichainGateDto>> => {
  return apiInstance.delete(`${BASE_URL}/${id}`);
};

export const getNftCollectionDetailsByAddress = (
  address: string,
): AxiosPromise<ResponseObject<MultichainCollectionDetailDto[]>> => {
  return apiInstance.get(`/multichain/nfts/collections/${address}`);
};
