import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { UserAvatarState } from './types';

export const useUserStore = defineStore('user', () => {
  const userUrlAvatarStateHash = ref<Map<string, UserAvatarState>>(new Map());
  const userDefaultAvatarHash = ref<Map<string, string>>(new Map());

  return { userUrlAvatarStateHash, userDefaultAvatarHash };
});
