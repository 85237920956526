export const convertBigIntToNumber = (value: any): any => {
  if (typeof value === 'bigint') {
    return Number(value);
  }

  if (typeof value !== 'object' || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(convertBigIntToNumber);
  }

  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [
      key,
      convertBigIntToNumber(value),
    ]),
  );
};
