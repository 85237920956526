import { defineStore } from 'pinia';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

export const useBreakpointsStore = defineStore('breakpoints', () => {
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const isSmallerThanXl = breakpoints.smaller('xl');
  const isSmallerThanLg = breakpoints.smaller('lg');
  const isSmallerThanMd = breakpoints.smaller('md');
  const isSmallerThanSm = breakpoints.smaller('sm');
  const isGreaterThanSm = breakpoints.greater('sm');

  return {
    breakpoints,
    isSmallerThanXl,
    isSmallerThanLg,
    isSmallerThanMd,
    isSmallerThanSm,
    isGreaterThanSm,
  };
});
