<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { compact } from 'lodash-es';
  import { useI18n } from 'vue-i18n';
  import { IS_HIDDEN } from '@/common';
  import { useToast } from '@/shared/model';
  import { Tabs } from '@/shared/ui/tabs';
  import { dscvrApi } from '@/shared/api';
  import { useUser } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { type WalletOption } from '@/entities/wallets';
  import {
    NonFungibleTokenList,
    FungibleTokenList,
    type NonFungibleToken,
    type FungibleToken,
    useVerifyNftPfpMutation,
    buildNftUpdateView,
  } from '@/entities/token';
  import { LootboxItemBase, LootboxClaimItems } from '@/features/lootbox';
  import { Icp } from '@/features/wallets';
  import { useProfileUpdate } from '@/features/user-settings';
  import WalletSection from '../ui/WalletSection.vue';
  import WalletTabOption from './WalletTabOption.vue';
  import NftSectionSkeleton from '../ui/NftSectionSkeleton.vue';
  import FungibleSectionSkeleton from '../ui/FungibleSectionSkeleton.vue';

  type WalletTab = 'all' | 'fungies' | 'nfts' | 'loots';

  const props = defineProps<{
    isLoadingNfts: boolean;
    isLoadingFungies: boolean;
    isLoadingLootboxItems: boolean;
    nfts: NonFungibleToken[];
    fts: FungibleToken[];
    claimedLootboxItems: dscvrApi.lootbox.ILootboxItem[];
    unclaimedLootboxItems: dscvrApi.lootbox.ILootboxItem[];
    selectedWalletOption?: WalletOption;
  }>();

  const currentTab = ref<WalletTab>('all');

  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { refreshCurrentUser } = useUser();
  const { isIcpUxChainEnabled } = useChainBehavior();
  const { updatePfp } = useProfileUpdate();
  const { mutateAsync: verifyNftPfp } = useVerifyNftPfpMutation();

  // for now we show all items in one list
  // when the lootbox claiming epic is ready e can separate them
  const lootboxItems = computed(() => [
    ...props.claimedLootboxItems,
    ...props.unclaimedLootboxItems,
  ]);

  const showNFTs = computed(
    () => currentTab.value === 'all' || currentTab.value === 'nfts',
  );

  const showFungies = computed(
    () => currentTab.value === 'all' || currentTab.value === 'fungies',
  );

  const showLoots = computed(
    () => currentTab.value === 'all' || currentTab.value === 'loots',
  );

  const isNotEmpty = computed(
    () => props.nfts.length || props.fts.length || lootboxItems.value.length,
  );

  const loadingStates = computed(() => [
    props.isLoadingNfts,
    props.isLoadingFungies,
    props.isLoadingLootboxItems,
  ]);

  const loadingSomething = computed(() => loadingStates.value.some(Boolean));
  const loadingAll = computed(() => loadingStates.value.every(Boolean));

  const makeNftPfp = async (token: NonFungibleToken) => {
    const nftUpdateView = buildNftUpdateView(token);

    const response = await updatePfp({ Nft: nftUpdateView });
    if (!response.length) {
      return;
    }
    await refreshCurrentUser();

    if (token.chain === 'sol') {
      await verifyNftPfp(token, {
        onError: (error) => {
          showToast({
            title: `${t('wallets.verifyNftPfpError')} ${error}`,
            type: 'error',
            durationSeconds: 3,
          });
        },
      });
    }
  };

  watch(loadingStates, () => {
    if (
      (currentTab.value === 'nfts' &&
        !props.isLoadingNfts &&
        !props.nfts.length) ||
      (currentTab.value === 'fungies' &&
        !props.isLoadingFungies &&
        !props.fts.length) ||
      (currentTab.value === 'loots' &&
        !props.isLoadingLootboxItems &&
        !lootboxItems.value.length)
    ) {
      currentTab.value = 'all';
    }
  });
</script>

<template>
  <div class="mt-6 pb-5 px-4 md:px-2 lg:px-0">
    <tabs
      v-if="loadingSomething || isNotEmpty"
      type="pills"
      class="flex flex-col gap-6"
      tabs-classes="flex w-full justify-between"
      content-classes="flex flex-col gap-6"
      base-color="leading-7 rounded-xl px-3 py-1 text-sm font-medium flex items-center gap-1"
      on-color="text-white bg-indigo-800"
      off-color="text-gray-300 bg-gray-950"
    >
      <template #tabs="{ baseColor, onColor, offColor }">
        <wallet-tab-option
          :is-loading="loadingAll"
          :custom-classes="
            compact([baseColor, currentTab === 'all' ? onColor : offColor])
          "
          @select="currentTab = 'all'"
        >
          {{ $t('all') }}
        </wallet-tab-option>
        <wallet-tab-option
          :is-loading="isLoadingNfts"
          :disabled="!nfts.length"
          :custom-classes="
            compact([baseColor, currentTab === 'nfts' ? onColor : offColor])
          "
          @select="currentTab = 'nfts'"
        >
          {{ $t('nfts') }}
          <template #count v-if="nfts.length">
            {{ nfts.length }}
          </template>
        </wallet-tab-option>
        <wallet-tab-option
          :is-loading="isLoadingFungies"
          :disabled="!fts.length"
          :custom-classes="
            compact([baseColor, currentTab === 'fungies' ? onColor : offColor])
          "
          @select="currentTab = 'fungies'"
        >
          {{ $t('tokens') }}
          <template #count v-if="fts.length">
            {{ fts.length }}
          </template>
        </wallet-tab-option>
        <wallet-tab-option
          :is-loading="isLoadingLootboxItems"
          :disabled="!lootboxItems.length"
          :custom-classes="
            compact([baseColor, currentTab === 'loots' ? onColor : offColor])
          "
          @select="currentTab = 'loots'"
        >
          {{ $t('lootbox.loot') }}
          <template #count v-if="lootboxItems.length">
            {{ lootboxItems.length }}
          </template>
        </wallet-tab-option>
      </template>
      <template #content>
        <lootbox-claim-items
          v-if="!IS_HIDDEN"
          :unclaimed-items="unclaimedLootboxItems"
        />
        <nft-section-skeleton v-if="isLoadingNfts" />
        <wallet-section v-else-if="showNFTs && nfts.length">
          <template #title>{{ $t('nfts') }}</template>
          <template #count>{{ nfts.length }}</template>
          <non-fungible-token-list
            :tokens="nfts"
            @make-pfp="makeNftPfp($event)"
          />
        </wallet-section>

        <fungible-section-skeleton v-if="isLoadingFungies" />
        <wallet-section
          v-else-if="showFungies && fts.length"
          grid-cols="md:grid-cols-2"
        >
          <template #title>{{ $t('tokens') }}</template>
          <template #count>{{ fts.length }}</template>
          <fungible-token-list :tokens="fts" />
        </wallet-section>

        <nft-section-skeleton v-if="isLoadingLootboxItems" />
        <wallet-section v-else-if="showLoots && lootboxItems.length">
          <template #title>
            <span class="capitalize">{{ $t('lootbox.lootEarned') }}</span>
          </template>
          <template #count>{{ lootboxItems.length }}</template>
          <lootbox-item-base
            v-for="item in lootboxItems"
            :key="item.id"
            :lootbox-item="item"
          />
        </wallet-section>

        <icp
          v-if="selectedWalletOption?.chain !== 'sol' && isIcpUxChainEnabled"
        />
      </template>
    </tabs>
  </div>
</template>
