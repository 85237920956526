import { storeToRefs } from 'pinia';
import { useUser } from '@/entities/user';
import { useFrameStore } from '@/shared/model';
import { useCreateFrameMutation } from '../../api/use-create-frame.mutation';

export const useValidateAndCreateFrame = () => {
  const frameStore = useFrameStore();
  const { savedUrl } = storeToRefs(frameStore);
  const { currentUserPrincipal } = useUser();
  const { mutateAsync: createFrameAsyncMutation } = useCreateFrameMutation();

  const validateAndCreateFrame = async (url: string) => {
    const frame = await createFrameAsyncMutation({
      url,
      userPrincipal: currentUserPrincipal.value?.toText(),
    });
    if (!frame) {
      return false;
    }

    savedUrl.value.push(url);
    return true;
  };

  return {
    validateAndCreateFrame,
  };
};
