import { generateRandomString } from '@/shared/lib/strings';

const OAUTH2_NONCE_LOCALSTORAGE_KEY = 'oauth2-nonce';

export const useOauth2Nonce = () => {
  const generateOauth2Nonce = () => {
    return generateRandomString(16);
  };

  const getLocalOauth2Nonce = () => {
    return localStorage.getItem(OAUTH2_NONCE_LOCALSTORAGE_KEY);
  };

  const setLocalOauth2Nonce = (nonce: string) => {
    localStorage.setItem(OAUTH2_NONCE_LOCALSTORAGE_KEY, nonce);
  };

  const generateAndStoreOauth2Nonce = () => {
    const nonce = generateOauth2Nonce();
    setLocalOauth2Nonce(nonce);
    return nonce;
  };
  return {
    generateOauth2Nonce,
    getLocalOauth2Nonce,
    setLocalOauth2Nonce,
    generateAndStoreOauth2Nonce,
  };
};
