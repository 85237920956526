import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import {
  useDialogStore,
  type DialogState,
  type CustomComponentProps,
} from '@/store';
import { Drawer } from '@/shared/ui/drawer';
import { BaseDialog } from '@/shared/ui/base-dialog';
import { useBreakpoint } from './use-breakpoint';

export const useDialog = () => {
  const { isSmallerThanMd } = useBreakpoint();
  const dialogStore = useDialogStore();

  const { dialog } = storeToRefs(dialogStore);

  const dialogProps = computed(() => ({
    opened: dialog.value.opened,
    // BaseDialog props
    ...(!isSmallerThanMd.value && {
      ...dialog.value.dialog,
    }),
    // Drawer props
    ...(isSmallerThanMd.value && {
      direction: 'bottom',
      ...dialog.value.drawer,
    }),
  }));

  const dialogComponent = computed(() =>
    isSmallerThanMd.value ? Drawer : BaseDialog,
  );

  const openConfiguredDialog = <
    HeaderProps extends CustomComponentProps = {},
    ContentProps extends CustomComponentProps = {},
  >(
    state: Partial<DialogState<HeaderProps, ContentProps>>,
  ) => {
    dialogStore.dialog = {
      opened: true,
      ...state,
    };
  };

  const closeDialog = () => {
    if (dialog.value.emit) {
      dialog.value.emit();
    }
    dialogStore.$reset();
  };

  const dialogDefaults = {
    dialog: {
      contentClasses: 'rounded-2xl',
    },
    drawer: {
      customClasses:
        'z-30 bg-gray-975 border border-gray-785 border-opacity-50 rounded-t-2xl',
    },
  };

  return {
    dialogStore,
    dialogComponent,
    dialogProps,
    openConfiguredDialog,
    closeDialog,
    dialogDefaults,
  };
};
