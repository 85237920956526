import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';
import { getCrc32 } from '@dfinity/principal/lib/esm/utils/getCrc';
import { sha224 } from '@dfinity/principal/lib/esm/utils/sha224';
import { getAvatarUrl } from '../../../avatar';
import { NFT } from './nft';

const to32bits = (num) => {
  const b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};

const getSubAccountArray = (s) => {
  if (Array.isArray(s)) {
    return s.concat(Array(32 - s.length).fill(0));
  } else {
    //32 bit number only
    return Array(28)
      .fill(0)
      .concat(to32bits(s ? s : 0));
  }
};

const principalToAccountIdentifier = (p) => {
  const padding = Buffer('\x0Aaccount-id');
  const array = new Uint8Array([
    ...padding,
    ...Principal.fromText(p).toUint8Array(),
    ...getSubAccountArray(0),
  ]);
  const hash = sha224(array);
  const checksum = to32bits(getCrc32(hash));
  const array2 = new Uint8Array([...checksum, ...hash]);
  return toHexString(array2);
};

const toHexString = (byteArray) => {
  return Array.from(byteArray, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');
};

export const tokenIdentifier = (principal, index) => {
  const padding = Buffer('\x0Atid');
  const array = new Uint8Array([
    ...padding,
    ...Principal.fromText(principal).toUint8Array(),
    ...to32bits(index),
  ]);
  return Principal.fromUint8Array(array).toText();
};

const idlFactory = ({ IDL }) => {
  const TokenIdentifier = IDL.Text,
    SubAccount = IDL.Vec(IDL.Nat8),
    Memo = IDL.Vec(IDL.Nat8),
    Balance = IDL.Nat,
    AccountIdentifier = IDL.Text,
    CommonError = IDL.Variant({
      InvalidToken: TokenIdentifier,
      Other: IDL.Text,
    }),
    User = IDL.Variant({
      principal: IDL.Principal,
      address: AccountIdentifier,
    }),
    TokenIndex = IDL.Nat32,
    Result = IDL.Variant({
      ok: IDL.Vec(TokenIndex),
      err: CommonError,
    }),
    Time = IDL.Int,
    Listing = IDL.Record({
      locked: IDL.Opt(Time),
      seller: IDL.Principal,
      price: IDL.Nat64,
    }),
    Result_1 = IDL.Variant({
      ok: IDL.Vec(
        IDL.Tuple(TokenIndex, IDL.Opt(Listing), IDL.Opt(IDL.Vec(IDL.Nat8))),
      ),
      err: CommonError,
    }),
    TransferResponse = IDL.Variant({
      ok: Balance,
      err: IDL.Variant({
        CannotNotify: AccountIdentifier,
        InsufficientBalance: IDL.Null,
        InvalidToken: TokenIdentifier,
        Rejected: IDL.Null,
        Unauthorized: AccountIdentifier,
        Other: IDL.Text,
      }),
    }),
    ClaimResult = IDL.Variant({ Ok: IDL.Nat64, Err: IDL.Text }),
    TransferRequest = IDL.Record({
      to: User,
      token: TokenIdentifier,
      notify: IDL.Bool,
      from: User,
      memo: Memo,
      subaccount: IDL.Opt(SubAccount),
      amount: Balance,
    }),
    BalanceResponse = IDL.Variant({ ok: Balance, err: CommonError }),
    BalanceRequest = IDL.Record({
      token: TokenIdentifier,
      user: User,
    });

  return IDL.Service({
    tokenId: IDL.Func([TokenIndex], [TokenIdentifier], ['query']),
    balance: IDL.Func([BalanceRequest], [BalanceResponse], ['query']),
    tokens: IDL.Func([AccountIdentifier], [Result], ['query']),
    tokens_ext: IDL.Func([AccountIdentifier], [Result_1], ['query']),
    transfer: IDL.Func([TransferRequest], [TransferResponse], []),
    getClaims: IDL.Func([], [IDL.Nat64], ['query']),
    claim: IDL.Func([], [ClaimResult], []),
  });
};
export class EXT {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  getAccountIdentifier(principal) {
    return principalToAccountIdentifier(principal);
  }

  toTokenId(tokenId) {
    return tokenIdentifier(this.canisterId, tokenId);
  }

  isValidPrincipal(val) {
    try {
      Principal.fromText(val);
      return true;
    } catch (e) {
      return false;
    }
  }

  async transfer(to, token) {
    if (this.isValidPrincipal(to)) {
      to = principalToAccountIdentifier(to);
    } else if (to.length != 64) {
      return {
        error: 'Invalid wallet address',
      };
    }
    return await this.actor.transfer({
      to: {
        address: to,
      },
      token: this.toTokenId(token),
      notify: false,
      from: {
        address: principalToAccountIdentifier(
          this.identity.getPrincipal().toText(),
        ),
      },
      memo: [],
      subaccount: [],
      amount: 1,
    });
  }

  async get_my_tokens() {
    return this.get_tokens(this.identity.getPrincipal().toText());
  }

  async get_claims() {
    return this.actor.getClaims();
  }

  async claim() {
    return this.actor.claim();
  }

  get_token_url(id) {
    return `https://${this.canisterId}.raw.icp0.io/?tokenid=${tokenIdentifier(
      this.canisterId,
      id,
    )}`;
  }

  async getTokens(principal) {
    const tokens = await this.actor.tokens_ext(
      principalToAccountIdentifier(principal.toText()),
    );
    //[this.get_token_url(t), this.canisterId, t]
    if (tokens.ok !== undefined) {
      const results = [];

      tokens.ok.map((t) => {
        const tokenUrl = this.get_token_url(t[0]);
        const nft = new NFT({
          tokenUrl: tokenUrl,
          tokenDisplayUrl: getAvatarUrl(tokenUrl),
          cid: this.canisterId,
          tokenIndex: t[0],
          tokenHash: tokenIdentifier(this.canisterId, t[0]),
        });
        results.push(nft);
      });
      return results;
    }
    return [];
  }
}
