import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useLogInteractionMutation = () => {
  return useMutation({
    mutationKey: ['log-canvas-interaction-mutation'],
    mutationFn: ({ url, contentId }: { url: string; contentId: bigint }) =>
      dscvrIcApi.canvas.logCanvasInteraction(url, contentId),
  });
};
