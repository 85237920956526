<script setup lang="ts">
  import { onMounted, onUnmounted } from 'vue';
  import { liveChatModel } from '@/entities/live';
  import { useTimeElapsedCounter } from '@/entities/live/lib';

  const { timeElapsed, setTimeElapsedCounter, cleanupTimeElapsedCounter } =
    useTimeElapsedCounter();

  const { selectedRoom } = liveChatModel.use();

  onMounted(() => {
    cleanupTimeElapsedCounter();
    setTimeElapsedCounter(selectedRoom.value?.startAt as string);
  });

  onUnmounted(() => {
    cleanupTimeElapsedCounter();
  });
</script>
<template>
  <div
    class="flex items-center p-1 text-xs font-semibold bg-gray-975 border border-white border-opacity-10 rounded-lg py-2 group"
  >
    <span class="pl-1">{{ $t('runtime') }}</span>
    <span
      class="inline-block align-middle w-2 h-2 rounded rounded-full my-1 mx-1"
      :class="{
        'bg-green-300': selectedRoom?.enabled,
      }"
    ></span>
    <span
      class="text-gray-400 pr-1 font-normal group-hover:text-white transition duration-500 min-w-16"
      >{{ timeElapsed }}</span
    >
  </div>
</template>
