import axios from 'axios';
import { config } from '@/shared/lib';

export const apiInstance = axios.create({
  baseURL: `${config.SOL_SERVICES_URL}/api`,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
