import { createClient } from '@supabase/supabase-js';
import { shuffle } from 'lodash-es';

const CACHE_TTL = 60 * 60 * 1000; // Cache data for 1 hour
const supabaseUrl = 'https://pfxgbjjerudeyddutwxf.supabase.co';
const supabaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBmeGdiamplcnVkZXlkZHV0d3hmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjQzMTEyNjksImV4cCI6MTk3OTg4NzI2OX0.aNpbUdyrkdXRygyHoDehO-Qpk7ODX6N5kESoQo9H2Fo';
const supabase = createClient(supabaseUrl, supabaseKey);

const sqrlLoader = async (passKey, data) => {
  const secret = passKey.split(':')[1];
  const iv = passKey.split(':')[0];
  return decrypt(secret, iv, data[0].data);
};

const hexStringToBuffer = (hexString) => {
  return new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)),
  );
};

const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const decrypt = async (hexKey, hexIv, hexCipherText) => {
  const key = await window.crypto.subtle.importKey(
    'raw',
    hexStringToBuffer(hexKey),
    'AES-CBC',
    false,
    ['decrypt'],
  );

  const iv = hexStringToBuffer(hexIv);
  const cipherText = hexStringToBuffer(hexCipherText);

  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: iv },
    key,
    cipherText,
  );
  return new TextDecoder().decode(decrypted);
};

const convertToCandidOptional = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  } else {
    return [];
  }
};

class CMS {
  async getBanner() {
    const result = await supabase
      .from('HomePageBanner')
      .select()
      .filter('start', 'lte', new Date().toISOString())
      .order('start', { ascending: false })
      .limit(1);
    if (result?.status == 200) {
      return result.data;
    }
    return [];
  }

  async getTopPortals(category) {
    if (category) {
      return supabase
        .from('TopPortals')
        .select()
        .filter('category', 'eq', category);
    }
    const result = await supabase.from('TopPortals').select();
    if (result?.status == 200) {
      if (result.data) {
        result.data = shuffle(result.data);
      }
    }
    return result;
  }

  async getArcadeGames() {
    const result = await supabase.from('ArcadeGames').select().order('ordinal');
    if (result?.status == 200) {
      return result.data;
    }
    return [];
  }

  async getArcadeUsernames() {
    const result = await supabase
      .from('ArcadeUsernames')
      .select()
      .order('ordinal');
    if (result?.status == 200) {
      return result.data.map((item) => item.username);
    }
    return [];
  }

  async getArcadePortalSlugs() {
    const result = await supabase
      .from('ArcadePortalSlugs')
      .select()
      .order('ordinal');
    if (result?.status == 200) {
      return result.data.map((item) => item.slug);
    }
    return [];
  }

  async getSqrls(passKey) {
    const result = await supabase.from('Sqrl').select();
    if (result?.status == 200) {
      return await sqrlLoader(passKey, result.data);
    }
    return '';
  }

  async getFeedAlgorithmV1(type) {
    let algoParameters = getWithExpiry(`feedAlgorithmV1-${type}`);
    if (!algoParameters) {
      const result = await supabase
        .from('PersonalizedFeedV1')
        .select()
        .eq('feed_type', type);

      if (result?.status == 200 && result.data.length > 0) {
        let data = result.data[0];

        setWithExpiry(`feedAlgorithmV1-${type}`, data, CACHE_TTL);
        algoParameters = data;
      }
    }

    //transform to fit the API
    if (algoParameters) {
      algoParameters.profile_posts_weight = convertToCandidOptional(
        algoParameters.profile_posts_weight,
      );
      algoParameters.portal_admin_posts_weight = convertToCandidOptional(
        algoParameters.portal_admin_posts_weight,
      );
      algoParameters.freshness_weight = convertToCandidOptional(
        algoParameters.freshness_weight,
      );
      algoParameters.show_caller_posts = convertToCandidOptional(
        algoParameters.show_caller_posts,
      );
      algoParameters.include_top_users = convertToCandidOptional(
        algoParameters.include_top_users,
      );
      algoParameters.award_age_multiplier = convertToCandidOptional(
        algoParameters.award_age_multiplier,
      );
      algoParameters.award_upvote_multiplier = convertToCandidOptional(
        algoParameters.award_upvote_multiplier,
      );
    }

    return algoParameters;
  }
}
export default new CMS();
