<script setup lang="ts">
  import { ref } from 'vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { usePreferenceSettings } from '@/entities/user-setting';
  import type { ChainOption } from '@/utils';

  const popup = ref(false);

  const {
    chainSelectionDropdown,
    closeChainSelectionDropdown,
    primaryChainOptions,
    primaryChain,
    setPrimaryChain,
    primaryChainOption,
  } = usePreferenceSettings();

  const toggleChainSelectionDropdown = () => {
    chainSelectionDropdown.value = !chainSelectionDropdown.value;
  };

  const selectValue = async (value: ChainOption) => {
    await setPrimaryChain(value);
    closeChainSelectionDropdown();
  };
</script>
<template>
  <div class="relative">
    <label
      for="chainSelectionDropdown"
      class="block text-base font-semibold leading-5"
    >
      {{ $t('primaryChain') }}
    </label>
    <base-button
      id="chainSelectionDropdown"
      variant="custom"
      custom-classes="mt-2 flex !justify-start relative w-full rounded-xl border-0 bg-black/32 cursor-pointer py-2 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-[#333B4780] focus:ring-1 focus:ring-inset focus:ring-[#333B4780] sm:text-sm sm:leading-6 transition-all duration-300 hover:bg-gray-950 disabled:opacity-75"
      @click="toggleChainSelectionDropdown"
      @mouseover="popup = true"
      @mouseout="popup = false"
    >
      <template v-if="primaryChainOption">
        <img
          :src="
            fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/chains/${primaryChainOption.logo}.svg`,
            )
          "
          :alt="`${primaryChainOption.title} Logo`"
          class="flex-shrink-0 size-6 rounded-full"
        />
        <span class="block ml-2 truncate">
          {{ primaryChainOption.title }}
        </span>
      </template>
      <template v-else>
        <base-icon
          name="filled-blockchain"
          size="size-5"
          class="text-gray-400"
        />
        <span class="block ml-3 text-gray-400 truncate">
          {{ $t('selectPrimaryChain') }}
        </span>
      </template>
      <span
        class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
      >
        <base-icon name="chevron-down" size="size-4" class="text-gray-400" />
      </span>
    </base-button>
    <base-dropdown
      v-model:opened="chainSelectionDropdown"
      match-content-width
      placement="bottom-end"
      custom-content-classes=" max-h-56 w-full !overflow-auto scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 rounded-xl bg-[#1c212e] text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
    >
      <template #content>
        <div
          v-for="(chain, idx) in primaryChainOptions"
          :key="idx"
          class="py-2 pl-3 cursor-pointer select-none pr-9 hover:bg-gray-785"
          :class="{
            'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
              primaryChain === chain.chain,
          }"
          @click="selectValue(chain)"
        >
          <div class="flex items-center">
            <img
              :src="
                fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/chains/${chain.logo}.svg`,
                )
              "
              :alt="`${chain.title} Logo`"
              class="flex-shrink-0 size-6 rounded-full"
            />
            <span class="ml-2 truncate">
              {{ chain.title }}
            </span>
          </div>
        </div>
      </template>
    </base-dropdown>
  </div>
</template>
