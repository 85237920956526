<script setup lang="ts">
  import { onMounted, ref, computed, watch } from 'vue';
  import { useElementHover } from '@vueuse/core';
  import { useUser } from '@/entities/user';
  import type { ButtonSize } from '@/shared/ui/base-button';
  import { ElasticLine } from '@/shared/ui/elastic-line';
  import type { UserView } from 'dfx/edge/edge.did';
  import type { UserListItemView } from 'dfx/edge/edge.did';
  import { ActionTypes } from '@/store';
  import { useStore } from 'vuex';
  import { useAuth } from '@/entities/auth';

  const props = withDefaults(
    defineProps<{
      user?: UserView | UserListItemView;
      size?: ButtonSize;
      followClass?: string;
      showLabelOnMobile?: boolean;
      fullyElastic?: boolean;
    }>(),
    {
      size: 'small',
      showLabelOnMobile: false,
      fullyElastic: false,
    },
  );

  const emit = defineEmits<{
    (e: 'update:is-following', isFollowing: boolean): void;
  }>();

  const store = useStore();
  const { showLoginSignUpDialog } = useAuth();
  const { userProfile, checkIfFollowingUser, currentUser } = useUser();

  const isFollowing = ref(false);
  const isLoading = ref(true);
  const buttonElement = ref();
  const isHovered = useElementHover(buttonElement);

  const profile = computed(() => props.user ?? userProfile.value);
  const label = computed(() => {
    if (!isFollowing.value) {
      if (props.fullyElastic) {
        return isHovered.value ? 'Follow' : '';
      }
      return 'Follow';
    }
    return isHovered.value ? 'Unfollow' : '';
  });

  const refresh = async () => {
    const isUserFollowing = await checkIfFollowingUser(profile.value?.id);
    isFollowing.value = isUserFollowing;
    isLoading.value = false;
  };

  onMounted(refresh);

  watch(() => props.user?.id, refresh);

  const toggleUserFollow = (event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else {
      store.dispatch(`users/${ActionTypes.TOGGLE_FOLLOW_USER}`, profile.value);
      isFollowing.value = !isFollowing.value;
      emit('update:is-following', isFollowing.value);
    }
  };
</script>

<template>
  <base-button
    v-if="!isLoading"
    ref="buttonElement"
    :variant="isFollowing ? 'glass' : 'primary'"
    :size="size"
    rounded="rounded-xl"
    class="animate-fade"
    :class="!isFollowing ? followClass : ''"
    :additional-classes="[
      'ring-1 ring-white ring-opacity-10 ring-inset',
      isFollowing
        ? 'bg-gray-820 hover:bg-gray-950 active:bg-gray-980 focus:border focus:border-white focus:border-opacity-40 bg-opacity-60 bg-blur-md'
        : '',
      label.length === 0 ? '!gap-0' : '',
    ]"
    @click="toggleUserFollow"
  >
    <base-icon
      :name="
        !isFollowing ? 'follow' : isHovered ? 'profile-remove' : 'profile-tick'
      "
      :size="size === 'x-small' ? 'size-5' : 'size-6'"
    />
    <elastic-line :class="{ 'hidden lg:block': !showLabelOnMobile }">
      {{ label }}
    </elastic-line>
  </base-button>
</template>
