<script lang="ts" setup>
  import { useBreakpoint, useDocumentScroll } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { UserSummary } from '@/features/user';
  import { CreatePostButton } from '@/features/post';
  import { Notifications } from '@/features/notifications';
  import { HOME, USER_CONTENT } from '@/common';

  defineProps<{
    menuDrawerOpened: boolean;
  }>();

  defineEmits<{
    (e: 'update:menuDrawerOpened', value: boolean): void;
  }>();

  const { isSmallerThanLg } = useBreakpoint();
  const { isScrolling } = useDocumentScroll();
  const { isLoggedIn, currentUser } = useUser();
</script>

<template>
  <footer
    v-if="isSmallerThanLg && isLoggedIn"
    class="fixed bottom-0 z-40 w-full px-2 pt-1 pb-4 border-t-1px border-gray-785 bg-black shadow-top-light backdrop-blur-sm transition-all duration-500 ease-in-out"
    :class="{ 'opacity-50 ': isScrolling }"
  >
    <div class="flex justify-between items-center py-1 px-2 h-full">
      <base-button variant="link" :to="{ name: HOME }">
        <base-icon name="dscvr" size="size-7" />
      </base-button>
      <base-button
        variant="link"
        custom-classes="flex justify-center items-center rounded-full bg-black bg-opacity-80"
        @click="$emit('update:menuDrawerOpened', !menuDrawerOpened)"
      >
        <base-icon name="search" size="size-5" />
      </base-button>

      <create-post-button variant="primary" size="x-small">
        <base-icon name="plus" size="size-4" />
      </create-post-button>
      <notifications />
      <base-button
        variant="link"
        custom-classes="rounded-full w-max"
        :to="{
          name: USER_CONTENT,
          params: { username: currentUser.username },
        }"
      >
        <user-summary :show-details="false" />
      </base-button>
    </div>
  </footer>
</template>
