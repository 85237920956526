<script lang="ts" setup>
  import { ref, computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import ContentDialog from '../../ui/Content.vue';
  import UserCard from '../../ui/UserCard.vue';
  import ContinueButton from '../ContinueButton.vue';
  import SkipButton from '../SkipButton.vue';
  import { OnboardingStep, OnboardingLootboxStep } from '@/shared/model';
  import { pluralize, formatToUnits } from '@/shared/lib';
  import { useSetUserTopUsersMutation } from '../../api/use-set-user-top-users.mutation';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { useGetOnboardingUserRecommendationsQuery } from '../../api/use-get-onboarding-user-recommendations.query';
  import type { Principal } from '@dfinity/principal';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { Loader } from '@/shared/ui/loader';
  import { useUser, coverGradients } from '@/entities/user';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  defineProps<{
    isCompletingSteps: boolean;
  }>();

  const { t } = useI18n({
    useScope: 'global',
  });
  useGtm()?.trackEvent({ event: 'Top_Users_Step_3' });
  const { currentUser } = useUser();
  const { data: userRecommendations, isLoading } =
    useGetOnboardingUserRecommendationsQuery(currentUser);
  const { mutate: setUserTopUsersMutation } = useSetUserTopUsersMutation();

  const selectedUsers = ref<Principal[]>();

  const totalSelectedUsers = computed(() => selectedUsers.value?.length ?? 0);

  const isContinueDisabled = computed(() => totalSelectedUsers.value < 3);

  const onClickCard = (userId: Principal) => {
    if (selectedUsers.value?.includes(userId)) {
      selectedUsers.value.splice(selectedUsers.value.indexOf(userId), 1);
    } else {
      if (!selectedUsers.value) {
        selectedUsers.value = [userId];
      } else {
        selectedUsers.value?.push(userId);
      }
    }
  };

  const onClickFollow = () => {
    setUserTopUsersMutation({ userIds: selectedUsers.value! });
  };

  watch(userRecommendations, (recommendations) => {
    if (recommendations && recommendations.length > 0) {
      recommendations.forEach((recommendation) => {
        if (recommendation.is_following) {
          if (selectedUsers.value) {
            selectedUsers.value.push(recommendation.user.id);
          } else {
            selectedUsers.value = [recommendation.user.id];
          }
        }
      });
    }
  });
</script>

<template>
  <div v-if="isLoading" class="relative p-40">
    <loader variant="rainbow" border-width="border" size="size-10 md:size-20" />
  </div>
  <content-dialog v-else class="pt-30 md:pt-20">
    <template #title>
      {{ t('onboarding.discoverUsers.title') }}
    </template>
    <template #description>
      {{ t('onboarding.discoverUsers.description') }} <br />
      {{ t('onboarding.chooseAtLeast3') }}
    </template>
    <div
      class="grid grid-cols-2 md:grid-cols-3 auto-cols-[200px] max-h-[450px] overflow-y-auto thin-scrollbar gap-y-18 gap-x-4 pt-13 pb-1 px-1"
    >
      <user-card
        v-for="userRecommendation in userRecommendations"
        :key="userRecommendation.user.id.toText()"
        :class="{
          'ring-2 ring-[#764BA2]': selectedUsers?.includes(
            userRecommendation.user.id,
          ),
          'ring-1 ring-gray-785 ring-opacity-50': !selectedUsers?.includes(
            userRecommendation.user.id,
          ),
        }"
        @click="onClickCard(userRecommendation.user.id)"
      >
        <template #cover>
          <cover-photo
            :src="userRecommendation.user.cover_photo[0]"
            :gradients="coverGradients"
          />
        </template>
        <template #avatar>
          <user-avatar
            :item="userRecommendation.user"
            rounded="rounded-full"
            size="size-16"
          />
        </template>
        <template #username> {{ userRecommendation.user.username }} </template>
        <template #followers>
          <span>{{ formatToUnits(userRecommendation.user.followers) }}</span>
          <span class="hidden md:block">{{
            pluralize(userRecommendation.user.followers, 'follower')
          }}</span>
        </template>
        <template #bio>
          {{ userRecommendation.user.bio }}
        </template>
      </user-card>
    </div>
    <template #actions>
      <div class="flex flex-col gap-1 w-full">
        <continue-button
          :current-step="
            isCompletingSteps
              ? OnboardingLootboxStep.MISSING_DISCOVER_USERS
              : OnboardingStep.DISCOVER_USERS
          "
          :disabled="isContinueDisabled"
          :on-custom-action="onClickFollow"
        >
          {{ t('onboarding.discoverUsers.follow') }}
          {{ totalSelectedUsers > 0 ? totalSelectedUsers : '' }}
        </continue-button>
        <skip-button
          :current-step="
            isCompletingSteps
              ? OnboardingLootboxStep.MISSING_DISCOVER_USERS
              : OnboardingStep.DISCOVER_USERS
          "
        />
      </div>
    </template>
  </content-dialog>
</template>
