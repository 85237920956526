import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getUserByWalletAddressQueryKey } from './keys';

export const useGetUserByWalletAddressQuery = (
  address: Ref<string | undefined>,
) => {
  return useQuery({
    queryKey: getUserByWalletAddressQueryKey(address),
    queryFn: async () => {
      if (!address.value) {
        return null;
      }

      return dscvrApi.wallet.getUserByWalletAddress(address.value);
    },
    enabled: false,
  });
};
