<script setup lang="ts">
  import { computed, ref, type Ref } from 'vue';
  import { useSticky } from '@/composables';
  import {
    useDocumentScroll,
    portalSortFieldTypes,
    type PortalSortField,
  } from '@/shared/model';
  import { BaseCollectionView } from '@/shared/ui/base-collection-view';
  import SearchForm from './SearchForm.vue';
  import SortSwitch from './SortSwitch.vue';
  import ViewSwitch from './ViewSwitch.vue';
  import type { ViewType } from '../types';
  import { useChainBehavior } from '@/entities/user-setting';
  import {
    GridItemLandscape,
    ListItem,
    TableView,
    useSearchPortalsQuery,
  } from '@/entities/portal';
  import { EXPLORE_PORTALS_FEATURED } from '@/common';
  import { type SortColumnDirection } from '@/shared/ui/base-table-view';
  import { getPortalSortBy, getSortDirection } from '@/shared/lib';
  import type { PortalSearchQuery } from 'dfx/edge/edge.did';
  import { uniqBy } from 'lodash-es';

  const props = withDefaults(
    defineProps<{
      showSearch: boolean;
      pageSize?: number;
    }>(),
    {
      pageSize: 8,
    },
  );

  defineEmits<{
    (e: 'back'): void;
  }>();

  const { contentPreset } = useChainBehavior();

  const stickyRef = ref<HTMLElement | null>(null);
  const contentRef = ref<HTMLElement | null>(null);
  const viewType = ref<ViewType>('table');
  const sortBy: Ref<PortalSortField> = ref('memberCount');
  const sortDirection: Ref<SortColumnDirection> = ref('desc');

  const queryParams = computed<PortalSearchQuery>(() => ({
    page: 0n,
    page_size: BigInt(props.pageSize),
    chain_filter: contentPreset.value,
    sort_by: getPortalSortBy(sortBy.value),
    sort_direction: getSortDirection(sortDirection.value),
    query: '',
  }));

  const { isStickyActive } = useSticky(stickyRef, contentRef);
  const {
    data: portalsDto,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSearchPortalsQuery(queryParams);

  const allPortalsPaginated = computed(() => {
    const pages = portalsDto.value?.pages ?? [];
    const portals = pages.flatMap((page) => page?.items ?? []);
    return uniqBy(portals, 'id');
  });

  useDocumentScroll({
    bottomReached: () => {
      if (hasNextPage.value && !isFetchingNextPage.value) {
        fetchNextPage();
      }
    },
  });

  const getSortByLabel = (by: PortalSortField) => {
    switch (by) {
      case 'memberCount':
        return 'Members';
      case 'name':
        return 'Name';
      case 'contentCount':
        return 'Posts';
    }
  };

  const changeSort = (by: PortalSortField, direction: SortColumnDirection) => {
    sortBy.value = by;
    sortDirection.value = direction;
  };
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      ref="stickyRef"
      class="flex justify-center w-full sticky top-18 bg-gray-975 z-40"
    >
      <div
        class="flex justify-between w-full py-8 max-w-screen-lg mx-4 relative flex-wrap"
        :class="{
          'shadow-bottom': viewType !== 'table' && isStickyActive,
        }"
      >
        <h2 class="text-xl font-bold text-white flex gap-1 items-center">
          <base-button
            variant="custom"
            size="x-small"
            :to="{ name: EXPLORE_PORTALS_FEATURED }"
          >
            <base-icon name="chevron-left" size="size-6" />
            <span class="capitalize text-xl">{{ $t('allPortals') }}</span>
          </base-button>
        </h2>
        <div class="flex items-center justify-center gap-2 md:gap-4">
          <transition
            enter-active-class="animate-fade-left"
            leave-active-class="animate-fade animate-reverse animate-duration-200"
          >
            <search-form v-if="showSearch" small class="hidden md:flex" />
          </transition>
          <sort-switch
            v-if="viewType !== 'table'"
            :types="portalSortFieldTypes"
            :sort-by="sortBy"
            :sort-direction="sortDirection"
            @sort="changeSort"
          >
            <template #item="{ by }">
              {{ getSortByLabel(by) }}
            </template>
          </sort-switch>
          <view-switch
            :title="$t('allPortals')"
            v-model="viewType"
            @back="$emit('back')"
          />
        </div>
      </div>
    </div>
    <div ref="contentRef" class="flex flex-col w-full max-w-screen-lg px-4">
      <table-view
        v-if="viewType === 'table'"
        :loading="isLoading"
        :portals="allPortalsPaginated"
        v-model:sort-by="sortBy"
        v-model:sort-direction="sortDirection"
        @sort-changed="true"
      />
      <base-collection-view
        v-else
        :loading="isLoading"
        :items="allPortalsPaginated"
        :type="viewType"
      >
        <template #item="{ item }">
          <template v-if="viewType === 'grid'">
            <grid-item-landscape :portal-view="item" />
          </template>
          <template v-else>
            <list-item :portal-view="item" />
          </template>
        </template>
      </base-collection-view>
    </div>
  </div>
</template>
