<script setup lang="ts">
  import { useUser } from '@/entities/user';
  import { usePostDialog } from '@/features/post';

  withDefaults(
    defineProps<{
      username: string;
      portals?: boolean;
    }>(),
    {
      portals: false,
    },
  );

  const { currentUser } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();
</script>

<template>
  <div class="p-16 text-center text-white shadow-lg bg-gray-950 md:rounded-xl">
    <div
      v-if="currentUser?.username === username"
      class="inset-0 flex flex-col items-center justify-center"
    >
      <span class="mb-2 text-xl font-bold">You haven't posted yet!</span>
      <span class="mb-4">
        Think
        <a
          class="font-bold rainbow-text"
          href="https://youtu.be/9C_HReR_McQ"
          target="_blank"
        >
          CrEaTiVely
        </a>
        and listen to the voices in your brain!
      </span>
      <button
        class="px-8 py-2 text-white bg-indigo-600 rounded-lg"
        @click="openCreateEditPostDialog()"
      >
        Create Post
      </button>
    </div>
    <div v-else class="inset-0 flex flex-col items-center justify-center">
      <span class="mb-2 text-xl font-bold">
        {{
          portals
            ? "User hasn't joined any portals yet"
            : 'User has no posts yet'
        }}
      </span>
    </div>
  </div>
</template>
