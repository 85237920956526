<script setup lang="ts">
  import { onKeyStroke, useVModel } from '@vueuse/core';

  const props = withDefaults(
    defineProps<{
      modelValue: string;
    }>(),
    { modelValue: '' },
  );

  const emit = defineEmits<{
    (e: 'sendMessage', message: string): void;
    (e: 'update:modelValue', value: string): void;
  }>();

  const messageDraft = useVModel(props, 'modelValue', emit);

  /**
   *
   * @param message
   */
  function sendMessage(message: string) {
    emit('sendMessage', message);
    messageDraft.value = '';
  }

  onKeyStroke('Enter', (e) => {
    if (!e.shiftKey) {
      e.preventDefault();
      console.log('SENDING MESSAGE', messageDraft.value);
      sendMessage(messageDraft.value);
    }
  });
</script>

<template>
  <div class="justify-center items-end flex w-full">
    <textarea
      v-model="messageDraft"
      class="block p-2 resize-none bg-gray-990 grow h-10 max-h-12 text-white placeholder:text-gray-400 border-white border-opacity-10 border-1 rounded-2xl focus:ring-0 mr-1 text-sm"
      type="text"
      placeholder="Message"
    />
    <button
      class="bg-gray-990 border border-white border-opacity-10 p-2.75 min-h-10 rounded-2xl cursor-pointer focus:ring-0 hover:bg-gray-800 ml-1"
      @click="sendMessage(messageDraft)"
    >
      <base-icon name="send" size="w-4 h-4" />
    </button>
  </div>
</template>
