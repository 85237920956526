import { defineAsyncComponent, ref, type Ref } from 'vue';
import { isEmpty } from 'lodash-es';
import { solApi } from '@/shared/api';
import { useDialog } from '@/shared/model';
import { useUser } from '@/entities/user';
import { usePostDialog } from '@/features/post';
import { useAuth } from '@/entities/auth';

export const useMagicEdenNft = (nftId?: string) => {
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();
  const { dialogDefaults, openConfiguredDialog } = useDialog();

  const currentSolBalance: Ref<number> = ref(0);

  const fetchNftDetails = async () => {
    if (!nftId) return;
    try {
      const response = await solApi.token.getToken(nftId);
      if (
        !response ||
        isEmpty(response) ||
        (isEmpty(response.nft) && !response.activities.length)
      ) {
        return;
      }

      return response;
    } catch (error) {
      console.log('error:', error);
    }
  };

  const setSolBalance = async (balance: number) => {
    currentSolBalance.value = balance;
  };

  const repost = (tokenId: string) => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    }

    const initialContent = `<preview data-embed="false" type="nft" id="${tokenId}"></preview>`;
    openCreateEditPostDialog({ initialContent });
  };

  const openBuyNftDialog = (options: {
    listing: solApi.collection.MagicEdenAuctionData;
    purchased?: () => void;
  }) => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import(
              '@/features/marketplace/components/MagicEdenBuyDialogContent.vue'
            ),
        ),
        props: {
          listing: options.listing,
        },
        emits: {
          purchased: () => options.purchased && options.purchased(),
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-screen lg:max-w-[380px] lg:min-h-[380px] ease-transition-all`,
        closeOnClickOutside: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
        closeOnClickOutside: false,
      },
    });
  };

  return {
    fetchNftDetails,
    setSolBalance,
    repost,
    currentSolBalance,
    openBuyNftDialog,
  };
};
