import { apiInstance } from '../base';
import type { AppInfoResponse } from './types';

export type { AppInfoResponse, CanvasInfo, FrameInfo } from './types';

const BASE_URL = '/proxy';

export const getEmbeddedApp = (
  url: string,
  customHeaders?: Record<string, string>,
) => {
  return apiInstance
    .get<AppInfoResponse>(`${BASE_URL}/get_embed_app`, {
      params: { url },
      headers: { ...customHeaders },
    })
    .then((response) => response.data);
};

export const createEmbeddedApp = (
  url: string,
  callerId?: string,
  signedBytes: string = '',
  customHeaders?: Record<string, string>,
) => {
  return apiInstance
    .post<AppInfoResponse>(
      `${BASE_URL}/create_embed_app`,
      {
        url,
        caller_id: callerId,
        signed_bytes: signedBytes,
      },
      {
        headers: { ...customHeaders },
        validateStatus: (status) => [200, 400].includes(status),
      },
    )
    .then((response) => response.data);
};
