import type {
  ActionResultPortal,
  PagedQuery,
  PortalHiglights,
  PortalView,
  RandomizedRecommendationQuery,
  UserPortalHighlight,
  ActionResultGetContentPaged,
  ChainTypeResult,
  PortalSearchQuery,
  ActionResultPortalSearch,
  RoleView,
  MemberKind,
  MemberListItemView,
  PortalMemberQuery,
  MemberView,
  UpdatePortalRole,
  ActionResultPortalRole,
  ActionResultUpdatePortalRoleOrdinal,
  GetPortalMemberV2Result,
} from 'dfx/edge/edge.did';
import type {
  AddRemoveMemberRoleMutationParams,
  PortalMembersStatusMutationParams,
  AddPortalRoleMutationParams,
  UpdatePortalRoleOrdinalsMutationParams,
} from './types';
import type { ContentPreset } from '../../../model/edge';
import { actor } from '../base';

export type {
  PortalMembersStatusMutationParams,
  AddRemoveMemberRoleMutationParams,
  AddPortalRoleMutationParams,
  UpdatePortalRoleOrdinalsMutationParams,
};

export const getPortal = (slug: string) => {
  return actor().get_portal(slug) as Promise<ActionResultPortal>;
};

export const getJoinedPortals = () => {
  return actor()
    .get_user_follows()
    .then((res) => res) as Promise<PortalView[]>;
};

export const getFavoritePortals = (contentPreset: ContentPreset) => {
  return actor()
    .list_highlighed_portals(contentPreset)
    .then((res) => res) as Promise<PortalHiglights[]>;
};

export const getRandomizedRecommendedPortals = (
  query: RandomizedRecommendationQuery,
) => {
  return actor()
    .get_randomized_recommended_portals(query)
    .then((res) => res) as Promise<PortalView[]>;
};

export const setFavoritePortals = (portalOrdinals: UserPortalHighlight[]) => {
  return actor().set_highlighted_portals(portalOrdinals) as Promise<
    PortalHiglights[]
  >;
};

export const getPortalContent = (slug: string, query: PagedQuery) => {
  return actor()
    .get_portal_content(slug, query)
    .then((res) => res) as Promise<ActionResultGetContentPaged>;
};

export const getPortalChainType = (portalId: bigint) => {
  return actor()
    .get_portal_chain_type(portalId)
    .then((res) => res) as Promise<ChainTypeResult>;
};

export const followPortalToggle = (portalId: bigint) => {
  return actor()
    .follow_portal_toggle(portalId)
    .then((res) => res) as Promise<ActionResultPortal>;
};

export const searchPortals = (query: PortalSearchQuery) => {
  return actor().search_portals(query) as Promise<ActionResultPortalSearch>;
};

export const getPortalRoles = (portalId: bigint) => {
  return actor().get_portal_roles(portalId) as Promise<RoleView[]>;
};

export const getPortalMemberMemos = (portalId: bigint, userId: bigint) => {
  return actor().get_portal_member_memos(portalId, userId) as Promise<string[]>;
};

export const getPortalMembersByStatus = (
  portalId: bigint,
  memberKind: MemberKind,
) => {
  return actor().get_portal_members_by_status(portalId, memberKind) as Promise<
    MemberListItemView[]
  >;
};

export const setPortalMemberStatus = (
  params: PortalMembersStatusMutationParams,
) => {
  return actor().set_portal_members_status(
    params.portalId,
    params.memberId,
    params.kind,
    params.reason,
  ) as Promise<[] | [MemberListItemView]>;
};

export const getPortalMembers = (params: PortalMemberQuery) => {
  return actor().get_portal_members_v2(
    params,
  ) as Promise<GetPortalMemberV2Result>;
};

export const addMemberRole = (params: AddRemoveMemberRoleMutationParams) => {
  return actor().add_member_role(params.portalId, [
    [params.memberId, params.roleId],
  ]) as Promise<MemberView[]>;
};

export const removeMemberRole = (params: AddRemoveMemberRoleMutationParams) => {
  return actor().remove_member_role(params.portalId, [
    [params.memberId, params.roleId],
  ]) as Promise<MemberView[]>;
};

export const getRoleMembers = (roleId: bigint) => {
  return actor()
    .get_role_members(roleId)
    .then((res) => res) as Promise<MemberListItemView[]>;
};

export const addPortalRole = (params: AddPortalRoleMutationParams) => {
  return actor()
    .add_portal_role(params.portalId, params.role)
    .then((res) => res) as Promise<ActionResultPortalRole>;
};

export const updatePortalRole = (role: UpdatePortalRole) => {
  return actor()
    .update_portal_role(role)
    .then((res) => res) as Promise<ActionResultPortalRole>;
};

export const deletePortalRole = (roleId: bigint) => {
  return actor()
    .delete_portal_role(roleId)
    .then((res) => res) as Promise<ActionResultPortalRole>;
};

export const updatePortalRoleOrdinals = (
  params: UpdatePortalRoleOrdinalsMutationParams,
) => {
  return actor()
    .update_portal_role_ordinals(params.portalId, params.ordinals)
    .then((res) => res) as Promise<ActionResultUpdatePortalRoleOrdinal>;
};
