<script lang="ts" setup>
  import { toRaw } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useDialog } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';

  const { dialogStore, dialogComponent, dialogProps, closeDialog } =
    useDialog();
  const { dialog } = storeToRefs(dialogStore);
</script>

<template>
  <component
    :is="dialogComponent"
    v-bind="dialogProps"
    @update:opened="closeDialog"
  >
    <template v-if="dialog.header" #header>
      <component
        :is="toRaw(dialog.header.component)"
        v-bind="dialog.header.props"
        v-on="dialog.header?.emits ?? {}"
      >
        <template
          v-for="(component, name) in dialog.header?.slots"
          :key="name"
          #[name]="data"
        >
          <component :is="toRaw(component)" v-bind="data" />
        </template>
      </component>
    </template>
    <template v-if="dialog.content?.component" #default>
      <suspense>
        <component
          :is="toRaw(dialog.content?.component)"
          v-bind="dialog.content?.props"
          v-on="dialog.content?.emits ?? {}"
        >
          <template
            v-for="(component, name) in dialog.content?.slots"
            :key="name"
            #[name]="data"
          >
            <component :is="toRaw(component)" v-bind="data" />
          </template>
        </component>
        <template #fallback>
          <div class="relative w-full min-h-48">
            <loader variant="rainbow" border-width="border" size="w-10 h-10" />
          </div>
        </template>
      </suspense>
    </template>
  </component>
</template>
