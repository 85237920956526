import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useOauth2QueryParams = () => {
  // get vue query params
  const route = useRoute();
  const code = computed(() => decodeURIComponent(route.query.code as string));
  const state = computed(() => route.query.state);
  const error = computed(() => route.query.error);

  return {
    code,
    state,
    error,
  };
};
