import { defineStore } from 'pinia';
import { ref } from 'vue';

// This store is used to store the mapping between the collection id and the tensor collection id
export const useTensorStore = defineStore('tensor', () => {
  const tensorCollectionIdMap = ref<Map<string, string>>(new Map());

  const setTensorCollectionIdMap = (collectionId: string, collId: string) => {
    tensorCollectionIdMap.value.set(collectionId, collId);
  };

  return {
    tensorCollectionIdMap,
    setTensorCollectionIdMap,
  };
});
