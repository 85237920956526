import type {
  CreatePaymentLinkRequest,
  StringResult,
  AwardView,
  PostQueueActionResult,
} from 'dfx/edge/edge.did';
import { actor } from '../base';
import type { AwardPostMutationParams } from './types';
export type { AwardPostMutationParams };

export const createCurrencyPaymentLink = (
  request: CreatePaymentLinkRequest,
) => {
  return actor()
    .create_currency_payment_link(request)
    .then((res) => res) as Promise<StringResult>;
};

export const getPurchasableAwards = () => {
  return actor()
    .get_purchasable_awards()
    .then((res) => res) as Promise<AwardView[]>;
};

export const awardPost = (params: AwardPostMutationParams) => {
  return actor()
    .award_post(params.awardId, params.contentId)
    .then((res) => res) as Promise<PostQueueActionResult>;
};
