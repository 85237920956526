<template>
  <a
    class="flex gap-x-2 items-center cursor-pointer no-underline !text-white"
    href="https://tensor.trade"
    target="_blank"
  >
    <base-icon name="tensor" size="w-10 h-10" />
    <span class="font-medium hidden sm:flex break-normal lg:hidden xl:flex">
      Tensor
    </span>
  </a>
</template>
