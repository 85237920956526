<script lang="ts" setup>
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useI18n } from 'vue-i18n';
  import { wisdomSentences } from '@/features/gimluck';

  withDefaults(
    defineProps<{
      points: string;
      username: string;
      lossMessage: string;
    }>(),
    {
      points: '0',
      username: '',
      lossMessage: '0',
    },
  );

  const { t } = useI18n({
    useScope: 'global',
  });
</script>

<template>
  <div
    class="px-4 pt-4 pb-8 w-full shadow-[0px_4px_16px_0px_rgba(0,0,0,0.24)] border border-gray-785 bg-black bg-opacity-50 rounded-xl not-prose"
  >
    <div class="flex flex-col gap-6 w-full max-w-[560px] mx-auto">
      <template v-if="Number(points) > 0">
        <div
          class="relative flex justify-center items-center h-[216px] font-extrabold w-full"
        >
          <div class="absolute bottom-2 left-1/2 -translate-x-1/2 z-1">
            <img
              :src="
                fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/celebration.gif`,
                )
              "
              class="h-50"
              alt="celebration"
            />
          </div>
          <div
            class="flex justify-center items-center rounded-xl z-1 size-full py-12"
          >
            <span
              class="text-[150px] font-extrabold text-transparent bg-clip-text bg-gradient-to-tr from-cyan-500 via-purple-500 to-orange-500"
            >
              {{ points }}
            </span>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row justify-center items-center gap-4 w-full px-4 md:px-16"
        >
          <img
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-small.gif`,
              )
            "
            class="size-16 flex-none"
            alt="Gimluck"
          />
          <div
            class="flex flex-col gap-1 text-xl text-center md:text-left md:text-2xl font-bold tracking-[0.12px]"
          >
            <span>{{ t('gimluck.embedded.theGimluckBestowed') }}</span>
            <span>
              <span class="text-indigo-300">{{ points }} points</span>
              {{ t('to') }} {{ username }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex items-center gap-2 px-4">
          <img
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-small.gif`,
              )
            "
            class="size-16 flex-none"
            alt="Gimluck"
          />
          <div class="flex flex-col gap-2">
            <span class="text-lg tracking-[0.18px] font-semibold">
              {{ t('gimluck.dialogs.loser.content') }}
            </span>
            <span class="font-medium">
              <span class="text-indigo-300">{{ username }}</span>
              {{ t('gimluck.embedded.receivedWisdom') }}
            </span>
          </div>
        </div>
        <div
          class="flex flex-col gap-2 bg-gray-990 border border-gray-785 border-opacity-50 mx-8 py-5 rounded-xl"
        >
          <img
            :src="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/rain.gif`)"
            alt="rainy cloud"
            class="w-[273px] h-auto mx-auto"
          />
          <div class="text-lg tracking-[0.18px] text-center px-8">
            <i>&quot;</i>{{ wisdomSentences[Number(lossMessage)] }}<i>&quot;</i>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
