<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useVModel, onClickOutside } from '@vueuse/core';
  import { BaseBackdrop } from '@/shared/ui/base-backdrop';
  import type { DrawerProps } from './types';

  const props = withDefaults(defineProps<DrawerProps>(), {
    direction: 'right',
    showHeader: true,
    opened: false,
    customClasses: '',
    titleClasses: 'p-4',
    closeOnClickOutside: true,
    backdropBlur: 'backdrop-blur-sm',
    zIndex: 'z-50',
    showBackdrop: true,
    showCloseButton: true,
  });

  const emit = defineEmits<{
    (e: 'update:opened', modelValue: boolean): void;
  }>();

  const drawer = ref<HTMLElement | null>(null);
  const opened = useVModel(props, 'opened', emit);

  const classes = computed(() => {
    const directionClasses = {
      bottom: `top-full left-0 right-0 ${
        opened.value ? '-translate-y-full' : 'translate-y-100'
      }`,
      left: `left-0 top-0 bottom-0 ${
        opened.value ? '-translate-x-0' : '-translate-x-full'
      }`,
      top: `top-0 left-0 right-0 ${
        opened.value ? '-translate-y-0' : '-translate-y-full'
      }`,
      right: `right-0 top-0 bottom-0 ${
        opened.value ? 'translate-x-0' : 'translate-x-full'
      }`,
    };

    return [
      directionClasses[props.direction],
      'transition-translate ease-in duration-300',
      props.customClasses,
      props.zIndex,
    ];
  });

  const titleClass = computed(() => {
    return [props.titleClasses, 'flex justify-between items-center'];
  });

  onClickOutside(drawer, () => {
    if (props.closeOnClickOutside) {
      opened.value = false;
    }
  });
</script>

<template>
  <teleport to="#drawer-view">
    <transition
      v-if="showBackdrop"
      enter-active-class="animate-fade animate-duration-300"
      leave-active-class="animate-fade animate-reverse animate-duration-300"
    >
      <base-backdrop v-if="opened" :class="[zIndex, backdropBlur, margin]" />
    </transition>
    <div
      ref="drawer"
      class="fixed flex flex-col max-h-screen max-w-screen"
      :class="classes"
    >
      <template v-if="showHeader">
        <slot name="header">
          <div :class="titleClass">
            <div>
              <slot name="title" />
            </div>
            <slot name="button">
              <base-button
                v-if="showCloseButton"
                variant="link"
                class="z-10"
                @click="opened = false"
              >
                <base-icon name="x" />
              </base-button>
            </slot>
          </div>
        </slot>
      </template>
      <div
        class="overflow-y-auto thin-scrollbar scrollbar-none flex flex-col h-full"
        :class="contentClasses"
      >
        <slot />
      </div>
    </div>
  </teleport>
</template>
