<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useUser, useMetaUser } from '@/entities/user';
  import { InviteBackground, UserInviteSignUp } from '@/features/invite';
  import type { UserView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    user: string;
  }>();

  const userData = ref<UserView>();

  const route = useRoute();
  const { getUserByUsername } = useUser();

  onMounted(async () => {
    userData.value = await getUserByUsername(props.user);
  });

  useMetaUser(userData);
</script>

<template>
  <div class="h-screen">
    <invite-background />
    <user-invite-sign-up
      v-if="userData"
      :user="userData"
      :code="typeof route.query.ur === 'string' ? route.query.ur : undefined"
    />
  </div>
</template>
