import { useFormatHTMLContent } from '@/composables';
import { dscvrIcApi } from '@/shared/api';

export const useUpdateCommentMutation = async (
  id: bigint,
  unstoredBody: string,
) => {
  const { storeMediaSources } = useFormatHTMLContent();
  const body = await storeMediaSources(unstoredBody);
  return dscvrIcApi.comment.updateComment(id, body);
};
