<script lang="ts" setup>
  import { onMounted } from 'vue';
  import { useAuth } from '@/entities/auth';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';

  const { showLoginSignUpDialog } = useAuth();

  onMounted(() => {
    showLoginSignUpDialog('signup');
  });
</script>

<template>
  <div
    class="fixed size-full bg-repeat bg-center"
    :style="{
      backgroundImage: `url(${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/background/bg-eclipse-x-dscvr-ter.png`,
      )})`,
    }"
  />
</template>
