import { useDeviceStore } from '../../model/store/device/store';
import { storeToRefs } from 'pinia';

export const useDevice = () => {
  const deviceStore = useDeviceStore();
  const {
    userAgent,
    isDesktop,
    isIos,
    isAndroid,
    isMobile,
    isMobileOrTablet,
    isDesktopOrTablet,
    isTablet,
    isWindows,
    isMacOS,
    isApple,
    isSafari,
    isFirefox,
    isEdge,
    isChrome,
    isSamsung,
    isCrawler,
    isEmbedded,
  } = storeToRefs(deviceStore);
  return {
    userAgent,
    isDesktop,
    isIos,
    isAndroid,
    isMobile,
    isMobileOrTablet,
    isDesktopOrTablet,
    isTablet,
    isWindows,
    isMacOS,
    isApple,
    isSafari,
    isFirefox,
    isEdge,
    isChrome,
    isSamsung,
    isCrawler,
    isEmbedded,
  };
};
