<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import {
    SettingsPairMultiple,
    useWalletPairing,
    useMatrica,
    WalletIcon,
  } from '@/entities/wallets';
  import { useDevice } from '@/shared/model';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { trackEvent } from '@/utils/tracker';

  const {
    unlinkMatricaWallet,
    checkLinkStatus,
    getMatricaAuthUrl,
    refreshMatricaWallets,
    isLoading,
    matricaWallets,
  } = useMatrica();
  const { fetchPairedWallets } = useWalletPairing();
  const { isMobile } = useDevice();

  const matricaAddresses = computed(() => {
    return matricaWallets.value.map((wallet) => wallet.address);
  });

  const matricaLinkStatus = ref<boolean>(false);

  const onLinkMatrica = async () => {
    trackEvent('user_settings_action', 'wallet_pair', 'matrica');
    const matricaUrl = await getMatricaAuthUrl();
    if (isMobile.value) {
      // don't open new tab on mobile
      window.location.href = matricaUrl;
    } else {
      window.open(matricaUrl, '_blank');
    }
  };

  const onUnlinkMatrica = async () => {
    // unlink matrica
    trackEvent('user_settings_action', 'wallet_unpair', 'matrica');
    await unlinkMatricaWallet();
    await checkLinkStatus().then((status) => {
      matricaLinkStatus.value = status;
    });
  };

  const onSyncMatrica = async () => {
    trackEvent('user_settings_action', 'wallet_sync', 'matrica');
    await refreshMatricaWallets();
    await fetchPairedWallets();
  };

  onMounted(() => {
    checkLinkStatus().then((status) => {
      matricaLinkStatus.value = status;
    });
  });
</script>

<template>
  <div class="relative mt-4 gap-y-4">
    <overlay-loader v-if="isLoading" />
    <settings-pair-multiple
      name="Matrica"
      :show-sync-button="true"
      :is-paired="matricaLinkStatus"
      :wallet-addresses="matricaAddresses"
      @on-pair="onLinkMatrica"
      @on-unpair="onUnlinkMatrica"
      @on-sync="onSyncMatrica"
      :paired-text="$t('wallets.matrica.linkedText')"
      :unpaired-text="$t('wallets.matrica.unlinkedText')"
    >
      <div class="relative">
        <wallet-icon id="matrica" size="size-10" />
        <base-icon
          name="solana"
          size="size-3"
          class="absolute -right-1 -bottom-1"
        />
      </div>
    </settings-pair-multiple>
  </div>
</template>
