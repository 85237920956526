<template>
  <h3 class="rounded-lg h-9 w-30 bg-gray-950 animate-pulse" />
  <div class="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2 lg:grid-cols-3">
    <div
      v-for="i in 3"
      :key="i"
      class="pb-18 rounded-xl overflow-hidden bg-gray-950 animate-pulse"
    >
      <div class="w-full aspect-square" />
    </div>
  </div>
</template>
