export enum OnboardingStep {
  INTRODUCTORY = 'introductory',
  INTERESTS = 'interests',
  DISCOVER_USERS = 'discover_users',
  JOIN_PORTALS = 'join_portals',
  REWARD_DSCVR_POINTS = 'reward_dscvr_points',
  CLAIM_STREAK = 'claim_streak',
  CLAIM_LOOTBOX = 'claim_lootbox',
  // this is the last step, just to identify that the user is done with onboarding
  // this is going to be used to do the initial check after logging in
  DONE = 'done',
}

export enum OnboardingLootboxStep {
  MISSING_STEPS = 'missing_steps',
  COMPLETE_ONBOARDING_STEPS = 'complete_onboarding_steps',
  MISSING_DISCOVER_USERS = 'missing_discover_users',
  MISSING_JOIN_PORTALS = 'missing_join_portals',
  MISSING_WALLET = 'missing_wallet',
  ONBOARDING_STEPS_COMPLETED = 'onboarding_steps_completed',
  DONE = 'done',
}

export type OnboardingState = {
  currentStep: OnboardingStep;
  completedSteps: OnboardingStep[];
};
