import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { EmbeddedCanvasEditor } from '@/features/canvas';

export const CanvasNode = Node.create({
  name: 'canvas',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      url: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'embedded-canvas',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['embedded-canvas', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(EmbeddedCanvasEditor);
  },
});
