<script lang="ts" setup>
  import type { BaseButtonProps } from '@/shared/ui/base-button';
  import { useUser } from '@/entities/user';
  import { usePostDialog } from '../model/composables/use-post-dialog';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<BaseButtonProps>();

  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();

  const clicked = () => {
    return currentUser.value
      ? openCreateEditPostDialog()
      : showLoginSignUpDialog();
  };
</script>

<template>
  <base-button v-bind="props" @click="clicked">
    <slot />
  </base-button>
</template>
