<script setup lang="ts">
  import { computed, toRef } from 'vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { liveChatModel } from '@/entities/live';
  import { LiveUser } from '@/entities/live';

  const { speakers, listeners, isModerator, moderatorAction } =
    liveChatModel.use();

  interface UsersStageProps {
    showSpeakerTitle?: boolean;
    showRaisedHandListeners?: boolean;
    showAllListeners?: boolean;
    gridClasses?: string;
  }

  const props = withDefaults(defineProps<UsersStageProps>(), {
    showSpeakerTitle: true,
    showRaisedHandListeners: false,
    showAllListeners: false,
    gridClasses: 'grid-cols-3 gap-x-2 gap-y-6',
  });

  const showRaisedHandListeners = toRef(props, 'showRaisedHandListeners');

  const raisedHandsUsers = computed(() => {
    return showRaisedHandListeners.value
      ? listeners.value.filter((user) => user.isHandRaised)
      : [];
  });

  const remainingListeners = computed(() => {
    return showRaisedHandListeners.value
      ? listeners.value.filter((user) => !user.isHandRaised)
      : listeners.value;
  });

  // const manySpeakersTest = computed(() => {
  //   if (speakers.value.length > 0) {
  //     return [
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0],
  //       speakers.value[0]
  //     ];
  //   }
  //   return [];
  // });
</script>

<template>
  <div>
    <div>
      <h2 v-show="showSpeakerTitle" class="px-3 text-sm text-gray-400">
        {{ $t('speakers') }}
      </h2>
      <div class="grid py-3.5" :class="gridClasses.split(' ')">
        <div
          v-if="!speakers.length"
          class="flex flex-col items-center justify-center w-24 py-5 rounded-2.5xl border border-gray-785 border-opacity-50"
        >
          <div
            class="mb-2 border-2 rounded-full w-14 h-14 border-gray-750"
            :style="{
              backgroundImage: `url('{${fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/live/Waiting.png`,
              )}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }"
          ></div>

          <span class="text-xs">{{ $t('waiting') }}...</span>
        </div>
        <!-- Room User -->
        <live-user
          v-for="user in speakers"
          :key="user.peerId"
          :user="user"
          :is-viewer-moderator="isModerator"
          @moderator-action="moderatorAction"
        />
      </div>
    </div>

    <div
      v-show="raisedHandsUsers.length"
      v-if="showRaisedHandListeners"
      class="mt-5"
      :class="{
        'border-t border-white border-opacity-10 pt-6': !showSpeakerTitle,
      }"
    >
      <p class="flex items-center text-sm text-gray-400">
        <base-icon name="hand-raise" size="w-11 h-11" />
        <span class="pr-1 text-yellow-400">{{ raisedHandsUsers.length }}</span>
        <span>{{ $t('raisedHands') }}</span>
      </p>
      <div class="grid py-5" :class="gridClasses.split(' ')">
        <!-- Room User -->
        <live-user
          v-for="user in raisedHandsUsers"
          :key="user.peerId"
          :user="user"
          :is-viewer-moderator="isModerator"
          @moderator-action="moderatorAction"
        />
      </div>
    </div>
    <div
      v-if="showAllListeners"
      class="mt-5"
      :class="{
        'border-t border-white border-opacity-10 pt-6': !showSpeakerTitle,
      }"
    >
      <h2 class="px-4 text-sm text-gray-400">{{ $t('guests') }}</h2>
      <div class="grid py-3.5" :class="gridClasses.split(' ')">
        <!-- Room User -->
        <live-user
          v-for="user in remainingListeners"
          :key="user.peerId"
          :user="user"
          :is-viewer-moderator="isModerator"
          @moderator-action="moderatorAction"
        />
      </div>
    </div>
  </div>
</template>
