<template>
  <div
    class="top-0 left-0 z-10 flex flex-col -mt-4 bg-gray-950 animate-pulse bg-opacity-30 md:bg-transparent md:overflow-hidden md:-mt-0 md:rounded-xl md:static"
  >
    <div
      class="relative top-0 left-0 flex items-end w-full overflow-hidden bg-gray-700 bg-opacity-30 h-42 md:h-62 md:rounded-t-xl"
    >
      <div class="absolute flex mb-3 right-2 gap-x-2 md:hidden">
        <span class="w-3 h-3 loading-item"> </span>
        <span class="w-3 h-3 loading-item"> </span>
      </div>
    </div>

    <!-- mobile -->
    <div class="flex flex-col items-center -mt-10 md:hidden">
      <div class="flex items-end justify-around w-full px-12 mt-4">
        <div class="flex flex-col items-center -mb-2">
          <span class="w-10 loading-item" />
          <span class="w-18 loading-item" />
        </div>
        <div
          class="w-24 h-24 -mt-6 bg-gray-700 rounded-full bg-opacity-40"
        ></div>
        <div class="flex flex-col items-center -mb-2">
          <span class="w-10 loading-item" />
          <span class="w-18 loading-item" />
        </div>
      </div>
      <span class="mt-4 w-28 loading-item" />
    </div>
    <div
      class="left-0 flex items-center justify-around w-full px-6 py-4 mb-2 top-30 gap-x-4 -mt-1px md:hidden"
    >
      <span class="w-18 loading-item" />
      <span class="w-18 loading-item" />
      <span class="w-18 loading-item" />
      <span class="w-18 loading-item" />
    </div>

    <!-- desktop -->
    <div
      class="justify-between hidden w-full bg-opacity-10 md:bg-gray-950 xl:rounded-b-xl md:flex"
    >
      <div
        class="z-10 mb-8 -mt-6 bg-gray-600 rounded-full bg-opacity-40 h-35 min-w-35 ml-7 mr-11"
      ></div>
      <div class="flex flex-col w-full mt-6 mr-6 xl:mb-8">
        <div class="flex justify-between">
          <div>
            <div
              class="w-40 h-6 mb-2 bg-gray-600 rounded-lg bg-opacity-60"
            ></div>
            <div class="flex mt-0.5 font-medium gap-x-4">
              <div class="flex">
                <div class="w-8 h-5 bg-gray-600 rounded-lg bg-opacity-40"></div>
                <div
                  class="w-20 h-5 ml-2 bg-gray-600 rounded-lg bg-opacity-40"
                ></div>
              </div>
              <div class="flex">
                <div class="w-8 h-5 bg-gray-600 rounded-lg bg-opacity-40"></div>
                <div
                  class="w-20 h-5 ml-2 bg-gray-600 rounded-lg bg-opacity-40"
                ></div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-x-2 h-fit">
            <span
              class="mr-1 transition-all duration-300 ease-in-out inline-flex-center gap-x-2 hover:text-gray-400"
            >
              <div
                class="hidden p-4 bg-gray-600 rounded-lg w-22 xl:block bg-opacity-40"
              ></div>
              <div
                class="block w-8 p-3 bg-gray-600 rounded-lg xl:hidden bg-opacity-40"
              ></div>
            </span>
            <div class="p-5 bg-gray-600 rounded-lg w-18 bg-opacity-40"></div>
            <div class="w-20 p-5 bg-gray-600 rounded-lg bg-opacity-40"></div>
            <div class="p-5 bg-gray-600 rounded-lg bg-opacity-40"></div>
          </div>
        </div>
        <div class="flex items-center w-full mt-4 gap-x-4">
          <span class="w-18 loading-item" />
          <span class="w-18 loading-item" />
          <span class="w-18 loading-item" />
          <span class="w-18 loading-item" />
        </div>
      </div>
    </div>
    <div
      class="w-full h-auto px-5 pt-5 pb-8 overflow-hidden md:px-6 md:pt-6 md:pb-3 xl:hidden md:rounded-b-xl md:bg-gray-950 bg-opacity-10"
    >
      <div class="flex flex-col">
        <span class="mb-3 md:mb-1 w-18 loading-item" />

        <div class="flex gap-2">
          <span class="w-18 loading-item" /> <span class="w-12 loading-item" />
          <span class="w-14 loading-item" /> <span class="w-18 loading-item" />
          <span class="w-12 loading-item" /> <span class="w-8 loading-item" />
          <span class="w-16 loading-item" /> <span class="w-20 loading-item" />
          <span class="w-8 loading-item" />
        </div>
      </div>
      <div class="flex flex-col gap-y-4"></div>
    </div>
  </div>
  <div
    class="left-0 z-10 flex justify-between px-4 py-4 font-medium text-gray-400 border-t border-gray-800 animate-pulse top-44 md:border-0 md:mt-3 md:mb-2 md:gap-x-8 md:justify-start md:static"
  >
    <span class="w-14 loading-item" />
    <span class="w-14 loading-item" />
    <span class="w-14 loading-item" />
    <span class="w-14 loading-item" />
    <span class="w-14 loading-item" />
  </div>
</template>

<script>
  export default {};
</script>

<style scoped>
  .loading-item {
    @apply p-3 md:p-4 bg-gray-600 rounded-lg bg-opacity-40;
  }
</style>
