<script lang="ts" setup>
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useDialog } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import { useAuth } from '../../model/composables/use-auth';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ useScope: 'global' });
  const { closeDialog } = useDialog();
  const { login, showLoginSignUpDialog, authEntity } = useAuth();

  const backToLoginDialog = () => {
    closeDialog();
    showLoginSignUpDialog();
  };

  const loginWithUsername = () => {
    authEntity.value.form.type = 'login';
    login('credentials');
  };
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-5">
    <base-button
      variant="link"
      custom-classes="absolute top-10 left-9"
      @click="backToLoginDialog"
    >
      <base-icon name="chevron-left" size="size-5" />
    </base-button>
    <base-button
      v-if="authEntity.context === 'dialog'"
      variant="link"
      custom-classes="absolute top-10 right-9"
    >
      <base-icon name="x" size="size-5" @click="closeDialog" />
    </base-button>
    <img
      v-lazy="{
        src: fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/auth/404.gif`),
      }"
      alt="account not found"
      class="w-[169px]"
    />
    <p class="text-center">
      {{
        authEntity.accountType === 'internet identity'
          ? t('auth.accountNotFound.notFoundWithII')
          : t('auth.accountNotFound.notFoundWithNFID')
      }}
    </p>
    <base-button
      variant="secondary"
      size="medium"
      rounded="rounded-full"
      class="w-full text-sm"
      @click="loginWithUsername"
    >
      <base-icon name="profile-circle" size="size-5" />
      {{ t('auth.accountNotFound.signInWithUsername') }}
    </base-button>
  </div>
</template>
