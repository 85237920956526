<script lang="ts" setup>
  import { computed } from 'vue';
  import { storeToRefs } from 'pinia';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useDialog } from '@/shared/model';
  import { usePersistentStore } from '@/store';
  import { fetchMedia } from '@/shared/lib';

  const { closeDialog } = useDialog();
  const persistentStore = usePersistentStore();
  const { verificationEmail } = storeToRefs(persistentStore);

  const headerImage = computed(() => {
    return verificationEmail.value.sent
      ? fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/streak/email-sent.gif`)
      : fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/streak/verify-email.gif`);
  });
</script>

<template>
  <div class="absolute -top-[110px] right-0 left-0">
    <img
      :src="headerImage"
      class="object-contain w-full flex justify-center size-[220px]"
    />
  </div>
  <div class="absolute top-5 right-4 z-10">
    <base-button variant="link" @click.stop="closeDialog">
      <base-icon name="x" size="w-5 h-5" />
    </base-button>
  </div>
</template>
