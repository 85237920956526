<script setup lang="ts">
  import { computed, onActivated, onMounted, ref } from 'vue';
  import { useRoute, type RouteLocationNamedRaw } from 'vue-router';
  import { useBreakpoint } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import {
    USER_SETTINGS_PORTALS,
    USER_SETTINGS_PORTALS_FAVORITES,
    USER_SETTINGS_PORTALS_MANAGED,
  } from '@/common';
  import { useUser } from '@/entities/user';
  import { isPortalManager, isPortalOwner } from '@/shared/lib';
  import { usePortalUserStatus } from '@/entities/portal';
  import {
    SearchForm,
    FavoritePortalsManagement,
    JoinedPortalList,
  } from './components';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';

  interface TabItem {
    key: 'joined' | 'favorites' | 'managed';
    label: string;
    route: RouteLocationNamedRaw;
  }

  const props = defineProps<{
    username: string;
    user: UserView;
  }>();

  const route = useRoute();
  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanSm } = useBreakpoint();
  const { currentUser } = useUser();
  const { favorites, isFavoritesLoading, joined } = usePortalUserStatus();

  const searchFilter = ref('');

  const joinedNonSelfPortals = computed(() => {
    return joined.value?.filter((portal) => {
      const portalType = portal?.portal_type[0];
      if (!portalType) {
        return false;
      }
      return 'Portal' in portalType;
    });
  });

  const ownedManagedPortals = computed(() => {
    return joinedNonSelfPortals.value?.filter(
      (portal) =>
        isPortalOwner(portal, currentUser.value) || isPortalManager(portal),
    );
  });

  const filteredJoinedPortals = computed(() =>
    joinedNonSelfPortals.value?.filter((portal) =>
      isMatch(portal, searchFilter.value),
    ),
  );

  const filteredFavoritePortals = computed(() =>
    favorites.value?.filter((portal) => isMatch(portal, searchFilter.value)),
  );

  const filteredManagedPortals = computed(() =>
    ownedManagedPortals.value?.filter((portal) =>
      isMatch(portal, searchFilter.value),
    ),
  );

  const filteredPortals = computed(() => {
    if (selectedTab.value.key === 'managed') {
      return filteredManagedPortals.value;
    }
    if (selectedTab.value.key === 'favorites') {
      return filteredFavoritePortals.value;
    }
    return filteredJoinedPortals.value;
  });

  const tabs = computed<TabItem[]>(() => {
    return [
      {
        key: 'joined',
        label: `${joinedNonSelfPortals.value?.length ?? 0} ${t('joined')}`,
        route: {
          name: USER_SETTINGS_PORTALS,
          params: {
            username: props.username,
          },
        },
      },
      {
        key: 'favorites',
        label: `${favorites.value?.length ?? 0} ${t('favorites')}`,
        route: {
          name: USER_SETTINGS_PORTALS_FAVORITES,
          params: {
            username: props.username,
          },
        },
      },
      {
        key: 'managed',
        label: `${ownedManagedPortals.value?.length ?? 0} ${t('managed')}`,
        route: {
          name: USER_SETTINGS_PORTALS_MANAGED,
          params: {
            username: props.username,
          },
        },
      },
    ];
  });

  const selectedTab = ref<TabItem>(tabs.value[0]);

  const isMatch = (portal: PortalView, search: string) => {
    return (
      !search ||
      portal.name.toLowerCase().includes(search.toLowerCase()) ||
      portal.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  const loadSelectedTabByRoute = () => {
    const tab = tabs.value.find((tab) => tab.route.name === route.name);
    if (tab) {
      selectedTab.value = tab;
    }
  };

  onMounted(() => {
    loadSelectedTabByRoute();
  });

  onActivated(() => {
    loadSelectedTabByRoute();
  });
</script>

<template>
  <div class="flex justify-between mb-10">
    <span class="text-2xl font-bold">{{ $t('portals') }}</span>
    <search-form v-if="isSmallerThanSm" v-model="searchFilter" />
  </div>

  <div class="flex flex-col gap-y-4">
    <div class="flex justify-between">
      <div class="flex gap-4 text-gray-400 text-sm">
        <base-button
          v-for="tab in tabs"
          :key="tab.key"
          variant="custom"
          rounded="rounded-none"
          class="font-medium py-2.5 border-b-2 border-transparent w-fit min-w-24 hover:text-white transition-all duration-300 capitalize cursor-pointer"
          :class="{ 'text-white border-white': selectedTab.key === tab.key }"
          :to="tab.route"
          @click="selectedTab = tab"
        >
          {{ tab.label }}
        </base-button>
      </div>
      <search-form v-if="!isSmallerThanSm" v-model="searchFilter" />
    </div>

    <div v-if="isFavoritesLoading" class="spinner-big" />
    <div v-else class="flex flex-col gap-4">
      <section v-if="filteredPortals?.length" class="mt-10">
        <joined-portal-list
          v-if="selectedTab.key !== 'favorites'"
          :portals="filteredPortals"
        />
        <favorite-portals-management
          v-else
          :portals="filteredPortals"
          :filtered="searchFilter.length > 0"
        />
      </section>
    </div>
  </div>
</template>
