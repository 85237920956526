<script lang="ts" setup>
  import type { ToastProps, ToastTypeColor } from './types';
  import { computed, onMounted, ref } from 'vue';
  import { compact } from 'lodash-es';

  const props = withDefaults(defineProps<ToastProps>(), {
    placement: 'bottom-right',
    position: 'fixed',
    timed: true,
    durationSeconds: 5,
    showCloseButton: true,
  });

  const emits = defineEmits<{
    (e: 'close'): void;
  }>();

  const timeoutId = ref<NodeJS.Timeout | null>(null);

  const toastTypeColor = computed<ToastTypeColor>(() => {
    if (props.type === 'error')
      return {
        background: 'bg-red-400',
        text: 'text-red-400',
        border: 'border-red-400',
      };
    else if (props.type === 'success')
      return {
        background: 'bg-green-400',
        text: 'text-green-400',
        border: 'border-green-400',
      };
    else if (props.type === 'warning')
      return {
        background: 'bg-orange-400',
        text: 'text-orange-400',
        border: 'border-orange-400',
      };
    else if (props.type === 'info') {
      return {
        background: 'bg-blue-500',
        text: 'text-blue-500',
        border: 'border-blue-500',
      };
    }
    return (
      props.customClasses ?? {
        background: '',
        text: '',
        border: '',
      }
    );
  });

  const toastPlacement = computed(() => {
    return compact([
      props.position !== 'relative' && props.placement,
      props.position,
    ]);
  });

  const toastStyle = computed(() => {
    if (props.variant === 'colored') {
      return `${toastTypeColor.value.background} ${
        props.border ?? 'rounded-2xl'
      } ${props.outerWidth ? props.outerWidth : 'min-w-[392px]'}`;
    }
    return `bg-gray-975 border border-gray-785 border-opacity-50 ${
      props.border ?? 'rounded-lg'
    } ${props.outerWidth ? props.outerWidth : 'min-w-full md:min-w-[360px]'}
    ${props.zIndex ?? 'z-[1000]'}`;
  });

  onMounted(() => {
    if (props.timed && props.durationSeconds) {
      timeoutId.value = setTimeout(close, props.durationSeconds * 1000);
    }
  });

  const close = () => {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }
    emits('close');
  };
</script>

<template>
  <div class="overflow-hidden toast" :class="[toastStyle, toastPlacement]">
    <div
      class="relative flex gap-4 py-3 px-[1.2rem] mx-auto"
      :class="[
        variant === 'bordered' && `border-l-4 ${toastTypeColor.border}`,
        variant === 'bordered' && !$slots.button
          ? 'items-start justify-between'
          : 'items-center justify-between',
        props.innerWidth,
        props.innerHeight,
      ]"
    >
      <slot v-if="$slots.image" name="image" />
      <slot name="icon">
        <base-icon
          v-if="iconName && !image"
          :name="iconName"
          size="w-4 h-4"
          :class="[
            variant === 'bordered' ? `${toastTypeColor.text}` : 'text-white',
          ]"
        />
      </slot>
      <slot name="content">
        <div
          class="flex flex-col flex-1 text-sm md:max-w-60"
          :class="image ? 'py-2 gap-y-2' : ''"
        >
          <div
            v-if="$slots.title"
            class="font-semibold text-white break-words flex gap-2"
          >
            <base-icon
              v-if="iconName && image"
              :name="iconName"
              size="w-5 h-5"
              :class="[
                variant === 'bordered'
                  ? `${toastTypeColor.text}`
                  : 'text-white',
                'flex-none',
              ]"
            />
            <slot name="title" />
          </div>
          <div
            v-if="$slots.description"
            class="text-gray-300 break-words max-w-60"
          >
            <slot name="description" />
          </div>
          <div v-if="$slots.link" class="text-gray-300 break-all">
            <slot name="link" />
          </div>
          <div v-if="$slots.buttons" class="flex gap-4 mt-2">
            <slot name="buttons" />
          </div>
        </div>
        <div v-if="$slots.button" class="text-sm self-center">
          <slot name="button" />
        </div>
        <base-icon
          v-if="showCloseButton"
          name="close"
          size="w-4 h-4"
          class="cursor-pointer z-1"
          :class="[
            variant === 'bordered' ? 'text-gray-400' : 'text-white',
            { 'self-start': $slots.buttons },
          ]"
          @click="close"
        />
      </slot>
    </div>
  </div>
</template>

<style scoped>
  .top-center,
  .bottom-center {
    @apply md:left-1/2 md:-translate-x-1/2;
  }
  .top-center {
    @apply top-20 left-0 md:left-1/2;
  }
  .top-right {
    @apply top-20 right-0 md:right-10;
  }
  .top-left {
    @apply top-20 left-0 md:left-10;
  }
  .bottom-center {
    @apply bottom-10 left-0 md:left-1/2;
  }
  .bottom-right {
    @apply bottom-0 right-0 md:bottom-10 md:right-10;
  }
  .bottom-left {
    @apply bottom-10 left-0 md:left-10;
  }
</style>
