<script lang="ts" setup>
  import { useToast } from '@/shared/model';
  import type { BaseButtonProps } from '@/shared/ui/base-button';
  import { useI18n } from 'vue-i18n';

  const props = defineProps<
    BaseButtonProps & {
      link: string;
    }
  >();

  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const copyReferralLink = () => {
    navigator.clipboard.writeText(props.link);
    showToast({
      type: 'success',
      title: t('referralFeature.copyLink'),
      durationSeconds: 5,
    });
  };
</script>

<template>
  <base-button v-bind="props" @click="copyReferralLink">
    <slot name="icon">
      <base-icon name="invite" size="w-5 h-5" />
    </slot>
    <span>
      <slot />
    </span>
  </base-button>
</template>
