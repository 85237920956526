<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL, IS_HIDDEN } from '@/common';
  import { OnboardingStep } from '@/shared/model';
  import ContinueButton from '../ContinueButton.vue';
  import ContentDialog from '../../ui/Content.vue';
  import { useBreakpoint } from '@/shared/model';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { config } from '@/shared/lib';

  const { t } = useI18n({
    useScope: 'global',
  });
  useGtm()?.trackEvent({ event: 'Welcome_Step_1' });

  const { isSmallerThanMd } = useBreakpoint();
</script>

<template>
  <div v-if="!isSmallerThanMd" class="absolute -top-10 -left-30 -z-1">
    <img
      :src="
        fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/welcome-confettis.gif`,
        )
      "
    />
  </div>
  <div v-if="!isSmallerThanMd" class="absolute top-30 -right-30 -z-1">
    <img
      :src="
        fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/welcome-confettis.gif`,
        )
      "
    />
  </div>
  <content-dialog class="py-8">
    <template #title>
      {{ t('onboarding.introductory.title') }}
    </template>
    <template #description>
      {{ t('onboarding.introductory.description') }}
    </template>
    <div
      class="flex flex-col gap-4 border border-white border-opacity-8 rounded-lg p-6 text-gray-200"
    >
      <div class="flex gap-4 justify-center items-center">
        <img
          :src="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/group.svg`)
          "
          class="size-10.5"
        />
        <div class="flex flex-col gap-1">
          <div class="font-bold">
            {{ t('onboarding.introductory.rewards.title') }}
          </div>
          <div class="text-sm">
            {{ t('onboarding.introductory.rewards.description') }}
          </div>
        </div>
      </div>
      <div
        v-if="config.ENABLE_ONBOARDING_LOOTBOX"
        class="flex gap-4 justify-center items-center"
      >
        <img
          :src="
            fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/treasure-chest.svg`,
            )
          "
          class="size-10.5"
        />
        <div class="flex flex-col gap-1">
          <div class="font-bold">
            {{ t('onboarding.introductory.lootbox.title') }}
          </div>
          <div class="text-sm">
            {{ t('onboarding.introductory.lootbox.description') }}
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <continue-button :current-step="OnboardingStep.INTRODUCTORY" />
    </template>
  </content-dialog>
</template>
