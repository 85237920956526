import ubiServices from '../../services/ubi';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';

// initial state
const state = () => ({
  isLoading: false,
  claimTime: null,
  successfullyClaimed: false,
});

// getters
const getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
  successfullyClaimed: (state) => {
    return state.successfullyClaimed;
  },
  claimTime: (state) => {
    return state.claimTime;
  },
};

// actions
const actions = {
  [ActionTypes.GET_NEXT_REWARD]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return ubiServices
      .get_next_reward()
      .then((response) => {
        commit(MutationTypes.SET_UBI_CLAIMED_TIME, response[0]);
      })
      .finally(() => commit(MutationTypes.SET_IS_LOADING, false));
  },
  [ActionTypes.CLAIM_REWARD]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return ubiServices
      .claim_reward()
      .then((response) => {
        commit(MutationTypes.SET_UBI_CLAIMED, response);
      })
      .finally(() => commit(MutationTypes.SET_IS_LOADING, false));
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_UBI_CLAIMED_TIME](state, val) {
    state.claimTime = val;
  },
  [MutationTypes.SET_UBI_CLAIMED](state, val) {
    state.successfullyClaimed = val;
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
