<template>
  <div v-if="!proposalCreated" class="relative">
    <area-loader v-if="isLoading" :rounded="false" class="rounded-full" />

    <form
      class="h-full py-4 text-gray-100 md:pb-0 md:h-auto"
      @submit.prevent="onSubmit"
    >
      <error-display
        class="break-words"
        :errors="errors"
        :error-message="errorMessage"
        :local-errors="localErrors"
      />

      <div class="flex flex-col h-full gap-1 md:h-auto">
        <label class="block">
          <input
            v-model="proposal.title"
            type="text"
            class="block w-full mt-1 border-transparent rounded-md bg-gray-950 md:bg-gray-900 focus:bg-gray-700 focus:ring-0"
            placeholder="Title"
          />
        </label>

        <label class="block">
          <input
            v-model="proposal.url"
            type="text"
            class="block w-full mt-1 border-transparent rounded-md bg-gray-950 md:bg-gray-900 focus:bg-gray-700 focus:ring-0"
            placeholder="Url (Optional)"
          />
        </label>

        <label class="block">
          <textarea
            v-model="proposal.summary"
            placeholder="Summary"
            class="block w-full mt-1 border-transparent rounded-md resize-none bg-gray-950 scrollbar-hide md:bg-gray-900 focus:bg-gray-700 focus:ring-0"
            rows="3"
            @input="
              (e) => {
                mixin_autoResize_resize(e);
              }
            "
          ></textarea>
        </label>

        <label for="">
          <span class="float-right text-sm text-gray-400">
            <a
              href="https://guides.github.com/features/mastering-markdown/"
              class="p-1 border border-gray-400 rounded-lg"
              target="_blank"
            >
              markdown guide
            </a>
          </span>
        </label>

        <label class="block">
          <div class="mt-4">Action: Motion</div>
        </label>

        <label class="block">
          <textarea
            v-model="proposal.action.Motion.motion_text"
            placeholder="Motion text"
            class="block w-full mt-1 border-transparent rounded-md resize-none bg-gray-950 scrollbar-hide md:bg-gray-900 focus:bg-gray-700 focus:ring-0"
            rows="3"
            @input="
              (e) => {
                mixin_autoResize_resize(e);
              }
            "
          ></textarea>
        </label>

        <div class="p-4 mt-2 rounded-md bg-gray-785">
          <div class="flex justify-between text-base font-semibold text-white">
            <span>Rejection Cost</span>
            <div class="flex items-center gap-1">
              <img
                class="inline size-5 mr-1"
                :src="
                  fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/sns.png`)
                "
              />
              0.1 <span class="text-sm font-normal text-gray-400">SNS-1</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-auto md:flex-row md:justify-end md:mt-3">
          <form-button
            class="py-3 text-base md:text-sm md:py-2"
            text="Create Proposal"
            :is-disabled="formDisabled"
            :form-ready="!formDisabled"
          />
        </div>
      </div>
    </form>
  </div>
  <div v-else-if="proposalCreated">
    <div class="p-4 text-center bg-gray-700 rounded-lg min-h-40">
      <div class="mt-4 text-lg font-bold">
        Proposal Created: Id {{ proposalCreated.proposal_id[0].id }}
      </div>

      <div class="my-4">
        The SNS-1 Proposal will show up in the SNS Portal in a few moments
      </div>

      <router-link
        :to="{
          name: PORTAL_CONTENT,
          params: {
            slug: 'sns1',
          },
        }"
        class="hover:underline"
      >
        SNS-1 Portal
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FormButton from '../buttons/FormButton.vue';
  import mixinAutoResize from '../mixins/autoResize.js';
  import ErrorDisplay from '../ErrorDisplay.vue';
  import AreaLoader from '../AreaLoader.vue';
  import { SNSGovernance } from '@/utils/sns.governance';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL, PORTAL_CONTENT } from '@/common';

  const PROPOSAL_TITLE_BYTES_MIN = 5;
  const PROPOSAL_TITLE_BYTES_MAX = 256;
  const PROPOSAL_SUMMARY_BYTES_MAX = 15000;
  const PROPOSAL_URL_CHAR_MAX = 2048;
  const PROPOSAL_MOTION_TEXT_BYTES_MAX = 10000;

  const byteSize = (str) => new Blob([str]).size;

  const fromHexString = (hex) => {
    if (hex.substr(0, 2) === '0x') hex = hex.substr(2);
    const bytes = [];
    for (let c = 0; c < hex.length; c += 2)
      bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
  };

  export default {
    name: 'create-proposal-form',
    components: {
      FormButton,
      ErrorDisplay,
      AreaLoader,
    },
    mixins: [mixinAutoResize],
    props: {
      neuronId: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        PORTAL_CONTENT,
        DSCVR_STATIC_ASSETS_CDN_URL,
        fetchMedia,
        isLoading: false,
        formDisabled: false,
        errors: [],
        proposalCreated: null,
        proposal: {
          url: '',
          title: '',
          action: {
            Motion: {
              motion_text: '',
            },
          },
          summary: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
    },
    mounted() {
      console.log(this.neuronId);
    },
    methods: {
      isFormValid() {
        this.errors = [];

        if (byteSize(this.proposal.title) < PROPOSAL_TITLE_BYTES_MIN) {
          this.errors.push(
            `Proposal Title is minimum ${PROPOSAL_TITLE_BYTES_MIN} characters`,
          );
        }

        if (byteSize(this.proposal.title) > PROPOSAL_TITLE_BYTES_MAX) {
          this.errors.push(
            `Proposal Title is max ${PROPOSAL_TITLE_BYTES_MAX} characters`,
          );
        }

        if (byteSize(this.proposal.summary) > PROPOSAL_SUMMARY_BYTES_MAX) {
          this.errors.push(
            `Proposal Summary is max ${PROPOSAL_SUMMARY_BYTES_MAX} characters`,
          );
        }

        if (this.proposal.url > PROPOSAL_URL_CHAR_MAX) {
          this.errors.push(
            `Proposal URL is max ${PROPOSAL_URL_CHAR_MAX} characters`,
          );
        }

        if (
          byteSize(this.proposal.action.Motion.motion_text) >
          PROPOSAL_MOTION_TEXT_BYTES_MAX
        ) {
          this.errors.push(
            `Proposal URL is max ${PROPOSAL_MOTION_TEXT_BYTES_MAX} characters`,
          );
        }
        return this.errors.length == 0;
      },
      async onSubmit() {
        console.log(fromHexString(this.neuronId));
        if (this.isFormValid()) {
          this.isLoading = true;
          const snsGovernance = new SNSGovernance();
          await snsGovernance.create('SNS-1');
          console.log('Form submit');
          console.log(fromHexString(this.neuronId), this.proposal);

          const results = await snsGovernance.createProposal(
            fromHexString(this.neuronId),
            this.proposal,
          );
          //let results = await mockSNSSuccess(2000);

          console.log('Create results', results);
          const commandResults = results.command[0];

          if ('Error' in commandResults) {
            console.log(commandResults);
            const errorReport = `SNS: ${commandResults.Error.error_message} - Error Type: ${commandResults.Error.error_type}`;
            this.errors.push(errorReport);
          } else if ('MakeProposal' in commandResults) {
            this.proposalCreated = commandResults.MakeProposal;
          }

          this.isLoading = false;
        }
      },
    },
  };
</script>

<style scoped>
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
