<script setup lang="ts">
  import { ref, type Ref } from 'vue';
  import tippy from 'tippy.js';
  import { useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { useAuth } from '@/entities/auth';
  import { WysiwygEditor } from './wysiwyg-editor';
  import { useFileUploadDialog } from '@/entities/file-upload';
  import 'tippy.js/dist/tippy.css';
  import 'tippy.js/dist/svg-arrow.css';

  const props = withDefaults(
    defineProps<{
      body?: string;
      editing?: boolean;
      autofocus?: boolean;
      isQuickViewComment?: boolean;
      isQuickViewReply?: boolean;
    }>(),
    {
      body: '',
      editing: false,
      autofocus: false,
    },
  );

  const emit = defineEmits<{
    (e: 'submit', body: string): void;
  }>();
  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const { openEditorMediaEmbedDialog, closeDialog } = useFileUploadDialog();
  const commentInput: Ref<InstanceType<typeof WysiwygEditor> | null> =
    ref(null);
  const { showToast } = useToast();
  const modelValue = ref(props.body);

  const hoverMenuItem = (e: MouseEvent, text: string) => {
    tippy(e.target as Element, {
      animation: 'fade',
      placement: 'bottom',
      content: text,
    });
  };

  const reset = () => {
    commentInput.value?.reset();
  };

  const onSubmit = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else if (!commentInput.value?.isEmpty) {
      emit('submit', modelValue.value);
    }
  };

  const handleMediaUpload = () => {
    openEditorMediaEmbedDialog(
      (files) => commentInput.value?.uploadFiles(files),
      (src) => commentInput.value?.pasteImage(src),
    );
  };

  defineExpose({
    reset,
    isFocused: commentInput.value?.isFocused,
  });
</script>

<template>
  <form
    class="flex overflow-hidden rounded-lg w-fill comment-input"
    :class="[
      isQuickViewComment ? '' : 'flex-col',
      isQuickViewReply
        ? 'my-4 bg-black rounded-xl w-full border border-gray-800'
        : '',
    ]"
    @submit.stop.prevent="onSubmit"
  >
    <div
      class="tip-tap px-4 py-1 overflow-y-auto thin-scrollbar"
      :class="[
        isQuickViewComment && !isQuickViewReply
          ? 'border border-gray-800 rounded-xl'
          : 'bg-gray-900 min-h-20 max-h-60',
        isQuickViewComment ? 'bg-black bg-opacity-40 w-full' : '',
      ]"
    >
      <wysiwyg-editor
        ref="commentInput"
        context="comment"
        placeholder="Add a comment..."
        :autofocus="autofocus"
        v-model="modelValue"
        @media-inserted="closeDialog"
        @error="showToast({ type: 'error', title: $event, durationSeconds: 5 })"
      />
    </div>
    <div
      class="flex flex-row justify-between ww-options"
      :class="[isQuickViewComment ? 'ml-2' : 'bg-gray-900 p-2']"
    >
      <ul
        v-if="!isQuickViewComment"
        class="flex flex-row w-1/3 p-2 text-gray-400 gap-x-6"
      >
        <li
          class="editor-action"
          @mouseover.stop="(event) => hoverMenuItem(event, 'Media')"
          @click="handleMediaUpload"
        >
          <base-icon
            name="image-upload"
            size="w-4 h-4"
            class="pointer-events-none"
          />
        </li>
      </ul>
      <button
        v-if="isQuickViewComment"
        type="submit"
        class="flex bg-gray-975 rounded-2xl border border-white border-opacity-10 p-3 hover:border-opacity-20 hover:bg-white/[0.05]"
        :class="isQuickViewReply ? 'self-end mr-2 mb-2' : 'self-center'"
      >
        <base-icon name="send" />
      </button>
      <button v-else ref="postButton" class="post-button">
        <span>
          {{ !editing ? 'Post' : 'Update' }}
        </span>
      </button>
    </div>
  </form>
</template>

<style lang="postcss" scoped>
  .editor-action {
    @apply pb-1 cursor-pointer hover:text-gray-200;
  }
  .post-button {
    @apply py-1.5 font-medium text-white text-sm bg-indigo-600 rounded-md w-20 hover:bg-indigo-500 relative;
  }
  .spinner::before {
    margin-top: -9px !important;
    margin-left: -6px !important;
  }
</style>
