import type { SocialLink } from '../types';

export const SOCIAL_LINKS: SocialLink[] = [
  {
    name: 'Facebook',
    icon: 'social-facebook-desaturated',
    url: (v: string, referral: string) =>
      `https://www.facebook.com/sharer.php?u=${referral}`,
  },
  {
    name: 'Twitter',
    icon: 'social-twitter-desaturated',
    url: (v: string, referral: string, isUser = false) =>
      !isUser
        ? `https://twitter.com/intent/tweet?text=Check%20out%20the%20${v}%20portal%20on%20DSCVR&url=${referral}`
        : `https://twitter.com/intent/tweet?text=Check%20out%20${v}%27s%20profile%20on%20DSCVR&url=${referral}`,
  },
  {
    name: 'LinkedIn',
    icon: 'social-linkedIn-desaturated',
    url: (v: string, referral: string, isUser = false) =>
      !isUser
        ? `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            referral,
          )}&title=Check%20out%20the%20${v}%20portal%20on%20DSCVR`
        : `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            referral,
          )}&title=Check%20out%20${v}%27s%20profile%20on%20DSCVR`,
  },
];
