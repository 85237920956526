import { dscvrApi } from '@/shared/api';
import type { PairDeeplinkParams } from '../model/types/pairing';

export const generateMobileDeeplink = (
  walletType: dscvrApi.wallet.WalletType,
  { dscvrPk, nonce, otpCode }: PairDeeplinkParams,
) => {
  try {
    const { browseUrl, requestUrl } = getMobileDeeplinkPairParams(walletType, {
      otpCode,
      dscvrPk,
      nonce,
    });
    return getMobilePairDeeplink(walletType, browseUrl, requestUrl);
  } catch {
    throw new Error('Failed to generate deeplink url');
  }
};

const getMobileDeeplinkPairParams = (
  walletType: dscvrApi.wallet.WalletType,
  { dscvrPk, nonce, otpCode }: PairDeeplinkParams,
) => {
  const { origin, href } = window.location;
  const url = new URL(`${origin}/mobile/wallet-pairing`);
  url.searchParams.set('walletType', walletType);
  url.searchParams.set('otpCode', otpCode);
  url.searchParams.set('dscvrPk', dscvrPk);
  url.searchParams.set('nonce', btoa(nonce));

  return {
    browseUrl: url.toString(),
    requestUrl: href,
  };
};

export const getMobilePairDeeplink = (
  walletType: dscvrApi.wallet.WalletType,
  browseUrl: string,
  requestUrl: string,
) => {
  const encodedBrowseUrl = encodeURIComponent(browseUrl);
  const encodedRequestUrl = encodeURIComponent(requestUrl);
  switch (walletType) {
    case dscvrApi.wallet.WALLET_TYPES.phantom: {
      const origin = `https://phantom.app/ul/browse`;
      const url = new URL(`${origin}/${encodedBrowseUrl}`);
      url.searchParams.set('ref', encodedRequestUrl);
      return url.toString();
    }
    case dscvrApi.wallet.WALLET_TYPES.solflare: {
      const origin = `https://solflare.com/ul/v1/browse`;
      const url = new URL(`${origin}/${encodedBrowseUrl}`);
      url.searchParams.set('ref', encodedRequestUrl);
      return url.toString();
    }
    case dscvrApi.wallet.WALLET_TYPES.metamask: {
      const origin = `https://metamask.app.link/dapp`;
      // Important, for Metamask browseUrl, should NOT be encoded
      return `${origin}/${browseUrl}`;
    }
    default:
      throw new Error(`Unsupported wallet: ${walletType}`);
  }
};
