import { Node, mergeAttributes } from '@tiptap/core';

const Video = Node.create({
  name: 'video',
  group: 'block',
  selectable: true,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-src': attributes.src,
            src: `${attributes.src}#t=0.1`,
            controls: true,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(HTMLAttributes)];
  },
});

export default Video;
