import type { AxiosResponse } from 'axios';
import { apiInstance } from '../base';
import type { TipTx, TippableTokenListItem } from '@/shared/model/tip';
import type {
  TipResultItems,
  GetTipTxPayload,
  AddTipTxPayload,
  TipResultItem,
} from './types';
export type { TipResultItem, TipResultItems, GetTipTxPayload, AddTipTxPayload };

const BASE_URL = '/solana/tip';

export const getTippableTokens = () => {
  return apiInstance
    .get<AxiosResponse<TippableTokenListItem[]>>(`${BASE_URL}/list`)
    .then((res) => res.data.data);
};

export const prepareTipTx = (payload: GetTipTxPayload) => {
  return apiInstance
    .post<AxiosResponse<TipTx>>(`${BASE_URL}/tx/${payload.tipId}`, {
      to: payload.to,
      from: payload.from,
      amount: payload.amount,
    })
    .then((res) => res.data.data);
};

export const addTipTx = (payload: AddTipTxPayload) => {
  return apiInstance
    .post<AxiosResponse<TipTx>>(`${BASE_URL}/add/${payload.tipId}`, payload)
    .then((res) => res.data.data);
};

export const getContentTips = (contentIds: bigint[]) => {
  return apiInstance
    .post<AxiosResponse<TipResultItems>>(`${BASE_URL}/content/tips`, {
      contentIds: contentIds.map((id) => id.toString()),
    })
    .then((res) => {
      return res.data.data;
    });
};
