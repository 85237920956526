<script lang="ts" setup>
  import { useRouter } from 'vue-router';
  import { USER_WALLETS } from '@/common';
  import { useDialog } from '@/shared/model';

  const props = defineProps<{
    username: string;
  }>();

  const router = useRouter();
  const { closeDialog } = useDialog();

  const onClick = () => {
    router.push({
      name: USER_WALLETS,
      params: {
        username: props.username,
      },
    });
    closeDialog();
  };
</script>

<template>
  <div class="flex flex-col justify-center items-center gap-4 pt-8 pb-10 px-6">
    <div class="font-semibold tracking-[-0.12px] text-2xl">
      {{ $t('wallets.successfullyPaired.successfullyConnected') }}
    </div>
    <base-button variant="primary" size="full" @click="onClick">
      {{ $t('wallets.successfullyPaired.takeMeToMyDscvrWallet') }}
    </base-button>
  </div>
</template>
