<script setup lang="ts">
  import { ref, type Ref } from 'vue';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { WysiwygEditor } from '@/components/forms/wysiwyg-editor';
  import { useFileUploadDialog } from '@/entities/file-upload';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { useUser } from '@/entities/user';

  withDefaults(
    defineProps<{
      placeholder?: string;
    }>(),
    {
      placeholder: 'Leave a comment',
    },
  );

  const emit = defineEmits<{
    (e: 'submit', body: string): void;
    (e: 'error', message: string): void;
  }>();

  const animateEmptyMessage = ref(false);
  let animateEmptyMessageTimeoutId: NodeJS.Timeout | null = null;
  const editorInput: Ref<InstanceType<typeof WysiwygEditor> | null> = ref(null);
  const contentBody = ref('');
  const statusMessage = ref<string | null>(null);
  const showEditor = ref(false);
  const isSaving = ref(false);
  const statusMessageTimeoutId = ref<NodeJS.Timeout | null>(null);
  const { currentUser } = useUser();
  const { openEditorMediaEmbedDialog, closeDialog } = useFileUploadDialog();

  const submit = (body: string, empty: boolean) => {
    if (empty) {
      executeEmptyMessageAnimation();
      return;
    }
    editorInput.value?.blur();
    emit('submit', body);
  };

  const setSaving = (saving: boolean) => {
    isSaving.value = saving;
  };

  const focus = () => {
    editorInput.value?.focus();
  };

  const reset = () => {
    editorInput.value?.reset();
  };

  const showStatusMessage = (message: string) => {
    if (statusMessageTimeoutId.value) {
      clearTimeout(statusMessageTimeoutId.value);
    }
    statusMessage.value = message;
    statusMessageTimeoutId.value = setTimeout(() => {
      statusMessage.value = null;
    }, 2000);
  };

  function executeEmptyMessageAnimation() {
    if (editorInput.value) {
      editorInput.value.executeEmptyMessageAnimation();
      return;
    }
    if (animateEmptyMessageTimeoutId) {
      clearTimeout(animateEmptyMessageTimeoutId);
    }
    animateEmptyMessage.value = true;
    animateEmptyMessageTimeoutId = setTimeout(() => {
      animateEmptyMessage.value = false;
    }, 1100);
  }

  const handleMediaUpload = () => {
    openEditorMediaEmbedDialog(
      (files) => editorInput.value?.uploadFiles(files),
      (src) => editorInput.value?.pasteImage(src),
    );
  };

  defineExpose({
    focus,
    reset,
    setSaving,
    showStatusMessage,
  });
</script>

<template>
  <div
    class="relative flex items-center overflow-hidden w-full border-gray-700 border-opacity-40 editor-form px-1"
  >
    <overlay-loader v-if="isSaving" />
    <user-avatar v-if="currentUser" :item="currentUser" size="size-10" />
    <div
      class="tip-tap flex flex-1 py-1 bg-black bg-opacity-32 rounded-xl max-h-60 overflow-y-auto thin-scrollbar ml-2 border border-gray-785 border-opacity-40"
      @click="showEditor = true"
    >
      <div class="relative flex-1 bg-[#10131a]">
        <div
          v-if="!showEditor"
          class="flex items-center h-full px-3 text-gray-350 opacity-50 transition duration-500 hover:opacity-80"
          :class="{
            'animate-shake animate-duration-200 animate-twice':
              animateEmptyMessage,
          }"
        >
          {{ placeholder }}
        </div>
        <wysiwyg-editor
          v-else
          single-line
          ref="editorInput"
          context="comment"
          editor-css-class="px-3"
          v-model="contentBody"
          autofocus
          :placeholder="placeholder"
          @media-inserted="closeDialog"
          @enter="submit"
          @error="emit('error', $event)"
        />
        <transition
          leave-active-class="animate-fade animate-duration-1000 animate-reverse"
        >
          <div
            class="absolute top-0 bg-inherit w-full h-full px-3 flex items-center text-gray-400"
            v-if="statusMessage"
          >
            {{ statusMessage }}
          </div>
        </transition>
      </div>

      <div class="flex flex-row justify-between pt-2.5 pr-3 h-10">
        <ul class="flex flex-row text-gray-500 gap-x-6">
          <base-tooltip content="Media">
            <li @click="handleMediaUpload(), (showEditor = true)">
              <base-icon
                name="image-upload"
                size="size-5"
                class="pointer-events-none"
              />
            </li>
          </base-tooltip>
        </ul>
      </div>
    </div>

    <base-tooltip content="Send">
      <div
        class="flex justify-center items-center ml-1 p-3 text-gray-500 hover:text-gray-300 transition bg-black bg-opacity-32 rounded-xl cursor-pointer border border-gray-785 border-opacity-40"
        @click="submit(contentBody, editorInput?.isEmpty ?? true)"
      >
        <base-icon name="outlined-send" size="size-6" />
      </div>
    </base-tooltip>
  </div>
</template>
