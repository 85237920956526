<template>
  <node-view-wrapper class="code-block">
    <select
      v-model="selectedLanguage"
      contenteditable="false"
      class="text-sm text-opacity-50 bg-gray-700 rounded-lg focus:border-indigo-400 bg-opacity-30"
    >
      <option :value="null">auto</option>
      <option disabled>—</option>
      <option
        v-for="(language, index) in languages"
        :key="index"
        :value="language"
      >
        {{ language }}
      </option>
    </select>
    <pre><code><node-view-content /></code></pre>
  </node-view-wrapper>
</template>

<script>
  import {
    NodeViewContent,
    nodeViewProps,
    NodeViewWrapper,
  } from '@tiptap/vue-3';
  export default {
    components: {
      NodeViewWrapper,
      NodeViewContent,
    },

    props: nodeViewProps,

    data() {
      return {
        languages: this.extension.options.lowlight.listLanguages(),
      };
    },

    computed: {
      selectedLanguage: {
        get() {
          return this.node.attrs.language;
        },
        set(language) {
          this.updateAttributes({ language });
        },
      },
    },
  };
</script>

<style>
  .code-block {
    position: relative;
  }
  .code-block select {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
</style>
