import { apiInstance } from '../base';
import type { OtpCode } from './types';

const BASE_URL = '/me';

export const getMyOtpCode = async () => {
  const response = await apiInstance.get<{ data: OtpCode }>(`${BASE_URL}/otp`);
  return response.data.data;
};

export const setClaimedOnboardingStreak = (claimedAt: Date) => {
  return apiInstance.post(`${BASE_URL}/set-claimed-onboarding-streak`, {
    claimedAt,
  });
};
