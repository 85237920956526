import { Ref, unref } from 'vue';
import { useEventListener } from '@vueuse/core';

/**
 *
 * @param target
 * @param handler
 */
export function onTouchOutside(
  target: Ref<HTMLElement | null>,
  handler: EventListener,
) {
  const listener = (event: PointerEvent) => {
    const el = unref(target);
    if (!el || el === event.target || event.composedPath().includes(el)) {
      return;
    }

    handler(event);
  };

  useEventListener(window, 'touchstart', listener, { passive: true });
}
