import { config } from '@/shared/lib';
import { actor } from '../base';
import type {
  ActionResultUserSelf,
  CreateICPUser,
  GetGlobalLiveDataResult,
} from 'dfx/edge/edge.did';

export const createReferredUser = (params: CreateICPUser) => {
  return actor()
    .create_referred_user(params)
    .then((res) => res) as Promise<ActionResultUserSelf>;
};

export const createIcpUser = async (params: CreateICPUser) => {
  // If we're using a hardcoded captcha then create the token and have it match the principal
  if (config.AUTH_TOKEN_BYPASS != null) {
    console.log('Creating captcha token');
    const token = params.username;
    params.auth_token = token;
    await actor().create_auth_token(config.AUTH_TOKEN_BYPASS, token, '');
  }
  return await actor().create_icp_user(params);
};

export const getGlobalLiveData = () => {
  return actor()
    .get_global_live_data([false])
    .then((res) => res) as Promise<GetGlobalLiveDataResult>;
};
