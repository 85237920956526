import type { dscvrApi } from '@/shared/api';
import * as CanvasInterface from '@dscvr-one/canvas-interface';
import * as CanvasWalletInterface from '@dscvr-one/canvas-wallet-interface';

export { CanvasInterface, CanvasWalletInterface };

export class WalletMethodNotSupportedError extends Error {
  name = 'WalletMethodNotSupportedError';
}

export type UntrustedCanvasResponse<
  T extends CanvasInterface.BaseHostMessage = CanvasInterface.BaseHostMessage,
> = T extends { untrusted: any }
  ? {
      type: T['type'];
      untrusted: T['untrusted'];
    }
  : {
      type: T['type'];
    };

export type CanvasAppInfo = {
  originalUrl: string;
  proxyUrl: string;
  previewImageUrl?: string;
  autoStart?: boolean;
  reportCount?: number;
  transactedCount?: number;
};

export interface ClientRequest<
  T extends CanvasInterface.BaseClientMessage = CanvasInterface.BaseClientMessage,
> {
  app: CanvasAppInfo;
  message: T;
}

export interface HostResponse<
  T extends CanvasInterface.BaseHostMessage = CanvasInterface.BaseHostMessage,
> {
  app: CanvasAppInfo;
  message: T;
}

export type ConnectWalletRequest =
  ClientRequest<CanvasInterface.User.ConnectWalletRequest>;

export type ConnectWalletResponse =
  HostResponse<CanvasInterface.User.ConnectWalletResponse>;

export type SignAndSendTransactionRequest =
  ClientRequest<CanvasInterface.User.SignAndSendTransactionRequest>;

export type SignAndSendTransactionResponse =
  HostResponse<CanvasInterface.User.SignAndSendTransactionResponse> & {
    scanResponse: dscvrApi.blowfish.ScanTransactionsSolanaResponse;
  };
