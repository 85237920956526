<script setup lang="ts">
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useClipboardToast } from '@/shared/model';
  import { type WalletOption } from '@/entities/wallets';
  import WalletOptionItem from '../ui/WalletOptionItem.vue';

  defineProps<{
    walletOptions: WalletOption[];
    selectedWalletOption?: WalletOption;
    allowAddWallet?: boolean;
  }>();

  defineEmits<{
    (e: 'add-wallet'): void;
    (e: 'select-wallet', option: WalletOption): void;
  }>();

  const { copyToClipboard } = useClipboardToast();
</script>

<template>
  <div :class="{ 'max-w-[196px]': selectedWalletOption?.type === 'all' }">
    <base-dropdown
      placement="bottom-start"
      custom-content-classes="md:min-w-[306px] w-full rounded-xl bg-gray-950 text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
    >
      <template #button="{ toggle }">
        <base-button
          variant="custom"
          custom-classes="flex items-center space-x-2 cursor-pointer"
          @click="toggle"
        >
          <wallet-option-item :icon="selectedWalletOption?.icon">
            {{ selectedWalletOption?.label || $t('wallets.allWallets') }}
            <template #subtitle v-if="selectedWalletOption?.subtitle">
              {{ selectedWalletOption.subtitle }}
            </template>
          </wallet-option-item>
          <base-icon name="outlined-chevron-down" size="size-4" />
        </base-button>
      </template>
      <template #content="{ hide }">
        <div
          v-for="(walletOption, idx) in walletOptions"
          :key="idx"
          class="cursor-pointer select-none py-3 px-3.5 hover:bg-gray-785"
          :class="{
            'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
              selectedWalletOption?.type === walletOption.type,
          }"
          @click="hide(), $emit('select-wallet', walletOption)"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <div class="flex justify-center w-12 mx-auto">
                <base-icon
                  :name="walletOption.icon"
                  size="size-10"
                  :class="{
                    'p-2': walletOption.type === 'all',
                    'px-[3px]': walletOption.type === 'dscvr-id',
                  }"
                />
              </div>
              <div class="flex flex-col items-start justify-center w-auto">
                <p class="font-medium leading-5 truncate">
                  {{ walletOption.label }}
                </p>
                <p class="text-sm text-gray-300">
                  {{ walletOption.subtitle }}
                </p>
              </div>
            </div>
            <template v-if="walletOption.address">
              <base-tooltip content="Copy">
                <button
                  type="button"
                  class="flex items-center justify-center size-8 rounded-md hover:bg-white hover:bg-opacity-16"
                  @click.stop="
                    copyToClipboard(
                      walletOption.address,
                      $t('wallets.addressCopied'),
                    )
                  "
                >
                  <base-icon name="outlined-copy" size="size-4" />
                </button>
              </base-tooltip>
            </template>
          </div>
        </div>
        <template v-if="allowAddWallet">
          <div class="my-2 mx-4 h-px bg-white/12"></div>
          <div
            class="flex items-center cursor-pointer select-none py-3 px-3.5 hover:bg-gray-785"
            @click="hide(), $emit('add-wallet')"
          >
            <base-icon name="outlined-plus" size="size-4" />
            <span class="ml-2 font-medium text-gray-300">
              {{ $t('wallets.addAnotherWallet') }}
            </span>
          </div>
        </template>
      </template>
    </base-dropdown>
  </div>
</template>
