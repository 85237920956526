import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';
import { IDL } from '@dfinity/candid';
import { getCrc32 } from '@dfinity/principal/lib/esm/utils/getCrc';
import { sha224 } from '@dfinity/principal/lib/esm/utils/sha224';
import { Token } from './token';

/**
 *
 * @param memo
 */
function encodeMemo(memo) {
  return [
    ...new Uint8Array(
      IDL.encode(
        [
          IDL.Record({
            to: IDL.Principal,
            contentID: IDL.Opt(IDL.Nat64),
            from: IDL.Principal,
          }),
        ],
        [memo],
      ),
    ),
  ];
}

const to32bits = (num) => {
  const b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};

const getSubAccountArray = (s) => {
  if (Array.isArray(s)) {
    return s.concat(Array(32 - s.length).fill(0));
  } else {
    //32 bit number only
    return Array(28)
      .fill(0)
      .concat(to32bits(s ? s : 0));
  }
};

const principalToAccountIdentifier = (p) => {
  const padding = Buffer('\x0Aaccount-id');
  const array = new Uint8Array([
    ...padding,
    ...Principal.fromText(p).toUint8Array(),
    ...getSubAccountArray(0),
  ]);
  const hash = sha224(array);
  const checksum = to32bits(getCrc32(hash));
  const array2 = new Uint8Array([...checksum, ...hash]);
  return toHexString(array2);
};

const toHexString = (byteArray) => {
  return Array.from(byteArray, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');
};

const idlFactory = ({ IDL }) => {
  const AccountIdentifier = IDL.Text;
  const User = IDL.Variant({
    principal: IDL.Principal,
    address: AccountIdentifier,
  });
  const SubAccount = IDL.Vec(IDL.Nat8);
  const AllowanceRequest = IDL.Record({
    owner: User,
    subaccount: IDL.Opt(SubAccount),
    spender: IDL.Principal,
  });
  const Balance__1 = IDL.Nat;
  const TokenIdentifier = IDL.Text;
  const CommonError__1 = IDL.Variant({
    InsufficientBalance: IDL.Null,
    InvalidToken: TokenIdentifier,
    Unauthorized: AccountIdentifier,
    Other: IDL.Text,
  });
  const Result_2 = IDL.Variant({ ok: Balance__1, err: CommonError__1 });
  const Balance = IDL.Nat;
  const ApproveRequest = IDL.Record({
    subaccount: IDL.Opt(SubAccount),
    allowance: Balance,
    spender: IDL.Principal,
  });
  const Result_3 = IDL.Variant({ ok: IDL.Bool, err: CommonError__1 });
  const BalanceRequest = IDL.Record({
    token: TokenIdentifier,
    user: User,
  });
  const CommonError = IDL.Variant({
    InsufficientBalance: IDL.Null,
    InvalidToken: TokenIdentifier,
    Unauthorized: AccountIdentifier,
    Other: IDL.Text,
  });
  const BalanceResponse = IDL.Variant({ ok: Balance, err: CommonError });
  const Result_7 = IDL.Variant({ ok: IDL.Nat, err: CommonError__1 });
  const Extension = IDL.Text;
  const HoldersRequest = IDL.Record({
    offset: IDL.Opt(IDL.Nat),
    limit: IDL.Opt(IDL.Nat),
  });
  const Holder = IDL.Record({
    balance: IDL.Nat,
    account: AccountIdentifier,
  });
  const Page_1 = IDL.Record({
    content: IDL.Vec(Holder),
    offset: IDL.Nat,
    limit: IDL.Nat,
    totalElements: IDL.Nat,
  });
  const Result_6 = IDL.Variant({ ok: Page_1, err: CommonError });
  const Result_5 = IDL.Variant({ ok: IDL.Text, err: CommonError__1 });
  const Metadata = IDL.Variant({
    fungible: IDL.Record({
      decimals: IDL.Nat8,
      ownerAccount: AccountIdentifier,
      metadata: IDL.Opt(IDL.Vec(IDL.Nat8)),
      name: IDL.Text,
      symbol: IDL.Text,
    }),
    nonfungible: IDL.Record({ metadata: IDL.Opt(IDL.Vec(IDL.Nat8)) }),
  });
  const Result_4 = IDL.Variant({ ok: Metadata, err: CommonError__1 });
  const MintRequest = IDL.Record({ to: User, amount: Balance });
  const TransferResponse = IDL.Variant({
    ok: Balance,
    err: IDL.Variant({
      InsufficientAllowance: IDL.Null,
      CannotNotify: AccountIdentifier,
      InsufficientBalance: IDL.Null,
      InvalidToken: TokenIdentifier,
      Rejected: IDL.Null,
      Unauthorized: AccountIdentifier,
      Other: IDL.Text,
    }),
  });
  const AccountIdentifier__1 = IDL.Text;
  const User__1 = IDL.Variant({
    principal: IDL.Principal,
    address: AccountIdentifier,
  });
  const Result_1 = IDL.Variant({ ok: IDL.Nat, err: CommonError });
  const TransactionRequest = IDL.Record({
    hash: IDL.Opt(IDL.Text),
    user: IDL.Opt(User),
    offset: IDL.Opt(IDL.Nat),
    limit: IDL.Opt(IDL.Nat),
    index: IDL.Opt(IDL.Nat),
  });
  const TransType = IDL.Variant({
    burn: IDL.Null,
    mint: IDL.Null,
    approve: IDL.Null,
    transfer: IDL.Null,
  });
  const Transaction = IDL.Record({
    to: AccountIdentifier,
    fee: Balance,
    status: IDL.Text,
    transType: TransType,
    from: AccountIdentifier,
    hash: IDL.Text,
    memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
    timestamp: IDL.Int,
    index: IDL.Nat,
    amount: Balance,
  });
  const Page = IDL.Record({
    content: IDL.Vec(Transaction),
    offset: IDL.Nat,
    limit: IDL.Nat,
    totalElements: IDL.Nat,
  });
  const Result = IDL.Variant({ ok: Page, err: CommonError });
  const Memo = IDL.Vec(IDL.Nat8);
  const TransferRequest = IDL.Record({
    to: User,
    token: TokenIdentifier,
    notify: IDL.Bool,
    from: User,
    memo: Memo,
    subaccount: IDL.Opt(SubAccount),
    amount: Balance,
  });
  return IDL.Service({
    allowance: IDL.Func([AllowanceRequest], [Result_2], ['query']),
    approve: IDL.Func([ApproveRequest], [Result_3], []),
    balance: IDL.Func([BalanceRequest], [BalanceResponse], ['query']),
    cycleAvailable: IDL.Func([], [Result_7], []),
    cycleBalance: IDL.Func([], [Result_7], ['query']),
    extensions: IDL.Func([], [IDL.Vec(Extension)], ['query']),
    getFee: IDL.Func([], [Result_2], ['query']),
    holders: IDL.Func([HoldersRequest], [Result_6], ['query']),
    logo: IDL.Func([], [Result_5], ['query']),
    metadata: IDL.Func([], [Result_4], ['query']),
    mint: IDL.Func([MintRequest], [TransferResponse], []),
    registry: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(AccountIdentifier__1, Balance__1))],
      ['query'],
    ),
    setFee: IDL.Func([Balance__1], [Result_3], []),
    setFeeTo: IDL.Func([User__1], [Result_3], []),
    setLogo: IDL.Func([IDL.Text], [Result_3], []),
    supply: IDL.Func([], [Result_2], ['query']),
    totalHolders: IDL.Func([], [Result_1], ['query']),
    transactions: IDL.Func([TransactionRequest], [Result], ['query']),
    transfer: IDL.Func([TransferRequest], [TransferResponse], []),
    transferFrom: IDL.Func([TransferRequest], [TransferResponse], []),
  });
};

export class EXT {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  getAccountIdentifier(principal) {
    return principalToAccountIdentifier(principal);
  }

  isValidPrincipal(val) {
    try {
      Principal.fromText(val);
      return true;
    } catch (e) {
      return false;
    }
  }

  async tip(to, amount, contentId) {
    const serviceAddress = principalToAccountIdentifier(
      'ykaf2-xqaaa-aaaaj-qayaa-cai',
    );

    const memo = encodeMemo({
      to: Principal.fromText(to),
      contentID: [contentId],
      from: this.identity.getPrincipal(),
    });

    return await this.actor.transfer({
      to: {
        address: serviceAddress,
      },
      token: '',
      notify: false,
      from: {
        address: principalToAccountIdentifier(
          this.identity.getPrincipal().toText(),
        ),
      },
      memo: memo,
      subaccount: [],
      amount: amount,
    });
  }

  async transfer(to, amount) {
    if (this.isValidPrincipal(to)) {
      to = principalToAccountIdentifier(to);
    } else if (to.length != 64) {
      return {
        error: 'Invalid wallet address',
      };
    }
    return await this.actor.transfer({
      to: {
        address: to,
      },
      token: '',
      notify: false,
      from: {
        address: principalToAccountIdentifier(
          this.identity.getPrincipal().toText(),
        ),
      },
      memo: [],
      subaccount: [],
      amount: amount,
    });
  }

  async balance(principal) {
    const tokens = await this.actor.balance({
      token: '',
      user: {
        principal: principal,
      },
    });
    if (tokens.ok !== undefined) {
      return new Token({
        balance: tokens.ok,
      });
    }
    return new Token({
      balance: 0,
    });
  }
}
