<script setup lang="ts">
  import { toRefs, computed } from 'vue';
  import { USER_CONTENT, RANDOM_GRADIENTS } from '@/common';
  import { useRouter } from 'vue-router';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { BaseCard } from '@/components/shared/base-card';
  import { fetchMedia, pluralize, getDisplayAmount } from '@/shared/lib';
  import type { CreatorCardProps } from '../types';

  const router = useRouter();
  const props = defineProps<CreatorCardProps>();

  const { user } = toRefs(props);

  const toggleUserFollow = (isFollowing: boolean) => {
    isFollowing ? user.value.followers++ : user.value.followers--;
  };

  const goToProfile = () => {
    router.push({
      name: USER_CONTENT,
      params: { username: user.value.username },
    });
  };

  const randomGradientBg = computed(() => {
    const randomIndex = Math.floor(Math.random() * RANDOM_GRADIENTS.length);
    return RANDOM_GRADIENTS[randomIndex];
  });
</script>

<template>
  <base-card
    :content-spacing="spacing"
    :background="fetchMedia(user.cover_photo[0]!)"
    :inner-shadow="true"
    :background-scale="true"
    class="h-84"
    :class="user.cover_photo[0] ? '' : randomGradientBg"
  >
    <div class="flex font-semibold">
      <user-avatar
        :item="user"
        size="w-12 h-12"
        class="mr-4 cursor-pointer"
        rounded="rounded-xl"
        @click="goToProfile"
      />
      <div class="flex justify-between items-center w-full">
        <div class="text-left cursor-pointer" @click="goToProfile">
          <div class="text-lg">{{ user.username }}</div>
          <div class="text-gray-400 text-sm">
            {{ getDisplayAmount(BigInt(user.followers), true) }}
            <span>{{ pluralize(user.followers, 'Follower') }}</span>
          </div>
        </div>
        <follow-user-button
          size="x-small"
          :user="user"
          @update:is-following="toggleUserFollow($event)"
        />
      </div>
    </div>
  </base-card>
</template>
