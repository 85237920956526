import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useUpdateContentMutation = () => {
  return useMutation({
    mutationKey: ['update-content-mutation'],
    mutationFn: (params: dscvrIcApi.post.UpdateContentParams) =>
      dscvrIcApi.post.updateContent(params),
  });
};
