import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getClaimedLootboxItemsQueryKey } from './keys';
import { sortClaimedItemsByDateDesc } from '../lib/utils';

export const useGetOpenedLootboxesItemsQuery = () => {
  return useQuery({
    queryKey: getClaimedLootboxItemsQueryKey,
    queryFn: dscvrApi.lootbox.getOpenedLootboxesItems,
    select: sortClaimedItemsByDateDesc,
    initialData: [],
  });
};
