import type { PortalInfoView, PortalView } from 'dfx/edge/edge.did';
import type { MaybeRef, Ref } from 'vue';

export const userKey = 'user';
export const isFollowingKey = 'is-following';
export const userPortalRoleKey = 'user-portal-role';
export const assignableUserPortalRolesKey = 'assignable-user-portal-roles';

export const getUserPortalRolesByIdKey = (
  portalId: Ref<string | undefined>,
  userId: Ref<string | undefined>,
) => {
  return [userPortalRoleKey, 'by-portal-id', portalId, 'by-user-id', userId];
};

export const getAssignableUserPortalRolesByIdKey = (
  portal: Ref<PortalInfoView | PortalView>,
) => {
  return [
    assignableUserPortalRolesKey,
    'by-portal-id',
    portal.value.id.toString(),
  ];
};

export const getUserByIdKey = (userId: MaybeRef<string>) => {
  return [userKey, 'get-user-by-id', userId];
};
