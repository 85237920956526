<template>
  <div class="mx-auto bg-gray-975">
    <div class="flex justify-center gap-6 md:px-2 mx-auto max-w-home">
      <div class="w-full max-w-screen-2xl overflow-hidden">
        <main class="flex justify-center">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
