import { useSwipe } from '@vueuse/core';

export const useNavigation = () => {
  const { direction, isSwiping } = useSwipe(window);

  const isRunningStandalone = window.matchMedia(
    '(display-mode: fullscreen)',
  ).matches;

  return {
    direction,
    isRunningStandalone,
    isSwiping,
  };
};
