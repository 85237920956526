import { dscvrIcApi } from '@/shared/api';
import type { Principal } from '@dfinity/principal';
import { useMutation } from '@tanstack/vue-query';

export const useUnbanUserMutation = () => {
  return useMutation({
    mutationKey: ['unban-user-mutation'],
    mutationFn: (userId: Principal) => dscvrIcApi.user.unbanUser(userId),
  });
};
