<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="flex flex-col items-center justify-center bg-white/[0.04] border border-[#333B47]/[0.5] rounded-xl mb-3"
  >
    <div
      class="flex justify-between w-full p-3 border-b border-[#333B47]/[0.5]"
    >
      <div class="flex items-center justify-center gap-2">
        <base-icon name="poll-tool" size="w-5 h-5" />
        <h1 class="text-lg font-semibold">Poll</h1>
      </div>
      <div
        class="w-8 h-8 rounded-md bg-white/[0.08] flex items-center justify-center cursor-pointer"
        @click="$emit('close')"
      >
        <base-icon name="close" />
      </div>
    </div>
    <div class="w-full p-4">
      <div ref="answers">
        <draggable
          v-model="poll.choices"
          item-key="id"
          handle=".handle"
          group="people"
          tag="div"
          :component-data="{ name: 'fade' }"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ index }">
            <div :key="index" class="mb-2 answer-option">
              <div class="flex flex-row items-center gap-3">
                <span v-if="!isPollType('Text')" class="w-full">
                  <input
                    id
                    :key="index"
                    v-model="poll.choices[index]"
                    type="text"
                    name="question"
                    :placeholder="'Option ' + (index + 1)"
                    class="w-full h-12 mr-0 text-white purple-caret bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md outline-none focus:outline-none focus:ring-0"
                  />
                </span>
                <span v-if="isPollType('Text')" class="w-full">
                  <input
                    id
                    :key="index"
                    v-model="poll.choices[index]"
                    type="text"
                    name="question"
                    :placeholder="'Option ' + (index + 1)"
                    class="w-full h-12 mr-0 text-white purple-caret bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md outline-none focus:outline-none focus:ring-0"
                  />
                </span>
                <span
                  v-if="index > 1"
                  class="flex items-center justify-center bg-white/[0.08] rounded-md w-10 h-10 cursor-pointer"
                  @click="deleteQuestion(index)"
                >
                  <base-icon name="trash" />
                </span>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="flex flex-col gap-3 mt-3">
        <div v-show="errors" class="text-sm text-red-500">
          {{ errors }}
        </div>

        <div
          v-show="poll.choices.length < maxPollQuestions()"
          class="py-3 font-medium text-sm text-center rounded-md bg-white/[0.08] cursor-pointer"
          @click="addQuestion"
        >
          + Add Option
        </div>

        <div class="flex w-full gap-2">
          <select
            v-model="poll.days"
            class="w-full py-3 font-mediium text-sm text-center bg-white/[0.08] border-none rounded-lg outline-none hover:bg-white/[0.08] focus:ring-indigo-600"
          >
            <option :value="0">Voting length</option>
            <option :value="1">1 day</option>
            <option :value="2">2 days</option>
            <option :value="3">3 days</option>
            <option :value="4">4 days</option>
            <option :value="5">5 days</option>
            <option :value="6">6 days</option>
            <option :value="7">7 days</option>
          </select>
          <select
            v-model="poll.kind"
            class="w-full py-3 font-mediium text-sm text-center bg-white/[0.08] border-none rounded-lg outline-none hover:bg-white/[0.08] focus:ring-indigo-600"
          >
            <option :value="{ Traditional: null }">Traditional</option>
            <option :value="{ Quadratic: null }" disabled>Distributed</option>
            <option :value="{ Text: null }">S̵̞̚u̶͈͠p̵̨̛e̸̟̎ṟ̷͛n̸̜̂o̴̳̍ṿ̸̛ä̴͓́</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import draggable from 'vuedraggable';

  export default {
    name: 'create-poll-form',
    components: {
      draggable,
    },
    props: {
      poll: {
        type: Object,
        default() {
          return {
            choices: ['', ''],
            days: '7',
            kind: { Traditional: null },
          };
        },
      },
    },
    emits: ['close'],
    data() {
      return {
        errors: '',
        text_choices: [
          {
            code: '',
            description: '',
          },
          {
            code: '',
            description: '',
          },
        ],
        drag: false,
        days: '7',
        kind: { Traditional: null },
      };
    },
    methods: {
      addQuestion() {
        if (this.poll.choices.length < this.maxPollQuestions()) {
          // eslint-disable-next-line vue/no-mutating-props
          this.poll.choices.push('');
          this.text_choices.push({
            code: '',
            description: '',
          });
        }

        this.$nextTick(() => {
          this.$refs.answers.scrollTo({
            top: this.$refs.answers.scrollHeight,
            behavior: 'smooth',
          });
        });
      },
      deleteQuestion(id) {
        // eslint-disable-next-line vue/no-mutating-props
        this.poll.choices.splice(id, 1);
        this.text_choices.splice(id, 1);
      },
      maxPollQuestions() {
        if (this.poll.kind && this.isPollType('Text')) {
          return 500;
        }
        return 15;
      },
      isPollType(type) {
        return type in this.poll.kind;
      },
      isValid() {
        if (this.choices) {
          this.errors = '';
          if (this.newTag.length > 10) {
            this.errors = 'max tag text length is 10 characters';
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>
<style>
  .sortable-chosen {
    opacity: 0.4;
  }

  .answer-option {
    scroll-margin-top: 20px;
  }
</style>
