<template>
  <div class="flex flex-col justify-center items-center gap-8 px-2 md:px-8">
    <div class="flex flex-col gap-4 text-center">
      <div class="text-2xl md:text-xxxl font-bold tracking-[0.32px]">
        <slot name="title" />
      </div>
      <div v-if="$slots.description" class="text-gray-400 text-sm">
        <slot name="description" />
      </div>
    </div>
    <div v-if="$slots.default" class="flex flex-wrap gap-4">
      <slot />
    </div>
    <slot name="actions" />
  </div>
</template>
