<script lang="ts" setup>
  import { useSocialSphere, SocialSphere } from '@/entities/user';
  import { onBeforeMount } from 'vue';

  const { selectedSocialSphereTab } = useSocialSphere();

  onBeforeMount(() => {
    selectedSocialSphereTab.value = 'followers';
  });
</script>

<template>
  <div class="mb-10 text-2xl font-bold">People</div>
  <social-sphere v-model:selected-tab="selectedSocialSphereTab" />
</template>
