<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { type BaseToggleProps } from './types';

  const props = defineProps<BaseToggleProps>();
  const emit = defineEmits<{ (e: 'update:toggled', toggled: boolean): void }>();

  const customClasses = computed(() => [
    props.disabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer',
    'flex items-center justify-between',
    props.toggleClasses,
    props.rounded ?? 'rounded-3xl',
    props.ringColor ?? 'ring-white',
  ]);

  const active = ref('primary');

  const toggle = (value: string) => {
    if (active.value === value || props.disabled) return;
    emit('update:toggled', !props.toggled);
    active.value = value;
  };
</script>

<template>
  <div
    class="ring-1 ring-inset ring-opacity-10 p-4 gap-x-4 shadow-xl"
    :class="customClasses"
  >
    <div
      @click="toggle('primary')"
      :class="[
        active === 'primary'
          ? props.activeToggleClasses
          : props.inactiveToggleClasses,
      ]"
      class="ease-transition-all"
    >
      <slot name="primary" />
    </div>
    <div
      @click="toggle('secondary')"
      :class="[
        active === 'secondary'
          ? props.activeToggleClasses
          : props.inactiveToggleClasses,
      ]"
      class="ease-transition-all"
    >
      <slot name="secondary" />
    </div>
  </div>
</template>
