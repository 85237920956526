import type { Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';
import { useQuery } from '@tanstack/vue-query';
import { randomizedRecommendedPortalsKey } from './keys';

export const useGetRandomizedRecommendedPortalsQuery = (
  query: Ref<RandomizedRecommendationQuery>,
) => {
  return useQuery({
    queryKey: [randomizedRecommendedPortalsKey, query],
    queryFn: () =>
      dscvrIcApi.portal.getRandomizedRecommendedPortals(query.value),
    staleTime: 1000 * 60 * 5,
  });
};
