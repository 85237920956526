import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useToast } from '@/shared/model';
import { usePersistentStore } from '@/store';
import { useValidateVerificationEmailTokenQuery } from '@/entities/user-setting';

export const useValidateToken = () => {
  const persistentStore = usePersistentStore();
  const { verificationEmail } = storeToRefs(persistentStore);

  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const token = ref('');
  const {
    data: verified,
    isLoading,
    status,
    refetch: validateEmailToken,
  } = useValidateVerificationEmailTokenQuery(token);

  const handleTokenValidation = async (updatedToken: string) => {
    token.value = updatedToken;
    if (!isLoading.value) {
      await validateEmailToken();
      if (status.value === 'success') {
        verificationEmail.value.verified = verified.value;
        if (verified.value) {
          showToast({
            type: 'success',
            title: t('verification.email.notifications.successTitle'),
            description: t(
              'verification.email.notifications.successDescription',
            ),
            durationSeconds: 4,
          });
        } else {
          showToast({
            type: 'error',
            title: t('verification.email.notifications.errorTitle'),
            description: t('verification.email.notifications.errorDescription'),
            durationSeconds: 4,
          });
        }
      }
    }
  };

  return {
    handleTokenValidation,
  };
};
