<script lang="ts" setup>
  import { useBreakpoint } from '@/shared/model';
  import { useStreak } from '../../model/composables/use-streak';
  import { computed } from 'vue';
  import { uniqueId } from 'lodash-es';
  import { pluralize } from '@/shared/lib';

  type Reward = {
    id: string;
    numDay: number;
    points: number;
    isActive: boolean;
  };

  const LAST_STREAK_DAY = 6; // 0-indexed

  const { isSmallerThanMd } = useBreakpoint();
  const { activeStreak, isPoweredUp, totalStreakCount } = useStreak();

  const rewards = computed<Reward[] | []>(() => {
    if (activeStreak.value) {
      const rewards: Reward[] = [];
      const points = Array.from(activeStreak.value.rewards);
      const startDay = activeStreak.value.start_day;
      points.forEach((point, index) => {
        const numDay = startDay + index;
        rewards.push({
          id: uniqueId(),
          numDay,
          points: Number(point),
          isActive: totalStreakCount.value >= numDay,
        });
      });
      return rewards;
    }
    return [];
  });
</script>

<template>
  <div
    class="flex w-full justify-around items-stretch rounded-2xl"
    :class="
      isSmallerThanMd
        ? 'gap-1'
        : ' p-[50px] border border-gray-785 border-opacity-50'
    "
  >
    <div
      v-for="(reward, index) in rewards"
      :key="reward.id"
      class="flex items-center"
    >
      <div
        v-if="index > 0 && !isSmallerThanMd"
        class="h-px px-2 w-[60px] flex-none"
        :class="
          reward.isActive
            ? 'bg-gradient-to-t to-[#00ECBC] from-[#007ADF]'
            : 'bg-gray-600'
        "
      />
      <div
        class="flex flex-col items-center gap-4 md:gap-[11px] md:w-[60px] font-medium text-gray-300"
        :class="{
          'animate-jump-in animate-once': isPoweredUp && reward.isActive,
        }"
      >
        <div
          class="text-center px-1"
          :class="{
            'text-gray-500': !reward.isActive,
          }"
        >
          <template v-if="!isSmallerThanMd">
            {{ $t('day') }}
          </template>
          {{ reward.numDay }}
        </div>
        <div class="relative">
          <template v-if="index === LAST_STREAK_DAY">
            <base-icon
              :name="
                isPoweredUp && reward.isActive
                  ? 'filled-airdrop3'
                  : 'outlined-airdrop3'
              "
              :size="
                isSmallerThanMd ? 'w-[30px] h-[30px]' : 'w-[58px] h-[58px]'
              "
              class="flex-none text-gray-500"
            />
            <base-icon
              v-if="isPoweredUp || !reward.isActive"
              name="vuesax-bulk-lock-circle"
              :size="isSmallerThanMd ? 'w-4 h-4' : 'w-5 h-5'"
              class="absolute -bottom-2 right-0"
            />
          </template>
          <template v-else>
            <base-icon
              :name="
                reward.isActive ? 'filled-flash' : 'vuesax-outline-flash-only'
              "
              :size="
                isSmallerThanMd ? 'w-[30px] h-[30px]' : 'w-[58px] h-[58px]'
              "
              class="flex-none text-indigo-950"
            />
          </template>
        </div>
        <div
          class="flex flex-col items-center uppercase text-center text-xs"
          :class="{
            'text-gray-500': !reward.isActive,
          }"
        >
          <template v-if="index === LAST_STREAK_DAY">
            <div v-if="isSmallerThanMd">1x</div>
            <div>
              {{ isSmallerThanMd ? 'multi' : $t('streak.airdropMultiplier') }}
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center">
              <span>{{ reward.points }}</span>
              <span>
                <template v-if="isSmallerThanMd">
                  {{ pluralize(reward.points, 'pt', 'pts') }}
                </template>
                <template v-else>
                  {{ pluralize(reward.points, 'point', 'points') }}
                </template>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
