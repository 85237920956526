import type { FrameMetaTag } from '../types';
import { requiredOpenFrameMetaTags } from './required-open-frame-meta-tags';

export const hasRequiredMetaTags = (metaTags: FrameMetaTag[]): boolean => {
  const matches = metaTags.filter((tag) => {
    return requiredOpenFrameMetaTags.some((requiredMetaTag) => {
      if (typeof requiredMetaTag === 'string') {
        return tag.name === requiredMetaTag;
      }
      return requiredMetaTag.test(tag.name);
    });
  });

  return matches.length >= 3;
};
