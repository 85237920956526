<script lang="ts" setup>
  import { computed, toRefs, ref, toRef } from 'vue';
  import { PermissionFlags, hasPermission, isPortalOwner } from '@/shared/lib';
  import { ROLE_KIND_FIELD_EVERYONE, USER_CONTENT } from '@/common';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import type {
    MemberListItemView,
    PortalView,
    RoleListItemView,
    RoleView,
  } from 'dfx/edge/edge.did';
  import { Diamond } from '@/shared/ui/diamond';
  import {
    useUser,
    useGetAssignableUserPortalRolesQuery,
  } from '@/entities/user';
  import {
    useAddMemberRoleMutation,
    useRemoveMemberRoleMutation,
  } from '@/entities/portal';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';

  const props = defineProps<{
    portalView: PortalView;
    member: MemberListItemView;
  }>();

  const emits = defineEmits<{
    (e: 'ban-member'): void;
    (e: 'kick-member'): void;
    (e: 'refetch'): void;
  }>();

  const { member } = toRefs(props);

  const { data: currentPortalUserAssignableRoles } =
    useGetAssignableUserPortalRolesQuery(
      toRef(() => props.portalView),
      true,
    );
  const { mutate: addMemberRoleMutation } = useAddMemberRoleMutation();
  const { mutate: removeMemberRoleMutation } = useRemoveMemberRoleMutation();
  const { currentUser } = useUser();

  const assignableRoles = computed(() =>
    currentPortalUserAssignableRoles.value?.filter(
      (role: RoleView) => !(ROLE_KIND_FIELD_EVERYONE in role.kind),
    ),
  );

  const availableRolesToAssign = computed(() => {
    return assignableRoles.value?.filter(
      (role: RoleView) => !isMemberRole(role),
    );
  });

  const isMemberRole = (role: RoleListItemView) =>
    member.value.roles.some(
      (memberRole: RoleListItemView) => memberRole.id == role.id,
    );

  const addMemberRole = (role: RoleListItemView) => {
    addMemberRoleMutation(
      {
        portalId: props.portalView.id,
        memberId: member.value.id,
        roleId: role.id,
      },
      {
        onSuccess: () => {
          emits('refetch');
        },
      },
    );
  };

  const removeMemberRole = (role: RoleListItemView) => {
    removeMemberRoleMutation(
      {
        portalId: props.portalView.id,
        memberId: member.value.id,
        roleId: role.id,
      },
      {
        onSuccess: () => {
          emits('refetch');
        },
      },
    );
  };
</script>

<template>
  <div class="flex items-center py-3 text-white border-b border-gray-700">
    <user-avatar
      v-if="member.user"
      :item="member.user"
      size="size-8"
      class="mr-2.5"
    />
    <base-icon v-else name="user-circle" size="size-8" class="mr-2.5" />
    <div class="flex-auto truncate">
      <router-link
        :to="{
          name: USER_CONTENT,
          params: { username: member.user.username },
        }"
      >
        {{ member.user.username }}
      </router-link>
    </div>
    <div
      v-for="role in member.roles"
      :key="member.id + '-' + role.id"
      class="relative flex p-2 my-1 ml-2 text-xs bg-gray-700 rounded-lg w-max group"
    >
      <span
        v-if="
          !(role.name === 'Admin' && !isPortalOwner(portalView, currentUser))
        "
        class="absolute z-1 p-1 text-white bg-gray-600 rounded-lg opacity-0 cursor-pointer left-1 group-hover:opacity-100 hover:bg-gray-950"
        @click="removeMemberRole(role)"
      >
        <base-icon name="x" size="size-2" />
      </span>
      <diamond :color="role.color" size="size-2" class="mt-1 mr-2" />
      {{ role.name }}
    </div>
    <base-dropdown placement="bottom-end" theme="gray" append-to-body>
      <template #button="{ toggle }">
        <base-button
          variant="custom"
          custom-classes="cursor-pointer p-2 ml-3 md:ml-2 text-xs bg-gray-700 rounded-lg hover:bg-gray-900"
          @click="toggle"
        >
          <base-icon name="plus" size="size-5" />
        </base-button>
      </template>
      <template #content>
        <div class="relative px-4 py-2 bg-gray-700 rounded-lg min-w-max">
          <template v-if="availableRolesToAssign?.length === 0">
            <span class="text-sm text-gray-400 opacity-50 hover:text-white">
              No Available Roles
            </span>
          </template>
          <template v-else>
            <div
              v-for="role in availableRolesToAssign"
              :key="role.id.toString()"
              class="flex items-center py-3 text-white cursor-pointer"
              @click="addMemberRole(role)"
            >
              <diamond :color="role.color" size="size-2" class="mr-2" />
              <span class="text-sm text-gray-400 hover:text-white">
                {{ role.name }}
              </span>
            </div>
          </template>
        </div>
      </template>
    </base-dropdown>
    <base-dropdown placement="bottom-end" theme="gray" append-to-body>
      <template #button="{ toggle }">
        <base-button
          variant="custom"
          custom-classes="p-2 ml-3 md:ml-2 text-xs bg-gray-700 rounded-lg hover:bg-gray-900"
          @click="toggle"
        >
          <base-icon name="ellipsis" size="size-5" />
        </base-button>
      </template>
      <template #content>
        <div
          class="flex flex-col justify-start items-start rounded-xl overflow-hidden bg-gray-700"
        >
          <template
            v-if="
              hasPermission(portalView.perm, PermissionFlags.BAN_MEMBER) &&
              !isPortalOwner(portalView, member.user)
            "
          >
            <base-button
              variant="custom"
              custom-classes="flex gap-2 p-4 cursor-pointer hover:bg-gray-600 w-full"
              @click="emits('ban-member')"
            >
              <base-icon name="outline-block" size="size-5" class="mr-2" />
              Ban {{ member.user.username }}
            </base-button>
          </template>
          <template
            v-if="hasPermission(portalView.perm, PermissionFlags.KICK_MEMBER)"
          >
            <base-button
              v-if="!isPortalOwner(portalView, member.user)"
              variant="custom"
              custom-classes="flex gap-2 p-4 cursor-pointer hover:bg-gray-600 w-full"
              @click="emits('kick-member')"
            >
              <base-icon name="remove" size="size-5" class="mr-2" />
              Remove {{ member.user.username }}
            </base-button>
            <div v-else class="p-4 text-sm text-gray-400">
              Cannot remove portal owner
            </div>
          </template>
        </div>
      </template>
    </base-dropdown>
  </div>
</template>
