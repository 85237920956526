/**
 *
 */
export function isMetamaskInstalled() {
  return typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
}

/**
 *
 * @param chainId
 */
export function chainHex(chainId: number) {
  return '0x' + parseInt(chainId.toString(), 10).toString(16);
}

interface ErrorWithCode extends Error {
  code: number;
}

/**
 *
 * @param chainId
 */
export async function changeEvmNetwork(chainId: number) {
  const chainIdHex = chainHex(chainId);
  try {
    if (!window.ethereum || !window.ethereum.request) {
      throw new Error('No ethereum provider found');
    }
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as ErrorWithCode).code === 4902) {
      try {
        if (!window.ethereum || !window.ethereum.request) {
          throw new Error('No ethereum provider found');
        }
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainIdHex,
            },
          ],
        });
      } catch (addError) {
        throw new Error(
          "Couldn't add network, it's possible that user has rejected the change",
        );
      }
    } else {
      throw new Error("Couldn't switch networks. Error: " + switchError);
    }
  }
}
