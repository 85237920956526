import { apiInstance } from '../base';
import type {
  DataResponse,
  BuyNowRequest,
  BuyNowResponse,
  TensorNft,
} from './types';

const BASE_URL = '/tensor/token';

export const getToken = (nftId: string) => {
  return apiInstance
    .get(`${BASE_URL}/${nftId}`)
    .then((response) => response.data) as Promise<DataResponse<TensorNft>>;
};

export const buyNFT = (payload: BuyNowRequest) => {
  return apiInstance
    .post(`${BASE_URL}/buy`, payload)
    .then((response) => response.data) as Promise<DataResponse<BuyNowResponse>>;
};
