<script lang="ts" setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { OnboardingStep, OnboardingLootboxStep } from '@/shared/model';
  import { useOnboardingStateMachine } from '../model/composables/use-onboarding-state-machine';
  import { useOnboardingLootboxStateMachine } from '../model/composables/use-onboarding-lootbox-state-machine';
  import { useOnboarding } from '../model/composables/use-onboarding';

  const props = defineProps<{
    currentStep: OnboardingStep | OnboardingLootboxStep;
    onCustomAction?: () => void;
  }>();

  const { t } = useI18n({
    useScope: 'global',
  });
  const { states: originalStateMachine } = useOnboardingStateMachine();
  const { states: lootboxStateMachine } = useOnboardingLootboxStateMachine();
  const { isOnboardingLootboxStep } = useOnboarding();

  const disabled = ref(false);

  const onHandleSkip = () => {
    disabled.value = true;
    if (props.onCustomAction) {
      props.onCustomAction();
    }
    if (isOnboardingLootboxStep(props.currentStep)) {
      lootboxStateMachine.value[props.currentStep].on('skip');
    } else {
      originalStateMachine.value[props.currentStep].on('skip');
    }
  };
</script>

<template>
  <div
    v-if="disabled"
    class="flex items-center justify-center text-indigo-300 w-full"
  >
    <slot>
      {{ t('onboarding.skip') }}
    </slot>
  </div>
  <base-button
    v-else
    variant="link"
    size="full"
    additional-classes="text-indigo-300"
    @click="onHandleSkip"
  >
    <slot>{{ t('onboarding.skip') }}</slot>
  </base-button>
</template>
