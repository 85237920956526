<script lang="ts" setup>
  import { computed, watch, nextTick } from 'vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useDocumentScroll } from '@/shared/model';
  import { TopPortals } from '.';

  const emit = defineEmits<{
    (e: 'minimized', value: boolean): void;
  }>();

  const MINIMIZE_THRESHOLD_PX = 150;
  const { y } = useDocumentScroll();
  const isMinimized = computed(() => y.value >= MINIMIZE_THRESHOLD_PX);

  watch(
    () => isMinimized.value,
    (value, prevValue) => {
      if (value !== prevValue) {
        emit('minimized', isMinimized.value);
      }
    },
  );

  const minimize = () => {
    nextTick(() =>
      scrollTo({ top: MINIMIZE_THRESHOLD_PX, behavior: 'smooth' }),
    );
  };
</script>

<template>
  <div class="relative w-full overflow-visible z-39">
    <div
      class="relative w-full overflow-hidden bg-center bg-no-repeat bg-cover"
      :class="isMinimized ? 'h-96 pt-21' : 'h-screen'"
      :style="{
        transition: 'height 0.5s',
        backgroundImage: `url(${fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/header/solana-banner.png`,
        )})`,
      }"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-pink-500 via-blue-900 to-indigo-950 -z-1"
      ></div>
      <div
        class="flex flex-col items-center justify-between w-full h-screen text-center pb-30 pt-18"
      >
        <div class="flex flex-col items-center gap-8 px-2 pt-12">
          <div
            class="text-lg font-normal transition-opacity duration-500"
            :class="isMinimized ? 'opacity-0' : 'opacity-100'"
          >
            Chart limitless galaxies on DSCVR. Social home of Solana.
          </div>
          <div
            class="mb-10 font-bold"
            :class="[
              isMinimized ? 'text-3xl md:text-5xl' : 'text-4xl md:text-6xl',
            ]"
            :style="{
              transition: 'font-size 0.5s',
            }"
          >
            Be Social. Get Rewards.
          </div>
          <div
            class="p-6 bg-white bg-opacity-5 backdrop-blur-sm w-full max-w-[428px] rounded-xl transition-opacity duration-500"
            :class="isMinimized ? 'opacity-0' : 'opacity-100'"
          >
            <top-portals />
          </div>
        </div>
        <base-button
          variant="custom"
          class="flex items-center justify-center gap-2 px-5 py-3.5 text-base leading-5 ease-transition-all bg-white bg-opacity-8 hover:bg-opacity-12 focus:border focus:border-white focus:border-opacity-8 active:bg-opacity-6 disabled:bg-opacity-6 backdrop-blur rounded-[20px]"
          @click="minimize()"
        >
          <span>{{ $t('scrollAndDiscover') }}</span>
          <base-icon
            name="outlined-arrow-down"
            size="w-3.5 h-3.5"
            class="animate-bounce"
          />
        </base-button>
      </div>
    </div>
  </div>
</template>
