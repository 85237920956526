<script lang="ts" setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { trackEvent } from '@/utils';
  import { useRouter } from 'vue-router';
  import { ActionTypes } from '@/store';
  import { USER_SETTINGS_PROFILE } from '@/common';
  import UpdateIcpUsername from '@/components/forms/UpdateIcpUsername.vue';
  import ErrorDisplay from '@/components/ErrorDisplay.vue';
  import {
    SocialLinksField,
    SocialPairingPlatforms,
  } from '@/features/social-links';
  import PhoneVerifyForm from '@/components/verifications/PhoneVerifyForm.vue';
  import { EmailUpdate } from '@/entities/user-setting';
  import type {
    UserSelfView,
    UpdateUsername,
    ActionResultUserSelf,
  } from 'dfx/edge/edge.did';
  import { useProfileUpdate } from '@/features/user-settings';
  import CoverPhoto from './components/CoverPhoto.vue';
  import ProfilePhoto from './components/ProfilePhoto.vue';

  type LocalComponentError = {
    field: string;
    errors: string[];
  };

  const props = defineProps<{
    user: UserSelfView;
    username: UserSelfView['username'];
  }>();

  const router = useRouter();
  const store = useStore();
  const { showToast } = useToast();
  const {
    currentUserSocialPresence,
    updateCurrentUserSocialPresence,
    getSelf,
  } = useUser();
  const { updateBio } = useProfileUpdate();

  const errors = ref([]);
  const errorMessage = ref('');
  const localBioErrors = ref<LocalComponentError[]>([]);
  const bio = ref(props.user?.bio);

  const isValidBio = () => {
    localBioErrors.value = [];

    if (bio.value && bio.value.length > 150) {
      localBioErrors.value.push({
        field: '',
        errors: ['Bio max length 150 chars'],
      });
    }
  };

  const onSubmit = () => {
    isValidBio();
    if (localBioErrors.value.length === 0) {
      updateBio(bio.value || '');
    }
  };

  const updateUsername = async (username: UpdateUsername) => {
    await store
      .dispatch(`auth/${ActionTypes.UPDATE_USERNAME}`, username)
      .then((result: ActionResultUserSelf) => {
        if (result?.status == 'happy' && result.result.length > 0) {
          trackEvent(
            'user_settings_action',
            'change_username',
            username.username,
          );
          showToast({
            title: 'Username Successfully Updated',
            type: 'success',
            durationSeconds: 3,
          });
          router.replace({
            name: USER_SETTINGS_PROFILE,
            params: {
              username: result.result[0]?.username,
            },
          });
        } else {
          errorMessage.value = result.message;
        }
      });
  };
</script>
<template>
  <div v-if="user">
    <h1 class="mb-6 text-2xl font-bold">
      {{ $t('userSettings.userProfile') }}
    </h1>
    <cover-photo />
    <profile-photo class="-mt-18.5 mb-6" />

    <span class="text-base font-bold">Username</span>
    <update-icp-username
      :errors="errors"
      :error-message="errorMessage"
      :username="user?.username"
      :disabled="user.identity.some((identity) => 'Username' in identity)"
      class="mb-8"
      @update-username="updateUsername"
    />

    <span class="text-base font-bold">About</span>
    <div class="relative flex flex-col items-end">
      <textarea
        v-model="bio"
        placeholder="Bio..."
        maxlength="150"
        rows="3"
        class="mt-2 text-sm settings-input min-h-40"
      />

      <base-button
        variant="secondary"
        size="small"
        class="absolute w-24 right-2 bottom-2"
        @click="onSubmit"
      >
        Update
      </base-button>
    </div>
    <div class="flex justify-end h-6 mb-8">
      <error-display :local-errors="localBioErrors" />
    </div>

    <span class="block mb-2 text-base font-bold">Email</span>
    <email-update :user="user" @refresh:user="getSelf" />

    <span class="block mt-8 mb-2 text-base font-bold">Phone Number</span>
    <phone-verify-form
      @phone-verified="
        showToast({
          title: $event,
          durationSeconds: 3,
          type: 'success',
        })
      "
    />

    <social-links-field
      v-if="user"
      class="mt-6"
      legend="Show off your social media presence in your profile."
      validate-username
      :model-value="currentUserSocialPresence"
      @update:model-value="updateCurrentUserSocialPresence"
    >
      <social-pairing-platforms />
    </social-links-field>
  </div>
</template>
