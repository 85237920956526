export const MutationTypes = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_USER_FOLLOWERS: 'SET_USER_FOLLOWERS',
  SET_USER_FOLLOWING: 'SET_USER_FOLLOWING',
  SET_USER_PAIRED_WALLETS: 'SET_USER_PAIRED_WALLETS',
  SET_USER_PAIRED_PLATFORMS: 'SET_USER_PAIRED_PLATFORMS',
  SET_USER_FOLLOWING_STATUS: 'SET_USER_FOLLOWING_STATUS',
  TOGGLE_BLOCK: 'TOGGLE_BLOCK',
  SET_USER_BLOCKED_STATUS: 'SET_USER_BLOCKED_STATUS',
  SET_COMMENT_REACTION: 'SET_COMMENT_REACTION',
  UNSET_COMMENT_CURRENT_REACTION: 'UNSET_COMMENT_REACTION',
  SET_PORTALS: 'SET_PORTALS',
  SET_CURRENT_PORTAL: 'SET_CURRENT_PORTAL',
  SET_CURRENT_PORTAL_ROLES: 'SET_CURRENT_PORTAL_ROLES',
  SET_CURRENT_PORTAL_USER_ROLES: 'SET_CURRENT_PORTAL_USER_ROLES',
  SET_CURRENT_PORTAL_HOVERED_USER_ROLES:
    'SET_CURRENT_PORTAL_HOVERED_USER_ROLES',
  SET_CURRENT_PORTAL_USER_MEMBER: 'SET_CURRENT_PORTAL_USER_MEMBER',
  SET_CURRENT_PORTAL_USER_ASSIGNABLE_ROLES:
    'SET_CURRENT_PORTAL_USER_ASSIGNABLE_ROLES',
  SET_CURRENT_PORTAL_OWNER_ROLES: 'SET_CURRENT_PORTAL_OWNER_ROLES',
  SET_CURRENT_PORTAL_ROLE_MEMBERS: 'SET_CURRENT_PORTAL_ROLE_MEMBERS',
  UPDATE_PORTAL: 'UPDATE_PORTAL',
  UPDATE_PORTAL_ICON: 'UPDATE_PORTAL_ICON',
  ADD_PORTAL: 'ADD_PORTAL',
  SET_ME: 'SET_ME',
  SET_IS_NAVBAR_VISIBLE: 'SET_IS_NAVBAR_VISIBLE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_MENTION: 'SET_MENTION',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_GLOBAL_LIVE_DATA: 'SET_GLOBAL_LIVE_DATA',
  SET_CURRENT_INFO_PANEL: 'SET_CURRENT_INFO_PANEL',
  SET_REPORT_MODAL: 'SET_REPORT_MODAL',
  SET_REPORTS: 'SET_REPORTS',
  SET_LOGIN_ROUTE: 'SET_LOGIN_ROUTE',
  SET_SHOW_ONBOARDING_MODAL: 'SET_SHOW_ONBOARDING_MODAL',
  SET_ONBOARDING_STEP: 'SET_ONBOARDING_STEP',
  UPDATE_REPORT: 'UPDATE_REPORT',
  REVEAL_NSFW_POST: 'REVEAL_NSFW_POST',
  SET_HOTSPOTS: 'SET_HOTSPOTS',
  SET_FEED_RECOMENDATIONS: 'SET_FEED_RECOMENDATIONS',
  SET_EXPLORE_RECOMENDATIONS: 'SET_EXPLORE_RECOMENDATIONS',
  SET_SPOTLIGHT_USERS: 'SET_SPOTLIGHT_USERS',
  SET_ALL_PAGINATED: 'SET_ALL_PAGINATED',

  ADD_NEW_NFT_TIP: 'ADD_NEW_NFT_TIP',
  REMOVE_NEW_NFT_TIP: 'REMOVE_NEW_NFT_TIP',
  RESET_TIPS: 'RESET_TIPS',

  ADD_NFT_TIPS: 'ADD_NFT_TIPS',
  ADD_ICRC_TIPS: 'ADD_ICRC_TIPS',

  ADD_CONTENT_TIP_WATCH: 'ADD_CONTENT_TIP_WATCH',
  REMOVE_CONTENT_TIP_WATCH: 'REMOVE_CONTENT_TIP_WATCH',

  NFT_SET_COLLECTIONS: 'NFT_SET_COLLECTIONS',

  SET_ENTREPOT_NFT_COLLECTIONS: 'SET_ENTREPOT_NFT_COLLECTIONS',

  SET_GATING_NFT_COLLECTIONS: 'SET_GATING_NFT_COLLECTIONS',
  SET_GATING_NFT_SAURON_ROLES: 'SET_GATING_NFT_SAURON_ROLES',
  REMOVE_GATING_NFT_COLLECTION: 'REMOVE_GATING_NFT_COLLECTION',
  ADD_GATING_NFT_COLLECTION: 'ADD_GATING_NFT_COLLECTION',
  UPDATE_GATING_NFT_COLLECTION: 'UPDATE_GATING_NFT_COLLECTION',

  SET_UBI_CLAIMED: 'SET_UBI_CLAIMED',
  SET_UBI_CLAIMED_TIME: 'SET_UBI_CLAIMED_TIME',
  SET_USER_PORTALS: 'SET_USER_PORTALS',

  //EVM
  SET_PROVIDER_ETHERS: 'SET_PROVIDER_ETHERS',
  SET_EVM_PAIRED_WALLETS: 'SET_EVM_PAIRED_WALLETS',
  SET_CONNECTED_METAMASK_WALLETS: 'SET_CONNECTED_METAMASK_WALLETS',
  SET_EVM_WALLET_ERROR_MSG: 'SET_EVM_WALLET_ERROR_MSG',
  SET_WALLETCONNECT_CLIENT: 'SET_WALLETCONNECT_CLIENT',
  SET_WEB3MODAL_CLIENT: 'SET_WEB3MODAL_CLIENT',

  //TOASTS
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',

  //BETA
  SET_BETA_FEATURES: 'SET_BETA_FEATURES',

  // FEED
  SET_FEED_ROUTE_BACKUP: 'SET_FEED_ROUTE_BACKUP',

  //ARCADE
  SET_ARCADE_GAMES: 'SET_ARCADE_GAMES',
  SET_ARCADE_USERS: 'SET_ARCADE_USERS',
  SET_ARCADE_PORTALS: 'SET_ARCADE_PORTALS',

  // REFERRAL
  SET_USER_REFERRAL_CODE: 'SET_USER_REFERRAL_CODE',
};
