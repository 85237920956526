<template>
  <span
    class="w-8 h-8 rounded cursor-pointer"
    :class="{
      'flex justify-center items-center border border-solid border-white border-opacity-25':
        checked,
    }"
    :style="{
      background: `url(${photoUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }"
  >
    <base-icon v-show="checked" name="check" />
  </span>
</template>

<script>
  export default {
    props: {
      photoUrl: {
        type: String,
        required: true,
      },
      checked: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
