import { actor } from '../base';
import type {
  UserSelfView,
  UpdateUserProfileV2,
  UpdateUserProfileIcon,
  ActionResultUserSelf,
  EmailVerificationEmptyResult,
  UpdateUserEmail,
} from 'dfx/edge/edge.did';

export const setUserBio = (payload: UpdateUserProfileV2) => {
  return actor().user_set_profile_v2(payload) as Promise<[] | [UserSelfView]>;
};

export const setProfilePicture = (payload?: UpdateUserProfileIcon) => {
  return actor().user_set_profile_icon(payload ? [payload] : []) as Promise<
    [] | [UserSelfView]
  >;
};

export const setCoverPhoto = (src?: string) => {
  return actor().update_user_cover_photo(
    src ? [src] : [],
  ) as Promise<ActionResultUserSelf>;
};

export const setUserEmail = (email: UpdateUserEmail) => {
  return actor().update_user_email(email) as Promise<ActionResultUserSelf>;
};

export const sendVerificationEmail = () => {
  return actor().send_verification_email() as Promise<EmailVerificationEmptyResult>;
};

export const getVerificationEmailStatus = () => {
  return actor().is_email_verification_pending() as Promise<boolean>;
};

export const verifyEmailAddressToken = (token: string) => {
  return actor().verify_email_token(
    token,
  ) as Promise<EmailVerificationEmptyResult>;
};
