import { computed, type Ref } from 'vue';
import {
  useFormatHTMLContent,
  useHtmlClamp,
  useIsolateHtmlParts,
} from '@/composables';
import type { ContentView } from 'dfx/edge/edge.did';
import { useBreakpoint } from '@/shared/model';

export function useRelevantChildren(comment: Ref<ContentView>) {
  const relevantChildren = computed(() => {
    if (
      comment.value.relevant_children &&
      comment.value.relevant_children.length > 0
    ) {
      return comment.value.relevant_children;
    }

    return [];
  });

  const body = computed(() => comment.value.body);
  const { isSmallerThanMd } = useBreakpoint();
  const { text } = useIsolateHtmlParts(body);
  const options = computed(() => ({
    maxCharacters: isSmallerThanMd.value ? 100 : 200,
  }));
  const { clampedHtml: summaryBody } = useHtmlClamp(text, options);
  const { isHtmlEmpty } = useFormatHTMLContent();
  const isEmpty = computed(() => isHtmlEmpty(summaryBody.value));
  const finalBody = computed(() =>
    isEmpty.value ? body.value : summaryBody.value,
  );

  return {
    finalBody,
    relevantChildren,
  };
}
