import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import EditorPreview from './EditorPreview.vue';

export default Node.create({
  name: 'preview',
  group: 'inline',
  inline: true,
  atom: true,
  addAttributes() {
    return {
      'data-embed': {
        default: false,
      },
      type: {
        default: '',
      },
      id: {
        default: '',
        // ensure the id attribute is passed as a string, otherwise it's
        // parsed into a number which is problematic for content IDs since they're
        // 64-bit integers. content ids are later converted to BigInt
        // by the EditorPreview
        parseHTML: (element) => {
          return element.getAttribute('id')?.toString();
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'preview',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['preview', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(EditorPreview);
  },
});
