import type { Gradient } from '@/shared/model';

/*
 * This map is used to determine the gradient colors for a portal cover.
 * The key is the color of the gradient
 * The value is an object with two keys: from and to representing the gradient colors
 * and an optional via key representing the color in the middle of the gradient
 */
export const coverGradients = new Map<number, Gradient>([
  [1, { from: 'from-green-550', to: 'to-sky-750' }],
  [2, { from: 'from-pink-625', to: 'to-amber-350' }],
  [3, { from: 'from-violet-825', to: 'to-blue-525' }],
  [4, { from: 'from-violet-750', to: 'to-pink-650' }],
  [5, { from: 'from-red-625', to: 'to-orange-350' }],
  [6, { from: 'from-emerald-250', to: 'to-yellow-220' }],
  [7, { from: 'from-fuchsia-350', to: 'to-amber-110' }],
  [8, { from: 'from-teal-425', to: 'to-blue-350' }],
]);
