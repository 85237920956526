import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import type { UpdateUserProfileIcon, UserSelfView } from 'dfx/edge/edge.did';
import { ActionTypes } from '@/store';
import { useToast } from '@/shared/model';
import { useUser } from '@/entities/user';
import {
  useSetUserBioMutation,
  useSetUserCoverPhotoMutation,
  useSetUserPFPMutation,
} from '@/entities/user-setting';

export const useProfileUpdate = () => {
  const store = useStore();
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { setIsLoading } = useUser();
  const { mutateAsync: setBio } = useSetUserBioMutation();
  const { mutateAsync: setPFP } = useSetUserPFPMutation();
  const { mutateAsync: updateCoverPhoto } = useSetUserCoverPhotoMutation();

  const refreshProfile = async (response: [] | [UserSelfView]) => {
    if (!response.length) {
      showToast({
        title: t('userSettings.updateProfileError'),
        type: 'error',
        durationSeconds: 3,
      });
      return;
    }
    await store.dispatch(`auth/${ActionTypes.UPDATE_BETA_FEATURES}`);
    await store.dispatch(`auth/${ActionTypes.SET_ME}`, response[0]);

    showToast({
      title: 'Profile successfully updated',
      type: 'success',
      durationSeconds: 3,
    });
  };

  const updateBio = async (bio: string) => {
    setIsLoading(true);
    await setBio(
      { bio },
      {
        onSuccess: refreshProfile,
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const updatePfp = async (payload?: UpdateUserProfileIcon) => {
    setIsLoading(true);
    return await setPFP(payload, {
      onSuccess: refreshProfile,
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  return {
    updateBio,
    updatePfp,
    updateCoverPhoto,
  };
};
