<script lang="ts" setup>
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
  import { computed } from 'vue';
  import EmbeddedGimluckReward from '../preview/EmbeddedGimluckReward.vue';

  const props = defineProps(nodeViewProps);

  const username = computed(() => props.node.attrs.username);
  const points = computed(() => props.node.attrs.points.toString());
  const lossMessage = computed(() => props.node.attrs.lossMessage.toString());
</script>

<template>
  <node-view-wrapper>
    <embedded-gimluck-reward
      :points="points"
      :username="username"
      :loss-message="lossMessage"
    />
  </node-view-wrapper>
</template>
