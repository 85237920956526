import {
  Cbor,
  Expiry,
  HttpAgent,
  IdentityInvalidError,
  type HttpAgentOptions,
  type Identity,
  Endpoint,
  ReadRequestType,
  type HttpAgentQueryRequest,
} from '@dfinity/agent';
import { IDL } from '@dfinity/candid';
import type { FuncClass, InterfaceFactory } from '@dfinity/candid/lib/cjs/idl';
import { Principal } from '@dfinity/principal';

/// Utility class that wraps HttpAgent to create custom signatures
export class IcHttpRequestSigner extends HttpAgent {
  private _id: Identity | Promise<Identity>;
  private _canisterId: Principal;
  private _idl: InterfaceFactory;

  constructor(options: HttpAgentOptions & { canisterId: string; idl: any }) {
    if (!options.identity) {
      throw new Error('Identity is required');
    }
    if (options.credentials) {
      throw new Error('Credentials not supported');
    }

    super(options);
    this._id = options.identity;
    this._canisterId = Principal.fromText(options.canisterId);
    this._idl = options.idl;
  }

  public async createSignedQuery(
    methodName: string,
    arg: any,
    expiryMsecs: number = 5 * 60 * 1000,
  ) {
    const id = await this._id;
    if (!id) {
      throw new IdentityInvalidError('Identity not found');
    }

    const sender = id.getPrincipal();

    const idl = this._idl({ IDL });
    const funcEntry = idl._fields.find((e) => e[0] === methodName);

    if (!funcEntry) {
      throw new Error(`Method ${methodName} not found in IDL`);
    }

    const funcType = funcEntry[1];
    const argBuffer = IDL.encode(funcType.argTypes, arg);

    const request = {
      request_type: 'query' as ReadRequestType,
      canister_id: this._canisterId,
      method_name: methodName,
      arg: argBuffer,
      sender,
      ingress_expiry: new Expiry(expiryMsecs),
    };

    const transformedRequest = await this._transform({
      endpoint: 'read' as Endpoint,
      body: request,
      request: {
        headers: {
          'Content-Type': 'application/cbor',
        },
        method: 'GET',
        body: null,
      },
    } as HttpAgentQueryRequest);

    // Apply transform for identity.
    const idTransformedRequest: any = await id?.transformRequest(
      transformedRequest,
    );

    return Cbor.encode(idTransformedRequest.body);
  }

  public async createSignedQueryRequest(methodName: string, arg: any) {
    let body = await this.createSignedQuery(methodName, arg);
    return {
      headers: {
        'Content-Type': 'application/cbor',
      },
      request: Buffer.from(body).toString('base64'),
    };
  }
}
