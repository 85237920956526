import { solApi } from '@/shared/api';
import { getListings } from '@/shared/api/sol-api/collection';

export interface GetListingsParams {
  collectionId: string;
  page: number;
  pageSize: number;
  sort?: solApi.collection.ListingSortType;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  aggregateMode?: string;
}

export const useGetMECollectionListingsQuery = async (
  params: GetListingsParams,
) => {
  return getListings(
    params.collectionId,
    params.page,
    params.pageSize,
    params.sort,
    params.sortDirection,
    params.search || '',
    params.aggregateMode || 'false',
  );
};
