<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useToast } from '@/shared/model';
  import { DSCVR_STATIC_ASSETS_CDN_URL, HOME } from '@/common';
  import { Loader } from '@/shared/ui/loader';
  import { fetchMedia } from '@/shared/lib';
  import { dscvrApi } from '@/shared/api';
  import {
    WalletIcon,
    usePairSolanaWallet,
    usePairEvmWallet,
  } from '@/entities/wallets';

  const isPairing = ref(false);
  const isError = ref(false);

  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { publicKey, finalizeMobilePair: finalizeSolanaMobilePair } =
    usePairSolanaWallet();
  const { finalizeMobilePair: finalizeEvmMobilePair } = usePairEvmWallet();

  const dscvrPk: string = route.query.dscvrPk as string;
  const otpCode: string = route.query.otpCode as string;
  const nonce: string = atob(route.query.nonce as string);
  const walletType = route.query.walletType as dscvrApi.wallet.WalletType;
  const isPaired = ref(false);

  const validateParams = () => {
    return dscvrPk && otpCode && nonce && walletType;
  };

  const pair = async () => {
    try {
      isPairing.value = true;
      switch (walletType) {
        case dscvrApi.wallet.WALLET_TYPES.metamask:
          isPaired.value = await finalizeEvmMobilePair(walletType, {
            nonce,
            dscvrPk,
            otpCode,
          });
          break;
        case dscvrApi.wallet.WALLET_TYPES.phantom:
        case dscvrApi.wallet.WALLET_TYPES.solflare:
          isPaired.value = await finalizeSolanaMobilePair(walletType, {
            nonce,
            dscvrPk,
            otpCode,
          });
          break;
        default:
          throw new Error();
      }
    } catch (error) {
      isError.value = true;
      const description = publicKey.value
        ? `${t('wallets.linkWalletFailedDescription')} ${publicKey.value}`
        : undefined;
      showToast({
        id: `${walletType}-wallet-link-error`,
        title: t('wallets.linkWalletFailed'),
        description,
        type: 'error',
        durationSeconds: 3,
      });
    } finally {
      isPairing.value = false;
    }
  };

  onMounted(async () => {
    if (!validateParams()) {
      router.push({ name: HOME });
    } else {
      pair();
    }
  });
</script>

<template>
  <div
    class="flex flex-col items-center justify-center h-screen p-6 gap-6 text-2xl font-bold"
  >
    <template v-if="isPaired">
      <h1 class="text-center">
        {{ $t('wallets.thanksForPairing') }}
      </h1>
      <div
        class="flex items-center justify-center bg-gradient-to-br from-[#AC32E4] to-[#4801FF] rounded-full"
      >
        <img
          v-lazy="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/solana/orbit-planet.gif`)
          "
          class="size-32"
          :alt="$t('wallets.thanksForPairing')"
        />
      </div>
    </template>
    <template v-else>
      <h1 class="text-center">{{ $t('wallets.mobileWalletPairing') }}</h1>
      <wallet-icon :id="walletType" size="h-32" />
      <div v-if="isPairing" class="relative w-full h-10">
        <loader variant="rainbow" border-width="border" size="size-10" />
      </div>
      <base-button
        v-else-if="!isError && !isPaired"
        variant="primary"
        size="medium"
        additional-classes="!text-2xl min-w-40"
        @click="pair"
      >
        {{ $t('pair') }}
      </base-button>
    </template>
  </div>
</template>
