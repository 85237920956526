<script setup lang="ts">
  import { useBreakpoint } from '@/shared/model';
  import { PopularPortals } from '@/features/portal';
  import { UserSpotlight } from '@/features/user';
  import { LeftSidebar } from '@/widgets/left-sidebar';
  import { Block as PwaBlock } from '@/widgets/pwa';
  import { CorporateLinks } from '@/widgets/corporate-links';
  import { ReferralBlock } from '@/features/referral';

  const { isSmallerThanLg } = useBreakpoint();
</script>

<template>
  <div class="flex justify-center gap-6 mx-auto max-w-home">
    <left-sidebar />
    <div class="flex-grow flex-shrink w-full max-w-[1173px]">
      <div class="flex md:gap-6 md:px-16 lg:px-0">
        <main class="inline-flex w-0 flex-auto">
          <slot />
        </main>
        <div v-if="!isSmallerThanLg" class="w-full md:max-w-84">
          <referral-block />
          <popular-portals />
          <user-spotlight />
          <pwa-block />
          <corporate-links />
        </div>
      </div>
    </div>
  </div>
</template>
