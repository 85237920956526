<template>
  <multiselect
    ref="multiselect"
    v-model="localTags"
    mode="tags"
    :allow-empty="false"
    placeholder="# Add tags"
    :options="search"
    :searchable="true"
    :filterResults="false"
    :min-chars="0"
    :resolve-on-load="true"
    :delay="500"
    :create-tag="true"
    :max="maxCount"
    :create-option="true"
    :on-create="handleCreate"
    :classes="{
      container:
        'relative mx-auto w-full flex items-center justify-center box-border cursor-pointer text-sm leading-snug outline-none',
      containerDisabled: 'cursor-default bg-gray-600',
      containerOpen: 'purple-caret',
      containerOpenTop: 'rounded-t-none',
      containerActive: '',
      singleLabel:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
      multipleLabel:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
      search:
        'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans pl-3.5',
      tags: 'w-full flex-grow items-center flex-shrink flex lg:flex-wrap mt-1 pl-1 items-end overflow-auto',
      tag: 'primary-bg-600 text-white text-sm font-semibold py-1 pl-2 rounded mr-1 mb-[0.3rem] flex items-center whitespace-nowrap',
      tagDisabled: 'pr-2 !bg-gray-400 text-black',
      tagRemove:
        'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-gray-700 group',
      tagRemoveIcon:
        'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
      tagsSearchWrapper:
        'inline-block relative px-1 mb-1 flex-grow flex-shrink w-0 min-w-10 h-full',
      tagsSearch:
        'h-full border-0 outline-none ring-0 p-0 text-white font-sans px-1 box-border flex-grow flex-shrink bg-transparent focus:outline-none focus:ring-0 group-hover:bg-gray-700 rounded-md w-full',
      placeholder:
        'flex items-center h-full absolute left-1 top-0 pointer-events-none bg-transparent leading-snug font-medium text-gray-400 focus:hidden',
      caret:
        'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform hidden',
      caretOpen: 'rotate-180',
      clear:
        'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 hidden',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block ',
      spinner:
        'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 hidden',
      dropdown:
        'absolute -left-px -right-px bottom-0 transform translate-y-full -mt-px overflow-y-auto thin-scrollbar z-50 border border-[#333B47]/[0.5] bg-gradient bg-[#1C212E] shadow-[0_10px_32px_0px_rgba(0,0,0,0.32)] flex flex-col rounded-xl z-50 max-h-48',
      dropdownTop:
        '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
      dropdownHidden: 'hidden',
      options: 'flex flex-col p-0 m-0 list-none',
      optionsTop: 'flex-col-reverse',
      option:
        'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
      optionPointed: 'text-gray-300 bg-gray-700',
      optionSelected: 'text-black bg-gray-500',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
      optionSelectedDisabled:
        'text-gray-100 bg-gray-500 bg-opacity-50 cursor-not-allowed',
      noOptions:
        'py-2 px-3 text-[#D1D5DB] border border-[#333B47]/[0.5] bg-gradient bg-[#1C212E] shadow-[0_10px_32px_0px_rgba(0,0,0,0.32)]',
      noResults:
        'py-2 px-3 text-[#D1D5DB] border border-[#333B47]/[0.5] bg-gradient bg-[#1C212E] shadow-[0_10px_32px_0px_rgba(0,0,0,0.32)]',
      fakeInput:
        'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
      spacer: 'h-9 py-px box-content',
      tagsSearchWrapper: 'h-full flex flex-col flex-grow',
      wrapper: 'w-full flex',
      assist: 'hidden',
    }"
    @keydown="openSelect"
    @change="closeSelect"
    @max="maxReached"
  >
    <template #option="{ option }">
      {{ option.label }}
    </template>

    <template #tag="{ option }">
      <div
        class="text-[#818CF8] text-xs leading-3 mr-1 mb-1 flex items-center whitespace-nowrap rounded-full bg-[#818CF8]/[0.2] px-2 py-[6px]"
      >
        #{{ option.value }}
      </div>
    </template>
  </multiselect>
</template>

<script>
  import Multiselect from '@vueform/multiselect';
  import { useToast } from '@/shared/model';
  import { useQueryClient } from '@tanstack/vue-query';
  import { dscvrIcApi } from '@/shared/api';

  export default {
    components: { Multiselect },
    props: {
      tags: {
        type: Array,
        default: () => [],
      },
      maxCount: {
        type: Number,
        default: 8,
      },
      maxCharLimit: {
        type: Number,
        default: 20,
      },
    },
    emits: ['set-tags'],
    setup() {
      const { showToast } = useToast();
      const queryClient = useQueryClient();

      return { showToast, queryClient };
    },
    data() {
      return {
        values: [],
        options: [],
        localTags: this.tags,
      };
    },
    watch: {
      localTags: {
        handler(newTags) {
          this.$emit('set-tags', newTags);
          this.closeSelect();
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      this.closeSelect();
    },
    methods: {
      closeSelect() {
        if (this.$refs.multiselect) {
          this.$refs.multiselect.close();
        }
      },
      maxReached() {
        this.showToast({
          type: 'error',
          title: `You cannot add more than ${this.maxCount} tags`,
          durationSeconds: 5,
        });
      },
      handleCreate(tag) {
        if (tag && tag.value && tag.value.length > this.maxCharLimit) {
          this.showToast({
            type: 'error',
            title: `Tags cannot have more than ${this.maxCharLimit} characters`,
            durationSeconds: 5,
          });
          return false;
        }
        return tag;
      },
      openSelect() {
        this.$refs.multiselect.open();
      },
      async search(query) {
        if (!query && this.localTags?.length > 0) {
          return this.localTags;
        } else if (!query) {
          query = '';
          return null;
        }
        const data = await this.queryClient.fetchQuery({
          queryKey: ['search-tags', query],
          queryFn: () => dscvrIcApi.post.searchTags(query),
        });
        const results = [];

        if (data?.length > 0) {
          for (let i = 0; i < data.length; i++) {
            results.push({
              value: data[i].name,
              label: data[i].name + ' (x' + data[i].total + ')',
            });
          }
        }
        return results;
      },
    },
  };
</script>

<style scoped>
  .bg-gradient {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 0.04)
      ),
      #1c212e;
  }
</style>
