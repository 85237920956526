import type { ContentReaction } from 'dfx/edge/edge.did';
import type { ReactionType } from '../types';

export const reactionTypeToContentReaction = (
  reactionType?: ReactionType,
): ContentReaction | undefined => {
  switch (reactionType) {
    case 'haha':
      return { HaHa: null };
    case 'clap':
      return { Clap: null };
    case 'fire':
      return { Fire: null };
    case 'care':
      return { Care: null };
    case 'wow':
      return { Wow: null };
    case 'sad':
      return { Sad: null };
    case 'angry':
      return { Angry: null };
    case 'dislike':
      return { Dislike: null };
    case 'like':
      return { Like: null };
    default:
      undefined;
  }
};
