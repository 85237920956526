import { Token } from './token';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';

const idlFactory = ({ IDL }) => {
  const TxError = IDL.Variant({
    InsufficientAllowance: IDL.Null,
    InsufficientBalance: IDL.Null,
    ErrorOperationStyle: IDL.Null,
    Unauthorized: IDL.Null,
    LedgerTrap: IDL.Null,
    ErrorTo: IDL.Null,
    Other: IDL.Text,
    BlockUsed: IDL.Null,
    AmountTooSmall: IDL.Null,
  });
  const Result = IDL.Variant({ Ok: IDL.Nat, Err: TxError });
  const Metadata = IDL.Record({
    fee: IDL.Nat,
    decimals: IDL.Nat8,
    owner: IDL.Principal,
    logo: IDL.Text,
    name: IDL.Text,
    totalSupply: IDL.Nat,
    symbol: IDL.Text,
  });
  const TokenInfo = IDL.Record({
    holderNumber: IDL.Nat64,
    deployTime: IDL.Nat64,
    metadata: Metadata,
    historySize: IDL.Nat64,
    cycles: IDL.Nat64,
    feeTo: IDL.Principal,
  });
  return IDL.Service({
    allowance: IDL.Func([IDL.Principal, IDL.Principal], [IDL.Nat], ['query']),
    approve: IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    balanceOf: IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    burn: IDL.Func([IDL.Nat], [Result], []),
    decimals: IDL.Func([], [IDL.Nat8], ['query']),
    getAllowanceSize: IDL.Func([], [IDL.Nat64], ['query']),
    getHolders: IDL.Func(
      [IDL.Nat64, IDL.Nat64],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    getMetadata: IDL.Func([], [Metadata], ['query']),
    getTokenInfo: IDL.Func([], [TokenInfo], ['query']),
    getUserApprovals: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    historySize: IDL.Func([], [IDL.Nat64], ['query']),
    logo: IDL.Func([], [IDL.Text], ['query']),
    mint: IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    name: IDL.Func([], [IDL.Text], ['query']),
    owner: IDL.Func([], [IDL.Principal], ['query']),
    setFee: IDL.Func([IDL.Nat], [], []),
    setFeeTo: IDL.Func([IDL.Principal], [], []),
    setLogo: IDL.Func([IDL.Text], [], []),
    setName: IDL.Func([IDL.Text], [], []),
    setOwner: IDL.Func([IDL.Principal], [], []),
    symbol: IDL.Func([], [IDL.Text], ['query']),
    totalSupply: IDL.Func([], [IDL.Nat], ['query']),
    transfer: IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    transferFrom: IDL.Func(
      [IDL.Principal, IDL.Principal, IDL.Nat],
      [Result],
      [],
    ),
  });
};
export const init = ({ IDL }) => {
  return [
    IDL.Text,
    IDL.Text,
    IDL.Text,
    IDL.Nat8,
    IDL.Nat,
    IDL.Principal,
    IDL.Nat,
    IDL.Principal,
    IDL.Principal,
  ];
};

export class DIP20 {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  async getMetadata() {
    if (!this.actor) {
      await this.create();
    }
    const data = await this.actor.getMetadata();
    return {
      cid: this.canisterId,
      fee: data.fee,
      name: data.name,
      symbol: data.symbol,
      standard: 'dip20',
      decimals: data.decimals,
      icpTicker: '',
      icon: data.logo,
    };
  }

  async balance(principal) {
    const tokens = await this.actor.balanceOf(principal);
    return new Token({
      balance: tokens,
    });
  }

  async transfer(to, amount) {
    return await this.actor.transfer(Principal.fromText(to), amount);
  }
}
