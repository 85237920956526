import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { isValidHttpUrl } from '@/shared/lib';
import { validateAndParse } from '../lib/validate-and-parse';

export const useCreateFrameMutation = () => {
  return useMutation({
    mutationKey: ['create-frame-mutation'],
    mutationFn: async ({
      url,
      userPrincipal,
    }: {
      url: string;
      userPrincipal?: string;
    }) => {
      if (!isValidHttpUrl(url)) return;

      const appInfo = await dscvrApi.proxy.createEmbeddedApp(
        url,
        userPrincipal,
      );

      if (!appInfo || appInfo.type !== 'frame') {
        return;
      }

      return validateAndParse(url, appInfo.data);
    },
  });
};
