import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import WwCodeSelector from '@/components/ww/WwCodeSelector.vue';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

const ExtendedCodeBlock = CodeBlockLowlight.extend({
  addNodeView() {
    return VueNodeViewRenderer(WwCodeSelector);
  },
});

export default ExtendedCodeBlock;
