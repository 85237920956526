import type { ActiveStreakWindow } from 'dfx/edge/edge.did';
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import {
  useDialog,
  OnboardingStep,
  useOnboardingStore,
  useGlobalStore,
} from '@/shared/model';
import { useStreakStore } from '../store';
import { useActiveWindowQuery } from '../../api/use-active-window.query';
import { useClaimDailyStreakQuery } from '../../api/use-claim-daily-streak.query';
import { useUser } from '../../../user';
import { usePostDialog } from '@/features/post';
import DailyStreakDialogContent from '../../components/daily-streak-dialog/Content.vue';
import DailyStreakDialogHeader from '../../components/daily-streak-dialog/Header.vue';
import LearnMoreDialogContent from '../../components/learn-more-dialog/Content.vue';
import LearnMoreDialogHeader from '../../components/learn-more-dialog/Header.vue';
import EmailVerificationDialogContent from '../../components/email-verification-dialog/Content.vue';
import EmailVerificationDialogHeader from '../../components/email-verification-dialog/Header.vue';
import ShareStreakDialogContent from '../../components/share-dialog/Content.vue';
import CompletionDialogContent from '../../components/completion-dialog/Content.vue';
import CompletionDialogHeader from '../../components/completion-dialog/Header.vue';

export const useStreak = () => {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { currentUser, isLoggedIn } = useUser();
  const streakStore = useStreakStore();
  const globalStore = useGlobalStore();
  const { openCreateEditPostDialog } = usePostDialog();
  const { activeStreak, isPoweredUp, showClaimButton, streakCountdown } =
    storeToRefs(streakStore);
  const { setStreakCountdown } = streakStore;
  const onboardingStore = useOnboardingStore();
  const { onboardingState } = storeToRefs(onboardingStore);
  const { globalLiveData } = storeToRefs(globalStore);

  const dialogCommonClasses = `${dialogDefaults.dialog.contentClasses} relative w-full max-w-[975px] min-h-[770px] p-[50px] streak-modal overflow-y-auto thin-scrollbar scrollbar-transparent`;
  const drawerCommonClasses =
    'w-full px-2 pt-2 pb-10 border border-gray-785 border-opacity-50 rounded-t-2xl streak-modal';

  const streakCount = computed(
    () => globalLiveData.value?.streak_count ?? BigInt(0),
  );

  const totalStreakCount = computed(
    () => (activeStreak.value && activeStreak.value.next_claim_day - 1) ?? 0,
  );

  const highestStreakCount = computed(
    () =>
      (activeStreak.value?.lifetime_longest_streak &&
        activeStreak.value.lifetime_longest_streak[0]) ??
      undefined,
  );

  const lifetimeAirdropMultiplier = computed(
    () =>
      (activeStreak.value?.lifetime_airdrop_multipliers &&
        activeStreak.value.lifetime_airdrop_multipliers[0]) ??
      undefined,
  );

  const activeStreakMultiplier = computed(
    () => activeStreak.value?.airdrop_multiplier_this_streak ?? 0,
  );

  const hasActiveStreak = computed(
    () => activeStreak.value && activeStreak.value?.next_claim_day > 1,
  );

  const isUserOnboarding = computed(() => {
    return onboardingState.value.currentStep === OnboardingStep.CLAIM_LOOTBOX;
  });

  const showDailyStreakDialog = () => {
    openConfiguredDialog({
      opened: true,
      content: {
        component: DailyStreakDialogContent,
        props: {
          nextClaimAt: activeStreak.value?.next_claim_at,
        },
      },
      header: {
        component: DailyStreakDialogHeader,
      },
      dialog: {
        contentClasses: dialogCommonClasses,
      },
      drawer: {
        customClasses: drawerCommonClasses,
        zIndex: 'z-[70]',
      },
    });
  };

  const showLearnMoreDialog = () => {
    openConfiguredDialog({
      opened: true,
      content: {
        component: LearnMoreDialogContent,
      },
      header: {
        component: LearnMoreDialogHeader,
      },
      dialog: {
        contentClasses: dialogCommonClasses,
      },
      drawer: {
        customClasses: drawerCommonClasses,
        zIndex: 'z-[70]',
      },
    });
  };

  const showEmailVerificationDialog = () => {
    openConfiguredDialog({
      opened: true,
      content: {
        component: EmailVerificationDialogContent,
      },
      header: {
        component: EmailVerificationDialogHeader,
      },
      dialog: {
        contentClasses:
          'h-full md:rounded-xl md:h-auto md:max-w-[420px] pt-16 pb-8 px-8',
      },
      drawer: {
        customClasses: drawerCommonClasses,
      },
    });
  };

  const showShareDialog = (postBody: string) => {
    openConfiguredDialog({
      opened: true,
      content: {
        component: ShareStreakDialogContent,
        props: {
          body: postBody,
        },
      },
      dialog: {
        contentClasses:
          'h-full md:rounded-xl md:h-auto md:max-w-[456px] pb-8 overflow-y-auto pt-16 px-8 pb-8',
      },
      drawer: {
        customClasses:
          'z-30 bg-gray-975 border border-gray-785 border-opacity-50 rounded-t-2xl',
      },
    });
  };

  const showCompletionDialog = () => {
    openConfiguredDialog({
      opened: true,
      content: {
        component: CompletionDialogContent,
      },
      header: {
        component: CompletionDialogHeader,
      },
      dialog: {
        contentClasses: dialogCommonClasses,
      },
      drawer: {
        customClasses: drawerCommonClasses,
      },
    });
  };

  const updateActiveStreakWindow = async () => {
    const activeWindowResult = await useActiveWindowQuery();
    if ('Ok' in activeWindowResult) {
      activeStreak.value = activeWindowResult.Ok as ActiveStreakWindow;
    }
  };

  const onClaimStreak = async () => {
    const activeWindowResult = await useClaimDailyStreakQuery();
    if ('Ok' in activeWindowResult) {
      activeStreak.value = activeWindowResult.Ok;
      showClaimButton.value = false;
      isPoweredUp.value = true;
      setStreakCountdown(activeStreak.value.next_claim_at);
      setTimeout(() => {
        isPoweredUp.value = false;
        if (isUserOnboarding.value) {
          closeDialog();
        }
        if (
          activeStreak.value?.start_day === activeStreak.value?.next_claim_day
        ) {
          showCompletionDialog();
        }
      }, 3000);
    }
  };

  const onShareStreak = () => {
    closeDialog();
    const initialContent = `<p></p><embedded-streak days="${
      activeStreak.value?.start_day.toString() ?? '0'
    }" multiplier="${Number(
      activeStreak.value?.airdrop_multiplier_this_streak,
    ).toString()}" points="${Number(
      activeStreak.value?.total_points_this_streak,
    ).toString()}" username="${currentUser.value.username}"></embedded-streak>`;
    openCreateEditPostDialog({ initialContent });
  };

  onMounted(() => {
    if (activeStreak.value === undefined && isLoggedIn.value) {
      updateActiveStreakWindow();
    }
  });

  return {
    activeStreak,
    hasActiveStreak,
    isUserOnboarding,
    isPoweredUp,
    onClaimStreak,
    onShareStreak,
    showClaimButton,
    showCompletionDialog,
    showDailyStreakDialog,
    showLearnMoreDialog,
    showShareDialog,
    streakCount,
    setStreakCountdown,
    streakCountdown,
    totalStreakCount,
    highestStreakCount,
    lifetimeAirdropMultiplier,
    activeStreakMultiplier,
    updateActiveStreakWindow,
    showEmailVerificationDialog,
  };
};
