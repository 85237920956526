<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import { Tippy } from 'vue-tippy';
  import type { Modifier } from '@popperjs/core';
  import type { BaseDropdownProps } from './types';
  import { useDocumentScroll } from '../../model/composables/use-document-scroll';
  import type { TippyRef } from '../base-tooltip';

  const props = withDefaults(defineProps<BaseDropdownProps>(), {
    opened: false,
    placement: 'bottom',
    distance: 10,
    skidding: 0,
    maxWidth: 'none',
    appendToBody: false,
    tag: 'div',
  });

  const emit = defineEmits<{ (e: 'update:opened', opened: boolean): void }>();

  const tippyRef = ref<TippyRef>();
  useDocumentScroll({
    hideOnScroll: () => {
      if (tippyRef.value) {
        tippyRef.value.hide();
      }
    },
  });

  const appendTo = computed(() =>
    props.appendToBody ? document.body : undefined,
  );

  const sameWidthModifier: Modifier<string, {}> = {
    enabled: true,
    fn: ({ instance, state }) => {
      const triggerReferenceWidth = `${state.rects.reference.width}px`;

      if (state.styles.popper.width !== triggerReferenceWidth) {
        state.styles.popper.width = triggerReferenceWidth;
        instance.update();
      }
    },
    name: 'sameWidth',
    phase: 'beforeWrite',
    requires: ['computeStyles'],
  };

  const modifiers = computed(() =>
    props.matchContentWidth ? [sameWidthModifier] : [],
  );

  watch(
    () => props.opened,
    (opened) => {
      if (tippyRef.value) {
        if (opened && !tippyRef.value.state.isShown) {
          tippyRef.value.show();
        } else if (!opened && tippyRef.value.state.isShown) {
          tippyRef.value.hide();
        }
      }
    },
  );

  watch(
    () => props.disabled,
    (disabled) => {
      if (tippyRef.value) {
        if (disabled) {
          tippyRef.value.disable();
        } else {
          tippyRef.value.enable();
        }
      }
    },
  );

  /*
  isVisible: indicates when the menu start to be visible
  isShown: indicates when the menu is fully visible
  */
  watch(
    () => tippyRef.value?.state?.isShown,
    (isShown) => {
      if (isShown && !props.opened) {
        emit('update:opened', true);
      } else if (!isShown && props.opened) {
        emit('update:opened', false);
      }
    },
  );

  const toggle = () => {
    if (tippyRef.value && tippyRef.value.state) {
      const isShown = tippyRef.value.state.isShown;
      if (isShown) {
        tippyRef.value.hide();
      } else {
        tippyRef.value.show();
      }
    }
  };
</script>

<template>
  <tippy
    ref="tippyRef"
    interactive
    trigger="manual"
    theme="transparent"
    :placement="placement"
    :arrow="false"
    :class="customClasses"
    :max-width="maxWidth"
    :offset="[skidding, distance]"
    :append-to="appendTo"
    :tag="tag"
    :popper-options="{ modifiers }"
  >
    <template #default="{ state }">
      <slot
        name="button"
        :toggle="toggle"
        :is-shown="state.isShown"
        :is-visible="state.isVisible"
      />
    </template>
    <template #content="{ hide, state }">
      <div
        v-if="state.isVisible || state.isShown"
        class="rounded-xl overflow-hidden"
        :class="customContentClasses"
      >
        <slot
          name="content"
          :hide="hide"
          :is-shown="state.isShown"
          :is-visible="state.isVisible"
        />
      </div>
    </template>
  </tippy>
</template>
