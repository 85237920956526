export const wisdomSentences = [
  "Some days you're the lighthouse, and other days you're the ship lost at sea.",
  "There are times you're the blooming flower, and others you're the seed buried in the dirt.",
  "Some days you're the soaring eagle, and others you're the wind it fights against.",
  "You're the painter of a masterpiece some days, and the blank canvas on others.",
  "Some days you're the mountain climber, and others you're the rock that slides down.",
  "There are times you're the sunrise, and others you're the darkest hour before dawn.",
  "Some days you're the melody, and others you're the silent pause between notes.",
  'You can be the flowing river some days, and the stubborn rock on others.',
  "There are moments you're the tree standing tall, and others you're the leaf carried by the wind.",
  "Some days you're the puzzle piece that fits perfectly, and others you're the one that just won't find its place.",
];
