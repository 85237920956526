<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useAuth } from '@/entities/auth';
  import { useUser } from '@/entities/user';

  const { t } = useI18n({
    useScope: 'global',
  });
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
</script>

<template>
  <div
    v-if="!isLoggedIn"
    class="flex justify-between items-center gap-4 rounded-xl py-4 px-6 bg-indigo-950 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.24)]"
  >
    <div class="flex items-center gap-6">
      <img
        :src="
          fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/treasure-chest.gif`,
          )
        "
        class="flex-none w-16 h-auto"
      />
      <div class="text-md md:text-lg font-semibold tracking-[0.18px]">
        {{ t('onboarding.newUser') }}
      </div>
    </div>
    <base-button
      variant="primary"
      size="small"
      @click="showLoginSignUpDialog('signup')"
      class="flex-none"
    >
      {{ t('signup') }}
    </base-button>
  </div>
</template>
