import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useScroll } from '@vueuse/core';

export const useScrollStore = defineStore('scroll', () => {
  const documentOnScrollStopY = ref(0);
  const distanceToBottom = ref<number>();

  // This is needed so android bottom arrive state works,
  const calculateArriveState = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = Math.abs(document.documentElement.scrollTop);
    // current implementation in vueuse https://github.com/vueuse/vueuse/blob/main/packages/core/useScroll/index.ts#L195
    // uses document.documentElement.clientHeight
    // which does not include the device header and footer
    // window.innerHeight solves that issue
    const clientHeight = window.innerHeight;
    const scrollBottomPosition = scrollTop + clientHeight;
    distanceToBottom.value = scrollHeight - scrollBottomPosition;
  };

  const { y, directions, arrivedState, isScrolling } = useScroll(document, {
    onStop: () => {
      documentOnScrollStopY.value = y.value;
      calculateArriveState();
    },
    onScroll: () => {
      calculateArriveState();
    },
  });

  return {
    y,
    directions,
    isScrolling,
    arrivedState,
    documentOnScrollStopY,
    distanceToBottom,
  };
});
