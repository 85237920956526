<script setup lang="ts">
  import { useVModel } from '@vueuse/core';
  import { EmojiPicker } from '@/features/live/emoji-picker';
  import { RaiseHandButton } from '@/features/live/raise-hand';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import type { PanelViewMode } from '../types';

  const props = withDefaults(
    defineProps<{
      modelValue: PanelViewMode;
      isMicOn: boolean;
      isSpeaker: boolean;
      messageCount: number;
    }>(),
    {
      modelValue: null,
    },
  );

  const emit = defineEmits<{
    (e: 'update:modelValue'): void;
    (e: 'update:isMicOn'): void;
  }>();

  const mode = useVModel(props, 'modelValue', emit);
  const isMicOnModel = useVModel(props, 'isMicOn', emit);

  function toggleMode(value: PanelViewMode) {
    if (mode.value === value) {
      mode.value = null;
    } else {
      mode.value = value;
    }
  }
</script>

<template>
  <div
    class="flex flex-col px-8 py-5 bg-gray-990 border-t border-gray-785 border-opacity-50 md:rounded-b-2.5xl"
  >
    <div class="flex justify-between items-center">
      <base-icon
        :name="isMicOnModel ? 'mini-mic-on' : 'mini-mic-off'"
        size="w-12 h-12"
        class="cursor-pointer p-2 rounded-full bg-gray-40 bg-opacity-40 hover:brightness-150 transition duration-500"
        :class="{
          'text-pink-500': !isMicOnModel,
          'text-gray-200': isMicOnModel,
        }"
        @click="isMicOnModel = !isMicOnModel"
      />
      <base-icon
        name="people-outline"
        size="w-12 h-12"
        class="cursor-pointer p-2 rounded-full hover:bg-gray-40 hover:bg-opacity-20 transition duration-500"
        :class="
          mode === 'users' ? 'text-white' : 'border-opacity-0 text-gray-400'
        "
        @click="toggleMode('users')"
      />

      <div class="relative">
        <base-dropdown
          placement="top-start"
          custom-content-classes="relative -right-30"
        >
          <template #button="{ isShown, toggle }">
            <base-icon
              name="emoji-plus"
              size="w-12 h-12"
              class="cursor-pointer p-2 rounded-full hover:bg-gray-40 hover:bg-opacity-20 transition duration-500"
              :class="isShown ? 'text-white' : 'border-opacity-0 text-gray-400'"
              @click="toggle"
            />
          </template>
          <template #content="{ hide }">
            <!-- Emoji Picker -->
            <emoji-picker @hide="hide()">
              <template v-if="!isSpeaker" #action>
                <raise-hand-button />
              </template>
            </emoji-picker>
          </template>
        </base-dropdown>
      </div>
      <div
        class="flex items-center cursor-pointer px-2 h-10 rounded-r-full rounded-l-full hover:bg-gray-40 hover:bg-opacity-20 hover:text-white transition duration-500"
        :class="
          mode === 'chat'
            ? 'border-opacity-10 text-white brightness-5'
            : 'border-opacity-0 text-gray-400'
        "
        @click="toggleMode('chat')"
      >
        <base-icon name="chat-outline" size="w-7 h-7" />
        <span class="ml-1">
          {{ messageCount }}
        </span>
      </div>
    </div>
  </div>
</template>
