import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import type { DialogState } from './types';

export const useDialogStore = defineStore('dialog', () => {
  const dialog: Ref<DialogState> = ref({
    opened: false,
  });

  const $reset = () => {
    dialog.value = {
      opened: false,
    };
  };

  return {
    $reset,
    dialog,
  };
});
