import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { userPostsListKey } from '@/shared/model';

export const usePinUserContentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['pin-user-content-mutation'],
    mutationFn: (params: dscvrIcApi.post.PinContentParams) =>
      dscvrIcApi.post.pinUserContent(params),
    onSettled: () => {
      queryClient.resetQueries({
        queryKey: [userPostsListKey],
      });
      window.scrollTo(0, 0);
    },
  });
};
