import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { config } from '@/shared/lib';
import { usePkce, useOauth2State, useToast } from '@/shared/model';
import {
  useLinkMatricaWalletMutation,
  useMatricaLinkStatusQuery,
  useRefreshMatricaWalletsMutation,
  useUnlinkMatricaWalletMutation,
  useWalletPairing,
} from '@/entities/wallets';
import { trackEvent } from '@/utils/tracker';

export const useMatrica = () => {
  const { pairedWallets, isFetchingPairedWallets: walletIsLoading } =
    useWalletPairing();
  const { showToast } = useToast();
  const isLoadingMatrica = ref(false);
  const { t } = useI18n();

  const { generateAndStorePkce } = usePkce();
  const { generateAndStoreOauth2State } = useOauth2State();

  const matricaWallets = computed(() => {
    return pairedWallets.value.filter((wallet) =>
      wallet.thirdPartyLinked?.includes('matrica'),
    );
  });

  const isLoading = computed(
    () => walletIsLoading.value && isLoadingMatrica.value,
  );

  const checkLinkStatus = async (): Promise<boolean> => {
    try {
      isLoadingMatrica.value = true;
      const { data } = await useMatricaLinkStatusQuery();
      return data.data?.status ?? false;
    } catch (error) {
      return false;
    } finally {
      isLoadingMatrica.value = false;
    }
  };

  const linkMatricaWallet = async (code: string): Promise<void> => {
    try {
      isLoadingMatrica.value = true;
      await useLinkMatricaWalletMutation(code);
    } catch (e) {
      const err = e as Error;
      showToast({
        id: 'link-matrica-wallets',
        title: t('wallets.matrica.linkWalletLinkErrorTitle'),
        description: `${t('wallets.matrica.linkWalletLinkErrorDescription')} ${
          err.message
        }`,
        type: 'error',
      });
      trackEvent('user_settings_action', 'link_matrica_error', 'matrica');
    } finally {
      isLoadingMatrica.value = false;
    }
  };

  const refreshMatricaWallets = async (): Promise<void> => {
    isLoadingMatrica.value = true;
    await useRefreshMatricaWalletsMutation()
      .then((response) => {
        console.log(response);
        showToast({
          id: 'refresh-matrica-wallets',
          title: t('wallets.matrica.walletsRefreshedTitle'),
          description: t('wallets.matrica.walletsRefreshedDescription'),
          type: 'success',
        });
      })
      .catch((error) => {
        let errMsg = '';
        if (error?.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          errMsg = error?.response?.data?.message;
          trackEvent(
            'user_settings_action',
            'refresh_wallets_error',
            'matrica',
          );
        }
        showToast({
          id: 'refresh-matrica-wallets',
          title: t('wallets.matrica.refreshWalletsErrorTitle'),
          description: `${t(
            'wallets.matrica.refreshWalletsErrorDescription',
          )} ${errMsg}`,
          type: 'error',
        });
      })
      .finally(() => {
        isLoadingMatrica.value = false;
      });
  };

  const unlinkMatricaWallet = async (): Promise<void> => {
    try {
      isLoadingMatrica.value = true;
      await useUnlinkMatricaWalletMutation();
    } catch (e) {
      const err = e as Error;
      showToast({
        id: 'unlink-matrica-wallets',
        title: t('wallets.matrica.linkWalletUnlinkErrorTitle'),
        description: `${t('matrica.linkWalletUnlinkErrorDescription')} ${
          err.message
        }`,
        type: 'error',
      });
    } finally {
      isLoadingMatrica.value = false;
    }
  };

  const getLocalPkceVerifier = async (): Promise<string> => {
    const result = await generateAndStorePkce();
    return result.code_challenge;
  };

  const getMatricaAuthUrl = async (): Promise<string> => {
    const pkceChallenge = await getLocalPkceVerifier();
    const state = generateAndStoreOauth2State();
    const redirectUri = `${window.location.origin}/oauth2/matrica/callback`;
    const authUrl = `${config.MATRICA_AUTHORIZATION_URL}?client_id=${
      config.MATRICA_CLIENT_ID
    }&response_type=code&scope=${
      config.MATRICA_SCOPES
    }&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&code_challenge=${pkceChallenge}&code_challenge_method=S256&state=${state}`;
    return authUrl;
  };

  return {
    matricaWallets,
    isLoading,
    checkLinkStatus,
    refreshMatricaWallets,
    unlinkMatricaWallet,
    linkMatricaWallet,
    getMatricaAuthUrl,
  };
};
