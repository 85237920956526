import { dscvrIcApi } from '@/shared/api';
import { useQuery } from '@tanstack/vue-query';
import { joinedPortalsKey, portalUserStatusKey } from './keys';

export const useGetJoinedPortalsQuery = () => {
  return useQuery({
    queryKey: [portalUserStatusKey, joinedPortalsKey],
    queryFn: dscvrIcApi.portal.getJoinedPortals,
    staleTime: 1000 * 60 * 5,
  });
};
