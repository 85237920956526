<script setup lang="ts">
  import { computed } from 'vue';
  import { useForm } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import * as yup from 'yup';
  import { DSCVR_STATIC_ASSETS_CDN_URL, IS_HIDDEN } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { SelectFormField, TextFormField } from '@/shared/ui/form-fields';
  import type { ReportTransactionPayload } from '../types';

  type Reason = {
    value: string;
    label: string;
  };
  type FormFields = {
    reason?: Reason;
    comments?: string;
  };
  defineProps<{
    signedTx: string;
  }>();

  const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'report', payload: ReportTransactionPayload): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });

  const reasons: Reason[] = [
    { value: 'fraud', label: t('walletTransaction.reasonFraud') },
    { value: 'spam', label: t('walletTransaction.reasonSpam') },
    { value: 'other', label: t('walletTransaction.reasonOther') },
  ];

  const schema = yup.object({
    reason: yup
      .object<Reason>()
      .required(t('walletTransaction.reasonErrorRequired')),
    comments: yup
      .string()
      .max(1000)
      .when('reason', {
        is: (reason?: Reason) => reason && reason.value === 'other',
        then: () =>
          yup.string().required(t('walletTransaction.commentsErrorRequired')),
      }),
  });

  const initialValues = computed(() => {
    return {
      reason: reasons[0],
      comments: '',
    };
  });

  const { handleSubmit, errors } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });

  const submit = handleSubmit((values) => {
    emit('report', {
      reason: values.reason!.value,
      comments: values.comments,
    });
  });
</script>

<template>
  <div class="flex flex-col items-center gap-4 p-6">
    <img
      v-lazy="
        fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/frames/chatbot-warning.gif`)
      "
      :alt="$t('walletTransaction.transactionWarning')"
      class="w-64"
    />
    <h2 class="font-bold text-2xl">
      {{ $t('walletTransaction.transactionReportTitle') }}
    </h2>
    <form class="flex flex-col gap-8 mt-8 w-full" @submit="submit">
      <div v-if="!IS_HIDDEN" class="flex flex-col gap-8">
        <select-form-field
          name="reason"
          :items="reasons"
          :label="$t('walletTransaction.reason')"
          :error="errors.reason"
        />
        <text-form-field
          name="comments"
          autocomplete="off"
          multiline
          :rows="3"
          :label="$t('comments')"
          :error="errors.comments"
        />
      </div>
      <div class="flex flex-col gap-2">
        <base-button variant="destructive" size="small">
          {{ $t('walletTransaction.report') }}
        </base-button>

        <base-button variant="tertiary" size="small" @click="$emit('close')">
          <span>{{ $t('cancel') }}</span>
        </base-button>
      </div>
    </form>
  </div>
</template>
