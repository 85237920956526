const devtools = {
  install(app) {
    if (import.meta.env.DEV && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
      window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
      // window.__VUE_DEVTOOLS_GLOBAL_HOOK__.store = store
    }
  },
};

export { devtools };
