import type { IconName } from '@/shared/ui/base-icon';

export type SocialNetworkType =
  | 'Discord'
  | 'Facebook'
  | 'Instagram'
  | 'LinkedIn'
  | 'OpenChat'
  | 'Pinterest'
  | 'Reddit'
  | 'Spotify'
  | 'Telegram'
  | 'TikTok'
  | 'Twitch'
  | 'Twitter'
  | 'Vimeo'
  | 'YouTube'
  | 'Atlas3'
  | 'Website';

export interface SocialNetwork {
  icon: IconName;
  iconDesaturated: IconName;
  matches: (string | RegExp)[];
  matchesGroup?: RegExp[];
  matchesUser?: RegExp[];
  groupName?: string;
  example: string;
  type: SocialNetworkType;
  sort: number;
  beta?: boolean;
}

export interface SocialLink {
  link: string;
  socialNetwork: SocialNetwork;
  user?: string;
  group?: string;
}

export const GenericSocialNetwork: SocialNetwork = {
  icon: 'social-web',
  iconDesaturated: 'social-web-desaturated',
  matches: [],
  example: '',
  type: 'Website',
  sort: 1,
};

export const SocialNetworks: Omit<SocialNetwork, 'sort'>[] = [
  {
    icon: 'social-atlas3',
    iconDesaturated: 'social-atlas3-desaturated',
    matches: [/^(https:\/\/)?atlas3.io/],
    matchesGroup: [/project\/(?<project>[^\/]+)/],
    groupName: 'project',
    example: 'atlas3.io/project/<projectname>',
    type: 'Atlas3',
    beta: true,
  },
  {
    icon: 'social-discord',
    iconDesaturated: 'social-discord-desaturated',
    matches: [/^(https:\/\/)?(www\.)?discord/],
    example: 'discord.gg/<invite>',
    type: 'Discord',
  },
  {
    icon: 'social-twitter',
    iconDesaturated: 'social-twitter-desaturated',
    matches: [/^(https:\/\/)?(www\.)?twitter/, /^(https:\/\/)?(www\.)?x.com/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'twitter.com/<username>',
    type: 'Twitter',
  },
  {
    icon: 'social-instagram',
    iconDesaturated: 'social-instagram-desaturated',
    matches: [/^(https:\/\/)?(www\.)?instagram/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'instagram.com/<username>',
    type: 'Instagram',
  },
  {
    icon: 'social-telegram',
    iconDesaturated: 'social-telegram-desaturated',
    matches: [/^(https:\/\/)?(web\.)?telegram/, /^(https:\/\/)?t\.me/],
    example: 't.me/<invite>',
    type: 'Telegram',
  },
  {
    icon: 'social-tiktok',
    iconDesaturated: 'social-tiktok-desaturated',
    matches: [/^(https:\/\/)?(www\.)?tiktok/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'tiktok.com/<username>',
    type: 'TikTok',
  },
  {
    icon: 'social-youtube',
    iconDesaturated: 'social-youtube-desaturated',
    matches: [/^(https:\/\/)?(www\.)?(youtu\.be|youtube(-nocookie)?\.com)/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'youtube.com/<username>',
    type: 'YouTube',
  },
  {
    icon: 'social-spotify-desaturated',
    iconDesaturated: 'social-spotify-desaturated',
    matches: [/^(https:\/\/)?open\.spotify.com/],
    matchesUser: [/user\/(?<user>[^\/]+)/, /artist\/(?<user>[^\/]+)/],
    example: 'open.spotify.com/user/<username>',
    type: 'Spotify',
  },
  {
    icon: 'social-facebook',
    iconDesaturated: 'social-facebook-desaturated',
    matches: [/^(https:\/\/)?(www\.)?facebook/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'facebook.com/<username>',
    type: 'Facebook',
  },
  {
    icon: 'social-linkedin',
    iconDesaturated: 'social-linkedIn-desaturated',
    matches: [/^(https:\/\/)?(www\.)?linkedin.com/],
    matchesUser: [/in\/(?<user>[^\/]+)/],
    example: 'linkedin.com/in/<username>',
    type: 'LinkedIn',
  },
  {
    icon: 'social-twitch',
    iconDesaturated: 'social-twitch-desaturated',
    matches: [/^(https:\/\/)?(www\.)?twitch/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'twitch.tv/<username>',
    type: 'Twitch',
  },
  {
    icon: 'social-pinterest-desaturated',
    iconDesaturated: 'social-pinterest-desaturated',
    matches: [/^(https:\/\/)?(www\.)?pinterest.com/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'pinterest.com/<username>',
    type: 'Pinterest',
  },
  {
    icon: 'social-vimeo-desaturated',
    iconDesaturated: 'social-vimeo-desaturated',
    matches: [/^(https:\/\/)?(www\.)?vimeo.com/],
    matchesUser: [/(?<user>[^\/]+)/],
    example: 'vimeo.com/<username>',
    type: 'Vimeo',
  },
  {
    icon: 'social-reddit-desaturated',
    iconDesaturated: 'social-reddit-desaturated',
    matches: [/^(https:\/\/)?(www\.)?reddit.com/],
    matchesUser: [/r\/(?<user>[^\/]+)/, /user\/(?<user>[^\/]+)/],
    example: 'reddit.com/r/<username>',
    type: 'Reddit',
  },
  {
    icon: 'social-open-chat',
    iconDesaturated: 'social-open-chat-desaturated',
    matches: [/^(https:\/\/)?oc.app/],
    matchesUser: [
      /community\/(?<user>[^\/]+)/,
      /group\/(?<user>[^\/]+)/,
      /user\/(?<user>[^\/]+)/,
    ],
    example: 'oc.app/user/<username>',
    type: 'OpenChat',
  },
];
