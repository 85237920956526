import { type Ref, computed, unref } from 'vue';
import {
  type ClampState,
  type ClampOptions,
  initClampState,
  isLineBreakTag,
  appendPlainText,
  finalizeClamp,
  textBeforeBeginTagRegExp,
  textBeforeEndTagRegExp,
} from '@/utils';

/**
 *
 * @param html
 * @param maxLineBreaks
 * @param overflowMarker
 */
function clampHtmlByLines(
  html: string,
  maxLineBreaks: number,
  overflowMarker: string,
): ClampState {
  const clampState = initClampState(html);

  while (
    clampState.lineBreaksCount < maxLineBreaks &&
    clampState.remainingHtml.length > 0
  ) {
    const textBeforeBeginTagMatch = textBeforeBeginTagRegExp.exec(
      clampState.remainingHtml,
    );

    if (textBeforeBeginTagMatch) {
      const plainText = textBeforeBeginTagMatch.groups?.plainText || '';
      const tag = textBeforeBeginTagMatch.groups?.tag || '';
      const selfClosedTag = textBeforeBeginTagMatch.groups?.selfClosed || '';

      clampState.htmlResult += textBeforeBeginTagMatch[0];
      clampState.remainingHtml = clampState.remainingHtml.substring(
        textBeforeBeginTagMatch.index + textBeforeBeginTagMatch[0].length,
      );

      if (plainText.length > 0 && isLineBreakTag(tag)) {
        clampState.lineBreaksCount++;
      }

      if (selfClosedTag) {
        if (isLineBreakTag(tag)) {
          clampState.lineBreaksCount++;
        }
      } else if (tag !== 'br') {
        // br is allowed to exists without closing tag
        clampState.openTags.push(tag);
      }
      continue;
    }

    const textBeforeEndTagMatch = textBeforeEndTagRegExp.exec(
      clampState.remainingHtml,
    );

    if (textBeforeEndTagMatch) {
      const tag = textBeforeEndTagMatch.groups?.tag || '';

      clampState.openTags.pop();
      clampState.htmlResult += textBeforeEndTagMatch[0];
      clampState.remainingHtml = clampState.remainingHtml.substring(
        textBeforeEndTagMatch.index + textBeforeEndTagMatch[0].length,
      );

      if (isLineBreakTag(tag)) {
        clampState.lineBreaksCount++;
      }
      continue;
    }

    // remainingHtml is pure text
    clampState.htmlResult += clampState.remainingHtml;
    clampState.remainingHtml = '';
    clampState.lineBreaksCount++;
    break;
  }

  return finalizeClamp(clampState, overflowMarker);
}

/**
 *
 * @param html
 * @param maxCharacters
 * @param overflowMarker
 */
function clampHtmlByCharacters(
  html: string,
  maxCharacters: number,
  overflowMarker: string,
): ClampState {
  const clampState = initClampState(html);

  while (
    clampState.charactersCount <= maxCharacters &&
    clampState.remainingHtml.length > 0
  ) {
    const textBeforeBeginTagMatch = textBeforeBeginTagRegExp.exec(
      clampState.remainingHtml,
    );
    if (textBeforeBeginTagMatch) {
      const plainText = textBeforeBeginTagMatch.groups?.plainText || '';
      const tag = textBeforeBeginTagMatch.groups?.tag || '';
      const selfClosed = textBeforeBeginTagMatch.groups?.selfClosed || '';

      appendPlainText(
        clampState,
        maxCharacters,
        plainText,
        textBeforeBeginTagMatch,
      );
      if (clampState.clamped) {
        break;
      }

      if (!selfClosed) {
        clampState.openTags.push(tag);
      }

      continue;
    }

    const textBeforeEndTagMatch = textBeforeEndTagRegExp.exec(
      clampState.remainingHtml,
    );

    if (textBeforeEndTagMatch) {
      const plainText = textBeforeEndTagMatch?.groups?.plainText || '';

      appendPlainText(
        clampState,
        maxCharacters,
        plainText,
        textBeforeEndTagMatch,
      );
      if (clampState.clamped) {
        break;
      }

      clampState.openTags.pop();

      continue;
    }

    // remainingHtml is pure text
    appendPlainText(clampState, maxCharacters, clampState.remainingHtml);
    break;
  }

  return finalizeClamp(clampState, overflowMarker);
}
/// Clamp html first by number of lines then by characters count
/// It assumes input html is well formated, sanitized and has been stripped of pre/code and script tags
/**
 *
 * @param html
 * @param options
 */
function clampHtml(html: string, options: ClampOptions): string {
  const { maxCharacters, maxLineBreaks = 3, overflowMarker = '...' } = options;

  const clampByLinesState = clampHtmlByLines(
    html,
    maxLineBreaks,
    overflowMarker,
  );

  const clampByCharactersState = clampHtmlByCharacters(
    clampByLinesState.htmlResultWithOverflowMarker,
    maxCharacters,
    overflowMarker,
  );

  if (clampByCharactersState.clamped) {
    return clampByCharactersState.htmlResult;
  }

  return clampByLinesState.htmlResult;
}

/**
 *
 * @param html
 * @param options
 */
export function useHtmlClamp(html: Ref<string>, options: Ref<ClampOptions>) {
  const clampedHtml = computed(() => clampHtml(unref(html), unref(options)));

  return {
    clampedHtml,
    clampHtml,
  };
}
