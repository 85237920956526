<template>
  <div class="mb-4 text-white height">
    <div class="flex items-center justify-between">
      <div class="text-lg font-semibold title">Popular Portals</div>
      <div v-show="trendingPortals.length > pageSize" class="flex gap-2">
        <button
          class="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer hover:bg-gray-785 active:bg-gray-950"
          @click="decrementPage"
        >
          <base-icon name="chevron-left" size="w-5 h-5" />
        </button>
        <button
          class="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer hover:bg-gray-785 active:bg-gray-950"
          @click="incrementPage"
        >
          <base-icon name="chevron-right" size="w-5 h-5" />
        </button>
      </div>
    </div>
    <div class="flex gap-4 mt-3" :class="inRows ? 'flex-row' : 'flex-col'">
      <div
        v-if="!trendingPortalsFiltered || trendingPortalsFiltered.length == 0"
        class="flex items-center justify-center space-x-4"
      >
        <div class="relative pt-2 text-center text-white w-14 h-14">
          <div
            class="transform translate-x-1.5 translate-y-6 spinner-big"
          ></div>
        </div>
      </div>
      <div
        v-for="portal in trendingPortalsFiltered"
        :key="portal.id"
        class="relative h-40 hover:brightness-110 filter"
      >
        <cover-photo
          class="rounded-xl"
          :src="portal.info[0]?.cover_photo[0]"
          :gradients="coverGradients"
        />
        <div class="absolute bottom-0 w-full h-20 p-4 overlay rounded-b-xl">
          <router-link
            :to="{
              name: PORTAL_CONTENT,
              params: { slug: portal.slug },
            }"
            @click="$emit('closeMenu')"
          >
            <div class="flex items-center w-full h-full">
              <img
                class="rounded-lg size-12"
                :src="getPortalIcon(portal)"
                alt="Portal Icon"
              />
              <div class="flex flex-col m-3">
                <div class="text-sm font-semibold break-words lg:text-lg">
                  {{ portal.name }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: EXPLORE_PORTALS }"
      class="flex items-center justify-end mt-4 text-sm font-medium text-gray-400 cursor-pointer gap-x-2 hover:text-white ease-transition-all"
    >
      <base-icon name="compass" class="!h-3.5 !w-3.5" />
      Explore more
    </router-link>
  </div>
</template>

<script>
  import portalService from '@/services/portals';
  import CMS from '@/services/cms';
  import { PORTAL_CONTENT, EXPLORE_PORTALS } from '@/common';
  import { getPortalIcon, coverGradients } from '@/entities/portal';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  export default {
    name: 'trending-portals',
    components: {
      CoverPhoto,
    },
    props: {
      inRows: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['closeMenu'],
    data() {
      return {
        PORTAL_CONTENT,
        EXPLORE_PORTALS,
        trendingPortalsSlugs: ['dscvr', 'general', 'bugs', 'feature-requests'],
        trendingPortals: [],
        startIndex: 0,
        pageSize: this.inRows ? 2 : 3,
        getPortalIcon,
        coverGradients,
      };
    },
    computed: {
      trendingPortalsFiltered() {
        return this.trendingPortals.slice(
          this.startIndex,
          this.startIndex + this.pageSize,
        );
      },
    },
    async mounted() {
      await CMS.getTopPortals().then((result) => {
        if (result?.status == 200) {
          this.trendingPortalsSlugs = [];
          this.trendingPortalsSlugs = [];
          for (let i = 0; i < result.data.length; i++) {
            this.trendingPortalsSlugs.push(
              result.data[i].portal_slug.toString(),
            );
          }
        }
      });
      await portalService
        .getPortals(this.trendingPortalsSlugs)
        .then((result) => {
          if (result?.status == 'happy') {
            this.trendingPortals = result.result[0];
          }
        });
    },
    methods: {
      incrementPage() {
        this.startIndex = Math.min(
          this.trendingPortals.length - this.pageSize,
          this.startIndex + this.pageSize,
        );
      },
      decrementPage() {
        this.startIndex = Math.max(0, this.startIndex - this.pageSize);
      },
    },
  };
</script>
<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(40px);
  }
  .title {
    letter-spacing: -0.01117em;
  }
</style>
