<script setup lang="ts">
  import { computed } from 'vue';
  import { dscvrApi } from '@/shared/api';

  const props = defineProps<{
    collection: dscvrApi.multichainGating.ICPGateDto;
    entrepotCollection: dscvrApi.multichainGating.EntrepotCollectionItem;
  }>();

  const nftUrl = computed<string>(() => {
    const url = props.entrepotCollection.avatar;
    return `background-image:url(${url})`;
  });

  const entrepotLinkPrefix = 'https://entrepot.app/marketplace/';
  const collectionLink = computed(() => {
    if (props.entrepotCollection) {
      return `${entrepotLinkPrefix}${props.entrepotCollection.id}`;
    }
    return undefined;
  });

  const verified = computed(() => {
    return props.collection?.shouldSyncRegistry;
  });
</script>

<template>
  <base-button
    variant="custom"
    custom-classes="h-full rounded-2xl p-6 md:p-10 lg:p-6 shadow-light border border-gray-800 overflow-hidden group relative"
    target="_blank"
    :to="collectionLink"
  >
    <div
      class="absolute inset-0 bg-gradient-to-b from-[#4138CA]/0 via-[#4138CA]/0 to-[#4138CA]/5 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-all duration-500 z-1"
    />
    <article class="flex flex-col gap-6 relative z-10">
      <section
        class="flex flex-col w-full gap-4 squared rounded-xl relative overflow-hidden"
      >
        <div class="absolute bg-cover bg-center inset-0" :style="nftUrl"></div>
        <div
          class="absolute bg-cover bg-center inset-0 opacity-0 group-hover:-inset-4 group-hover:opacity-100 transition-all duration-500"
          :style="nftUrl"
        ></div>
        <div
          class="absolute inset-0 bg-gradient-to-t from-black/0 to-black/70"
        ></div>
        <div class="flex justify-end w-full p-4">
          <base-button
            variant="glass"
            size="small"
            rounded="rounded-2xl"
            class="drop-shadow-light"
          >
            <base-icon name="vuesax-linear-key" size="w-5 h-5" />
            <span>{{ $t('key') }}</span>
          </base-button>
        </div>
      </section>
      <section class="min-h-11 text-left">
        <p class="font-semibold text-indigo-300 text-lg">
          <span>{{ collection.name }}</span>
          <base-icon
            v-if="verified"
            name="filled-account-verified"
            size="w-4 h-4"
            class="inline-block ml-1 -mt-1"
          />
        </p>
        <p class="uppercase">
          <base-icon
            name="chain-icp"
            size="w-5 h-5"
            class="inline-block mr-2 -mt-1"
          />
          <span>ICP</span>
        </p>
      </section>
    </article>
  </base-button>
</template>
