import { computed, type Ref } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { searchPortalsKey } from './keys';
import type { PortalSearchQuery } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';
import { hasNextPage } from '@/shared/model';

export const useSearchPortalsQuery = (queryParams: Ref<PortalSearchQuery>) => {
  const filters = computed(() => ({
    sort_direction: queryParams.value.sort_direction,
    query: queryParams.value.query,
    sort_by: queryParams.value.sort_by,
    chain_filter: queryParams.value.chain_filter,
  }));

  return useInfiniteQuery({
    queryKey: [searchPortalsKey, filters],
    queryFn: async (ctx) => {
      queryParams.value.page = BigInt(ctx.pageParam);
      const data = await dscvrIcApi.portal.searchPortals(queryParams.value);
      return data.result.length ? data.result[0] : null;
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, __) =>
      hasNextPage(allPages[0]?.total_pages, lastPageParam),
  });
};
