import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

export const shareImage: {
  [key: number]: {
    backgroundColor: string;
    gradientColors: string;
    url: string;
  };
} = {
  7: {
    backgroundColor: 'bg-blue-750',
    gradientColors: 'from-cyan-650 to-teal-125',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/7-meteor-shower.gif`,
    ),
  }, // Week 1
  14: {
    backgroundColor: 'bg-blue-750',
    gradientColors: 'from-indigo-400 to-[#4DC9FE]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/14-meteor-with-purple-planet.gif`,
    ),
  }, // Week 2
  21: {
    backgroundColor: 'bg-[#240052]',
    gradientColors: 'from-[#252659] to-[#4089F6]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/21-earth-with-comets.gif`,
    ),
  }, // Week 3
  28: {
    backgroundColor: 'bg-[#003343]',
    gradientColors: 'from-cyan-650 to-teal-125',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/28-asteroid.gif`,
    ),
  }, // Week 4
  35: {
    backgroundColor: 'bg-[#112047]',
    gradientColors: 'from-indigo-400 to-[#181D42]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/35-rocket-and-black-hole.gif`,
    ),
  }, // Week 5
  42: {
    backgroundColor: 'bg-[#0B0E23]',
    gradientColors: 'from-[#2B3063] to-indigo-400',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/42-rocket-launch.gif`,
    ),
  }, // Week 6
  49: {
    backgroundColor: 'bg-gray-850',
    gradientColors: 'from-[#2F3470] to-[#20AFF1]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/49-rocket-with-flying-saucers.gif`,
    ),
  }, // Week 7
  56: {
    backgroundColor: 'bg-gray-850',
    gradientColors: 'from-[#2F3470] to-[#20AFF1]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/56-rocket-with-asteroids.gif`,
    ),
  }, // Week 8
  63: {
    backgroundColor: 'bg-[#090A2B]',
    gradientColors: 'from-[#43A1C9] to-[#4245A9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/63-rocket-with-planets.gif`,
    ),
  }, // Week 9
  70: {
    backgroundColor: 'bg-[#200D56]',
    gradientColors: 'from-[#2568ED] to-[#8F8CF9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/70-rocket-with-stars-and-planet.gif`,
    ),
  }, // Week 10
  77: {
    backgroundColor: 'bg-[#1B2583]',
    gradientColors: 'from-[#4578EC] to-[#3B38FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/77-rotating-rocket.gif`,
    ),
  }, // Week 11
  84: {
    backgroundColor: 'bg-[#251B63]',
    gradientColors: 'from-[#202DB9] to-[#3CF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/84-bouncing-rocket.gif`,
    ),
  }, // Week 12
  91: {
    backgroundColor: 'bg-[#152C62]',
    gradientColors: 'from-[#43A1C9] to-[#4245A9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/91-astronaut-fishing.gif`,
    ),
  }, // Week 13
  98: {
    backgroundColor: 'bg-[#078DB8]',
    gradientColors: 'from-[#2E657E] to-[#29B3F2]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/98-astronaut-blowing-planet.gif`,
    ),
  }, // Week 14
  105: {
    backgroundColor: 'bg-[#4007B8]',
    gradientColors: 'from-[#4089F6] to-[#19C1FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/105-astronaut-on-swing.gif`,
    ),
  }, // Week 15
  112: {
    backgroundColor: 'bg-[#04173C]',
    gradientColors: 'from-[#0FB58C] to-[#0D8AB4]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/112-astronaut-playing-with-planet.gif`,
    ),
  }, // Week 16
  119: {
    backgroundColor: 'bg-[#291562]',
    gradientColors: 'from-[#43A1C9] to-[#4245A9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/119-astronaut-flying.gif`,
    ),
  }, // Week 17
  126: {
    backgroundColor: 'bg-[#08061C]',
    gradientColors: 'from-[#2636DD] to-[#5840D8]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/126-man-with-jetpack.gif`,
    ),
  }, // Week 18
  133: {
    backgroundColor: 'bg-[#031939]',
    gradientColors: 'from-[#9389CD] to-[#723DE9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/133-man-listening-music-with-robot-dog.gif`,
    ),
  }, // Week 19
  140: {
    backgroundColor: 'bg-[#0F122E]',
    gradientColors: 'from-[#4578EC] to-[#7BA2FE]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/140-woman-and-robot.gif`,
    ),
  }, // Week 20
  147: {
    backgroundColor: 'bg-[#080252]',
    gradientColors: 'from-[#111DB0] to-[#4578EC]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/147-aliens-abducting-a-tree.gif`,
    ),
  }, // Week 21
  154: {
    backgroundColor: 'bg-[#1C0D3F]',
    gradientColors: 'from-[#1D3743] to-[#653EE0]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/154-observatory.gif`,
    ),
  }, // Week 22
  161: {
    backgroundColor: 'bg-[#1A1437]',
    gradientColors: 'from-[#4DC8A9] to-[#4E4482]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/161-ufo-collecting-rocks.gif`,
    ),
  }, // Week 23
  168: {
    backgroundColor: 'bg-[#1A1437]',
    gradientColors: 'from-[#4DC8A9] to-[#4E4482]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/168-ufo-with-bugs.gif`,
    ),
  }, // Week 24
  175: {
    backgroundColor: 'bg-[#2B1989]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/175-robot-scientist.gif`,
    ),
  }, // Week 25
  182: {
    backgroundColor: 'bg-[#1560A5]',
    gradientColors: 'from-[#453C70] to-[#9164FB]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/182-robot_with_plant.gif`,
    ),
  }, // Week 26
  189: {
    backgroundColor: 'bg-[#120428]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/189-floating_eco_world.gif`,
    ),
  }, // Week 27
  196: {
    backgroundColor: 'bg-[#141278]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/196-eco_village.gif`,
    ),
  }, // Week 28
  203: {
    backgroundColor: 'bg-[#201460]',
    gradientColors: 'from-[#3811FC] to-[#723DE9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/203-robot_and_human.gif`,
    ),
  }, // Week 29
  210: {
    backgroundColor: 'bg-[#140F31]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/210-space_wormhole.gif`,
    ),
  }, // Week 30
  217: {
    backgroundColor: 'bg-[#140F31]',
    gradientColors: 'from-[#091CEB] to-[#4AC3FD]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/217-space_exploration.gif`,
    ),
  }, // Week 31
  224: {
    backgroundColor: 'bg-[#150B3D]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/224-robot_with_machine.gif`,
    ),
  }, // Week 32
  231: {
    backgroundColor: 'bg-[#352695]',
    gradientColors: 'from-[#4247F3] to-[#B9A1F3]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/231-rocket_and_brain.gif`,
    ),
  }, // Week 33
  238: {
    backgroundColor: 'bg-[#003343]',
    gradientColors: 'from-[#111DB0] to-[#16AD8C]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/238-robot_with_holograms.gif`,
    ),
  }, // Week 34
  245: {
    backgroundColor: 'bg-[#140F31]',
    gradientColors: 'from-[#89ABFB] to-[#9269F0]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/245-battery_and_bubbles.gif`,
    ),
  }, // Week 35
  252: {
    backgroundColor: 'bg-[#0A0334]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/252-robot_in_lab.gif`,
    ),
  }, // Week 36
  259: {
    backgroundColor: 'bg-[#0B2F8C]',
    gradientColors: 'from-[#70F4D5] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/259-plant_experiment.gif`,
    ),
  }, // Week 37
  266: {
    backgroundColor: 'bg-[#2F0E59]',
    gradientColors: 'from-[#3C2F89] to-[#C2ACF7]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/266-robot_with_computer.gif`,
    ),
  }, // Week 38
  273: {
    backgroundColor: 'bg-[#140F31]',
    gradientColors: 'from-[#111DB0] to-[#8551FF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/273-alien_experiment.gif`,
    ),
  }, // Week 39
  280: {
    backgroundColor: 'bg-[#302E96]',
    gradientColors: 'from-[#101CB0] to-[#00BAFF]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/280-robot_with_containers.gif`,
    ),
  }, // Week 40
  287: {
    backgroundColor: 'bg-[#23104E]',
    gradientColors: 'from-[#00AEFF] to-[#8F8CF9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/287-robot_with_earth.gif`,
    ),
  }, // Week 41
  294: {
    backgroundColor: 'bg-[#0A0E31]',
    gradientColors: 'from-[#4578EC] to-[#8957FE]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/294-man_with_hoops.gif`,
    ),
  }, // Week 42
  301: {
    backgroundColor: 'bg-[#0F1547]',
    gradientColors: 'from-[#723DE9] to-[#3A63C4]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/301-brain_and_planet.gif`,
    ),
  }, // Week 43
  308: {
    backgroundColor: 'bg-[#211567]',
    gradientColors: 'from-[#3415CF] to-[#723DE9]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/308-woman_and_rocket.gif`,
    ),
  }, // Week 44
  315: {
    backgroundColor: 'bg-[#3D3AD4]',
    gradientColors: 'from-[#3D3BEC] to-[#3EC1FE]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/315-spaceman_experiment.gif`,
    ),
  }, // Week 45
  322: {
    backgroundColor: 'bg-[#0E0C69]',
    gradientColors: 'from-[#20A9F6] to-[#504DF3]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/322-floating_man_vr.gif`,
    ),
  }, // Week 46
  329: {
    backgroundColor: 'bg-[#1E082F]',
    gradientColors: 'from-[#5840D8] to-[#356EF2]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/329-hoverboard_man.gif`,
    ),
  }, // Week 47
  336: {
    backgroundColor: 'bg-[#08112F]',
    gradientColors: 'from-[#51D0FF] to-[#111DB0]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/336-floating_cubes.gif`,
    ),
  }, // Week 48
  343: {
    backgroundColor: 'bg-[#17113F]',
    gradientColors: 'from-[#507CF1] to-[#6929FD]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/343-dog_with_helmet.gif`,
    ),
  }, // Week 49
  350: {
    backgroundColor: 'bg-[#091266]',
    gradientColors: 'from-[#5CA8CC] to-[#2C02FC]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/350-cat_on_planet.gif`,
    ),
  }, // Week 50
  357: {
    backgroundColor: 'bg-[#420966]',
    gradientColors: 'from-[#80D5F5] to-[#3541C3]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/357-man_on_planet.gif`,
    ),
  }, // Week 51
  364: {
    backgroundColor: 'bg-[#0C0538]',
    gradientColors: 'from-[#CD91D8] to-[#7E52E4]',
    url: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/share/364-woman_on_planet.gif`,
    ),
  }, // Week 52
};
