// TODO: Fix FSD
import { portalUserStatusKey } from './../../api/keys';
import type { PortalView, ActionResultPortal } from 'dfx/edge/edge.did';
import { PHONE_VERIFICATION_REQUIRED_ERROR } from '@/common';
import { getPortalsOrdinals } from '../../lib/favorite-portals';
import { useSetFavoritePortalsMutation } from '../../api/use-set-favorite-portals.mutation';
import { favoritePortalsKey } from '../../api/keys';
import { useQueryClient } from '@tanstack/vue-query';
import { useGetFavoritePortalsQuery } from '../../api/use-get-favorite-portals.query';
import { useGetJoinedPortalsQuery } from '../../api/use-get-joined-portals.query';
import { isFavorited } from '../../lib/is-favorited';
import { isJoined } from '../../lib/is-joined';
import { useAuth } from '../../../auth';
import { useUser } from '../../../user';
import { useFollowPortalToggleMutation } from '../../api/use-follow-portal-toggle.mutation';
import { usePortalDialog } from './use-portal-dialog';

export const usePortalUserStatus = () => {
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openVerifyPhoneDialog } = usePortalDialog();
  const queryClient = useQueryClient();
  const { mutate: setFavoritePortalsMutation } =
    useSetFavoritePortalsMutation();
  const { mutate: followPortalToggleMutation } =
    useFollowPortalToggleMutation();
  const { data: favorites, isLoading: isFavoritesLoading } =
    useGetFavoritePortalsQuery();
  const { data: joined, isLoading: isJoinedLoading } =
    useGetJoinedPortalsQuery();

  const toggleFavorite = async (portalView: PortalView) => {
    if (!favorites.value) return;
    const foundPortal = favorites.value.filter(
      (p) => p.slug === portalView.slug,
    );
    let updatedFavoritePortals: PortalView[];
    if (!foundPortal.length) {
      updatedFavoritePortals = [...favorites.value, portalView];
    } else {
      updatedFavoritePortals = favorites.value.filter(
        (p) => p.slug !== portalView.slug,
      );
    }
    updateSortedFavoritePortals(updatedFavoritePortals);
  };

  const updateSortedFavoritePortals = async (portalViews: PortalView[]) => {
    const ordinals = getPortalsOrdinals(portalViews);
    setFavoritePortalsMutation(ordinals);
    const queryKey = [portalUserStatusKey, favoritePortalsKey];
    await queryClient.cancelQueries({
      queryKey,
    });
    queryClient.setQueryData(queryKey, () => portalViews);
  };

  const onMutateFollowPortalToggle = (portalView: PortalView) => {
    return new Promise<ActionResultPortal>((resolve) => {
      followPortalToggleMutation(portalView.id, {
        onSuccess: async (data) => {
          resolve(data);
        },
      });
    });
  };

  const onSuccessfulJoin = (portalView: PortalView) => {
    queryClient.refetchQueries({
      queryKey: [portalUserStatusKey],
    });
  };

  const onJoin = async (portalView: PortalView) => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
    } else {
      const mutation = await onMutateFollowPortalToggle(portalView);
      if (mutation.status === 'happy') {
        if (mutation.result[0]) {
          onSuccessfulJoin(mutation.result[0]);
        }
      } else if (mutation.status === 'sad') {
        if (mutation.message === PHONE_VERIFICATION_REQUIRED_ERROR) {
          const verified = await openVerifyPhoneDialog();
          if (verified) {
            const mutation = await onMutateFollowPortalToggle(portalView);
            if (mutation.status === 'happy') {
              if (mutation.result[0]) {
                onSuccessfulJoin(mutation.result[0]);
              }
            }
          }
        }
      }
    }
  };

  const isPortalFavorited = (portalView: PortalView) =>
    isFavorited(portalView, favorites.value);

  const isPortalJoined = (portalView: PortalView) =>
    isJoined(portalView, joined.value);

  return {
    favorites,
    isFavoritesLoading,
    isJoinedLoading,
    isPortalFavorited,
    isPortalJoined,
    joined,
    onJoin,
    onMutateFollowPortalToggle,
    toggleFavorite,
    updateSortedFavoritePortals,
  };
};
