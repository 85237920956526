import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useContentPollVoteMutation = () => {
  return useMutation({
    mutationKey: ['content-poll-vote-mutation'],
    mutationFn: (params: dscvrIcApi.post.ContentPollVoteParams) =>
      dscvrIcApi.post.contentPollVote(params),
  });
};
