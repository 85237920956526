<script setup lang="ts">
  import { ref, onMounted, type Ref } from 'vue';
  import { useUser } from '@/entities/user';
  import { DSCVR_OG_DROP_BASE_URL } from '@/common/constants';
  import { getAvatarUrl } from '@/utils/avatar';
  import { SNSGovernance } from '@/utils/sns.governance';
  import NeuronItem from '@/components/sns/NeuronItem.vue';
  import SpinnerLoader from '@/components/loaders/SpinnerLoader.vue';
  import '@typeform/embed/build/css/slider.css';
  import type { C3Token, CurrentTokenTransferC3, Gift } from '../../types';

  const { currentUser } = useUser();

  const showSNS = ref(false);
  const isLoading = ref(true);
  const showTokenTransferModalC3 = ref(false);
  const gifts: Ref<Gift[] | []> = ref([]);
  const c3Tokens: Ref<C3Token[] | []> = ref([]);
  const snsNeurons = ref([]);
  const currentDSCVROGDrops = ref([]);
  const currentTokenTransferItemC3: Ref<CurrentTokenTransferC3 | []> = ref([]);
  const c3ClaimLoading = ref({});

  onMounted(async () => {
    refreshAll();
  });

  const getImage = (url: string) => {
    return getAvatarUrl(url);
  };

  const openTokenTransferModalC3 = (c3: C3Token, item: number[]) => {
    currentTokenTransferItemC3.value = [c3, item];
    showTokenTransferModalC3.value = true;
  };

  const getSNSNeurons = async () => {
    isLoading.value = true;
    const snsGovernance = new SNSGovernance();
    await snsGovernance.create('SNS-1');
    const results = await snsGovernance.listNeurons();
    results.neurons = results.neurons.filter(
      (neuron) => neuron.cached_neuron_stake_e8s > 0,
    );
    if (results.neurons.length > 0) {
      showSNS.value = true;
      snsNeurons.value = results.neurons;
    }
    isLoading.value = false;
  };

  const refreshAll = async () => {
    await getSNSNeurons();
  };
</script>

<template>
  <section>
    <div
      v-if="showSNS && isLoading && (!snsNeurons || snsNeurons.length === 0)"
      class="relative flex justify-center w-full mt-2 text-center"
    >
      <spinner-loader />
    </div>

    <div v-if="showSNS && snsNeurons.length > 0" class="mb-4">
      <neuron-item
        v-for="neuron in snsNeurons"
        :key="neuron"
        :neuron="neuron"
        :user-principal="currentUser.id"
      />
    </div>

    <div v-if="gifts.length > 0" class="text-lg">Gifts</div>

    <div v-if="gifts.length > 0" class="mb-4">
      <div class="grid grid-cols-2 gap-4 mt-3 md:grid-cols-4">
        <div v-for="gift in gifts" :key="gift.localToken" class="relative">
          <div class="relative">
            <span
              v-if="gift.unwrapped"
              class="absolute px-1 py-1 mt-1 ml-1 text-xs font-bold text-white bg-purple-500 rounded-full ring-2 ring-purple-900"
            >
              unwrapped
            </span>

            <img class="p-2 m-2 w-72" :src="getImage(gift.image)" />
          </div>
        </div>
      </div>
    </div>

    <div v-for="c3 in c3Tokens" :key="c3.cid">
      <div v-if="c3.tokens.length > 0 || c3.claims > 0">
        <div>
          <div class="grid grid-cols-2 gap-4 mt-3 md:grid-cols-4">
            <div v-for="n in c3.claims" :key="n">
              <img
                class="size-44 md:size-48"
                :class="{
                  'animate-shake animate-infinite animate-duration-200':
                    c3ClaimLoading[c3.cid][n],
                }"
                :src="c3.claimImage"
                :data-key="n"
              />
            </div>

            <div v-for="n in c3.tokens" :key="n">
              <div class="relative">
                <span
                  class="absolute px-1 py-1 mt-1 ml-1 text-xs font-bold text-white bg-purple-500 rounded-full ring-2 ring-purple-900"
                >
                  claimed
                </span>
                <img
                  class="size-44 md:size-48"
                  :src="`https://gateway.filedrive.io/ipfs/${n[0].photoLink}`"
                />
              </div>

              <div class="mt-4 mb-4 text-center">
                <span
                  class="px-5 py-3 text-xs font-bold leading-normal text-white uppercase rounded-full shadow-lg cursor-pointer btn-primary"
                  @click="openTokenTransferModalC3(c3, n)"
                >
                  Transfer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentDSCVROGDrops.length > 0">
      <div class="mt-5 font-bold text-gray-400 border-b">
        <span
          class="text-xl font-bold text-white"
          style="font-family: 'Helvetica Neue', sans-serif"
        >
          DSCVR OG Badge 2021
        </span>
      </div>

      <div>
        <div class="grid md:flex">
          <div v-for="item in currentDSCVROGDrops" :key="item">
            <img
              class="p-2 m-2 w-72"
              :src="`${DSCVR_OG_DROP_BASE_URL}/${item}`"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
