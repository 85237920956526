<script lang="ts" setup>
  import { useIgc } from '../model/composables/use-igc';

  const emits = defineEmits<{
    (e: 'close'): void;
  }>();

  const { openConfiguredPurchaseDialog, sparksCount } = useIgc();

  const onClickPurchase = () => {
    emits('close');
    openConfiguredPurchaseDialog();
  };
</script>

<template>
  <div class="flex flex-col w-60">
    <div
      class="flex justify-between gap-4 p-4 w-full bg-gradient-to-r from-indigo-850 via-transparent to-transparent"
    >
      <span class="font-semibold tracking-[0.18px]">Current balance</span>
      <div class="flex items-center gap-1">
        <base-icon name="sparks" size="size-4" />
        <span class="font-medium">{{ sparksCount }}</span>
      </div>
    </div>
    <div class="p-4">
      <base-button
        variant="primary"
        size="full"
        additional-classes="font-medium"
        @click="onClickPurchase"
      >
        Purchase
      </base-button>
    </div>
  </div>
</template>
