import { Extension } from '@tiptap/core';
import type { Node } from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { useValidateAndCreateCanvas } from '@/features/canvas';
import { useValidateAndCreateFrame } from '@/features/frame';

export const createOpengraphExtension = () => {
  const { validateAndCreateCanvas } = useValidateAndCreateCanvas();
  const { validateAndCreateFrame } = useValidateAndCreateFrame();

  return Extension.create({
    name: 'opengraphExtension',
    addProseMirrorPlugins(): Plugin<any>[] {
      return [
        new Plugin({
          props: {
            transformPasted: (slice, view) => {
              const url = slice.content.firstChild?.textContent;
              if (url) {
                const replaceSelectionWithNode = (node: Node) => {
                  const { from, to } = view.state.selection;
                  const newTransaction = view.state.tr.replaceRangeWith(
                    from,
                    to,
                    node,
                  );
                  view.dispatch(newTransaction);
                };

                validateAndCreateCanvas(url).then((isValid) => {
                  if (isValid) {
                    const { canvas } = view.state.schema.nodes;
                    const node = canvas.create({ url });
                    replaceSelectionWithNode(node);
                  }
                });

                validateAndCreateFrame(url).then((isValid) => {
                  if (isValid) {
                    const { frame } = view.state.schema.nodes;
                    const node = frame.create({ url });
                    replaceSelectionWithNode(node);
                  }
                });
              }

              return slice;
            },
          },
        }),
      ];
    },
  });
};
