import type { Principal } from '@dfinity/principal';
import { type OnboardingState } from '../../../model/store/onboarding/types';
import { edgeActor } from '../base';
import type { PortalView, UserRecommendation } from 'dfx/edge/edge.did';

export const setOnboardingState = (state: OnboardingState) => {
  return edgeActor().set_onboarding_state(JSON.stringify(state));
};

export const getOnboardingInterests = () => {
  return edgeActor()
    .get_onboarding_interests()
    .then((res) => res) as Promise<string[]>;
};

export const getOnboardingUserRecommendations = () => {
  return edgeActor()
    .get_onboarding_user_recommendations_v2()
    .then((res) => res) as Promise<UserRecommendation[]>;
};

export const setUserTopUsers = (userIds: Principal[]) => {
  return edgeActor().follow_user_multi(userIds);
};

export const getOnboardingPortalRecommendations = () => {
  return edgeActor()
    .get_onboarding_portal_recommendations()
    .then((res) => res) as Promise<PortalView[]>;
};

export const setUserPortalJoined = (portalIds: bigint[]) => {
  return edgeActor().follow_portal_multi(portalIds);
};

export const claimOnboardingReward = () => {
  return edgeActor().claim_onboarding_reward();
};
