<script lang="ts" setup>
  import { ref } from 'vue';
  import { useUser, useSocialSphere } from '@/entities/user';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useAuth } from '@/entities/auth';

  const { showReferralCommunityDialog } = useSocialSphere();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();

  const referralBlock = ref<HTMLElement>();
</script>

<template>
  <div
    ref="referralBlock"
    class="flex gap-1 justify-center items-center py-2 rounded-xl bg-cover bg-no-repeat bg-white bg-opacity-10 mb-2 md:mb-6 cursor-pointer"
    :style="{
      backgroundImage: `url('${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/referral/block-gradient.svg`,
      )}')`,
    }"
    @click="
      !isLoggedIn
        ? showLoginSignUpDialog()
        : showReferralCommunityDialog('referrals', true)
    "
  >
    <img
      :src="
        fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/referral/onboarding-referral.gif`,
        )
      "
      alt="Get Rewards!"
      class="w-16 h-auto"
    />
    <span class="font-medium tracking-[0.16px]">
      {{ $t('referralFeature.getRewards') }}
    </span>
    <span class="tracking-[0.16px]">
      {{ $t('referralFeature.inviteYourFriends') }}
    </span>
  </div>
</template>
