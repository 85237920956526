<script lang="ts" setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useTippy } from 'vue-tippy';
  import { useI18n } from 'vue-i18n';
  import { storeToRefs } from 'pinia';
  import { useStreak } from '../../model/composables/use-streak';
  import HeaderDialog from '../../ui/HeaderDialog.vue';
  import { USER_SETTINGS_NOTIFICATIONS } from '@/common';
  import { useUser, useNotificationSettingStore } from '../../../user';
  import { useDialog } from '@/shared/model';
  import { Toast } from '@/shared/ui/toast';
  import { ElasticLine } from '@/shared/ui/elastic-line';

  const showToast = ref(false);
  const streakReminderButton = ref<HTMLElement>();

  const { closeDialog } = useDialog();
  const { showLearnMoreDialog, showEmailVerificationDialog, isPoweredUp } =
    useStreak();

  const router = useRouter();
  const { t } = useI18n({ useScope: 'global' });
  const { currentUser } = useUser();
  const notificationSettingStore = useNotificationSettingStore();
  const { inAppNotifications } = storeToRefs(notificationSettingStore);

  const setStreakReminder = async () => {
    // If user is not verified, show the verification dialog
    // else set the streak reminder
    if (currentUser.value.email_verified) {
      hideTooltip();
      await notificationSettingStore.setUserSettings(true, 'streak_reminders');
      showToast.value = true;
    } else {
      showEmailVerificationDialog();
    }
  };

  const gotoNotificationSettings = () => {
    closeDialog();
    showToast.value = false;
    router.push({
      name: USER_SETTINGS_NOTIFICATIONS,
      params: { username: currentUser.value.username },
    });
  };

  const { show: showTooltip, hide: hideTooltip } = useTippy(
    streakReminderButton,
    {
      content: t('streak.activeDialog.optInToReceive'),
      placement: 'left',
      theme: 'blue',
      maxWidth: 139,
      offset: [0, 10],
      hideOnClick: 'toggle',
    },
  );
</script>

<template>
  <header-dialog>
    <template #title>
      <div
        class="flex flex-col items-start gap-4 md:flex-row md:items-center md:gap-2 text-gray-300"
      >
        <div class="text-2xl font-bold tracking-[-0.12px]">
          <component :is="isPoweredUp ? ElasticLine : 'div'">
            {{
              t(
                !isPoweredUp
                  ? 'streak.activeDialog.powerUp'
                  : 'streak.activeDialog.allPoweredUp',
              )
            }}
          </component>
        </div>
        <base-button
          variant="link"
          additional-classes="gap-2 items-center"
          @click="showLearnMoreDialog"
        >
          <base-icon name="info" size="w-4 h-4" class="text-indigo-300" />
          <span class="text-md font-medium tracking-[0.16px]">
            {{ t('learnMore') }}
          </span>
        </base-button>
      </div>
    </template>
    <template #close>
      <section class="flex gap-4 md:gap-2">
        <template v-if="inAppNotifications">
          <template v-if="inAppNotifications.streak_reminders.length === 0">
            <div ref="streakReminderButton">
              <base-button
                variant="glass"
                size="small"
                additional-classes="min-h-10 !rounded-full text-gray-50"
                @click="setStreakReminder"
                @mouseover="showTooltip"
              >
                <base-icon
                  name="vuesax-linear-sms-notification"
                  size="size-5"
                  class="text-white"
                />
                <span
                  class="text-sm leading-4.5 tracking-[0.14px] text-gray-50 font-medium"
                >
                  {{ t('streak.activeDialog.getNotified') }}
                </span>
              </base-button>
            </div>
          </template>
          <template v-else>
            <base-button
              variant="glass"
              size="small"
              additional-classes="min-h-10 !rounded-full text-gray-50"
            >
              <base-icon
                name="filled-check-circle"
                size="size-5"
                class="text-green-300"
              />
              <span
                class="text-sm leading-4.5 tracking-[0.14px] text-gray-50 font-medium"
              >
                {{ t('streak.activeDialog.optedIn') }}
              </span>
            </base-button>
          </template>
        </template>
        <base-button variant="link" @click.stop="closeDialog">
          <base-icon name="x" size="size-5" class="text-gray-400" />
        </base-button>
      </section>
    </template>
  </header-dialog>
  <template v-if="showToast">
    <toast
      variant="bordered"
      type="success"
      placement="bottom-center"
      :timed="false"
      @close="showToast = false"
    >
      <template #title> {{ t('streak.activeDialog.toast.title') }} </template>
      <template #description>
        {{ t('streak.activeDialog.toast.description') }}
      </template>
      <template #button>
        <base-button
          variant="link"
          size="small"
          class="text-indigo-300"
          @click="gotoNotificationSettings"
        >
          {{ t('streak.activeDialog.toast.gotoSettings') }}
        </base-button>
      </template>
    </toast>
  </template>
</template>
