import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { ReportTransactionPayload } from '@/entities/wallet-transaction';

export const useReportFrameTransactionMutation = () => {
  return useMutation({
    mutationKey: ['report-frame-transaction-mutation'],
    mutationFn: ({
      frameUrl,
      transactionId,
      payload,
    }: {
      frameUrl: string;
      transactionId: string;
      payload: ReportTransactionPayload;
    }) =>
      dscvrIcApi.frame.reportFrameTransaction(
        frameUrl,
        transactionId,
        payload.reason,
        payload.comments,
      ),
  });
};
