import axios from 'axios';
import TokenService from './token.service';
import { config } from '../shared/lib/config';
import { useFile } from '@/composables';

const storageAxios = axios.create({
  baseURL: `${config.GATED_API_BASE}/api`,
  headers: {
    'Content-type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
});

storageAxios.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (config.headers && token)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 *
 * @param file
 */
function uploadFile(file) {
  return storageAxios
    .post('storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((exc) => {
      return exc.response;
    });
}

export default {
  upload(file) {
    return uploadFile(file);
  },
  // base64 example 'data:image/png;base64,ABC1234...'
  uploadBase64(base64) {
    const { base64ToFile } = useFile();
    return base64ToFile(base64).then((file) => {
      const formData = new FormData();
      formData.append('file', file);
      return uploadFile(formData).then((uploadedFile) => {
        const fileUrl = uploadedFile.data.results;
        return fileUrl;
      });
    });
  },
};
