<script setup lang="ts">
  import { toRef, computed } from 'vue';
  import type { ContentView } from 'dfx/edge/edge.did';
  import TipNew from './TipNew.vue';
  import { useRepost } from '@/composables';
  import { usePost } from '@/entities/post';
  import { useUser } from '@/entities/user';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useShareDialog } from '@/shared/model';
  import { useStreak } from '@/entities/streak';

  const props = withDefaults(
    defineProps<{
      content: ContentView;
      isThreaded?: boolean;
      isComment?: boolean;
    }>(),
    {
      isThreaded: false,
      isComment: false,
    },
  );

  defineEmits<{
    (e: 'clicked-post'): void;
  }>();

  const content = toRef(props, 'content');
  const { currentUser } = useUser();
  const { showShareDialog } = useStreak();
  const { postDetailAbsoluteUrl } = usePost(content);
  const { repost } = useRepost(content);
  const { openShareDialog } = useShareDialog();

  const isStreakContent = computed(() =>
    content.value?.body.includes('embedded-streak'),
  );
</script>

<template>
  <div class="flex justify-end w-full gap-2.5">
    <base-tooltip theme="action" :content="$t('postButtonLabelRepost')">
      <span
        class="flex text-gray-400 btn-post-actions share-padding hover:bg-[#333C34] group/repost"
        @click="repost"
      >
        <base-icon
          name="repost"
          size="w-5 h-5"
          class="group-hover/repost:text-[#22C55E]"
        />
      </span>
    </base-tooltip>

    <base-tooltip theme="action" :content="$t('postButtonLabelShare')">
      <span
        class="flex text-gray-400 btn-post-actions share-padding hover:bg-[#0044F3]/[0.2] group/share"
        @click="
          isStreakContent
            ? showShareDialog(content.body)
            : openShareDialog(postDetailAbsoluteUrl)
        "
      >
        <base-icon
          name="share"
          size="w-5 h-5"
          class="fill-gray-950 group-hover/share:text-[#3B82F6] group-hover/share:fill-[#3B82F6]"
        />
      </span>
    </base-tooltip>

    <tip-new
      v-if="!isThreaded && currentUser"
      :content-id="content.id"
      :user-id="content.owner_id"
    />

    <base-button
      v-if="!isComment && !isThreaded"
      variant="link"
      @click="$emit('clicked-post')"
      class="cursor-pointer tooltip-parent"
    >
      <base-tooltip theme="action" :content="$t('postButtonLabelReply')">
        <div
          v-if="!isThreaded"
          class="flex items-center text-gray-400 btn-post-actions hover:bg-[#4E00F3]/[0.2] group/comment"
        >
          <base-icon
            name="comment"
            size="w-5 h-5"
            class="fill-gray-950 group-hover/comment:text-[#6366F1] group-hover/comment:fill-[#6366F1]"
          />

          <span class="mb-0 ml-1 text-sm">
            {{ content.children_count }}
          </span>
        </div>
      </base-tooltip>
    </base-button>
  </div>
</template>

<style scoped>
  @media (hover: none) {
    span:hover {
      background-color: inherit;
    }
  }
</style>
