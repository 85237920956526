<script setup lang="ts">
  import { computed } from 'vue';
  import type { CheckboxFieldProps } from '../types';
  import { uniqueId } from 'lodash-es';

  const props = withDefaults(defineProps<CheckboxFieldProps>(), {
    labelPosition: 'left',
    variant: 'checkbox',
  });
  const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
    (e: 'blur', value: FocusEvent): void;
  }>();

  const fieldId = `checkboxfield-${uniqueId()}`;

  const inputClass = computed(() => {
    return props.variant === 'toggle'
      ? 'sr-only'
      : 'w-6 h-6 mr-2 bg-indigo-600 rounded-md focus:ring-0 focus:outline-none focus:bg-indigo-600 checked:bg-indigo-600 hover:bg-indigo-700 checked:focus:bg-indigo-700';
  });

  const updateValue = (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('update:modelValue', target?.checked);
  };
</script>

<template>
  <div class="w-full flex items-center justify-center">
    <label
      :for="fieldId"
      class="flex items-center gap-2 w-full"
      :class="{
        'cursor-default opacity-50': disabled,
        'cursor-pointer': !disabled,
      }"
    >
      <div
        v-if="$slots.default && labelPosition === 'left'"
        class="flex-1 text-gray-400"
      >
        <slot />
      </div>
      <div class="relative">
        <input
          type="checkbox"
          :class="inputClass"
          v-bind="{ ...$attrs, ...props }"
          :id="fieldId"
          :checked="modelValue"
          @input="updateValue"
          @blur="$emit('blur', $event)"
        />

        <div
          v-if="variant === 'toggle'"
          class="bg-gray-600 h-8 w-14 block rounded-full checked:bg-indigo-600"
          :class="{ 'bg-indigo-500': modelValue }"
        />

        <div
          v-if="variant === 'toggle'"
          class="bg-white h-6 w-6 absolute top-1 left-1 rounded-full transition transform"
          :class="{ 'translate-x-full': modelValue }"
        />
      </div>
      <div
        v-if="$slots.default && labelPosition === 'right'"
        class="flex-1 text-gray-400"
      >
        <slot />
      </div>
    </label>
  </div>
</template>
