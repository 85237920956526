<template>
  <div
    class="flex justify-between items-center p-4 w-full rounded-2xl cursor-pointer hover:bg-gray-950"
  >
    <div class="relative flex items-center gap-2.5">
      <div class="flex-none">
        <slot name="avatar" />
      </div>
      <span
        class="text-left font-semibold tracking-[0.07px] truncate w-full max-w-32 md:max-w-full"
      >
        <slot name="username" />
      </span>
    </div>
    <div v-if="$slots.points" class="font-medium tracking-[0.14px]">
      <slot name="points" />
    </div>
    <div v-if="$slots.actions" class="flex items-center gap-4">
      <slot name="actions" />
    </div>
  </div>
</template>
