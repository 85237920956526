import { dscvrIcApi } from '@/shared/api';
import type { PersonalizedFeedV1Query } from 'dfx/edge/edge.did';
import { useInfiniteQuery } from '@tanstack/vue-query';
import type { ComputedRef, MaybeRef, Ref } from 'vue';
import {
  getFeedByNameKey,
  followingFeedKey,
  hasNextPage,
  type FeedFilter,
} from '@/shared/model';

export const useFollowingFeedQuery = (
  feedFilters: ComputedRef<FeedFilter>,
  queryParams: Ref<PersonalizedFeedV1Query>,
  enabled?: MaybeRef<boolean>,
) => {
  return useInfiniteQuery({
    queryKey: [...getFeedByNameKey(followingFeedKey), feedFilters],
    queryFn: async (ctx) => {
      // We override the page parameter with the current page number using the one provided by vue-query
      // this is a temporary hack until backend creates a cursor based pagination
      queryParams.value.query.page = BigInt(ctx.pageParam);

      const data = await dscvrIcApi.post.getFollowingFeed(queryParams.value);
      return 'Ok' in data ? data.Ok : null;
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, __) =>
      hasNextPage(allPages[0]?.total_pages, lastPageParam),
    enabled,
    staleTime: Infinity,
  });
};
