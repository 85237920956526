import { NFT } from './nft';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';

const idlFactory = ({ IDL }) => {
  const Vec = IDL.Rec();
  const NftError = IDL.Variant({
    UnauthorizedOperator: IDL.Null,
    SelfTransfer: IDL.Null,
    TokenNotFound: IDL.Null,
    UnauthorizedOwner: IDL.Null,
    TxNotFound: IDL.Null,
    SelfApprove: IDL.Null,
    OperatorNotFound: IDL.Null,
    ExistedNFT: IDL.Null,
    OwnerNotFound: IDL.Null,
    Other: IDL.Text,
  });
  const BalanceOfResponse = IDL.Variant({ Ok: IDL.Nat, Err: NftError });
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const Request = IDL.Record({
    url: IDL.Text,
    method: IDL.Text,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
  });
  const StreamingCallbackToken = IDL.Record({
    key: IDL.Text,
    index: IDL.Nat,
    content_encoding: IDL.Text,
  });
  const StreamingCallbackResponse = IDL.Record({
    token: IDL.Opt(StreamingCallbackToken),
    body: IDL.Vec(IDL.Nat8),
  });
  const StreamingCallback = IDL.Func(
    [StreamingCallbackToken],
    [StreamingCallbackResponse],
    ['query'],
  );
  const StreamingStrategy = IDL.Variant({
    Callback: IDL.Record({
      token: StreamingCallbackToken,
      callback: StreamingCallback,
    }),
  });
  const Response = IDL.Record({
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
    streaming_strategy: IDL.Opt(StreamingStrategy),
    status_code: IDL.Nat16,
  });
  const Metadata = IDL.Record({
    logo: IDL.Opt(IDL.Text),
    name: IDL.Opt(IDL.Text),
    created_at: IDL.Nat64,
    upgraded_at: IDL.Nat64,
    custodians: IDL.Vec(IDL.Principal),
    symbol: IDL.Opt(IDL.Text),
  });
  Vec.fill(
    IDL.Vec(
      IDL.Tuple(
        IDL.Text,
        IDL.Variant({
          Nat64Content: IDL.Nat64,
          Nat32Content: IDL.Nat32,
          BoolContent: IDL.Bool,
          Nat8Content: IDL.Nat8,
          Int64Content: IDL.Int64,
          IntContent: IDL.Int,
          NatContent: IDL.Nat,
          Nat16Content: IDL.Nat16,
          Int32Content: IDL.Int32,
          Int8Content: IDL.Int8,
          FloatContent: IDL.Float64,
          Int16Content: IDL.Int16,
          BlobContent: IDL.Vec(IDL.Nat8),
          NestedContent: Vec,
          Principal: IDL.Principal,
          TextContent: IDL.Text,
        }),
      ),
    ),
  );
  const GenericValue = IDL.Variant({
    Nat64Content: IDL.Nat64,
    Nat32Content: IDL.Nat32,
    BoolContent: IDL.Bool,
    Nat8Content: IDL.Nat8,
    Int64Content: IDL.Int64,
    IntContent: IDL.Int,
    NatContent: IDL.Nat,
    Nat16Content: IDL.Nat16,
    Int32Content: IDL.Int32,
    Int8Content: IDL.Int8,
    FloatContent: IDL.Float64,
    Int16Content: IDL.Int16,
    BlobContent: IDL.Vec(IDL.Nat8),
    NestedContent: Vec,
    Principal: IDL.Principal,
    TextContent: IDL.Text,
  });
  const MintResponse = IDL.Variant({ Ok: IDL.Nat, Err: NftError });
  const OperatorOfResponse = IDL.Variant({
    Ok: IDL.Opt(IDL.Principal),
    Err: NftError,
  });
  const OperatorTokenIdentifiersResponse = IDL.Variant({
    Ok: IDL.Vec(IDL.Nat),
    Err: NftError,
  });
  const TokenMetadata = IDL.Record({
    transferred_at: IDL.Opt(IDL.Nat64),
    transferred_by: IDL.Opt(IDL.Principal),
    owner: IDL.Opt(IDL.Principal),
    operator: IDL.Opt(IDL.Principal),
    approved_at: IDL.Opt(IDL.Nat64),
    approved_by: IDL.Opt(IDL.Principal),
    properties: IDL.Vec(IDL.Tuple(IDL.Text, GenericValue)),
    is_burned: IDL.Bool,
    token_identifier: IDL.Nat,
    burned_at: IDL.Opt(IDL.Nat64),
    burned_by: IDL.Opt(IDL.Principal),
    minted_at: IDL.Nat64,
    minted_by: IDL.Principal,
  });
  const OperatorTokenMetadataResponse = IDL.Variant({
    Ok: IDL.Vec(TokenMetadata),
    Err: NftError,
  });
  const OwnerOfResponse = IDL.Variant({
    Ok: IDL.Opt(IDL.Principal),
    Err: NftError,
  });
  const OwnerTokenIdentifiersResponse = IDL.Variant({
    Ok: IDL.Vec(IDL.Nat),
    Err: NftError,
  });
  const OwnerTokenMetadataResponse = IDL.Variant({
    Ok: IDL.Vec(TokenMetadata),
    Err: NftError,
  });
  const Stats = IDL.Record({
    cycles: IDL.Nat,
    total_transactions: IDL.Nat,
    total_unique_holders: IDL.Nat,
    total_supply: IDL.Nat,
  });
  const SupportedInterface = IDL.Variant({
    Burn: IDL.Null,
    Mint: IDL.Null,
    Approval: IDL.Null,
    TransactionHistory: IDL.Null,
  });
  const TokenMetadataResponse = IDL.Variant({
    Ok: TokenMetadata,
    Err: NftError,
  });
  const TransferResponse = IDL.Variant({ Ok: IDL.Nat, Err: NftError });
  const TransferFromResponse = IDL.Variant({
    Ok: IDL.Nat,
    Err: NftError,
  });
  return IDL.Service({
    balanceOf: IDL.Func([IDL.Principal], [BalanceOfResponse], ['query']),
    custodians: IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    cycles: IDL.Func([], [IDL.Nat], ['query']),
    http_request: IDL.Func([Request], [Response], ['query']),
    logo: IDL.Func([], [IDL.Opt(IDL.Text)], ['query']),
    metadata: IDL.Func([], [Metadata], ['query']),
    mint: IDL.Func(
      [IDL.Principal, IDL.Nat, IDL.Vec(IDL.Tuple(IDL.Text, GenericValue))],
      [MintResponse],
      [],
    ),
    name: IDL.Func([], [IDL.Opt(IDL.Text)], ['query']),
    operatorOf: IDL.Func([IDL.Nat], [OperatorOfResponse], ['query']),
    operatorTokenIdentifiers: IDL.Func(
      [IDL.Principal],
      [OperatorTokenIdentifiersResponse],
      ['query'],
    ),
    operatorTokenMetadata: IDL.Func(
      [IDL.Principal],
      [OperatorTokenMetadataResponse],
      ['query'],
    ),
    ownerOf: IDL.Func([IDL.Nat], [OwnerOfResponse], ['query']),
    ownerTokenIdentifiers: IDL.Func(
      [IDL.Principal],
      [OwnerTokenIdentifiersResponse],
      ['query'],
    ),
    ownerTokenMetadata: IDL.Func(
      [IDL.Principal],
      [OwnerTokenMetadataResponse],
      ['query'],
    ),
    setCustodians: IDL.Func([IDL.Vec(IDL.Principal)], [], ['oneway']),
    setLogo: IDL.Func([IDL.Text], [], ['oneway']),
    setNFTArtwork: IDL.Func([IDL.Text], [], ['oneway']),
    setName: IDL.Func([IDL.Text], [], ['oneway']),
    setSymbol: IDL.Func([IDL.Text], [], ['oneway']),
    stats: IDL.Func([], [Stats], ['query']),
    supportedInterfaces: IDL.Func([], [IDL.Vec(SupportedInterface)], ['query']),
    symbol: IDL.Func([], [IDL.Opt(IDL.Text)], ['query']),
    tokenMetadata: IDL.Func([IDL.Nat], [TokenMetadataResponse], ['query']),
    totalSupply: IDL.Func([], [IDL.Nat], ['query']),
    totalUniqueHolders: IDL.Func([], [IDL.Nat], ['query']),
    transfer: IDL.Func([IDL.Principal, IDL.Nat], [TransferResponse], []),
    transferFrom: IDL.Func(
      [IDL.Principal, IDL.Principal, IDL.Nat],
      [TransferFromResponse],
      [],
    ),
  });
};

export class DIP721V2 {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  async getKey() {
    return (await client.getIdentity()).getPrincipal().toText();
  }

  async get_my_tokens() {
    return this.get_tokens(this.identity.getPrincipal());
  }

  async getTokens(pid) {
    const tokens = await this.actor.ownerTokenIdentifiers(pid);
    const results = [];
    if (tokens.Ok.length == 0) return results;

    for (let i = 0; i < tokens.Ok.length; i++) {
      const tokenId = tokens.Ok[i];
      const tokenUrl = `https://${this.canisterId}.raw.icp0.io/?tokenid=${tokenId}`;
      const nft = new NFT({
        tokenUrl: tokenUrl,
        tokenDisplayUrl: tokenUrl,
        cid: this.canisterId,
        tokenIndex: tokenId,
        tokenHash: '',
      });
      results.push(nft);
    }
    return results;
  }
}
