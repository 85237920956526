import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { CanvasInterface, UntrustedCanvasResponse } from '../types';

export const useSignCanvasResponseMutation = () => {
  return useMutation({
    mutationKey: ['sign-canvas-response-mutation'],
    mutationFn: async (
      response: UntrustedCanvasResponse,
    ): Promise<CanvasInterface.BaseHostMessage> => {
      if (!('untrusted' in response)) {
        return response as CanvasInterface.BaseHostMessage;
      }

      const untrusted = JSON.stringify(response.untrusted);
      const trustedBytes = await dscvrIcApi.canvas.signResponse(untrusted);

      response.type;
      return {
        ...response,
        trustedBytes,
      };
    },
  });
};
