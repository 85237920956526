<script lang="ts" setup>
  import type { WalletName } from '@solana/wallet-adapter-base';
  import { useDevice } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { WalletIcon, usePairSolanaWallet } from '@/entities/wallets';

  const emit = defineEmits<{
    (e: 'connected'): void;
    (e: 'close'): void;
  }>();

  const { isMobileOrTablet } = useDevice();
  const { currentUser } = useUser();
  const { pairEnabledWallets, pair } = usePairSolanaWallet();

  const onSelectWallet = async (walletName: WalletName) => {
    const success = await pair(currentUser.value, walletName);

    if (success) {
      emit('connected');
    }
  };
</script>

<template>
  <div class="relative flex items-center justify-between p-0 md:p-4 pb-0">
    <div class="pl-4 text-xl font-bold">Connect your wallet</div>
    <div class="pr-4">
      <base-button variant="link" @click="$emit('close')">
        <base-icon name="close" class="size-5 text-gray-400 cursor-pointer" />
      </base-button>
    </div>
  </div>
  <section class="w-full min-w-[326px] px-2 pt-5 pb-2">
    <div
      class="w-full flex flex-col overflow-hidden border border-white border-opacity-[0.08] rounded-lg"
      v-if="pairEnabledWallets.length > 0"
    >
      <base-button
        v-for="(wallet, i) in pairEnabledWallets"
        :key="i"
        variant="link"
        additional-classes="justify-between cursor-pointer p-2 hover:bg-white hover:bg-opacity-[0.08]"
        @click="onSelectWallet(wallet.id)"
      >
        <div class="flex items-center gap-3">
          <wallet-icon :id="wallet.id" class="size-8" />
          <span class="text-base font-medium">{{ wallet.id }}</span>
        </div>
        <div v-if="wallet.readyState === 'Installed'" class="opacity-40">
          {{ $t('wallets.connectWallet.detected') }}
        </div>
        <div v-else-if="!isMobileOrTablet" class="opacity-40">
          {{ $t('wallets.connectWallet.notDetected') }}
        </div>
      </base-button>
    </div>
    <div v-else>
      <div class="text-center">
        <div class="text-base font-medium">No wallets detected</div>
        <div class="text-sm text-gray-400">
          Please install a Solana wallet extension and refresh the page
        </div>
      </div>
    </div>
  </section>
</template>
