<script lang="ts" setup>
  import type { BaseButtonProps } from '@/shared/ui/base-button';
  import { useUser } from '@/entities/user';
  import { usePortalDialog } from '@/entities/portal';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<BaseButtonProps>();
  const emit = defineEmits<{
    (e: 'clicked'): void;
  }>();

  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openCreatePortalDialog } = usePortalDialog();

  const clicked = () => {
    emit('clicked');
    return isLoggedIn.value
      ? openCreatePortalDialog()
      : showLoginSignUpDialog();
  };
</script>

<template>
  <base-button v-bind="props" @click="clicked">
    <slot />
  </base-button>
</template>
