<script setup lang="ts">
  import { computed, ref, toRef } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { PORTAL_CONTENT, USER_CONTENT } from '@/common';
  import { Toast } from '@/shared/ui/toast';
  import { resolvePostDetailRoute } from '@/entities/post';
  import CreatePostForm from '@/components/forms/CreatePostForm.vue';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { useGetPortalBySlugQuery } from '@/entities/portal';

  interface PostData {
    title: string;
    body: string;
    url: string;
    icon_url: string;
    is_nsfw: boolean;
  }

  defineProps<{
    editPost?: ContentView;
    initialContent?: string;
  }>();

  const emit = defineEmits<{
    (e: 'post-created', post: ContentView): void;
    (e: 'post-updated', post: ContentView): void;
    (e: 'close'): void;
  }>();

  const hasUnsavedChanges = ref(false);
  const showConfirmationToast = ref(false);

  const router = useRouter();
  const route = useRoute();

  const { data: portalView } = useGetPortalBySlugQuery(
    toRef(() => route.params.slug as string),
    true,
  );

  const isPortalOrUserFeed = computed(() => {
    return route.name === PORTAL_CONTENT || route.name === USER_CONTENT;
  });

  const navigateToPost = (post: ContentView) => {
    router.push(resolvePostDetailRoute(post));
  };

  const openPostInNewTab = (post: ContentView) => {
    const url = router.resolve(resolvePostDetailRoute(post));
    window.open(url.href, '_blank');
  };

  const postCreated = (post: ContentView) => {
    emit('post-created', post);
    openPostInNewTab(post);
  };

  const postUpdated = (post: ContentView) => {
    emit('post-updated', post);

    if (!isPortalOrUserFeed.value) {
      navigateToPost(post);
    }
  };

  const contentAdded = (data: PostData) => {
    hasUnsavedChanges.value = !!(
      data.title ||
      data.body ||
      data.url ||
      data.icon_url
    );
  };

  const close = () => {
    if (!hasUnsavedChanges.value) {
      emit('close');
      return;
    }
    showConfirmationToast.value = true;
  };
</script>

<template>
  <section class="w-full h-full p-1 overflow-y-auto lg:w-[600px] md:!h-fit">
    <create-post-form
      :portal-view="portalView"
      :edit-post="editPost"
      :initial-content="initialContent"
      @create-post="postCreated"
      @edit-post="postUpdated"
      @content-added="contentAdded"
      @close="close"
    />
    <div
      v-if="showConfirmationToast"
      class="fixed inset-0 flex flex-col-reverse items-center p-10 bg-black bg-opacity-50 z-100"
    >
      <toast
        variant="bordered"
        type="warning"
        position="relative"
        :timed="false"
        @close="showConfirmationToast = false"
      >
        <template #content>
          <div class="flex items-center gap-4">
            <base-icon
              name="filled-exclamation-triangle"
              size="w-5 h-5 min-w-5"
              class="text-orange-400"
            />
            <div class="font-semibold text-white">
              {{ $t('unsavedChanges') }}
            </div>
            <div class="flex gap-2">
              <base-button
                variant="link"
                size="small"
                class="text-indigo-300"
                @click.stop="$emit('close')"
              >
                {{ $t('leave') }}
              </base-button>

              <base-button
                variant="primary"
                size="small"
                @click.stop="showConfirmationToast = false"
              >
                {{ $t('stay') }}
              </base-button>
            </div>
          </div>
        </template>
      </toast>
    </div>
  </section>
</template>
