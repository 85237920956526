<script setup lang="ts">
  import { ref, toRefs } from 'vue';
  import { formatToTime } from '@/shared/lib';
  import type { FrameActionPayload } from '@/features/frame';
  import { useBreakpoint } from '@/shared/model';
  import StackTraceItemRequest from './StackTraceItemRequest.vue';
  import StackTraceItemResponse from './StackTraceItemResponse.vue';
  import { useFrameAction } from '../composables/use-frame-action';

  const props = defineProps<{
    action: FrameActionPayload;
    actionIndex: number;
  }>();

  const expanded = ref(true);
  const { action } = toRefs(props);

  const { isSmallerThanLg } = useBreakpoint();
  const { isLink, title, link } = useFrameAction(action);
</script>

<template>
  <div
    class="flex flex-col gap-2 min-w-0 overflow-hidden p-4"
    :class="{
      'border border-red-500 border-opacity-50': action.type === 'error',
    }"
  >
    <h3 class="flex gap-4 items-center lg:text-lg lg:pr-7">
      <div class="flex justify-between gap-2 whitespace-nowrap">
        <base-button
          v-if="!isLink"
          @click="expanded = !expanded"
          variant="custom"
        >
          <base-icon
            :name="expanded ? 'chevron-up-filled' : 'chevron-down-filled'"
            size="size-5"
            class="text-gray-500"
          />
        </base-button>
        <b :class="{ 'ml-7': isLink }">#{{ actionIndex }}</b> {{ title }}
      </div>
      <div v-if="!isSmallerThanLg && link" class="flex-1 flex min-w-0">
        <span class="text-gray-400 italic whitespace-nowrap truncate">
          {{ link }}
        </span>
      </div>

      <span class="ml-auto italic whitespace-nowrap text-sm">
        {{ formatToTime(action.requestTimestamp) }}
      </span>
    </h3>
    <div v-if="isSmallerThanLg && link" class="flex min-w-0">
      <span class="text-gray-400 italic whitespace-nowrap truncate">
        {{ link }}
      </span>
    </div>
    <div v-if="expanded && !isLink" class="p-4 lg:m-2 bg-black bg-opacity-10">
      <div class="flex flex-col gap-6 overflow-hidden min-w-0">
        <stack-trace-item-request :action="action" />
        <stack-trace-item-response :action="action" />
      </div>
    </div>
  </div>
</template>
