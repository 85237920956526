<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { dscvrApi } from '@/shared/api';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { parseNftName } from '@/entities/token';

  const props = defineProps<{
    lootboxItem: dscvrApi.lootbox.ILootboxItem;
  }>();

  const isImageError = ref(false);

  const showAmount = computed(() => {
    return (
      props.lootboxItem.tokenType ===
        dscvrApi.lootbox.LootboxItemType.DSCVR_POINTS ||
      props.lootboxItem.tokenType === dscvrApi.lootbox.LootboxItemType.SOL_SPL
    );
  });

  const rank = computed(() => {
    if (showAmount.value) {
      return undefined;
    }
    const rarity = props.lootboxItem.token.rarity;
    // TODO: ranking is not implemented yet
    return Number(rarity);
  });

  const rankIcon = computed(() => {
    switch (rank.value) {
      case 1:
        return 'rank-e';
      case 2:
        return 'rank-d';
      case 3:
        return 'rank-c';
      case 4:
        return 'rank-b';
      default:
        return 'rank-a';
    }
  });

  const getDisplayAmount = (amount: bigint, decimals: number) => {
    return Number(amount) / 10 ** decimals;
  };
</script>

<template>
  <article
    class="flex flex-col rounded-xl overflow-hidden bg-gray-975 border border-gray-785 border-opacity-50 shadow-darker size-full"
  >
    <section class="flex-1 relative">
      <img
        class="aspect-square h-full"
        v-lazy="{
          src: lootboxItem.token.image,
          error: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
          ),
        }"
        :class="{
          'p-4':
            lootboxItem.tokenType ===
            dscvrApi.lootbox.LootboxItemType.DSCVR_POINTS,
        }"
        :alt="lootboxItem.token.name"
        @error="isImageError = true"
      />
      <span class="absolute bottom-4 left-4 font-bold" v-if="isImageError">
        {{ $t('wallets.failedToLoad') }}
      </span>
    </section>

    <footer class="flex-1 flex items-center overflow-hidden bg-gray-950">
      <div class="flex flex-col gap-2 w-full px-4 py-6.5">
        <div class="flex justify-between items-center gap-4 font-medium">
          <p>
            {{ parseNftName(lootboxItem.token.name).name }}
          </p>

          <p v-if="showAmount" class="text-gray-400">
            {{
              getDisplayAmount(lootboxItem.amount, lootboxItem.token.decimals)
            }}
            <span class="mr-2 text-sm">{{ lootboxItem.token.symbol }}</span>
          </p>
          <p v-else class="text-gray-400">
            {{ parseNftName(lootboxItem.token.name).number }}
          </p>
        </div>
        <div v-if="rank" class="flex gap-1">
          <base-icon
            v-for="i in rank"
            :key="i"
            :name="rankIcon"
            size="size-5"
          />
        </div>
      </div>
    </footer>
  </article>
</template>
