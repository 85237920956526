import { defineAsyncComponent } from 'vue';
import { useDialog } from '@/shared/model';

export function useSettingsDialog() {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openSettingsDialog = () => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import(
              '@/features/live/room-settings/components/DialogContent.vue'
            ),
        ),
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-screen-xs`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  return {
    openSettingsDialog,
    closeDialog,
  };
}
