import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi, dscvrApi } from '@/shared/api';

export const useRejectTransactionMutation = () => {
  return useMutation({
    mutationKey: ['reject-canvas-transaction-mutation'],
    mutationFn: ({
      appUrl,
      scanResponse,
      txId,
    }: {
      appUrl: string;
      scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response;
      txId: string;
    }) =>
      dscvrIcApi.canvas.rejectTransaction(appUrl, scanResponse.requestId, txId),
  });
};
