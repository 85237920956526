<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import { useWalletDialog } from '@/entities/wallets';
  import { usePairSolanaWallet } from '@/entities/wallets';
  import { useUser } from '@/entities/user';
  import { OnboardingLootboxStep, useToast } from '@/shared/model';
  import ContentDialog from '../../ui/Content.vue';
  import { useOnboardingLootboxStateMachine } from '../../model/composables/use-onboarding-lootbox-state-machine';

  const { t } = useI18n({
    useScope: 'global',
  });

  const { showToast } = useToast();
  const { openConnectWalletDialog } = useWalletDialog();
  const { pair } = usePairSolanaWallet();
  const { currentUser } = useUser();
  const { states } = useOnboardingLootboxStateMachine();

  const onClickConnectWallet = async () => {
    const result = await openConnectWalletDialog();

    if (result) {
      const isPaired = await pair(currentUser.value, result);
      if (isPaired) {
        states.value[OnboardingLootboxStep.DONE].on('continue');
      } else {
        showToast({
          title: t('onboarding.connectYourWallet.error'),
          type: 'error',
          durationSeconds: 5,
        });
      }
    }
  };
</script>

<template>
  <content-dialog>
    <template #title>
      {{ t('onboarding.connectYourWallet.title') }}
    </template>
    <template #description>
      {{ t('onboarding.connectYourWallet.description') }}
    </template>
    <template #actions>
      <base-button variant="primary" size="full" @click="onClickConnectWallet">
        {{ t('onboarding.connectYourWallet.connectWallet') }}
      </base-button>
    </template>
  </content-dialog>
</template>
