import { type Ref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getUserByIdKey } from './keys';
import { dscvrApi } from '@/shared/api';
import { config } from '@/shared/lib';
import type { UserSelfView } from 'dfx/edge/edge.did';

export const useGetOnboardingLootboxQuery = (user: Ref<UserSelfView>) => {
  const userId = computed(() => user.value.id.toText());
  return useQuery({
    queryKey: [...getUserByIdKey(userId), 'lootbox'],
    queryFn: () =>
      dscvrApi.lootbox.getGlobalClaimableLootboxById(
        config.ONBOARDING_LOOTBOX_TARGET_ID,
      ),
  });
};
