type AvatarSettings = {
  name: string;
  size: number | string;
  initial_bg: string;
  initial_fg: string;
  initial_size: number;
  initial_weight: number;
  initial_font_family: string;
  height_offset?: number;
};

const unicodeAt = (str: string, index: number) => {
  const c = Array.from(str);
  if (c.length <= index) {
    return '';
  } else {
    return c[index];
  }
};

const getInitials = (name: string) => {
  let results = '';
  if (name.length > 0) {
    const names = name.trim().split(' ');
    if (names.length == 1) {
      results = unicodeAt(names[0], 0) + unicodeAt(names[0], 1);
    } else {
      results = unicodeAt(names[0], 0);
      results += unicodeAt(names[names.length - 1], 0);
    }
  }
  return results.toUpperCase();
};

export const getInitialAvatar = (settings: AvatarSettings) => {
  const defaultParams = {
    name: null,
    size: null,
    initial_bg: null,
    initial_fg: null,
    initial_size: null,
    initial_weight: null,
    initial_font_family: null,
    height_offset: null,
  };

  if (typeof settings.size === 'string') {
    settings.size = parseInt(settings.size);
  }

  settings.name = getInitials(settings.name);

  const paramsWithDefaults = { ...defaultParams, ...settings };

  if (!paramsWithDefaults.height_offset) {
    //resolves an issue with `Inter` font rendering
    paramsWithDefaults.height_offset = Math.floor(
      settings.size / 33.33333333333333,
    );
  }

  const queryParams = new URLSearchParams();
  Object.entries(paramsWithDefaults).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, value?.toString());
    }
  });

  return `https://avatar-gen.dscvr.one/?${queryParams.toString()}`;
};

export function getAvatarIcon(name: string, override = {}) {
  // i.e https://ui-avatars.com/api/?background=2564eb&color=fff&name=Supernova
  return getInitialAvatar({
    name,
    size: 512,
    initial_bg: '#374151',
    initial_fg: '#fff',
    initial_size: 0,
    initial_weight: 400,
    initial_font_family: 'Inter',
    ...override,
  });
}

/**
 *
 * @param name
 * @param color
 */
export function getFallbackIcon(name: string, backgroundColor = '2564eb') {
  return `https://ui-avatars.com/api/?background=${backgroundColor}&color=fff&name=${name}`;
}
