import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useRemoveMemberRoleMutation = () => {
  return useMutation({
    mutationKey: ['remove-member-role-mutation'],
    mutationFn: (params: dscvrIcApi.portal.AddRemoveMemberRoleMutationParams) =>
      dscvrIcApi.portal.removeMemberRole(params),
  });
};
