export class WalletNotFoundError extends Error {
  name = 'WalletNotFoundError';
}

export class WalletNotInstalledError extends Error {
  name = 'WalletNotInstalledError';
}

export class WalletPairError extends Error {
  name = 'WalletPairError';
}
