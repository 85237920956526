import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { isValidHttpUrl } from '@/shared/lib';

export const useCreateCanvasMutation = () => {
  return useMutation({
    mutationKey: ['create-canvas-mutation'],
    mutationFn: async ({
      url,
      userPrincipal,
    }: {
      url: string;
      userPrincipal?: string;
    }) => {
      if (!isValidHttpUrl(url)) return;

      const appInfo = await dscvrApi.proxy.createEmbeddedApp(
        url,
        userPrincipal,
      );

      if (!appInfo || appInfo.type !== 'canvas') {
        return;
      }

      return appInfo.data;
    },
  });
};
