import { useI18n } from 'vue-i18n';
import { computed, ref, type Ref } from 'vue';
import { uniqueId } from 'lodash-es';
import { useStore } from 'vuex';
import { useToast } from '@/shared/model';
import { ActionTypes } from '@/store/action-types';
import { lang, translationLanguages } from '@/utils/lang';
import { trackEvent } from '@/utils/tracker';
import {
  chainOptions,
  type ChainOption,
  chainOptionSolana,
  chainOptionICP,
  chainOptionAll,
} from '@/utils';
import type { ActionResultUserSelf, UserSettingsView } from 'dfx/edge/edge.did';
// This is an FSD issue, but we will permit for now, while we refactor the codebase
import { useUser } from '@/entities/user';

type TabItem = {
  id: string;
  title: string;
};

type LanguageAvailableOption = 'en' | 'zh' | 'vi';
type LanguageOption = {
  id: string;
  label: string;
  language: LanguageAvailableOption;
};

type TranslationOption = {
  id: string;
  label: string;
  language: string;
};

type PortalSortAvailableOption = 'hot' | 'top' | 'new';
type PortalSortOption = {
  id: string;
  title: PortalSortAvailableOption;
};

export const usePreferenceSettings = () => {
  const isLoading = ref(true);
  const PORTALS_DEFAULT_SORTING_KEY = 'DSCVR_PORTALS_DEFAULT_SORTING';
  const store = useStore();
  const { locale } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { currentUser } = useUser();

  const primaryChain = computed(() => {
    if (
      currentUser.value &&
      currentUser.value.user_settings &&
      currentUser.value.user_settings.preferred_chain.length
    ) {
      if (
        'InternetComputer' in currentUser.value.user_settings.preferred_chain[0]
      ) {
        return 'icp';
      } else if (
        'Solana' in currentUser.value.user_settings.preferred_chain[0]
      ) {
        return 'sol';
      }
    }
    return 'all';
  });

  const primaryChainOption = computed(() => {
    switch (primaryChain.value) {
      case 'icp':
        return chainOptionICP;
      case 'sol':
        return chainOptionSolana;
      default:
        return chainOptionAll;
    }
  });
  /** Section for Page Tabs */
  const tabItems = computed<TabItem[]>(() => [
    {
      id: uniqueId(),
      title: 'General',
    },
    {
      id: uniqueId(),
      title: 'Chains',
    },
  ]);
  const activeTabItem = ref(tabItems.value[0]);
  const setActiveTab = (e: TabItem) => {
    activeTabItem.value = e;
  };

  /** Section for Language */
  const languageSelectionDropdown = ref(false);
  const closeLanguageSelectionDropdown = () => {
    languageSelectionDropdown.value = false;
  };
  const languageOptions = ref<LanguageOption[]>([
    {
      id: uniqueId(),
      label: 'English',
      language: 'en',
    },
    {
      id: uniqueId(),
      label: 'Chinese',
      language: 'zh',
    },
    {
      id: uniqueId(),
      label: 'Vitenamese',
      language: 'vi',
    },
  ]);
  const language = ref<LanguageOption | null>(null);
  const getLanguage = () => {
    const currentLanguage = lang.getQueryLang();
    if (currentLanguage) {
      language.value =
        languageOptions.value.find(
          (option) => option.language === currentLanguage,
        ) || null;
    }
  };
  const setLanguage = (e: LanguageOption) => {
    language.value = e;
    lang.setQueryLang(e.language);
    locale.value = e.language;
    showToast({
      type: 'success',
      title: `Site language set to ${e.label}`,
      durationSeconds: 3,
    });
  };

  /** Section for Translation */
  const translationSelectionDropdown = ref(false);
  const closeTranslationSelectionDropdown = () => {
    translationSelectionDropdown.value = false;
  };
  const translationOptions = ref<TranslationOption[]>(
    translationLanguages.map((language) => ({
      id: uniqueId(),
      label: language.language,
      language: language.code,
    })) as TranslationOption[],
  );
  const translation = ref<TranslationOption | null>(null);
  const getTranslation = () => {
    const currentTranslation = lang.getTranslationLang();
    if (currentTranslation) {
      translation.value =
        translationOptions.value.find(
          (option) => option.language === currentTranslation,
        ) || null;
    }
  };
  const setTranslation = (e: TranslationOption) => {
    translation.value = e;
    lang.setTranslationLang(e.language);
    trackEvent('user_settings_action', 'set_translation_lang', e.label);
    showToast({
      type: 'success',
      title: `Translation set to ${e.label}`,
      durationSeconds: 3,
    });
  };

  /** Section for Portal Sorting */
  const portalSortSelectionDropdown = ref(false);
  const closePortalSortSelectionDropdown = () => {
    portalSortSelectionDropdown.value = false;
  };
  const portalSortOptions = ref<PortalSortOption[]>([
    {
      id: uniqueId(),
      title: 'hot',
    },
    {
      id: uniqueId(),
      title: 'top',
    },
    {
      id: uniqueId(),
      title: 'new',
    },
  ]);
  const portalSort = ref<PortalSortOption | null>(null);
  const getPortalSort = () => {
    const portal = localStorage.getItem(PORTALS_DEFAULT_SORTING_KEY);
    if (portal) {
      portalSort.value =
        portalSortOptions.value.find((option) => option.title === portal) ||
        null;
    }
  };
  const setPortalSort = (e: PortalSortOption) => {
    portalSort.value = e;
    localStorage.setItem(PORTALS_DEFAULT_SORTING_KEY, portalSort.value.title);
    showToast({
      type: 'success',
      title: `Portal sorting set to ${e.title}`,
      durationSeconds: 3,
    });
  };

  const nsfw = ref(false);
  const isNsfwLoading = ref(false);
  const getNSFW = () => {
    nsfw.value = currentUser.value.user_settings.allow_nsfw;
  };
  const setNSFW = async (value: boolean) => {
    nsfw.value = value;
    const settings = currentUser.value.user_settings;
    if (value === settings.allow_nsfw) return;
    settings.allow_nsfw = nsfw.value;
    try {
      isNsfwLoading.value = true;
      await store.dispatch(`auth/${ActionTypes.SET_SETTINGS}`, settings);
      showToast({
        type: 'success',
        title: `NSFW is ${value ? 'enabled' : 'disabled'}`,
        durationSeconds: 3,
      });
      isNsfwLoading.value = false;
      return true;
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error while saving NSFW state: ${error}`,
        durationSeconds: 3,
      });
    } finally {
      isNsfwLoading.value = false;
    }
    return false;
  };

  /** Section for Chains – Primary Chain Selection Dropdown */
  const chainSelectionDropdown = ref(false);
  const closeChainSelectionDropdown = () => {
    chainSelectionDropdown.value = false;
  };
  /** Section for Chains – Primary Chain Selection */
  const primaryChainOptions = ref<ChainOption[]>(chainOptions);
  const getSelf = async () => {
    const { result }: ActionResultUserSelf = await store.dispatch(
      `auth/${ActionTypes.GET_SELF}`,
      { hasLoading: true },
    );
    return result[0];
  };
  const setPrimaryChain = async (e: ChainOption) => {
    const user = await getSelf();
    store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, true);
    let preferredChain: UserSettingsView['preferred_chain'] = [];
    switch (e.chain) {
      case 'icp':
        preferredChain = [{ InternetComputer: null }];
        break;
      case 'sol':
        preferredChain = [{ Solana: null }];
        break;
      default:
        preferredChain = [];
        break;
    }
    const settings: UserSettingsView = {
      ...user?.user_settings,
      preferred_chain: preferredChain,
    } as UserSettingsView;
    try {
      await store.dispatch(`auth/${ActionTypes.SET_SETTINGS}`, settings);
      await getSelf();
      showToast({
        id: uniqueId(),
        type: 'success',
        title: `Primary chain updated to ${e.title}`,
        durationSeconds: 3,
      });
    } catch (error) {
      console.error(error);
      showToast({
        id: uniqueId(),
        type: 'error',
        title: `Unable to update the primary chain: ${error}`,
        durationSeconds: 3,
      });
    } finally {
      store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, false);
    }
  };

  return {
    isLoading,
    tabItems,
    activeTabItem,
    setActiveTab,
    languageSelectionDropdown,
    closeLanguageSelectionDropdown,
    languageOptions,
    language,
    getLanguage,
    setLanguage,
    translationSelectionDropdown,
    closeTranslationSelectionDropdown,
    translationOptions,
    translation,
    getTranslation,
    setTranslation,
    portalSortSelectionDropdown,
    closePortalSortSelectionDropdown,
    portalSortOptions,
    portalSort,
    getPortalSort,
    setPortalSort,
    nsfw,
    isNsfwLoading,
    getNSFW,
    setNSFW,
    chainSelectionDropdown,
    closeChainSelectionDropdown,
    primaryChainOptions,
    primaryChain,
    setPrimaryChain,
    primaryChainOption,
  };
};
