<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { CheckboxField } from '@/shared/ui/fields';
  import { useBreakpoint, useClipboardToast } from '@/shared/model';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { dscvrApi } from '@/shared/api';
  import { useQueryClient } from '@tanstack/vue-query';
  import { shortenString } from '@/shared/lib';
  import { getMyWalletsQueryKey } from '@/entities/wallets/api/keys';
  import { useSetWalletStatusMutation } from '@/entities/wallets/api/use-set-wallet-status.mutation';

  const props = withDefaults(
    defineProps<{
      wallet: dscvrApi.wallet.Wallet;
      idPrefix?: string;
    }>(),
    {
      idPrefix: 'Address',
    },
  );

  const { copyToClipboard } = useClipboardToast();
  const { isSmallerThanMd } = useBreakpoint();
  const queryClient = useQueryClient();
  const { mutate: mutateSetStatus, isPending } = useSetWalletStatusMutation();

  const shortenedAddress = computed(() =>
    shortenString(props.wallet.address || ''),
  );

  const setWalletStatus = (status: dscvrApi.wallet.PatchWalletStatus) => {
    mutateSetStatus(
      { address: props.wallet.address, status },
      {
        onSettled: () =>
          queryClient.invalidateQueries({
            queryKey: getMyWalletsQueryKey,
          }),
      },
    );
  };

  const primaryWalletChanged = (event: Event) => {
    let checked = (event.target as HTMLInputElement).checked;
    setWalletStatus({
      isPrimary: checked,
    });
  };

  const setWalletPublic = (value: boolean) => {
    setWalletStatus({
      isPublic: value,
    });
  };
</script>

<template>
  <section class="relative w-full">
    <overlay-loader v-if="isPending" />
    <div
      :class="{
        'grid grid-cols-1 place-items-end': isSmallerThanMd,
        hidden: !isSmallerThanMd,
      }"
    >
      <div class="flex items-center justify-end pb-4">
        <span
          class="mr-4 min-w-[92px] text-sm font-medium leading-4.5 tracking-[0.14px]"
        >
          {{ $t('allowFrames') }}
        </span>
        <checkbox-field
          label-position="left"
          name="allow-frames"
          variant="toggle"
          :model-value="wallet.isPublic"
          @update:model-value="setWalletPublic"
        />
      </div>
    </div>
    <div
      class="flex flex-col w-full border rounded-xl bg-gray-950 border-gray-750"
    >
      <div
        class="flex items-center justify-between p-5 bg-black gap-x-2 bg-opacity-15"
      >
        <div
          class="flex items-center overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <base-icon
            :name="`multi-wallet-${wallet.walletType.slug}`"
            size="size-10"
            class="inline"
          />
          <div
            class="flex flex-col w-full ml-3 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <div class="flex whitespace-normal">
              <p
                class="mr-2 font-semibold leading-5 text-base tracking-[0.16px]"
              >
                {{ wallet.networks[0].name }}
              </p>
            </div>
            <div class="flex items-center mt-2 gap-x-1">
              <span
                class="overflow-hidden text-sm tracking-[0.14px] leading-4.5 font-medium text-gray-400 text-ellipsis whitespace-nowrap"
              >
                {{ idPrefix }}: {{ shortenedAddress }}
              </span>
              <base-button
                variant="custom"
                @click="copyToClipboard(wallet!.address)"
              >
                <base-icon name="outlined-copy" size="size-4" />
              </base-button>
            </div>
          </div>
        </div>
        <section class="flex items-center justify-between w-auto md:w-1/3">
          <!-- Toggle button -->
          <div
            :class="{
              'flex items-center': !isSmallerThanMd,
              hidden: isSmallerThanMd,
            }"
          >
            <span
              class="mr-4 min-w-[92px] w-full text-sm font-medium leading-4.5 tracking-[0.14px]"
            >
              {{ $t('allowFrames') }}
            </span>
            <checkbox-field
              label-position="left"
              name="allow-frames"
              variant="toggle"
              :model-value="wallet.isPublic"
              @update:model-value="setWalletPublic"
            />
          </div>
          <!-- Primary radio -->
          <div class="flex items-center">
            <label
              for="primary-wallet"
              class="mr-3 w-full text-sm font-medium leading-4.5 tracking-[0.14px]"
            >
              {{ $t('primaryWallet') }}
            </label>
            <input
              id="primary-wallet"
              name="primary-wallet"
              type="radio"
              :checked="wallet.isPrimary"
              class="size-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
              @change="primaryWalletChanged"
            />
          </div>
        </section>
      </div>
    </div>
  </section>
</template>
