<script lang="ts" setup>
  import { computed } from 'vue';
  import { type IdentityType, type AccountType } from '@/shared/model';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { useDevice } from '@/shared/model';
  import { Avatar } from '@/shared/ui/avatar';
  import { solanaLogos } from '../../lib/solanaLogos';
  import { useAuth } from '../../model/composables/use-auth';
  import { useI18n } from 'vue-i18n';

  type LocalIdentityType = Exclude<IdentityType, 'unknown'>;

  const { t } = useI18n({ useScope: 'global' });
  const {
    isInternetIdentityOrOther,
    authEntity,
    showLoginSignUpDialog,
    closeDialog,
    login,
    resetAuthEntity,
  } = useAuth();

  const button = computed(() => {
    return {
      google: {
        label: 'Google',
        iconName: 'colored-google',
        accountType: 'google',
      },
      nfid: {
        label: 'NFID + Google (Legacy)',
        iconName: 'colored-nfid',
        accountType: 'other',
      },
      'internet-identity': {
        label: 'Internet Identity',
        iconName: 'colored-internet-computer',
        accountType: 'internet identity',
      },
      wallet: {
        label: 'wallet',
        iconName: 'solana',
        accountType: 'solana-wallets',
      },
      username: {
        label: 'Continue',
        iconName: 'solana',
        accountType: 'username',
      },
      worldcoin: {
        label: 'Worldcoin',
        iconName: 'worldcoin',
        accountType: 'worldcoin',
      },
    }[authEntity.value.identityType as LocalIdentityType];
  });

  const { isEmbedded } = useDevice();

  const isInternetIdentityOrOtherButton = computed(() => {
    return (
      button.value.accountType === 'internet identity' ||
      button.value.accountType === 'other'
    );
  });

  const onClickBack = () => {
    resetAuthEntity();
    showLoginSignUpDialog(authEntity.value.form.type);
  };

  const onClickClose = () => {
    resetAuthEntity();
    closeDialog();
  };
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-5">
    <base-button
      variant="link"
      custom-classes="absolute top-10 left-9"
      @click="onClickBack"
    >
      <base-icon name="chevron-left" size="size-5" />
    </base-button>
    <base-button
      v-if="authEntity.context === 'dialog'"
      variant="link"
      custom-classes="absolute top-10 right-9"
    >
      <base-icon name="x" size="size-5" @click="onClickClose" />
    </base-button>
    <span>
      <img
        :src="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/auth/404.gif`)"
        alt="account not found"
        class="w-[169px]"
      />
    </span>
    <template v-if="authEntity.identityType !== 'username'">
      <span class="text-lg font-semibold leading-normal text-center w-52">
        {{ t('auth.detectionForm.accountFound') }}
      </span>
      <span class="text-sm text-center text-gray-400">
        <template v-if="isEmbedded">
          {{ t('auth.detectionForm.yourAccount') }} {{ authEntity.username }}
          {{ t('auth.detectionForm.wasMadeWith') }}
          {{
            isInternetIdentityOrOtherButton
              ? 'Internet Identity or NFID.'
              : `${button.label}.`
          }}
          {{ t('auth.detectionForm.useNonWalletBrowser') }}
        </template>
        <template v-else>
          {{ t('auth.detectionForm.yourAccount') }}
          {{ t('auth.detectionForm.wasMadeWith') }}
          {{
            isInternetIdentityOrOtherButton
              ? `Internet Identity or NFID. ${t(
                  'auth.detectionForm.clickOneOfTheButtons',
                )}`
              : `${button.label}. ${t('auth.detectionForm.clickTheButton')}`
          }}
          {{ t('auth.detectionForm.belowToContinue') }}
        </template>
      </span>
    </template>
    <div
      v-if="!isInternetIdentityOrOther && !isEmbedded"
      class="flex flex-col w-full gap-1"
    >
      <label class="text-sm text-gray-400">
        {{ t('auth.detectionForm.username') }}
      </label>
      <div
        class="flex gap-2 items-center bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl px-4 py-3 w-full [&_*]:cursor-not-allowed opacity-50"
      >
        <div
          class="flex gap-2 p-0 bg-transparent border-0 appearance-none grow"
        >
          {{ authEntity.username }}
        </div>
      </div>
    </div>
    <template v-if="!isEmbedded">
      <template v-if="isInternetIdentityOrOtherButton">
        <base-button
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="w-full text-sm"
          @click="login('other')"
        >
          <base-icon name="colored-nfid" size="w-5 h-5" />
          {{ t('auth.detectionForm.signInWith') }} NFID + Google (Legacy)
        </base-button>
        <base-button
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="w-full text-sm"
          @click="login('internet identity')"
        >
          <base-icon name="colored-internet-computer" size="w-5 h-5" />
          {{ t('auth.detectionForm.signInWith') }} Internet Identity
        </base-button>
      </template>
      <template v-else>
        <base-button
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="w-full text-sm"
          @click="login(button.accountType as AccountType)"
        >
          <avatar
            v-if="button.iconName === 'solana'"
            :avatars="solanaLogos"
            reverse
            size="w-5 h-5"
          />
          <base-icon v-else :name="button.iconName" size="w-5 h-5" />
          {{ t('auth.detectionForm.signInWith') }} {{ button.label }}
        </base-button>
      </template>
    </template>
  </div>
</template>
