import { dscvrIcApi } from '@/shared/api';
import type { OnboardingState } from '@/shared/model';
import { useMutation } from '@tanstack/vue-query';

export const useSetOnboardingStateMutation = () => {
  return useMutation({
    mutationKey: ['set-onboarding-state-mutation'],
    mutationFn: (state: OnboardingState) =>
      dscvrIcApi.onboarding.setOnboardingState(state),
  });
};
