import { uniqueId } from 'lodash-es';

export type AppChainType = 'icp' | 'sol' | 'all';
export type ChainOption = {
  id: string;
  title: string;
  logo: string;
  chain: AppChainType;
};

export const chainOptionSolana: ChainOption = {
  id: uniqueId(),
  title: 'Solana',
  logo: 'solana',
  chain: 'sol',
};

export const chainOptionICP: ChainOption = {
  id: uniqueId(),
  title: 'ICP',
  logo: 'icp',
  chain: 'icp',
};

export const chainOptionAll: ChainOption = {
  id: uniqueId(),
  title: 'All',
  logo: 'all',
  chain: 'all',
};

export const chainOptions: ChainOption[] = [
  chainOptionSolana,
  chainOptionICP,
  chainOptionAll,
];

export const chainOptionsRestricted = chainOptions.filter(
  (chainOption) => chainOption.chain !== 'all',
);
