import jwt_decode from 'jwt-decode';
import type { JWTPayload, LocalStorageDSCVRUser } from './types';

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('gated-user') || '{}');
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('gated-user') || '{}');
    return user?.token;
  }

  updateLocalAccessToken(token: string) {
    const user = JSON.parse(localStorage.getItem('gated-user') || '{}');
    user.token = token;
    localStorage.setItem('gated-user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('gated-user') || '{}');
  }

  setUser(user: LocalStorageDSCVRUser) {
    localStorage.setItem('gated-user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('gated-user');
  }

  checkValidToken() {
    const token = this.getLocalAccessToken();
    if (token) {
      const decoded = jwt_decode(token) as JWTPayload;
      const notExpired = Date.now() < decoded.exp * 1000;
      !notExpired && this.removeUser();
      return notExpired;
    }
    return false;
  }
}
export default new TokenService();
