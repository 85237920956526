<template>
  <div class="fixed inset-0 w-full h-full z-10">
    <div
      class="fixed inset-0 bg-black w-full h-full opacity-40"
      :class="{
        'rounded-full': rounded,
      }"
    ></div>

    <div class="lds-ring absolute-center">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'area-loader',
    props: {
      rounded: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    methods: {},
  };
</script>
