import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import type { SolanaNonFungibleToken } from '../types';

export const useVerifyNftPfpMutation = () => {
  return useMutation({
    mutationKey: ['verify-nft-pfp-mutation'],
    mutationFn: async (token: SolanaNonFungibleToken) =>
      dscvrApi.multichainPfps.verify({
        network: 'solana',
        nftId: token.nft_id,
        collectionAddress: token.collection.metaplex_mint ?? '',
      }),
  });
};
