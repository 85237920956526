import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';
import type { PhantomClaimRequest } from 'dfx/edge/edge.did';

export const useClaimPhantomRewardMutation = () => {
  return useMutation({
    mutationKey: ['claim-phantom-reward-mutation'],
    mutationFn: (claimRequest: PhantomClaimRequest) =>
      dscvrIcApi.gimluck.claimGimluck(claimRequest),
  });
};
