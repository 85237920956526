import { DOMAINS_BLACKLIST, VIDEO_STREAMING_DOMAINS } from '@/common';

/**
 *
 * @param domain
 */
export function isBlacklistedDomain(domain: string): boolean {
  return !DOMAINS_BLACKLIST.some((regExp) => regExp.test(domain));
}

/**
 *
 * @param src
 */
export function isVideoUrl(src: string): boolean {
  return VIDEO_STREAMING_DOMAINS.some((regExp) => src.match(regExp));
}

export function attachAttributesToUrl(url: string, attrs: string): string {
  if (url.includes('?')) {
    return `${url}&${attrs}`;
  }
  return `${url}?${attrs}`;
}
/**
 *
 * @param src
 */
export function isImageUrl(src: string): boolean {
  return src.match(/\.(png|svg|jpg|jpeg|bmp|gif|webp)(\?.*)?$/) != null;
}

export function isDataImage(src: string): boolean {
  return src?.includes('data:image');
}

/**
 *
 * @param link
 */
export function isExternalLink(link: string): boolean {
  return (
    !link.startsWith('/') &&
    !link.startsWith('#') &&
    !link.startsWith(`${location.protocol}//${location.hostname}`)
  );
}

export function getIframelySrc(src: string): string | null {
  const iframelyMatch = src.match(
    /^(https:)?\/\/cdn.iframe.ly.*?url=(?<src>[^&]*)/,
  );
  if (iframelyMatch && iframelyMatch.groups?.src) {
    return decodeURIComponent(iframelyMatch.groups.src);
  }
  return null;
}

export function isValidUrl(url: string, ignoreProtocol = false): boolean {
  const urlToTest =
    ignoreProtocol && !/^(https?|ftp):\/\//.test(url) ? `https://${url}` : url;
  const urlTestPattern =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return urlTestPattern.test(urlToTest);
}
