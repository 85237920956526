<script setup lang="ts">
  import { toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_CONTENT } from '@/common';
  import { formatToUnits } from '@/shared/lib';
  import {
    getPortalIcon,
    ActionButton,
    coverGradients,
  } from '@/entities/portal';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const { portalView } = toRefs(props);
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: PORTAL_CONTENT,
      params: { slug: portalView.slug },
    }"
    custom-classes="flex flex-col justify-end w-full rounded-xl p-6 h-42.5 drop-shadow-light border border-gray-800 relative overflow-hidden group before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/10 before:to-black/90 before:z-1"
  >
    <div
      class="absolute inset-0 transition-all duration-500 bg-center bg-cover group-hover:-inset-4"
      :class="{
        'group-hover:hue-rotate-180': !portalView.info[0]?.cover_photo[0],
      }"
    >
      <cover-photo
        :src="portalView.info[0]?.cover_photo[0]"
        :gradients="coverGradients"
      />
    </div>
    <div class="flex items-center z-1 gap-3.5">
      <img
        alt="Portal icon"
        v-lazy="getPortalIcon(portalView)"
        class="rounded-lg shadow-lg w-9 h-9 sm:w-11 sm:h-11"
      />
      <div class="flex flex-col w-full min-w-0 gap-1 text-white">
        <span class="font-medium leading-5 truncate" :title="portalView.name">
          {{ portalView.name }}
        </span>
        <div class="flex items-center gap-4 text-white">
          <div class="flex items-center gap-1">
            <base-icon name="outlined-users2" size="w-4 h-4" />
            <span class="text-xs">
              {{ formatToUnits(portalView.member_count) }}
            </span>
          </div>
          <div class="flex items-center gap-1">
            <base-icon name="vuesax-linear-simcard2" size="w-4 h-4" />
            <span class="text-xs">
              {{ formatToUnits(portalView.content_count) }}
            </span>
          </div>
          <div class="flex justify-end flex-1">
            <action-button :portal-view="portalView" />
          </div>
        </div>
      </div>
    </div>
  </base-button>
</template>
