import { defineAsyncComponent, type Component } from 'vue';
import type {
  CustomComponent,
  PropsToMaybeRefs,
  CustomComponentProps,
} from '@/store';
import { useDialog } from '../../../../model/composables/use-dialog';

interface ConfirmationDialogOptions {
  submit?: () => void;
  headerTitle?: string;
  header?: CustomComponent;
  dialogContentClasses?: string;
  contentProps?: PropsToMaybeRefs<CustomComponentProps>;
  contentSlots: {
    default: Component;
    buttons?: Component;
    cancel?: Component;
    cancelLabel?: Component;
    submit?: Component;
    submitLabel?: Component;
  };
}

export function useConfirmationDialog() {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openConfirmationDialog = (options: ConfirmationDialogOptions) => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: defineAsyncComponent(
            () =>
              import('@/shared/ui/confirmation/components/DialogContent.vue'),
          ),
          props: options.contentProps,
          emits: {
            submit: () => {
              if (options.submit) {
                options.submit();
              }
              resolve(true);
            },
            close: () => {
              closeDialog();
              resolve(false);
            },
          },
          slots: {
            ...options.contentSlots,
            'cancel-label': options.contentSlots.cancelLabel,
            'submit-label': options.contentSlots.submitLabel,
          },
        },
        header: options.header ?? {
          component: defineAsyncComponent(
            () => import('@/shared/ui/base-dialog/TitleHeader.vue'),
          ),
          props: {
            title: options.headerTitle,
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses:
            options.dialogContentClasses ??
            `${dialogDefaults.dialog.contentClasses} max-w-sm`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
      });
    });
  };

  return {
    openConfirmationDialog,
    closeDialog,
  };
}
