import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getClaimableLootboxQueryKey } from './keys';

export const useGetClaimableLootboxQuery = (enabled: Ref<boolean>) => {
  return useQuery({
    queryKey: getClaimableLootboxQueryKey,
    queryFn: dscvrApi.lootbox.getClaimableLootbox,
    enabled,
  });
};
