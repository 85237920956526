<template>
  <div class="w-full flex items-center justify-center">
    <label :for="id" class="flex items-center cursor-pointer">
      <div class="relative">
        <input :id="id" v-model="isChecked" class="sr-only" type="checkbox" />

        <div
          class="bg-gray-600 h-8 w-14 block rounded-full checked:bg-indigo-600"
          :class="{ 'bg-indigo-500': isChecked }"
        />

        <div
          class="bg-white h-6 w-6 absolute top-1 left-1 rounded-full transition transform"
          :class="{ 'translate-x-full': isChecked }"
        />
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'toggle-button',
    props: {
      checked: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['changed'],
    data() {
      return {
        isChecked: false,
        id: null,
      };
    },
    watch: {
      isChecked: {
        immediate: false,
        handler(newVal, oldVal) {
          if (oldVal !== newVal) {
            this.$emit('changed', newVal);
          }
        },
      },
      checked: {
        immediate: false,
        handler(newVal, oldVal) {
          if (oldVal !== newVal) {
            this.isChecked = newVal;
          }
        },
      },
    },
    async mounted() {
      this.id = this._uid;
      this.isChecked = this.checked;
    },
  };
</script>
