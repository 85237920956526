export const roundify = (
  value: number,
  decimalPlaces: number,
  rounding: 'up' | 'down',
) => {
  const factor = 10 ** decimalPlaces;
  return rounding === 'up'
    ? Math.ceil(value * factor) / factor
    : Math.floor(value * factor) / factor;
};
