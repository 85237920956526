<script setup lang="ts">
  import { ref } from 'vue';
  import { UserGridItemLandscape } from '@/entities/user';
  import FeaturedFooter from './FeaturedFooter.vue';
  import SearchForm from './SearchForm.vue';
  import { EXPLORE_PEOPLE } from '@/common';
  import { BaseCollectionView } from '@/shared/ui/base-collection-view';
  import { useSticky } from '@/composables';
  import type { UserView } from 'dfx/edge/edge.did';

  withDefaults(
    defineProps<{
      loading: boolean;
      showSearch: boolean;
      featured?: UserView[];
      count?: number;
    }>(),
    {
      count: 50,
    },
  );

  defineEmits<{
    (e: 'all'): void;
  }>();

  const stickyRef = ref<HTMLElement | null>(null);
  const contentRef = ref<HTMLElement | null>(null);
  const { isStickyActive } = useSticky(stickyRef, contentRef);
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      ref="stickyRef"
      class="flex justify-center sticky top-47.5 bg-gray-975 z-40 w-full"
    >
      <div
        class="flex justify-between items-end w-full max-w-screen-lg mx-4 pb-4"
        :class="{
          'shadow-bottom': isStickyActive,
        }"
      >
        <h2 class="text-2xl md:text-xxxl font-bold text-white">
          {{ $t('recommended') }}
        </h2>
        <div class="flex gap-4">
          <transition
            enter-active-class="animate-fade-left"
            leave-active-class="animate-fade animate-reverse animate-duration-200"
          >
            <search-form v-if="showSearch" small class="hidden md:flex" />
          </transition>
          <base-button
            variant="secondary"
            size="small"
            :to="{ name: EXPLORE_PEOPLE }"
          >
            {{ $t('viewAll') }}
          </base-button>
        </div>
      </div>
    </div>
    <div ref="contentRef" class="flex flex-col w-full max-w-screen-lg mx-4">
      <base-collection-view
        :items="featured ?? []"
        :loading="loading"
        type="grid"
      >
        <template #item="{ item }">
          <user-grid-item-landscape :user="item" />
        </template>
      </base-collection-view>
    </div>
  </div>
  <featured-footer mode="people" />
</template>
