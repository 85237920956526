import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getLootboxTargetQueryKey } from './keys';

export const useGetLootboxTargetQuery = (
  lootboxId: Ref<string | undefined>,
) => {
  return useQuery({
    queryKey: getLootboxTargetQueryKey(lootboxId),
    queryFn: () => {
      if (!lootboxId.value) {
        return undefined;
      }
      return dscvrApi.lootbox.getLootboxTarget(lootboxId.value);
    },
    enabled: () => !!lootboxId.value,
  });
};
