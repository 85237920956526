<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import VRuntimeTemplate from 'vue3-runtime-template';
  import { useFormatDynamicContent } from '@/components/dynamic-content';
  import { UserTooltip } from '@/entities/user';
  import BaseIcon from '@/shared/ui/base-icon/BaseIcon.vue';
  import {
    DynamicImage,
    DynamicVideo,
    DynamicIframe,
  } from '@/entities/content';
  import { useEmbeddedMention } from '@/composables';
  import { EmbeddedPortal } from '@/components/tiptap';

  import 'highlight.js/styles/github.css';
  import '@/shared/ui/styles/markdown.css';
  import '@/shared/ui/styles/tailwindExtended.css';
  import '@/shared/ui/styles/highlights.css';

  const props = defineProps<{
    body: string;
  }>();

  const { body } = toRefs(props);

  const { computedHtml } = useFormatDynamicContent(body);
  const { usersHash, portalsHash } = useEmbeddedMention();

  const templateProps = computed(() => ({
    usersHash: usersHash.value,
    portalsHash: portalsHash.value,
    days: '0',
    multiplier: '0',
    points: '0',
    username: '',
  }));

  const templateOptions = {
    $options: {
      components: {
        EmbeddedPortal,
        UserTooltip,
        BaseIcon,
        DynamicImage,
        DynamicVideo,
        DynamicIframe,
      },
    },
  };
</script>

<template>
  <div ref="container" class="text-white dynamic-content prose">
    <v-runtime-template
      :template="computedHtml"
      :parent="templateOptions"
      :template-props="templateProps"
    />
  </div>
</template>
