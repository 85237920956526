<script lang="ts" setup>
  import { useDialog } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import { useIgc } from '../../model/composables/use-igc';
  import type { ContentView } from 'dfx/edge/edge.did';

  const props = withDefaults(
    defineProps<{
      contentView?: ContentView;
      showBackButton?: boolean;
    }>(),
    {
      showBackButton: false,
    },
  );

  const { t } = useI18n({
    useScope: 'global',
  });
  const { closeDialog } = useDialog();
  const { openConfiguredAwardsSelectionDialog } = useIgc(props.contentView);
</script>

<template>
  <div class="flex gap-4 px-8 py-5">
    <div class="flex items-center gap-4 w-full">
      <base-button
        v-if="showBackButton"
        variant="link"
        @click="openConfiguredAwardsSelectionDialog"
      >
        <base-icon name="chevron-left" size="size-5" />
      </base-button>
      <div class="text-lg font-bold">
        {{ t('igc.purchaseDialog.title') }}
      </div>
    </div>
    <base-button variant="custom" class="mt-1" @click="closeDialog">
      <base-icon name="x" size="size-4" />
    </base-button>
  </div>
</template>
