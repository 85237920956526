import { dscvrIcApi } from '@/shared/api';
import { useQuery } from '@tanstack/vue-query';
import { verificationEmailStatusKey } from './keys';

export const useVerificationEmailStatusQuery = () => {
  return useQuery({
    queryKey: verificationEmailStatusKey,
    queryFn: dscvrIcApi.userSettings.getVerificationEmailStatus,
    enabled: false,
  });
};
