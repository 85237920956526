import { computed, type Ref } from 'vue';
import type { AppChainType } from '@/utils';
import type { ContentPreset } from '@/shared/model';
import { usePreferenceSettings } from './use-preference-settings';

type ChainParameter = {
  [chain in AppChainType]: ContentPreset;
};

const chainParameter: ChainParameter = {
  icp: [{ InternetComputer: null }],
  sol: [{ Solana: null }],
  all: [],
};

export const useChainBehavior = () => {
  const { primaryChain } = usePreferenceSettings();

  const selectedChainType = computed((): AppChainType => {
    // when not primary chain is set, the default is solana
    return primaryChain.value || 'sol';
  });

  const isIcpUxChainEnabled = computed(() => {
    return (
      !!selectedChainType.value &&
      ['icp', 'all'].includes(selectedChainType.value)
    );
  });

  const isSolanaUxChainEnabled = computed(() => {
    return (
      !!selectedChainType.value &&
      ['sol', 'all'].includes(selectedChainType.value)
    );
  });

  const contentPreset = computed<ContentPreset>(() => {
    return chainParameter[selectedChainType.value];
  });

  return {
    selectedChainType,
    isIcpUxChainEnabled,
    isSolanaUxChainEnabled,
    contentPreset,
  };
};
