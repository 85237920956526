<script setup lang="ts">
  import { computed } from 'vue';
  import { type ArcadeCardProps, type ArcadeProps } from '../types';

  const props = defineProps<ArcadeCardProps>();
  const item = computed(() => props.item as ArcadeProps);
</script>
<template>
  <div class="flex flex-col md:flex-row w-full items-center justify-between">
    <div class="flex items-center w-full md:w-auto">
      <div
        :style="`background-image: url('${item.background}') `"
        class="min-w-16 h-16 md:w-52 md:min-w-52 md:h-52 bg-center bg-cover mr-4 md:mr-10 rounded-xl ring ring-inset ring-white ring-opacity-10"
      />
      <div
        class="flex flex-row md:flex-col justify-between w-full items-center md:items-start"
      >
        <div class="text-2xl font-bold hidden md:block">{{ item.title }}</div>
        <div class="gap-2 pt-3 pb-4 flex flex-col md:flex-row">
          <div class="text-lg font-bold md:hidden">{{ item.title }}</div>
          <div
            :style="`background-image: url('${item.icon}') `"
            class="hidden md:block min-w-12 w-12 h-12 bg-center bg-cover rounded-xl"
          />
          <div
            class="flex flex-row md:flex-col gap-2 md:gap-0 items-center md:items-start mr-4"
          >
            <div
              class="text-xs md:text-lg font-medium md:font-semibold text-indigo-300 md:text-white"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <slot name="button" />
      </div>
    </div>
    <div class="w-1/2 hidden md:block text-gray-300">
      {{ item.description }}
      <div v-if="item.sourceCode">
        Source Code:
        <a :href="item.sourceCode" target="_blank" class="underline">{{
          item.sourceCode
        }}</a>
      </div>
    </div>
    <div class="block md:hidden text-gray-300 mt-2">
      {{ item.description }}
      <div v-if="item.sourceCode">
        Source Code:
        <a :href="item.sourceCode" target="_blank" class="underline">{{
          item.sourceCode
        }}</a>
      </div>
    </div>
  </div>
</template>
