import { apiInstance } from '../base';
import type { MagicEdenToken } from '../collection/types';
import type { BuyNowRequest, BuyNowResponse } from './types';
export type { BuyNowRequest, BuyNowResponse };

const BASE_URL = '/token';

export const getToken = (nftId: string) => {
  return apiInstance
    .get(`${BASE_URL}/${nftId}`)
    .then((response) => response.data) as Promise<MagicEdenToken>;
};

export const buyNow = (payload: BuyNowRequest) => {
  return apiInstance
    .post(`${BASE_URL}/buy`, payload)
    .then((response) => response.data) as Promise<BuyNowResponse>;
};
