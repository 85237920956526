import { ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Connection } from '@solana/web3.js';
import { isUserConnectRejectionSolanaError } from '@/shared/lib';
import {
  useConnectSolanaWallets,
  WalletNotFoundError,
} from '@/entities/wallets';
import {
  parseTransaction,
  serializeTransaction,
  useGetTransactionScanQuery,
} from '@/entities/wallet-transaction';
import {
  WalletMethodNotSupportedError,
  CanvasWalletInterface,
  type UntrustedCanvasResponse,
} from '../../types';

export const useCanvasWalletExtension = ({
  appUrl,
  sendMessage,
}: {
  appUrl: Ref<string>;
  sendMessage: (
    message: UntrustedCanvasResponse<CanvasWalletInterface.HostMessage>,
  ) => void;
}) => {
  const chainId = ref<string>();
  const transactionId = ref<string>();

  const { t } = useI18n({ useScope: 'global' });
  const {
    wallet,
    disconnect,
    sendTransaction,
    signTransaction,
    signAllTransactions,
    signMessage,
  } = useConnectSolanaWallets();
  const { refetch: getTransactionScan } = useGetTransactionScanQuery(
    appUrl,
    chainId,
    transactionId,
  );

  // fixed to mainnet by default
  const confirmTransaction = async (
    unsignedTx: string,
    chain: string = 'solana:101',
  ) => {
    chainId.value = chain;
    transactionId.value = unsignedTx;
    const { data: scanResponse } = await getTransactionScan();

    if (!scanResponse?.requestId) {
      throw new Error(t('walletTransaction.transactionGenericError'));
    }
    return true;
  };

  const handleDisconnect = async (
    message: CanvasWalletInterface.DisconnectRequest,
  ) => {
    try {
      await disconnect();
      sendMessage({
        type: 'wallet:disconnect-response',
        untrusted: {
          name: message.payload.name,
          success: true,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      sendMessage({
        type: 'wallet:disconnect-response',
        untrusted: {
          name: message.payload.name,
          success: false,
          errorReason: 'error',
          error: error.message,
        },
      });
    }
  };

  const handleSendTransaction = async (
    message: CanvasWalletInterface.SendTransactionRequest,
  ) => {
    try {
      const connection = new Connection(message.payload.rpcEndpoint);
      const transaction = parseTransaction(message.payload.unsignedTx);
      const confirm = await confirmTransaction(
        message.payload.unsignedTx,
        message.payload.chain,
      );
      if (!confirm) {
        throw new Error('User rejected the request.');
      }

      const signedTx = await sendTransaction(
        transaction,
        connection,
        message.payload.options,
      );

      sendMessage({
        type: 'wallet:send-transaction-response',
        untrusted: {
          success: true,
          name: message.payload.name,
          signedTx,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      const errorReason = isUserConnectRejectionSolanaError(error)
        ? 'user-cancelled'
        : 'error';

      sendMessage({
        type: 'wallet:send-transaction-response',
        untrusted: {
          name: message.payload.name,
          success: false,
          errorReason,
          error: error.message,
        },
      });
    }
  };

  const handleSignTransaction = async (
    message: CanvasWalletInterface.SignTransactionRequest,
  ) => {
    try {
      if (!signTransaction.value) {
        throw new WalletMethodNotSupportedError('signTransaction');
      }
      const unsignedTx = parseTransaction(message.payload.unsignedTx);

      const confirm = await confirmTransaction(
        message.payload.unsignedTx,
        message.payload.chain,
      );
      if (!confirm) {
        throw new Error('User rejected the request.');
      }

      const transaction = await signTransaction.value(unsignedTx);

      const signedTx = serializeTransaction(transaction);
      sendMessage({
        type: 'wallet:sign-transaction-response',
        untrusted: {
          success: true,
          name: message.payload.name,
          signedTx,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      const errorReason = isUserConnectRejectionSolanaError(error)
        ? 'user-cancelled'
        : 'error';

      sendMessage({
        type: 'wallet:sign-transaction-response',
        untrusted: {
          name: message.payload.name,
          success: false,
          errorReason,
          error: error.message,
        },
      });
    }
  };

  const handleSignAllTransactions = async (
    message: CanvasWalletInterface.SignAllTransactionsRequest,
  ) => {
    try {
      if (!signAllTransactions.value) {
        throw new WalletMethodNotSupportedError('signAllTransactions');
      }
      const unsignedTxs = message.payload.unsignedTxs.map(parseTransaction);
      const transactions = await signAllTransactions.value(unsignedTxs);

      const signedTxs = transactions.map(serializeTransaction);
      sendMessage({
        type: 'wallet:sign-all-transactions-response',
        untrusted: {
          success: true,
          name: message.payload.name,
          signedTxs,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      const errorReason = isUserConnectRejectionSolanaError(error)
        ? 'user-cancelled'
        : 'error';

      sendMessage({
        type: 'wallet:sign-all-transactions-response',
        untrusted: {
          name: message.payload.name,
          success: false,
          errorReason,
          error: error.message,
        },
      });
    }
  };

  const handleSignMessage = async (
    message: CanvasWalletInterface.SignMessageRequest,
  ) => {
    try {
      if (!signMessage.value) {
        throw new WalletMethodNotSupportedError('signMessage');
      }
      const signedMessage = await signMessage.value(
        message.payload.unsignedMessage,
      );

      sendMessage({
        type: 'wallet:sign-message-response',
        untrusted: {
          success: true,
          name: message.payload.name,
          signedMessage,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      const errorReason = isUserConnectRejectionSolanaError(error)
        ? 'user-cancelled'
        : 'error';

      sendMessage({
        type: 'wallet:sign-message-response',
        untrusted: {
          name: message.payload.name,
          success: false,
          errorReason,
          error: error.message,
        },
      });
    }
  };

  const handleReceiveMessage = (event: MessageEvent): boolean => {
    const messageData = event.data;
    const message = CanvasWalletInterface.parseClientMessage(messageData);
    if (!message) {
      return false;
    }

    if (!wallet.value || wallet.value.id.toString() !== message.payload.name) {
      throw new WalletNotFoundError();
    }
    switch (message.type) {
      case 'wallet:disconnect-request':
        handleDisconnect(message);
        break;
      case 'wallet:send-transaction-request':
        handleSendTransaction(message);
        break;
      case 'wallet:sign-transaction-request':
        handleSignTransaction(message);
        break;
      case 'wallet:sign-all-transactions-request':
        handleSignAllTransactions(message);
        break;
      case 'wallet:sign-message-request':
        handleSignMessage(message);
        break;
      default:
        throw new Error(t('canvas.unknownMessageError'));
    }
    return true;
  };

  return { handleReceiveMessage };
};
