<script lang="ts" setup>
  import { useBreakpoint } from '@/shared/model';
  import type { UserTooltipProps } from '../../types';
  import MobileView from './Mobile.vue';
  import DesktopView from './Desktop.vue';

  const props = withDefaults(defineProps<UserTooltipProps>(), {
    avatarSize: 'lg',
    showPfp: true,
    isSelfPost: false,
  });

  const emits = defineEmits<{
    (e: 'clicked-user-tooltip'): void;
  }>();

  const { isSmallerThanMd } = useBreakpoint();
</script>

<template>
  <div
    class="relative inline-block not-prose"
    @click="emits('clicked-user-tooltip')"
  >
    <template v-if="isSmallerThanMd">
      <mobile-view v-bind="props" />
    </template>
    <template v-else>
      <desktop-view v-bind="props" />
    </template>
  </div>
</template>

<style scoped>
  .prose a.mention {
    color: rgb(99, 102, 241) !important;
  }
</style>
