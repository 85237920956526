<script lang="ts" setup>
  import { shallowRef, ref, watch, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { DEFAULT_LAYOUT, HOME, FEED_PAGES } from '@/common';
  import BaseLayout from './base-layout.vue';
  import ReportModal from '@/components/ReportModal.vue';
  import { BackButton } from '@/features/navigation';
  import { useUser } from '@/entities/user';
  import { Loader } from '@/shared/ui/loader';
  import { useNavigation } from '@/features/navigation';

  withDefaults(
    defineProps<{
      disabled?: boolean;
    }>(),
    {
      disabled: false,
    },
  );

  const route = useRoute();
  const router = useRouter();
  const { isLoggedIn } = useUser();
  const { isSwiping, direction } = useNavigation();
  const loading = ref(false);
  const layout = shallowRef(DEFAULT_LAYOUT);

  const showLoading = computed(
    () =>
      FEED_PAGES.includes(route.name as string) &&
      ((isSwiping.value && direction.value === 'down') || loading.value),
  );
  router.afterEach((to) => {
    layout.value = (to.meta?.layout as string) ?? DEFAULT_LAYOUT;
  });

  watch(isSwiping, (value) => {
    if (value) {
      loading.value = true;
    }
  });
</script>

<template>
  <div
    :class="{
      Dk3b7fZqA2LmN1R9P8Xw: disabled,
    }"
  >
    <template v-if="layout === 'blank-layout'">
      <component :is="layout">
        <slot />
      </component>
    </template>
    <base-layout v-else>
      <div v-if="showLoading" class="relative pt-16">
        <loader
          variant="rainbow"
          size="size-8"
          border-width="border"
          top="top-26"
          wrapper-classes="z-30 w-full p-10"
          :duration-seconds="1"
          @done="loading = false"
        />
      </div>
      <div
        class="mx-auto bg-gray-975 mb-16 md:mb-0"
        :class="route.name === HOME && !isLoggedIn ? 'pt-10' : 'pt-20 md:pt-24'"
      >
        <back-button />
        <component :is="layout">
          <slot />
        </component>
        <report-modal />
      </div>
    </base-layout>
  </div>
</template>

<style lang="postcss" scoped>
  .Dk3b7fZqA2LmN1R9P8Xw {
    @apply pointer-events-none select-none fixed w-full;
  }
</style>
