import { WalletSendTransactionError } from '@solana/wallet-adapter-base';
import { isUserRejectionSolanaError } from '@/shared/lib';
import { useSignAndSendTransaction } from '../../api/use-sign-and-send-transaction.mutation';
import type { Adapter } from '@solana/wallet-adapter-base';
import { parseTransaction } from '../../lib/parse-transaction';
import { useToast } from '@/shared/model';
import { useI18n } from 'vue-i18n';

export const useWalletTransaction = () => {
  const { mutateAsync: signWalletTransactionAsyncMutation } =
    useSignAndSendTransaction();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const onSignedWalletTransactionSignature = async (
    chainId: string,
    unsignedTx: string,
    walletAdapter: Adapter,
  ) => {
    return await signWalletTransactionAsyncMutation(
      {
        chainId: chainId,
        unsignedTx: parseTransaction(unsignedTx),
        walletAdapter,
      },
      {
        onError: (error) => {
          if (
            error instanceof WalletSendTransactionError &&
            isUserRejectionSolanaError(error)
          ) {
            return;
          }

          showToast({
            title: t('walletTransaction.transactionGenericError'),
            type: 'error',
            durationSeconds: 5,
          });
        },
      },
    );
  };

  return {
    onSignedWalletTransactionSignature,
  };
};
