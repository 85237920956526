export const feedKey = 'feed';
export const forYouFeedKey = 'for-you-feed';
export const listContentKey = 'list-content';
export const userPostsListKey = 'user-posts-list';
export const followingFeedKey = 'following-feed';
export const searchTagsKey = 'search-tags';
export const allDscvrFeedKey = 'all-dscvr';
export const searchFeedKey = 'search';
export const portalsFeedKey = 'portals';
export const portalFeedKey = 'portal';
export const contentKey = 'content';
export const getContentByIdKey = 'get-content-by-id';

export const getFeedByNameKey = (feed: string) => {
  return [contentKey, feedKey, feed];
};
