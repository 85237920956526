<script setup lang="ts">
  import { ref, computed, onMounted, onBeforeMount } from 'vue';
  import { Loader } from '@/shared/ui/loader';
  import { CustomDynamicScrollerItem } from '@/shared/ui/virtual-scroller';
  import {
    GroupedItem,
    Item,
    useNotificationQuery,
    useNotificationStore,
  } from '@/entities/notification';
  import { useNotificationSettingStore } from '@/entities/user';
  import { useToast } from '@/shared/model';
  import type {
    AppNotificationPayload,
    NotificationPageQuery,
  } from 'dfx/edge/edge.did';
  import { storeToRefs } from 'pinia';

  const MIN_ITEM_SIZE = 250;
  const BUFFER_PX = 4000;

  const { showToast } = useToast();
  const notificationStore = useNotificationStore();
  const notificationSettingStore = useNotificationSettingStore();
  const { groupNotifications } = storeToRefs(notificationSettingStore);

  const notifications = ref<AppNotificationPayload[]>([]);
  const page = ref(BigInt(0));
  const isEmpty = computed(() => notifications.value.length === 0);
  const isLoading = ref(false);

  const computedNotifications = computed(() => {
    return notifications.value.map((notification) => {
      return {
        ...notification,
        idAsString: notification.notification_id.toString(),
      };
    });
  });

  const load = async () => {
    if (!isLoading.value) {
      isLoading.value = true;
      try {
        const payload: NotificationPageQuery = {
          page: page.value,
          page_size: BigInt(12),
          include_types: [],
          grouping_enabled: [groupNotifications.value],
        };
        const response = await useNotificationQuery(payload);
        if ('Ok' in response) {
          // @ts-ignore
          notifications.value = [
            ...notifications.value,
            ...response.Ok.notification_page.notifications,
          ];
          page.value += BigInt(1);
        } else {
          showToast({
            type: 'error',
            title: 'Error',
            description: `Failed to fetch notifications: ${response.Err}`,
          });
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: 'Error',
          description: `Failed to fetch notifications: ${error}`,
        });
      } finally {
        isLoading.value = false;
      }
    }
  };

  onBeforeMount(async () => {
    await notificationSettingStore.getUserSettings();
    await load();
  });

  onMounted(async () => {
    await notificationStore.markAllNotificationsAsRead();
  });
</script>

<template>
  <div
    class="max-w-screen-lg p-5 text-white bg-gray-950 shadow-lg post-item sm:rounded-xl"
  >
    <div class="my-6 text-lg font-bold text-center text-white sm:text-left">
      NOTIFICATIONS
    </div>
    <div
      v-if="!notifications.length"
      class="mt-3 text-xs font-bold text-gray-400"
    >
      There are no notifications currently
    </div>
    <template v-if="!isEmpty">
      <section class="flex flex-col">
        <dynamic-scroller
          class="overflow-y-auto thin-scrollbar h-full flex-1"
          :items="computedNotifications"
          key-field="idAsString"
          :min-item-size="MIN_ITEM_SIZE"
          :buffer="BUFFER_PX"
          @scroll-end="load()"
        >
          <template #default="{ item: notification, active }">
            <custom-dynamic-scroller-item
              :id="notification.idAsString"
              :item="notification"
              :active="active"
            >
              <component
                :is="groupNotifications ? GroupedItem : Item"
                :key="notification.idAsString"
                :notification="notification"
              />
            </custom-dynamic-scroller-item>
          </template>
        </dynamic-scroller>
      </section>
    </template>
    <div v-if="isLoading" class="relative w-full min-h-48">
      <loader variant="rainbow" border-width="border" size="w-10 h-10" />
    </div>
  </div>
</template>
