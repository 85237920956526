import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useSetUserInterestsMutation = () => {
  return useMutation({
    mutationKey: ['set-user-interests-mutation'],
    mutationFn: (params: { interests: string[] }) =>
      dscvrIcApi.user.setUserInterests(params.interests),
  });
};
