import tippy from 'tippy.js';
import type { GetReferenceClientRect, Instance, Props } from 'tippy.js';
import Mention from '@tiptap/extension-mention';
import { VueRenderer } from '@tiptap/vue-3';
import { UserMentionList } from '@/entities/user';

export function configureMention() {
  return Mention.configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: {
      render: () => {
        let component: VueRenderer;
        let popup: Instance<Props>[];

        return {
          onStart: (props) => {
            component = new VueRenderer(UserMentionList, {
              props,
              editor: props.editor,
            });
            if (!props.clientRect) {
              return;
            }
            popup = tippy('body', {
              getReferenceClientRect:
                props.clientRect as GetReferenceClientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              theme: 'mention',
              trigger: 'manual',
              placement: 'bottom-start',
              onShow: () => {
                try {
                  props.clientRect?.();
                } catch {
                  return false;
                }
              },
            });
          },
          async onUpdate(props) {
            component.updateProps(props);
            if (!props.clientRect) {
              return;
            }
            popup[0].setProps({
              getReferenceClientRect:
                props.clientRect as GetReferenceClientRect,
            });
          },
          onKeyDown(props) {
            if (props.event.key === 'Escape') {
              popup[0].hide();
              return true;
            }

            return component.ref?.onKeyDown(props);
          },
          onExit() {
            if (popup && popup[0]) popup[0].destroy();
            component.destroy();
          },
        };
      },
    },
  });
}
