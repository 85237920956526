import { ref, computed, type Ref } from 'vue';
import { useBreakpoint } from '@/shared/model';
import { isValidUrl } from '@/utils';
import type { Frame, FrameButton, FrameActionPayload } from '../../types';
import { useHandleFrameAction } from './use-handle-frame-action';
import type { IconName } from '@/shared/ui/base-icon';
import { fetchMedia } from '@/shared/lib';

export const useEmbeddedFrame = (
  url: Ref<string>,
  isDebug: Ref<boolean | undefined>,
  inCarousel: Ref<boolean | undefined>,
  actionCallback: (payload: FrameActionPayload) => void,
) => {
  const isPayloadLoading = ref(true);
  const isImageLoading = ref(false);
  const imageUrl = ref<string>();
  const errorLoadingImage = ref(false);
  const savedImgHeight = ref<number | undefined>();
  const inputTextFieldValue = ref<string>();
  const embeddedFrame = ref<Frame | undefined>();
  const framesCount = ref(0);

  const { isSmallerThanSm, isSmallerThanMd, isSmallerThanXl } = useBreakpoint();
  const {
    reportCount,
    transactedCount,
    loadFirstFrame,
    handleImageClick,
    handleButtonClick,
  } = useHandleFrameAction({
    url,
    currentFrame: embeddedFrame,
    isDebug,
    startFrameLoading: () => startFrameLoading(),
    endFrameLoading: (frame) => endFrameLoading(frame),
    actionCallback,
  });

  const hasActions = computed(() => {
    return (
      embeddedFrame.value?.inputText || embeddedFrame.value?.buttons.length
    );
  });

  const isFrameLoading = computed(
    () => isPayloadLoading.value || isImageLoading.value,
  );

  const numberOfGridColumns = computed(() => {
    if (embeddedFrame.value?.buttons.length === 1) {
      return 'grid-cols-1';
    }

    if (embeddedFrame.value?.buttons.length === 2) {
      return 'grid-cols-2';
    }

    if (inCarousel.value && isSmallerThanXl.value) {
      return 'grid-rows-2 grid-cols-2';
    }

    if (embeddedFrame.value?.buttons.length === 3) {
      return isSmallerThanMd.value ? 'grid-rows-2 grid-cols-2' : 'grid-cols-3';
    }
    return isSmallerThanMd.value ? 'grid-rows-2 grid-cols-2' : 'grid-cols-4';
  });

  const buttonSize = computed(() => {
    if (inCarousel.value) {
      return isSmallerThanXl.value ? 'x-small' : 'medium';
    }
    return isSmallerThanSm.value ? 'x-small' : 'medium';
  });

  const buttonIconSize = computed(() => {
    if (inCarousel.value) {
      return isSmallerThanXl.value ? 'size-3' : 'size-5';
    }
    return isSmallerThanSm.value ? 'size-3' : 'size-5';
  });

  const imgContainerHeightPx = computed(() =>
    !inCarousel.value && savedImgHeight.value && !errorLoadingImage.value
      ? `${savedImgHeight.value}px`
      : undefined,
  );

  const imgWrapperClass = computed(() => {
    if (inCarousel.value) return 'min-h-0 flex-1';

    if (imgContainerHeightPx.value) {
      return 'overflow-hidden transition-[height] duration-200 ease-in-out';
    }

    return 'overflow-hidden aspect-[2/1]';
  });

  const getButtonClass = (button: FrameButton) => {
    let baseClass = 'text-center';
    if (inCarousel.value) {
      baseClass += ' truncate';
    }
    const is3rdLastButton =
      embeddedFrame.value?.buttons.length === 3 && button.index === 3;
    if (!is3rdLastButton) {
      return baseClass;
    }

    const show3Columns = numberOfGridColumns.value.includes('grid-cols-3');
    if (show3Columns) {
      return baseClass;
    }

    return `${baseClass} col-span-2`;
  };

  const hasButtonRightIcon = (button: FrameButton) =>
    button.action === 'link' ||
    button.action === 'post_redirect' ||
    button.action === 'mint' ||
    button.action === 'tx';

  const getButtonRightIcon = (button: FrameButton): IconName => {
    return button.action === 'link' || button.action === 'post_redirect'
      ? 'vuesax-linear-export'
      : button.action === 'tx'
      ? 'vuesax-broken-export'
      : 'vuesax-linear-diamonds';
  };

  const preFetchImage = (url: string) => {
    const image = new Image();
    const result = new Promise((resolve) => {
      image.onload = () => {
        resolve(true);
      };
      image.onerror = () => {
        resolve(false);
      };
    });

    image.src = url;
    return result;
  };

  const loadImage = async (image: string) => {
    if (!image || !isValidUrl(image)) {
      return image;
    }

    const proxyImageUrl = fetchMedia(image);
    const isProxyLoaded = await preFetchImage(proxyImageUrl!);
    if (isProxyLoaded) {
      return proxyImageUrl;
    }

    const isOriginalLoaded = await preFetchImage(image!);
    if (isOriginalLoaded) {
      return image;
    }
  };

  const startFrameLoading = () => {
    isPayloadLoading.value = true;
  };

  const endFrameLoading = async (nextFrame?: Frame) => {
    isPayloadLoading.value = false;
    if (nextFrame) {
      inputTextFieldValue.value = '';
      isImageLoading.value = true;
      const resultUrl = await loadImage(nextFrame.imageUrl);
      framesCount.value += 1;
      imageUrl.value = resultUrl;
      errorLoadingImage.value = !resultUrl;
      isImageLoading.value = false;
      embeddedFrame.value = nextFrame;
    }
  };

  const imageLoaded = (event: Event) => {
    const image = event.target as HTMLImageElement;
    savedImgHeight.value = image.height;
  };

  const onClick = (button: FrameButton) =>
    handleButtonClick(button, inputTextFieldValue.value);

  const onClickImage = () => handleImageClick(url.value);

  const introduceFrame = () => {
    framesCount.value = 0;
    return loadFirstFrame();
  };

  return {
    imageUrl,
    hasActions,
    numberOfGridColumns,
    buttonSize,
    buttonIconSize,
    errorLoadingImage,
    savedImgHeight,
    imgWrapperClass,
    embeddedFrame,
    framesCount,
    isFrameLoading,
    imgContainerHeightPx,
    inputTextFieldValue,
    reportCount,
    transactedCount,
    getButtonClass,
    hasButtonRightIcon,
    getButtonRightIcon,
    onClick,
    onClickImage,
    introduceFrame,
    imageLoaded,
  };
};
