import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';
import { encodeTokenIdentifier } from 'ictool';
import { NFT } from './nft';

const to32bits = (num) => {
  const b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};

export const tokenIdentifier = (principal, index) => {
  const padding = Buffer('\x0Atid');
  const array = new Uint8Array([
    ...padding,
    ...Principal.fromText(principal).toUint8Array(),
    ...to32bits(index),
  ]);
  return Principal.fromUint8Array(array).toText();
};

const idlFactory = ({ IDL }) => {
  const EmptyResult = IDL.Variant({ Ok: IDL.Null, Err: IDL.Text });
  const ServiceControllerKind = IDL.Variant({
    Restore: IDL.Null,
    Backup: IDL.Null,
    Admin: IDL.Null,
    Owner: IDL.Null,
  });
  const ServiceController = IDL.Record({
    kind: ServiceControllerKind,
    created_at: IDL.Nat64,
    controller_id: IDL.Principal,
  });
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const HttpRequest = IDL.Record({
    url: IDL.Text,
    method: IDL.Text,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
  });
  const HttpResponse = IDL.Record({
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
    status_code: IDL.Nat16,
  });
  return IDL.Service({
    add_admin: IDL.Func([IDL.Principal], [EmptyResult], []),
    add_asset: IDL.Func([IDL.Vec(IDL.Nat8)], [EmptyResult], []),
    append_asset: IDL.Func([IDL.Vec(IDL.Nat8)], [EmptyResult], []),
    clear_asset: IDL.Func([], [], []),
    get_admins: IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    get_asset: IDL.Func([], [IDL.Vec(IDL.Nat8)], ['query']),
    get_registry: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Nat64)))],
      ['query'],
    ),
    get_service_controllers: IDL.Func(
      [],
      [IDL.Vec(ServiceController)],
      ['query'],
    ),
    http_request: IDL.Func([HttpRequest], [HttpResponse], ['query']),
    mint: IDL.Func([IDL.Principal], [EmptyResult], []),
    remove_admin: IDL.Func([IDL.Principal], [EmptyResult], []),
    test: IDL.Func([], [IDL.Text], ['query']),
    tokens: IDL.Func([IDL.Principal], [IDL.Vec(IDL.Nat64)], ['query']),
  });
};

export class SBT {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  get_token_url(id) {
    return `https://${this.canisterId}.raw.icp0.io/?id=${id}`;
  }

  get_encoded_token_url(id) {
    return `https://${
      this.canisterId
    }.raw.icp0.io/?tokenid=${encodeTokenIdentifier(
      this.canisterId,
      Number(id),
    )}`;
  }

  async getTokens(principal) {
    const tokens = await this.actor.tokens(principal);
    //[this.get_token_url(t), this.canisterId, t]
    if (tokens.length > 0) {
      const results = [];

      tokens.forEach((t) => {
        if (t) {
          let tokenUrl = '';
          if (this.canisterId == 'e4cao-6iaaa-aaaab-qah2q-cai') {
            tokenUrl = this.get_encoded_token_url(t);
          } else {
            tokenUrl = this.get_token_url(t);
          }
          const nft = new NFT({
            tokenUrl: tokenUrl,
            tokenDisplayUrl: tokenUrl,
            cid: this.canisterId,
            tokenIndex: t,
            tokenHash: '',
          });
          results.push(nft);
        }
      });
      return results;
    }
    return [];
  }
}
