import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { UpdateUserProfileIcon } from 'dfx/edge/edge.did';

export const useSetUserPFPMutation = () => {
  return useMutation({
    mutationKey: ['set-user-pfp-mutation'],
    mutationFn: (payload?: UpdateUserProfileIcon) =>
      dscvrIcApi.userSettings.setProfilePicture(payload),
  });
};
