<script setup lang="ts">
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useBreakpoint } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import { dscvrApi } from '@/shared/api';
  import { useLootbox } from '../model/composables/use-lootbox';

  defineProps<{
    unclaimedItems: dscvrApi.lootbox.ILootboxItem[];
  }>();

  const { isSmallerThanMd } = useBreakpoint();
  const { openClaimItemsDialog } = useLootbox();
</script>

<template>
  <div
    v-if="unclaimedItems.length"
    class="flex items-center gap-4 md:gap-6 bg-gray-950 border border-[#B39FFF] border-opacity-50 p-4 rounded-xl"
  >
    <div class="flex justify-center items-center md:px-5">
      <img
        v-lazy="
          fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/lootbox/diamond-pent-badge.gif`,
          )
        "
        class="size-12 md:size-18 min-w-12 md:min-w-18"
      />
    </div>
    <div
      class="flex-1 flex flex-col items-start lg:flex-row lg:items-center gap-1.5 md:gap-4"
    >
      <b>
        {{
          isSmallerThanMd
            ? $t('lootbox.claimTitle.sm')
            : $t('lootbox.claimTitle.md')
        }}
      </b>
      <span>
        {{
          isSmallerThanMd
            ? $t('lootbox.claimSubtitle.sm')
            : $t('lootbox.claimSubtitle.md')
        }}
      </span>
    </div>
    <base-button
      variant="primary"
      size="small"
      @click="openClaimItemsDialog(unclaimedItems)"
    >
      {{
        isSmallerThanMd
          ? $t('lootbox.claimYourLoot.sm')
          : $t('lootbox.claimYourLoot.md')
      }}
    </base-button>
  </div>
</template>
