<script setup lang="ts">
  import { ref, toRefs, computed } from 'vue';
  import type { HorizontalSlideProps } from './types';
  import { useHorizontalSlide } from './composables';

  const props = withDefaults(defineProps<HorizontalSlideProps>(), {
    heightClass: 'min-h-60',
    itemWidthClass: 'squared',
    centered: false,
  });
  const { count } = toRefs(props);

  const slide = ref<HTMLElement | null>(null);
  const scrollableArea = ref<HTMLElement | null>(null);
  const itemsContainer = ref<HTMLElement | null>(null);
  const {
    touchFocus,
    scrollable,
    displayIndex,
    showLeftArrow,
    showRightArrow,
    sidePadding,
    moveLeft,
    moveRight,
  } = useHorizontalSlide(slide, scrollableArea, itemsContainer, count);

  const onlyOne = computed(() => count.value <= 1);
</script>

<template>
  <div
    ref="slide"
    class="relative group/slide flex justify-center"
    :class="heightClass"
  >
    <div v-if="onlyOne" class="slide-item" :class="itemWidthClass">
      <slot name="item-1" />
    </div>
    <template v-else>
      <div
        ref="scrollableArea"
        class="absolute w-full h-full top-0 left-0 slide-scroll scroll-smooth no-scrollbar z-1"
        :class="{
          'touch-focus': touchFocus,
          'scroll-snap-center': centered,
        }"
      >
        <div
          ref="itemsContainer"
          class="h-full inline-flex gap-4 md:gap-6"
          :style="{
            paddingLeft: centered ? `${sidePadding}px` : undefined,
            paddingRight: centered ? `${sidePadding}px` : undefined,
          }"
        >
          <div
            v-for="index in count"
            :key="index"
            class="h-full slide-item"
            :class="itemWidthClass"
          >
            <slot :name="`item-${index}`" />
          </div>
        </div>
      </div>

      <div
        v-if="scrollable"
        class="absolute top-4 right-4 slide-count group-hover/slide:opacity-100 z-2"
        :class="touchFocus ? 'opacity-100' : 'opacity-0'"
      >
        {{ displayIndex }}/{{ count }}
      </div>

      <div class="slide-side-area justify-start left-0 relative z-2">
        <div
          class="slide-side-arrow"
          :class="{
            '!opacity-100': showLeftArrow,
          }"
          @click.stop.prevent="moveLeft"
        >
          <base-icon
            name="chevron-left"
            class="text-white z-30"
            size="w-10 h-10"
          />
        </div>
      </div>
      <div class="slide-side-area justify-end right-0 relative z-2">
        <div
          class="slide-side-arrow"
          :class="{
            '!opacity-100': showRightArrow,
          }"
          @click.stop.prevent="moveRight"
        >
          <base-icon
            name="chevron-right"
            class="text-white z-30"
            size="w-10 h-10"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
  .slide-squared {
    padding-bottom: 100%;
  }

  .slide-item {
    @apply h-full rounded-2xl bg-gray-975 overflow-hidden flex justify-center items-center border border-white border-opacity-10;
    scroll-snap-align: start;
  }

  .slide-count {
    @apply transition-opacity duration-500 bg-black bg-opacity-60 text-white px-2 z-10 rounded-2xl;
  }

  .slide-side-area {
    @apply absolute top-1/2 -translate-y-1/2 h-20 w-20 cursor-pointer flex items-center;
  }

  .slide-side-arrow {
    @apply relative flex items-center justify-center w-14 h-14 opacity-0 mx-2 transition-opacity duration-500 rounded-full overflow-hidden backdrop-blur-lg before:absolute before:w-full before:h-full before:bg-black before:bg-opacity-40 before:z-20;
  }

  .slide-scroll {
    overflow-x: overlay;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-center {
    .slide-item {
      scroll-snap-align: center;
    }
  }

  .squared {
    aspect-ratio: 1;
  }
</style>
