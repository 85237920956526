<script setup lang="ts">
  import type { UserView } from 'dfx/edge/edge.did';
  import { toRef, defineAsyncComponent } from 'vue';
  import { useGetUserRankQuery, useUser } from '@/entities/user';
  import { useDialog } from '@/shared/model';
  import { AdminToolbar } from '@/shared/ui/admin-toolbar';
  import type { BanFormFields, TokenGiftFormFields } from '../types';
  import { useConfirmationDialog } from '@/shared/ui/confirmation';

  const props = defineProps<{
    user: UserView;
  }>();

  const user = toRef(props, 'user');

  const { onBan, onUnban, onSetUserRank, onTokenGift } = useUser();
  const { data: userRank } = useGetUserRankQuery(user);
  const { openConfiguredDialog, dialogDefaults, closeDialog } = useDialog();
  const { openConfirmationDialog } = useConfirmationDialog();

  const openBanDialog = () => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import('@/features/user/components/dialogs/BanDialogContent.vue'),
        ),
        props: {
          user,
        },
        emits: {
          onBan: (values: BanFormFields) => {
            onBan(user.value, values);
            closeDialog();
          },
        },
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/TitleHeader.vue'),
        ),
        props: {
          title: 'Ban user',
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-96 pb-5`,
      },
    });
  };

  const openUnbanDialog = () => {
    openConfirmationDialog({
      contentSlots: {
        default: defineAsyncComponent(
          () =>
            import('@/features/user/components/dialogs/UnbanDialogContent.vue'),
        ),
        submitLabel: {
          template: 'Unban',
        },
        cancelLabel: {
          template: 'Close',
        },
      },
      contentProps: {
        user,
      },
      headerTitle: 'Unban user',
      submit: () => {
        onUnban(user.value);
        closeDialog();
      },
    });
  };

  const openTokenMintDialog = () => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import(
              '@/features/user/components/dialogs/TokenGiftDialogContent.vue'
            ),
        ),
        props: {
          user,
        },
        emits: {
          onGift: (values: TokenGiftFormFields) => {
            onTokenGift(user.value, values);
            closeDialog();
          },
        },
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/TitleHeader.vue'),
        ),
        props: {
          title: 'Token gift',
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-96 pb-5`,
      },
    });
  };

  const setRank = (amount: bigint) => {
    onSetUserRank(user.value, amount);
  };
</script>

<template>
  <admin-toolbar
    @💣="setRank(BigInt(0))"
    @☯️="setRank(BigInt(1))"
    @🚀="setRank(BigInt(101))"
  >
    R: {{ userRank ?? 'N/A' }}
    <template #action-buttons>
      <base-button variant="secondary" size="small" @click="openBanDialog">
        BAN
      </base-button>
      <base-button variant="secondary" size="small" @click="openUnbanDialog">
        UNBAN
      </base-button>
      <base-button
        variant="secondary"
        size="small"
        @click="openTokenMintDialog"
      >
        TOKEN GIFT
      </base-button>
    </template>
  </admin-toolbar>
</template>
