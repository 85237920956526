import { defineAsyncComponent } from 'vue';
import { useDialog } from '@/shared/model';
import AddWalletContent from '../../components/dialogs/AddWalletContent.vue';

export const usePairDialog = () => {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openAddWalletDialog = () => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: AddWalletContent,
          emits: {
            added: () => {
              resolve(true);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg select-none`,
          showCloseButton: false,
        },
        drawer: {
          showHeader: false,
          customClasses: `${dialogDefaults.drawer.customClasses} px-6 pt-8 pb-6 select-none`,
        },
        emit: () => resolve(false),
      });
    });
  };

  return {
    closeDialog,
    openAddWalletDialog,
  };
};
