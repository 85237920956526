<script setup lang="ts">
  import { computed, onMounted, ref, toRef, onUnmounted } from 'vue';
  import { IFRAME_SANDBOX_RESTRICTIONS } from '@/common';
  import { isVideoUrl, getIframelySrc } from '@/utils';
  import { useRegisterPlayback } from '../../../model/composables/use-register-playback';
  import { extractYoutubeIdFromUrl } from '@/shared/lib/strings';
  import { uniqueId } from 'lodash-es';
  import { EmbeddedLiteYoutube } from '@/components/tiptap';

  const props = defineProps<{
    src: string;
  }>();

  const emit = defineEmits<{
    (e: 'loaded'): void;
  }>();

  const sandboxRestrictions = IFRAME_SANDBOX_RESTRICTIONS;
  const id = uniqueId();
  const src = toRef(props, 'src');
  const iframeRef = ref<HTMLIFrameElement | null>(null);

  const youtubeVideoId = computed(() => extractYoutubeIdFromUrl(src.value));

  const { setMediaPlayback, unsetMediaPlayback } = useRegisterPlayback();

  const iframeClass = computed(() => {
    return getIframeWrapperClass(props.src);
  });

  const getDomainClass = (src: string): string => {
    if (src.match(/^https:\/\/(www\.)?bandcamp\.com/)) {
      return 'media-bandcamp';
    }

    if (src.match(/^https:\/\/(www\.)?tiktok\.com/)) {
      return 'media-tiktok';
    }

    if (src.match(/^https:\/\/platform\.twitter\.com\/embed-holdback-prod/)) {
      return 'media-platform-twitter';
    }

    if (isVideoUrl(src)) {
      return 'media-video-player';
    }

    return '';
  };

  const getIframeWrapperClass = (src: string): string => {
    const iframelySrc = getIframelySrc(src);
    if (iframelySrc) {
      return getDomainClass(iframelySrc);
    }

    return getDomainClass(src);
  };

  onMounted(() => {
    if (iframeRef.value) {
      setMediaPlayback(id, {
        element: iframeRef.value,
        pause: () => {
          if (iframeRef.value) {
            iframeRef.value.src = iframeRef.value.src;
          }
        },
      });
    }
  });

  onUnmounted(() => {
    unsetMediaPlayback(id);
  });
</script>

<template>
  <div class="iframe-container" :class="iframeClass">
    <embedded-lite-youtube v-if="youtubeVideoId" :src="src" />
    <iframe
      v-else
      ref="iframeRef"
      :sandbox="sandboxRestrictions"
      :src="src"
      allowfullscreen
      @load="$emit('loaded')"
      @error="$emit('loaded')"
    />
  </div>
</template>

<style scoped>
  .media-bandcamp iframe {
    max-width: 700px;
  }

  .media-tiktok iframe {
    max-height: 738px;
  }

  .media-platform-twitter iframe {
    max-width: 550px;
  }
</style>
