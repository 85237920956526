<script lang="ts" setup>
  import { computed } from 'vue';
  import FeaturedPortal from './FeaturedPortal.vue';
  import { SEARCH } from '@/common';
  import { CreatePortalButton } from '@/widgets/create-portal-button';
  import { useSearchPortalsQuery } from '@/entities/portal';
  import { uniqBy } from 'lodash-es';
  import { useDocumentScroll } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';
  import { useRouter, useRoute } from 'vue-router';
  import type { PortalSearchQuery } from 'dfx/edge/edge.did';

  const props = defineProps<{
    hideHeader?: boolean;
    shownCount?: number;
    search?: string;
  }>();

  const router = useRouter();
  const route = useRoute();

  const queryParams = computed<PortalSearchQuery>(() => ({
    sort_by: [{ Name: null }],
    page_size: 12n,
    page: 0n,
    query: props.search ?? '',
    chain_filter: [],
    sort_direction: [{ Ascending: null }],
  }));

  const {
    data: searchPortals,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useSearchPortalsQuery(queryParams);

  const portals = computed(() => {
    const pages = searchPortals.value?.pages ?? [];
    const items = pages.flatMap((i) => i?.items ?? []);
    const portalList = uniqBy(items, 'id');
    return props.hideHeader
      ? portalList
      : portalList.slice(0, props.shownCount);
  });

  useDocumentScroll({
    bottomReached: () => {
      if (props.hideHeader && hasNextPage.value && !isFetchingNextPage.value) {
        fetchNextPage();
      }
    },
  });

  const setActiveTab = () => {
    const query = JSON.parse(JSON.stringify(route.query));
    query.tab = 'Portals';
    router.push({
      name: SEARCH,
      query: query,
    });
  };
</script>

<template>
  <div
    class="text-white"
    :class="{
      'mt-5': !portals?.length,
    }"
  >
    <div v-if="!hideHeader" class="flex items-center mx-4 mb-4 md:mx-0">
      <div class="text-lg font-bold">Portals</div>
      <div
        class="flex items-center justify-center mt-0.5 ml-2 text-sm text-indigo-300 cursor-pointer"
        @click="setActiveTab"
      >
        <span>View More</span>
        <base-icon name="chevron-right" size="size-4" />
      </div>
    </div>
    <div
      class="relative w-full p-4 mb-4 text-white bg-gray-950 shadow-lg md:rounded-xl"
    >
      <div
        v-if="isLoading && !portals?.length"
        class="flex items-center justify-center h-56 space-x-4 no-results"
      >
        <div class="relative pt-2 text-center text-white w-14 h-14">
          <div
            class="transform translate-x-1.5 translate-y-6 spinner-big"
          ></div>
        </div>
      </div>
      <div
        v-else-if="portals == null || portals.length == 0"
        class="flex items-center justify-center space-x-4 min-h-56"
      >
        <div class="flex flex-col items-center justify-center no-results">
          <div class="text-xl text-gray-300">
            <a
              href="https://www.youtube.com/watch?v=DtFhreFhSfU"
              target="_blank"
            >
              Oops </a
            >, no portals found
          </div>
          <div class="flex flex-col items-center mt-3 text-base text-gray-400">
            <div class="min-w-max">Try searching for something else, or</div>
            <div>create the portal yourself!</div>
            <create-portal-button
              variant="primary"
              size="small"
              rounded="rounded-lg"
              additional-classes="mt-3 px-5 py-2.5"
            >
              <base-icon name="outlined-plus" size="size-4" />
              <span class="text-white font-medium">
                {{ $t('createPortal') }}
              </span>
            </create-portal-button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <featured-portal
          v-for="portalView in portals"
          :key="portalView.id.toString()"
          :portal-view="portalView"
        />
      </div>
      <div v-if="isFetchingNextPage" class="relative h-10 p-10">
        <loader variant="rainbow" size="size-10" border-width="border" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(20px);
  }
  .no-results {
    min-height: 180px;
  }
</style>
