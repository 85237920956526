<script lang="ts" setup generic="T extends SelectFieldBaseType">
  import { useField } from 'vee-validate';
  import { toRef } from 'vue';
  import type { SelectFormFieldProps } from './types';
  import {
    SelectField,
    FieldWrapper,
    type SelectFieldBaseType,
  } from '@/shared/ui/fields';

  const props = defineProps<SelectFormFieldProps<T>>();
  const { value, handleBlur } = useField(
    toRef(props, 'name'),
    undefined,
    props.options,
  );
</script>

<template>
  <div class="flex flex-col relative w-full bg-transparent border-none p-0">
    <field-wrapper :class="innerClasses">
      <select-field
        v-bind="{ ...$attrs, ...props }"
        v-model="value"
        field-classes="-mr-4"
        @blur="handleBlur"
      >
        <template v-for="(_, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </select-field>
      <base-icon v-if="iconName" :name="iconName" size="w-4 h-4" />
    </field-wrapper>
    <div v-if="error" class="text-sm text-red-500">
      {{ error }}
    </div>
  </div>
</template>
