import CMS from '@/services/cms';
import authService from '../../services/auth';
import portalService from '../../services/portals';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';

// initial state
const state = () => ({
  games: [],
  portals: [],
  users: [],
});

// getters
const getters = {
  games: (state) => {
    return state.games;
  },
  portals: (state) => {
    return state.portals;
  },
  users: (state) => {
    return state.users;
  },
};

// actions
const actions = {
  [ActionTypes.GET_ARCADE_USERS]({ commit }) {
    return CMS.getArcadeUsernames().then((usernames) => {
      const usernamesPromise = usernames.map((username) => {
        return authService.get_user(username);
      });

      return Promise.all(usernamesPromise).then((results) => {
        let users = results.reduce((acc, result) => {
          if (result?.status == 'happy') {
            acc.push(result.result[0]);
          }
          return acc;
        }, []);
        commit(MutationTypes.SET_ARCADE_USERS, users);
        return users;
      });
    });
  },
  [ActionTypes.GET_ARCADE_GAMES]({ commit }) {
    return CMS.getArcadeGames().then((games) => {
      commit(MutationTypes.SET_ARCADE_GAMES, games);
      return games;
    });
  },
  [ActionTypes.GET_ARCADE_PORTALS]({ commit }) {
    return CMS.getArcadePortalSlugs().then((portalSlugs) => {
      return portalService.getPortals(portalSlugs).then((result) => {
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_ARCADE_PORTALS, result.result[0]);
        }
        return result;
      });
    });
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_ARCADE_USERS](state, users) {
    state.users = users;
  },
  [MutationTypes.SET_ARCADE_GAMES](state, games) {
    state.games = games;
  },
  [MutationTypes.SET_ARCADE_PORTALS](state, portals) {
    state.portals = portals;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
