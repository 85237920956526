import { Editor } from '@tiptap/vue-3';
import { POST_FILE_MAX_UPLOAD_SIZE_ERROR_MESSAGE } from '@/common';
import { isIOSMobile } from '@/utils';
import { useFile } from './use-file';
import type { ComputedEmbedType } from './types';
import storage from '@/services/storage';

export function useWysiwyg(editor: Editor) {
  const { validateFile, fileToBase64 } = useFile();

  const isImageFile = (file: Blob) => file.type.startsWith('image');

  const uploadFilesToEditor = (files: FileList) => {
    const view = editor.view;
    const focusIndex = view.state.selection.anchor;
    return Promise.all(
      [...files].map((file) => {
        if (!validateFile(file)) {
          return Promise.reject(POST_FILE_MAX_UPLOAD_SIZE_ERROR_MESSAGE);
        }
        return fileToBase64(file).then((base64) =>
          isImageFile(file) || !isIOSMobile()
            ? { file, src: base64 }
            : storage.uploadBase64(base64).then((src) => ({ file, src })),
        );
      }),
    ).then((converted) => {
      converted.reverse().forEach(({ file, src }) => {
        uploadSingleFileToEditor(file, src || '', focusIndex);
      });
    });
  };

  function uploadSingleFileToEditor(file: Blob, src: string, position: number) {
    const view = editor.view;
    const { schema } = view.state;
    if (isImageFile(file)) {
      const node = schema.nodes.image.create({ src });
      const transaction = view.state.tr.insert(position, node);
      view.dispatch(transaction);
    } else {
      const node = schema.nodes.video.create({ src });
      const transaction = view.state.tr.insert(position, node);
      view.dispatch(transaction);
    }
  }

  function focus() {
    editor.chain().focus().run();
  }

  function blur() {
    editor.chain().blur().run();
  }

  function resetContent(autofocus?: boolean) {
    editor.chain().clearContent().run();
    if (autofocus) {
      editor.chain().focus().run();
    }
  }

  function setPlaceHolder(placeholder: string) {
    const placeHolderExtension = editor.extensionManager.extensions.filter(
      (extension) => extension.name === 'placeholder',
    );
    if (placeHolderExtension.length > 0) {
      placeHolderExtension[0].options['placeholder'] = placeholder;
    }
    editor.view.dispatch(editor.state.tr);
  }

  function insertLink(href: string, text: string = '') {
    let command = editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href });
    if (text) {
      command = command.command(({ tr }) => {
        tr.insertText(text);
        return true;
      });
    }
    command.run();
  }

  function embedContent(type: ComputedEmbedType, id: string) {
    editor
      .chain()
      .focus('end')
      .createParagraphNear()
      .insertContent(
        `<preview data-embed="false" type="${type}" id="${id}"></preview>`,
      )
      .run();
  }

  function embedIframe(link: string, text: string) {
    editor
      .chain()
      .focus()
      .createParagraphNear()
      .insertContent(`<a data-embed="${link}">${text}</a>`)
      .run();
  }

  function getSelectedText(): string {
    const { view, state } = editor;
    if (view.state.selection.empty) {
      return '';
    }
    const { from, to } = view.state.selection;
    const text = state.doc.textBetween(from, to, '');
    return text;
  }

  function clearStyles() {
    editor.chain().focus().clearNodes().run();
    editor.chain().focus().unsetAllMarks().run();
  }

  function pasteImage(url: string) {
    editor.chain().focus().setImage({ src: url }).run();
  }

  function startMention() {
    editor.chain().focus().insertContent('@').run();
  }

  function startCodeBlock() {
    editor.chain().focus().toggleCodeBlock().run();
  }

  function isBoldActive(): boolean | undefined {
    return editor.isActive('bold');
  }

  function toggleBold() {
    return editor.chain().focus().toggleBold().run();
  }

  function isItalicActive(): boolean | undefined {
    return editor.isActive('italic');
  }

  function toggleItalic() {
    return editor.chain().focus().toggleItalic().run();
  }

  function isUnderlineActive(): boolean | undefined {
    return editor.isActive('underline');
  }

  function toggleUnderline() {
    return editor.chain().focus().toggleUnderline().run();
  }

  function isHeadingActive(): boolean | undefined {
    return editor.isActive('heading', { level: 1 });
  }

  function toggleHeading() {
    return editor.chain().focus().toggleHeading({ level: 1 }).run();
  }

  function isParagraphActive(): boolean | undefined {
    return editor.isActive('paragraph');
  }

  function toggleParagraph() {
    return editor.chain().focus().setParagraph().run();
  }

  function isBulletListActive(): boolean | undefined {
    return editor.isActive('bulletList');
  }

  function toggleBulletList() {
    return editor.chain().focus().toggleBulletList().run();
  }

  function isOrderedListActive(): boolean | undefined {
    return editor.isActive('orderedList');
  }

  function toggleOrderedList() {
    return editor.chain().focus().toggleOrderedList().run();
  }

  return {
    uploadFilesToEditor,
    focus,
    blur,
    resetContent,
    setPlaceHolder,
    insertLink,
    embedContent,
    embedIframe,
    getSelectedText,
    clearStyles,
    pasteImage,
    startMention,
    startCodeBlock,
    isBoldActive,
    toggleBold,
    isItalicActive,
    toggleItalic,
    isUnderlineActive,
    toggleUnderline,
    isHeadingActive,
    toggleHeading,
    isParagraphActive,
    toggleParagraph,
    isBulletListActive,
    toggleBulletList,
    isOrderedListActive,
    toggleOrderedList,
  };
}
