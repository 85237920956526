import { computed, type Ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import type { ContentView, ContentViewPage } from 'dfx/edge/edge.did';
import { ActionTypes } from '@/store/action-types';
import {
  POST_DETAIL,
  POST_DETAIL_COMMENT,
  POST_DETAIL_COMMENT_NO_TITLE,
  POST_DETAIL_ID_ONLY,
} from '@/common';
import { trackEvent } from '@/utils/tracker';
import { resolvePostDetailRoute } from '../../lib/resolve-post-detail-route';

export function usePost(content?: Ref<ContentView>) {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const revealedNSFWContent = computed<bigint[]>(() => {
    return store.getters['posts/revealedNSFWContent'];
  });

  const isLoadingContent = computed<boolean>(
    () => store.state.posts.isLoadingContent,
  );

  const currentPost = computed<ContentView>(() => store.getters['posts/post']);

  const pages = computed<Omit<ContentViewPage, 'contents'>>(
    () => store.getters['posts/pages'],
  );

  const revealNSFWContent = (id: bigint) => {
    trackEvent('click', 'reveal_nsfw', id.toString());
    store.dispatch(`posts/${ActionTypes.REVEAL_NSFW_POST}`, id);
  };

  const postId = computed(() => {
    return content?.value.id?.toString() ?? null;
  });

  const isPostDetailPage = computed(
    () =>
      route.name &&
      [
        POST_DETAIL,
        POST_DETAIL_COMMENT,
        POST_DETAIL_COMMENT_NO_TITLE,
        POST_DETAIL_ID_ONLY,
      ].includes(route.name.toString()),
  );

  const postDetailAbsoluteUrl = computed(() => {
    if (!content?.value) return;

    const route = router.resolve(resolvePostDetailRoute(content.value));

    return new URL(route.href, window.location.origin).href;
  });

  const setIsLoading = (isLoading: boolean) => {
    store.commit(`posts/${ActionTypes.SET_IS_LOADING}`, isLoading);
  };

  return {
    isLoadingContent,
    pages,
    postDetailAbsoluteUrl,
    postId,
    currentPost,
    revealedNSFWContent,
    isPostDetailPage,
    revealNSFWContent,
    setIsLoading,
  };
}
