import { Extension } from '@tiptap/core';
import { Plugin, TextSelection } from '@tiptap/pm/state';
import { AddMarkStep } from '@tiptap/pm/transform';

const magicEdenRegex = /^https:\/\/magiceden\.io\/item-details\/(?<id>[^\/]+)/;

export const MagicEden = Extension.create({
  name: 'magicEdenEmbed',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, _, newState) => {
          const lastTransaction = transactions[transactions.length - 1];

          if (
            lastTransaction &&
            lastTransaction.steps.length > 0 &&
            lastTransaction.steps[0] instanceof AddMarkStep &&
            lastTransaction.steps[0].mark.type.name === 'link' &&
            magicEdenRegex.test(lastTransaction.steps[0].mark.attrs.href)
          ) {
            const match = magicEdenRegex.exec(
              lastTransaction.steps[0].mark.attrs.href,
            );
            const { preview, hardBreak } = newState.schema.nodes;
            const node = preview.create({ type: 'nft', id: match?.groups?.id });
            const newTransaction = newState.tr.replaceRangeWith(
              lastTransaction.steps[0].from,
              lastTransaction.steps[0].to,
              node,
            );
            newTransaction
              .setSelection(new TextSelection(newTransaction.selection.$to))
              .insert(newTransaction.selection.$to.pos, hardBreak.create());
            return newTransaction;
          }
          return null;
        },
      }),
    ];
  },
});
