import { computed, reactive, onMounted } from 'vue';
import type { SortField, SortColumnDirection } from '../types';

export function useSorting<T extends string>(
  fields: T[],
  options?: {
    defaultField?: T;
    defaultDirection?: SortColumnDirection;
    onChange?: (field: T, direction: SortColumnDirection) => void;
  },
) {
  const sortFieldsData: Map<T, SortField<T>> = new Map();

  const sortFields = reactive<Map<T, SortField<T>>>(sortFieldsData);

  const activeSortField = computed(() => {
    return [...sortFields.values()].find((sortField) => sortField.active);
  });

  const changeSort = (field: T, direction: SortColumnDirection) => {
    [...sortFields.values()].forEach((sortField) => {
      sortField.active = false;
    });
    const sortField = sortFields.get(field);
    if (sortField) {
      sortField.active = true;
      sortField.direction = direction;
      if (options?.onChange) {
        options.onChange(field, direction);
      }
    }
  };

  onMounted(() => {
    fields.forEach((field) => {
      const sortInfo: SortField<T> =
        options?.defaultField === field
          ? {
              field,
              active: true,
              direction: options?.defaultDirection || 'asc',
            }
          : {
              field,
              active: false,
              direction: 'asc',
            };
      sortFieldsData.set(field, sortInfo);
    });
  });

  return {
    sortFields,
    activeSortField,
    changeSort,
  };
}
