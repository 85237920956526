<script lang="ts" setup>
  import type { AvatarProps } from './types';
  import { computed } from 'vue';

  const props = withDefaults(defineProps<AvatarProps>(), {
    size: 'size-8',
    reverse: false,
  });
  const avatarStyle = computed(() => {
    return ['rounded-full overflow-hidden', props.size];
  });
</script>

<template>
  <div
    class="flex flex-wrap"
    :class="{
      'flex-row-reverse justify-end': reverse,
    }"
  >
    <slot v-bind="props">
      <div v-if="(avatars as string[]).length === 1" :class="[avatarStyle]">
        <img
          :src="(avatars as string[])[0]"
          alt="badge-0"
          class="w-full h-full object-cover"
        />
      </div>
      <div
        v-for="(avatar, index) in avatars"
        v-else
        :key="index"
        class="inline-flex items-center justify-center -ml-0.5 -mr-0.5 relative"
      >
        <div :class="avatarStyle">
          <img
            :src="(avatar as string)"
            :alt="`badge-${index}`"
            class="w-full h-full object-cover"
          />
        </div>
      </div>
    </slot>
  </div>
</template>
