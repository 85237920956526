<script
  lang="ts"
  setup
  generic="T extends UserSelfView | UserView | UserListItemView | AppNotificationPayload"
>
  import { computed, ref, watch } from 'vue';
  import { useElementVisibility } from '@vueuse/core';
  import { useUser, useUserStore } from '@/entities/user';
  import { fetchMedia } from '../../lib';
  import { enrichVideoUrl } from '@/utils';
  import { Loader } from '../../ui/loader';
  import GltfAvatar from './GltfAvatar.vue';
  import type {
    UserSelfView,
    UserView,
    UserListItemView,
    AppNotificationPayload,
  } from 'dfx/edge/edge.did';
  import { mediaApi } from '../../api';
  import { useDevice } from '../../model/composables/use-device';

  const props = withDefaults(
    defineProps<{
      item: T;
      size?: string;
      ringSize?: string;
      avatarWidth?: number;
      rounded?: string;
    }>(),
    {
      size: 'size-44 lg:size-36',
      ringSize: 'ring-2',
      rounded: 'rounded-full',
    },
  );

  const { getAvatarIcon } = useUser();
  const userStore = useUserStore();
  const containerRef = ref<HTMLElement>();
  const videoDataLoaded = ref(false);
  const objectLoadingError = ref(false);
  const imageAvatarRef = ref<HTMLImageElement>();
  const isVisible = useElementVisibility(containerRef);
  const { isMobile } = useDevice();

  const isUploadedImage = computed(() => {
    const imageUrl =
      props.item && 'icon_url' in props.item
        ? props.item.icon_url
        : props.item.notifier_icon_url[0];
    return imageUrl && imageUrl !== '';
  });

  const avatarUrl = computed(() => {
    if (props.item) {
      if ('icon_url' in props.item && props.item.icon_url !== '') {
        return props.item.icon_url;
      }
      if (
        'notifier_icon_url' in props.item &&
        props.item.notifier_icon_url[0]
      ) {
        return props.item.notifier_icon_url[0];
      }
      // NFT UserView or UserSelfView
      if (
        'display_nft' in props.item &&
        props.item.display_nft.length &&
        'meta' in props.item.display_nft[0]
      ) {
        return props.item.display_nft[0].meta;
      }
      // Notification
      if ('notifier_nft' in props.item && props.item.notifier_nft.length) {
        return props.item.notifier_nft[0].meta;
      }
    }
    return '';
  });

  const isOwner = computed(() => {
    return (
      ('display_nft' in props.item &&
        props.item.display_nft.length &&
        'is_owner' in props.item.display_nft[0] &&
        props.item.display_nft[0].is_owner) ??
      false
    );
  });

  const ringStyle = computed(() => {
    if (isOwner.value) {
      return `${props.ringSize} ring-yellow-400`;
    }
    return null;
  });

  const username = computed(() => {
    if ('username' in props.item) {
      return props.item.username;
    } else if ('notifier' in props.item && props.item.notifier.length) {
      return props.item.notifier[0];
    }
    return '';
  });

  const fetchAvatarLinkPreview = async () => {
    if (
      userStore.userUrlAvatarStateHash.has(avatarUrl.value) ||
      userStore.userDefaultAvatarHash.has(username.value)
    ) {
      return;
    }

    if (!avatarUrl.value) {
      userStore.userDefaultAvatarHash.set(
        username.value,
        getAvatarIcon(username.value),
      );
      return;
    }

    if (isUploadedImage.value) {
      userStore.userUrlAvatarStateHash.set(avatarUrl.value, {
        loading: false,
        preview: avatarUrl.value,
      });
      return;
    }

    userStore.userUrlAvatarStateHash.set(avatarUrl.value, {
      loading: true,
      preview: avatarUrl.value,
    });

    const result = await mediaApi.getLinkPreview(avatarUrl.value);
    if (result) {
      userStore.userUrlAvatarStateHash.set(avatarUrl.value, {
        loading: false,
        preview: result,
      });
    } else {
      userStore.userUrlAvatarStateHash.set(avatarUrl.value, {
        loading: false,
        preview: getAvatarIcon(username.value),
      });
    }
  };

  const linkPreview = computed(() => {
    const urlState = userStore.userUrlAvatarStateHash.get(avatarUrl.value);
    if (urlState) {
      return urlState.loading ? null : urlState.preview;
    }
    const defaultAvatar = userStore.userDefaultAvatarHash.get(username.value);
    if (defaultAvatar) {
      return defaultAvatar;
    }
    return null;
  });

  const isLoading = computed(() => {
    if (!avatarUrl.value) {
      return false;
    }
    const urlState = userStore.userUrlAvatarStateHash.get(avatarUrl.value);
    if (!urlState) {
      return true;
    }
    return urlState.loading;
  });

  const getAvatarImage = (url: string) => {
    if (imageAvatarRef.value) {
      const width = imageAvatarRef.value.offsetWidth || 48;
      const height = imageAvatarRef.value.offsetHeight || 48;
      return fetchMedia(url, { fit: 'scale-down', quality: 75, width, height });
    }
    return fetchMedia(url, { fit: 'scale-down', quality: 75, width: 48 });
  };

  watch(
    avatarUrl,
    (value) => {
      if (value) {
        userStore.userDefaultAvatarHash.delete(username.value);
      }
      fetchAvatarLinkPreview();
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <div
    ref="containerRef"
    class="relative flex-none overflow-hidden bg-gray-950"
    :class="[size, ringStyle, rounded]"
  >
    <template v-if="isLoading">
      <loader
        variant="custom"
        :size="size"
        border-width="border-0"
        custom-classes="bg-gray-700"
        animation="animate-pulse"
      />
    </template>
    <template v-else-if="linkPreview !== null">
      <template
        v-if="
          typeof linkPreview === 'object' &&
          ['image', 'website', 'video'].includes(linkPreview.mediaType)
        "
      >
        <template
          v-if="
            linkPreview.mediaType === 'image' &&
            linkPreview.contentType !== 'image/svg+xml'
          "
        >
          <img
            ref="imageAvatarRef"
            v-lazy="{
              src: getAvatarImage(linkPreview.url),
              error: getAvatarIcon(username),
            }"
            :class="size"
            alt="Avatar"
          />
        </template>
        <template v-else-if="linkPreview.contentType === 'image/svg+xml'">
          <img
            ref="imageAvatarRef"
            v-lazy="{
              src: getAvatarImage(linkPreview.url),
            }"
            :type="linkPreview.contentType"
            :class="size"
          />
        </template>
        <template
          v-else-if="
            linkPreview.mediaType === 'website' &&
            linkPreview.images &&
            linkPreview.images.length > 0
          "
        >
          <img
            ref="imageAvatarRef"
            :class="size"
            v-lazy="{
              src: getAvatarImage(linkPreview.images[0]),
            }"
          />
        </template>
        <template v-else-if="linkPreview.mediaType === 'video'">
          <!--
            We disable autoplay on mobile to save bandwidth and enrich the
            video URL so that a thumbnail is shown for the video.
          -->
          <video
            v-if="isVisible || videoDataLoaded"
            muted
            loop
            :autoplay="!isMobile"
            controlsList="nodownload nofullscreen noremoteplayback"
            playsinline
            @loadeddata="videoDataLoaded = true"
          >
            <source :src="enrichVideoUrl(fetchMedia(linkPreview.url))" />
          </video>
        </template>
        <template
          v-else-if="
            linkPreview.mediaType === 'website' &&
            linkPreview.url.endsWith('glb')
          "
        >
          <gltf-avatar :glb="linkPreview.url" />
        </template>
        <template v-else>
          <object
            v-if="!objectLoadingError"
            :data="linkPreview.url"
            :type="linkPreview.contentType"
            :class="size"
            @error="objectLoadingError = true"
          />
          <img
            v-else
            v-lazy="getAvatarIcon(username)"
            :class="size"
            alt="Avatar"
          />
        </template>
      </template>
      <img
        v-else-if="typeof linkPreview === 'string'"
        ref="imageAvatarRef"
        v-lazy="{
          src: getAvatarImage(linkPreview),
          error: getAvatarIcon(username),
        }"
        :class="size"
        alt="Avatar"
      />
      <img
        v-else
        v-lazy="{
          src: getAvatarIcon(username),
        }"
        :class="size"
        alt="Avatar"
      />
    </template>
  </div>
</template>
