import type { AxiosPromise } from 'axios';
import { apiInstance } from '../base';
import type { LinkCallbackBody } from './types';

const BASE_URL = '/oauth2';

export const matricaLinkCallback = (
  body: LinkCallbackBody,
): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/matrica/callback`, body);
};

export const unlinkMatrica = (): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/matrica/unlink`);
};

export const twitterLinkCallback = (body: LinkCallbackBody): Promise<void> => {
  return apiInstance
    .post(`${BASE_URL}/twitter/callback`, body)
    .then((response) => {
      return response.data;
    });
};

export const unlinkTwitter = (): AxiosPromise<void> => {
  return apiInstance.delete(`${BASE_URL}/twitter/unlink`);
};
