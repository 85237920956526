// Lootboxes
export enum LootboxOpenStatus {
  READY = 1,
  NO_LOOTBOXES = 2,
  NO_WALLET = 3,
  ALREADY_OPENED = 4,
  OPENED = 5,
  NO_LOOTBOX = 6,
  TARGETED = 7,
}

export enum LootboxItemClaimStatus {
  SENT = 1,
  ERROR = 2,
  CLAIMED = 3,
  ALREADY_CLAIMED = 4,
  NO_WALLLET = 5,
}

export enum LootboxItemType {
  SOL_NFT = 1,
  SOL_SPL = 2,
  DSCVR_POINTS = 3,
  ICRC = 4,
  IC_EXT_NFT = 5,
  SOL_NFT_MINTABLE = 6,
  SOL_TARGETED_NFT = 7,
}

export interface ILootboxItemClaim {
  item?: ILootboxItem;
  status: LootboxItemClaimStatus;
  error?: string;
}

export interface ILootboxItem {
  tokenType: LootboxItemType;
  id: string;
  mintAddress: string;
  token: ILookboxItemToken;
  isClaimed: boolean;
  lootboxId: string;
  amount: bigint;
  campaign?: ILootboxItemCampaign;
  openedAt?: string;
  isOpened: boolean;
}

export interface ILootboxItemCampaign {
  id: string;
  text: string;
  meta: string;
}

export interface ILookboxItemToken {
  image: string;
  name: string;
  decimals: number;
  symbol: string;
  rarity?: string;
}

export interface ILootbox {
  id: string;
  items: ILootboxItem[];
}

export interface ILootboxOpen {
  lootbox?: ILootbox;
  status: LootboxOpenStatus;
}

export type LootboxAvailable = {
  id?: string;
  target?: LootboxTargetResult;
  status: LootboxOpenStatus;
};

export type LootboxTarget = {
  id: string;
  expiresAt: Date;
  maxMints: number;
  currentMints: number;
};

export type LootboxTargetResult = {
  result: boolean;
  data: Target;
};

export type Target = {
  id: string;
  expiresAt: Date;
  version: string;
  rules: TargetRule[];
  paths: string[];
  domain?: string;
};

export type TargetRule = {
  type: TargetType;
  target: string;
  roles?: string[];
};

export type TargetType = 'user-followers' | 'portal-roles' | 'portal-members';
