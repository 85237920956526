<script lang="ts" setup>
  import { onBeforeMount, toRefs } from 'vue';
  import { refDebounced } from '@vueuse/core';
  import { TextField, FieldWrapper } from '@/shared/ui/fields';
  import type { FrameActionPayload } from '../types';
  import { useEmbeddedFrame } from '../model/composables/use-embedded-frame';
  import { ReportSummary } from '@/entities/wallet-transaction';

  const props = defineProps<{
    url: string;
    isDebug?: boolean;
    inCarousel?: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'action', payload: FrameActionPayload): void;
  }>();

  const { url, isDebug, inCarousel } = toRefs(props);

  const {
    imageUrl,
    hasActions,
    numberOfGridColumns,
    buttonSize,
    buttonIconSize,
    errorLoadingImage,
    imgWrapperClass,
    embeddedFrame,
    framesCount,
    isFrameLoading,
    imgContainerHeightPx,
    inputTextFieldValue,
    reportCount,
    transactedCount,
    getButtonClass,
    hasButtonRightIcon,
    getButtonRightIcon,
    onClick,
    onClickImage,
    introduceFrame,
    imageLoaded,
  } = useEmbeddedFrame(
    url,
    isDebug,
    inCarousel,
    (payload: FrameActionPayload) => emit('action', payload),
  );

  const isFrameLoadingDebounced = refDebounced(isFrameLoading, 500);

  onBeforeMount(introduceFrame);
</script>

<template>
  <div
    class="flex flex-col gap-2"
    :class="{
      'size-full': inCarousel,
      'pointer-events-none': isFrameLoading,
    }"
  >
    <report-summary
      v-if="!inCarousel && (reportCount || transactedCount)"
      :report-count="reportCount"
      :transacted-count="transactedCount"
    />
    <div
      class="not-prose bg-gray-990 border border-gray-785 border-opacity-50 rounded-xl overflow-hidden w-full relative before:block before:pointer-events-none before:absolute before:bg-black before:bg-opacity-40 before:inset-1 before:z-5 before:backdrop-blur-[2px] before:opacity-0 before:transition-all"
      :class="{
        'size-full': inCarousel,
        'before:opacity-100': isFrameLoadingDebounced,
      }"
    >
      <div
        class="flex items-center justify-center w-full h-full rounded-xl overflow-hidden z-1 p-1"
      >
        <div
          v-if="isFrameLoadingDebounced"
          class="absolute animate-spin animate-duration-[3000ms] size-[250%] conic-gradient"
        />
        <div
          class="relative rounded-xl overflow-hidden bg-gray-990 w-full h-full z-2"
        >
          <transition mode="out-in">
            <div
              :key="framesCount"
              class="flex flex-col"
              :class="[
                inCarousel
                  ? 'h-full gap-2 p-3'
                  : 'gap-12 md:gap-4 my-1 p-5 min-h-80',
              ]"
            >
              <base-button
                variant="link"
                target="_link"
                rounded="rounded-none"
                :additional-classes="imgWrapperClass"
                :style="{
                  height: imgContainerHeightPx,
                }"
                @click.stop="onClickImage"
              >
                <img
                  v-if="!errorLoadingImage"
                  :key="imageUrl"
                  :src="imageUrl"
                  :class="{
                    'max-h-full object-contain': inCarousel,
                  }"
                  @load="imageLoaded"
                />
                <div
                  v-else
                  class="error w-full h-full flex flex-col items-center justify-center gap-2 text-slade-500 text-white text-opacity-70 p-4 md:p-7"
                >
                  <div class="flex-1 min-h-0 w-full">
                    <base-icon
                      name="error-bot"
                      class="opacity-60"
                      size="w-full h-full"
                    />
                  </div>
                  <span
                    class="relative"
                    :class="inCarousel ? 'text-sm lg:text-xl' : 'text-xl'"
                  >
                    {{ $t('errorLoadingImage') }}
                  </span>
                </div>
              </base-button>
              <div
                v-if="embeddedFrame && hasActions"
                class="flex flex-col"
                :class="inCarousel ? 'gap-2' : 'gap-6'"
              >
                <field-wrapper
                  v-if="embeddedFrame.inputText"
                  :padding-class="inCarousel ? 'px-3 py-2' : undefined"
                >
                  <text-field
                    name="frame-input-text"
                    :value="embeddedFrame.inputText.value"
                    :placeholder="embeddedFrame.inputText.placeholder ?? ''"
                    :custom-field-classes="
                      inCarousel ? 'caret-white text-xs' : 'caret-white'
                    "
                    @update:model-value="inputTextFieldValue = $event"
                  />
                </field-wrapper>
                <div
                  class="grid gap-2 place-content-center"
                  :class="numberOfGridColumns"
                >
                  <base-button
                    v-for="button in embeddedFrame.buttons"
                    :key="button.index"
                    variant="secondary"
                    type="button"
                    :size="buttonSize"
                    :additional-classes="getButtonClass(button)"
                    rounded="rounded-2.5xl"
                    @click.stop="onClick(button)"
                  >
                    <span :class="inCarousel ? 'truncate' : ''">
                      {{ button.label }}
                    </span>
                    <base-icon
                      v-if="hasButtonRightIcon(button)"
                      :name="getButtonRightIcon(button)"
                      :size="buttonIconSize"
                      class="flex-none"
                    />
                  </base-button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .editor-input .ProseMirror img {
    @apply max-h-full w-full;
    margin: 0 !important;
  }

  .error {
    background: linear-gradient(
      161.15deg,
      rgba(103, 20, 204, 0.2) 12.73%,
      rgba(46, 104, 245, 0.2) 72.95%
    );
  }

  .conic-gradient {
    background-image: conic-gradient(#3b82f6 20deg, transparent 120deg);
  }
</style>
