import type { LinkPreview } from '@/entities/user';
import { config } from '@/shared/lib';
import axios from 'axios';

export const getLinkPreview = async (url: string) => {
  try {
    const urlObject = new URL('/meta', config.MEDIA_WORKER_URL);
    urlObject.searchParams.append('media', url);
    const { data } = await axios.get<LinkPreview>(urlObject.toString());
    return data;
  } catch (error) {
    console.error('Error fetching link preview:', error);
    return null;
  }
};
