import { dscvrApi } from '@/shared/api';
import { usePkce } from '@/shared/model';

export const useLinkMatricaWalletMutation = async (code: string) => {
  const { getLocalPkce } = usePkce();
  const pkce = await getLocalPkce();
  if (!pkce) throw new Error('PKCE not found');
  return dscvrApi.oauth2.matricaLinkCallback({
    code,
    codeVerifier: pkce,
    redirectUri: `${window.location.origin}/oauth2/matrica/callback`,
  });
};
