<template>
  <div class="absolute inset-0 z-10 w-full h-full">
    <div
      class="absolute inset-0 w-full h-full bg-black opacity-40"
      :class="{ 'rounded-lg': rounded }"
    />

    <circular-loader class="absolute-center" />
  </div>
</template>

<script>
  import CircularLoader from './CircularLoader.vue';

  export default {
    name: 'overlay-loader',
    components: { CircularLoader },
    props: {
      rounded: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped>
  .absolute-center {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
