<script lang="ts" setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import ContentDialog from '../../ui/Content.vue';
  import { wisdomSentences } from '../../lib/wisdom-sentences';
  import type { GimluckRewardCard } from '../../types';
  import ShareButton from '../../ShareButton.vue';

  defineProps<{
    card: GimluckRewardCard;
  }>();

  const { t } = useI18n({
    useScope: 'global',
  });

  const wisdomSentence = computed(() => {
    return wisdomSentences[Math.floor(Math.random() * wisdomSentences.length)];
  });
</script>

<template>
  <content-dialog>
    <template #content>
      <div
        class="flex flex-col justify-center items-center w-full gap-2 p-8 bg-black rounded-xl border border-gray-785 border-opacity-50"
      >
        <div class="font-bold text-2xl text-center tracking-[-0.12px]">
          {{ t('gimluck.dialogs.loser.title') }}
        </div>
        <img
          :src="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/rain.gif`)"
          alt="rainy cloud"
          class="w-[273px] h-auto"
        />
        <div class="text-lg tracking-[0.18px] text-center">
          <i>&quot;</i>{{ wisdomSentence }}<i>&quot;</i>
        </div>
      </div>
    </template>
    <template #conclusion>
      <div class="text-center text-gray-300">
        {{ t('gimluck.dialogs.loser.content') }}
      </div>
    </template>
    <template #button>
      <share-button :reward="card.reward" />
    </template>
  </content-dialog>
</template>
