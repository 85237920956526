<template>
  <div
    v-if="open"
    class="flex flex-col items-center justify-center bg-white/[0.04] border border-[#333B47]/[0.5] rounded-xl mb-3"
  >
    <NewTabbedContainer
      :inline-close="true"
      :tab-list="['Upload', 'Embed']"
      @close="$emit('close')"
    >
      <template #tabPanel-0>
        <upload-area @files-uploaded="uploadFiles($event)" />
      </template>
      <template #tabPanel-1>
        <div class="flex flex-col items-center w-full px-4 py-8">
          <input
            ref="imageUrl"
            type="url"
            class="w-full py-3 px-4 bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md outline-none focus:outline-none focus:ring-0"
            placeholder="Paste the image link"
            @input="() => (invalidImageText = '')"
            @keyup.enter="pasteImage"
          />
          <span class="h-6 pt-1 text-sm text-red-500">
            {{ invalidImageText }}
          </span>
          <DefaultButton
            text="embed image"
            extra-classes="text-sm mt-4"
            @click.prevent.stop="pasteImage"
          />
        </div>
      </template>
    </NewTabbedContainer>
  </div>
</template>

<script>
  import { getAvatarUrl, parseNFTUrl } from '../../utils/avatar';
  import DefaultButton from '../buttons/DefaultButton.vue';
  import NewTabbedContainer from '../tabs/NewTabbedContainer.vue';
  import { UploadArea } from '@/entities/file-upload';

  export default {
    components: {
      NewTabbedContainer,
      UploadArea,
      DefaultButton,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close', 'scrollHeightUpdated', 'paste-image', 'upload-files'],
    data() {
      return {
        invalidImageText: '',
      };
    },
    methods: {
      uploadFiles(files) {
        this.$emit('upload-files', files);
      },
      pasteImage() {
        const pastedImage = this.$refs.imageUrl.value;
        if (this.isImageUrl(pastedImage) || this.isDataImage(pastedImage)) {
          this.$emit('paste-image', pastedImage);
        } else if (
          pastedImage &&
          this.isValidUrl(pastedImage) &&
          parseNFTUrl(pastedImage)
        ) {
          const pastedNftUrl = getAvatarUrl(pastedImage);
          this.$emit('paste-image', pastedNftUrl);
        } else {
          this.invalidImageText = 'Please enter a valid image url';
        }
      },
    },
  };
</script>
