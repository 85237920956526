import { useI18n } from 'vue-i18n';
import { TitleHeader } from '@/shared/ui/base-dialog';
import { useDialog } from '@/shared/model';
import type { IcpFungibleToken, IcpNonFungibleToken } from '../../types';
import FungieTokenTransfer from '../../components/dialogs/FungieTokenTransfer.vue';
import NonFungieTokenTransfer from '../../components/dialogs/NonFungieTokenTransfer.vue';

export const useTokenDialog = () => {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { t } = useI18n({ useScope: 'global' });

  const openFungieTokenTransferDialog = (token: IcpFungibleToken) => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: FungieTokenTransfer,
          props: {
            token,
          },
          emits: {
            success: () => {
              resolve(true);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        header: {
          component: TitleHeader,
          props: {
            title: token.name,
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-md`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
        emit: () => resolve(false),
      });
    });
  };

  const openNonFungieTokenTransferDialog = (token: IcpNonFungibleToken) => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: NonFungieTokenTransfer,
          props: {
            token,
          },
          emits: {
            success: () => {
              resolve(true);
              closeDialog();
            },
            close: closeDialog,
          },
        },
        header: {
          component: TitleHeader,
          props: {
            title: t('tokenTransfer.title'),
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-md`,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
        },
        emit: () => resolve(false),
      });
    });
  };

  return {
    closeDialog,
    openFungieTokenTransferDialog,
    openNonFungieTokenTransferDialog,
  };
};
