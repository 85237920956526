<script lang="ts" setup>
  import CompletionImage from '../completion-image/CompletionImage.vue';
  import ContentDialog from '../../ui/ContentDialog.vue';
  import { useStreak } from '../../model/composables/use-streak';
  import { computed } from 'vue';
  import { pluralize } from '@/shared/lib';
  import { shareImage } from '../../lib/share-image';

  const { activeStreak, onShareStreak } = useStreak();

  const totalPoints = computed(() => {
    const points = activeStreak.value
      ? Number(activeStreak.value.total_points_this_streak)
      : 0;
    return `${points} ${pluralize(points, 'point', 'points')}`;
  });
  const airdropMultiplier = computed(() => {
    const multiplier = activeStreak.value
      ? Number(activeStreak.value.airdrop_multiplier_this_streak)
      : 0;
    return `${multiplier} ${pluralize(
      multiplier,
      'Airdrop Multiplier',
      'Airdrop Multipliers',
    )}`;
  });
  const numberOfDays = computed(() => {
    const startDay = activeStreak.value?.start_day ?? 0;
    return startDay - 1;
  });
</script>

<template>
  <div
    v-if="activeStreak"
    class="relative flex flex-col justify-center gap-8 max-w-[565px] mx-auto"
  >
    <div class="mx-auto h-[308px] w-auto">
      <completion-image :url="shareImage[activeStreak.start_day - 1].url" />
    </div>
    <content-dialog>
      <template #title>
        {{ $t('streak.shareDialog.title.global') }}
        <span class="text-indigo-300">
          {{ totalPoints }}
        </span>
        {{ $t('and') }}
        <span class="text-indigo-300">
          {{ airdropMultiplier }}
        </span>
        {{ $t('streak.duringAStreakOf') }}
        {{ numberOfDays }}
        {{ $t('days') }}
      </template>
      <template #first-row-title>
        {{ $t('streak.shareDialog.lightning.title') }}
      </template>
      <template #first-row-description>
        {{ $t('streak.shareDialog.lightning.description') }}
      </template>
      <template #second-row-title>
        {{ $t('streak.shareDialog.plus.title') }}
      </template>
      <template #second-row-description>
        {{ $t('streak.shareDialog.plus.description') }}
      </template>
      <template #button>
        <base-button variant="primary" size="medium" @click="onShareStreak">
          <base-icon name="share" />
          <span class="font-semibold text-lg">
            {{ $t('streak.shareDialog.shareYourStreak') }}
          </span>
        </base-button>
      </template>
    </content-dialog>
  </div>
</template>
