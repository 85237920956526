import * as bs58 from 'bs58';
import { VersionedTransaction, type Transaction } from '@solana/web3.js';

export const serializeTransaction = (
  transaction: Transaction | VersionedTransaction,
) => {
  if (transaction instanceof VersionedTransaction) {
    return bs58.encode(transaction.serialize());
  }

  return bs58.encode(Uint8Array.from(transaction.serialize()));
};
