<template>
  <div class="inline-block w-24 h-10">
    <div
      class="relative flex flex-row w-full h-10 mt-1 bg-transparent rounded-lg"
    >
      <button
        data-action="decrement"
        class="w-10 h-full text-gray-200 bg-gray-900 rounded-l outline-none cursor-pointer hover:bg-gray-700 focus:outline-none"
        @click="dec"
      >
        <span class="m-auto text-2xl font-thin">-</span>
      </button>
      <div
        class="items-center flex-auto px-1 pt-2 font-semibold text-center text-gray-200 align-middle bg-gray-900 outline-none text-md focus:outline-none"
      >
        {{ value }}
      </div>

      <button
        data-action="increment"
        class="w-10 h-full text-gray-200 bg-gray-900 rounded-r cursor-pointer hover:bg-gray-700 focus:outline-none"
        @click="inc"
      >
        <span class="m-auto text-2xl font-thin">+</span>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'number-increment',
    props: {
      value: {
        type: Number,
        default: 0,
      },
    },
    emits: ['inc', 'dec'],
    methods: {
      inc() {
        this.$emit('inc');
      },
      dec() {
        this.$emit('dec');
      },
    },
  };
</script>
