<script lang="ts" setup>
  import { useSlots } from 'vue';

  const slots = useSlots();
</script>

<template>
  <div class="flex flex-col gap-8 items-center justify-center w-full pt-4 px-8">
    <div
      v-if="slots.title || slots.description"
      class="flex flex-col justify-center items-center gap-4 w-full px-8"
    >
      <span class="text-2xl font-bold tracking-[-0.12px] text-center">
        <slot name="title" />
      </span>
      <span class="font-medium tracking-[0.16px] text-gray-300 text-center">
        <slot name="description" />
      </span>
    </div>
    <slot name="content" />
    <slot v-if="slots.conclusion" name="conclusion" />
    <slot name="button" />
  </div>
</template>
