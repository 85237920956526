<template>
  <section class="content">
    <div
      class="shadow-lg bg-gray-950 text-white post-item sm:rounded-xl max-w-screen-lg p-5 mt-6"
    >
      <div class="text-lg">Settings</div>

      <div class="text-sm font-bold border-b mt-5 text-gray-400">PLATFORMS</div>

      <div class="w-full my-4">
        <div class="grid grid-cols-3 gap-6">
          <label class="inline col-span-2">
            <input
              v-model="controllerId"
              type="text"
              class="mt-1 ring-1 block w-full rounded-md bg-gray-900 border-transparent focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Discord Controller Id"
            />
          </label>

          <div class="inline text-left">
            <button
              type="submit"
              class="btn-submit mt-1 disabled:opacity-50"
              @click="addDiscordController"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div class="text-sm font-bold border-b mt-5 text-gray-400">REGISTRY</div>

      <div class="w-full my-4">
        <div class="grid grid-cols-3 gap-6">
          <label class="inline col-span-2">
            <input
              v-model="registryControllerId"
              type="text"
              class="mt-1 ring-1 block w-full rounded-md bg-gray-900 border-transparent focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Registry Controller Id"
            />
          </label>

          <div class="inline text-left">
            <button
              type="submit"
              class="btn-submit mt-1 disabled:opacity-50"
              @click="addRegistryController"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { Principal } from '@dfinity/principal';
  import { client } from '../utils/dfinity';

  export default {
    name: 'mod-page',
    data() {
      return {
        controllerId: null,
        registryControllerId: null,
      };
    },
    async mounted() {
      const registry = await client.actor.get_registry_controller();

      if (registry.length > 0) {
        this.registryControllerId = registry[0].toText();
      }

      const platforms = await client.actor.get_platform_controllers();

      for (let i = 0; i < platforms.length; i++) {
        const p = platforms[i];

        if ('Discord' in p.kind) {
          this.controllerId = p.controller_id.toText();
        }
      }
    },
    methods: {
      async addDiscordController() {
        console.log(
          await client.actor.add_platform_controller(
            { Discord: null },
            Principal.fromText(this.controllerId),
          ),
        );
      },
      async addRegistryController() {
        console.log(
          await client.actor.set_registry_controller(
            Principal.fromText(this.registryControllerId),
          ),
        );
      },
    },
  };
</script>
