import { useQuery } from '@tanstack/vue-query';
import { getUserByIdKey, isFollowingKey } from './keys';
import { dscvrIcApi } from '@/shared/api';
import { computed, type Ref } from 'vue';
import type { UserView } from 'dfx/edge/edge.did';

export const useIsUserFollowingQuery = (
  user: Ref<UserView>,
  enabled: Ref<boolean>,
) => {
  const userId = computed(() => user.value.id.toText());
  return useQuery({
    queryKey: [...getUserByIdKey(userId), isFollowingKey],
    queryFn: () => dscvrIcApi.user.isUserFollowing(user.value.id),
    enabled,
  });
};
