<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useVModel } from '@vueuse/core';
  import CommentActionsDropDown from '@/components/comments/CommentActionsDropDown.vue';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { PermissionFlags, hasPermission } from '@/shared/lib';
  import { TipNew } from '@/features/post';
  import { useUser, useUserRole } from '@/entities/user';
  import type { CommentViewNode } from '@/shared/model';

  const props = defineProps<{
    item: CommentViewNode;
    canTranslate: boolean;
    showVoteTip: boolean;
    disableVoting: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'edit'): void;
    (e: 'delete'): void;
    (e: 'remove'): void;
    (e: 'translate'): void;
    (e: 'replying'): void;
    (e: 'report'): void;
    (e: 'vote', value: boolean): void;
    (e: 'set-nsfw', value: boolean): void;
    (e: 'update:showVoteTip', value: boolean): void;
    (e: 'goodbye'): void;
  }>();

  const { isAdmin } = useUserRole();
  const hasToken = ref(false);
  const showVoteTipModel = useVModel(props, 'showVoteTip', emit);

  const { currentUser } = useUser();

  const upvoted = computed(() => props.item.is_upvoter);
  const downvoted = computed(() => props.item.is_downvoter);
  const upvoteCount = computed(() => props.item.upvotes - props.item.downvotes);

  const canEdit = computed(
    () =>
      currentUser.value &&
      props.item.owner_id.toText() == currentUser.value.id.toText(),
  );
</script>

<template>
  <div class="inline-flex items-center gap-3 mt-4 text-sm text-gray-400">
    <base-button
      variant="custom"
      custom-classes="flex justify-center items-center px-2 py-1.5 rounded-md text-white overflow-hidden disabled:cursor-not-allowed disabled:opacity-70 disabled:pointer-events-none"
      :class="{
        upvoted: upvoted,
        'bg-red-600': downvoted,
        'bg-gray-700 hover:bg-gray-600': !upvoted && !downvoted,
      }"
      title="Upvote"
      :disabled="disableVoting"
      @click="$emit('vote', true)"
    >
      <div
        class="flex items-center gap-2"
        :class="{
          'upvote-slide': upvoted,
          'downvote-slide': downvoted,
        }"
      >
        <base-icon :name="downvoted ? 'downvote' : 'upvote'" size="w-5 h-5" />
        <span
          :class="{
            'upvote-slide-text': upvoted,
          }"
        >
          {{ upvoteCount }}
        </span>
      </div>
    </base-button>
    <base-button
      v-if="hasPermission(item.perm, PermissionFlags.DISLIKE_CONTENT)"
      variant="custom"
      size="x-small"
      custom-classes="-ml-2 btn-comment-actions disabled:cursor-not-allowed disabled:opacity-70 disabled:pointer-events-none"
      :disabled="disableVoting"
      @click="$emit('vote', false)"
    >
      <base-icon name="downvote" class="downvote-icon" />
    </base-button>
    <base-tooltip :content="$t('postButtonLabelReply')">
      <base-button
        v-if="hasPermission(item.perm, PermissionFlags.CREATE_CONTENT_COMMENT)"
        variant="custom"
        custom-classes="btn-comment-actions group/comment"
        @click="$emit('replying')"
      >
        <base-icon
          name="comment"
          size="w-4 h-4"
          class="fill-gray-800 group-hover/comment:fill-gray-700"
        />
      </base-button>
    </base-tooltip>

    <div class="relative flex">
      <tip-new
        v-if="currentUser"
        :content-id="item.id"
        :user-id="item.owner_id"
        @has-token="hasToken = $event"
      />

      <div
        v-if="showVoteTipModel && hasToken"
        class="absolute bg-indigo-600 w-2 h-2 transform rotate-45 -translate-x-1/2 left-1/2 top-11 rounded-sm z-10"
      />
      <div
        v-if="showVoteTipModel && hasToken"
        class="absolute w-68 text-gray-200 bg-indigo-600 text-center p-3 z-10 transform -translate-x-1/2 left-1/2 top-12 rounded-md"
      >
        <p class="font-medium text-sm leading-4.5">
          Like this post? You can show love with your favorite meme token too!
          Use the rain icon to make it rain!
        </p>
        <button
          class="mt-3 w-32 h-8 text-sm font-medium leading-4.5 bg-white text-black rounded-md"
          @click="showVoteTipModel = false"
        >
          Got it
        </button>
      </div>
    </div>
    <base-tooltip v-if="false" :content="$t('gift')">
      <span class="btn-comment-actions">
        <base-icon name="gift" />
      </span>
    </base-tooltip>
    <base-tooltip :content="$t('translate')">
      <base-button
        v-if="canTranslate"
        variant="custom"
        custom-classes="btn-comment-actions"
        @click="$emit('translate')"
      >
        <base-icon name="translate" />
      </base-button>
    </base-tooltip>

    <base-tooltip content="Goodbye">
      <base-button
        v-if="isAdmin"
        variant="custom"
        custom-classes="btn-comment-actions"
        @click="$emit('goodbye')"
      >
        👋
      </base-button>
    </base-tooltip>

    <comment-actions-drop-down
      v-if="currentUser"
      :is-n-s-f-w="item.is_nsfw"
      :content-id="item.id"
      :permissions="item.perm"
      :can-edit="canEdit"
      :can-delete="canEdit"
      class="mr-3"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
      @remove="$emit('remove')"
      @report="$emit('report')"
      @set-nsfw="$emit('set-nsfw', $event)"
    />
  </div>
</template>
