<script setup lang="ts">
  import { toRef } from 'vue';
  import { useQueryClient } from '@tanstack/vue-query';
  import { fetchMedia } from '@/shared/lib';
  import type { IcpFungibleToken } from '../types';
  import FungibleTokenCard from '../ui/FungibleTokenCard.vue';
  import { useTokenDialog } from '../model/composables/use-token-dialog';
  import { useGetIcpBalanceQuery } from '../api/use-get-icp-balance.query';
  import {
    tokenFungibleQueryKey,
    tokenIcpQueryKey,
    tokenQueryKey,
  } from '../api/keys';

  const props = defineProps<{
    token: IcpFungibleToken;
  }>();

  const queryClient = useQueryClient();
  const { openFungieTokenTransferDialog, closeDialog } = useTokenDialog();
  const { data: icpBalance } = useGetIcpBalanceQuery(toRef(props, 'token'));

  const openTransferDialog = async () => {
    const success = await openFungieTokenTransferDialog(props.token);
    if (success) {
      queryClient.invalidateQueries({
        queryKey: [tokenQueryKey, tokenIcpQueryKey, tokenFungibleQueryKey],
      });
      closeDialog();
    }
  };
</script>

<template>
  <fungible-token-card :chain="token.chain" @action="openTransferDialog">
    <template #icon>
      <img
        v-lazy="{ src: fetchMedia(token.icon) }"
        :alt="token.name"
        :title="token.name"
        class="size-full rounded-full"
      />
    </template>
    <template #name>{{ token.symbol }}</template>
    <template #balance>
      {{ token.toDisplay() }}
      <span class="text-sm font-medium">{{ token.symbol }}</span>
    </template>
    <template #description>{{ token.name }}</template>
    <template v-if="icpBalance" #swapBalance>{{ icpBalance }}</template>
    <template #action>{{ $t('transfer') }}</template>
  </fungible-token-card>
</template>
