<script setup lang="ts">
  import { useWysiwyg } from '@/composables';
  import type { Editor } from '@tiptap/vue-3';

  const props = defineProps<{
    editor: Editor;
  }>();
  const {
    isBoldActive,
    toggleBold,
    isItalicActive,
    toggleItalic,
    isUnderlineActive,
    toggleUnderline,
    isHeadingActive,
    toggleHeading,
    isParagraphActive,
    toggleParagraph,
    isBulletListActive,
    toggleBulletList,
    isOrderedListActive,
    toggleOrderedList,
  } = useWysiwyg(props.editor);
</script>

<template>
  <div
    class="flex items-center border border-gray-785 border-opacity-50 rounded-xl px-2 py-1"
  >
    <base-button
      alt="bold"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isBoldActive() ? 'bg-gray-700 text-white' : 'text-gray-400'"
      @click.stop.prevent="toggleBold()"
    >
      <base-icon name="bold" size="w-4 h-4" class="menu-icon" />
    </base-button>
    <base-button
      alt="italic"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isItalicActive() ? 'text-white bg-gray-700' : 'text-gray-400'"
      @click.stop.prevent="toggleItalic()"
    >
      <base-icon name="italic" size="w-4 h-4" class="menu-icon" />
    </base-button>

    <base-button
      alt="underline"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isUnderlineActive() ? 'text-white bg-gray-700' : 'text-gray-400'"
      @click.stop.prevent="toggleUnderline()"
    >
      <base-icon name="underline" size="w-4 h-4" class="menu-icon" />
    </base-button>
    <base-button
      alt="h1"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isHeadingActive() ? 'text-white bg-gray-700' : 'text-gray-400'"
      @click.stop.prevent="toggleHeading()"
    >
      <base-icon name="h1" size="w-5 h-5" />
    </base-button>
    <base-button
      alt="paragraph"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isParagraphActive() ? 'text-white bg-gray-700' : 'text-gray-400'"
      @click.stop.prevent="toggleParagraph()"
    >
      <base-icon name="paragraph" size="w-5 h-5" />
    </base-button>
    <base-button
      alt="bullet list"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="isBulletListActive() ? 'text-white bg-gray-700' : 'text-gray-400'"
      @click.stop.prevent="toggleBulletList()"
    >
      <base-icon name="bullet-list" size="w-4 h-4" class="menu-icon" />
    </base-button>
    <base-button
      alt="ordered list"
      variant="custom"
      custom-classes="w-8 h-8 flex justify-center items-center rounded-md hover:bg-white/[0.16]"
      :class="
        isOrderedListActive() ? 'text-white bg-gray-700' : 'text-gray-400'
      "
      @click.stop.prevent="toggleOrderedList()"
    >
      <base-icon name="ordered-list" size="w-4 h-4" class="menu-icon" />
    </base-button>
  </div>
</template>
