import { computed } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useSetUserEmailMutation } from '../../api/use-set-user-email.mutation';
import { useSendVerificationEmailMutation } from '../../api/use-send-verification-email.mutation';
import { useVerificationEmailStatusQuery } from '../../api/use-verification-email-status.query';
import { ActionTypes, usePersistentStore } from '@/store';
import { useToast } from '@/shared/model';
import { trackEvent } from '@/utils/tracker';
import type { Ref } from 'vue';
import type { InvalidSubmissionHandler } from 'vee-validate';
import type {
  EmailVerificationError,
  UpdateUserEmail,
  UserSelfView,
} from 'dfx/edge/edge.did';

export const useEmailUpdate = (currentUser: Ref<UserSelfView>) => {
  const store = useStore();
  const { t } = useI18n();
  const { showToast } = useToast();
  const { mutateAsync: sendVerificationEmailMutation } =
    useSendVerificationEmailMutation();
  const { refetch: getUserEmailVerifiedStatusQuery } =
    useVerificationEmailStatusQuery();

  const persistentStore = usePersistentStore();
  const { verificationEmail } = storeToRefs(persistentStore);

  const setEmailAddress = async (
    email: string,
    { toast }: { toast: boolean },
  ) => {
    try {
      verificationEmail.value.sent = false;
      verificationEmail.value.resend = false;
      verificationEmail.value.verified = false;
      await store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, true);
      const payload: UpdateUserEmail =
        email.length > 0 ? { email: [email] } : { email: [] };
      const result = await useSetUserEmailMutation(payload);
      if (result?.status === 'happy') {
        trackEvent('user_settings_action', 'change_email', email);
        await store.dispatch(`auth/${ActionTypes.SET_ME}`, result.result[0]);
        if (toast) {
          showToast({
            type: 'success',
            title: t('verification.email.accountEmailUpdated'),
            durationSeconds: 4,
          });
        }
      } else {
        showToast({
          type: 'error',
          title: result.message,
          durationSeconds: 4,
        });
      }
    } catch (error: unknown) {
      const err = error as Error;
      showToast({
        type: 'error',
        title: err.message,
        durationSeconds: 4,
      });
    } finally {
      await store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, false);
    }
  };
  const verifyEmailAddress = async (email: string) => {
    verificationEmail.value.resend = false;
    await sendVerificationEmailMutation(undefined, {
      onError: (error: EmailVerificationError) => {
        verificationEmail.value.sent = false;
        verificationEmail.value.resend = false;
        let sendVerificationEmailErrorMessage = t(
          'verification.email.errors.unknownError',
        );
        if ('SocietyRsError' in error) {
          if ('InternalError' in error.SocietyRsError) {
            if (
              error.SocietyRsError.InternalError.includes('Too Many Requests')
            ) {
              sendVerificationEmailErrorMessage = `${t(
                'verification.email.errors.tooManyRequests',
              )}`;
            }
          }
        }
        showToast({
          type: 'error',
          title: 'Error',
          description: `${t(
            'verification.email.emailNotSent',
          )} ${sendVerificationEmailErrorMessage}`,
          durationSeconds: 4,
        });
      },
      onSuccess: () => {
        trackEvent('user_settings_action', 'verify_email', email);
        verificationEmail.value.sent = true;
        setTimeout(() => {
          verificationEmail.value.resend = true;
        }, 120000);
        showToast({
          type: 'success',
          title: t('verification.email.emailSuccessfullySent'),
          durationSeconds: 4,
        });
      },
    });
  };
  const getEmailAddressVerificationPendingStatus = async () => {
    const { data: isPending } = await getUserEmailVerifiedStatusQuery();
    if (isPending) {
      verificationEmail.value.sent = true;
      verificationEmail.value.resend = true;
      verificationEmail.value.verified = false;
    } else {
      verificationEmail.value.sent = false;
      verificationEmail.value.resend = false;
    }
  };

  const { handleSubmit, meta, resetForm } = useForm<{ email: string }>({
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('verification.email.form.errors.invalid'))
        .required(),
    }),
    initialValues: computed(() => {
      return {
        email: currentUser.value.email[0] ?? '',
      };
    }),
    // @see: https://github.com/logaretm/vee-validate/issues/4252#issuecomment-1542747543
    keepValuesOnUnmount: true,
  });

  const onInvalidSubmit: InvalidSubmissionHandler<{ email: string }> = ({
    values,
  }) => {
    if (!values.email) {
      showToast({
        type: 'error',
        title: t('verification.email.form.errors.required'),
        durationSeconds: 4,
      });
    }
  };

  const emailVerified = computed(() => {
    return currentUser.value.email_verified;
  });
  const emailDirty = computed(() => {
    return meta.value.dirty;
  });
  return {
    emailAddress: currentUser.value.email[0] ?? '',
    emailVerified,
    emailDirty,
    handleSubmit,
    onInvalidSubmit,
    resetForm,
    setEmailAddress,
    verifyEmailAddress,
    getEmailAddressVerificationPendingStatus,
  };
};
