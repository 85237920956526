<script lang="ts" setup>
  import { Toast } from '@/shared/ui/toast';
  import { useToast } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  const { toasts, removeToast } = useToast();
</script>

<template>
  <toast
    v-for="toast in toasts"
    :key="toast.id"
    placement="bottom-center"
    variant="bordered"
    :image="toast.image"
    :icon-name="toast.iconName"
    :type="toast.type"
    timed
    :duration-seconds="toast.durationSeconds"
    @close="removeToast(toast)"
  >
    <template v-if="toast.image" #image>
      <img
        v-lazy="{
          src: toast.image,
          error: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
          ),
        }"
        class="object-cover w-24 h-24 rounded-2xl"
      />
    </template>
    <template v-if="toast.title" #title>
      {{ toast.title }}
    </template>
    <template v-if="toast.description" #description>
      {{ toast.description }}
    </template>
    <template v-if="toast.link" #link>
      <a
        :href="toast.link"
        target="_blank"
        rel="noopener noreferrer"
        class="font-medium text-indigo-300"
      >
        {{ toast.linkText }}
      </a>
    </template>
  </toast>
</template>
