<script setup lang="ts">
  import { computed, onMounted, toRef } from 'vue';
  import type { Principal } from '@dfinity/principal';
  import { Tippy } from 'vue-tippy';
  import { useClipboardToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { useSocialLinks } from './model';
  import type { SocialLink, SocialNetworkType } from '@/utils';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';

  const props = withDefaults(
    defineProps<{
      links: string[];
      forUserId?: Principal;
      exclude?: SocialNetworkType[];
    }>(),
    {
      forUserId: undefined,
      exclude: undefined,
    },
  );

  const links = toRef(props, 'links');
  const forUserId = toRef(props, 'forUserId');
  const { matchedSocialNetworks, getSocialNetwork } = useSocialLinks(links, {
    validateUsername: props.forUserId !== undefined,
    validateGroup: props.forUserId === undefined,
  });
  const { discordUsername, loadPairedPlatforms } = useUser(forUserId);
  const { copyToClipboard } = useClipboardToast();

  const fullSocialNetworks = computed(() => {
    const filteredList =
      props.exclude && props.exclude.length
        ? matchedSocialNetworks.value.filter(
            ({ socialNetwork }) => !props.exclude?.includes(socialNetwork.type),
          )
        : matchedSocialNetworks.value;

    if (!forUserId.value || !discordUsername.value) {
      return filteredList;
    }

    const discordEntry: SocialLink = {
      link: '',
      socialNetwork: getSocialNetwork('Discord')!,
      user: discordUsername.value,
    };

    const newList = [...filteredList, discordEntry].sort((a, b) => {
      const sort = a.socialNetwork.sort - b.socialNetwork.sort;
      if (sort !== 0) {
        return sort;
      }
      return a.link.localeCompare(b.link);
    });
    return newList;
  });

  const slicedSocialNetworks = computed(() => {
    if (fullSocialNetworks.value.length <= 5) {
      return fullSocialNetworks.value;
    }
    return fullSocialNetworks.value.slice(0, 4);
  });
  const remainingSocialNetworks = computed(() => {
    if (fullSocialNetworks.value.length <= 5) {
      return [];
    }
    return fullSocialNetworks.value.slice(4);
  });

  const fullLink = (link: string): string => {
    if (link.startsWith('http')) {
      return link;
    }
    return `https://${link}`;
  };

  onMounted(() => {
    if (forUserId.value) {
      loadPairedPlatforms(forUserId.value);
    }
  });
</script>

<template>
  <ul class="flex justify-center gap-5">
    <li
      v-for="{ link, socialNetwork, user } of slicedSocialNetworks"
      :key="link"
    >
      <tippy
        v-if="socialNetwork.type === 'Discord' && user && !link"
        theme="mention"
      >
        <base-icon
          :name="socialNetwork.iconDesaturated"
          size="w-5 h-5"
          class="cursor-pointer"
          @click="copyToClipboard(user)"
        />
        <template #content>
          <span class="lowercase">{{ user }}</span>
        </template>
      </tippy>
      <tippy
        v-else-if="socialNetwork.type === 'Atlas3' && !user && link"
        theme="mention"
      >
        <a :href="fullLink(link)" target="_blank" class="cursor-pointer">
          <base-icon :name="socialNetwork.iconDesaturated" size="w-5 h-5" />
        </a>
        <template #content>
          <span class="normal-case">{{ $t('collaborateOnAtlas3') }}</span>
        </template>
      </tippy>
      <tippy v-else theme="mention">
        <a :href="fullLink(link)" target="_blank" class="cursor-pointer">
          <base-icon :name="socialNetwork.iconDesaturated" size="w-5 h-5" />
        </a>
        <template #content>
          <span class="lowercase">{{ forUserId && user ? user : link }}</span>
        </template>
      </tippy>
    </li>
    <base-dropdown
      v-if="remainingSocialNetworks.length > 0"
      placement="bottom-end"
      custom-content-classes="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl max-w-[300px] overflow-hidden"
    >
      <template #button="{ toggle }">
        <base-tooltip content="More" theme="mention">
          <base-icon
            name="plus"
            size="w-5 h-5"
            class="cursor-pointer"
            @click="toggle"
          />
        </base-tooltip>
      </template>
      <template #content="{ hide }">
        <ul class="flex flex-col" @click="hide">
          <li
            v-for="{ link, socialNetwork, user } of remainingSocialNetworks"
            :key="link"
            class="cursor-pointer p-3 hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
          >
            <div
              v-if="socialNetwork.type === 'Discord' && user && !link"
              class="flex items-center gap-5"
              :title="user"
              @click="copyToClipboard(user)"
            >
              <base-icon :name="socialNetwork.iconDesaturated" size="w-5 h-5" />
              <span class="truncate ellipsis">{{ user }}</span>
            </div>
            <a
              v-else-if="socialNetwork.type === 'Atlas3' && !user && link"
              :href="fullLink(link)"
              target="_blank"
              class="flex items-center gap-5"
              :title="forUserId && user ? user : link"
            >
              <base-icon :name="socialNetwork.iconDesaturated" size="w-5 h-5" />
              <span class="truncate ellipsis normal-case">{{
                $t('collaborateOnAtlas3')
              }}</span>
            </a>
            <a
              v-else
              :href="fullLink(link)"
              target="_blank"
              class="flex items-center gap-5"
              :title="forUserId && user ? user : link"
            >
              <base-icon :name="socialNetwork.iconDesaturated" size="w-5 h-5" />
              <span class="truncate ellipsis">{{
                forUserId && user ? user : link
              }}</span>
            </a>
          </li>
        </ul>
      </template>
    </base-dropdown>
  </ul>
</template>
