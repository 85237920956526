<script setup lang="ts">
  import { withDefaults } from 'vue';

  const emit = defineEmits<{
    (e: 'close'): void;
  }>();

  /**
   *
   */
  function close() {
    emit('close');
  }
</script>

<template>
  <div
    class="bg-zinc-950 py-6 pl-8 pr-11 h-screen md:h-full w-96 flex-1 flex flex-col border border-gray-785 border-opacity-50 lg:after:absolute lg:after:top-0 lg:after:right-0 lg:after:w-8 lg:after:h-full lg:after:bg-gradient-to-r lg:after:from-transparent lg:after:to-black justify-start"
  >
    <div class="flex items-center">
      <base-icon
        name="outlined-arrow-left"
        size="w-6 h-6"
        class="cursor-pointer text-gray-400 mr-2 lg:hidden hover:text-white transition duration-500"
        @click="close"
      />
      <base-icon
        name="chevron-right"
        size="w-6 h-6"
        class="rounded-lg bg-gray-800 p-1 cursor-pointer mr-2 hidden lg:block hover:brightness-150 transition duration-500"
        @click="close"
      />
      <slot name="header"></slot>
    </div>
    <slot></slot>
  </div>
</template>
