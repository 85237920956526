import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import type { PatchWalletStatus } from '@/shared/api/dscvr-api/wallet/types';

export const useSetWalletStatusMutation = () => {
  return useMutation({
    mutationKey: ['set-wallet-status-mutation'],
    mutationFn: (wallet: { address: string; status: PatchWalletStatus }) =>
      dscvrApi.wallet.updateWalletStatus(wallet.address, wallet.status),
  });
};
