<template>
  <div
    ref="faq"
    class="p-4 bg"
    :style="{
      backgroundImage: `url(${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/background/circle-bg@3x.jpg`,
        { quality: 100 },
      )})`,
    }"
  >
    <h1 class="mt-12 mb-4 text-center landing-title sm:mt-24 sm:mb-4">
      For users
    </h1>
    <div class="m-auto text-center landing-content sm:max-w-xl mb-9 sm:mb-26">
      <!-- DSCVR makes crypto as friendly and easy as social media.  -->
      Access the bleeding-edge of Web3 with the familiarity and ease-of-use of
      Web2.
    </div>
    <div class="m-auto max-w-[1002px]">
      <div class="items-center justify-between block sm:flex mb-14 sm:mb-28">
        <img
          class="mb-8 sm:mb-0 w-auto max-w-full sm:max-w-[440px]"
          :src="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/landing/for_users.png`, {
              quality: 100,
            })
          "
        />
        <div class="sm:max-w-md">
          <h1 class="mb-4 text-center landing-subtitle sm:text-left">
            Discover Portals
          </h1>
          <div class="text-center landing-content sm:text-left">
            Join and participate in unique communities. Everything you know and
            love from Web2 social media with a suite of Web3 functionality.
          </div>
        </div>
      </div>
      <div
        class="items-center justify-between block sm:flex sm:flex-row-reverse mb-14 sm:mb-28"
      >
        <img
          class="mb-8 sm:mb-0 w-auto max-w-full sm:max-w-[396px]"
          :src="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/landing/Wallet.png`, {
              quality: 100,
            })
          "
        />
        <div class="sm:max-w-md">
          <h1 class="mb-4 text-center landing-subtitle sm:text-left">
            Walletless Web3
          </h1>
          <div class="text-center landing-content sm:text-left">
            Send and receive tokens, no wallet required. Pair one if you want to
            - or never think about wallets again.
          </div>
        </div>
      </div>
      <div class="items-center justify-between block sm:flex sm:mb-14">
        <img
          class="mb-8 sm:mb-0 w-auto max-w-full sm:max-w-[440px]"
          :src="
            fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/landing/Raining.png`, {
              quality: 100,
            })
          "
        />
        <div class="sm:max-w-md">
          <h1 class="mb-4 text-center landing-subtitle sm:text-left">
            It's raining tokens
          </h1>
          <div class="text-center landing-content sm:text-left">
            Create great content to receive NFTs and fungibles as airdrops and
            tips.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  export default {
    name: 'users-section',
    data() {
      return {
        DSCVR_STATIC_ASSETS_CDN_URL,
        fetchMedia,
      };
    },
    methods: {
      scrollToFaq() {
        this.$refs.faq.scrollIntoView({ behavior: 'smooth' });
      },
    },
  };
</script>

<style scoped>
  .for-user-img {
    width: 440px;
    min-width: 440px;
    height: 318px;
  }
  .gradient-bg {
    position: absolute;
    background: linear-gradient(180deg, #100f2e 0%, #010101 33.87%);
  }
  .bg {
    background: #010102;
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 682px;
  }
</style>
