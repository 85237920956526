<script setup lang="ts">
  import { useSlots } from 'vue';
  import { Badge } from '@/shared/ui/badge';
  import TabSkeleton from '../ui/TabSkeleton.vue';

  defineProps<{
    customClasses: string[];
    isLoading?: boolean;
    disabled?: boolean;
  }>();

  defineEmits<{
    (e: 'select'): void;
  }>();

  const slots = useSlots();
</script>

<template>
  <tab-skeleton v-if="isLoading" />
  <base-button
    v-else
    variant="custom"
    :disabled="disabled"
    :custom-classes="[
      customClasses,
      disabled
        ? 'cursor-default'
        : 'cursor-pointer hover:brightness-125 ease-transition-all ',
    ]"
    @click="$emit('select')"
  >
    <span><slot /></span>
    <badge
      v-if="slots.count"
      spacing="px-2 py-0.5"
      background="bg-indigo-400 bg-opacity-20"
      font="font-semibold text-xs"
      dimensions="min-w-6"
    >
      <slot name="count" />
    </badge>
  </base-button>
</template>
