<script lang="ts" setup>
  import { useUserLinks } from '@/widgets/app-header';
  import type { UserMenuProps, UserEmit } from '@/widgets/app-header';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const props = defineProps<UserMenuProps>();

  const emit = defineEmits<UserEmit>();
  const { userLinks, onClickRoute, customLinksClasses } = useUserLinks(
    props,
    emit,
  );
</script>

<template>
  <template v-for="link in userLinks" :key="link.label">
    <base-button
      variant="link"
      class="w-full"
      :custom-classes="customLinksClasses"
      :class="{
        'bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
          typeof link.route === 'object' && link.route?.name === route.name,
      }"
      @click="onClickRoute(link.route)"
    >
      <base-icon
        v-if="link.icon"
        :name="link.icon"
        size="w-5 h-5"
        class="text-indigo-300"
      />
      <span>
        {{ link.label }}
      </span>
    </base-button>
  </template>
</template>
