<script lang="ts" setup>
  import { useDialog } from '@/shared/model';
  import { useIgc } from '../../model/composables/use-igc';

  const { closeDialog } = useDialog();
  const { sparksCount } = useIgc();
</script>

<template>
  <div class="absolute top-10 right-10 z-10">
    <base-button variant="link" @click.stop="closeDialog">
      <base-icon name="x" size="size-5" class="text-gray-400" />
    </base-button>
  </div>
  <div class="pt-6 md:pt-0 mb-6">
    <div
      class="flex items-center gap-2 py-2 px-4 max-w-max rounded-full border border-gray-785 border-opacity-50"
    >
      <base-icon name="sparks" size="size-5" />
      <span class="font-medium tracking-[0.16px]">
        {{ sparksCount }}
      </span>
    </div>
  </div>
</template>
