import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi, dscvrApi } from '@/shared/api';

export const useAcceptTransactionMutation = () => {
  return useMutation({
    mutationKey: ['accept-canvas-transaction-mutation'],
    mutationFn: ({
      appUrl,
      scanResponse,
      signedTx,
    }: {
      appUrl: string;
      scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response;
      signedTx: string;
    }) =>
      dscvrIcApi.canvas.acceptTransaction(
        appUrl,
        scanResponse.requestId,
        signedTx,
      ),
  });
};
