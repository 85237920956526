<script lang="ts" setup>
  import { computed } from 'vue';
  import { useBreakpoint, useShareDialog } from '@/shared/model';
  import {
    JoinPortalButton,
    getPortalIcon,
    coverGradients,
    getAbbreviatedMemberCount,
    getAbbreviatedContentCount,
    usePortalUserStatus,
  } from '@/entities/portal';
  import ReadMore from '@/components/ReadMore.vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { JoinPortalLabel } from '@/features/portal';
  import { IntroductionFull } from '@/widgets/introduction';
  import { CreateLiveRoomButton } from '@/features/live/create-room';
  import { SocialLinksSummary } from '@/features/social-links';
  import { useSocialLinks } from '@/features/social-links';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import {
    PORTAL_SETTINGS_PAGE_BANS,
    PORTAL_SETTINGS_PAGE_MEMBERS,
    PORTAL_SETTINGS_PAGE_OVERVIEW,
    PORTAL_SETTINGS_PAGE_ROLES,
  } from '@/common';
  import {
    PermissionFlags,
    isPortalOwner,
    isPortalManager,
  } from '@/shared/lib';
  import InvitePortalButton from './components/InvitePortalButton.vue';
  import { CoverPhoto } from '@/shared/ui/cover-photo';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useUser } from '@/entities/user';
  import { liveRoomsModel } from '@/entities/live';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const { currentUser, isLoggedIn } = useUser();
  const { isPortalFavorited, toggleFavorite, isPortalJoined } =
    usePortalUserStatus();
  const { isSmallerThanMd } = useBreakpoint();
  const { liveRoomsCount } = liveRoomsModel.use();

  const socialLinks = computed(() => {
    if (!props.portalView.info.length) {
      return [];
    }
    return props.portalView.info[0].links.map(({ href }) => href);
  });

  const noRoomsAreLive = computed(() => {
    return liveRoomsCount.value === 0;
  });

  const isLiveButtonVisible = computed(() => {
    return isPortalManager(props.portalView) && noRoomsAreLive.value;
  });

  const isCurrentUserPortalOwner = computed(
    () =>
      isLoggedIn.value && isPortalOwner(props.portalView, currentUser.value),
  );
  const isCurrentUserPortalOwnerOrManager = computed(
    () => isPortalManager(props.portalView) || isCurrentUserPortalOwner.value,
  );
  const { matchedSocialNetworks } = useSocialLinks(socialLinks, {
    validateUsername: false,
    validateGroup: true,
  });
  const { openSharePortalProfile } = useShareDialog();

  const customDropdownButtonClasses =
    'flex gap-3 p-3 w-[210px] hover:bg-gray-785 active:bg-gray-785 focus:bg-gray-700';

  const atlas3SocialLink = computed(() =>
    matchedSocialNetworks.value.find(
      ({ socialNetwork }) => socialNetwork.type === 'Atlas3',
    ),
  );

  const creationDate = computed(() => {
    return (
      props.portalView &&
      new Date(
        Number(props.portalView.created_at / 1000000n),
      ).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
  });

  const portalSettingsRoute = computed(() => {
    const perms = Number(props.portalView.perm);
    if (
      (perms & PermissionFlags.MODERATE_CONTENT) > 0 ||
      isCurrentUserPortalOwner.value
    ) {
      return PORTAL_SETTINGS_PAGE_OVERVIEW;
    } else if ((perms & PermissionFlags.MANAGE_ROLES) > 0) {
      return PORTAL_SETTINGS_PAGE_ROLES;
    } else if ((perms & PermissionFlags.MANAGE_MEMBERS) > 0) {
      return PORTAL_SETTINGS_PAGE_MEMBERS;
    } else if ((perms & PermissionFlags.BAN_MEMBER) > 0) {
      return PORTAL_SETTINGS_PAGE_BANS;
    }
    return '';
  });
</script>

<template>
  <introduction-full
    v-if="portalView"
    class="mb-4 overflow-hidden border border-opacity-50 bg-gray-950 rounded-b-xl border-gray-785 md:rounded-b-none lg:rounded-xl"
  >
    <template #cover-image>
      <div class="relative">
        <cover-photo
          :src="portalView.info[0]?.cover_photo[0]"
          :gradients="coverGradients"
          class="min-h-27 md:min-h-72 md:max-h-72"
        />
        <div
          class="absolute inset-0 w-full h-full bg-gradient-to-t from-black/74 via-transparent to-transparent"
        ></div>
      </div>
    </template>
    <template #photo>
      <img
        alt="Portal Icon"
        class="size-16 md:size-[148px] rounded-xl"
        v-lazy="getPortalIcon(portalView)"
      />
    </template>
    <template #name>
      {{ portalView.name }}
    </template>
    <template #slug>
      {{ portalView.slug }}
    </template>
    <template #members-count>
      {{ getAbbreviatedMemberCount(portalView) }}
    </template>
    <template #content-count>
      {{ getAbbreviatedContentCount(portalView) }}
    </template>
    <template #creation-date>
      {{ creationDate }}
    </template>
    <template #action-button-1>
      <invite-portal-button :portal-view="portalView" />
    </template>
    <template v-if="atlas3SocialLink" #action-button-2>
      <base-tooltip :content="$t('collaborateOnAtlas3')" placement="top">
        <base-button
          variant="secondary"
          size="small"
          :to="atlas3SocialLink.link"
          target="_blank"
        >
          <base-icon
            :name="atlas3SocialLink.socialNetwork.iconDesaturated"
            size="size-5"
          />
          <span>{{ $t('collab') }}</span>
        </base-button>
      </base-tooltip>
    </template>
    <template #action-button-3 v-if="isLiveButtonVisible">
      <create-live-room-button :portal-view="portalView" />
    </template>
    <template v-if="!isCurrentUserPortalOwner" #action-button-4>
      <join-portal-button
        :portal-view="portalView"
        classes="w-full md:w-auto"
        join-class="capitalize group/join drop-shadow-light"
      >
        <template #label="{ joinState }">
          <join-portal-label :join-state="joinState" />
        </template>
      </join-portal-button>
    </template>
    <template #action-dropdown>
      <base-dropdown placement="bottom-end" :distance="-5">
        <template #button="{ toggle }">
          <base-button variant="custom" class="py-2.5 px-3" @click="toggle">
            <base-icon name="vertical-dots" size="size-5" />
          </base-button>
        </template>
        <template #content="{ hide }">
          <div
            class="right-0 mt-4 overflow-hidden border border-opacity-50 rounded-xl bg-gray-725 border-gray-785 top-full"
          >
            <base-button
              v-if="
                isCurrentUserPortalOwner || isCurrentUserPortalOwnerOrManager
              "
              variant="custom"
              size="medium"
              :custom-classes="customDropdownButtonClasses"
              :to="{
                name: portalSettingsRoute,
                params: {
                  slug: portalView.slug,
                },
              }"
              @click="hide()"
            >
              <base-icon
                name="settings"
                size="size-5"
                class="text-indigo-300"
              />
              Manage
            </base-button>
            <base-button
              v-if="isPortalJoined(portalView)"
              variant="custom"
              size="small"
              :custom-classes="customDropdownButtonClasses"
              @click="hide(), toggleFavorite(portalView)"
            >
              <base-icon
                name="outline-star"
                size="size-5"
                class="text-indigo-300"
              />
              <span>{{
                isPortalFavorited(portalView)
                  ? $t('favoritePortals.removeFromFavorites')
                  : $t('favoritePortals.addToFavorites')
              }}</span>
            </base-button>
            <base-button
              variant="custom"
              size="medium"
              :custom-classes="customDropdownButtonClasses"
              @click="hide(), openSharePortalProfile(portalView)"
            >
              <base-icon
                name="outlined-share"
                size="size-5"
                class="text-indigo-300"
              />
              Share Portal
            </base-button>
          </div>
        </template>
      </base-dropdown>
    </template>
    <template #bio>
      <read-more
        class="w-full px-4 text-sm text-gray-300 break-words md:place-self-start"
        :show-length="isSmallerThanMd ? 70 : 1200"
        :context="portalView.description"
      />
    </template>
    <template #social-links>
      <social-links-summary :links="socialLinks" :exclude="['Atlas3']" />
    </template>
  </introduction-full>
</template>
