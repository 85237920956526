<script lang="ts" setup>
  import { toRef } from 'vue';
  import { useGetContentRanksQuery } from '@/entities/post';
  import type { UserView, ContentView } from 'dfx/edge/edge.did';
  import { AdminToolbar } from '@/shared/ui/admin-toolbar';

  const props = defineProps<{
    content: ContentView;
  }>();

  const emits = defineEmits<{
    (e: 'remove-content', content: ContentView): void;
    (e: 'set-rank', params: { content: ContentView; amount: bigint }): void;
    (e: 'goodbye', user: UserView): void;
  }>();

  const content = toRef(props, 'content');
  const { data: rank } = useGetContentRanksQuery(content);

  const setRank = (amount: number) => {
    emits('set-rank', { content: content.value, amount: BigInt(amount) });
  };

  const goodbye = () => {
    if (confirm(`Ban ${content.value.owner.username}`)) {
      emits('goodbye', content.value.owner);
    }
  };

  const onRemove = () => {
    if (confirm('Are you sure you want to remove this content?')) {
      emits('remove-content', content.value);
    }
  };
</script>

<template>
  <admin-toolbar
    @💣="setRank(0)"
    @☯️="setRank(1)"
    @🚀="setRank(101)"
    @goodbye="goodbye"
    @remove="onRemove"
  >
    R: {{ rank }}
  </admin-toolbar>
</template>
