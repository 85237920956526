<template>
  <h3 class="rounded-lg h-9 w-30 bg-gray-950 animate-pulse" />
  <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2">
    <div
      v-for="i in 2"
      :key="i"
      class="h-20 rounded-2xl overflow-hidden bg-gray-950 animate-pulse"
    />
  </div>
</template>
