import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useUpdatePortalRoleOrdinalsMutation = () => {
  return useMutation({
    mutationKey: ['update-portal-role-ordinals-mutation'],
    mutationFn: (
      params: dscvrIcApi.portal.UpdatePortalRoleOrdinalsMutationParams,
    ) => dscvrIcApi.portal.updatePortalRoleOrdinals(params),
  });
};
