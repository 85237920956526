import type { FrameMetaTagPropertyType, FrameMetaTags } from '../types';

export const extractFrameMetaTags = (doc: Document): FrameMetaTags => {
  const metaTags = doc.querySelectorAll(
    `meta[property^="of:"], meta[name^="of:"], meta[property^="fc:frame"], meta[name^="fc:frame"]`,
  );
  const result: FrameMetaTags = {
    source: '',
    list: [],
  };
  metaTags.forEach((tag) => {
    const name = (
      tag.hasAttribute('property')
        ? tag.getAttribute('property')
        : tag.getAttribute('name')
    ) as FrameMetaTagPropertyType;
    const content = tag.getAttribute('content');
    if (name && content) {
      result.list.push({
        name,
        content,
      });

      result.source = result.source.concat(tag.outerHTML).concat('\n');
    }
  });
  return result;
};
