import { computed } from 'vue';
import { PermissionFlags } from '@/shared/lib';
import { ADMIN_USER_TYPE } from '@/common/constants';
import type { ContentView } from 'dfx/edge/edge.did';
import { useUser } from '@/entities/user';

export const useUserRole = (post?: ContentView | undefined) => {
  const { currentUser } = useUser();
  const isAdmin = computed(
    () => Number(currentUser.value?.user_type) == ADMIN_USER_TYPE,
  );
  const isContentOwner = computed(
    () => currentUser.value?.username === post?.owner.username,
  );
  const isContentModerator = computed(
    () =>
      (Number(post?.perm) & PermissionFlags.MODERATE_CONTENT) ===
      PermissionFlags.MODERATE_CONTENT,
  );

  return {
    isAdmin,
    isContentOwner,
    isContentModerator,
  };
};
