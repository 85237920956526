<script lang="ts" setup>
  import { computed, toRef, ref, onMounted } from 'vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { USER_CONTENT } from '@/common';
  import { Diamond } from '@/shared/ui/diamond';
  import { useGetUserPortalRolesQuery } from '@/entities/user';
  import type { PortalRule, PortalView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const portalOwner = toRef(() => props.portalView.owner);

  const rules = ref<(PortalRule & { expanded: boolean })[]>();

  const { data: portalRoles } = useGetUserPortalRolesQuery(
    toRef(() => props.portalView),
    portalOwner,
    ref(true),
  );

  onMounted(() => {
    const portalInfo = props.portalView.info;
    if (portalInfo.length && portalInfo[0].rules.length) {
      rules.value = portalInfo[0].rules[0].map((rule) => ({
        ...rule,
        expanded: false,
      }));
    }
  });
</script>

<template>
  <div
    v-if="portalView"
    class="w-full px-4 pt-3 pb-4 mb-4 text-left text-white bg-gray-950 shadow-lg sidebar-portalGovernance height sm:rounded-xl"
  >
    <p class="my-3 text-xl font-medium">Portal Governance</p>

    <p class="p-0 m-0 mt-1 mb-2 font-light text-white truncate">Admin</p>

    <router-link
      :to="{
        name: USER_CONTENT,
        params: { username: portalOwner?.username },
      }"
      class="inline-flex items-center gap-1 px-3 py-1 text-base bg-gray-700 rounded-lg cursor-pointer"
    >
      <user-avatar
        v-if="portalOwner"
        :item="portalOwner"
        size="size-4"
        class="inline mr-1"
      />
      <base-icon v-else name="user-circle" size="size-6" class="inline" />
      {{ portalView.owner.username }}
    </router-link>

    <div v-if="portalRoles && portalRoles.roles.length > 0">
      <p class="p-0 m-0 mt-4 mb-2 font-light text-white truncate">Roles</p>

      <div
        v-for="(role, index) in portalRoles.roles"
        :key="index"
        class="inline-block mb-2 mr-2"
      >
        <div class="role">
          <diamond :color="role.color" size="size-3" class="mr-2" />

          <p class="ml-1 text-base">
            {{ role.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="Number(portalView?.id) === 5412741"
    class="w-full px-4 pt-3 pb-4 mb-4 text-left text-white bg-gray-950 shadow-lg sidebar-portalLinks height sm:rounded-xl"
  >
    <h1 class="my-3 text-xl font-medium">Resources</h1>
    <div>
      <div class="py-1 md:mb-0">
        <a href="https://dscvr.one/post/6958037/neurons-101" class="underline">
          Neurons 101
        </a>
        - <span class="text-gray-300">Learn about what a Neuron is.</span>
      </div>
      <div class="py-1 md:mb-0">
        <a href="https://dscvr.one/u/SNSProposalBot" class="underline">
          Proposals
        </a>
        - <span class="text-gray-300">List of proposals for the SNS-1.</span>
      </div>
    </div>
  </div>
  <div
    v-if="rules?.length"
    class="w-full px-4 pt-3 pb-4 mb-16 text-left text-white bg-gray-950 shadow-lg sidebar-portalLinks height sm:rounded-xl sm:mb-4"
  >
    <h1 class="my-3 text-xl font-medium">Rules</h1>
    <div class="mt-3 overflow-y-auto thin-scrollbar">
      <div v-for="(rule, key) in rules" :key="key" class="py-2">
        <button
          class="flex items-center justify-between w-full"
          v-click-outside="() => (rule.expanded = false)"
          @click="rule.expanded = !rule.expanded"
        >
          <h4 class="p-0 m-0 text-gray-300 truncate">
            {{ key + 1 }}. {{ rule.name }}
          </h4>
          <svg
            :class="{ 'transform rotate-180': rule.expanded == true }"
            class="size-5 text-gray-300"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          v-show="rule.expanded"
          class="mt-3"
          :class="{ 'text-gray-600': rule.expanded == true }"
        >
          <p class="text-gray-400">
            {{ rule.desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .role {
    @apply bg-gray-700 py-1 px-3 inline-flex items-center rounded-lg text-xs;
  }
</style>
