import nftsService from '../../services/nfts';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
import { TipCan } from '../../utils/tipCan';
import { decodeTokenIdentifier } from 'ictool';
import gatedService from '../../services/gated';

// initial state
const state = () => ({
  isLoading: false,
  all: [],
  newTips: [],
  tipWatch: [],
  currentTips: {},
  currentICRCTips: {},
  entrepotCollections: [],
});

// getters
const getters = {
  collections: (state) => {
    return state.all;
  },
  entrepotCollections: (state) => {
    return state.entrepotCollections;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  newTips: (state) => {
    return state.newTips;
  },
  currentTips: (state) => {
    return state.currentTips;
  },
  currentICRCTips: (state) => {
    return state.currentICRCTips;
  },
};

// actions
const actions = {
  [ActionTypes.LIST_ENTREPOT_NFT_COLLECTIONS]({ commit }, { hideLoading }) {
    if (!hideLoading) {
      commit(MutationTypes.SET_IS_LOADING, true);
    }
    return nftsService
      .listEntrpotCollections()
      .then((response) => {
        const nfts = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        commit(MutationTypes.SET_ENTREPOT_NFT_COLLECTIONS, nfts);
      })
      .finally(
        () => !hideLoading && commit(MutationTypes.SET_IS_LOADING, false),
      );
  },
  [ActionTypes.NFT_LIST_COLLECTIONS]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return nftsService
      .listCollections()
      .then((collections) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        commit(MutationTypes.NFT_SET_COLLECTIONS, collections);
        return collections;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.TRIGGER_TIP_WATCH]({ commit, state }) {
    if (state.tipWatch.length > 0) {
      //lets see if any notices this is not running before removing it
      // const tc = new TipCan();
      // tc.create().then(() => {
      //   tc.tipsOfContentIDs(state.tipWatch).then((tips) => {
      //     commit(MutationTypes.ADD_NFT_TIPS, tips);
      //   });
      // });

      gatedService
        .getTips({
          contentIds: state.tipWatch.map((x) => x.toString()),
        })
        .then((tips) => {
          commit(MutationTypes.ADD_ICRC_TIPS, tips.data);
        });
    }
  },

  [ActionTypes.ADD_OPTIMISTIC_TIP]({ commit }, payload) {
    commit(MutationTypes.ADD_NEW_NFT_TIP, payload);
  },
};

// mutations
const mutations = {
  [MutationTypes.NFT_SET_COLLECTIONS](state, collections) {
    state.all = collections;
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
  [MutationTypes.ADD_NEW_NFT_TIP](state, val) {
    state.newTips.push(val);
  },
  [MutationTypes.RESET_TIPS](state) {
    state.currentTips = {};
  },
  [MutationTypes.ADD_ICRC_TIPS](state, vals) {
    state.currentICRCTips = {};

    for (let i = 0; i < vals.length; i++) {
      const tip = vals[i];

      if (!(tip.contentId in state.currentICRCTips)) {
        state.currentICRCTips[tip.contentId] = [];
      }

      const tipItem = state.currentICRCTips[tip.contentId].find(
        (item) => item.cid == tip.tokenType,
      );

      if (tipItem) {
        tipItem.amount += Number(tip.amount);
      } else {
        state.currentICRCTips[tip.contentId].push({
          cid: tip.tokenType,
          amount: Number(tip.amount),
        });
      }
    }
  },
  [MutationTypes.ADD_NFT_TIPS](state, vals) {
    state.currentTips = {};
    if (vals.length > 0) {
      for (let i = 0; i < vals[0].length; i++) {
        const tip = vals[0][i];
        if (tip.contentID.length > 0) {
          if (!(tip.contentID[0] in state.currentTips)) {
            state.currentTips[tip.contentID[0]] = [];
          }
          const cid = decodeTokenIdentifier(tip.token).canister;

          const tipItem = state.currentTips[tip.contentID[0]].find(
            (item) => item.cid == cid,
          );
          if (tipItem) {
            tipItem.amount += Number(tip.transaction.amount);
          } else {
            state.currentTips[tip.contentID[0]].push({
              cid: cid,
              amount: Number(tip.transaction.amount),
            });
          }
        }
      }
    }
  },
  [MutationTypes.REMOVE_NEW_NFT_TIP](state, val) {
    state.newTips = state.newTips.filter((x) => x.id != val);
  },
  [MutationTypes.ADD_CONTENT_TIP_WATCH](state, val) {
    if (!state.tipWatch.includes(val)) {
      state.tipWatch.push(val);
    }
  },
  [MutationTypes.REMOVE_CONTENT_TIP_WATCH](state, val) {
    state.tipWatch = state.tipWatch.filter((x) => x.id != val);
  },
  [MutationTypes.SET_ENTREPOT_NFT_COLLECTIONS](state, val) {
    state.entrepotCollections = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
