import type { WalletName } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { dscvrApi } from '@/shared/api';
import { SOLANA_WALLET_NAMES } from '@/shared/lib';

export const SOLANA_PAIR_ENABLED_WALLETS = [
  SOLANA_WALLET_NAMES.Phantom,
  SOLANA_WALLET_NAMES.Solflare,
  SOLANA_WALLET_NAMES.Backpack,
];

const EXCLUDED_SOLANA_WALLETS = [SOLANA_WALLET_NAMES.Metamask];

export const convertWalletNameToWalletType = (name: WalletName) => {
  switch (name) {
    case SOLANA_WALLET_NAMES.Phantom:
      return dscvrApi.wallet.WALLET_TYPES.phantom;
    case SOLANA_WALLET_NAMES.Solflare:
      return dscvrApi.wallet.WALLET_TYPES.solflare;
    case SOLANA_WALLET_NAMES.Metamask:
      return dscvrApi.wallet.WALLET_TYPES.metamask;
    case SOLANA_WALLET_NAMES.Backpack:
      return dscvrApi.wallet.WALLET_TYPES.backpack;
    default:
      return undefined;
  }
};

export const convertWalletTypeToWalletName = (
  type: dscvrApi.wallet.WalletType,
) => {
  switch (type) {
    case dscvrApi.wallet.WALLET_TYPES.phantom:
      return SOLANA_WALLET_NAMES.Phantom;
    case dscvrApi.wallet.WALLET_TYPES.solflare:
      return SOLANA_WALLET_NAMES.Solflare;
    case dscvrApi.wallet.WALLET_TYPES.metamask:
      return SOLANA_WALLET_NAMES.Metamask;
    case dscvrApi.wallet.WALLET_TYPES.backpack:
      return SOLANA_WALLET_NAMES.Backpack;
    case dscvrApi.wallet.WALLET_TYPES.matrica:
      throw new Error(`Unsupported solana wallet: ${type}`);
    default:
      return undefined;
  }
};

export const getSolanaWalletOptions = () => {
  return {
    // We need to specify our default adapters so the install redirection occurs on wallet connect for non installed wallets
    // When we are moved fully to StandardWalletAdapters, we can remove this
    wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
  };
};

export const isExcludedSolanaWallet = (walletName: WalletName) => {
  return EXCLUDED_SOLANA_WALLETS.includes(walletName);
};
