<script setup lang="ts">
  import { shortenString } from '@/shared/lib';
  import { dscvrApi } from '@/shared/api';
  import { useClipboardToast } from '@/shared/model';
  import { useTransactionScan } from '../model/composables/use-transaction-scan';
  import { toRef } from 'vue';

  const props = defineProps<{
    scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response;
  }>();

  const { copyToClipboard } = useClipboardToast();
  const { stateChangeDescriptions, warnings, warningsType } =
    useTransactionScan(toRef(props, 'scanResponse'));
</script>

<template>
  <div
    v-if="stateChangeDescriptions.length"
    class="flex flex-col -mt-4 mb-4 font-medium text-gray-400 w-100"
  >
    <div
      v-for="{ changes, address } in stateChangeDescriptions"
      :key="address"
      class="flex items-start py-8 border-b border-opacity-50 border-gray-785"
    >
      <ul class="flex flex-1 flex-col gap-4">
        <li
          v-for="(change, index) in changes"
          :key="index"
          :class="{ 'whitespace-nowrap': !change.prefix }"
        >
          <span v-if="change.prefix" :class="change.prefixColor" class="mr-2">
            {{ change.prefix }}
          </span>
          <span>{{ change.description }}</span>
        </li>
      </ul>
      <base-button
        variant="custom"
        additional-classes="flex flex-1 gap-1 !justify-end items-center text-indigo-300"
        @click="copyToClipboard(address.toString())"
      >
        <span>{{ shortenString(address.toString()) }}</span>
        <base-icon name="copy-documents" size="size-4" />
      </base-button>
    </div>
  </div>
  <slot v-else />
  <div v-if="warnings.length" class="px-6 w-full">
    <p
      class="flex flex-col gap-2 p-6 rounded-lg border border-opacity-20 w-full"
      :class="
        warningsType === 'BLOCK'
          ? 'border-red-325 text-red-550'
          : 'border-yellow-400 text-yellow-500'
      "
    >
      <span v-for="(message, index) in warnings" :key="index">
        {{ message }}
      </span>
    </p>
  </div>
</template>
