<script lang="ts" setup>
  import { computed } from 'vue';
  import type { Gradient } from '../../model';
  import { isValidHttpUrl, fetchMedia } from '../../lib';
  import { compact } from 'lodash-es';

  const props = defineProps<{
    gradients: Map<number, Gradient>;
    src?: string;
  }>();

  const gradientClass = computed(() => {
    const randomNumber = Math.floor(Math.random() * props.gradients.size) + 1;
    const srcAsNumber = Number(props.src);
    let number = randomNumber;
    if (!isNaN(srcAsNumber) && props.gradients.has(srcAsNumber)) {
      number = srcAsNumber;
    }
    return compact([
      props.gradients.get(number)!.direction ?? 'bg-gradient-to-b',
      props.gradients.get(number)!.from,
      props.gradients.get(number)!.to,
      props.gradients.get(number)!.via,
    ]);
  });
</script>

<template>
  <template v-if="src && isValidHttpUrl(src)">
    <img
      v-lazy="fetchMedia(src)"
      alt="cover photo"
      class="object-cover size-full"
    />
  </template>
  <template v-else>
    <div :class="gradientClass" class="size-full" />
  </template>
</template>
