<script setup lang="ts">
  import { toRef } from 'vue';
  import { useRoute } from 'vue-router';
  import { InviteBackground, PortalInviteSignUp } from '@/features/invite';
  import { useGetPortalBySlugQuery, useMetaPortal } from '@/entities/portal';

  const props = defineProps<{
    slug: string;
  }>();

  const route = useRoute();

  const { data: portalView } = useGetPortalBySlugQuery(
    toRef(props, 'slug'),
    true,
  );
  useMetaPortal(portalView);
</script>

<template>
  <div class="h-screen">
    <invite-background />
    <portal-invite-sign-up
      v-if="portalView"
      :portal-view="portalView"
      :code="typeof route.query.ur === 'string' ? route.query.ur : undefined"
    />
  </div>
</template>
