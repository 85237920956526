<script lang="ts" setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { commafy } from '@/shared/lib';
  import type { Spark } from '../../types';
  import { isEqual, uniqueId } from 'lodash-es';
  import { useToast } from '@/shared/model';
  import { useCreateCurrencyPaymentLinkMutation } from '../../api/use-create-currency-payment-link.mutation';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = useI18n({
    useScope: 'global',
  });
  const route = useRoute();
  const router = useRouter();
  const { showToast } = useToast();
  const { mutateAsync: createCurrencyPaymentLinkAsyncMutation } =
    useCreateCurrencyPaymentLinkMutation();

  const sparks: Spark[] = [
    { id: uniqueId(), token: 1000, usd: 1 },
    { id: uniqueId(), token: 5000, usd: 5 },
    { id: uniqueId(), token: 10000, usd: 10 },
    { id: uniqueId(), token: 25000, usd: 25 },
    { id: uniqueId(), token: 50000, usd: 50 },
    { id: uniqueId(), token: 100000, usd: 100 },
  ];

  const selectedSpark = ref<Spark | null>(null);

  const onBuySparks = async () => {
    if (selectedSpark.value) {
      const queryParams = {
        ...route.query,
        quantity: selectedSpark.value.token.toString(),
      };
      const newSuccessQueryParams = {
        ...queryParams,
        state: 'success',
      };
      const newFailureQueryParams = {
        ...queryParams,
        state: 'failure',
      };
      const routeSuccessHref = router.resolve({
        ...route,
        query: newSuccessQueryParams,
      });
      const routeFailureHref = router.resolve({
        ...route,
        query: newFailureQueryParams,
      });
      await createCurrencyPaymentLinkAsyncMutation(
        {
          success_url: new URL(routeSuccessHref.href, window.location.origin)
            .href,
          failure_url: new URL(routeFailureHref.href, window.location.origin)
            .href,
          quantity: BigInt(selectedSpark.value.token),
        },
        {
          onSuccess: (data) => {
            if ('Ok' in data) {
              window.open(data.Ok, '_self');
            } else {
              showToast({
                title: t('igc.purchaseDialog.errorPaymentLink'),
                type: 'error',
                durationSeconds: 5,
              });
            }
          },
        },
      );
    } else {
      showToast({
        title: t('igc.purchaseDialog.errorSelection'),
        type: 'error',
        durationSeconds: 3,
      });
    }
  };
</script>

<template>
  <div class="flex flex-col gap-3.5">
    <div
      class="flex flex-col w-full gap-3.5 py-7 max-h-[400px] overflow-y-auto thin-scrollbar"
    >
      <div
        v-for="spark in sparks"
        :key="spark.id"
        class="flex items-center gap-7 mx-auto w-58"
      >
        <base-button
          variant="quaternary"
          additional-classes="relative"
          rounded="rounded-xl"
          @click="selectedSpark = spark"
        >
          <div
            v-if="isEqual(selectedSpark, spark)"
            class="z-1 glowing-effect-orange"
          />
          <div
            class="relative z-2 bg-gray-975 rounded-xl flex items-center justify-center gap-1 w-38 h-18"
          >
            <base-icon name="sparks" size="size-8" />
            <span
              class="text-transparent bg-clip-text bg-gradient-to-t to-[#F9D423] from-[#E14FAD] font-semibold"
            >
              {{ commafy(spark.token) }}
            </span>
          </div>
        </base-button>
        <span class="text-xl font-medium text-gray-300">
          ${{ commafy(spark.usd) }}
        </span>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <base-button variant="primary" size="medium" @click="onBuySparks">
        {{ t('igc.purchaseDialog.button') }}
      </base-button>
    </div>
  </div>
</template>
