import { actor } from '../base';
import { lang } from '@/utils/lang';
import { callWithRetry } from '@/utils/general';
import type {
  CreateContent,
  ContentQuery,
  ContentReaction,
  ActionResultContent,
  ContentTreeView,
} from 'dfx/edge/edge.did';

export const fetchComments = (query: ContentQuery) => {
  return actor()
    .get_content_children(query)
    .then((res) => res) as Promise<ContentTreeView[]>;
};

export const createComment = async (payload: CreateContent) => {
  payload.lang = await lang.getContentLang();
  return actor().create_content(payload) as Promise<ActionResultContent>;
};

export const deleteComment = (id: bigint) => {
  return actor().delete_content(id) as Promise<ActionResultContent>;
};

export const removeComment = (id: bigint) => {
  return actor().remove_content(id) as Promise<ActionResultContent>;
};

export const updateComment = (id: bigint, body: string) => {
  return actor().update_content(
    id,
    body,
    [] /* tags */,
    [] /* disable comments */,
    [] /* nsfw */,
    [] /* poll options */,
  ) as Promise<ActionResultContent>;
};

export const reactToComment = async (
  id: bigint,
  reaction?: ContentReaction,
) => {
  const contentReaction = reaction ? [reaction] : [];
  return (await callWithRetry(async () => {
    return await actor().react_to_content(id, contentReaction);
  })) as Promise<ActionResultContent>;
};

export const setNSFWComment = (id: bigint, value: boolean) => {
  if (!actor) {
    throw new Error('Actor is not defined');
  }
  return actor().content_nsfw_set(id, value) as Promise<ActionResultContent>;
};
