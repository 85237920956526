<script setup lang="ts">
  import { useDialog } from '@/shared/model';
  import { trackEvent } from '@/utils';
  import { ContentWithTitle } from '@/shared/ui/base-dialog';
  import { useAuth } from '../../model/composables/use-auth';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ useScope: 'global' });
  const { closeDialog } = useDialog();
  const {
    login,
    authEntity,
    resetAuthFormValues,
    resetAuthEntity,
    showLoginSignUpDialog,
    updateAuthManager,
  } = useAuth();

  const onSignInWithThisAccount = () => {
    trackEvent(
      'user_action',
      'signup',
      'user_clicked_signin_with_this_account_button',
    );
    authEntity.value.form.type = 'login';
    login('credentials');
    if (authEntity.value.context === 'dialog') {
      closeDialog();
    }
  };

  const onClickBack = () => {
    resetAuthEntity();
    if (authEntity.value.context === 'dialog') {
      showLoginSignUpDialog(authEntity.value.form.type);
    } else {
      updateAuthManager('page', { name: 'login-signup', type: 'signup' });
    }
  };

  const onUseDifferentWallet = () => {
    if (authEntity.value.context === 'dialog') {
      closeDialog();
    }
    trackEvent(
      'user_action',
      'signup',
      'user_clicked_use_a_different_wallet_button',
    );
    resetAuthFormValues();
    login('solana-wallets');
  };
</script>

<template>
  <base-button
    variant="link"
    custom-classes="absolute top-10 left-9"
    @click="onClickBack"
  >
    <base-icon name="chevron-left" size="size-5" />
  </base-button>
  <content-with-title>
    <template #title>
      {{ t('wallets.unsuccessfulPairing.identity.walletAlreadyPaired') }}
    </template>
    <p>
      {{ t('wallets.unsuccessfulPairing.identity.walletAlreadyAssociated') }}
      <span class="ml-1 text-white">{{ authEntity.username }}.</span>
    </p>
    <template #actions>
      <base-button
        variant="primary"
        size="full"
        additional-classes="h-10"
        rounded="rounded-2xl"
        @click="onSignInWithThisAccount"
      >
        <span class="text-gray-200 leading-6 tracking-[0.08px]">
          {{ t('wallets.unsuccessfulPairing.identity.signinWithThisAccount') }}
        </span>
      </base-button>
      <base-button
        variant="link"
        size="full"
        class="!py-0 text-gray-200"
        @click="onUseDifferentWallet"
      >
        {{ t('wallets.unsuccessfulPairing.inApp.useDifferentWallet') }}
      </base-button>
    </template>
  </content-with-title>
</template>
