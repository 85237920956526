<script setup lang="ts">
  import { computed } from 'vue';
  import { storeToRefs } from 'pinia';
  import GroupedItem from './GroupedItem.vue';
  import Item from './Item.vue';
  import { useNotificationStore } from '../model/store/store';
  import { CustomDynamicScrollerItem } from '@/shared/ui/virtual-scroller';
  import { useNotificationSettingStore } from '@/entities/user';

  const MIN_ITEM_SIZE = 160;
  const BUFFER_PX = 1000;

  const notificationStore = useNotificationStore();
  const { state } = storeToRefs(notificationStore);

  // Notification Settings store
  const notificationSettingStore = useNotificationSettingStore();
  const { groupNotifications } = storeToRefs(notificationSettingStore);

  const computedNotifications = computed(() => {
    return state.value.notifications.map((notification) => {
      return {
        ...notification,
        idAsString: notification.notification_id.toString(),
      };
    });
  });
</script>

<template>
  <dynamic-scroller
    class="overflow-y-auto thin-scrollbar h-full flex-1"
    :items="computedNotifications"
    key-field="idAsString"
    :min-item-size="MIN_ITEM_SIZE"
    :buffer="BUFFER_PX"
    @scroll-end="notificationStore.fetchNotifications()"
  >
    <template #default="{ item: notification, active }">
      <custom-dynamic-scroller-item
        :id="notification.idAsString"
        :item="notification"
        :active="active"
      >
        <component
          :is="groupNotifications ? GroupedItem : Item"
          :key="notification.idAsString"
          :notification="notification"
        />
      </custom-dynamic-scroller-item>
    </template>
  </dynamic-scroller>
</template>
