<script lang="ts" setup>
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useI18n } from 'vue-i18n';
  import HeaderDialog from '../../ui/Header.vue';

  const { t } = useI18n({
    useScope: 'global',
  });
</script>

<template>
  <header-dialog class="-top-[5%] md:-top-24">
    <img
      :src="
        fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/rotating-black-hole.gif`,
        )
      "
      :alt="t('onboarding.joinPortals.title')"
      class="w-[314px] h-auto"
    />
  </header-dialog>
</template>
