import { dscvrIcApi } from '@/shared/api';
import type { Principal } from '@dfinity/principal';
import { useMutation } from '@tanstack/vue-query';

export const useSetUserRankMutation = () => {
  return useMutation({
    mutationKey: ['set-user-rank-mutation'],
    mutationFn: (params: { id: Principal; rank: bigint }) =>
      dscvrIcApi.user.setUserRank(params.id, params.rank),
  });
};
