import { parseNFTUrl } from '@/utils';
import { Principal } from '@dfinity/principal';
import type { MetaType, NFTKindV2, NFTUpdateView } from 'dfx/edge/edge.did';
import type { NonFungibleToken, SolanaNonFungibleToken } from '../types';

const getMetaType = (token: SolanaNonFungibleToken): MetaType => {
  if (token.model_url) return { Model: token.model_url };
  if (token.other_url) return { Other: token.other_url };
  if (token.audio_url) return { Audio: token.audio_url };
  if (token.video_url) return { Video: token.video_url };
  return { Image: token.image_url };
};

const getMetaUrl = (token: SolanaNonFungibleToken): string => {
  return token.audio_url
    ? token.audio_url
    : token.video_url
    ? token.video_url
    : token.image_url;
};

export const buildNftUpdateView = (token: NonFungibleToken): NFTUpdateView => {
  let nftUpdateView: NFTUpdateView = {
    kind: [],
    meta: [],
  };
  if (token.chain === 'sol') {
    const kind = {
      MultiChain: {
        chain: [
          {
            Solana: null,
          },
        ],
        token_id: token.token_id ? [token.token_id] : [],
        nft_id: [token.nft_id],
        meta_type: getMetaType(token) ? [getMetaType(token)] : [],
        last_verified: BigInt(1),
        // contract_address is actually metaplex mint address for solana keying by simplehash
        contract_address: token.collection.metaplex_mint ?? '',
      },
    } as NFTKindV2;
    nftUpdateView.kind = [kind];
    // meta should be a link to the pfp asset
    nftUpdateView.meta = [getMetaUrl(token)];
  } else if (token.chain === 'icp') {
    const parsedNftUrl = parseNFTUrl(token.tokenUrl);
    if (parsedNftUrl?.nft_id) {
      const kind = {
        InternetComputer: {
          nft_id: parsedNftUrl.nft_id,
          canister_id: Principal.fromText(parsedNftUrl.canister_id),
        },
      } as NFTKindV2;
      nftUpdateView.kind = [kind];
      nftUpdateView.meta = [token.tokenUrl];
    }
  }
  return nftUpdateView;
};
