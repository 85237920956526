export interface ClampState {
  htmlResult: string;
  htmlResultWithOverflowMarker: string;
  remainingHtml: string;
  openTags: string[];
  clamped: boolean;
  charactersCount: number;
  lineBreaksCount: number;
}

export interface ClampOptions {
  maxCharacters: number;
  maxLineBreaks?: number;
  overflowMarker?: string;
}

export const textBeforeBeginTagRegExp =
  /^(?<plainText>[^<>]*?)<(?<tag>[a-zA-Z0-9-]+)(\s+[^>]*?)?(?<selfClosed>\/)?>/;
export const textBeforeEndTagRegExp =
  /^(?<plainText>[^<>]*?)<\/(?<tag>[a-zA-Z0-9-]+)>/;
export const textAtEndRegExp = /(?<plainText>[^<>]*?)$/;
const endsWithBeginTagRegExp =
  /<(?<tag>[a-zA-Z0-9-]+)(\s+[^>]*?)?(?<selfClosed>\/)?>$/;
const endsWithEndTagRegExp = /<\/(?<tag>[a-zA-Z0-9-]+)>$/;
const tagWithinTextRegExp =
  /<(?<tag>[a-zA-Z0-9-]+)(\s+[^>]*?)?>(?<plainText>[^<>]*?)<\/\k<tag>>/;
const lineBreakTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'ul',
  'ol',
  'li',
  'br',
  'li',
  'img',
  'video',
  'div',
];

export const isLineBreakTag = (tag: string): boolean => {
  return lineBreakTags.includes(tag);
};

export const closeOpenTags = (openTags: string[]): string => {
  const tags = openTags.reverse().reduce((acum, tag) => `${acum}</${tag}>`, '');
  openTags = [];
  return tags;
};

/**
 *
 * @param html
 */
export function initClampState(html: string): ClampState {
  const clampState: ClampState = {
    htmlResult: '',
    htmlResultWithOverflowMarker: '',
    remainingHtml: html,
    clamped: false,
    charactersCount: 0,
    lineBreaksCount: 0,
    openTags: [],
  };

  return clampState;
}

/**
 *
 * @param remainingHtml
 */
export function hasRemainingPlainText(remainingHtml: string) {
  const textBeforeBeginTagMatch = remainingHtml.match(textBeforeBeginTagRegExp);
  if (
    textBeforeBeginTagMatch &&
    textBeforeBeginTagMatch.groups?.plainText.length
  ) {
    return true;
  }

  const textBeforeEndTagMatch = remainingHtml.match(textBeforeEndTagRegExp);
  if (textBeforeEndTagMatch && textBeforeEndTagMatch.groups?.plainText.length) {
    return true;
  }

  const textAtEndMatch = remainingHtml.match(textAtEndRegExp);
  if (textAtEndMatch && textAtEndMatch.groups?.plainText.length) {
    return true;
  }

  const tagWithinTextMatch = remainingHtml.match(tagWithinTextRegExp);
  if (tagWithinTextMatch && tagWithinTextMatch.groups?.plainText.length) {
    return true;
  }

  return false;
}

function clampTextKeepingWordsComplete(
  plainText: string,
  breakPoint: number,
): string {
  const cutoffText = plainText.substring(0, breakPoint);
  const leftoverText = plainText.substring(breakPoint);
  const isMidWord = cutoffText.match(/\w$/) && leftoverText.match(/^\w/);
  if (!isMidWord) {
    return cutoffText;
  }

  const lastSpaceIndex = cutoffText.lastIndexOf(' ');
  const lastSlashIndex = cutoffText.lastIndexOf('/');
  if (lastSpaceIndex > 0) {
    return cutoffText.substring(0, lastSpaceIndex);
  } else if (lastSlashIndex > 0) {
    return cutoffText.substring(0, lastSlashIndex);
  }

  return '';
}

/**
 *
 * @param clampState
 * @param maxCharacters
 * @param plainText
 * @param fullMatch
 */
export function appendPlainText(
  clampState: ClampState,
  maxCharacters: number,
  plainText: string,
  fullMatch?: RegExpExecArray,
) {
  const matchedHtmlIndex = fullMatch ? fullMatch.index : 0;
  let matchedHtml = fullMatch ? fullMatch[0] : plainText;
  let textToAppend = plainText;

  if (clampState.charactersCount + plainText.length >= maxCharacters) {
    const breakPoint = maxCharacters - clampState.charactersCount;
    textToAppend = clampTextKeepingWordsComplete(plainText, breakPoint);
    matchedHtml = textToAppend;
    clampState.clamped = true;
  }

  clampState.charactersCount += textToAppend.length;
  clampState.htmlResult += matchedHtml;
  clampState.remainingHtml = clampState.remainingHtml.substring(
    matchedHtmlIndex + matchedHtml.length,
  );
}

function closeOpenedAnchor(clampState: ClampState): ClampState {
  const indexOfAnchor = clampState.openTags.indexOf('a');
  if (indexOfAnchor >= 0) {
    const tagsFromAnchor = clampState.openTags.splice(indexOfAnchor);
    const closedAnchorTags = closeOpenTags(tagsFromAnchor);
    clampState.htmlResult += closedAnchorTags;
    clampState.htmlResultWithOverflowMarker += closedAnchorTags;
  }
  return clampState;
}

/**
 *
 * @param clampState
 * @param overflowMarker
 */
export function finalizeClamp(
  clampState: ClampState,
  overflowMarker: string,
): ClampState {
  const endsWithBeginTagMatch = clampState.htmlResult.match(
    endsWithBeginTagRegExp,
  );
  if (endsWithBeginTagMatch) {
    const tag = endsWithBeginTagMatch.groups?.tag || '';
    const selfClosedTag = endsWithBeginTagMatch.groups?.selfClosed || '';
    clampState.htmlResult = clampState.htmlResult.substring(
      0,
      endsWithBeginTagMatch.index,
    );
    if (!selfClosedTag && tag !== 'br') {
      clampState.openTags.pop();
    }
  }
  let endsWithEndTagMatch = clampState.htmlResult.match(endsWithEndTagRegExp);
  while (endsWithEndTagMatch) {
    const endTag = endsWithEndTagMatch.groups?.tag || '';
    if (!isLineBreakTag(endTag)) {
      break;
    }
    clampState.htmlResult = clampState.htmlResult.substring(
      0,
      endsWithEndTagMatch.index,
    );
    clampState.openTags.push(endTag);
    endsWithEndTagMatch = clampState.htmlResult.match(endsWithEndTagRegExp);
  }

  clampState = closeOpenedAnchor(clampState);

  const useOverflowMarker = hasRemainingPlainText(clampState.remainingHtml);
  clampState.htmlResultWithOverflowMarker = clampState.htmlResult;
  if (useOverflowMarker) {
    clampState.htmlResult += overflowMarker;
  }
  const closedOpenTags = closeOpenTags(clampState.openTags);
  clampState.clamped = useOverflowMarker;
  clampState.htmlResult += closedOpenTags;
  clampState.htmlResultWithOverflowMarker += closedOpenTags;

  return clampState;
}
