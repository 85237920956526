<template>
  <div :class="'relative flex-initial ' + extraClasses">
    <div
      v-click-outside="() => (expanded = false)"
      class="bg-gray-900 hover:bg-gray-700 flex items-center mr-4 px-4 md:mr-0 py-2.5 rounded-md cursor-pointer"
      @click="expanded = !expanded"
    >
      <div class="flex-grow text-sm font-medium">
        {{ selectedOption ? selectedOption.label : 'hot' }}
      </div>
      <svg
        class="w-3 ml-2"
        viewBox="0 0 10 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.20711 0C0.761654 0 0.538571 0.538571 0.853553 0.853553L4.64645 4.64645C4.84171 4.84171 5.15829 4.84171 5.35355 4.64645L9.14645 0.853553C9.46143 0.53857 9.23835 0 8.79289 0H1.20711Z"
          fill="white"
        />
      </svg>
    </div>
    <div
      class="absolute left-0 z-10 flex flex-col p-4 pr-32 mt-2 md:left-auto md:right-0 rounded-xl bg-gray-785 gap-y-4"
      :class="{ hidden: !expanded }"
    >
      <label
        v-for="option in options"
        :key="option.id"
        class="flex items-center gap-4 text-sm cursor-pointer"
        :class="{ 'min-w-max': autoWidth }"
        @click="onSelect(option)"
      >
        <span>
          <svg
            v-if="option.id == selected.id"
            class="w-4 h-4"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="10" fill="#DB2C8A" />
            <rect x="5" y="5" width="10" height="10" rx="5" fill="white" />
          </svg>
          <svg
            v-else
            class="w-4 h-4"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="10" fill="#6B7280" />
          </svg>
        </span>
        <span>
          {{ option.label }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'default-dropdown',
    props: {
      selected: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      options: {
        type: Array,
        required: true,
      },
      extraClasses: {
        type: String,
        required: false,
        default: '',
      },
      autoWidth: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['roleClick', 'selected'],
    data() {
      return {
        expanded: false,
        selectedOption: null,
      };
    },
    watch: {
      selected() {
        this.selectedOption = this.selected;
      },
    },
    mounted() {
      this.selectedOption = this.selected;
    },
    methods: {
      onSelect(option) {
        this.selectedOption = option;
        this.expanded = false;
        this.$emit('selected', this.selectedOption);
      },
    },
  };
</script>
