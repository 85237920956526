import { uniqWith } from 'lodash-es';
import type { dscvrApi } from '@/shared/api';
import type {
  IcpFungibleToken,
  IcpNonFungibleToken,
  SolanaFungibleToken,
  SolanaNonFungibleToken,
} from '../types';
import { extendIcpTokenStandard } from './extend-token';

const compareIcpFT = (a: IcpFungibleToken, b: IcpFungibleToken) => {
  return a.symbol === b.symbol && a.name === b.name;
};

const compareIcpNFT = (a: IcpNonFungibleToken, b: IcpNonFungibleToken) => {
  return a.tokenIndex === b.tokenIndex && a.cid === b.cid;
};

const compareSolanaFT = (a: SolanaFungibleToken, b: SolanaFungibleToken) => {
  return (
    a.ownerWalletAddress === b.ownerWalletAddress && a.address === b.address
  );
};

const compareSolanaNFT = (
  a: SolanaNonFungibleToken,
  b: SolanaNonFungibleToken,
) => {
  return a.nft_id === b.nft_id;
};

export const convertResponseIcpFT = (tokens: IcpFungibleToken[]) => {
  return uniqWith(
    tokens
      .map((token) => ({ ...token, chain: 'icp' } as IcpFungibleToken))
      .sort((a, b) => (a.symbol > b.symbol ? 1 : -1)),
    compareIcpFT,
  ).map(extendIcpTokenStandard);
};

export const convertResponseIcpNFT = (tokens: IcpNonFungibleToken[]) => {
  return uniqWith(
    tokens
      .map(
        (token) =>
          ({ ...token, chain: 'icp', address: null } as IcpNonFungibleToken),
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
    compareIcpNFT,
  );
};

export const convertResponseSolanaFT = (
  response?: dscvrApi.wallet.SolanaFungiblesResponse,
): SolanaFungibleToken[] => {
  if (!response) return [];
  const tokens = uniqWith(
    response.data
      .map(({ address, tokens }) =>
        (tokens ?? []).map(
          (token) =>
            ({
              ...token,
              chain: 'sol',
              ownerWalletAddress: address,
            } as SolanaFungibleToken),
        ),
      )
      .flat(),
    compareSolanaFT,
  );

  const aggregatedTokens = Object.values(
    tokens.reduce((acc, token) => {
      const aggregatedBalance = response.meta.aggregation[token.info.symbol];
      if (aggregatedBalance) {
        acc[token.info.symbol] = {
          ...token,
          balance: aggregatedBalance,
          ownerWalletAddress: null,
        };
      }
      return acc;
    }, {} as Record<string, SolanaFungibleToken>),
  );

  return [...tokens, ...aggregatedTokens];
};

export const convertResponseSolanaNFT = (
  items: dscvrApi.wallet.SolanaNonFungiblesResponseWallet[],
) => {
  return uniqWith(
    items
      .map(({ address, nfts }) =>
        (nfts ?? []).map(
          (token) =>
            ({
              ...token,
              chain: 'sol',
              address: token.address ?? null,
              ownerWalletAddress: address,
            } as SolanaNonFungibleToken),
        ),
      )
      .flat(),
    compareSolanaNFT,
  );
};
