import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useScrollStore } from '../../model/store/scroll/store';

const SCROLL_HIDE_THRESHOLD_PX = 10;

export const useDocumentScroll = ({
  hideOnScroll,
  bottomReached,
  bottomOffset,
}: {
  hideOnScroll?: () => void;
  bottomReached?: () => void;
  bottomOffset?: number;
} = {}) => {
  const lastScrollY = ref(0);
  const scrollStore = useScrollStore();

  const {
    y,
    directions,
    isScrolling,
    documentOnScrollStopY,
    distanceToBottom,
  } = storeToRefs(scrollStore);

  if (hideOnScroll) {
    watch(documentOnScrollStopY, (y) => {
      if (Math.abs(lastScrollY.value - y) > SCROLL_HIDE_THRESHOLD_PX) {
        hideOnScroll();
      }
      lastScrollY.value = y;
    });
  }

  const arrivedToBottom = computed(
    () =>
      distanceToBottom.value !== undefined &&
      distanceToBottom.value <=
        document.documentElement.scrollHeight * (bottomOffset ?? 0.03),
  );

  if (bottomReached) {
    watch(arrivedToBottom, (value) => {
      if (value) {
        bottomReached();
      }
    });
  }

  return {
    arrivedToBottom,
    y,
    directions,
    isScrolling,
    documentOnScrollStopY,
  };
};
