<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { tokenManager } from '@/utils/tokens';
  import { Loader } from '@/shared/ui/loader';
  import { useToast } from '@/shared/model';
  import { NFT_TYPE_EXT } from '@/common';
  import type { IcpNonFungibleToken } from '../../types';

  const props = defineProps<{
    token: IcpNonFungibleToken;
  }>();

  const emit = defineEmits<{
    (e: 'success'): void;
    (e: 'close'): void;
  }>();

  const validated = ref(false);
  const isLoading = ref(false);
  const errorText = ref('');
  const walletAddress = ref('');

  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const tokenStandardEXT = computed(
    () => props.token.standard === NFT_TYPE_EXT,
  );

  const reviewTransfer = () => {
    if (validateTransfer()) {
      errorText.value = '';
      validated.value = true;
    }
  };

  const validateTransfer = () => {
    walletAddress.value = walletAddress.value.trim();
    if (
      !tokenManager.validateNFTTransferAddress(props.token, walletAddress.value)
    ) {
      if (tokenStandardEXT.value) {
        errorText.value = t('tokenTransfer.invalidWalletExtErrorMessage');
      } else {
        errorText.value = t('tokenTransfer.invalidWalletErrorMessage');
      }
      return false;
    } else {
      errorText.value = '';
      return true;
    }
  };

  const transfer = async () => {
    if (true || validateTransfer()) {
      isLoading.value = true;
      const transferResults = await tokenManager.transferNFT(
        props.token,
        walletAddress.value,
      );
      if (transferResults.ok) {
        emit('success');
        showToast({
          title: t('tokenTransfer.transferNftSuccessMessage'),
          type: 'success',
          durationSeconds: 3,
        });
      } else {
        showToast({
          title: t('tokenTransfer.transferNftFailedMessage'),
          type: 'error',
          durationSeconds: 3,
        });
      }
      isLoading.value = false;
    }
  };
</script>

<template>
  <div v-if="isLoading" class="relative w-full min-h-48">
    <loader variant="rainbow" border-width="border" size="size-10" />
  </div>
  <div v-else class="relative p-6">
    <div class="relative flex flex-col w-full">
      <div class="relative flex-auto p-6 pt-0">
        <div v-if="!validated">
          <p class="mb-4 text-lg leading-relaxed text-white">
            {{ $t('tokenTransfer.validationNftLegend') }}
          </p>
          <div v-if="errorText" class="text-center text-red-500">
            {{ errorText }}
          </div>
          <input
            v-model="walletAddress"
            class="block w-full p-2 mt-1 text-white bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
            :placeholder="
              tokenStandardEXT
                ? $t('tokenTransfer.walletPlaceholderExt')
                : $t('tokenTransfer.walletPlaceholder')
            "
          />
        </div>
        <div v-else>
          <p class="my-2 text-lg leading-relaxed text-center text-white">
            {{ $t('tokenTransfer.transferNftLegend') }}
          </p>
          <p
            class="my-2 text-lg font-bold leading-relaxed text-center text-white bg-gray-900 border border-indigo-600 text-red"
          >
            <a :href="token.tokenUrl" target="_blank">
              {{ token.tokenIndex }}
            </a>
          </p>
          <p class="text-lg leading-relaxed text-center text-white">to</p>
          <p
            class="px-2 my-2 overflow-hidden text-lg font-bold leading-relaxed text-center text-white bg-gray-900 border border-indigo-600"
          >
            {{ walletAddress }}
          </p>
          <div
            class="px-2 my-2 text-lg font-bold leading-relaxed text-center text-white"
          >
            {{ $t('tokenTransfer.transferWarning') }}
          </div>
        </div>
      </div>

      <div
        class="flex justify-end gap-2 pt-8 w-full border-0 border-t border-gray-300"
      >
        <base-button
          variant="tertiary"
          size="medium"
          @click.stop="$emit('close')"
        >
          {{ $t('cancel') }}
        </base-button>
        <base-button
          v-if="!validated"
          variant="primary"
          size="medium"
          @click="reviewTransfer()"
        >
          {{ $t('tokenTransfer.reviewTransfer') }}
        </base-button>
        <base-button v-else variant="primary" size="medium" @click="transfer()">
          {{ $t('transfer') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
../../types
