<script lang="ts" setup>
  import { useBreakpoint } from '@/shared/model';
  import { Mobile, NonMobile } from './components';
  import CMS from '@/services/cms';
  import { ref } from 'vue';
  import { useIntervalFn } from '@vueuse/core';

  const { isSmallerThanMd } = useBreakpoint();
  const banner = ref<any[] | null>(null);

  useIntervalFn(
    () => {
      CMS.getBanner().then((data) => {
        banner.value = data;
      });
    },
    1000 * 60 * 30,
    { immediate: true, immediateCallback: true },
  );
</script>

<template>
  <template v-if="banner && banner[0]">
    <mobile v-if="isSmallerThanMd" :banner="banner" />
    <non-mobile v-if="!isSmallerThanMd" :banner="banner" />
  </template>
</template>
