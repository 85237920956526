import {
  formatDistanceStrict,
  getUnixTime,
  fromUnixTime,
  isFuture,
  formatDate,
} from 'date-fns';

const convertTimestampToNumber = (timestamp: number | bigint) => {
  let date = timestamp as number;
  if (typeof timestamp === 'bigint') {
    if (timestamp.toString().length <= 13) {
      date = Number(timestamp.toString());
    } else {
      // This is to convert milliseconds in timestamp
      // @ts-ignore
      date = Math.ceil(timestamp.toString() / 1000000);
    }
  }
  return date;
};

const getDateFromTimestamp = (timestamp: number | bigint) => {
  return fromUnixTime(getUnixTime(convertTimestampToNumber(timestamp)));
};

export const formatToTime = (timestamp: number | bigint) => {
  return formatDate(convertTimestampToNumber(timestamp), 'HH:mm a');
};

export const fromNow = (timestamp: number | bigint, trimmedSuffix = false) => {
  const date = getDateFromTimestamp(timestamp);

  let formatted = formatDistanceStrict(date, new Date(), {
    addSuffix: !trimmedSuffix,
  });

  // Adds weeks to the formatted string
  const [time, duration] = formatted.split(' ');
  const days = parseInt(time);
  if (duration === 'days') {
    if (days >= 7 && days <= 13) {
      formatted = `1 week${trimmedSuffix ? '' : ' ago'}`;
    } else if (days >= 14 && days < 21) {
      formatted = `2 weeks${trimmedSuffix ? '' : ' ago'}`;
    } else if (days >= 21 && days < 28) {
      formatted = `3 weeks${trimmedSuffix ? '' : ' ago'}`;
    }
  }

  if (trimmedSuffix) {
    formatted = formatted
      .replace(/ about /g, '')
      .replace('years', 'y')
      .replace('year', 'y')
      .replace('months', 'mo')
      .replace('month', 'mo')
      .replace('weeks', 'w')
      .replace('week', 'w')
      .replace('days', 'd')
      .replace('day', 'd')
      .replace('hours', 'h')
      .replace('hour', 'h')
      .replace('minutes', 'm')
      .replace('minute', 'm')
      .replace('seconds', 's')
      .replace('second', 's');
    formatted = formatted.trim().replace(/ /g, '');

    if (!isFuture(date)) {
      formatted += ' ago';
    }
  }

  return formatted;
};
