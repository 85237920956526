<script setup lang="ts">
  import { ref } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  import type { ContentReaction } from 'dfx/edge/edge.did';
  import { reactions } from '../lib/reactions';
  import { reactionTypeToIconName } from '../lib/reaction-type-to-icon-name';
  import type { ReactionType } from '../types';
  import { computed } from 'vue';
  import type { IconName } from '@/shared/ui/base-icon';

  const props = defineProps<{
    contentReaction?: ContentReaction;
    reactionType?: ReactionType;
    reactionEmoji: IconName | null;
    removeDislike?: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'reacted', reaction?: ReactionType): void;
  }>();

  const form = ref<HTMLElement | null>(null);
  const reactionList = computed(() => {
    if (props.removeDislike) {
      return reactions.filter((r) => r !== 'dislike');
    }
    return reactions;
  });

  const expanded = ref(false);

  const selectReaction = (selectedReactionType?: ReactionType) => {
    expanded.value = false;
    if (!selectedReactionType || selectedReactionType === props.reactionType) {
      emit('reacted');
    } else {
      emit('reacted', selectedReactionType);
    }
  };

  const toggleExpanded = () => {
    expanded.value = !expanded.value;
  };

  onClickOutside(form, () => {
    expanded.value = false;
  });

  defineExpose({
    toggleExpanded,
  });
</script>

<template>
  <form class="flex h-8" ref="form">
    <base-button
      variant="custom"
      custom-classes="rounded-l-xl flex items-center justify-center px-3 py-0 mr-0.5 text-white"
      :class="
        !expanded && !reactionType
          ? 'bg-indigo-600 hover:bg-indigo-500'
          : 'bg-indigo-825'
      "
      @click.prevent.stop="!expanded && selectReaction(reactionType ?? 'like')"
    >
      <base-icon
        v-if="reactionEmoji"
        :name="reactionEmoji"
        class="relative left-0.5 animate-fade"
        size="w-7 h-7"
      />
      <template v-else>
        <base-icon name="heart" class="text-xl animate-fade" size="w-5 h-5" />
        <span
          class="text-sm font-medium ml-2 select-none mr-1 whitespace-nowrap animate-fade hidden md:block"
        >
          Like
        </span>
      </template>
    </base-button>
    <div class="relative w-10">
      <div
        class="absolute h-full rounded-r-xl flex items-center justify-center z-1"
        :class="
          expanded ? 'bg-indigo-900' : 'bg-indigo-800 hover:bg-indigo-500'
        "
      >
        <base-button
          v-show="!expanded"
          variant="custom"
          custom-classes="text-xxs text-white w-full h-full px-3 animate-fade animate-duration-200 overflow-hidden"
          @click.prevent.stop="expanded = true"
        >
          <base-icon name="plus" size="w-3 h-3" class="relative -left-0.5" />
        </base-button>
        <ul
          v-show="expanded"
          class="flex text-md gap-2 px-3 animate-fade-right animate-duration-200"
        >
          <li v-for="reactionItem of reactionList" :key="reactionItem">
            <a
              class="cursor-pointer"
              @click.stop="
                selectReaction(
                  reactionItem !== reactionType ? reactionItem : undefined,
                )
              "
            >
              <div
                :class="
                  reactionItem !== reactionType
                    ? 'transition-all duration-200 hover:scale-[200%] hover:drop-shadow-[0_8px_16px_rgba(0,0,0,0.4)] relative z-1 hover:z-10'
                    : ''
                "
              >
                <base-icon
                  :name="reactionTypeToIconName(reactionItem)"
                  :class="reactionItem === reactionType ? 'opacity-30' : ''"
                  class="relative -left-0.5"
                  size="w-6 h-6"
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>
