<script setup lang="ts">
  import { ref, computed, type Ref } from 'vue';
  import { Tippy } from 'vue-tippy';
  import { useVModel } from '@vueuse/core';
  import sanitize from 'sanitize-html';
  import { sanitizeSettings } from '@/utils';
  import type { CreatePoll } from 'dfx/edge/edge.did';
  import { WysiwygEditor } from '@/components/forms/wysiwyg-editor';
  import { WysiwygEditorStyleMenu } from '@/components/forms/wysiwyg-editor-style-menu';
  import SettingsMenu from './SettingsMenu.vue';
  import CreatePollForm from '@/components/forms/CreatePollForm.vue';
  import MediaUploadEmbeddedModal from '@/components/modal/MediaUploadEmbeddedModal.vue';
  import AddLinkEmbeddedModal from '@/components/modal/AddLinkEmbeddedModal.vue';
  import AddMemeFighterModal from '@/components/modal/AddMemeFighterModal.vue';
  import TagSelector from '@/components/TagSelector.vue';
  import { NsfwTag } from '@/features/post';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useCanvasStore, useFrameStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import { config } from '@/shared/lib';

  const props = defineProps<{
    modelValue: string;
    editing: boolean;
    poll: CreatePoll;
    tags: string[];
    isNsfw: boolean;
    disableComments: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'scroll-into-view'): void;
    (e: 'error', error: string): void;
    (e: 'update:modelValue', value: string): void;
    (e: 'update:tags', tags: string[]): void;
    (e: 'update:isNsfw', value: boolean): void;
    (e: 'update:disable-comments', value: boolean): void;
    (e: 'submit', value: string): void;
  }>();

  const frameStore = useFrameStore();
  const canvasStore = useCanvasStore();
  const { savedUrl: savedFramesUrls } = storeToRefs(frameStore);
  const { savedUrl: savedCanvasUrls } = storeToRefs(canvasStore);
  const editorInput: Ref<InstanceType<typeof WysiwygEditor> | null> = ref(null);
  const bodyModel = useVModel(props, 'modelValue', emit);
  const tagsModel = useVModel(props, 'tags', emit);
  const isNsfwModel = useVModel(props, 'isNsfw', emit);
  const disableCommentsModel = useVModel(props, 'disableComments', emit);
  const postFormSettingsMenu = ref<HTMLElement | null>(null);
  const postFormStyleMenu = ref<HTMLElement | null>(null);
  const tipTapWrapper = ref<HTMLElement | null>(null);
  const isLinkModalOpen = ref(false);
  const isMediaModalOpen = ref(false);
  const isPollModalOpen = ref(false);
  const isMemeFighterOpen = ref(false);
  const isEmpty = computed(() => editorInput.value?.isEmpty);

  const mediaInserted = () => {
    emit('scroll-into-view');
    isMediaModalOpen.value = false;
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      tipTapWrapper.value?.scrollTo({
        top: tipTapWrapper.value.scrollHeight,
      });
    });
  };

  const openMemeFighterModal = () => {
    isMemeFighterOpen.value = true;
    scrollToBottom();
  };

  const openMediaModal = () => {
    isMediaModalOpen.value = true;
    scrollToBottom();
  };

  const openLinkModal = () => {
    isLinkModalOpen.value = true;
    scrollToBottom();
  };

  const openPollModal = () => {
    isPollModalOpen.value = true;
    scrollToBottom();
  };

  const isSavedFrameUrl = (url: string) => {
    return config.ENABLE_FRAMES && savedFramesUrls.value.includes(url);
  };

  const isSavedCanvasUrl = (url: string) => {
    return savedCanvasUrls.value.includes(url);
  };

  const onSubmit = () => {
    // we are removing the frame url from the body before submitting
    const divElement = document.createElement('div');
    divElement.innerHTML = bodyModel.value;
    const aTags = divElement.querySelectorAll('a');
    aTags.forEach((aTag) => {
      const href = aTag.getAttribute('href');
      if (
        href &&
        href === aTag.textContent &&
        (isSavedFrameUrl(href) || isSavedCanvasUrl(href))
      ) {
        aTag.remove();
      }
    });
    bodyModel.value = sanitize(divElement.innerHTML, sanitizeSettings);
    savedFramesUrls.value = [];
    savedCanvasUrls.value = [];
    emit('submit', bodyModel.value);
  };

  defineExpose({
    isEmpty,
    executeEmptyMessageAnimation: () => {
      editorInput.value?.executeEmptyMessageAnimation();
    },
  });
</script>

<template>
  <div
    class="flex-1 flex flex-col justify-between border-b border-white border-opacity-12"
  >
    <div
      ref="tipTapWrapper"
      class="tip-tap px-2 pt-1 pb-3 min-h-60 overflow-y-auto thin-scrollbar"
    >
      <wysiwyg-editor
        ref="editorInput"
        context="feed"
        placeholder="What's on your mind..."
        autofocus
        v-model="bodyModel"
        @media-inserted="mediaInserted"
        @error="(error) => emit('error', error)"
      />
      <create-poll-form
        v-if="isPollModalOpen"
        :poll="poll"
        @close="isPollModalOpen = false"
      />
      <media-upload-embedded-modal
        :open="isMediaModalOpen"
        @upload-files="editorInput?.uploadFiles"
        @paste-image="editorInput?.pasteImage"
        @close="isMediaModalOpen = false"
      />

      <add-meme-fighter-modal
        :open="isMemeFighterOpen"
        @close="isMemeFighterOpen = false"
        @paste-image="editorInput?.pasteImage"
      />

      <add-link-embedded-modal
        :open="isLinkModalOpen"
        :initial-text="editorInput?.getSelectedText()"
        @insert-link="editorInput?.insertLink"
        @embed-content="editorInput?.embedContent"
        @embed-link="editorInput?.embedIframe"
        @close="isLinkModalOpen = false"
      />
    </div>
    <div class="flex items-center">
      <nsfw-tag v-if="isNsfwModel" />
      <tag-selector
        :tags="tagsModel"
        class="rounded-lg h-14 flex-1"
        @set-tags="(value) => (tagsModel = value)"
      />
    </div>
    <slot name="editorFooter" />
  </div>
  <div class="flex flex-col gap-3 md:items-center md:flex-row md:p-2 md:pt-4">
    <div class="flex items-center justify-between flex-1">
      <div class="relative flex items-center gap-1">
        <tippy
          interactive
          trigger="click"
          theme="transparent"
          placement="top-start"
          :arrow="false"
        >
          <base-tooltip content="Style">
            <button
              type="button"
              class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
            >
              <base-icon name="t" class="text-gray-400" size="w-4 h-4" />
            </button>
          </base-tooltip>
          <template #content>
            <wysiwyg-editor-style-menu
              v-if="editorInput?.editor"
              ref="postFormStyleMenu"
              class="style-menu"
              :editor="editorInput?.editor"
            />
          </template>
        </tippy>
        <hr class="w-px bg-white/[0.12] h-6 border-none" />
        <base-tooltip content="Media">
          <button
            type="button"
            class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
            @click="openMediaModal"
          >
            <base-icon name="image" class="text-gray-400" size="w-4 h-4" />
          </button>
        </base-tooltip>
        <base-tooltip content="Meme">
          <button
            type="button"
            class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
            @click="openMemeFighterModal"
          >
            <base-icon
              name="meme-fighter"
              class="text-gray-400"
              size="w-4 h-4"
            />
          </button>
        </base-tooltip>
        <base-tooltip content="Link">
          <button
            type="button"
            class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
            @click="openLinkModal"
          >
            <base-icon name="link-tool" class="text-gray-400" size="w-4 h-4" />
          </button>
        </base-tooltip>
        <base-tooltip content="Poll">
          <button
            type="button"
            class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
            @click="openPollModal"
          >
            <base-icon name="poll-tool" class="text-gray-400" size="w-4 h-4" />
          </button>
        </base-tooltip>
        <hr class="w-px bg-white/[0.12] h-6 border-none" />
        <base-dropdown placement="bottom-start">
          <template #button="{ toggle }">
            <button
              type="button"
              class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white hover:bg-opacity-16"
              @click="toggle"
            >
              <base-icon name="settings" class="text-gray-400" size="w-4 h-4" />
            </button>
          </template>
          <template #content>
            <settings-menu
              ref="postFormSettingsMenu"
              class="settings-menu"
              :editing="editing"
              v-model:is-nsfw="isNsfwModel"
              v-model:disable-comments="disableCommentsModel"
            />
          </template>
        </base-dropdown>
      </div>
    </div>
    <button
      type="button"
      className="px-12 py-3 bg-indigo-850 rounded-md text-sm font-medium btn-primary w-full md:w-auto"
      @click="onSubmit"
    >
      {{ $t('post') }}
    </button>
  </div>
</template>

<style lang="postcss" scoped>
  .tip-tap {
    max-height: calc(100vh - 300px);
  }

  .style-menu,
  .settings-menu {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 0.04)
      ),
      #1c212e;
  }

  .settings-menu {
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.4);
  }
</style>
