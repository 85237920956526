<script lang="ts" setup>
  import type { TextFieldProps } from '../types';
  import { uniqueId } from 'lodash-es';
  import { computed } from 'vue';

  const props = defineProps<TextFieldProps>();
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
    (e: 'blur', value: FocusEvent): void;
  }>();

  const textFieldId = `textfield-${uniqueId()}`;

  const updateValue = (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('update:modelValue', target?.value);
  };
  const inputWrapperStyle = computed(() => {
    return [
      props.disabled && '[&_*]:cursor-default opacity-50',
      props.customWrapperClasses,
      'flex justify-center items-center gap-2 bg-transparent appearance-none border-0 grow p-0 autofill:!bg-red relative',
    ];
  });
  const inputFieldStyle = computed(() => {
    return [
      props.customFieldClasses,
      'bg-transparent appearance-none border-0 grow p-0 autofill:!bg-red form-input',
    ];
  });
</script>

<template>
  <div :class="inputWrapperStyle">
    <slot name="prefix" />
    <textarea
      v-if="multiline"
      v-bind="{ ...$attrs, ...props }"
      :id="textFieldId"
      :value="modelValue?.toString()"
      :rows="rows"
      :class="inputFieldStyle"
      @input="updateValue"
      @blur="$emit('blur', $event)"
    />
    <input
      v-else
      v-bind="{ ...$attrs, ...props }"
      :id="textFieldId"
      :value="modelValue"
      :class="inputFieldStyle"
      @input="updateValue"
      @blur="$emit('blur', $event)"
    />
    <label
      :for="name"
      class="absolute left-0 -translate-y-2/4 pointer-events-none duration-200 ease-in-out text-gray-400"
      :class="multiline ? 'top-[15%]' : 'top-[50%]'"
    >
      {{ label }}
    </label>
    <slot name="suffix" />
  </div>
</template>

<style scoped>
  .form-input:focus {
    outline: none !important;
    border: 0px !important;
    box-shadow: none;
  }
  .form-input:-webkit-autofill,
  .form-input:-webkit-autofill:hover,
  .form-input:-webkit-autofill:focus,
  .form-input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
  .form-input:focus + label,
  .form-input[modelValue]:not([modelValue='']) + label {
    @apply -top-6 -left-4 text-sm text-gray-400;
  }
</style>
