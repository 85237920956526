<script setup lang="ts">
  import { computed, onBeforeMount, toRef } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import InviteDialogContents from './InviteDialogContents.vue';
  import { useUser } from '@/entities/user';
  import { getPortalIcon, useGetPortalChainTypeQuery } from '@/entities/portal';
  import { formatToUnits } from '@/shared/lib';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const { data: portalChainType } = useGetPortalChainTypeQuery(
    toRef(() => props.portalView),
  );
  const { getReferralCode, referralCode } = useUser();

  const inviteLink = computed(() => {
    const portalChain =
      portalChainType &&
      portalChainType.value &&
      'Solana' in portalChainType.value
        ? 'sol.'
        : '';
    return `https://${portalChain}dscvr.one/join/${props.portalView.slug}?ur=${referralCode.value}`;
  });

  onBeforeMount(async () => {
    await getReferralCode();
  });
</script>
<template>
  <div class="p-8 pt-4 md:p-0">
    <invite-dialog-contents
      :referent="portalView"
      :invite-link="inviteLink"
      :name="portalView.name"
    >
      <template #avatar>
        <img
          alt="Portal Icon"
          class="w-16 h-16 md:w-[148px] md:h-[148px] rounded-xl"
          v-lazy="getPortalIcon(portalView)"
        />
      </template>
      <template #name>
        {{ portalView.name }}
      </template>
      <template #description>
        {{ portalView.description }}
      </template>
      <template #membersOrFollowers>
        {{ formatToUnits(portalView.member_count) }}
      </template>
      <template #posts>
        {{ formatToUnits(portalView.content_count) }}
      </template>
    </invite-dialog-contents>
  </div>
</template>
