import type {
  EmptyResult,
  NotificationPageQuery,
  NotificationResult,
  ReadNotificationsResult,
} from 'dfx/edge/edge.did';
import { actor } from '../base';

export const getNotifications = (query: NotificationPageQuery) => {
  return actor().get_notification_page(query) as Promise<NotificationResult>;
};
export const setReadNotifications = (notifications: bigint[]) => {
  return actor().mark_notifications_read(
    notifications,
  ) as Promise<ReadNotificationsResult>;
};
export const setReadAllNotifications = () => {
  return actor().mark_all_notifications_read() as Promise<EmptyResult>;
};
