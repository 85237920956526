<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import type { PortalView } from 'dfx/edge/edge.did';
  import {
    BaseTableView,
    BaseTableHeaderCell,
    type SortColumnDirection,
    useSorting,
  } from '@/shared/ui/base-table-view';
  import { portalSortFieldTypes, type PortalSortField } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';
  import RowItem from './RowItem.vue';

  const props = defineProps<{
    portals: PortalView[];
    loading: boolean;
    sortBy: PortalSortField;
    sortDirection: SortColumnDirection;
  }>();

  const emit = defineEmits<{
    (e: 'update:sortBy', value: PortalSortField): void;
    (e: 'update:sortDirection', value: SortColumnDirection): void;
    (e: 'sortChanged'): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const { sortFields, changeSort } = useSorting(portalSortFieldTypes, {
    defaultField: props.sortBy,
    defaultDirection: props.sortDirection,
    onChange: (field, direction) => {
      emit('update:sortBy', field);
      emit('update:sortDirection', direction);
      emit('sortChanged');
    },
  });

  const sortColumns: {
    label: string;
    widthClass: string;
    sortField?: PortalSortField;
  }[] = [
    {
      label: t('portal'),
      widthClass: 'w-50',
      sortField: 'name',
    },
    {
      label: t('description'),
      widthClass: 'w-full',
    },
    {
      label: t('members'),
      widthClass: 'w-28',
      sortField: 'memberCount',
    },
    {
      label: t('posts'),
      widthClass: 'w-28',
      sortField: 'contentCount',
    },
    {
      label: '',
      widthClass: 'w-28',
    },
  ];
</script>

<template>
  <base-table-view :items="portals">
    <template #header>
      <template v-for="sortColumn in sortColumns" :key="sortColumn.sortField">
        <base-table-header-cell
          v-if="sortColumn.sortField"
          :class="sortColumn.widthClass"
          :sort-active="sortFields.get(sortColumn.sortField)?.active"
          :sort-direction="sortFields.get(sortColumn.sortField)?.direction"
          @update:sort-direction="changeSort(sortColumn.sortField, $event)"
        >
          <template #sortLabel>{{ sortColumn.label }}</template>
        </base-table-header-cell>
        <base-table-header-cell v-else :class="sortColumn.widthClass">
          {{ sortColumn.label }}
        </base-table-header-cell>
      </template>
    </template>
    <template #item="{ item }">
      <row-item :portal-view="item" />
    </template>
  </base-table-view>

  <div v-if="loading" class="relative w-full min-h-48">
    <loader variant="rainbow" border-width="border" size="w-10 h-10" />
  </div>
</template>
