<template>
  <div>
    <div
      v-if="showReportModal"
      class="overflow-x-hidden overflow-y-auto thin-scrollbar fixed inset-0 z-30 outline-none focus:outline-none justify-center items-center flex"
    >
      <div
        v-if="showReportModal"
        class="opacity-50 fixed inset-0 bg-black"
        @click="closeModal()"
      />
      <div class="z-50 relative w-auto my-6 mx-0 md:mx-auto">
        <!--content-->
        <div
          class="rounded-xl shadow-lg relative flex flex-col bg-gray-950 outline-none focus:outline-none w-screen md:w-112"
        >
          <form class="rounded-xl" @submit.prevent="onSubmit">
            <!--header-->
            <div class="flex items-center justify-center p-5 mx-4 relative">
              <button class="text-white absolute left-0" @click="closeModal()">
                <base-icon name="close" size="h-3 w-3" />
              </button>

              <h3 class="text-lg font-semibold text-white text-center w-full">
                Report
              </h3>
            </div>

            <!--body-->
            <div class="relative p-4 flex-auto">
              <p
                v-if="reportSubmitted"
                class="text-white font-semibold leading-relaxed flex flex-col justify-center text-center"
              >
                <base-icon name="circle-check" class="flex self-center mb-5" />
                Thanks for looking out for the community!
                <!-- <img src="https://media.giphy.com/media/RgfGmnVvt8Pfy/giphy.gif" alt="" /> -->
              </p>

              <div v-if="!reportSubmitted">
                <p class="pb-3 text-white leading-relaxed">
                  What is the issue?
                </p>
                <error-display :local-errors="localErrors" />
                <textarea
                  v-model="message"
                  class="mt-1 text-white block w-fill rounded-md bg-gray-900 border-transparent focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
                  rows="3"
                  placeholder="Please explain what is wrong with this content."
                ></textarea>
              </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-4 gap-3">
              <button
                v-if="!reportSubmitted"
                class="bg-gray-700 hover:bg-gray-600 text-white font-bold uppercase text-sm px-6 py-3 rounded-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style="transition: all 0.15s ease"
                @click="closeModal()"
              >
                Cancel
              </button>
              <!-- <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="closeModal()">
              Login
            </button> -->

              <button
                v-if="!reportSubmitted"
                class="text-white bg-red-600 hover:bg-red-500 font-bold uppercase px-6 py-3 rounded-lg text-sm outline-none focus:outline-none mr-1 mb-1"
                style="transition: all 0.15s ease"
                type="submit"
              >
                Report
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ErrorDisplay from './ErrorDisplay.vue';
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '../store/action-types';
  import { MutationTypes } from '../store/mutation-types';

  export default {
    name: 'report-modal',
    components: {
      ErrorDisplay,
    },
    props: {
      contentId: {
        type: BigInt,
        default() {
          return 1n;
        },
      },
    },
    data() {
      return {
        reportSubmitted: false,
        message: '',
        localErrors: [],
      };
    },
    computed: {
      ...mapGetters({
        currentReportContentId: 'currentReportContentId',
        showReportModal: 'showReportModal',
      }),
    },
    watch: {
      showReportModal: function () {
        this.message = '';
        this.localErrors = [];
        this.reportSubmitted = false;
      },
    },
    methods: {
      onSubmit() {
        if (this.isValid()) {
          this.$store
            .dispatch(`reports/${ActionTypes.CREATE_REPORT}`, {
              content_id: this.currentReportContentId,
              report_type: 'content',
              message: this.message,
            })
            .then(() => {
              this.reportSubmitted = true;
            });
        }
      },
      isValid() {
        this.localErrors = [];
        if (!this.message.replace(/\s/g, '').length) {
          this.localErrors.push({
            field: 'message',
            errors: ['You must say what this report is about'],
          });
        }
        return this.localErrors.length == 0;
      },
      closeModal: function () {
        if (this.showReportModal) {
          this.$store.commit(MutationTypes.SET_REPORT_MODAL, {
            value: false,
            contentId: 0,
          });
        }
      },
      openModal: function () {
        this.$store.commit(MutationTypes.SET_REPORT_MODAL, {
          value: true,
          contentId: 0,
        });
      },
    },
  };
</script>
