<script setup lang="ts">
  import { toRefs, ref, computed } from 'vue';
  import { PORTAL_CONTENT } from '@/common';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useShareDialog } from '@/shared/model';
  import { CoverPhoto } from '@/shared/ui/cover-photo';
  import { getDisplayAmount } from '@/shared/lib';
  import ReadMore from '@/components/ReadMore.vue';
  import { CreateLiveRoomButton } from '@/features/live/create-room';
  import { SocialLinksSummary } from '@/features/social-links';
  import {
    JoinPortalButton,
    coverGradients,
    getPortalIcon,
  } from '@/entities/portal';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  defineEmits(['portal-update']);

  const { openSharePortalProfile } = useShareDialog();

  const socialLinks = computed(() => {
    if (!props.portalView.info.length) {
      return [];
    }
    return props.portalView.info[0].links.map(({ href }) => href);
  });

  const showMore = ref(false);

  const onSharePortal = () => {
    showMore.value = false;
    openSharePortalProfile(props.portalView);
  };
</script>

<template>
  <router-link
    v-if="portalView"
    class="no-underline not-prose"
    :to="{
      name: PORTAL_CONTENT,
      params: { slug: portalView.slug },
    }"
  >
    <div
      class="relative z-10 text-white shadow-lg bg-gray-950 md:pb-5 md:rounded-xl"
    >
      <div class="w-full mb-2 bg-gray-700 h-[160px] sm:mb-0 md:rounded-t-xl">
        <cover-photo
          :src="portalView.info[0]?.cover_photo[0]"
          :gradients="coverGradients"
        />
      </div>

      <div
        class="flex flex-col justify-center w-full px-4 pt-2 sm:items-end sm:pb-2 sm:mb-2 sm:flex-row sm:items-bottom sm:justify-between sm:absolute topInfoActionBar"
      >
        <div class="flex items-end justify-start">
          <img
            alt="icon"
            class="mr-4 border-gray-300 size-16 rounded-xl sm:size-42 border-1"
            :src="getPortalIcon(portalView)"
          />

          <div class="flex flex-col items-start justify-start">
            <p class="my-0 text-2xl font-bold md:text-xxxl">
              {{ portalView.name }}
            </p>

            <div class="flex flex-col items-center mt-2 md:mt-1">
              <div
                class="inline-flex items-center order-2 gap-1 px-1 py-0.5 text-gray-300 bg-gray-700 rounded-md lg:order-1 lg:bg-transparent"
              >
                <base-icon name="users" size="size-5" class="inline" />

                <p class="text-smaller">
                  {{ getDisplayAmount(portalView.member_count, true) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <read-more
          class="my-4 text-sm text-gray-300 sm:hidden"
          :context="portalView.description"
        />

        <div class="flex flex-row items-center justify-end">
          <create-live-room-button
            :portal-view="portalView"
            class="relative mr-3"
          />

          <div class="[&>button]:text-base">
            <join-portal-button :portal-view="portalView" />
          </div>

          <base-dropdown>
            <template #button="{ toggle }">
              <base-button variant="secondary" size="small" @click="toggle">
                <base-icon name="ellipsis" size="size-5" />
              </base-button>
            </template>
            <template #content="{ hide }">
              <span
                class="flex items-center gap-2 py-5 pl-5 pr-4 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200"
                @click="hide(), onSharePortal()"
              >
                <base-icon name="share" size="size-5" />
                Share Portal
              </span>
            </template>
          </base-dropdown>
        </div>
      </div>
      <div class="hidden size-10 sm:size-20 sm:block sm:mt-2"></div>
      <div class="flex justify-end px-10 pt-4 pb-4 md:pb-0">
        <social-links-summary :links="socialLinks" />
      </div>
    </div>
  </router-link>
</template>

<style scoped>
  @media screen and (min-width: 640px) {
    .topInfoActionBar {
      transform: translateY(-50%);
    }
  }
</style>
