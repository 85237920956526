import { Token } from '@/utils/tokens/standards/token/token';
import type { IcpFungibleToken, IcpNonFungibleToken } from '../types';

export const extendIcpTokenStandard = <
  T extends IcpFungibleToken | IcpNonFungibleToken,
>(
  token: T,
) => {
  return new Token(token) as Token & T;
};

export const convertLamportToSol = (value: bigint | number) => {
  return new Token({ decimals: 9 }).toDisplayAmount(value);
};

export const parseNftName = (sourceName: string) => {
  const hashIndex = sourceName?.indexOf('#');
  const name = hashIndex === -1 ? sourceName : sourceName.slice(0, hashIndex);
  const number = hashIndex === -1 ? undefined : sourceName.slice(hashIndex);
  return {
    name,
    number,
    numberOrName: number ?? name,
  };
};
