<template>
  <div class="text-white w-14 h-14">
    <div class="transform translate-x-1.5 translate-y-6 spinner-big"></div>
  </div>
</template>

<script>
  export default {
    name: 'spinner-loader',
  };
</script>
