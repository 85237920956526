import { apiInstance } from '../base';
import type {
  FramePostRequest,
  FramePostResponse,
  FrameTransactionResponse,
} from './types';

export {
  type FramePostRequestActionType,
  type FramePostRequest,
  type FramePostResponse,
  type FrameTransactionResponse,
  type DscvrUntrustedData,
} from './types';

const BASE_URL = '/proxy';

export const getFrame = async (
  url: string,
  customHeaders?: Record<string, string>,
) => {
  const response = await apiInstance.get(`${BASE_URL}/embed`, {
    params: {
      url: url,
    },
    headers: {
      ...customHeaders,
    },
  });
  if (response.status !== 200 || !response.data) {
    throw new Error('Failed to get frame');
  }
  return response.data as string;
};

export const postFrame = async (
  request: FramePostRequest,
  customHeaders?: Record<string, string>,
): Promise<FramePostResponse> => {
  const response = await apiInstance.post(`${BASE_URL}/frame_action`, request, {
    headers: {
      'Content-type': 'application/json',
      ...customHeaders,
    },
  });

  if (request.actionType === 'mint') {
    throw new Error('Mint action not supported');
  }

  if (request.actionType === 'tx') {
    if (response.status !== 200 || !response.data) {
      throw new Error('Invalid frame response');
    }

    return {
      type: 'tx',
      transactionResponse: response.data as FrameTransactionResponse,
    };
  }

  if (request.actionType === 'post_redirect') {
    if (response.status !== 200 || !response.headers.location) {
      throw new Error('Invalid frame response');
    }

    return {
      type: 'redirect',
      location: response.headers.location,
    };
  }

  if (response.status !== 200 || !response.data) {
    throw new Error(`Failed to get frame ${response.status}`);
  }
  return {
    type: 'post',
    html: response.data as string,
  };
};
