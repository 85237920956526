import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';

export const useClaimLootboxItemMutation = () => {
  return useMutation({
    mutationKey: ['claim-lootbox-item-mutation'],
    mutationFn: ({
      lootboxId,
      tokenType,
    }: {
      lootboxId: string;
      tokenType: number;
    }) => dscvrApi.lootbox.claimLootboxItem(lootboxId, tokenType),
  });
};
