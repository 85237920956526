<script setup lang="ts">
  import type { UserView } from 'dfx/edge/edge.did';
  import { USER_CONTENT } from '@/common';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { formatToUnits } from '@/shared/lib';
  import { useUser } from '../model/composables/use-user';
  import StreakCount from '../components/StreakCount.vue';
  import { coverGradients } from '../lib/cover-gradients';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  defineProps<{
    user: UserView;
  }>();

  const { getCreationDate, getFormattedTokens } = useUser();
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: USER_CONTENT,
      params: { username: user.username },
    }"
    custom-classes="flex justify-center items-center flex-row sm:flex-col lg:flex-row gap-6 h-full rounded-xl p-6 md:p-10 lg:p-6 shadow-light border border-gray-800 overflow-hidden group"
  >
    <div
      class="flex flex-col flex-1 sm:flex-initial sm:w-full justify-center items-center gap-4 lg:flex-1 h-44 rounded-xl relative overflow-hidden"
    >
      <div
        class="absolute bg-cover bg-center inset-0 group-hover:-inset-4 transition-all duration-500 before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/0 before:via-black/50 before:to-black/80"
        :class="{
          'group-hover:hue-rotate-180': !user.cover_photo[0],
        }"
      >
        <cover-photo :src="user.cover_photo[0]" :gradients="coverGradients" />
      </div>
      <user-avatar
        v-if="user"
        :item="user"
        size="w-15 h-15"
        class="drop-shadow-light z-1"
      />
      <span
        class="text-lg font-semibold leading-5 truncate text-white text-center px-4 w-full z-1"
        :title="user.username"
      >
        {{ user.username }}
      </span>
      <div class="flex items-center gap-2 z-1">
        <base-icon name="dscvr" size="w-4 h-4" />
        <span class="font-medium text-sm text-white">
          {{ getFormattedTokens(user.rights, true) }}
        </span>
      </div>
    </div>
    <div class="flex-1 flex flex-col justify-between gap-6 w-full min-w-0">
      <p
        v-if="user.bio"
        :title="user.bio"
        class="clamp-bio max-h-6 lg:max-h-12 text-sm font-normal"
      >
        {{ user.bio }}
      </p>
      <p v-else class="clamp-bio max-h-12 text-sm font-normal">
        {{ $t('memberSince') }}
        {{ getCreationDate(user.created_at) }}
      </p>
      <div class="flex gap-4 text-sm font-medium">
        <div class="flex gap-1 items-center">
          <base-icon name="outlined-users2" size="w-4 h-4" />
          <span>{{ formatToUnits(user.followers) }}</span>
          <span class="hidden sm:block">{{ $t('followers') }}</span>
        </div>

        <div class="flex gap-1 items-center">
          <base-icon name="vuesax-linear-notification-status" size="w-4 h-4" />
          <span>{{ formatToUnits(user.num_posts) }}</span>
          <span class="hidden sm:block">{{ $t('posts') }}</span>
        </div>
      </div>
      <streak-count :count="Number(user.active_streak)" />
      <div class="flex">
        <follow-user-button size="x-small" :user="user" show-label-on-mobile />
      </div>
    </div>
  </base-button>
</template>

<style scoped>
  .clamp-bio {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      -webkit-line-clamp: 1;
    }
  }
</style>
