import ColorThief from 'colorthief';
type RGB = [number, number, number];

const colorThief = new ColorThief();

export const rgbToHex = (rgb: RGB) =>
  '#' +
  rgb
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');

export const getDominantColors = (image: string): Promise<RGB[]> => {
  return new Promise((resolve, reject) => {
    const imageElement = new Image();
    imageElement.crossOrigin = 'anonymous';

    imageElement.onload = () => {
      const colors: RGB[] = colorThief?.getPalette(imageElement, 10);

      const dominantColors = colors
        .filter(([r, g, b]) => r < 235 && g < 235 && b < 235)
        .filter(([r, g, b]) => r > 20 && g > 20 && b > 20)
        .sort((a, b) => b[0] - a[0]);

      resolve(dominantColors.slice(0, 2));
    };

    imageElement.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    imageElement.src = image;
  });
};

export const generateComplementaryColors = (): string[] => {
  const randomColor = () => Math.floor(Math.random() * 255);

  const color1: RGB = [randomColor(), randomColor(), randomColor()];
  const color2: RGB = [randomColor(), randomColor(), randomColor()];

  return [rgbToHex(color1), rgbToHex(color2)];
};
