import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPortalBySlugKey } from './keys';
import type { PortalView } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';

export const useGetPortalRolesQuery = (portalView: Ref<PortalView>) => {
  const portalSlug = computed(() => portalView.value.slug);
  return useQuery({
    queryKey: [...getPortalBySlugKey(portalSlug), 'roles'],
    queryFn: () => dscvrIcApi.portal.getPortalRoles(portalView.value.id),
  });
};
