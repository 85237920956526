<script setup lang="ts">
  import { computed } from 'vue';
  import { PORTAL_CONTENT, EXPLORE_PORTALS } from '@/common';
  import { useChainBehavior } from '@/entities/user-setting';
  import {
    getPortalIcon,
    useGetRandomizedRecommendedPortalsQuery,
    usePortalUserStatus,
  } from '@/entities/portal';
  import type {
    PortalView,
    RandomizedRecommendationQuery,
  } from 'dfx/edge/edge.did';

  const props = withDefaults(
    defineProps<{
      favorites: PortalView[];
      count?: number;
    }>(),
    {
      count: 3,
    },
  );

  const { contentPreset } = useChainBehavior();
  const { toggleFavorite, isPortalFavorited, isPortalJoined, onJoin } =
    usePortalUserStatus();

  const queryParams = computed<RandomizedRecommendationQuery>(() => ({
    filter_tags: [['hotspot']],
    count: props.count + 3,
    content_preset: contentPreset.value,
  }));

  const { data: randomizedRecommendedPortals } =
    useGetRandomizedRecommendedPortalsQuery(queryParams);

  const portalFavoriteRecommendations = computed(() =>
    randomizedRecommendedPortals.value
      ?.filter(
        (portal) =>
          !props.favorites.some(
            (favoritePortal) => favoritePortal.slug === portal.slug,
          ),
      )
      .slice(0, props.count),
  );

  const toggleJoin = (portalView: PortalView) => {
    onJoin(portalView);
  };
</script>

<template>
  <h3 class="mt-8 mb-3 font-semibold text-gray-300">
    {{ $t('youMightLike') }}
  </h3>
  <ul class="flex flex-col gap-1 pl-2 pr-2.5">
    <template v-if="!portalFavoriteRecommendations?.length">
      <li v-for="i in count" :key="i">
        <div
          class="h-12 p-2 bg-gray-600 bg-opacity-40 animate-pulse rounded-xl"
        ></div>
      </li>
    </template>
    <template v-else>
      <li v-for="portal in portalFavoriteRecommendations" :key="portal.slug">
        <base-button
          variant="link"
          :to="{
            name: PORTAL_CONTENT,
            params: { slug: portal.slug },
          }"
          custom-classes="flex gap-5 justify-between items-center p-2 rounded-xl w-full"
          class="overflow-hidden font-medium text-gray-300 hover:text-white hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
        >
          <div class="flex items-center min-w-0 gap-2">
            <img
              class="size-8 rounded-lg"
              :src="getPortalIcon(portal)"
              alt="Portal Icon"
            />
            <span
              class="text-sm font-medium leading-5 break-words truncate"
              :title="portal.name"
            >
              {{ portal.name }}
            </span>
            <base-icon
              v-if="portal.is_nsfw"
              name="eighteen-plus"
              size="size-4 min-w-4"
            />
          </div>

          <base-button
            v-if="isPortalJoined(portal)"
            variant="tertiary"
            size="x-small"
            :title="$t('favoritePortals.favorite')"
            @click.prevent="toggleFavorite(portal)"
          >
            <base-icon
              :name="isPortalFavorited(portal) ? 'star' : 'outline-star'"
              size="size-4"
            />
          </base-button>

          <base-button
            v-else
            variant="tertiary"
            size="x-small"
            :title="$t('join')"
            @click.prevent="toggleJoin(portal)"
          >
            <base-icon name="plus-circle" size="size-4" />
          </base-button>
        </base-button>
      </li>
    </template>
  </ul>

  <section class="flex flex-col gap-2.5 mt-4 text-center">
    <h3 class="text-gray-400">{{ $t('favoritePortals.favoritesLegend') }}</h3>
    <base-button
      variant="link"
      class="font-medium text-indigo-300"
      :to="{
        name: EXPLORE_PORTALS,
      }"
    >
      {{ $t('exploreMore') }}
    </base-button>
  </section>
</template>
