<template>
  <CustomMenu as="div">
    <MenuButton
      class="flex items-center justify-center gap-x-2 w-full rounded-md bg-gray-750 py-3 text-sm font-medium hover:bg-gray-700 duration-300 transition-all text-white capitalize"
    >
      {{ currentTab }}
      <base-icon name="chevron-down-filled" size="w-3 h-3" />
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 origin-top-right rounded-lg bg-[#1f2530] text-white w-full"
      >
        <div class="py-1">
          <MenuItem
            v-for="(item, index) in items"
            :key="index"
            v-slot="{ active }"
            class="w-full hover:bg-gray-700 hover:text-white font-medium duration-300 transition-all"
          >
            <button
              :class="[
                active ? 'bg-gray-100 text-white' : 'text-gray-300',
                'block px-4 py-3 text-sm',
              ]"
              @click="$emit('setTab', item.name)"
            >
              {{ item.short }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </CustomMenu>
</template>

<script>
  import {
    Menu as CustomMenu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/vue';

  export default {
    components: {
      CustomMenu,
      MenuButton,
      MenuItem,
      MenuItems,
    },
    props: {
      items: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    emits: ['setTab'],
    computed: {
      currentTab() {
        return this.getActiveTab(this.items, this.$route.name);
      },
    },
    methods: {
      getActiveTab(tabList, currentRoute) {
        for (const tab in tabList) {
          if (tabList[tab].name === currentRoute) {
            return tabList[tab].short;
          }
        }
      },
    },
  };
</script>
