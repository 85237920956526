<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import PostBodySummaryText from './PostBodySummaryText.vue';
  import PostBodySummaryMedia from './PostBodySummaryMedia.vue';
  import PostBodySummaryEmbed from './PostBodySummaryEmbed.vue';
  import {
    useFormatHTMLContent,
    useFormatMarkdownContent,
    useIsolateHtmlParts,
  } from '@/composables';
  import type { PortalInfoView } from 'dfx/edge/edge.did';
  import { useBreakpoint } from '@/shared/model';

  const props = defineProps<{
    body: string;
    portalInfoView: PortalInfoView;
    excludeEmbeddedPosts?: boolean;
  }>();

  defineEmits<{
    (e: 'read-more'): void;
  }>();

  const { body } = toRefs(props);
  const { isSmallerThanMd } = useBreakpoint();
  const maxCharacters = computed(() => (isSmallerThanMd.value ? 100 : 200));

  const { isHTML, computedBody } = useFormatHTMLContent(body);
  const { compiledMarkdownAsHTML } = useFormatMarkdownContent();
  const finalBody = computed(() => {
    if (isHTML.value) {
      return computedBody.value;
    }
    return compiledMarkdownAsHTML(computedBody.value);
  });

  const { text, mediaList, embedList } = useIsolateHtmlParts(finalBody);

  const filteredEmbedList = computed(() => {
    if (props.excludeEmbeddedPosts) {
      return embedList.value.filter(({ type }) => type !== 'post');
    }

    return embedList.value;
  });
</script>

<template>
  <div class="flex flex-col gap-6">
    <post-body-summary-text
      v-if="text"
      :body="text"
      :portal-info-view="portalInfoView"
      :max-characters="maxCharacters"
      @read-more="$emit('read-more')"
    />
    <post-body-summary-media v-if="mediaList.length" :media-list="mediaList" />
    <post-body-summary-embed
      v-if="filteredEmbedList.length"
      :embed-list="filteredEmbedList"
    />
  </div>
</template>
