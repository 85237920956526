<template>
  <section class="overflow-x-hidden">
    <navbar-landing />
    <hero-section />
    <standing-out />
    <users-section />
    <creators />
    <features-section />
    <investors />
    <join-us />
  </section>
</template>

<script>
  import HeroSection from '../components/landing/Hero.vue';
  import StandingOut from '../components/landing/StandingOut.vue';
  import UsersSection from '../components/landing/Users.vue';
  import Creators from '../components/landing/Creators.vue';
  import FeaturesSection from '../components/landing/Features.vue';
  import Investors from '../components/landing/Investors.vue';
  import JoinUs from '../components/landing/JoinUs.vue';
  import { NavbarLanding } from '@/widgets/navbar-landing';

  export default {
    name: 'landing-page',
    components: {
      HeroSection,
      StandingOut,
      UsersSection,
      Creators,
      FeaturesSection,
      Investors,
      JoinUs,
      NavbarLanding,
    },
    mounted() {
      const typeFormScript = document.createElement('script');
      typeFormScript.setAttribute(
        'src',
        'https://embed.typeform.com/next/embed.js',
      );
      document.head.appendChild(typeFormScript);
    },
  };
</script>
