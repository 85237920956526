<template>
  <div
    class="z-50 w-full h-auto duration-500 ease-in-out transform group transition-width"
  >
    <form
      class="flex items-center w-full border border-white border-opacity-12 rounded-full bg-black bg-opacity-80 search-bar group-hover:bg-gray-950"
      :class="{
        'h-full': currentUser,
        '-mt-1': !currentUser,
        'ring-1 ring-indigo-600': isFocused,
      }"
      @submit.prevent="onSubmit"
    >
      <span class="inline-flex items-center ml-4">
        <base-icon
          name="search"
          size="w-4 h-4 sm:w-5 sm:h-5"
          class="cursor-pointer"
          :class="{
            'text-white': isTyping,
            'text-gray-400': !isTyping,
          }"
          @click="search"
        />
      </span>

      <input
        ref="input"
        v-model="query"
        class="flex-1 block w-full px-2 py-2.5 sm:py-2 text-sm sm:text-base text-white placeholder-gray-400 bg-transparent border-transparent cursor-pointer purple-caret caret-indigo-500 rounded-r-full focus:ring-0 focus:outline-none focus:outline-0"
        placeholder="Search"
        @focus="isFocused = !isFocused"
        @blur="isFocused = !isFocused"
        @input="onTyping"
      />
      <base-icon
        v-if="isTyping || query"
        name="close-circle"
        class="mr-4 text-gray-500"
        @click="clearSearch"
      />
    </form>
    <div
      v-show="1 == 2 && isTyping && query"
      class="absolute z-50 w-full mt-1 bg-gray-700 rounded-xl"
    >
      <div class="flex flex-col gap-2">
        <div
          v-for="(entry, index) in searchEntries"
          :key="index"
          class="flex items-center gap-3 p-3 cursor-pointer hover:bg-gray-600"
        >
          <div>
            <img
              v-if="entry.type != 'Tag'"
              alt="icon"
              class="w-8 h-8 border-2 border-yellow-500 rounded-full"
              :class="{ 'border-none': entry.type != 'User' }"
              lazy="loaded"
              :src="entry.icon"
            />
            <svg
              v-else
              class="w-6 h-6 mx-1 my-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.7651 1.82484C18.8261 1.27594 18.4305 0.781519 17.8816 0.720529C17.3327 0.65954 16.8383 1.05507 16.7773 1.60398L16.2476 6.37105H9.00331L9.50845 1.82484C9.56944 1.27594 9.1739 0.781519 8.62499 0.720529C8.07609 0.65954 7.58167 1.05507 7.52068 1.60398L6.991 6.37105H2.85742C2.30514 6.37105 1.85742 6.81877 1.85742 7.37105C1.85742 7.92334 2.30514 8.37105 2.85742 8.37105H6.76878L5.96236 15.6289H2.85742C2.30514 15.6289 1.85742 16.0766 1.85742 16.6289C1.85742 17.1812 2.30514 17.6289 2.85742 17.6289H5.74014L5.23502 22.1749C5.17403 22.7238 5.56957 23.2182 6.11847 23.2792C6.66738 23.3402 7.1618 22.9446 7.22279 22.3957L7.75244 17.6289H14.9968L14.4917 22.1749C14.4307 22.7238 14.8262 23.2182 15.3751 23.2792C15.924 23.3402 16.4184 22.9446 16.4794 22.3957L17.0091 17.6289H21.1427C21.6949 17.6289 22.1427 17.1812 22.1427 16.6289C22.1427 16.0766 21.6949 15.6289 21.1427 15.6289H17.2313L18.0377 8.37105H21.1427C21.6949 8.37105 22.1427 7.92334 22.1427 7.37105C22.1427 6.81877 21.6949 6.37105 21.1427 6.37105H18.26L18.7651 1.82484ZM16.0254 8.37105H8.78109L7.97467 15.6289H15.219L16.0254 8.37105Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="flex flex-col">
            <div class="text-base font-medium text-white">
              {{ entry.title }}
            </div>
            <div class="flex flex-row items-center gap-2 text-sm text-gray-400">
              <div>{{ entry.type }}</div>
              <div>
                <svg
                  width="5"
                  height="6"
                  viewBox="0 0 5 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.70881 5.13068C2.30777 5.13068 1.94152 5.03291 1.61009 4.83736C1.27865 4.63849 1.01349 4.37334 0.814631 4.0419C0.619081 3.71046 0.521307 3.34422 0.521307 2.94318C0.521307 2.53883 0.619081 2.17259 0.814631 1.84446C1.01349 1.51302 1.27865 1.24953 1.61009 1.05398C1.94152 0.855113 2.30777 0.755682 2.70881 0.755682C3.11316 0.755682 3.4794 0.855113 3.80753 1.05398C4.13897 1.24953 4.40246 1.51302 4.59801 1.84446C4.79688 2.17259 4.89631 2.53883 4.89631 2.94318C4.89631 3.34422 4.79688 3.71046 4.59801 4.0419C4.40246 4.37334 4.13897 4.63849 3.80753 4.83736C3.4794 5.03291 3.11316 5.13068 2.70881 5.13068Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </div>
              <div>{{ entry.stat }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full border-t border-gray-600"></div>
      <div
        class="flex items-center gap-4 p-3 text-base text-white cursor-pointer hover:bg-gray-600"
        @click="onSubmit"
      >
        <div>
          <svg
            class="w-6 h-6 ml-1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7999 19.2C15.4391 19.2 19.1999 15.4392 19.1999 10.8C19.1999 6.1608 15.4391 2.39999 10.7999 2.39999C6.16071 2.39999 2.3999 6.1608 2.3999 10.8C2.3999 15.4392 6.16071 19.2 10.7999 19.2Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.5998 21.6L16.7998 16.8"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>
          View all results for <span class="break-all">"{{ query }}"</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { trackGAEvent } from '../../utils/tracker';
  import { SEARCH } from '../../common';
  import { fetchMedia } from '@/shared/lib';

  export default {
    name: 'search-bar',
    emits: ['onSearch'],
    data() {
      return {
        searchEntries: [
          {
            icon: fetchMedia(
              'https://ipfs.dscvr.one/b2801e07-5fcb-486b-8149-9ee1b66f840b-bucket/l5u7qro5crij96sxkkn.jpeg',
            ),
            title: 'DSCVR_Team',
            type: 'User',
            stat: '2,369 Followers',
          },
          {
            icon: fetchMedia(
              'https://ipfs.dscvr.one/b2801e07-5fcb-486b-8149-9ee1b66f840b-bucket/l5j607raidtoh7l2eed.jpeg',
            ),
            title: 'DSCVR',
            type: 'Portal',
            stat: '25,441 Members',
          },
          {
            title: 'dscvr',
            type: 'Tag',
            stat: '19,8k Posts',
          },
        ],
        input: null,
        query: '',
        isFocused: false,
        isTyping: false,
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
    },
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          if (to.name !== SEARCH) {
            this.query = null;
            this.isTyping = false;
          }
        },
      },
      '$route.query': {
        immediate: true,
        handler(query) {
          if (query.q) {
            this.query = query.q;
          }
        },
      },
    },
    mounted() {
      if (this.$route.query.q) {
        this.query = this.$route.query.q;
      }
    },
    methods: {
      search() {
        if (this.query) {
          trackGAEvent('search_action', { term: this.query });
        }
        if (this.$route.query.sort) {
          this.$router.push({
            name: SEARCH,
            query: { sort: this.$route.query.sort, q: this.query },
          });
        } else {
          this.$router.push({
            name: SEARCH,
            query: { q: this.query },
          });
        }

        this.$refs.input.blur();
        this.$emit('onSearch', {});
      },
      onTyping() {
        this.isTyping = this.$refs.input.value.length > 0;
      },
      clearSearch() {
        this.$refs.input.value = '';
        this.query = '';
        this.isTyping = false;
      },
      onSubmit() {
        this.isTyping = false;
        this.search();
      },
    },
  };
</script>
