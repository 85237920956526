import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { usePkce } from '@/shared/model';
import { getMyTwitterProfileKey } from './keys';

export const useLinkTwitterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['link-twitter-mutation'],
    mutationFn: async (code: string) => {
      const { getLocalPkce } = usePkce();
      const pkce = await getLocalPkce();
      if (!pkce) throw new Error('PKCE not found');
      return dscvrApi.oauth2.twitterLinkCallback({
        code,
        codeVerifier: pkce,
        redirectUri: `${window.location.origin}/oauth2/twitter/callback`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getMyTwitterProfileKey,
      });
    },
  });
};
