<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { HOME } from '@/common';
  import { useAuth } from '@/entities/auth';

  const { showLoginSignUpDialog } = useAuth();

  const isOpen = ref(false);
  const isTransparent = ref(true);

  const login = () => {
    isOpen.value = false;
    showLoginSignUpDialog();
  };
  const signup = () => {
    isOpen.value = false;
    showLoginSignUpDialog();
  };
  const handleScroll = () => {
    if (window.pageYOffset > 5) {
      isTransparent.value = false;
    } else {
      isTransparent.value = true;
    }
  };
  const scrollToSection = (sectionId: string) => {
    isOpen.value = false;
    document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
</script>

<template>
  <header
    class="fixed z-20 flex justify-center w-full h-16"
    :class="{
      active: !isTransparent,
    }"
  >
    <div
      class="flex flex-row items-center justify-between w-full px-2 py-1 md:px-6 max-w-home"
    >
      <router-link :to="{ name: HOME }">
        <base-icon
          name="dscvr-with-label"
          size="w-[7rem] h-[2rem] w-[8.5rem] sm:h-[2.5rem]"
        />
      </router-link>

      <div
        class="flex-row flex-auto px-4 py-1 hidden lg:flex gap-x-6 justify-center"
      >
        <button @click="scrollToSection('features')">Features</button>
        <button @click="scrollToSection('standOut')">Why DSCVR?</button>
        <button @click="scrollToSection('creators')">Creators</button>
        <a href="https://dscvr.notion.site/" target="__blank"> FAQ </a>
        <button
          data-tf-slider="ibCBNICS"
          data-tf-opacity="100"
          data-tf-size="100"
          data-tf-iframe-props="title=Contact us"
          data-tf-transitive-search-params
        >
          Contact us
        </button>
      </div>

      <div class="flex flex-row px-4 py-1 gap-x-2 sm:gap-x-4 items-center">
        <button class="hidden sm:block" @click="login">Log in</button>
        <button
          class="w-32 px-6 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md focus:outline-none hover:bg-indigo-500"
          @click="signup"
        >
          Get started
        </button>
        <button
          class="flex items-center justify-center text-white cursor-pointer focus:outline-none w-8 h-8 lg:hidden"
          @click="isOpen = !isOpen"
        >
          <base-icon name="hamburger" size="w-4 h-4" />
        </button>
      </div>
    </div>
  </header>
  <div class="fixed inset-0 bg-black z-10" :class="{ hidden: !isOpen }">
    <div class="flex flex-col items-start px-6 py-12 mt-16 gap-6">
      <button @click="scrollToSection('features')">Features</button>
      <button @click="scrollToSection('standOut')">Why DSCVR?</button>
      <button @click="scrollToSection('creators')">Creators</button>
      <button>FAQ</button>
      <button>Contact us</button>
    </div>
    <div class="flex gap-4 flex-col w-full px-6 justify-center items-center">
      <button
        class="w-full sm:w-32 h-12 px-6 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md focus:outline-none hover:bg-indigo-500"
        @click="signup"
      >
        Get started
      </button>
      <button
        class="grey-button w-full sm:w-32 h-12 rounded-md focus:outline-none"
        @click="login"
      >
        Log in
      </button>
    </div>
  </div>
</template>

<style scoped>
  .active {
    background: rgba(13, 8, 16, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
  }
</style>
