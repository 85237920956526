<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { OnboardingStep } from '@/shared/model';
  import ContinueButton from '../ContinueButton.vue';
  import { useGetOnboardingInterestsQuery } from '../../api/use-get-onboarding-interests.query';
  import { interestColors } from '../../lib/interest-colors';
  import ContentDialog from '../../ui/Content.vue';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { Loader } from '@/shared/ui/loader';
  import { useSetUserInterestsMutation, useUser } from '@/entities/user';

  const { t } = useI18n({
    useScope: 'global',
  });
  useGtm()?.trackEvent({ event: 'Interests_Step_2' });
  const { currentUser } = useUser();
  const { data: interests, isLoading } =
    useGetOnboardingInterestsQuery(currentUser);
  const { mutate: setUserOnboardingInterestsMutation } =
    useSetUserInterestsMutation();

  const selectedInterests = ref<string[]>([]);

  const isButtonEnabled = computed(() => selectedInterests.value.length > 2);

  const onChangeInterest = (interest: string) => {
    if (selectedInterests.value.includes(interest)) {
      selectedInterests.value.splice(
        selectedInterests.value.indexOf(interest),
        1,
      );
    } else {
      selectedInterests.value.push(interest);
    }
  };

  const onContinue = async () => {
    setUserOnboardingInterestsMutation({ interests: selectedInterests.value });
  };
</script>

<template>
  <div v-if="isLoading" class="relative p-40">
    <loader variant="rainbow" border-width="border" size="size-10 md:size-20" />
  </div>
  <content-dialog v-else class="pt-30 md:pt-20">
    <template #title>
      {{ t('onboarding.interest.title') }}
    </template>
    <template #description>
      {{ t('onboarding.interest.description') }}
    </template>
    <div class="flex flex-wrap gap-4 px-2">
      <base-button
        v-for="(interest, index) in interests"
        :key="index"
        variant="custom"
        custom-classes="flex items-center justify-center py-2 px-4 rounded-full"
        size="small"
        :class="{
          'bg-indigo-950 hover:bg-indigo-900':
            !selectedInterests.includes(interest),
          [interestColors[selectedInterests.indexOf(interest)]]:
            selectedInterests.includes(interest),
        }"
        @click="onChangeInterest(interest)"
      >
        #{{ interest }}
      </base-button>
    </div>
    <template #actions>
      <div class="flex flex-col gap-1 w-full">
        <continue-button
          :current-step="OnboardingStep.INTERESTS"
          :disabled="!isButtonEnabled"
          :on-custom-action="onContinue"
        />
        <continue-button
          variant="link"
          :current-step="OnboardingStep.INTERESTS"
        >
          {{ t('onboarding.interest.noneOfThese') }}
        </continue-button>
      </div>
    </template>
  </content-dialog>
</template>
