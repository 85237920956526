<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import type { dscvrApi } from '@/shared/api';
  import { fetchMedia } from '@/shared/lib';
  import { animateLootboxItem } from '../util';
  import SolanaLootIcon from '../ui/SolanaLootIcon.vue';

  const props = defineProps<{
    lootboxItem: dscvrApi.lootbox.ILootboxItem;
  }>();

  const tokenDisplay = ref<HTMLImageElement>();

  const getDisplayAmount = (amount: bigint, decimals: number) => {
    return Number(amount) / 10 ** decimals;
  };

  onMounted(() => {
    if (!props.lootboxItem.isClaimed && tokenDisplay.value) {
      animateLootboxItem(tokenDisplay.value);
    }
  });
</script>

<template>
  <div>
    <solana-loot-icon class="absolute top-6 left-6 z-40" />
    <img
      v-if="lootboxItem"
      v-lazy="{
        src: lootboxItem.token.image,
        error: fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
        ),
      }"
      :alt="lootboxItem.token.name"
      class="aspect-square rounded-full m-10"
      ref="tokenDisplay"
    />
    <h3 class="flex py-6 pl-4 text-base font-semibold text-white gap-x-2">
      <span class="mr-2">
        {{ getDisplayAmount(lootboxItem.amount, lootboxItem.token.decimals) }}
        {{ lootboxItem.token.symbol }}</span
      >
    </h3>
  </div>
</template>
