<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { dscvrApi } from '@/shared/api';
  import ClaimLootboxItem from './ClaimLootboxItem.vue';
  import { useClaimLootboxItemMutation } from '@/entities/lootbox';

  const props = defineProps<{
    lootboxItems: dscvrApi.lootbox.ILootboxItem[];
  }>();

  const emit = defineEmits<{
    (event: 'close'): void;
  }>();

  const currentIndex = ref(0);

  const { mutateAsync: claimLootboxItem, isPending: isClaiming } =
    useClaimLootboxItemMutation();

  const currentItem = computed(() => props.lootboxItems[currentIndex.value]);

  const claim = () => {
    claimLootboxItem(
      {
        lootboxId: currentItem.value.lootboxId,
        tokenType: currentItem.value.tokenType,
      },
      {
        onSettled: () => {
          currentIndex.value = currentIndex.value + 1;
          if (currentIndex.value >= props.lootboxItems.length) {
            emit('close');
          }
        },
      },
    );
  };
</script>

<template>
  <div
    class="absolute w-[120%] -top-[20%] -left-[10%] md:w-[200%] md:-left-[50%] md:-top-[70%] -z-1"
  >
    <img
      :key="currentIndex"
      v-lazy="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/lootbox/confetti.gif`)"
      alt="Party"
    />
  </div>
  <div class="flex flex-col items-center justify-center gap-8 w-full">
    <div class="h-100 w-full">
      <transition
        mode="out-in"
        class="item-enter-animation"
        leave-active-class="item-leave-animation"
      >
        <claim-lootbox-item :key="currentIndex" :lootbox-item="currentItem" />
      </transition>
    </div>
    <div class="flex flex-col gap-6 items-center justify-center">
      <div
        class="relative flex items-center justify-center min-w-0 min-h-0 rounded-full overflow-hidden z-1 p-0.5"
      >
        <div
          v-if="isClaiming"
          class="absolute animate-spin animate-duration-[1000ms] size-[500%] conic-gradient"
        />
        <base-button
          variant="primary"
          size="medium"
          rounded="rounded-full"
          additional-classes="font-medium min-w-40 z-2 disabled:!opacity-100"
          @click="claim"
          :disabled="isClaiming"
        >
          {{ $t('claim') }}
        </base-button>
      </div>
      <div class="flex gap-2">
        <span
          v-for="i in lootboxItems.length"
          :key="i"
          :class="[
            'size-3 rounded-full',
            i <= currentIndex + 1
              ? 'bg-gradient-to-b from-[#495AFF] to-[#0ACFFE]'
              : 'border border-[#00f2fe] p-1 ',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .item-enter-animation {
    animation: item-enter-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
  }

  @keyframes item-enter-animation {
    0% {
      transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
        skew(-35deg, 10deg);
      opacity: 0;
    }
    30% {
      transform: rotate(-40deg) rotateX(0deg) translate(-100px, 0px)
        skew(-32deg, 32deg) scaleX(1.5);
      opacity: 1;
    }
    40% {
      transform: rotate(-40deg) rotateX(0deg) translate(-100px, 50px)
        skew(-32deg, 32deg) scaleX(2);
    }
    50% {
      transform: rotate(-3deg) rotateX(0deg) translate(0, 0) skew(0deg, 0deg)
        scaleX(1.1);
    }
    65% {
      transform: rotate(4deg) rotateX(0deg) translate(50px, -100px)
        skew(-8deg, 0deg) scaleX(0.7);
    }
    75% {
      transform: rotate(-3deg) rotateX(0deg) translate(-10px, 10px)
        skew(0deg, 0deg) scaleX(1.2);
    }
    100% {
      transform: skew(0deg 0deg);
    }
  }

  .item-leave-animation {
    animation: item-leave-animation 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
  }

  @keyframes item-leave-animation {
    0% {
      transform: skew(0deg 0deg);
    }
    100% {
      transform: skew(0deg, -11deg) translate(-200px, 200px) rotateY(52deg)
        rotateX(30deg);
      opacity: 0;
    }
  }

  .conic-gradient {
    background-image: conic-gradient(#3b82f6 20deg, transparent 120deg);
  }
</style>
