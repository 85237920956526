<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { usePersistentStore } from '@/store';
  import { useUser } from '../../../user';
  import { useEmailUpdate } from '../../../user-setting';
  import { TextFormField } from '@/shared/ui/form-fields';

  import type { SubmissionHandler } from 'vee-validate';

  const { currentUser } = useUser();
  const {
    emailAddress,
    emailDirty,
    handleSubmit,
    setEmailAddress,
    verifyEmailAddress,
    onInvalidSubmit,
  } = useEmailUpdate(currentUser);

  const persistentStore = usePersistentStore();
  const { verificationEmail } = storeToRefs(persistentStore);

  const onValidSubmit: SubmissionHandler<{ email: string }> = async (
    values,
  ) => {
    // If user manually updates the form field
    // then implicitly update the email address
    if (emailDirty.value) {
      await setEmailAddress(values.email, { toast: false });
    }
    await verifyEmailAddress(values.email);
  };
  const onSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);
</script>

<template>
  <section class="mt-[110px] md:mt-[55px] pt-4">
    <div class="flex flex-col items-center justify-center">
      <h1 class="text-white font-bold text-2xl leading-6 tracking-[-0.12px]">
        {{
          verificationEmail.sent
            ? $t('verification.email.resendTitle')
            : $t('verification.email.title')
        }}
      </h1>
      <p class="mt-4 text-gray-400 text-sm text-center tracking-[0.14px]">
        {{
          verificationEmail.sent
            ? $t('verification.email.resendSubtitle')
            : $t('verification.email.subtitle')
        }}
      </p>
      <div class="mt-6 w-full">
        <form class="w-full flex flex-col" @submit.prevent="onSubmit">
          <section v-if="!verificationEmail.sent" class="py-2">
            <span
              class="mb-2 text-gray-500 text-xs font-medium tracking-[0.12px]"
            >
              Email
            </span>
            <text-form-field
              type="email"
              name="email"
              id="username"
              :model-value="emailAddress"
              inner-classes="!py-2.5 max-h-12"
            />
          </section>
          <div class="flex flex-col items-center justify-center">
            <!-- Verify button -->
            <base-button
              v-if="!verificationEmail.sent"
              variant="primary"
              size="small"
              class="mt-6 w-full rounded-2xl"
            >
              {{ $t('verification.email.buttons.sendEmail') }}
            </base-button>
            <!-- Resend button -->
            <base-button
              v-else
              variant="primary"
              size="small"
              class="w-full rounded-2xl"
            >
              {{ $t('verification.email.buttons.resend') }}
            </base-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
