<script lang="ts" setup>
  import { ref } from 'vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useUser } from '../../model/composables/use-user';
  import type { UserTooltipProps } from '../../types';
  import FollowButton from '@/components/buttons/FollowButton.vue';
  import { fromNow } from '@/utils';
  import Roles from './Roles.vue';
  import GoodbyeButton from './GoodbyeButton.vue';
  import { isPortalOwner, isPortalManager } from '@/shared/lib';
  import { useTooltip } from '../../model/composables/use-tooltip';
  import UserSummary from './UserSummary.vue';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';

  const props = defineProps<UserTooltipProps>();

  const {
    assignableRoles,
    goToProfile,
    isAdmin,
    isEnabled,
    isUserFollowing,
    onAddNewRoleToUser,
    onClickFollowingFollower,
    onClickGoToProfile,
    onGoodbye,
    onHide,
    refetchIsUserFollowingQuery,
    userPortalRolesDto,
  } = useTooltip(props);
  const { getFormattedTokens } = useUser(props.user.id);

  const userTooltipRef = ref<HTMLElement>();
</script>

<template>
  <base-tooltip
    ref="userTooltipRef"
    placement="bottom-start"
    interactive
    theme="transparent"
    :arrow="false"
    append-to-body
    :delay="200"
    @hide="onHide"
    @show="isEnabled = true"
  >
    <template #default>
      <user-summary v-bind="props" @clicked-profile="onClickGoToProfile" />
    </template>
    <template #content>
      <div class="relative rounded-lg shadow-lg bg-gray-785 w-full min-w-96">
        <div class="flex flex-col gap-1 p-4">
          <div class="flex justify-between items-center w-full gap-2">
            <div class="flex items-center gap-4">
              <user-avatar :item="user" size="size-11" class="flex-none" />
              <div class="flex flex-col">
                <base-button
                  variant="custom"
                  custom-classes="flex items-center font-bold text-gray-300 hover:text-indigo-300"
                  @click="goToProfile"
                >
                  <span v-if="isMention">@</span>
                  <span class="text-lg whitespace-nowrap">
                    {{ user.username }}
                  </span>
                </base-button>

                <div
                  class="flex items-center gap-2 text-white whitespace-nowrap"
                >
                  <base-icon name="dscvr" size="size-4" />
                  <span>{{ getFormattedTokens(user.rights) }}</span>
                </div>
              </div>
            </div>
            <follow-button
              :is-following="isUserFollowing"
              :user-id="user.id"
              size="sm"
              class="w-max"
              @follow-user="refetchIsUserFollowingQuery"
              @login-modal-shown="onHide"
            />
          </div>
          <div class="text-white whitespace-nowrap mt-2">
            Joined {{ fromNow(user.created_at) }}
          </div>
          <div class="flex gap-4">
            <base-button
              variant="link"
              class="gap-1 hover:underline follow-link"
              @click="onClickFollowingFollower('following')"
            >
              <span class="font-semibold">{{ user.following }}</span>
              <span class="font-medium text-blue-400">Following</span>
            </base-button>
            <base-button
              variant="link"
              class="gap-1 hover:underline follow-link"
              @click="onClickFollowingFollower('followers')"
            >
              <span class="font-semibold">{{ user.followers }}</span>
              <span class="font-medium text-blue-400">Followers</span>
            </base-button>
          </div>
        </div>
        <template v-if="isAdmin">
          <goodbye-button @goodbye="onGoodbye" />
        </template>
        <template v-if="'Portal' in portalInfoView.portal_type">
          <roles
            :is-owner="isPortalOwner(portalInfoView, user)"
            :is-portal-manager="isPortalManager(portalInfoView)"
            :portal-info-view="portalInfoView"
            :user-portal-roles="userPortalRolesDto?.roles"
            :available-assignable-roles="assignableRoles"
            @on-add-new-role-to-user="onAddNewRoleToUser($event)"
          />
        </template>
      </div>
    </template>
  </base-tooltip>
</template>
