<template>
  <div
    v-if="showClaim"
    class="flex justify-between w-full p-3 text-white shadow-lg bg-color-reward post-item sm:rounded-xl"
  >
    <slot />
  </div>
</template>

<script>
  import { ActionTypes } from '../../store/action-types';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ubi-slot',
    components: {},
    props: {},
    computed: {
      ...mapGetters({
        claimTime: 'ubi/claimTime',
      }),
      showClaim() {
        return new Date(Number(this.claimTime) / 1000000) - new Date() < 0;
      },
    },
    mounted() {
      this.getLastClaim();
    },
    methods: {
      getLastClaim() {
        this.$store.dispatch(`ubi/${ActionTypes.GET_NEXT_REWARD}`);
      },
    },
  };
</script>

<style>
  .bg-color-reward {
    background-color: #242c48;
  }
</style>
