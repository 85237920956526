import { useInfiniteQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { PersonalizedFeedV1Query } from 'dfx/edge/edge.did';
import type { Ref, MaybeRef, ComputedRef } from 'vue';
import {
  hasNextPage,
  getFeedByNameKey,
  portalFeedKey,
  type FeedFilter,
} from '@/shared/model';

export const useGetPortalContentQuery = (
  slug: Ref<string> | undefined,
  feedFilters: ComputedRef<FeedFilter>,
  queryParams: Ref<PersonalizedFeedV1Query>,
  enabled?: MaybeRef<boolean>,
) => {
  return useInfiniteQuery({
    queryKey: [...getFeedByNameKey(portalFeedKey), slug, feedFilters],
    queryFn: async (ctx) => {
      // We override the page parameter with the current page number using the one provided by vue-query
      // this is a temporary hack until backend creates a cursor based pagination
      queryParams.value.query.page = BigInt(ctx.pageParam);
      if (!slug) return null;
      const data = await dscvrIcApi.portal.getPortalContent(
        slug.value,
        queryParams.value.query,
      );
      return data.result.length ? data.result[0] : null;
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, __) =>
      hasNextPage(allPages[0]?.total_pages, lastPageParam),
    enabled,
    staleTime: 1000 * 60,
  });
};
