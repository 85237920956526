import { HttpAgent, Actor } from '@dfinity/agent';

const enviros = {
  ic: {
    name: 'ic',
    url: import.meta.env.VITE_IC_URL,
  },
};

export const getActor = (idlFactory, canisterId, key) => {
  const enviro = enviros['ic'];
  const agent = new HttpAgent({ identity: key, host: enviro.url });
  const actorConstructor = Actor.createActorClass(idlFactory);
  return new actorConstructor({ agent, canisterId: canisterId });
};
