<script setup lang="ts">
  import { useDialog } from '@/shared/model';
  import { ContentWithTitle } from '@/shared/ui/base-dialog';

  defineProps<{
    username: string;
  }>();

  const emit = defineEmits<{
    (e: 'pair'): void;
  }>();

  const { closeDialog } = useDialog();

  const onForcePair = () => {
    emit('pair');
    closeDialog();
  };
</script>

<template>
  <content-with-title>
    <template #title>
      {{ $t('wallets.unsuccessfulPairing.inApp.pairingUnsuccessful') }}
    </template>
    <p>
      {{ $t('wallets.unsuccessfulPairing.inApp.walletAlreadyPairedTo') }}
      <span class="ml-1 text-white">{{ username }}.</span>
    </p>
    <p>
      {{ $t('wallets.unsuccessfulPairing.inApp.pairingWillUnpairFrom') }}
      <span class="ml-1 text-white">{{ username }}.</span>
    </p>
    <template #actions>
      <base-button
        variant="primary"
        size="full"
        class="h-[40px]"
        rounded="rounded-2xl"
        @click="onForcePair"
      >
        <span class="text-gray-200 leading-6 tracking-[0.08px]">
          {{ $t('wallets.unsuccessfulPairing.inApp.pairThisAccount') }}
        </span>
      </base-button>
      <base-button
        variant="link"
        size="full"
        class="!py-0 text-indigo-300"
        @click="closeDialog"
      >
        {{ $t('wallets.unsuccessfulPairing.inApp.useDifferentWallet') }}
      </base-button>
    </template>
  </content-with-title>
</template>
