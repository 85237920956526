import type { MaybeRef } from 'vue';

export const portalContentKey = 'portal-content';
export const portalKey = 'portal';
export const favoritePortalsKey = 'favorite-portals';
export const joinedPortalsKey = 'joined-portals';
export const randomizedRecommendedPortalsKey = 'randomized-recommended-portals';
export const searchPortalsKey = 'search-portals';
export const portalUserStatusKey = 'portal-user-status';
export const portalMembersByStatusKey = 'portal-members-by-status';

export const getPortalBySlugKey = (slug: MaybeRef<string>) => {
  return [portalKey, 'portal-by-slug', slug];
};
