<script lang="ts" setup>
  import { ref } from 'vue';
  import TooltipContent from './TooltipContent.vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useIgc } from '../model/composables/use-igc';

  const { sparksCount } = useIgc();
  const isOpened = ref(false);
</script>

<template>
  <base-dropdown
    v-model:opened="isOpened"
    :distance="26"
    append-to-body
    custom-content-classes="border border-white border-opacity-16 bg-gray-950"
  >
    <template #button="{ toggle }">
      <base-button variant="tertiary" size="x-small" @click="toggle">
        <base-icon name="sparks" size="size-5" />
        <span>{{ sparksCount }}</span>
      </base-button>
    </template>
    <template #content>
      <tooltip-content @close="isOpened = false" />
    </template>
  </base-dropdown>
</template>
