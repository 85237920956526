<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { Countdown } from '@/shared/ui/countdown';
  import { useI18n } from 'vue-i18n';
  import { OnboardingLootboxStep, useBreakpoint } from '@/shared/model';
  import { useUser, useGetUsersByPkQuery } from '@/entities/user';
  import { formatZeroPadding } from '@/shared/lib';
  import { useOnboardingLootboxStateMachine } from '../../model/composables/use-onboarding-lootbox-state-machine';
  import { useOnboarding } from '../../model/composables/use-onboarding';
  import { useWalletPairing } from '@/entities/wallets';

  const { t } = useI18n({
    useScope: 'global',
  });
  const { states } = useOnboardingLootboxStateMachine();
  const { currentUser } = useUser();
  const { isUserDiscoverStepMissing, isUserJoinPortalsStepMissing } =
    useOnboarding();
  const { data: userPk } = useGetUsersByPkQuery(currentUser);
  const { pairedWallets, fetchPairedWallets, primarySolanaWallet } =
    useWalletPairing();
  const { isSmallerThanMd } = useBreakpoint();

  const isClaimable = ref(false);
  const lootboxCountdown = ref<string>('');

  const startOnboardingLootboxTimer = (nextClaimAt: string) => {
    const currentTime = new Date().getTime();
    const nextClaimTime = new Date(nextClaimAt).getTime();
    const timeDifference = nextClaimTime - currentTime;

    if (timeDifference >= 0) {
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      const formattedHours = formatZeroPadding(hours);
      const formattedMinutes = formatZeroPadding(minutes);
      const formattedSeconds = formatZeroPadding(seconds);
      lootboxCountdown.value = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      isClaimable.value = true;
    }
  };

  const buttonSize = computed(() =>
    isSmallerThanMd.value ? 'small' : 'medium',
  );

  const onClickClaim = async () => {
    if (isUserDiscoverStepMissing.value || isUserJoinPortalsStepMissing.value) {
      states.value[OnboardingLootboxStep.MISSING_STEPS].on('continue');
    } else {
      await fetchPairedWallets();
      if (!primarySolanaWallet.value && !pairedWallets.value.length) {
        states.value[OnboardingLootboxStep.MISSING_WALLET].on('continue');
      } else {
        states.value[OnboardingLootboxStep.DONE].on('continue');
      }
    }
  };
</script>

<template>
  <div
    class="flex justify-between items-center gap-4 py-4 px-6 bg-gray-950 border border-gray-700 shadow-lg border-opacity-70 rounded-xl mb-4"
  >
    <div class="flex items-center gap-6">
      <img
        :src="
          fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/treasure-chest.gif`,
          )
        "
        class="flex-none w-16 md:w-30 h-auto"
      />
      <div class="flex flex-col">
        <div class="font-semibold text-md md:text-lg tracking-[0.18px]">
          {{ t('onboarding.whatIsInTheBox') }}
        </div>
        <countdown
          v-if="!isClaimable && userPk?.claimedOnboardingStreakAt"
          :countdown="lootboxCountdown"
          class="self-start"
          @running="
            startOnboardingLootboxTimer(userPk.claimedOnboardingStreakAt)
          "
        >
          <template #prefix>
            <div v-if="lootboxCountdown" class="flex gap-1.5 items-center">
              <base-icon
                name="clock"
                size="size-4"
                class="flex-none text-white"
              />
              <span v-if="!isSmallerThanMd">
                {{ t('comeBackIn') }}
              </span>
            </div>
          </template>
        </countdown>
      </div>
    </div>
    <base-button
      :disabled="!isClaimable"
      variant="primary"
      :size="buttonSize"
      @click="isClaimable ? onClickClaim() : null"
    >
      {{
        isClaimable
          ? isSmallerThanMd
            ? t('claim')
            : t('claimLootbox')
          : isSmallerThanMd
          ? t('onboarding.unlock')
          : t('onboarding.unlockLootbox')
      }}
    </base-button>
  </div>
</template>
