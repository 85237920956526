<script setup lang="ts">
  import { Drawer } from '@/shared/ui/drawer';
  import { Tabs } from '@/shared/ui/tabs';
  import {
    UnreadNotificationButton,
    PortalAdminToggle,
    NotificationSettingsButton,
    Empty,
    List,
    MarkAllAsReadButton,
    ViewAllNotificationsButton,
  } from '@/entities/notification';
  import { useNotification } from '@/features/notifications';

  const {
    isOpen,
    tabItems,
    activeTabItem,
    isEmpty,
    contentRef,
    drawerClasses,
    closeDrawer,
    setTab,
  } = useNotification();
</script>

<template>
  <div
    v-click-outside="closeDrawer"
    class="flex items-center justify-center size-10"
  >
    <unread-notification-button />
  </div>
  <!-- Drawer Component -->
  <drawer
    :opened="isOpen"
    @update:opened="closeDrawer"
    direction="right"
    title-classes="px-8 md:px-6 py-0"
    :custom-classes="drawerClasses"
  >
    <!-- Header section -->
    <template #title>
      <section class="flex items-center justify-between w-full h-16">
        <div class="text-lg font-semibold leading-6 text-white">
          {{ $t('notifications.title') }}
        </div>
        <portal-admin-toggle />
      </section>
    </template>
    <!-- Notifications section -->
    <tabs
      direction="horizontal"
      type="underlined"
      class="h-[calc(100%-4rem)] overflow-hidden"
      tabs-classes="bg-gray-blue-900 w-full h-16 px-6 ml-4 md:ml-0"
      content-classes="flex flex-col pl-4 md:pl-0 w-full h-[calc(100%-4rem)] overflow-y-auto thin-scrollbar"
      off-color="border-transparent text-gray-400 disabled:border-transparent disabled:text-gray-500 hover:border-white hover:text-white"
      on-color="border-[#6366F1] text-white"
    >
      <template #tabs="{ onColor, offColor }">
        <div class="flex items-center justify-between w-full">
          <div class="flex space-x-4 max-w-44">
            <base-button
              v-for="tab in tabItems"
              :key="tab.id"
              variant="custom"
              custom-classes="flex items-center whitespace-nowrap cursor-pointer select-none border-b-2 h-[52px] mb-2 disabled:cursor-not-allowed"
              :class="tab.id === activeTabItem.id ? onColor : offColor"
              :disabled="tab.disabled"
              @click.stop="setTab(tab, true)"
            >
              {{ tab.title }}
            </base-button>
          </div>
          <div class="pr-4 md:pr-0">
            <notification-settings-button />
          </div>
        </div>
      </template>
      <template #content>
        <section ref="contentRef" class="flex-1 h-full">
          <!-- No Notifications -->
          <template v-if="isEmpty">
            <empty />
          </template>
          <template v-else>
            <section class="flex flex-col">
              <list />
            </section>
          </template>
        </section>
      </template>
    </tabs>
    <!-- Footer section -->
    <section
      class="absolute bottom-0 h-16 flex items-center justify-between w-full py-4 px-6 bg-gray-blue-900 border-t border-gray-800"
    >
      <mark-all-as-read-button />
      <view-all-notifications-button />
    </section>
  </drawer>
</template>
