<script setup lang="ts">
  import { JoinedPortalListItem } from '.';
  import type { PortalView } from 'dfx/edge/edge.did';

  const MIN_ITEM_SIZE = 106;
  const BUFFER_PX = 5 * MIN_ITEM_SIZE; // 10 items on top and 10 at the bottom pre-rendered forsmooth scrolling

  defineProps<{
    portals: PortalView[];
  }>();
</script>

<template>
  <recycle-scroller
    page-mode
    :items="portals"
    :min-item-size="MIN_ITEM_SIZE"
    :buffer="BUFFER_PX"
    v-slot="{ item: portalView }"
  >
    <joined-portal-list-item :key="portalView.slug" :portal-view="portalView" />
  </recycle-scroller>
</template>
