<script setup lang="ts">
  // TODO: fix FSD
  import { ref, computed, watch, onMounted, toRefs } from 'vue';
  import sanitizeHtml from 'sanitize-html';
  import type { CommentViewNode } from '@/shared/model';
  import enrichService from '@/services/enrich.js';
  import { lang } from '@/utils/lang';
  import { trackEvent } from '@/utils/tracker';
  import { isValidUrl, isImageUrl, fromNow, sanitizeSettings } from '@/utils';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import CommentForm from '@/components/forms/CommentForm.vue';
  import { DynamicContent } from '@/components/dynamic-content';
  import { TipDisplay } from '@/features/post';
  import CommentActions from './CommentActions.vue';
  import { useUser, useUserRole, UserTooltip } from '@/entities/user';
  import type { ValidationErrors } from 'dfx/edge/edge.did';
  import { useCommentFeed } from '../model/composables/use-comment-feed';
  import type { CommentVote } from '../types';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<{
    item: CommentViewNode;
  }>();

  const { item } = toRefs(props);
  const shouldAttachReplyForm = ref(false);
  const isReplyToggled = ref(false);
  const edit = ref(false);
  const errors = ref<ValidationErrors[] | undefined>([]);
  const errorMessage = ref('');
  const enrichedComment = ref(null);
  const isCommenting = ref(false);
  const canTranslate = ref(false);
  const isVoteTipShown = ref(false);
  const isVoting = ref(false);
  const editForm = ref<HTMLFormElement>();
  const createForm = ref<HTMLFormElement>();

  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn, currentUser, onSetUserRank } = useUser();
  const { isAdmin } = useUserRole();
  const {
    updateFeedComment,
    createFeedComment,
    deleteFeedComment,
    removeFeedComment,
    voteFeedComment,
    setFeedNSFW,
    report,
    toggleChildren,
  } = useCommentFeed();

  const checkAdmin = computed(() => currentUser.value && isAdmin.value);

  const isDeleted = computed(() => {
    return item.value.body === '[deleted]';
  });

  const marginLeftPX = computed(() => {
    return item.value.depth * 8;
  });

  const setupTranslation = () => {
    const tLang = lang.getTranslationLang();

    if (tLang && tLang != '0') {
      canTranslate.value = true;
    }
  };

  const hasRainbowRole = () => {
    if (item.value && item.value.owner_roles) {
      return item.value.owner_roles.includes(1877342n);
    }

    return false;
  };

  const untranslate = () => {
    item.value.translatedBody = undefined;
  };

  const translate = async () => {
    if (item.value.translatedBody) {
      untranslate();
    } else {
      enrichService
        .translate(item.value.body, lang.getTranslationLang())!
        .then((res) => {
          trackEvent('comment_action', 'translated', item.value.id.toString());
          if (res.data && res.data.data) {
            const translation = res.data.data;

            if (translation.translations.length > 0) {
              item.value.translatedBody =
                translation.translations[0].translatedText;
            }
          }
        });
    }
  };

  const isImage = (url: string) => {
    return isValidUrl(url) && isImageUrl(url);
  };

  const checkEnrich = (url: string) => {
    if (enrichService) {
      enrichService.enrichUrl(url)!.then((response) => {
        if (response && response.data && response.data.html) {
          enrichedComment.value = response.data.html;
        }
      });
    }
  };

  const updateCommentSubmit = (unstoredBody: string) => {
    errors.value = [];
    isCommenting.value = true;
    updateFeedComment(item.value.id, unstoredBody)
      .then((result) => {
        if (result?.status === 'happy') {
          edit.value = false;
          if (editForm.value) editForm.value.reset();
        } else {
          errorMessage.value = result.message;
          errors.value = result.errors[0];
        }
      })
      .finally(() => {
        isCommenting.value = false;
      });
  };

  const createCommentSubmit = (unstoredBody: string) => {
    errors.value = [];
    isCommenting.value = true;
    createFeedComment(item.value.portal.id, item.value.id, unstoredBody)
      .then(() => {
        isCommenting.value = false;
        if (createForm.value) createForm.value.reset();
        toggleReply();
      })
      .catch(() => {
        isCommenting.value = false;
      });
  };

  const toggleReply = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else {
      shouldAttachReplyForm.value = true;
      isReplyToggled.value = !isReplyToggled.value;
    }
  };

  const toggleEdit = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else {
      edit.value = !edit.value;
    }
  };

  const showVoteTip = (vote: CommentVote) => {
    if (vote === 'upvote') {
      if (!localStorage.getItem('upvoted')) {
        isVoteTipShown.value = true;
        localStorage.setItem('upvoted', 'true');
      }
    }
  };

  const vote = async (isUpvote: boolean) => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
    } else {
      isVoting.value = true;
      await voteFeedComment(props.item, isUpvote, showVoteTip);
      isVoting.value = false;
    }
  };

  watch(
    () => item.value.body,
    (newVal) => {
      if (isValidUrl(newVal)) {
        checkEnrich(newVal);
      }
    },
  );

  onMounted(() => {
    if (item.value) {
      enrichedComment.value = null;

      if (isValidUrl(item.value.body)) {
        checkEnrich(item.value.body);
      }
    }

    setupTranslation();
  });
</script>

<template>
  <div :style="{ marginLeft: `${marginLeftPX}px` }" :id="item.id.toString()">
    <div class="relative">
      <span
        v-if="checkAdmin"
        class="absolute inline text-xs text-white top-3 right-3"
      >
        {{ item.lang }}
      </span>

      <overlay-loader v-if="isCommenting" />

      <div class="mx-4 border-t border-gray-700" />
      <div v-if="isDeleted" class="mx-4 my-5 text-sm text-gray-400">
        <span class="font-medium">Deleted</span>
        {{ fromNow(item.created_at) }}
      </div>

      <div
        v-else
        class="text-gray-1000"
        :class="item.highlighted ? 'bg-gray-700' : 'bg-gray-800'"
      >
        <div class="flex-grow p-4 sm:flex link__item">
          <div class="w-full link__main">
            <div class="flex flex-col">
              <div class="flex items-center mb-2 text-sm">
                <user-tooltip
                  :user="item.owner"
                  :portal-info-view="item.portal"
                  :color="item.owner_color"
                  avatar-size="md"
                />
                <span class="ml-2 font-medium text-gray-400">
                  {{ fromNow(item.created_at) }}
                </span>
                <span class="ml-3">
                  <TipDisplay :content-id="item.id" />
                </span>
              </div>

              <div v-if="edit" class="flex flex-wrap items-baseline">
                <comment-form
                  ref="editForm"
                  :body="item.body"
                  @submit="updateCommentSubmit"
                />
              </div>

              <div v-if="!edit" class="flex-wrap items-baseline">
                <div v-if="!isImage(item.body)">
                  <div
                    :class="{
                      'rainbow-text': hasRainbowRole(),
                    }"
                    class="break-words content-body"
                  >
                    <dynamic-content
                      :body="item.translatedBody ?? item.body"
                      :portal-info-view="item.portal"
                    />
                  </div>

                  <div
                    v-if="enrichedComment"
                    class="mt-1"
                    v-html="sanitizeHtml(enrichedComment, sanitizeSettings)"
                  />
                </div>

                <div v-else>
                  <a :href="item.body" target="_blank">
                    <img class="object-fill w-48" :src="item.body" />
                  </a>
                </div>
              </div>
              <comment-actions
                :item="item"
                :can-translate="canTranslate"
                :is-vote-tip-shown="isVoteTipShown"
                :disable-voting="isVoting"
                v-model:show-vote-tip="isVoteTipShown"
                @edit="toggleEdit"
                @replying="toggleReply"
                @translate="translate"
                @delete="deleteFeedComment(item.id)"
                @remove="removeFeedComment(item.id)"
                @vote="vote"
                @report="report(item.id)"
                @set-nsfw="setFeedNSFW(item.id, $event)"
                @goodbye="onSetUserRank(item.owner, BigInt(0))"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="shouldAttachReplyForm"
        class="mx-4 mb-2"
        :class="{ hidden: !isReplyToggled, block: isReplyToggled }"
      >
        <comment-form ref="createForm" @submit="createCommentSubmit" />
      </div>
    </div>

    <div
      v-if="item && item.children && item.children.length > 0"
      @click="toggleChildren(item.id)"
    >
      <div
        v-if="!item.hideChildren"
        class="mb-2 ml-4 text-gray-400 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span class="ml-1 text-xs hover:underline">hide comments</span>
      </div>

      <div v-else class="mb-2 ml-4 text-gray-400 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span class="ml-1 text-xs hover:underline">
          show comments ({{ item.children.length }})
        </span>
      </div>
    </div>
  </div>
</template>
