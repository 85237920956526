<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { type MaybeElement } from '@vueuse/core';
  import {
    type ModeratorActionType,
    type ChatUserRole,
    type LiveChatUser,
    liveChatModel,
  } from '@/entities/live';
  import { useEmbeddedMention } from '@/composables';
  import { useUser } from '@/entities/user';
  import { useShareDialog } from '@/shared/model';
  import { getFallbackIcon } from '@/shared/lib';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';

  const moderatorDropdownRef = ref<MaybeElement | null>(null);

  interface ModeratorActionPayload {
    peer: LiveChatUser;
    action: ModeratorActionType;
  }

  const emit = defineEmits<{
    (e: 'moderator-action', action: ModeratorActionPayload): void;
  }>();

  interface LiveUserProps {
    user: LiveChatUser;
    isViewerModerator: boolean;
  }

  const props = withDefaults(defineProps<LiveUserProps>(), {
    isViewerModerator: () => false,
  });

  const { copyPrincipalId } = useUser();
  const { openShareUserProfile } = useShareDialog();
  const { fetchUsers, usersHash } = useEmbeddedMention();
  const { hostPrincipal } = liveChatModel.use();

  const nativeUser = computed(() =>
    usersHash.value.get(props.user.username.toLowerCase()),
  );
  const isHost = computed(
    () =>
      nativeUser.value && nativeUser.value.id.toText() === hostPrincipal.value,
  );

  const userRole = computed(() => {
    if (isHost.value) {
      return 'Host';
    }

    const roleMap = {
      moderator: 'Co-Host',
      listener: 'Guest',
      speaker: 'Speaker',
    };
    return roleMap[props.user.roleName as ChatUserRole];
  });

  /**
   *
   * @param action
   */
  function onModeratorAction(action: ModeratorActionType) {
    emit('moderator-action', { peer: props.user, action: action });
  }

  const showModeratorTools = computed(() => {
    return props.isViewerModerator && !isHost.value;
  });

  const isModerator = computed(() => props.user.roleName === 'moderator');

  const isSpeaker = computed(() => props.user.roleName === 'speaker');

  const isSpeakerOrModerator = computed(
    () => isModerator.value || isSpeaker.value,
  );

  const isHandRaised = computed(() => {
    return props.user.metadata?.isHandRaised as boolean;
  });

  const userIconUrl = computed(() => {
    if (!props.user || !props.user.icon)
      return getFallbackIcon(props.user.username);
    return props.user.icon;
  });

  onMounted(() => fetchUsers([props.user.username]));
</script>

<template>
  <base-dropdown placement="left-end" append-to-body :distance="-80">
    <template #button="{ toggle }">
      <div
        class="group flex flex-col items-center justify-center p-5 rounded-2.5xl hover:bg-white hover:bg-opacity-5"
        @click="toggle"
      >
        <div class="relative">
          <img
            class="rounded-full w-14 h-14 border-2 border-gray-750 mb-2"
            :src="userIconUrl"
          />
          <span
            v-if="user.metadata.emoji"
            class="absolute top-0 right-0 w-6 h-6 text-base text-center text-white bg-gray-900 rounded-full"
          >
            <base-icon :name="user.metadata.emoji.icon" size="w-6 h-6" />
          </span>
          <base-icon
            v-else-if="isHandRaised && !isSpeakerOrModerator"
            name="emoji-waving-hand"
            size="w-9 h-9"
            class="absolute top-0 right-0"
          />
        </div>

        <div
          class="font-bold text-sm text-center truncate max-w-28"
          :class="isSpeakerOrModerator ? 'text-white' : 'text-gray-300'"
        >
          {{ user.username }}
        </div>

        <div
          class="text-xs flex justify-center items-center text-gray-300 mt-2 relative -left-2"
        >
          <base-icon
            v-if="user.isAudioEnabled"
            name="mini-mic-on"
            size="w-4 h-4"
            class="text-purple-400"
          />
          <base-icon
            v-else
            name="mini-mic-off"
            size="w-4 h-4"
            class="text-red-500"
          />
          <span class="ml-1">
            {{ userRole }}
          </span>
        </div>

        <div class="flex justify-center items-center hidden md:block">
          <base-icon
            name="ellipsis"
            data-dropdown-toggle="dropdownHover"
            data-dropdown-trigger="hover"
            size="w-4 h-4"
            class="text-white inline invisible group-hover:visible"
          />
        </div>
      </div>
    </template>

    <template #content="{ hide }">
      <!-- Dropdown menu -->
      <div
        :id="`dropdownHover-${user.peerId}`"
        ref="moderatorDropdownRef"
        class="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl relative overflow-hidden"
      >
        <ul class="flex flex-col" aria-labelledby="dropdownHoverButton">
          <li
            v-if="showModeratorTools && isSpeakerOrModerator"
            class="flex space-x-3 hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all px-3 py-5 text-gray-300 cursor-pointer hover:text-white"
            @click="onModeratorAction('make-listener'), hide()"
          >
            <base-icon name="mini-mic-off" size="w-5 h-5" />
            <span class="whitespace-nowrap">{{ $t('guest') }}</span>
          </li>
          <li
            v-if="showModeratorTools && !isSpeakerOrModerator"
            class="flex space-x-3 hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all px-3 py-5 text-gray-300 cursor-pointer hover:text-white"
            @click="onModeratorAction('make-speaker'), hide()"
          >
            <base-icon name="sound" size="w-5 h-5" />
            <span class="whitespace-nowrap">{{ $t('speaker') }}</span>
          </li>
          <li
            v-if="showModeratorTools"
            class="border-t border-white border-opacity-12 mx-3"
          ></li>
          <li
            v-if="showModeratorTools && !isModerator"
            class="flex space-x-3 hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all px-3 py-5 text-gray-300 cursor-pointer hover:text-white"
            @click="onModeratorAction('make-moderator'), hide()"
          >
            <span class="whitespace-nowrap">{{ $t('makeCoHost') }}</span>
          </li>

          <li
            v-if="showModeratorTools"
            class="border-t border-white border-opacity-12 mx-3"
          ></li>

          <li
            v-if="nativeUser"
            class="flex space-x-3 hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all px-3 py-5 text-gray-300 cursor-pointer hover:text-white"
            @click="copyPrincipalId(nativeUser), hide()"
          >
            <span class="whitespace-nowrap">{{ $t('copyPrincipal') }}</span>
          </li>

          <li
            v-if="nativeUser"
            class="flex space-x-3 hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all px-3 py-5 text-gray-300 cursor-pointer hover:text-white"
            @click="hide(), openShareUserProfile(user.username)"
          >
            <span class="whitespace-nowrap">{{ $t('shareProfile') }}</span>
          </li>

          <li
            v-if="showModeratorTools"
            class="border-t border-white border-opacity-12 mx-3"
          ></li>

          <li
            v-if="showModeratorTools && !isHost"
            class="flex space-x-3 hover:bg-gradient-to-r transition-all px-3 py-5 text-red-500 hover:text-red-400 cursor-pointer"
            @click="onModeratorAction('ban-user'), hide()"
          >
            <span class="whitespace-nowrap">{{ $t('kick') }}</span>
          </li>
        </ul>
      </div>
    </template>
  </base-dropdown>
</template>
