import type { VerifyAppTransactionResult } from 'dfx/edge/edge.did';
import { dscvrApi } from '@/shared/api';

export const parseTransactionScan = (
  scanResponseResult?: VerifyAppTransactionResult,
) => {
  if (!scanResponseResult) return;
  if (
    !('Ok' in scanResponseResult) ||
    scanResponseResult.Ok.length != 1 ||
    !('Blowfish' in scanResponseResult.Ok[0])
  ) {
    if ('Err' in scanResponseResult) {
      console.error('Canvas tx: Scan response error', scanResponseResult.Err);
    }
    return;
  }

  const jsonResponse = scanResponseResult.Ok[0].Blowfish;
  const scanResponse = JSON.parse(
    jsonResponse,
  ) as dscvrApi.blowfish.ScanTransactionsSolana200Response;

  return scanResponse;
};
