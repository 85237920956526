import { StoicIdentity } from 'ic-stoic-identity';

export class StoicClient {
  constructor() {
    this.debug = import.meta.env.DEV;
    this.enviro = {
      host: import.meta.env.VITE_EDGE_URL,
      canisterId: import.meta.env.VITE_CANISTER_ID,
    };
  }

  async login() {
    this.identity = await StoicIdentity.connect();
    return this.identity;
  }

  async connect() {
    return await StoicIdentity.load();
  }

  isConnected() {
    return this.agent != null;
  }

  async getAgent() {
    return this.agent;
  }
}
export const stoicClient = new StoicClient();
