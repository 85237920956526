<template>
  <div class="relative flex items-center">
    <button
      ref="claimButton"
      class="justify-center w-full btn-claim z-10"
      :disabled="isDisabled"
      @click="claimTokens"
    >
      {{ isDisabled ? $t('claimed') : text }}
    </button>
    <div ref="claimSparkles" class="absolute top-0 z-0 w-full h-full"></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '@/store/action-types';
  import { playSparkles } from '@/utils/ubi';
  import { trackEvent } from '@/utils/tracker';
  import { useAuth } from '@/entities/auth';

  export default {
    name: 'ubi-claim-button',
    props: {
      sparkleSize: {
        type: Number,
        default: 80,
      },
      text: {
        type: String,
        default: 'Claim Points',
      },
      amount: {
        type: Number,
        default: 5,
      },
    },
    setup() {
      const { showLoginSignUpDialog } = useAuth();
      return { showLoginSignUpDialog };
    },
    data() {
      return {
        claimed: false,
      };
    },
    computed: {
      ...mapGetters({
        isLoading: `ubi/isLoading`,
        successfullyClaimed: `ubi/successfullyClaimed`,
        currentUser: 'auth/me',
      }),
      isDisabled() {
        return this.successfullyClaimed || this.claimed;
      },
    },
    methods: {
      claimTokens() {
        if (!this.currentUser) {
          this.showLoginSignUpDialog();
        } else {
          playSparkles(this.$refs.claimSparkles, this.sparkleSize);
          this.claimed = true;

          this.$store.dispatch(`ubi/${ActionTypes.CLAIM_REWARD}`);
          trackEvent('ubi_action', 'claim');
        }
      },
    },
  };
</script>

<style scoped>
  button[disabled] {
    @apply bg-indigo-600 cursor-auto opacity-40 transition-opacity duration-150;
  }
</style>
