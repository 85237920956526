import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyOtpCodeQueryKey } from './keys';

export const useGetMyOtpCodeQuery = (enabled: Ref<boolean>) => {
  return useQuery({
    queryKey: getMyOtpCodeQueryKey,
    queryFn: async () => {
      const response = await dscvrApi.me.getMyOtpCode();
      return response?.code ?? null;
    },
    enabled,
    // otp expires every 60 seconds, so we generate every 30 seconds
    refetchInterval: 30_000,
  });
};
