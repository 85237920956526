import { type Ref, computed } from 'vue';
import { sortBy, unescape } from 'lodash-es';
import {
  isBlacklistedDomain,
  portalMentionRegExp,
  postMentionRegExp,
  removePreCodeTags,
  lazyImageRegExp,
  imageRegExp,
  iframeRegExp,
  videoRegExp,
  magicEdenPreviewRegExp,
  magicEdenRegExp,
  streakPreviewRegExp,
  framePreviewRegExp,
  canvasPreviewRegExp,
  liteYoutubeRegExp,
  gimluckReviewRegExp,
} from '@/utils';
import type {
  ComputedEmbed,
  ComputedEmbedType,
  ComputedMedia,
  ComputedMediaType,
} from './types';

type RetrievalRegExp<T> = {
  regExp: RegExp;
  type: T;
};

type EmbedRetrievalRegExp = RetrievalRegExp<ComputedEmbedType>;
type MediaRetrievalRegExp = RetrievalRegExp<ComputedMediaType>;

/**
 *
 * @param body
 */
function retrieveEmbed(body: string): ComputedEmbed[] {
  const embedRegExpList: EmbedRetrievalRegExp[] = [
    { regExp: postMentionRegExp, type: 'post' },
    { regExp: portalMentionRegExp, type: 'portal' },
    { regExp: magicEdenPreviewRegExp, type: 'nft' },
    { regExp: magicEdenRegExp, type: 'nft' },
    { regExp: streakPreviewRegExp, type: 'streak' },
    { regExp: gimluckReviewRegExp, type: 'gimluck' },
    { regExp: framePreviewRegExp, type: 'frame' },
    { regExp: canvasPreviewRegExp, type: 'canvas' },
  ];

  const embedMatches = embedRegExpList.reduce((list, embedRegExp) => {
    const matches: RegExpMatchArray[] = [...body.matchAll(embedRegExp.regExp)];
    const computedEmbedList = matches.map((match) => ({
      type: embedRegExp.type,
      content: match[0],
      ref: match.groups?.id || '',
      index: match.index || 0,
      ...(match.groups && { ...match.groups }),
    }));
    return [...list, ...computedEmbedList];
  }, [] as ComputedEmbed[]);

  return sortBy(embedMatches, (match) => match.index);
}

/**
 *
 * @param body
 */
function retrieveMedia(body: string): ComputedMedia[] {
  const mediaRegExpList: MediaRetrievalRegExp[] = [
    { regExp: lazyImageRegExp, type: 'image' },
    { regExp: imageRegExp, type: 'image' },
    { regExp: iframeRegExp, type: 'iframe' },
    { regExp: videoRegExp, type: 'video' },
    { regExp: liteYoutubeRegExp, type: 'iframe' },
  ];

  const mediaMatches = mediaRegExpList.reduce((list, mediaRegExp) => {
    const matches: RegExpMatchArray[] = [...body.matchAll(mediaRegExp.regExp)];
    const computedMediaList = matches.map((match) => ({
      type: mediaRegExp.type,
      content: match[0],
      // Since the src attribute is HTML encoded, we need to decode it
      src: unescape(match.groups?.src || ''),
      index: match.index || 0,
    }));
    return [...list, ...computedMediaList];
  }, [] as ComputedMedia[]);

  return sortBy(mediaMatches, (match) => match.index);
}

/**
 *
 * @param body
 */
function removeEmptyTagsInHtml(body: string): string {
  const trimHtmlRegExp = /<[^/>]+>[ \n\r\t]*<\/[^>]+>/g;
  return body.replace(trimHtmlRegExp, '');
}

/**
 *
 * @param inputBody
 * @param mediaList
 * @param embedList
 */
function retrieveText(
  inputBody: string,
  mediaList: ComputedMedia[],
  embedList: ComputedEmbed[],
): string {
  let body = mediaList.reduce((text, media) => {
    return text.replace(media.content, '');
  }, inputBody);
  body = embedList.reduce((text, media) => {
    return text.replace(media.content, '');
  }, body);
  body = removePreCodeTags(body);
  return removeEmptyTagsInHtml(body);
}

/**
 *
 * @param body
 */
export function useIsolateHtmlParts(body: Ref<string>) {
  const fullMediaList = computed(() => retrieveMedia(body.value));
  const embedList = computed(() => retrieveEmbed(body.value));
  const text = computed(() =>
    retrieveText(body.value, fullMediaList.value, embedList.value),
  );

  const mediaList = computed(() =>
    fullMediaList.value.filter(({ src }) => isBlacklistedDomain(src)),
  );

  return {
    text,
    embedList,
    mediaList,
    retrieveMedia,
  };
}
