<script lang="ts" setup>
  import { useRoute, useRouter } from 'vue-router';
  import { computed, ref, watch } from 'vue';
  import SearchFilterBar from '@/components/bars/SearchFilterBar.vue';
  import SearchedPortalsList from '@/components/portals/SearchedPortalsList.vue';
  import { SearchedUsersList } from './components';
  import { SEARCH } from '@/common';
  import { PostList } from '@/widgets/post-list';
  import { useChainBehavior } from '@/entities/user-setting';
  import { useFeedFilter } from '@/widgets/feed-filter';
  import {
    useFeed,
    type FeedOptions,
    searchFeedKey,
    listContentKey,
  } from '@/shared/model';
  import { useHead } from '@unhead/vue';

  type Tab = 'All' | 'Posts' | 'Portals' | 'Users';

  const route = useRoute();
  const router = useRouter();
  const { contentPreset } = useChainBehavior();
  useHead({
    title: 'Search - DSCVR',
  });

  const searchQuery = computed(() => {
    return route.query.q?.toString() ?? '';
  });

  const feedOptions = computed<FeedOptions>(() => ({
    key: searchFeedKey,
    type: listContentKey,
    filter: 'search',
    chain_filter: contentPreset.value,
    query: searchQuery.value ? [searchQuery.value.trim()] : [],
  }));

  const {
    isLoading,
    onComment,
    onDeleteContent,
    onEditContent,
    onFetchNextPage,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
    personalizedFeedV1Query,
    posts,
  } = useFeed(feedOptions);

  const { dateFilterQueryParameter, sortFilterQueryParameter } =
    useFeedFilter();
  const activeTab = ref<Tab>('All');

  const tabs: Tab[] = ['All', 'Posts', 'Users', 'Portals'];

  const portalsShownCount = computed(() => {
    return activeTab.value === 'Portals' ? 20 : 4;
  });

  const usersShownCount = computed(() => {
    return activeTab.value === 'Users' ? 20 : 4;
  });

  const onUpdateActiveTab = (tab: Tab) => {
    activeTab.value = tab;
    router.push({ name: SEARCH, query: { ...route.query, tab } });
  };

  watch(
    () => route.query,
    (query) => {
      if (query.tab) {
        activeTab.value = query.tab as Tab;
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    [
      () => route.query.sort,
      () => route.query.sortDateRange,
      () => route.query.q,
    ],
    ([sortValue, _]) => {
      if (personalizedFeedV1Query.value) {
        personalizedFeedV1Query.value.query.sort =
          sortFilterQueryParameter.value;
        personalizedFeedV1Query.value.query.max_age =
          sortValue === 'top' && dateFilterQueryParameter.value
            ? [BigInt(dateFilterQueryParameter.value)]
            : [];
        personalizedFeedV1Query.value.query.query = [searchQuery.value.trim()];
      }
    },
    { immediate: true },
  );
</script>

<template>
  <section class="w-full">
    <div
      v-if="searchQuery"
      class="flex gap-3 mx-4 md:mx-0"
      :class="activeTab === 'Posts' ? 'mb-0' : 'mb-5'"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="px-4 py-2 text-white rounded-lg cursor-pointer"
        :class="activeTab != tab ? 'opacity-60' : 'bg-gray-700'"
        @click="onUpdateActiveTab(tab)"
      >
        {{ tab }}
      </div>
    </div>
    <searched-portals-list
      v-if="activeTab === 'All' || activeTab === 'Portals'"
      :hide-header="activeTab === 'Portals'"
      :search="searchQuery"
      :shown-count="portalsShownCount"
    />
    <searched-users-list
      v-if="activeTab === 'All' || activeTab === 'Users'"
      :hide-header="activeTab === 'Users'"
      :search="searchQuery"
      :shown-count="usersShownCount"
    />
    <template v-if="activeTab === 'All' || activeTab === 'Posts'">
      <search-filter-bar
        v-if="activeTab === 'All' || activeTab === 'Posts'"
        :hide-header="activeTab === 'Posts'"
        class="mb-3"
      />
      <post-list
        :posts="posts"
        :is-loading="isLoading"
        @fetch-next-page="onFetchNextPage"
        @translate-content="onTranslateContent"
        @delete-content="onDeleteContent"
        @remove-content="onRemoveContent"
        @edit-content="onEditContent"
        @pin-content="onPinContent"
        @pin-content-to-profile="onPinContentToProfile"
        @set-nsfw="onSetNsfw"
        @goodbye="onGoodbye"
        @set-rank="onSetRank"
        @on-comment="onComment"
        @on-poll-vote="onPollVote"
      />
    </template>
  </section>
</template>
