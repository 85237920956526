<template>
  <div class="mx-4 text-white height md:mx-0">
    <div
      class="flex flex-col md:flex-row md:items-center"
      :class="{
        'justify-end': hideHeader,
        'justify-between': !hideHeader,
      }"
    >
      <div v-if="!hideHeader" class="flex items-center">
        <div class="text-lg font-bold">Posts</div>
        <div
          class="flex items-center justify-center mt-0.5 ml-2 text-sm text-indigo-300 cursor-pointer"
          @click="setActiveTab"
        >
          <span>View More</span>
          <base-icon name="chevron-right" size="size-4" />
        </div>
      </div>
      <div class="flex items-center mt-1 md:mt-0">
        <div class="flex items-center">
          <div></div>
          <default-dropdown
            :options="relevanceOptions"
            :selected="
              sortBy ? relevanceOptions.find((x) => x.id == sortBy) : null
            "
            @selected="relevanceChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { SEARCH } from '../../common';
  import DefaultDropdown from '../inputs/DefaultDropdown.vue';

  export default {
    name: 'search-filter-bar',
    components: { DefaultDropdown },
    props: {
      hideHeader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        relevanceOptions: [
          { id: 'hot', label: 'Hot' },
          { id: 'top', label: 'Top' },
          { id: 'new', label: 'New' },
        ],
        timeOptions: [
          { id: 'hour', label: 'Last Hour' },
          { id: 'day', label: 'Today' },
          { id: 'week', label: 'This Week' },
          { id: 'month', label: 'This Month' },
          { id: 'year', label: 'This Year' },
          { id: 'all', label: 'All Time' },
        ],
        sortBy: 'hot',
        sortDateRange: 'all',
      };
    },
    watch: {
      $route: {
        immediate: true,
        deep: true,
        handler() {
          this.sortBy = this.$route.query.sort ?? 'hot';
          this.sortDateRange = this.$route.query.sortDateRange ?? 'hot';
        },
      },
    },
    methods: {
      setActiveTab() {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.tab = 'Posts';
        this.$router.push({
          name: SEARCH,
          query: query,
        });
      },
      relevanceChanged(option) {
        this.sortBy = option.id;
        this.sortAction(this.sortBy, this.sortDateRange);
      },
      timeChanged(option) {
        this.sortDateRange = option.id;
        this.sortAction(this.sortBy, this.sortDateRange);
      },
      sortAction(sortBy, sortDateRange = 'all') {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.sort = sortBy;
        query.sortDateRange = sortDateRange;
        this.$router.push({
          name: SEARCH,
          query: query,
        });
      },
    },
  };
</script>
