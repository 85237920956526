<template>
  <div class="text-white height">
    <div v-if="!hideHeader" class="flex items-center mx-4 mb-4 md:mx-0">
      <div class="text-lg font-bold">Users</div>
      <div
        class="flex items-center justify-center mt-0.5 ml-2 text-sm text-indigo-300 cursor-pointer"
        @click="setActiveTab"
      >
        <span>View More</span>
        <base-icon name="chevron-right" size="size-4" />
      </div>
    </div>
    <div
      class="relative w-full p-4 mb-4 text-gray-300 bg-gray-950 shadow-lg post-item md:rounded-xl"
    >
      <div class="flex flex-col gap-4">
        <div v-for="user in users" :key="user.id" class="flex justify-between">
          <router-link
            :to="{
              name: USER_CONTENT,
              params: { username: user.username },
            }"
            class="flex items-center justify-between"
          >
            <div class="flex items-center">
              <user-avatar
                v-if="user"
                :item="user"
                size="w-10 h-10"
                class="mr-3"
              />
              <div class="flex flex-col truncate max-w-40 md:max-w-full">
                <div class="max-w-full mb-1 text-base font-bold">
                  {{ user.username }}
                </div>
                <div class="flex text-sm">
                  <div class="mr-2">
                    {{ getDisplayAmount(BigInt(user.followers), true) }}
                    {{ pluralize(user.followers, $t('follower')) }}
                  </div>
                  <div class="hidden sm:block">
                    {{ Number(user.following) }} Following
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          <div
            v-if="!currentUser || user.id.toText() !== currentUser.id.toText()"
            class="pt-2 mr-2 max-w-36"
          >
            <follow-user-button
              :user="user"
              @update:is-following="
                (isFollowing) =>
                  isFollowing ? user.followers++ : user.followers--
              "
            />
          </div>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="flex items-center justify-center space-x-4"
        :class="{
          'h-12': hideHeader && users?.length,
          'h-56': !hideHeader || (hideHeader && !users?.length),
        }"
      >
        <div class="relative pt-2 text-center text-white w-14 h-14">
          <div
            class="transform translate-x-1.5 translate-y-6 spinner-big"
          ></div>
        </div>
      </div>
      <div
        v-else-if="users == null || users.length == 0"
        class="flex items-center justify-center space-x-4 min-h-56"
      >
        <div class="flex flex-col items-center justify-center">
          <div class="text-xl">
            <a
              href="https://www.youtube.com/watch?v=DtFhreFhSfU"
              target="_blank"
            >
              Oops </a
            >, no users found
          </div>
          <div class="flex flex-col items-center mt-3 text-base text-gray-400">
            <div class="min-w-max">Try searching for something else!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ActionTypes } from '@/store/action-types';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { SEARCH, USER_CONTENT } from '@/common';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { useUser } from '@/entities/user';
  import { pluralize, getDisplayAmount } from '@/shared/lib';
  import { useDocumentScroll } from '@/shared/model';
  import { useChainBehavior } from '@/entities/user-setting';

  export default {
    name: 'searched-users-list',
    components: {
      UserAvatar,
      FollowUserButton,
    },
    props: {
      hideHeader: {
        type: Boolean,
        default: false,
      },
      shownCount: {
        type: Number,
        default: 4,
      },
      search: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        USER_CONTENT,
        noMore: false,
        shownOffset: 0,
        totalPages: 0n,
        users: [],
        isLoading: true,
        pluralize,
        getDisplayAmount,
      };
    },
    watch: {
      search() {
        this.noMore = false;
        this.fetchUsers(false);
      },
      shownCount() {
        this.noMore = false;
        this.fetchUsers(false);
      },
      contentPreset() {
        this.noMore = false;
        this.fetchUsers(false);
      },
    },
    async mounted() {
      useDocumentScroll({
        bottomReached: () => {
          if (
            !this.isLoading &&
            !this.noMore &&
            this.shownOffset < this.totalPages - 1n
          ) {
            this.fetchUsers(true);
          }
        },
      });
      await this.fetchUsers(false);
    },
    setup() {
      const { currentUser } = useUser();
      const { contentPreset } = useChainBehavior();
      return {
        contentPreset,
        currentUser,
      };
    },
    methods: {
      async fetchUsers(additive) {
        if (additive) {
          this.isLoading = true;
          this.shownOffset += 1;
        }
        if (this.search) {
          await this.$store
            .dispatch(`auth/${ActionTypes.GET_SEARCHED_USERS_PAGINATED}`, {
              page_size: BigInt(this.shownCount),
              page: BigInt(this.shownOffset),
              query: this.search,
              chain_filter: this.contentPreset,
              sort_by: [],
              sort_direction: [],
            })
            .then((response) => {
              if (response.result.length > 0) {
                this.totalPages = response.result[0].total_pages;
                if (response.result[0].items.length == 0) {
                  this.noMore = true;
                } else {
                  response.result[0].items.forEach(
                    (x) => (x.is_following = false),
                  );
                  if (additive === false) {
                    this.users = response.result[0].items;
                  } else {
                    this.users = this.users.concat(response.result[0].items);
                  }
                }
              } else {
                this.noMore = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      setActiveTab() {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.tab = 'Users';
        this.$router.replace({
          name: SEARCH,
          query: query,
        });
      },
    },
  };
</script>
<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(20px);
  }
  .no-results {
    min-height: 180px;
  }
</style>
