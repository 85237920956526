import type { MaybeRef } from 'vue';
import type { Finality, GetVersionedTransactionConfig } from '@solana/web3.js';

export const walletTransactionQueryKey = 'wallet-transaction';
export const getTransactionDetailsQueryKey = (
  chainId: MaybeRef<string | undefined>,
  transactionId: MaybeRef<string | undefined>,
  config: MaybeRef<GetVersionedTransactionConfig | Finality | undefined>,
) => [
  walletTransactionQueryKey,
  'get-transaction-details',
  chainId,
  transactionId,
  config,
];
export const getTransactionScanQueryKey = (
  appUrl: MaybeRef<string | undefined>,
  chainId: MaybeRef<string | undefined>,
  transactionId: MaybeRef<string | undefined>,
) => [
  walletTransactionQueryKey,
  'get-transaction-details',
  appUrl,
  chainId,
  transactionId,
];
