<script setup lang="ts">
  import { computed, onMounted, watch, ref } from 'vue';
  import { useHead } from '@unhead/vue';
  import { useQueryClient } from '@tanstack/vue-query';
  import { Loader } from '@/shared/ui/loader';
  import {
    tokenQueryKey,
    type FungibleToken,
    type NonFungibleToken,
    type SolanaFungibleToken,
    type SolanaNonFungibleToken,
  } from '@/entities/token';
  import { useWalletPairing, type WalletOption } from '@/entities/wallets';
  import {
    lootboxClaimQueryKey,
    useGetOpenedLootboxesItemsQuery,
  } from '@/entities/lootbox';
  import {
    EmptyWallet,
    usePairDialog,
    useWalletOptions,
  } from '@/features/wallets';
  import WalletsHeader from './components/WalletsHeader.vue';
  import WalletsContent from './components/WalletsContent.vue';
  import UnpairedState from './components/UnpairedState.vue';

  const props = defineProps<{
    username: string;
  }>();

  const selectedWalletOption = ref<WalletOption>();

  const queryClient = useQueryClient();
  const { isPairing } = useWalletPairing();
  const { openAddWalletDialog } = usePairDialog();
  const { data: openLootboxesItems, isFetching: isFetchingLootboxItems } =
    useGetOpenedLootboxesItemsQuery();
  useHead({
    title: `${props.username}'s wallet - DSCVR`,
  });

  const {
    allWalletsOption,
    walletOptions,
    hasWalletOptions,
    isWalletOptionsLoading,
    isFTsLoading,
    fungibleTokens,
    isNFTsLoading,
    nonFungibleTokens,
    fetchWalletOptions,
  } = useWalletOptions();

  const isAllOptionSelected = computed(
    () =>
      !selectedWalletOption.value ||
      selectedWalletOption.value?.id === allWalletsOption.value.id,
  );

  const filterFTByWallet = (token: FungibleToken) => {
    if (isAllOptionSelected.value) {
      return token.chain === 'icp' || !token.ownerWalletAddress;
    }
    if (selectedWalletOption.value?.chain === 'icp') {
      return token.chain === 'icp';
    }
    const solanaToken = token as SolanaFungibleToken;
    return (
      selectedWalletOption.value?.address === solanaToken.ownerWalletAddress
    );
  };

  const filterNFTByWallet = (token: NonFungibleToken) => {
    if (selectedWalletOption.value?.chain === 'icp') {
      return token.chain === 'icp';
    }
    const solanaToken = token as SolanaNonFungibleToken;
    return (
      selectedWalletOption.value?.address === solanaToken.ownerWalletAddress
    );
  };

  const filteredNonFungibleTokens = computed(() => {
    if (isAllOptionSelected.value) {
      return nonFungibleTokens.value;
    }
    return nonFungibleTokens.value.filter(filterNFTByWallet);
  });

  const filteredFungibleTokens = computed(() => {
    return fungibleTokens.value.filter(filterFTByWallet);
  });

  const claimedLootboxItems = computed(() =>
    openLootboxesItems.value.filter((item) => item.isClaimed),
  );

  const unclaimedLootboxItems = computed(() =>
    openLootboxesItems.value.filter((item) => !item.isClaimed),
  );

  const isEmpty = computed(
    () =>
      !filteredNonFungibleTokens.value.length &&
      !filteredFungibleTokens.value.length &&
      !claimedLootboxItems.value.length,
  );

  const isLoading = computed(
    () =>
      isPairing.value ||
      isWalletOptionsLoading.value ||
      isFTsLoading.value ||
      isNFTsLoading.value ||
      isFetchingLootboxItems.value,
  );

  const loadDefaultSelectedWallet = () => {
    if (
      selectedWalletOption.value &&
      walletOptions.value.some(
        (option) => option.id === selectedWalletOption.value?.id,
      )
    ) {
      return;
    }

    selectedWalletOption.value =
      walletOptions.value.find((option) => option.chain === 'all') ||
      walletOptions.value[0];
  };

  const refreshAll = async () => {
    await fetchWalletOptions();
    loadDefaultSelectedWallet();
    await queryClient.invalidateQueries({
      queryKey: [tokenQueryKey],
    });
    await queryClient.invalidateQueries({
      queryKey: [lootboxClaimQueryKey],
    });
  };

  const addWallet = async () => {
    const added = await openAddWalletDialog();
    if (added) {
      refreshAll();
    }
  };

  const setActiveWallet = (option: WalletOption) => {
    selectedWalletOption.value = option;
    queryClient.invalidateQueries({
      queryKey: [tokenQueryKey],
    });
  };

  watch(isPairing, (value) => {
    if (!value) {
      refreshAll();
    }
  });

  onMounted(async () => {
    await fetchWalletOptions();
    loadDefaultSelectedWallet();
  });
</script>

<template>
  <template v-if="hasWalletOptions">
    <wallets-header
      :wallet-options="walletOptions"
      :is-loading="isLoading"
      :selected-wallet-option="selectedWalletOption"
      @add-wallet="addWallet"
      @select-wallet="setActiveWallet"
    />

    <!-- Wallet with Assets -->
    <wallets-content
      :selected-wallet-option="selectedWalletOption"
      :is-loading-fungies="isFTsLoading"
      :is-loading-nfts="isNFTsLoading"
      :is-loading-lootbox-items="isFetchingLootboxItems"
      :nfts="filteredNonFungibleTokens"
      :fts="filteredFungibleTokens"
      :claimed-lootbox-items="claimedLootboxItems"
      :unclaimed-lootbox-items="unclaimedLootboxItems"
    />

    <!-- Wallet is Paired, but Empty -->
    <template v-if="!isLoading && isEmpty">
      <!-- New UI, SOL || ALL -->
      <empty-wallet
        v-if="selectedWalletOption?.chain !== 'icp'"
        class="border-gray-785 border-opacity-80 bg-gray-950"
      />
    </template>
  </template>
  <!-- Wallet is Not Paired -->
  <unpaired-state v-else-if="!isLoading" @add-wallet="addWallet" />
  <div v-else-if="isLoading" class="relative w-full h-48">
    <loader variant="rainbow" border-width="border" size="size-10" />
  </div>
</template>
