import type { Principal } from '@dfinity/principal';
import { client } from '@/utils/dfinity';
import type {
  ActionResultUser,
  ActionResultUserSearch,
  PortalView,
  RandomizedRecommendationQuery,
  UserFollowPaged,
  UserListPaged,
  UserPlatformPair,
  UserSearchQuery,
  UserView,
} from 'dfx/edge/edge.did';

export default {
  async isUserFollowing(userId: Principal) {
    if (client.actor) {
      return (await client.actor.is_user_following(userId)) as Promise<boolean>;
    }
    throw new Error('Actor is not defined');
  },
  async toggleFollowUser(userId: Principal) {
    if (client.actor) {
      return (await client.actor.follow_user_toggle(
        userId,
      )) as Promise<ActionResultUser>;
    }
    throw new Error('Actor is not defined');
  },
  async getUserPortals(userId: Principal) {
    if (client.actor) {
      return (await client.actor.get_user_portals(userId)) as Promise<
        PortalView[]
      >;
    }
    throw new Error('Actor is not defined');
  },
  async getUserBlocked(query: UserListPaged) {
    if (client.actor) {
      return (await client.actor.get_user_blocked(
        query,
      )) as Promise<UserFollowPaged>;
    }
    throw new Error('Actor is not defined');
  },
  async blockUserToggle(userId: Principal) {
    if (client.actor) {
      return (await client.actor.block_user_toggle(userId)) as Promise<
        [] | [boolean]
      >;
    }
    throw new Error('Actor is not defined');
  },
  getPairedPlatformsByUser(userId: Principal) {
    if (client.actor) {
      return client.actor.get_paired_platforms_by_user(userId) as Promise<
        UserPlatformPair[]
      >;
    }
    throw new Error('Actor is not defined');
  },
  getRandomizedRecommendedUsers(query: RandomizedRecommendationQuery) {
    if (client.edgeActor) {
      return client.edgeActor.get_randomized_recommended_users(
        query,
      ) as Promise<UserView[]>;
    }
    throw new Error('Edge actor is not defined');
  },
  searchPaginated(query: UserSearchQuery) {
    if (client.edgeActor) {
      return client.edgeActor.search_users(
        query,
      ) as Promise<ActionResultUserSearch>;
    }
    throw new Error('Edge actor is not defined');
  },
  getUserReferralCode() {
    if (client.actor) {
      return client.actor.get_user_referral_code() as Promise<string>;
    }
    throw new Error('Edge actor is not defined');
  },
  acceptUserInvite(query: string) {
    if (client.actor) {
      return client.actor.accept_user_invite(query) as Promise<string>;
    }
    throw new Error('Edge actor is not defined');
  },
};
