<script lang="ts" setup>
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
  import { computed } from 'vue';
  import EmbeddedLiteYoutube from '../preview/EmbeddedLiteYoutube.vue';

  const props = defineProps(nodeViewProps);

  const src = computed(() => props.node.attrs.src);
</script>

<template>
  <node-view-wrapper>
    <embedded-lite-youtube :src="src" />
  </node-view-wrapper>
</template>
