import { ref, type Ref } from 'vue';
import { useEventListener } from '@vueuse/core';
import { onTouchOutside } from '@/utils';

/**
 *
 * @param element
 */
export function useTouchFocus(element: Ref<HTMLElement | null>) {
  const touchFocus = ref(false);

  useEventListener(element, 'touchstart', () => {
    touchFocus.value = true;
  });
  onTouchOutside(element, () => {
    touchFocus.value = false;
  });

  return {
    touchFocus,
  };
}
