import { onUnmounted } from 'vue';
import { trackEvent } from '@/utils/tracker';
import { useI18n } from 'vue-i18n';
import { useToast } from '@/shared/model';
import { dscvrApi } from '@/shared/api';
import {
  WalletPairError,
  WalletNotInstalledError,
} from '../../model/types/error';
import type { PairDeeplinkParams } from '../../model/types/pairing';
import { POLYGON_CHAIN_ID } from '../../lib/evm/settings';
import { prepareSignedMessage } from '../../lib/message-to-sign';
import { useConnectEvmWallets } from './use-connect-evm-wallets';
import { useWalletPairing } from './use-wallet-pairing';
import { useWalletDialog } from './use-wallet-dialog';

export const usePairEvmWallet = () => {
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { openInstallMetamaskDialog } = useWalletDialog();
  const { providerEthers, connectProvider, cleanUpProvider } =
    useConnectEvmWallets();
  const { getNonce, pairWallet, finalizeWalletMobilePair } = useWalletPairing();

  const getPairWalletPayload = async (
    walletType: dscvrApi.wallet.WalletType,
    overrideNonce?: string,
  ) => {
    if (!providerEthers.value) {
      showToast({
        id: `${walletType}-wallet-link-error`,
        title: t('wallets.evm.providersNotFound'),
        type: 'error',
        durationSeconds: 3,
      });
      throw new WalletPairError();
    }

    const newNonce = overrideNonce ?? (await getNonce());
    if (!newNonce) {
      throw new Error('Failed to get nonce');
    }
    const signer = providerEthers.value.getSigner();
    const signerAddress = await signer.getAddress();
    const { rawMessage: messageToSign } = prepareSignedMessage(
      'ethereum',
      `eip155:${POLYGON_CHAIN_ID}`,
      signerAddress,
      newNonce,
    );
    const signature = await signer.signMessage(messageToSign);

    const payload: dscvrApi.wallet.LinkWalletDto = {
      signature: signature,
      message: messageToSign,
      address: signerAddress,
      network: 'polygon',
      walletType,
    };
    return payload;
  };

  const connectAndLinkWallet = async () => {
    try {
      await connectProvider();
      const pairPayload = await getPairWalletPayload('metamask');
      await pairWallet('metamask', pairPayload);
    } catch (error) {
      if (error instanceof WalletNotInstalledError) {
        openInstallMetamaskDialog();
      }
    } finally {
      trackEvent('user_settings_action', 'wallet_pair', 'metamask');
    }
  };

  const finalizeMobilePair = async (
    walletType: dscvrApi.wallet.WalletType,
    pairParams: PairDeeplinkParams,
  ) => {
    await connectProvider();
    const pairPayload = await getPairWalletPayload(
      walletType,
      pairParams.nonce,
    );

    return await finalizeWalletMobilePair(walletType, pairPayload, {
      ...pairParams,
    });
  };

  onUnmounted(cleanUpProvider);

  return {
    connectAndLinkWallet,
    finalizeMobilePair,
  };
};
