<script setup lang="ts">
  import { ref, computed, toRef } from 'vue';
  import VueQrcode from '@chenfengyuan/vue-qrcode';
  import { toSvg } from 'html-to-image';
  import { uniqueId } from 'lodash-es';
  import { useIsolateHtmlParts } from '@/composables';
  import CompletionImage from '../completion-image/CompletionImage.vue';
  import { shareImage } from '../../lib/share-image';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  const props = defineProps<{
    body: string;
  }>();

  const postBody = toRef(props, 'body');
  const { embedList } = useIsolateHtmlParts(postBody);

  const shareCard = ref<HTMLElement | null>(null);

  const username = computed(() => embedList.value[0].username);
  const days = computed(() => embedList.value[0].days);
  const socialLinks = computed(() => {
    const onboardingUrl = 'https://dscvr.one';
    return [
      {
        id: uniqueId(),
        name: 'Facebook',
        icon: 'social-facebook-desaturated',
        url: `https://www.facebook.com/sharer.php?u=${onboardingUrl}`,
      },
      {
        name: 'X',
        icon: 'social-twitter-desaturated',
        url: `https://x.com/intent/tweet?text=${username.value}%20unlocked%20an%20Airdrop%20Multiplier!&url=${onboardingUrl}`,
      },
      {
        name: 'LinkedIn',
        icon: 'social-linkedIn-desaturated',
        url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          onboardingUrl,
        )}&title=${username.value}%20unlocked%20an%20Airdrop%20Multiplier!`,
      },
    ];
  });

  const openSocialShare = (link: string) => {
    window.open(link, '_blank');
  };
  const downloadShareCard = async () => {
    if (!shareCard.value) return;
    const dataUrl = await toSvg(shareCard.value);
    const link = document.createElement('a');
    link.download = 'DSCVR-streak.svg';
    link.href = dataUrl;
    link.click();
  };
</script>

<template>
  <div
    ref="shareCard"
    class="py-4 px-7 rounded-2xl relative overflow-hidden bg-gradient-to-b"
    :class="shareImage[Number(days) - 1].gradientColors"
  >
    <div
      class="rounded-xl overflow-hidden border border-gray-700 bg-gradient-to-b from-black/40 to-black/80"
    >
      <div
        class="flex flex-col gap-2 -mt-[1px] -mx-[1px] p-4 rounded-xl rounded-b-2xl border border-gray-700"
        :class="shareImage[Number(days) - 1].backgroundColor"
      >
        <div class="flex justify-center items-center h-75">
          <completion-image :url="shareImage[Number(days) - 1].url" />
        </div>
        <div class="flex w-fill justify-between items-end mx-4">
          <img
            :src="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/common/dscvr-with-label.svg`,
              )
            "
            alt="DSCVR"
            class="w-[8.2rem] h-[2.5rem]"
          />
          <div class="bg-black/50 rounded-xl p-2 border border-gray-600">
            <vue-qrcode
              value="https://dscvr.one"
              :options="{ width: 42, margin: 1 }"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col justify-center items-center w-full px-4 py-6 text-xl font-medium tracking-[0.2px] break-all"
      >
        <span class="text-center">
          {{ username }} {{ $t('streak.shareDialog.unlockedAn') }}
        </span>
        <span class="text-indigo-300 capitalize">
          {{ $t('streak.airdropMultiplier') }}!
        </span>
      </div>
    </div>
  </div>
  <div class="my-6 flex justify-between">
    <ul
      class="py-2 px-3 rounded-full flex gap-x-4 border border-gray-700 w-fit opacity-70"
    >
      <li
        v-for="(link, index) in socialLinks"
        :key="index"
        @click="openSocialShare(link.url)"
        class="cursor-pointer hover:text-white text-gray-400 ease-transition-all"
      >
        <base-icon :name="link.icon" size="w-5 h-5" />
      </li>
    </ul>
    <div
      class="rounded-full border border-gray-700 p-2 bg-white/10 flex justify-center items-center cursor-pointer hover:bg-white/20 ease-transition-all"
      @click="downloadShareCard"
    >
      <base-icon name="download" size="w-5 h-5" />
    </div>
  </div>
</template>
