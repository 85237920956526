import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getTippedContentsPollingQueryKey } from './keys';

export const useAddTipTxMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['add-tip-tx-mutation'],
    mutationFn: (payload: dscvrApi.tip.AddTipTxPayload) =>
      dscvrApi.tip.addTipTx(payload),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: getTippedContentsPollingQueryKey,
      }),
  });
};
