<script setup lang="ts">
  import type { SortColumnDirection } from './types';

  const props = withDefaults(
    defineProps<{
      align?: 'left' | 'center' | 'right';
      sortActive?: boolean;
      sortDirection?: SortColumnDirection;
    }>(),
    {
      align: 'left',
      sortActive: false,
      sortDirection: 'asc',
    },
  );

  const emit = defineEmits<{
    (e: 'update:sortDirection', value: SortColumnDirection): void;
  }>();

  const toggleSort = () => {
    if (!props.sortActive) {
      emit('update:sortDirection', props.sortDirection);
      return;
    }
    if (props.sortDirection === 'asc') {
      emit('update:sortDirection', 'desc');
    } else {
      emit('update:sortDirection', 'asc');
    }
  };
</script>

<template>
  <th
    class="text-sm font-medium"
    :class="{
      'text-left': align === 'left',
      'text-center': align === 'center',
      'text-right': align === 'right',
    }"
  >
    <slot>
      <base-button
        variant="custom"
        custom-classes="flex items-center gap-1.5"
        @click="toggleSort"
      >
        <slot name="sortLabel" />
        <base-icon
          :name="
            sortDirection === 'desc'
              ? 'chevron-up-filled'
              : 'chevron-down-filled'
          "
          size="w-5 h-5"
          :class="{ 'opacity-50': !sortActive }"
        />
      </base-button>
    </slot>
  </th>
</template>
