import reportService from '../../services/reports';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';

// initial state
const state = () => ({
  isLoading: false,
  all: [],
});

// getters
const getters = {
  reports: (state) => {
    return state.all;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
};

// actions
const actions = {
  [ActionTypes.CREATE_REPORT]({ commit }, report) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return reportService
      .createReport(report)
      .then(() => {
        commit(MutationTypes.SET_IS_LOADING, false);
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.READ_REPORT]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return reportService
      .readReport(id)
      .then(() => {
        commit(MutationTypes.SET_IS_LOADING, false);
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ACTION_REPORT]({ commit }, action) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return reportService
      .actionReport(action)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.UPDATE_REPORT, result.result[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.LIST_REPORTS]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return reportService
      .getReports()
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        commit(MutationTypes.SET_REPORTS, result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
};

/**
 *
 * @param reports
 * @param id
 */
function getReport(reports, id) {
  for (let i = 0; i < reports.length; i++) {
    const report = reports[i];
    if (report) {
      if (report.id == id) {
        return report;
      }
    }
  }
  return null;
}

// mutations
const mutations = {
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
  [MutationTypes.SET_REPORTS](state, reports) {
    state.all = reports;
  },
  [MutationTypes.UPDATE_REPORT](state, report) {
    const r = getReport(state.all, report.id);
    if (r) {
      r.actor = report.actor;
      r.action = report.action;
      r.actioned_at = report.actioned_at;
      r.actor_id = report.actor_id;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
