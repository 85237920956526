import { useI18n } from 'vue-i18n';
import { useDialog, useToast } from '@/shared/model';
import { dscvrApi } from '@/shared/api';
import {
  useOpenLootboxMutation,
  buildLootboxCampaign,
} from '@/entities/lootbox';
import ClaimDialogContent from '../../components/ClaimDialogContent.vue';
import DialogContent from '../../components/DialogContent.vue';

export const useLootbox = () => {
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { mutateAsync: openLootboxMutation } = useOpenLootboxMutation();

  const openClaimItemsDialog = (
    lootboxItems: dscvrApi.lootbox.ILootboxItem[],
  ) => {
    return new Promise<void>((resolve) => {
      openConfiguredDialog({
        content: {
          component: ClaimDialogContent,
          props: {
            lootboxItems,
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses:
            'bg-transparent border-none antialiased rounded-none shadow-none max-w-80',
          showCloseButton: false,
          closeOnClickOutside: false,
        },
        drawer: {
          contentClasses: 'relative p-10 !overflow-visible',
          showHeader: false,
          closeOnClickOutside: false,
        },
        emit: resolve,
      });
    });
  };

  const showLootboxDialog = (lootbox: dscvrApi.lootbox.ILootbox) => {
    openConfiguredDialog({
      content: {
        component: DialogContent,
        props: {
          lootboxId: lootbox.id,
          lootboxPages: buildLootboxCampaign(lootbox),
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[438px] pt-6 pb-8 px-8`,
      },
      drawer: {
        customClasses: `${dialogDefaults.drawer.customClasses} px-6 pb-8`,
      },
    });
  };

  const openLootbox = async (lootboxId: string) => {
    const openResult = await openLootboxMutation(lootboxId);

    if (openResult.status === dscvrApi.lootbox.LootboxOpenStatus.OPENED) {
      if (openResult.lootbox && openResult.lootbox.items.length > 0) {
        showLootboxDialog({
          id: openResult.lootbox.id,
          items: openResult.lootbox.items,
        });
      }
    } else if (
      openResult.status === dscvrApi.lootbox.LootboxOpenStatus.NO_WALLET
    ) {
      showToast({
        type: 'error',
        title: t('lootbox.pairError'),
        durationSeconds: 5000,
      });
    }
    return openResult;
  };

  return {
    openLootbox,
    openClaimItemsDialog,
  };
};
