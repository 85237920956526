import { dscvrApi } from '@/shared/api';
import type { PublicKey } from '@solana/web3.js';

const getNetworkName = (network: dscvrApi.wallet.NetworkType) => {
  switch (network) {
    case 'solana':
      return 'Solana';
    case 'ethereum':
      return 'EVM';
    default:
      throw new Error(`Unsupported network: ${network}`);
  }
};

const getMessageToSign = (
  network: dscvrApi.wallet.NetworkType,
  chainId: string,
  accountAddress: string | PublicKey,
  nonce: string,
) => {
  const name = getNetworkName(network);
  return `https://dscvr.one wants you to sign in with your ${name} account: ${
    typeof accountAddress === 'string'
      ? accountAddress
      : accountAddress.toString()
  } \n
  Signing this message proves you own this wallet and nothing else. This is not a transaction, so you will not be charged any fees.

  URI: https://dscvr.one
  Chain ID: ${chainId}
  Nonce: ${nonce}`;
};

export const prepareSignedMessage = (
  network: dscvrApi.wallet.NetworkType,
  chainId: string,
  publicKey: string | PublicKey,
  nonce: string,
) => {
  const rawMessage = getMessageToSign(network, chainId, publicKey, nonce);
  const message = new TextEncoder().encode(rawMessage);
  return { message, rawMessage };
};
