<template>
  <div class="flex justify-center p-2 mb-2 bg-gray-950 w-fill sm:rounded-xl">
    <circular-loader />
  </div>
</template>

<script>
  import CircularLoader from './CircularLoader.vue';

  export default {
    name: 'content-loader',
    components: { CircularLoader },
  };
</script>
