<script setup lang="ts">
  import { ref } from 'vue';

  withDefaults(
    defineProps<{
      dragActiveCssClass?: string;
    }>(),
    {
      dragActiveCssClass: 'p-4',
    },
  );
  const emit = defineEmits<{
    (e: 'drop', event: DragEvent): void;
  }>();

  const dropArea = ref<HTMLElement | null>(null);
  const dragActive = ref(false);

  const isCurrentArea = (elem: HTMLElement) => {
    return dropArea.value === elem || dropArea.value?.contains(elem);
  };

  const isDraggingAFile = (event: DragEvent) => {
    return event.dataTransfer?.types.includes('Files');
  };

  const dragenter = (event: DragEvent) => {
    if (isDraggingAFile(event)) {
      dragActive.value = true;
    }
  };

  const dragleave = (event: DragEvent) => {
    const target = event.relatedTarget as HTMLElement;
    if (!isCurrentArea(target)) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      dragActive.value = false;
    }
  };

  const drop = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    dragActive.value = false;
    if (isDraggingAFile(event)) {
      emit('drop', event);
    }
  };
</script>

<template>
  <div
    ref="dropArea"
    class="relative w-full"
    @dragenter.prevent="dragenter"
    @dragover.prevent
    @dragleave="dragleave"
    @drop="drop"
  >
    <slot :drag-active="dragActive"></slot>
    <div
      v-show="dragActive"
      class="absolute top-0 w-full h-full transition-opacity bg-inherit duration-400 pointer-events-none z-20"
      :class="dragActiveCssClass"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-full text-white border border-white border-dashed rounded-lg"
      >
        <slot name="message">
          <base-icon
            name="plus"
            size="w-3.5 h-3.5"
            class="pointer-events-none"
          />
          <span class="text-xs mt-2 pointer-events-none">
            {{ $t('dropFilesHere') }}
          </span>
        </slot>
      </div>
    </div>
  </div>
</template>
