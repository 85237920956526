import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getUserWalletsQueryKey } from './keys';

export const useGetUserSolanaWalletsQuery = (dscvrPk: string) => {
  return useQuery({
    queryKey: getUserWalletsQueryKey(dscvrPk),
    queryFn: () => dscvrApi.wallet.getUserWallets(dscvrPk),
    refetchOnMount: false,
    initialData: () => [],
  });
};
