<script lang="ts" setup>
  import { ref } from 'vue';
  import InfoSection from './_partials/InfoSection.vue';
  import WalletConnect from './_partials/WalletPair.vue';

  defineEmits<{
    (e: 'added'): void;
    (e: 'close'): void;
  }>();

  const pairWallet = ref(false);
</script>
<template>
  <div class="select-none">
    <info-section
      v-if="!pairWallet"
      @continue="pairWallet = true"
      @close="$emit('close')"
    />
    <wallet-connect
      v-else
      @connected="$emit('added')"
      @close="$emit('close')"
    />
  </div>
</template>
