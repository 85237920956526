import debugUser from './lib/debugUsers.json';

const isDebug = import.meta.env.VITE_ENABLE_DEBUG === 'true';

export const config = {
  DEBUG_USERS: isDebug ? debugUser : [],
  ENABLE_DEBUG: isDebug,
  GATED_API_BASE: import.meta.env.VITE_GATED_API_BASE,
  DSCVR_API_BASE: import.meta.env.VITE_GATED_API_BASE,
  CANISTER_ID: import.meta.env.VITE_CANISTER_ID,
  IC_URL: import.meta.env.VITE_IC_URL,
  EDGE_URL: import.meta.env.VITE_EDGE_URL,
  BETA_FEATURES: import.meta.env.VITE_BETA_FEATURES,
  WALLETCONNECT_APP_ID: import.meta.env.VITE_WALLETCONNECT_APP_ID,
  IDENTITY_URL: import.meta.env.VITE_IDENTITY_URL,
  SOL_SERVICES_URL: import.meta.env.VITE_SOL_SERVICES_URL,
  HELLOMOON_API_KEY: import.meta.env.VITE_HELLOMOON_API_KEY,
  ALCHEMY_API_KEY: import.meta.env.VITE_ALCHEMY_API_KEY,
  ENABLE_MARKETPLACE_TAB:
    import.meta.env.VITE_ENABLE_MARKETPLACE_TAB === 'true',
  SENTRY_URL: import.meta.env.VITE_SENTRY_URL,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
  SENTRY_SAMPLE_RATE: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 1.0,
  SENTRY_SESSION_RATE: import.meta.env.VITE_SENTRY_SESSION_RATE || 0.1,
  SENTRY_ERROR_RATE: import.meta.env.VITE_SENTRY_ERROR_RATE || 1.0,
  JUPITER_RPC_ENDPOINT: import.meta.env.VITE_JUPITER_RPC_ENDPOINT,
  ENABLE_FRAMES: import.meta.env.VITE_ENABLE_FRAMES === 'true',
  MATRICA_CLIENT_ID:
    import.meta.env.VITE_MATRICA_CLIENT_ID || 'a5e8f068f11d0d6',
  MATRICA_AUTHORIZATION_URL:
    import.meta.env.VITE_MATRICA_AUTHORIZATION_URL ||
    'https://matrica.io/oauth2',
  MATRICA_SCOPES: import.meta.env.VITE_MATRICA_SCOPES || 'wallets profile',
  JUPITER_FEE_BPS: Number(import.meta.env.VITE_JUPITER_FEE_BPS) || 1,
  JUPITER_ACCOUNT_PUBLIC_KEY: import.meta.env.VITE_JUPITER_ACCOUNT_PUBLIC_KEY,
  MEDIA_WORKER_URL: import.meta.env.VITE_MEDIA_WORKER_URL,
  TWITTER_CLIENT_ID: import.meta.env.VITE_TWITTER_CLIENT_ID,
  SKIP_ONBOARDING: import.meta.env.VITE_SKIP_ONBOARDING === 'true',
  ENABLE_ONBOARDING_LOOTBOX:
    import.meta.env.VITE_ENABLE_ONBOARDING_LOOTBOX === 'true',
  ONBOARDING_LOOTBOX_TARGET_ID: import.meta.env
    .VITE_ONBOARDING_LOOTBOX_TARGET_ID,
  AUTH_TOKEN_BYPASS: import.meta.env.VITE_AUTH_TOKEN_BYPASS,
  ENABLE_GIMLUCK: import.meta.env.VITE_ENABLE_GIMLUCK === 'true',
};
