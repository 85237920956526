import { computed, type Ref } from 'vue';
import type { SocialNetworkType, SocialNetwork, SocialLink } from '@/utils';
import { GenericSocialNetwork, SocialNetworks } from '@/utils';

export const useSocialPresence = (link?: Ref<string>) => {
  const availableSocialNetworks = computed(() =>
    SocialNetworks.map((network, index) => ({
      ...network,
      sort: index + 2,
    })),
  );

  const getSocialNetwork = (
    type: SocialNetworkType,
  ): SocialNetwork | undefined => {
    const socialNetwork = availableSocialNetworks.value.find(
      (network) => network.type === type,
    );
    if (socialNetwork) {
      return socialNetwork;
    }

    return undefined;
  };

  const doesLinkMatchesSocialNetwork = (
    link: string,
    socialNetwork: SocialNetwork,
  ): boolean => {
    return socialNetwork.matches.some((match) =>
      match instanceof RegExp
        ? match.test(link.trim())
        : link.trim().startsWith(match),
    );
  };

  const findLinkSocialNetwork = (link: string): SocialNetwork => {
    const socialNetwork = availableSocialNetworks.value.find((network) =>
      doesLinkMatchesSocialNetwork(link, network),
    );

    if (socialNetwork) {
      return socialNetwork;
    }

    return GenericSocialNetwork;
  };

  const findLinkUsername = (
    link: string,
    socialNetwork: SocialNetwork,
  ): string | undefined => {
    const domainRegExp = /^(https?:\/\/)?[^\/]+\/?/;
    const match = domainRegExp.exec(link);
    const linkReminder = match ? link.slice(match[0].length) : link;
    if (socialNetwork.matchesUser && socialNetwork.matchesUser.length) {
      const matches = socialNetwork.matchesUser
        .map((regExp) => regExp.exec(linkReminder))
        .filter((result) => result !== null) as RegExpExecArray[];
      if (matches.length) {
        return matches[0].groups?.user;
      }
    }

    return undefined;
  };

  const findLinkGroup = (
    link: string,
    socialNetwork: SocialNetwork,
  ): string | undefined => {
    const domainRegExp = /^(https?:\/\/)?[^\/]+\/?/;
    const match = domainRegExp.exec(link);
    const linkReminder = match ? link.slice(match[0].length) : link;
    if (
      socialNetwork.matchesGroup &&
      socialNetwork.matchesGroup.length &&
      socialNetwork.groupName
    ) {
      const matches = socialNetwork.matchesGroup
        .map((regExp) => regExp.exec(linkReminder))
        .filter((result) => result !== null) as RegExpExecArray[];
      if (matches.length && matches[0].groups) {
        return matches[0].groups[socialNetwork.groupName];
      }
    }

    return undefined;
  };

  const matchLinks = (linkList: string[]): SocialLink[] => {
    return linkList
      .map((link) => ({
        link,
        socialNetwork: findLinkSocialNetwork(link),
      }))
      .map(({ link, socialNetwork }) => ({
        link,
        socialNetwork,
        user: findLinkUsername(link, socialNetwork),
        group: findLinkGroup(link, socialNetwork),
      }))
      .sort((a, b) => {
        const sort = a.socialNetwork.sort - b.socialNetwork.sort;
        if (sort !== 0) {
          return sort;
        }
        return a.link.localeCompare(b.link);
      });
  };

  const matchedSocialNetwork = computed(() => {
    if (!link?.value) {
      return GenericSocialNetwork;
    }
    return findLinkSocialNetwork(link.value);
  });

  return {
    availableSocialNetworks,
    matchedSocialNetwork,
    getSocialNetwork,
    findLinkSocialNetwork,
    doesLinkMatchesSocialNetwork,
    matchLinks,
    findLinkUsername,
    findLinkGroup,
  };
};
