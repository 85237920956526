import { client } from '../utils/dfinity';

export default {
  async createReport(createReport) {
    return await client.actor.create_report(createReport);
  },
  async getReports() {
    return await client.actor.list_reports();
  },
  async readReport(id) {
    return await client.actor.set_read(id);
  },
  async actionReport(action) {
    return await client.actor.set_action(action.id, action.message);
  },
};
