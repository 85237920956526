<script setup lang="ts">
  import { ref, watch, computed, toRef, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';
  import { useBreakpoint } from '@/shared/model';
  import { useMetaPortal, useGetPortalBySlugQuery } from '@/entities/portal';
  import { useNFTGating } from '@/entities/nft-gate';
  import { ListenLiveRoomModal } from '@/widgets/listen-live-room-modal';
  import { PORTAL_ABOUT, PORTAL_MARKETPLACE, PORTAL_CONTENT } from '@/common';
  import { config } from '@/shared/lib';
  import { liveChatModel } from '@/entities/live';
  import { usePostDialog } from '@/features/post';
  import { PortalProfileHeader } from '@/widgets/portal-profile-header';
  import { Empty } from '@/shared/ui/empty';
  import { compact } from 'lodash-es';

  const props = defineProps<{
    slug: string;
  }>();

  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n({ useScope: 'global' });
  const {
    marketplaceNftCollections,
    fetchNftGatedCollections,
    fetchEntrepotCollections,
  } = useNFTGating();

  const {
    data: portalView,
    isLoading,
    isFetching,
  } = useGetPortalBySlugQuery(toRef(props, 'slug'), true);

  useMetaPortal(portalView);
  const { openCreatePostDialog } = usePostDialog();

  const { isSmallerThanLg, isSmallerThanMd } = useBreakpoint();
  const { showLiveModal } = liveChatModel.use();

  const selectedTab = ref('posts');
  const portalTabs = computed(() =>
    compact([
      {
        key: 'posts',
        label: t('posts'),
        route: {
          name: PORTAL_CONTENT,
          params: {
            slug: props.slug,
          },
        },
        icon: 'vuesax-linear-notification-status',
        metaTitle: `Posts in ${portalView.value?.name} - DSCVR Community`,
      },
      config.ENABLE_MARKETPLACE_TAB &&
        marketplaceNftCollections.value.length > 0 && {
          key: 'marketplace',
          label: t('marketplace'),
          route: {
            name: PORTAL_MARKETPLACE,
            params: {
              slug: props.slug,
            },
          },
          icon: 'vuesax-linear-shop',
          metaTitle: `Marketplace in ${portalView.value?.name} - DSCVR Community`,
        },
      isSmallerThanLg.value && {
        key: 'about',
        label: t('about'),
        route: {
          name: PORTAL_ABOUT,
          params: {
            slug: props.slug,
          },
        },
        icon: 'outlined-user-circle',
        metaTitle: `About ${portalView.value?.name} - DSCVR Community`,
      },
    ]),
  );

  useHead({
    title: () =>
      portalView.value
        ? portalTabs.value.find((tab) => tab.key === selectedTab.value)
            ?.metaTitle ?? ''
        : '',
  });

  onMounted(async () => {
    if (config.ENABLE_MARKETPLACE_TAB) {
      await fetchNftGatedCollections(props.slug);
      await fetchEntrepotCollections();
    }
  });

  const loadSelectedTabByRoute = () => {
    if (route.name === PORTAL_CONTENT) {
      selectedTab.value = 'posts';
    } else if (route.name === PORTAL_MARKETPLACE) {
      selectedTab.value = 'marketplace';
    } else if (route.name === PORTAL_ABOUT) {
      selectedTab.value = 'about';
    }
  };

  watch(
    () => isSmallerThanLg.value,
    (value) => {
      if (!value && route.name === PORTAL_ABOUT) {
        router.push({
          name: PORTAL_CONTENT,
          params: {
            slug: props.slug,
          },
        });
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    () => route.name,
    () => loadSelectedTabByRoute(),
    {
      immediate: true,
    },
  );

  watch(
    () => portalView.value?.slug,
    (slug) => {
      if (slug && route.query.post && typeof route.query.post === 'string') {
        openCreatePostDialog(route.query.post);
        router.replace({ query: { ...route.query, post: undefined } });
      }
    },
  );
</script>

<template>
  <section v-if="!isLoading && portalView" class="w-full">
    <portal-profile-header :portal-view="portalView" />
    <div
      class="flex justify-center md:justify-start items-start mb-1 pb-2 px-5 md:px-16 lg:px-0 bg-gray-975 pt-5 sm:pt-2"
    >
      <div
        v-if="portalTabs.length > 1"
        class="flex w-full gap-10 pb-6 text-white justify-start"
      >
        <base-button
          v-for="tab in portalTabs"
          :key="tab.key"
          variant="custom"
          custom-classes="flex gap-2 items-center pb-3 px-4 md:px-0"
          :class="{
            'border-b-2': selectedTab === tab.key,
            'text-gray-400': selectedTab !== tab.key,
          }"
          :to="tab.route"
          @click.stop="selectedTab = tab.key"
        >
          <base-icon :name="tab.icon" />
          <span v-if="!isSmallerThanMd" class="text-base font-medium">
            {{ tab.label }}
          </span>
        </base-button>
      </div>
    </div>
    <router-view />
  </section>
  <empty v-else-if="!isFetching && !portalView">
    <template #title>
      {{ $t('portalDoNotExist') }}
    </template>
  </empty>
  <listen-live-room-modal v-if="showLiveModal" />
</template>
