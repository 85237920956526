export type UserSortField =
  | 'pointCount'
  | 'name'
  | 'followingCount'
  | 'activeStreak';

export const userSortFieldTypes: UserSortField[] = [
  'pointCount',
  'name',
  'followingCount',
  'activeStreak',
];
