import type { Principal } from '@dfinity/principal';
import { actor } from '../base';
import type {
  PhantomClaimRequest,
  PhantomRewardResult,
} from 'dfx/edge/edge.did';

export const getGimluckCards = (userId: Principal, rewardId: bigint) => {
  return actor()
    .get_user_phantom_reward(userId, rewardId)
    .then((res) => res) as Promise<PhantomRewardResult>;
};

export const claimGimluck = (claimRequest: PhantomClaimRequest) => {
  return actor()
    .claim_phantom_reward([claimRequest])
    .then((res) => res) as Promise<PhantomRewardResult>;
};
