<template>
  <div v-if="report" class="grid text-white bg-gray-700 shadow-lg">
    <div class="flex m-2">
      <div class="">
        Reporter:

        <router-link
          :to="{
            name: USER_CONTENT,
            params: { username: report.reporter.username },
          }"
          class="cursor-pointer hover:underline"
        >
          {{ report.reporter.username }}
        </router-link>

        {{ fromNow(report.created_at) }}
      </div>

      <div class="pl-2 ml-2 border-l">
        Responder:

        <span v-if="report.actor.length > 0">
          <router-link
            :to="{
              name: USER_CONTENT,
              params: { username: report.actor[0].username },
            }"
            class="cursor-pointer hover:underline"
          >
            {{ report.actor[0].username }}
          </router-link>

          {{ fromNow(report.actioned_at) }}
        </span>

        <span v-if="report.actor.length == 0"> None </span>
      </div>
    </div>

    <div class="m-2">
      <p class="py-1 leading-relaxed text-white">User message:</p>

      <div class="p-2 bg-gray-950 rounded-md">
        <div style="white-space: pre-wrap">
          {{ report.message }}
        </div>
      </div>
    </div>

    <div class="m-2">
      <post :post="report.content" class="bg-gray-950" />
    </div>

    <form
      v-if="!report.action"
      class="m-2 text-white bg-gray-950 shadow-lg"
      @submit.prevent="onSubmit"
    >
      <p class="p-4 leading-relaxed text-white">
        What is the action you would like to take?
      </p>

      <error-display :local-errors="localErrors" />

      <div class="m-2">
        <textarea
          v-model="action"
          class="block w-full text-white bg-gray-700 border-transparent rounded-md focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
          rows="3"
          placeholder="Please describe the action."
        />
      </div>

      <div class="flex justify-end">
        <button
          class="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase outline-none background-transparent focus:outline-none"
          style="transition: all 0.15s ease"
          type="submit"
        >
          Submit Action
        </button>
      </div>
    </form>

    <div v-if="report.action" class="m-2">
      <p class="py-1 leading-relaxed text-white">Action taken</p>

      <div class="p-2 bg-gray-950 rounded-md">
        <div style="white-space: pre-wrap">
          {{ report.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ActionTypes } from '../store/action-types';
  import ErrorDisplay from '../components/ErrorDisplay.vue';
  import { Post } from '@/features/post';
  import { USER_CONTENT } from '../common';

  export default {
    name: 'report-item',
    components: { ErrorDisplay, Post },
    props: {
      report: {
        type: Object,
        default: () => null,
      },
    },
    data() {
      return {
        USER_CONTENT,
        action: '',
        localErrors: [],
      };
    },
    methods: {
      onSubmit() {
        if (this.isValid()) {
          this.$store.dispatch(`reports/${ActionTypes.ACTION_REPORT}`, {
            id: this.report.id,
            message: this.action,
          });
        }
      },
      isValid() {
        this.localErrors = [];

        if (!this.action) {
          this.localErrors.push({
            field: 'action',
            errors: ['You must say what action was'],
          });
        }

        return this.localErrors.length == 0;
      },
    },
  };
</script>
