import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import * as lib from './lib';

export const useDeviceStore = defineStore('device', () => {
  const userAgent = ref<string>(navigator.userAgent);

  const isMobile = computed(() => lib.isMobile(userAgent.value));
  const isMobileOrTablet = computed(() =>
    lib.isMobileOrTablet(userAgent.value),
  );
  const isTablet = computed(() => !isMobile.value && isMobileOrTablet.value);
  const isDesktop = computed(() => !isMobileOrTablet.value);
  const isIos = computed(() => lib.isIos(userAgent.value));
  const isAndroid = computed(() => lib.isAndroid(userAgent.value));
  const isWindows = computed(() => lib.isWindows(userAgent.value));
  const isMacOS = computed(() => lib.isMacOS(userAgent.value));
  const isApple = computed(() => isIos.value || isMacOS.value);
  const isDesktopOrTablet = computed(() => !isMobile.value);
  const isEmbedded = computed(() => lib.isEmbedded());
  const browserName = computed(() => lib.getBrowserName(userAgent.value));
  const isSafari = computed(() => browserName.value === 'Safari');
  const isFirefox = computed(() => browserName.value === 'Firefox');
  const isEdge = computed(() => browserName.value === 'Edge');
  const isChrome = computed(() => browserName.value === 'Chrome');
  const isSamsung = computed(() => browserName.value === 'Samsung');
  const isCrawler = computed(() => lib.REGEX_CRAWLER.test(userAgent.value));

  const refreshUserAgent = () => (userAgent.value = navigator.userAgent);

  if (lib.REFRESH_ON_RESIZE) {
    window.addEventListener('resize', () => {
      setTimeout(() => (userAgent.value = navigator.userAgent), 50);
    });
  }

  return {
    userAgent,
    isMobile,
    isMobileOrTablet,
    isTablet,
    isDesktop,
    isIos,
    isAndroid,
    isWindows,
    isMacOS,
    isApple,
    isEmbedded,
    isDesktopOrTablet,
    isSafari,
    isFirefox,
    isEdge,
    isChrome,
    isSamsung,
    isCrawler,
    refreshUserAgent,
  };
});
