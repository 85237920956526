<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import type { ComputedMedia } from '@/composables/types';
  import MediaIframe from './MediaIframe.vue';
  import MediaVideo from './MediaVideo.vue';
  import MediaImage from './MediaImage.vue';
  import { isImageUrl, getIframelySrc } from '@/utils';

  const props = withDefaults(
    defineProps<{
      media: ComputedMedia;
      single?: boolean;
    }>(),
    {
      single: false,
    },
  );

  const emit = defineEmits<{
    (e: 'loaded'): void;
  }>();

  const { single, media } = toRefs(props);
  const iframeImageSrc = computed(() => {
    if (media.value.type !== 'iframe') {
      return null;
    }
    const iframelySrc = getIframelySrc(media.value.src);
    const realSrc = iframelySrc || media.value.src;
    if (!isImageUrl(realSrc)) {
      return null;
    }
    return realSrc;
  });

  const isImage = computed(
    () => iframeImageSrc.value || media.value.type === 'image',
  );

  const imageSrc = computed(() => iframeImageSrc.value || media.value.src);

  const containerClass = computed(() => {
    if (!single.value) {
      return 'w-full h-full flex justify-center items-center';
    }
    let widthClass = 'w-full';
    if (isImage.value) {
      widthClass = 'w-auto';
    }
    return `rounded-xl overflow-hidden bg-gray-975 border border-white border-opacity-10 ${widthClass}`;
  });
</script>

<template>
  <div :class="containerClass">
    <media-image
      v-if="isImage"
      :src="imageSrc"
      :fit-contain="single"
      @loaded="$emit('loaded')"
    />
    <media-iframe
      v-else-if="media.type === 'iframe'"
      :src="media.src"
      @click.stop
      @loaded="$emit('loaded')"
    />
    <media-video
      v-else-if="media.type === 'video'"
      :src="media.src"
      controls
      @click.stop
      @loaded="$emit('loaded')"
    />
  </div>
</template>
