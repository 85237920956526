import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { SizeItem } from './types';
import { getSizedItemKey } from '../../../lib/size';

export const useSizeStore = defineStore('size', () => {
  const sizeHash = ref<Map<string, SizeItem>>(new Map());

  const setSizeItem = (item: SizeItem) => {
    const key = getSizedItemKey(item.type, item.id);
    sizeHash.value.set(key, item);
  };

  return {
    sizeHash,
    setSizeItem,
  };
});
