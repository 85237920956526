import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useGetRoleMembersQuery = (roleId: Ref<string>) => {
  return useQuery({
    queryKey: ['role-members', 'by-id', roleId],
    queryFn: () => dscvrIcApi.portal.getRoleMembers(BigInt(roleId.value)),
  });
};
