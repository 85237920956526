<script setup lang="ts">
  import { ref, type Ref, onMounted } from 'vue';
  import { DynamicScrollerItem } from 'vue-virtual-scroller';

  defineProps<{
    id: string;
    item: any;
    active: boolean;
  }>();

  const itemRef: Ref<InstanceType<typeof DynamicScrollerItem> | undefined> =
    ref();

  /* TODO: move this to its own forked version of vue-virtual-scroller */
  const overrideApplySize = () => {
    if (itemRef.value) {
      const defaultApplySizeFn = itemRef.value.applySize;
      itemRef.value.applySize = (size: number) => {
        if (
          itemRef.value.$el &&
          itemRef.value.$el.getAttribute('data-id') !== itemRef.value.id
        ) {
          return;
        }
        defaultApplySizeFn(size);
      };
    }
  };

  onMounted(overrideApplySize);
</script>
<template>
  <dynamic-scroller-item
    ref="itemRef"
    :item="item"
    :active="active"
    :data-id="id"
  >
    <slot />
  </dynamic-scroller-item>
</template>
