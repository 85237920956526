<template>
  <div
    class="bg-gray-950 rounded-b-xl border border-gray-785 border-opacity-50 mb-4 md:rounded-b-none lg:rounded-xl"
  >
    <slot name="cover-image" />
    <div class="flex flex-col items-center gap-6 -mt-8 mb-6 z-1">
      <div
        class="flex flex-col md:flex-row items-center justify-center w-full md:px-6"
      >
        <div class="flex justify-around items-end w-full md:w-[148px]">
          <div v-if="$slots.followers" class="md:hidden pt-12">
            <slot name="followers" />
          </div>
          <div
            v-if="$slots.photo"
            class="self-start w-16 h-16 md:w-[148px] md:h-[148px] relative"
          >
            <slot name="photo" />
          </div>
          <div v-if="$slots.following" class="md:hidden">
            <slot name="following" />
          </div>
        </div>
        <div
          class="flex flex-col items-center gap-2 md:items-start w-full mt-3 md:pl-6 md:pt-10 md:grow"
        >
          <div
            v-if="$slots.name || $slots['user-slug']"
            class="flex flex-col md:flex-row items-center w-full md:items-end md:gap-4"
          >
            <h1 class="text-2xl font-bold">
              <slot name="name" />
            </h1>
            <span v-if="$slots['user-slug']" class="text-gray-400">
              <slot name="user-slug" />
            </span>
          </div>
          <div v-if="$slots.slug" class="text-sm font-medium text-gray-400">
            @<slot name="slug" />
          </div>
          <div v-if="$slots.streak" class="md:hidden">
            <slot name="streak" />
          </div>
          <div
            v-if="$slots.followers || $slots.following || $slots.streak"
            class="flex gap-4"
          >
            <div v-if="$slots.followers" class="hidden md:flex md:gap-1.5">
              <slot name="followers" />
            </div>
            <div v-if="$slots.following" class="hidden md:flex md:gap-1.5">
              <slot name="following" />
            </div>
            <div v-if="$slots.streak" class="hidden md:flex">
              <slot name="streak" />
            </div>
          </div>
          <slot name="stats">
            <div class="flex justify-center items-center gap-4 md:flex-1">
              <slot
                v-if="$slots['stats-tokens'] || $slots['tokens-count']"
                name="stats-tokens"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon name="dscvr" size="w-4 h-4" />
                  <span class="text-gray-200 font-medium">
                    <slot name="tokens-count" />
                  </span>
                </div>
              </slot>
              <slot
                v-if="$slots['stats-portal'] || $slots['portals-count']"
                name="stats-portals"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon
                    name="outline-portal"
                    size="w-4 h-4"
                    class="text-blue-500"
                  />
                  <span class="text-gray-200 font-medium">
                    <slot name="portals-count" />
                  </span>
                </div>
              </slot>
              <slot
                v-if="$slots['stats-members'] || $slots['members-count']"
                name="stats-members"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon
                    name="outlined-users-3"
                    size="w-4 h-4"
                    class="text-blue-500"
                  />
                  <span class="text-gray-200 font-medium">
                    <slot name="members-count" />
                  </span>
                </div>
              </slot>
              <slot
                v-if="$slots['stats-content'] || $slots['content-count']"
                name="stats-content"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon
                    name="notification-status"
                    size="w-4 h-4"
                    class="text-pink-500"
                  />
                  <span class="text-gray-200 font-medium">
                    <slot name="content-count" />
                  </span>
                </div>
              </slot>
              <slot
                v-if="$slots['stats-airdrop'] || $slots['airdrop-count']"
                name="stats-airdrop"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon
                    name="outlined-airdrop2"
                    size="w-4 h-4"
                    class="text-yellow-500"
                  />
                  <span class="text-gray-200 font-medium">
                    <slot name="airdrop-count" />
                  </span>
                </div>
              </slot>
              <slot
                v-if="$slots['stats-creation-date'] || $slots['creation-date']"
                name="stats-creation-date"
              >
                <div class="flex justify-center items-center gap-1.5">
                  <base-icon
                    name="outlined-clock"
                    size="w-4 h-4"
                    class="text-gray-400"
                  />
                  <span
                    class="text-sm text-gray-400 font-medium whitespace-nowrap"
                  >
                    <slot name="creation-date" />
                  </span>
                </div>
              </slot>
            </div>
          </slot>
        </div>

        <slot name="actions">
          <div
            class="flex w-full px-4 mt-4 md:max-w-[300px] md:px-0 md:self-start md:mt-15 gap-2 justify-end"
          >
            <div v-if="$slots['action-button-1']" class="flex-1 md:flex-none">
              <slot name="action-button-1" />
            </div>
            <div v-if="$slots['action-button-2']" class="flex-1 md:flex-none">
              <slot name="action-button-2" />
            </div>
            <div v-if="$slots['action-button-3']" class="flex-1 md:flex-none">
              <slot name="action-button-3" />
            </div>
            <div v-if="$slots['action-button-4']" class="flex-1 md:flex-none">
              <slot name="action-button-4" />
            </div>
            <slot v-if="$slots['action-dropdown']" name="action-dropdown" />
          </div>
        </slot>
      </div>
      <div class="h-px bg-gray-785 bg-opacity-50 w-full" />
      <div
        class="flex flex-col gap-6 w-full place-items-center lg:flex-row lg:place-content-between"
      >
        <slot name="bio" />
        <div v-if="$slots['social-links']" class="md:pr-10">
          <slot name="social-links" />
        </div>
      </div>
    </div>
  </div>
</template>
