<template>
  <div class="w-full">
    <form
      class="flex flex-col lg:flex-row gap-2 w-full"
      @submit.prevent="onSubmit"
    >
      <div v-if="step !== 2" class="w-full">
        <input
          v-model="input"
          type="text"
          class="block w-full text-white bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl"
          :class="isError ? 'border-red-600' : 'border-gray-600'"
          required
          :placeholder="placeholder"
        />
      </div>

      <div :class="{ 'w-60': step === 2 }">
        <base-button
          variant="secondary"
          size="small"
          class="relative w-24 h-full"
          :disabled="isLoading"
        >
          <overlay-loader v-if="isLoading" :rounded="true" />
          {{ buttonText }}
        </base-button>
      </div>
    </form>
    <div v-if="isError" class="font-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '@/store/action-types';
  import gatedService from '@/services/gated.js';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import { trackEvent } from '@/utils/tracker';

  export default {
    name: 'phone-verify-form',
    components: { OverlayLoader },
    emits: ['phone-verified'],
    data() {
      return {
        step: 0,
        input: '',
        buttonText: 'Update',
        placeholder: '+11234567890',
        sid: '',
        isLoading: false,
        isError: false,
        errorMessage: 'this is error message',
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
    },
    watch: {
      step: {
        immediate: true,
        handler() {
          switch (this.step) {
            case 0:
              this.buttonText = 'Update';
              this.placeholder = '+11234567890';
              break;
            case 1:
              this.buttonText = 'confirm';
              this.placeholder = 'Enter code';
              break;
            case 2:
              this.buttonText = 'verified';
              break;
            default:
              break;
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        this.isLoading = true;
        await gatedService.verifyUser();
        if (this.step === 0) {
          const { data } = await gatedService.sendPhoneVerify(this.input);

          this.isError = data.status === 'error';
          this.errorMessage = data.status === 'error' ? data.msg : '';

          if (data.status === 'ok') {
            this.sid = data.sid;
            this.step = 1;
          }
        } else if (this.step === 1) {
          const { data } = await gatedService.confirmPhone(
            this.input,
            this.sid,
          );

          this.isError = data.status === 'error';
          this.errorMessage = data.status === 'error' ? data.msg : '';

          if (data.status === 'ok') {
            trackEvent('user_settings_action', 'pair_phone');
            this.$emit('phone-verified', 'Phone Successfully Verified');
            await this.$store.dispatch(`auth/${ActionTypes.GET_SELF}`);
            this.step = 2;
          }
          if (this.isError && 'code' in data) {
            this.step = 0;
          }
        }

        this.input = '';
        this.isLoading = false;
      },
    },
  };
</script>
