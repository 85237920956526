export const properyToString = (value: unknown): string | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number' || typeof value === 'bigint') {
    return value.toString();
  } else if (
    typeof value === 'object' &&
    'toText' in value &&
    typeof value.toText === 'function'
  ) {
    return value.toText();
  }
  return undefined;
};

export const objectPropertyToString = <T>(
  item: T,
  attr?: keyof T,
  possibleAttrs?: string[],
): string | undefined => {
  if (item === null || item === undefined) {
    return undefined;
  }
  const returnValue = properyToString(item);
  if (returnValue !== undefined) {
    return returnValue;
  }
  if (attr) {
    const value = item[attr];
    const returnValue = properyToString(value);
    if (returnValue !== undefined) {
      return returnValue;
    }
  }
  if (possibleAttrs && typeof item === 'object') {
    for (const attr of possibleAttrs) {
      if (attr && attr in item) {
        const entries = Object.entries(item);
        const value = entries.find(([key]) => key === attr)?.[1];
        const returnValue = properyToString(value);
        if (returnValue !== undefined) {
          return returnValue;
        }
      }
    }
  }
  return item.toString();
};
