<script setup lang="ts">
  import { computed, toRef } from 'vue';
  import type { SocialNetwork } from '@/utils';
  import { SingleLinkFrame } from '.';

  const props = defineProps<{
    link: string;
    matchedSocialNetwork: SocialNetwork;
  }>();

  defineEmits<{
    (e: 'remove'): void;
  }>();

  const link = toRef(props, 'link');
  const icon = computed(() => props.matchedSocialNetwork.iconDesaturated);
  const name = computed(() => props.matchedSocialNetwork.type);
</script>

<template>
  <single-link-frame
    class="animate-fade-left"
    :icon="icon"
    :name="name"
    button-text="Remove"
    @submit="$emit('remove')"
  >
    <input
      class="block w-full bg-transparent rounded-xl p-2 truncate text-right"
      disabled
      :value="link"
      :title="link"
    />
  </single-link-frame>
</template>
