<script setup lang="ts">
  // TODO: Fix FSD
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_SETTINGS_PAGE_OVERVIEW } from '@/common';
  import { useUser } from '@/entities/user';
  import { ElasticLine } from '@/shared/ui/elastic-line';
  import { isPortalOwnerOrManager } from '@/shared/lib/permissions';
  import { usePortalUserStatus } from '../model/composables/use-portal-user-status';

  const props = withDefaults(
    defineProps<{
      portalView: PortalView;
      hideLabel?: boolean;
    }>(),
    {
      hideLabel: false,
    },
  );

  const { isLoggedIn, currentUser } = useUser();
  const { toggleFavorite, isPortalFavorited, isPortalJoined, onJoin } =
    usePortalUserStatus();
</script>

<template>
  <base-button
    v-if="isLoggedIn && isPortalOwnerOrManager(portalView, currentUser)"
    variant="secondary"
    size="x-small"
    additional-classes="drop-shadow-light"
    :to="{
      name: PORTAL_SETTINGS_PAGE_OVERVIEW,
      params: { slug: portalView.slug },
    }"
  >
    <base-icon name="settings" size="size-4" />
  </base-button>
  <base-button
    v-else-if="!isPortalJoined(portalView)"
    variant="primary"
    size="x-small"
    rounded="rounded-xl"
    additional-classes="drop-shadow-light"
    @click.prevent="onJoin(portalView)"
  >
    <base-icon name="plus-circle" size="size-4" class="flex-none" />
    <span>{{ $t('join') }}</span>
  </base-button>
  <base-button
    v-else
    variant="glass"
    size="x-small"
    additional-classes="drop-shadow-light"
    @click.prevent="toggleFavorite(portalView)"
  >
    <base-icon
      :name="isPortalFavorited(portalView) ? 'star' : 'outline-star'"
      size="size-4"
    />
    <elastic-line>
      {{
        hideLabel
          ? ''
          : isPortalFavorited(portalView)
          ? $t('favoritePortals.faved')
          : $t('favoritePortals.fave')
      }}
    </elastic-line>
  </base-button>
</template>
