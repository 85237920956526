<template>
  <div v-if="portalView" class="flex flex-col items-center sm:block">
    <span class="text-lg font-bold text-white">Portal Gates</span>
    <div class="font-caption sub-title">preferences</div>

    <div class="w-full my-4">
      <div class="relative flex justify-between items-center">
        <div class="max-w-lg">
          <div class="font-label-medium font-medium">Phone Verified</div>

          <div class="text-neutral-300 font-paragraph-small">
            Require new members who joined to have a verified phone number.
            Existing users will not be impacted
          </div>
        </div>

        <div>
          <toggle-button :checked="requiresPhone" @changed="phoneVerify" />
        </div>
      </div>
    </div>
    <portal-gates-setup :portal-view="portalView" />
  </div>
</template>
<script>
  import { ActionTypes } from '../store/action-types';
  import ToggleButton from './buttons/ToggleButton.vue';
  import { PortalGatesSetup } from '@/features/gating';

  export default {
    name: 'portal-settings-gates',
    components: {
      ToggleButton,
      PortalGatesSetup,
    },
    props: {
      slug: {
        type: String,
        default: '',
      },
      portalView: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    data() {
      return {
        requiresPhone: false,
      };
    },
    async mounted() {
      this.requiresPhone = this.portalView.requires_phone;
    },

    methods: {
      phoneVerify(val) {
        if (val == this.requiresPhone) return;

        this.requiresPhone = val;

        this.$store.dispatch(`portals/${ActionTypes.SET_PORTAL_PHONE_VERIFY}`, {
          id: this.portalView.id,
          requiresPhone: this.requiresPhone,
        });
      },
    },
  };
</script>
<style scoped>
  .sub-title {
    @apply mt-7_5 mb-4 py-1.5 font-semibold uppercase text-gray-400 border-b border-gray-700;
  }

  .mt-7_5 {
    margin-top: 30px;
  }
</style>
