<script setup lang="ts">
  import { ref } from 'vue';
  import { liveChatModel } from '@/entities/live';
  import LiveChatMessageInput from '@/entities/live/ui/LiveChatMessageInput.vue';

  const { sendGeneralMessage } = liveChatModel.use();
  const messageDraft = ref('');
</script>

<template>
  <LiveChatMessageInput
    v-model="messageDraft"
    @send-message="sendGeneralMessage"
  />
</template>
