export const interestColors = [
  'bg-violet-500 hover:bg-violet-400',
  'bg-green-700 hover:bg-green-600',
  'bg-blue-600 hover:bg-blue-500',
  'bg-yellow-500 hover:bg-yellow-400',
  'bg-red-500 hover:bg-red-400',
  'bg-purple-500 hover:bg-purple-400',
  'bg-pink-500 hover:bg-pink-400',
  'bg-gray-500 hover:bg-gray-400',
  'bg-lime-500 hover:bg-lime-400',
];
