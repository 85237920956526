import { defineStore } from 'pinia';
import { useClipboard } from '@vueuse/core';

export const useClipboardStore = defineStore('clipboard', () => {
  const clipboard = useClipboard();

  return {
    clipboard,
  };
});
