<template>
  <section class="content">
    <div class="w-full text-md pt-2">
      <div class="w-full my-4">
        <div v-for="(report, index) in reports" :key="index" class="my-4">
          <report-item :report="report" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { ActionTypes } from '../store/action-types';
  import { mapGetters } from 'vuex';
  import ReportItem from '../components/ReportItem.vue';

  export default {
    name: 'reports-page',
    components: {
      ReportItem,
    },

    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        reports: `reports/reports`,
        isLoading: `isLoading`,
        currentUser: 'auth/me',
      }),
    },
    mounted() {
      this.loadReports();
    },
    activated() {
      this.loadReports();
    },
    methods: {
      async loadReports() {
        this.$store.dispatch(`reports/${ActionTypes.LIST_REPORTS}`);
      },
    },
  };
</script>
