<script lang="ts" setup>
  import { useUser } from '@/entities/user';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useGlobalStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';

  withDefaults(
    defineProps<{
      showDetails?: boolean;
    }>(),
    {
      showDetails: true,
    },
  );

  const { currentUser, getFormattedTokens } = useUser();
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);
</script>
<template>
  <div v-if="currentUser" class="flex gap-2">
    <user-avatar :item="currentUser" size="size-8 md:size-10" />
    <div v-if="showDetails" class="flex flex-col items-start">
      <div class="font-semibold text-sm">
        {{ currentUser.username }}
      </div>
      <div class="flex items-center gap-1">
        <base-icon name="dscvr" size="size-3" />
        <span class="text-xs text-gray-400">
          {{ getFormattedTokens(globalLiveData?.tokens) }}
        </span>
      </div>
    </div>
  </div>
</template>
