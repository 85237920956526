import type { UserSortField } from '../../model/user';
import type { UserSortBy } from 'dfx/edge/edge.did';

export const getUserSortBy = (field: UserSortField): [] | [UserSortBy] => {
  if (field === 'pointCount') {
    return [{ PointCount: null }];
  }
  if (field === 'followingCount') {
    return [{ FollowingCount: null }];
  }
  if (field === 'name') {
    return [{ Name: null }];
  }
  if (field === 'activeStreak') {
    return [{ ActiveStreak: null }];
  }
  return [];
};
