import { defineAsyncComponent, ref, type Ref } from 'vue';
import { useDialog } from '@/shared/model';
import type { TensorNft } from '@/shared/api/sol-api/tensor/collection';
import { getToken } from '@/shared/api/sol-api/tensor/token';

export const useTensorNft = (nftId?: string) => {
  const { dialogDefaults, openConfiguredDialog } = useDialog();

  const currentSolBalance: Ref<number> = ref(0);

  const fetchNftDetails = async () => {
    if (!nftId) return;
    try {
      const response = await getToken(nftId);
      if (!response || !response.data) {
        return;
      }

      return response.data;
    } catch (error) {
      console.log('error:', error);
    }
  };

  const setSolBalance = async (balance: number) => {
    currentSolBalance.value = balance;
  };

  const openBuyNftDialog = (options: {
    nft: TensorNft;
    purchased?: () => void;
  }) => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import(
              '@/features/marketplace/components/TensorBuyDialogContent.vue'
            ),
        ),
        props: {
          listing: options.nft,
        },
        emits: {
          purchased: () => options.purchased && options.purchased(),
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-screen lg:max-w-[380px] lg:min-h-[380px] ease-transition-all`,
        closeOnClickOutside: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
        closeOnClickOutside: false,
      },
    });
  };

  return {
    fetchNftDetails,
    setSolBalance,
    currentSolBalance,
    openBuyNftDialog,
  };
};
