<script lang="ts" setup>
  import type { RoleView } from 'dfx/edge/edge.did';
  import type { UserTooltipComponentsProps } from '../../types';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useBreakpoint } from '@/shared/model';
  import { Diamond } from '@/shared/ui/diamond';

  withDefaults(
    defineProps<
      Pick<
        UserTooltipComponentsProps,
        'availableAssignableRoles' | 'portalInfoView' | 'userPortalRoles'
      > & {
        isOwner?: boolean;
        isPortalManager?: boolean;
      }
    >(),
    {
      isOwner: false,
      isPortalManager: false,
    },
  );

  const emits = defineEmits<{
    (e: 'onAddNewRoleToUser', role: RoleView): void;
  }>();

  const { isSmallerThanMd } = useBreakpoint();
</script>

<template>
  <div class="w-full h-px bg-gray-600" />
  <div
    :class="{
      'p-4': !isSmallerThanMd,
    }"
    class="flex flex-col gap-2 w-full"
  >
    <div class="font-semibold text-white">{{ portalInfoView.name }} Roles</div>
    <div class="flex flex-wrap items-start gap-2 max-w-96">
      <div
        v-for="(role, index) in userPortalRoles"
        :key="index"
        class="flex items-center gap-2 py-1 px-3 text-sm font-medium bg-gray-600 rounded-lg cursor-default whitespace-nowrap"
      >
        <diamond :color="role.color" size="size-3" />

        <span class="text-white">{{ role.name }}</span>
      </div>

      <base-tooltip
        v-if="isPortalManager || isOwner"
        ref="rolesTooltipRef"
        placement="top-end"
        interactive
        theme="transparent"
        :arrow="false"
        :delay="200"
      >
        <template #default>
          <base-button
            variant="glass"
            size="x-small"
            rounded="rounded-full"
            class="relative"
          >
            <base-icon name="plus" size="size-4" />
          </base-button>
        </template>
        <template #content>
          <div
            class="flex flex-col right-0 z-30 p-5 bg-black rounded-lg bg-opacity-90 min-w-max"
          >
            <template
              v-if="
                availableAssignableRoles && availableAssignableRoles.length > 0
              "
            >
              <base-button
                v-for="role in availableAssignableRoles"
                :key="role.id.toString()"
                variant="link"
                custom-classes="flex items-center text-white cursor-pointer"
                @click="emits('onAddNewRoleToUser', role)"
              >
                <diamond :color="role.color" size="size-2" class="mr-2" />

                <span class="text-sm text-gray-400 hover:text-white">
                  {{ role.name }}
                </span>
              </base-button>
            </template>
            <div
              v-else
              class="text-sm text-gray-400 opacity-50 hover:text-white"
            >
              No Available Roles
            </div>
          </div>
        </template>
      </base-tooltip>
    </div>
  </div>
</template>
