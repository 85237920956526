<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({
    useScope: 'global',
  });
</script>

<template>
  <div class="flex flex-col gap-2 justify-center items-center px-6 py-8 pb-10">
    <div class="text-white font-bold text-2xl">
      {{ t('auth.mobileWalletApp.title') }}
    </div>
    <div class="text-gray-400 text-base text-center">
      {{ t('auth.mobileWalletApp.description') }}
    </div>
  </div>
</template>
