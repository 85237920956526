<script setup lang="ts">
  import { type CardProps } from './types';
  import { computed } from 'vue';

  const props = defineProps<CardProps>();

  const classes = computed(() => {
    const general =
      'group grid relative rounded-xl w-full p-6 border border-gray-800 ease-transition-all overflow-hidden';
    const spacing =
      props.contentSpacing === 'between' ? 'content-between' : 'content-end';
    const shadow = props.innerShadow
      ? 'hover:shadow-[inset_0_10px_40px_10px_rgba(0,0,0,0.3)]'
      : '';
    return [general, spacing, shadow];
  });

  const backgroundClasses = computed(() => {
    const general =
      'bg-center bg-cover absolute w-full h-full -z-1 ease-transition-all';
    const scale = props.backgroundScale ? 'group-hover:scale-105' : '';

    return [general, scale];
  });
</script>

<template>
  <div :class="classes">
    <div
      v-if="props.background"
      :style="`background-image: url('${props.background}') `"
      :class="backgroundClasses"
    />

    <slot />
  </div>
</template>
