<script setup lang="ts">
  import { ref, watch } from 'vue';
  import type { UserView } from 'dfx/edge/edge.did';
  import { USER_CONTENT } from '@/common';
  import { useUser } from '../model/composables/use-user';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { formatToUnits } from '@/shared/lib';

  const props = defineProps<{
    user: UserView;
  }>();

  const { getCreationDate, getFormattedTokens } = useUser();
  const failedToLoadImage = ref(false);

  watch(
    () => props.user.id,
    () => {
      failedToLoadImage.value = false;
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: USER_CONTENT,
      params: { username: user.username },
    }"
    custom-classes="flex justify-center items-center gap-6 h-full rounded-xl shadow-light border border-gray-800 overflow-hidden group flex-col p-8"
  >
    <div
      class="flex flex-col justify-center items-center w-full gap-4 rounded-xl relative overflow-hidden"
    >
      <follow-user-button
        size="x-small"
        :user="user"
        fully-elastic
        class="absolute top-4 right-4 z-1"
        follow-class="drop-shadow-light"
      />
      <user-avatar
        :item="user"
        :avatar-width="400"
        class="[&>img]:object-cover group-hover:scale-110 transition-all duration-500"
        size="w-full h-auto"
        rounded="rounded-none"
      />
    </div>
    <span
      class="text-lg font-semibold leading-5 truncate text-white w-full"
      :title="user.username"
    >
      {{ user.username }}
    </span>
    <div class="flex-1 flex flex-col justify-between gap-6 w-full min-w-0">
      <div class="flex gap-4 text-sm font-medium">
        <div class="flex gap-1 items-center">
          <base-icon name="dscvr" size="w-4 h-4" />
          <span>{{ getFormattedTokens(user.rights, true) }}</span>
        </div>

        <div class="flex gap-1 items-center">
          <base-icon name="vuesax-linear-notification-status" size="w-4 h-4" />
          <span>{{ formatToUnits(user.num_posts) }}</span>
          <span class="block">{{ $t('posts') }}</span>
        </div>
      </div>

      <p
        v-if="user.bio"
        :title="user.bio"
        class="clamp-bio text-sm font-normal"
      >
        {{ user.bio }}
      </p>
      <p v-else class="clamp-bio text-sm font-normal">
        {{ $t('memberSince') }}
        {{ getCreationDate(user.created_at) }}
      </p>
    </div>
  </base-button>
</template>

<style scoped>
  .clamp-bio {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 3.8rem;
  }
</style>
