import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { UpdatePortalRole } from 'dfx/edge/edge.did';

export const useUpdatePortalRoleMutation = () => {
  return useMutation({
    mutationKey: ['update-portal-role-mutation'],
    mutationFn: (params: UpdatePortalRole) =>
      dscvrIcApi.portal.updatePortalRole(params),
  });
};
