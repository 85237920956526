<script setup lang="ts">
  import { useCreateRoomDialog } from '../composables/use-create-room-dialog';
  import type { PortalView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const { openCreateRoomDialog } = useCreateRoomDialog(props.portalView);
</script>

<template>
  <div class="w-full">
    <base-button
      size="small"
      variant="secondary"
      additional-classes="w-full"
      @click="openCreateRoomDialog"
    >
      <base-icon name="sound" size="size-5" class="mr-1" />
      <span>Live</span>
    </base-button>
  </div>
</template>
