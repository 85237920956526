<script setup lang="ts">
  import MagicEdenLogo from '@/features/marketplace/ui/MagicEdenLogo.vue';
  import MagicEdenBuyButton from '@/features/marketplace/components/MagicEdenBuyButton.vue';
  import OfferButton from '@/features/marketplace/ui/OfferButton.vue';
  import SolanaNftInfo from '@/features/marketplace/ui/SolanaNftInfo.vue';
  import { solApi } from '@/shared/api';
  import { SizeCapture } from '@/entities/content';
  import { fetchMedia } from '@/shared/lib';
  import { createMagicEdenNftUrl } from '@/entities/token';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { computed } from 'vue';

  const BUY_NOW = 'buyNow';
  const BID = 'bid';

  const props = defineProps<{
    nft: solApi.collection.MagicEdenTokenMetadata;
    listing?: solApi.collection.MagicEdenAuctionData;
    activity?: solApi.collection.MagicEdenActivityData[];
  }>();

  const magicEdenShareUrl = createMagicEdenNftUrl(props.nft.mintAddress);

  const lastSalePrice = computed(() => {
    if (!props.activity) return null;
    const lastSale: solApi.collection.MagicEdenActivityData | undefined =
      props.activity.find(
        (v: solApi.collection.MagicEdenActivityData) =>
          v.type === BUY_NOW || (v.type === BID && v.buyer && v.seller),
      );
    return lastSale?.price;
  });
</script>

<template>
  <div
    v-if="nft"
    class="flex flex-col bg-[#10141c] border border-gray-820 rounded-xl px-8 md:px-10 lg:px-20 py-8 nft-embed"
  >
    <div class="flex justify-between items-center">
      <magic-eden-logo />
      <div class="flex items-center gap-x-4">
        <offer-button :share-url="magicEdenShareUrl" :is-disabled="!nft" />
        <magic-eden-buy-button :nft="nft" :listing="listing" />
      </div>
    </div>
    <a
      :href="createMagicEdenNftUrl(nft.mintAddress)"
      target="_blank"
      class="py-2"
    >
      <size-capture :id="nft.image" type="nft" v-slot="{ contentReady }">
        <img
          v-lazy="{
            src: nft.image,
            error: fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
            ),
          }"
          class="w-full object-cover rounded-2xl aspect-square"
          :alt="`${nft.name} - ${nft.collectionName}`"
          @load="contentReady"
          @error="contentReady"
        />
      </size-capture>
    </a>
    <solana-nft-info
      :nft-collection-name="nft.collectionName"
      :nft-name="nft.name"
      :nft-rarity="listing?.rarity?.moonrank?.absolute_rarity"
      :last-sale-price="lastSalePrice"
      :nft-price="listing?.price"
      :nft-share-url="magicEdenShareUrl"
    />
  </div>
</template>

<style scoped lang="scss">
  .dynamic-content .nft-embed img {
    margin: 1.5rem 0 !important;
    max-height: 60rem !important;
  }

  .slide-item .nft-embed img {
    max-height: 21rem !important;

    @media (max-width: 1023px) {
      max-height: 10rem !important;
    }

    @media (max-width: 642px) {
      max-height: 7.5rem !important;
    }
  }
</style>
