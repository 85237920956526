<script lang="ts" setup>
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
  import { computed } from 'vue';
  import { EmbeddedStreak } from '@/components/tiptap';

  const props = defineProps(nodeViewProps);

  const username = computed(() => props.node.attrs.username);
  const points = computed(() => props.node.attrs.points.toString());
  const multiplier = computed(() => props.node.attrs.multiplier.toString());
  const days = computed(() => props.node.attrs.days.toString());
</script>

<template>
  <node-view-wrapper>
    <embedded-streak
      :days="days"
      :multiplier="multiplier"
      :points="points"
      :username="username"
    />
  </node-view-wrapper>
</template>
