<template>
  <div class="p-4 mb-1 bg-gray-700 rounded-lg">
    <div class="flex justify-between">
      <div>
        <img
          class="inline w-5 h-5 mb-1 mr-1"
          :src="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/sns.png`)"
        />
        <span class="text-sm font-bold md:text-lg">SNS-1</span>
      </div>
      <div class="flex">
        <div
          class="flex items-center px-3 py-1 text-sm transition-all duration-300 rounded-full cursor-pointer bg-gray-785 hover:bg-gray-900 tooltip-parent whitespace-nowrap"
          @click="copyNeuronId"
        >
          <div class="inline">
            {{ toNeuronIdDisplay() }}
          </div>
          <base-icon name="copy-documents" class="ml-2" size="w-4 h-4" />
        </div>
        <div
          v-if="isHotKey"
          class="flex items-center px-1 py-1 ml-1 rounded-full bg-gray-785 hover:bg-gray-900"
        >
          <base-icon name="key" size="w-4 h-4" class="inline text-yellow-500" />
        </div>
      </div>
    </div>
    <div class="flex justify-between my-4">
      <div>
        <span class="flex items-center ml-1 text-lg"
          >{{ toDisplay(neuron.cached_neuron_stake_e8s) }}
          <span class="ml-1 text-sm text-gray-300">SNS-1</span></span
        >
      </div>

      <div>
        <span class="px-3 py-1 ml-1 text-sm text-gray-300">
          <span v-if="isDisolving(neuron.dissolve_state)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline w-4 h-4 mb-0.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{
              isDisovled(neuron.dissolve_state) ? 'Dissolved' : 'Dissolving'
            }}</span
          >
          <span v-if="!isDisolving(neuron.dissolve_state)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline w-4 h-4 mb-0.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </svg>

            Locked</span
          >
        </span>
      </div>
    </div>
    <div class="mb-2">
      <div class="inline mr-2 text-sm md:text-base whitespace-nowrap">
        <span v-if="!isDisovled(neuron.dissolve_state)" class="text-gray-300"
          >Dissolve Delay:</span
        >
        {{ fromNow(getDisolveDelay(neuron.dissolve_state)) }}
      </div>
    </div>
    <div v-if="getPermissions(neuron.permissions).length > 0">
      <div
        class="inline mr-2 text-sm text-gray-300 whitespace-nowrap md:text-base"
      >
        User Permissions:
      </div>
      <span
        v-for="(perm, index) in getPermissions(neuron.permissions)"
        :key="index"
      >
        <span
          class="px-2 mr-1 text-sm bg-gray-600 rounded-xl whitespace-nowrap md:text-base"
        >
          {{ perm }}</span
        >
      </span>
    </div>
    <div
      v-if="canCreateProposal(getDisolveDelay(neuron.dissolve_state))"
      class="mt-4 mr-2 inline"
    >
      <router-link
        class="px-2 rounded-full btn-primary"
        :to="{
          name: PROPOSAL_CREATE_PAGE,
          params: { neuronId: getNeuronId() },
        }"
      >
        Create Proposal
      </router-link>
    </div>
    <div
      v-if="canConfigureDissolveState(neuron.permissions)"
      class="mt-4 inline"
    >
      <span
        v-if="getDisolveDelay(neuron.dissolve_state) > 0"
        class="px-2 rounded-full btn-primary"
        @click="startDissolveNeuron(neuron.id[0].id)"
      >
        Dissolve
      </span>
      <span
        v-if="isDisovled(neuron.dissolve_state)"
        class="px-2 rounded-full btn-primary"
        @click="startDisburseNeuron(neuron.id[0].id)"
      >
        Disburse
      </span>
    </div>
  </div>
</template>

<script>
  import { useClipboard } from '@/composables';
  import { fetchMedia } from '@/shared/lib';
  import {
    toDisplayPermission,
    convertToNeuronPermissionType,
    SNSGovernance,
  } from '@/utils/sns.governance';
  import { PROPOSAL_CREATE_PAGE, DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  export default {
    name: 'neuron-item',
    props: {
      neuron: {
        type: Object,
        default() {
          return null;
        },
      },
      userPrincipal: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    emits: ['OpenModal'],
    data() {
      return {
        PROPOSAL_CREATE_PAGE,
        DSCVR_STATIC_ASSETS_CDN_URL,
        isHotKey: false,
        fetchMedia,
      };
    },
    mounted() {
      this.isHotKey = false;
    },
    methods: {
      toDisplay(balance) {
        return Number(Number(Number(balance) / 10 ** Number(8))).toFixed(2);
      },
      toNeuronIdDisplay() {
        const neuronIdHex = this.getNeuronId();
        if (neuronIdHex.length < 32) {
          return neuronIdHex;
        }
        const p1 = neuronIdHex.slice(0, 8);
        const p2 = neuronIdHex.slice(neuronIdHex.length - 6);
        return p1 + '...' + p2;
      },
      copyNeuronId() {
        const neuronIdHex = this.getNeuronId();
        const { toClipboard } = useClipboard();
        toClipboard(neuronIdHex);
      },
      getNeuronId() {
        return Buffer.from(this.neuron.id[0].id).toString('hex');
      },
      isDisolving(disolveState) {
        if (disolveState.length > 0) {
          return 'WhenDissolvedTimestampSeconds' in disolveState[0];
        }
        return false;
      },
      isDisovled(disolveState) {
        if (disolveState.length > 0) {
          if ('WhenDissolvedTimestampSeconds' in disolveState[0]) {
            return (
              Number(disolveState[0].WhenDissolvedTimestampSeconds) <
              Math.round(Date.now() / 1000)
            );
          }
        }
        return false;
      },
      getDisolveDelay(disolveState) {
        if (disolveState.length > 0) {
          if ('DissolveDelaySeconds:' in disolveState[0]) {
            return Number(disolveState[0].DissolveDelaySeconds);
          }
          if ('WhenDissolvedTimestampSeconds' in disolveState[0]) {
            return (
              Number(disolveState[0].WhenDissolvedTimestampSeconds) -
              Math.round(Date.now() / 1000)
            );
          }
        }
        return 0;
      },
      fromNow(val) {
        return printTime(val);
      },
      async startDissolveNeuron(neuronId) {
        const snsGovernance = new SNSGovernance();
        await snsGovernance.create('SNS-1');
        await snsGovernance.dissolveNeuron(neuronId);
      },
      async startDisburseNeuron(neuronId) {
        const snsGovernance = new SNSGovernance();
        await snsGovernance.create('SNS-1');
        await snsGovernance.disburseNeuron(neuronId, 100000000);
      },
      canCreateProposal(neuronDissolveDelay) {
        const minDissolveDelay = 2629800; // 1 month
        return neuronDissolveDelay >= minDissolveDelay;
      },
      canConfigureDissolveState(permissions) {
        let currentPermissions = this.getPermissions(permissions);
        return currentPermissions.includes('Configure Dissolve State');
      },
      getPermissions(permissions) {
        const currentPermissions = permissions.find(
          (x) => x.principal[0].toText() == this.userPrincipal.toText(),
        );

        if (currentPermissions) {
          const permToArray = Array.from(currentPermissions.permission_type);
          const displayPermissions = permToArray.map((x) =>
            this.toDisplayPermission(x),
          );
          if (areArraysEqual(permToArray.sort(), [3, 4].sort())) {
            this.isHotKey = true;
          }
          return displayPermissions;
        }
        return [];
      },
      toDisplayPermission(permission) {
        return toDisplayPermission(convertToNeuronPermissionType(permission));
      },
    },
  };

  /**
   *
   * @param arr1
   * @param arr2
   */
  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((element, index) => element === arr2[index]);
  }

  export const SECONDS_IN_MINUTE = 60;
  export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
  export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
  export const SECONDS_IN_YEAR = ((4 * 365 + 1) * SECONDS_IN_DAY) / 4;

  /**
   *
   * @param seconds
   */
  function printTime(seconds) {
    // Calculate the time difference in milliseconds
    const years = seconds / SECONDS_IN_YEAR;

    const days = (seconds % SECONDS_IN_YEAR) / SECONDS_IN_DAY;
    const hours = (seconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR;

    const yearUnit = years == 1 ? 'year' : 'years';
    const dayUnit = days == 1 ? 'day' : 'days';
    const hourUnit = years == 1 ? 'hour' : 'hours';

    // Initialize an empty time string
    let timeString = '';

    // Add the remaining years, days, minutes, and seconds to the time string if they are greater than 0
    if (years >= 1) timeString += Math.floor(years) + ' ' + yearUnit + ' ';
    if (days >= 1) timeString += Math.floor(days) + ' ' + dayUnit + ' ';
    if (hours >= 1) timeString += Math.floor(hours) + ' ' + hourUnit + ' ';

    // Print the time string
    return timeString;
  }
</script>
