import { computed } from 'vue';
import { useStore } from 'vuex';

function isFeatureEnabled(
  features: string | string[] | null,
  feature: string,
): boolean {
  if (features === 'all') {
    return true;
  } else if (Array.isArray(features)) {
    return features.includes(feature);
  } else {
    return false;
  }
}

export const useBetaFeatures = () => {
  const store = useStore();
  const features = computed<string | string[] | null>(
    () => store?.getters['auth/betaFeatures'],
  );
  const isBetaEnabled = computed<boolean>(() => {
    return isFeatureEnabled(features.value, 'Pioneer');
  });

  return {
    isBetaEnabled,
  };
};
