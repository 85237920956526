<template>
  <div
    class="relative flex flex-col items-center w-full bg-gray-700 rounded-lg"
  >
    <router-link
      :to="{
        name: PORTAL_CONTENT,
        params: { slug: portalView.slug },
      }"
      class="size-full"
    >
      <div
        class="w-full overflow-hidden rounded-t-lg h-22 sm:h-28 lg:h-22 xl:h-28"
      >
        <cover-photo
          :src="portalView.info[0]?.cover_photo[0]"
          :gradients="coverGradients"
        />
      </div>
    </router-link>
    <div class="relative">
      <router-link
        class="absolute transform -translate-x-1/2 -translate-y-1/2 rounded-lg size-12"
        :to="{
          name: PORTAL_CONTENT,
          params: { slug: portalView.slug },
        }"
      >
        <img
          class="w-full h-full rounded-lg"
          alt="Portal cover image"
          :src="getPortalIcon(portalView)"
        />
      </router-link>
    </div>
    <div class="h-6"></div>
    <div class="w-full p-3 rounded-b-xl">
      <div class="flex flex-col items-center justify-between w-full h-full">
        <router-link
          :to="{
            name: PORTAL_CONTENT,
            params: { slug: portalView.slug },
          }"
          class="w-full overflow-hidden text-lg text-center text-ellipsis whitespace-nowrap"
        >
          {{ portalView.name }}
        </router-link>
        <router-link
          :to="{
            name: PORTAL_CONTENT,
            params: { slug: portalView.slug },
          }"
          class="ml-3 mr-3 text-xs text-gray-300 min-w-max"
        >
          {{ portalView.member_count }} Members
        </router-link>
        <div class="mt-3 w-full [&>button]:w-full">
          <join-portal-button :portal-view="portalView" is-portal-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { PORTAL_CONTENT } from '@/common';
  import {
    JoinPortalButton,
    getPortalIcon,
    coverGradients,
  } from '@/entities/portal';
  import { CoverPhoto } from '@/shared/ui/cover-photo';

  export default {
    name: 'featured-portal',
    components: {
      JoinPortalButton,
      CoverPhoto,
    },
    props: {
      portalView: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        PORTAL_CONTENT,
        coverGradients,
        getPortalIcon,
      };
    },
  };
</script>
