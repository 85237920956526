import { unref, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { getTransactionScanQueryKey } from './keys';
import { parseTransactionScan } from '../lib/parse-transaction-scan';

export const useGetTransactionScanQuery = (
  appUrl: Ref<string>,
  chainId: Ref<string | undefined>,
  tx: Ref<string | undefined>,
) => {
  return useQuery({
    queryKey: getTransactionScanQueryKey(appUrl, chainId, tx),
    queryFn: async () => {
      const appUrlValue = unref(appUrl);
      const chainIdValue = unref(chainId);
      const txValue = unref(tx);
      if (!appUrlValue || !chainIdValue || !txValue) return;

      return await dscvrIcApi.canvas.verifyAppTransaction(
        appUrlValue,
        chainIdValue,
        txValue,
      );
    },
    select: parseTransactionScan,
    enabled: false,
  });
};
