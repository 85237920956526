import { computed, toRef } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ActionTypes } from '@/store';
import {
  NFT_SAURON_ROLE,
  ROLE_KIND_FIELD_CUSTOM,
  ROLE_KIND_FIELD_DEFAULT,
} from '@/common';
import { useDialog, useToast } from '@/shared/model';
import { useNFTGating } from '@/entities/nft-gate';
import type { CreatePortalRole, PortalView, RoleView } from 'dfx/edge/edge.did';
import type { CreateICPGateDto, UpdateICPGateDto } from '../../types';
import { dscvrApi } from '@/shared/api';
import { Principal } from '@dfinity/principal';
import type { SelectFieldOptionType } from '@/shared/ui/fields';
import CreateEditDialogContent from '../../components/portal-gates-setup/CreateEditDialogContent.vue';
import DeleteDialogContent from '../../components/portal-gates-setup/DeleteDialogContent.vue';
import { TitleHeader } from '@/shared/ui/base-dialog';
import {
  useGetPortalRolesQuery,
  useAddPortalRoleMutation,
} from '@/entities/portal';

export function useNFTGatingSetup(portalView: PortalView) {
  const store = useStore();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });
  const {
    isNftSauronSetup,
    entrepotCollections,
    allNftCollections,
    NFT_TYPE_EXT,
    loadSauronPortalRoles,
    setupNftWatcher,
    fetchEntrepotCollections,
    fetchNftGatedCollections,
    validateMintAddress,
    getNftCollectionDetailsByAddress,
  } = useNFTGating();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { data: portalRoles, refetch } = useGetPortalRolesQuery(
    toRef(() => portalView),
  );
  const { mutateAsync: addPortalRoleAsyncMutation } =
    useAddPortalRoleMutation();

  const roleOptions = computed(() => {
    const options: (RoleView | string)[] = portalRoles.value
      ? portalRoles.value.filter(
          (role) =>
            ROLE_KIND_FIELD_CUSTOM in role.kind &&
            role.name !== 'Admin' &&
            role.name !== NFT_SAURON_ROLE,
        )
      : [];
    options.push(t('createNewRole'));
    return options;
  });

  const defaultRole = computed(() => {
    return portalRoles.value?.find(
      (role) => ROLE_KIND_FIELD_DEFAULT in role.kind,
    );
  });

  const nftTypeOptions: SelectFieldOptionType[] = [
    { label: 'EXT', value: NFT_TYPE_EXT },
    { label: 'CCC', value: 'ccc' },
    { label: 'CCC DG', value: 'cccdg' },
    { label: 'Bootcamp DIP721', value: 'bootcamp_dip_721' },
  ];

  const validatePrincipalId = (principalId: string): boolean => {
    try {
      Principal.fromText(principalId);
      return true;
    } catch (_e) {
      return false;
    }
  };

  const validateRoleName = (roleName: string): boolean => {
    return !portalRoles.value?.some(
      (role) => role.name.toLowerCase() === roleName.toLowerCase(),
    );
  };

  const createNftCollection = async (
    payload:
      | dscvrApi.multichainGating.CreateMultichainGateDto
      | CreateICPGateDto,
  ) => {
    const response = await store.dispatch(
      `gating/${ActionTypes.GATING_CREATE_NFT_COLLECTION}`,
      payload,
    );
    if (!response) {
      showToast({
        title: t('createNftCollectionError'),
        type: 'error',
        durationSeconds: 5,
      });
    } else {
      showToast({
        title: t('createNftCollectionSuccess'),
        type: 'success',
        durationSeconds: 5,
      });
    }
    return response;
  };

  const updateNftCollection = async (
    id: string,
    payload:
      | dscvrApi.multichainGating.UpdateMultichainGateDto
      | UpdateICPGateDto,
  ) => {
    const response = await store.dispatch(
      `gating/${ActionTypes.GATING_UPDATE_NFT_COLLECTION}`,
      {
        id,
        payload,
      },
    );
    if (!response) {
      showToast({
        title: t('updateNftCollectionError'),
        type: 'error',
        durationSeconds: 5,
      });
    } else {
      showToast({
        title: t('updateNftCollectionSuccess'),
        type: 'success',
        durationSeconds: 5,
      });
    }
    return response;
  };

  const deleteNftCollection = async (
    item: dscvrApi.multichainGating.NftGateDto,
  ) => {
    const payload =
      'network' in item
        ? { network: item.network, id: item.id }
        : { id: item.id };
    const response = await store.dispatch(
      `gating/${ActionTypes.GATING_DELETE_NFT_COLLECTION}`,
      payload,
    );
    if (!response) {
      showToast({
        title: t('deleteNftCollectionError'),
        type: 'error',
        durationSeconds: 5,
      });
    } else {
      showToast({
        title: t('deleteNftCollectionSuccess'),
        type: 'success',
        durationSeconds: 5,
      });
    }
    return response;
  };

  const createNewRole = async (name: string): Promise<RoleView | undefined> => {
    const role: CreatePortalRole = {
      color: defaultRole.value?.color ?? 0,
      icon_url: '',
      name,
      ordinal: BigInt(portalRoles.value!.length),
      permissions: defaultRole.value?.permissions ?? 0n,
    };
    const response = await addPortalRoleAsyncMutation({
      portalId: portalView.id,
      role,
    });
    if (!response || response.status !== 'happy') {
      showToast({
        title: t('createRoleError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }
    showToast({
      title: t('createRoleSuccess'),
      type: 'success',
      durationSeconds: 5,
    });

    return response.result[0];
  };

  const openCreateEditDialog = (
    portalView: PortalView,
    item?: dscvrApi.multichainGating.NftGateDto,
  ) => {
    openConfiguredDialog({
      content: {
        component: CreateEditDialogContent,
        props: {
          item,
          portalView,
        },
        emits: {
          saved: closeDialog,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: `${item ? t('edit') : t('create')} ${t('nftGate')}`,
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg`,
        closeOnClickOutside: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openDeleteDialog = (
    portalView: PortalView,
    item: dscvrApi.multichainGating.NftGateDto,
  ) => {
    openConfiguredDialog({
      content: {
        component: DeleteDialogContent,
        props: {
          item,
          portalView,
        },
        emits: {
          deleted: closeDialog,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: t('deleteNftGate'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg`,
        closeOnClickOutside: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  return {
    isNftSauronSetup,
    portalRoles,
    entrepotCollections,
    roleOptions,
    allNftCollections,
    NFT_TYPE_EXT,
    nftTypeOptions,
    loadSauronPortalRoles,
    setupNftWatcher,
    fetchEntrepotCollections,
    fetchNftGatedCollections,
    fetchPortalRoles: refetch,
    getNftCollectionDetailsByAddress,
    validateMintAddress,
    validatePrincipalId,
    validateRoleName,
    createNftCollection,
    updateNftCollection,
    deleteNftCollection,
    createNewRole,
    openCreateEditDialog,
    openDeleteDialog,
    closeDialog,
  };
}
