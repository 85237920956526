import { unref, type MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { isValidHttpUrl } from '@/shared/lib';
import { dscvrApi } from '@/shared/api';
import { getFrameQueryKey } from './keys';
import { validateAndParse } from '../lib/validate-and-parse';

export const useGetFrameQuery = (url: MaybeRef<string | undefined>) => {
  return useQuery({
    queryKey: getFrameQueryKey(url),
    queryFn: async () => {
      const urlValue = unref(url);
      if (!urlValue || !isValidHttpUrl(urlValue)) return;

      const appInfo = await dscvrApi.proxy.getEmbeddedApp(urlValue);

      if (!appInfo || appInfo.type !== 'frame') {
        return;
      }

      return validateAndParse(urlValue, appInfo.data);
    },
    enabled: false,
  });
};
