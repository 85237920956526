<script lang="ts" setup>
  import { useField } from 'vee-validate';
  import { toRef } from 'vue';
  import type { CheckboxFormFieldProps } from './types';
  import { CheckboxField } from '@/shared/ui/fields';

  const props = defineProps<CheckboxFormFieldProps>();
  const { value, handleBlur } = useField(
    toRef(props, 'name'),
    undefined,
    props.options,
  );
</script>

<template>
  <div class="flex flex-col relative w-full bg-transparent border-none p-0">
    <div class="py-3 w-full" :class="innerClasses">
      <checkbox-field
        v-bind="{ ...$attrs, ...props }"
        v-model="value"
        @blur="handleBlur"
      >
        <template v-for="(_, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </checkbox-field>
    </div>
    <div v-if="error" class="text-sm text-red-500">
      {{ error }}
    </div>
  </div>
</template>
