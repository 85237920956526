<script setup lang="ts">
  import { toRefs, computed } from 'vue';
  import { PORTAL_CONTENT } from '@/common';
  import { type ArcadeCardProps, type ArcadeProps } from '../types';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { BaseCard } from '@/components/shared/base-card';
  import { fetchMedia, pluralize, getDisplayAmount } from '@/shared/lib';
  import { useRouter } from 'vue-router';

  const router = useRouter();
  const props = defineProps<ArcadeCardProps>();
  const { isGame } = toRefs(props);
  const gameItem = computed(() =>
    isGame.value ? (props.item as ArcadeProps) : null,
  );
  const portalItem = computed(() =>
    !isGame.value ? (props.item as PortalView) : null,
  );

  const emit = defineEmits<{
    (e: 'playGame', link: string, title: string): void;
  }>();

  const goToPortal = (slug: string) => {
    router.push({
      name: PORTAL_CONTENT,
      params: { slug },
    });
  };

  const playGame = () => {
    if (!gameItem.value) return;
    emit('playGame', gameItem.value.link, gameItem.value.title);
  };
</script>

<template>
  <div>
    <base-card
      :content-spacing="props.spacing"
      :background="
        isGame
          ? fetchMedia(gameItem?.background!)
          : fetchMedia(portalItem?.info[0]?.cover_photo[0]!)
      "
      :inner-shadow="true"
      :background-scale="true"
      :class="[isGame ? 'h-46 md:h-120' : 'h-84']"
    >
      <div v-if="isGame" class="text-[18px] font-bold z-1">
        {{ gameItem?.title }}
      </div>
      <div
        v-if="isGame"
        class="absolute inset-0 w-full h-full flex md:hidden items-center justify-center cursor-pointer"
      >
        <base-button
          variant="custom"
          custom-classes="ring ring-inset ring-white ring-opacity-10 bg-white bg-opacity-20 bg-blur-md rounded-full flex items-center justify-center"
          @click="playGame"
        >
          <base-icon class="text-white p-1" name="play-game" size="w-12 h-12" />
        </base-button>
      </div>
      <div class="font-semibold" :class="[isGame ? 'hidden md:flex' : 'flex']">
        <img
          class="w-12 h-12 rounded-xl mr-4 cursor-pointer"
          :src="isGame ? fetchMedia(gameItem?.icon!) : fetchMedia(portalItem?.icon_url!)"
          @click="isGame ? null : goToPortal(portalItem!.slug)"
        />
        <div class="flex justify-between items-center w-full z-1">
          <component
            :is="isGame ? 'div' : 'router-link'"
            :to="{
              name: PORTAL_CONTENT,
              params: { slug: portalItem?.slug },
            }"
          >
            <div class="text-lg text-left">{{ props.item.name }}</div>
            <div class="text-gray-400 text-sm text-left">
              {{
                isGame
                  ? gameItem?.genre
                  : getDisplayAmount(portalItem!.member_count, true)
              }}
              <span v-if="!isGame">
                {{ pluralize(portalItem!.member_count, 'Member') }}
              </span>
            </div>
          </component>
          <slot name="button" />
        </div>
      </div>
    </base-card>
    <div :class="[isGame ? '' : 'hidden']">
      <div class="flex md:hidden my-2 font-semibold">
        <img
          class="w-12 h-12 rounded-xl mr-4 cursor-pointer"
          :src="
            isGame
              ? fetchMedia(gameItem?.icon!)
              : fetchMedia(portalItem?.icon_url!)
          "
          @click="isGame ? null : goToPortal(portalItem!.slug)"
        />
        <div class="flex justify-between items-center w-full z-1">
          <component
            :is="isGame ? 'div' : 'router-link'"
            :to="{
              name: PORTAL_CONTENT,
              params: { slug: portalItem?.slug },
            }"
          >
            <div class="text-lg break-all">{{ item.name }}</div>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>
