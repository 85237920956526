import { computed, unref, type MaybeRef } from 'vue';
import { ICPSwap } from '@/utils/icpSwap';
import { useQuery } from '@tanstack/vue-query';
import type { IcpFungibleToken } from '../types';
import { getIcpBalanceQueryKey } from './keys';

export const useGetIcpBalanceQuery = (token: MaybeRef<IcpFungibleToken>) => {
  const tokenId = computed(() => {
    const tokenValue = unref(token);
    return `${tokenValue.cid}-${tokenValue.icpTicker}-${tokenValue.name}`;
  });
  return useQuery({
    queryKey: getIcpBalanceQueryKey(tokenId),
    queryFn: async (): Promise<number> => {
      const swap = new ICPSwap();
      try {
        await swap.create();
        const tokenValue = unref(token);
        const balance = (await swap.getICPAmount(
          tokenValue.icpTicker,
          tokenValue.balance,
        )) as { ok: string | bigint };
        const value = Number(balance.ok);
        return Number((value / 10 ** 8).toFixed(2));
      } catch {
        return 0;
      }
    },
  });
};
