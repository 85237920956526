<script setup lang="ts">
  import { ref } from 'vue';
  import { getAvatarUrl, parseNFTUrl } from '@/utils/avatar';
  import { isImageUrl, isDataImage, isValidUrl } from '@/utils';
  import { Tabs } from '@/shared/ui/tabs';
  import UploadArea from './UploadArea.vue';

  const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'upload-files', files: FileList): void;
    (e: 'paste-image', src: string): void;
  }>();

  const imageUrl = ref('');
  const isInvalidImageUrl = ref(false);
  const tabItems = ['Upload', 'Embed'];
  const activeTab = ref(tabItems[0]);

  function pasteImage() {
    if (isImageUrl(imageUrl.value) || isDataImage(imageUrl.value)) {
      emit('paste-image', imageUrl.value);
    } else if (
      imageUrl.value &&
      isValidUrl(imageUrl.value) &&
      parseNFTUrl(imageUrl.value)
    ) {
      const pastedNftUrl = getAvatarUrl(imageUrl.value);
      emit('paste-image', pastedNftUrl);
    } else {
      isInvalidImageUrl.value = true;
    }
  }
</script>

<template>
  <div class="flex flex-col items-center justify-center relative">
    <base-button
      variant="custom"
      custom-classes="absolute right-8 top-4 p-1 rounded-lg block md:hidden"
      @click="$emit('close')"
    >
      <base-icon name="x" size="w-4 h-4" />
    </base-button>
    <tabs
      type="underlined"
      direction="horizontal"
      class="w-full"
      off-color="text-white hover:text-gray-300"
      on-color="border-b-2"
    >
      <template #tabs="{ onColor, offColor }">
        <ul
          class="flex w-full px-8 gap-x-4 border-solid border-b border-gray-700 mx-2 mb-px"
        >
          <li
            v-for="tab in tabItems"
            :key="tab"
            variant="custom"
            :class="[tab === activeTab ? onColor : offColor]"
            class="p-4 cursor-pointer"
            @click.stop="activeTab = tab"
          >
            <span>{{ tab }}</span>
          </li>
        </ul>
      </template>
      <template #content>
        <template v-if="activeTab === tabItems[0]">
          <upload-area @files-uploaded="$emit('upload-files', $event)" />
        </template>
        <template v-if="activeTab === tabItems[1]">
          <div class="flex flex-col items-center w-full my-embed px-4 py-2">
            <input
              type="url"
              class="w-full py-3 bg-gray-900 border-0 rounded-lg outline-none focus:border focus:border-indigo-600"
              placeholder="Image Link"
              v-model="imageUrl"
              @keyup.enter="pasteImage"
              @input="isInvalidImageUrl = false"
            />
            <span
              v-if="isInvalidImageUrl"
              class="h-5 pt-1 text-sm text-red-500"
            >
              {{ $t('invalidImageUrlError') }}
            </span>
            <base-button
              variant="primary"
              size="small"
              class="mt-4"
              @click.prevent.stop="pasteImage"
            >
              {{ $t('embedImage') }}
            </base-button>
          </div>
        </template>
      </template>
    </tabs>
  </div>
</template>
