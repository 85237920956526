<script lang="ts" setup>
  import { useI18n } from 'vue-i18n';
  import type { NFTUpdateView } from 'dfx/edge/edge.did';
  import { useFile } from '@/composables';
  import storage from '@/services/storage';
  import { POST_IMAGE_ACCEPTED_MIME_TYPES } from '@/common';
  import { parseNFTUrl } from '@/utils';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { TitleHeader } from '@/shared/ui/base-dialog';
  import { useBreakpoint, useDialog, useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { usePreferenceSettings } from '@/entities/user-setting';
  import { useFileUploadDialog } from '@/entities/file-upload';
  import {
    buildNftUpdateView,
    useVerifyNftPfpMutation,
    type NonFungibleToken,
  } from '@/entities/token';
  import { useWalletPairing } from '@/entities/wallets';
  import { usePairDialog } from '@/features/wallets';
  import { useProfileUpdate } from '@/features/user-settings';
  import SelectNftDialogContent from './SelectNftDialogContent.vue';
  import ProfilePhotoMenuDialog from './ProfilePhotoMenuDialog.vue';

  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanMd } = useBreakpoint();
  const { showToast } = useToast();
  const { currentUser, setIsLoading } = useUser();
  const { updatePfp } = useProfileUpdate();
  const { validateFile, fileToBase64 } = useFile();
  const { openFileUploadDialog } = useFileUploadDialog();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { primaryChainOption } = usePreferenceSettings();
  const { mutateAsync: verifyNftPfp } = useVerifyNftPfpMutation();
  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { openAddWalletDialog } = usePairDialog();

  const save = (srcOrNft: string | NFTUpdateView) => {
    if (typeof srcOrNft === 'string') {
      updatePfp({ Url: srcOrNft });
    } else {
      if (
        srcOrNft.meta[0] &&
        srcOrNft.kind[0] &&
        'InternetComputer' in srcOrNft.kind[0] &&
        !parseNFTUrl(srcOrNft.meta[0])
      ) {
        showToast({
          type: 'error',
          title: t('userSettings.invalidIcpNftAddressMessage'),
          durationSeconds: 5,
        });
        return;
      }
      updatePfp({ Nft: srcOrNft });
    }
  };

  const uploadImage = async (files: FileList) => {
    if (files.length) {
      setIsLoading(true);
      const file = files[0];
      if (!validateFile(file, POST_IMAGE_ACCEPTED_MIME_TYPES)) {
        showToast({
          type: 'error',
          title: t('userSettings.invalidImageFileMessage'),
          durationSeconds: 5,
        });
        setIsLoading(false);
        return;
      }
      closeDialog();
      const base64 = await fileToBase64(file);
      const src = await storage.uploadBase64(base64);
      setIsLoading(false);
      if (src.length > 0) {
        save(src);
      } else {
        showToast({
          type: 'error',
          title: t('userSettings.errorUploadingFile'),
          durationSeconds: 5,
        });
      }
    }
  };

  const onUpdatePFP = (token: NonFungibleToken) => {
    const nftUpdateView = buildNftUpdateView(token);
    save(nftUpdateView);
    if (token.chain === 'sol') {
      verifyNftPfp(token);
    }
    closeDialog();
  };

  const openSelectNftDialog = () => {
    openConfiguredDialog({
      content: {
        component: SelectNftDialogContent,
        emits: {
          'update:selected-nft': onUpdatePFP,
        },
      },
      header: {
        component: TitleHeader,
        props: {
          title: t('editProfilePhoto'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[500px] min-h-[400px] overflow-hidden`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
        contentClasses: 'relative min-h-20',
      },
    });
  };

  const openMenuDialog = () => {
    openConfiguredDialog({
      content: {
        component: ProfilePhotoMenuDialog,
        emits: {
          upload: () => {
            closeDialog();
            handleImageUpload();
          },
          'use-nft': () => {
            closeDialog();
            handleSelectNft();
          },
        },
      },
      dialog: {
        contentClasses: dialogDefaults.dialog.contentClasses,
        showCloseButton: false,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
        showHeader: false,
      },
    });
  };

  const handleImageUpload = () => {
    openFileUploadDialog(uploadImage, {
      accept: POST_IMAGE_ACCEPTED_MIME_TYPES.join(','),
      multiple: false,
      dragMessage: t('dragImage'),
      title: t('uploadProfilePhoto'),
    });
  };

  const handleSelectNft = async () => {
    if (primaryChainOption.value?.chain === 'sol') {
      await fetchPairedWallets();
      if (firstPairedSolanaWallet.value) {
        openSelectNftDialog();
      } else {
        openAddWalletDialog();
      }
    } else {
      openSelectNftDialog();
    }
  };
</script>

<template>
  <div v-if="isSmallerThanMd">
    <base-button
      variant="custom"
      custom-classes="inline relative"
      @click="openMenuDialog"
    >
      <user-avatar size="size-37" :item="currentUser" />
      <div
        class="absolute flex justify-center items-center bottom-4 right-0 rounded-full bg-white bg-opacity-8 backdrop-blur text-white p-2.5"
      >
        <base-icon name="upload" size="size-5" />
      </div>
    </base-button>
  </div>
  <div v-else class="flex gap-4 justify-start items-end">
    <user-avatar size="size-37" :item="currentUser" class="z-2" />
    <div class="flex gap-4 items-center py-5">
      <base-tooltip
        append-to-body
        placement="bottom"
        tag="div"
        :offset="[0, 18]"
        :content="$t('userSettings.avatarRequirements')"
      >
        <template #default="{ hide }">
          <base-button
            variant="secondary"
            size="small"
            @click="hide(), handleImageUpload()"
          >
            <base-icon name="upload" size="size-5" />
            <span>{{ $t('upload') }}</span>
          </base-button>
        </template>
      </base-tooltip>
      <base-button variant="tertiary" size="small" @click="handleSelectNft">
        <base-icon name="wallet" size="size-5" />
        {{ $t('useNFT') }}
      </base-button>
    </div>
  </div>
</template>
