<script lang="ts" setup>
  import { type BaseDialogProps } from './types';
  import { computed, ref } from 'vue';
  import { BaseBackdrop } from '../base-backdrop';
  import { onClickOutside, onKeyStroke, type MaybeElement } from '@vueuse/core';

  const props = withDefaults(defineProps<BaseDialogProps>(), {
    showCloseButton: true,
    closeOnClickOutside: true,
    zIndex: 'z-50',
    backdropBlur: 'backdrop-blur-sm',
  });
  const emit = defineEmits<{
    (e: 'update:opened', opened: boolean): void;
  }>();

  const dialogContent = ref<MaybeElement>();
  const contentStyle = computed(() => {
    if (props.dialogClasses) {
      return props.dialogClasses;
    }
    return [
      'relative w-full bg-gray-975 border border-gray-785 border-opacity-50 antialiased rounded-2xl shadow-2xl',
      props.contentClasses,
    ];
  });

  onClickOutside(
    dialogContent,
    () => {
      if (props.closeOnClickOutside) {
        emit('update:opened', false);
      }
    },
    {
      ignore: ['.fake-copy-element'],
    },
  );

  onKeyStroke('Escape', () => {
    if (props.closeOnClickOutside) {
      emit('update:opened', false);
    }
  });
</script>

<template>
  <teleport to="#dialog-view">
    <base-backdrop
      v-if="opened"
      :class="[zIndex, backdropBlur, margin]"
      class="justify-center items-center"
    >
      <div ref="dialogContent" :class="contentStyle">
        <slot name="header">
          <base-button
            v-if="showCloseButton"
            variant="link"
            class="absolute top-6 right-9 z-10"
            @click.stop="emit('update:opened', false)"
          >
            <base-icon name="x" size="w-4 h-4" />
          </base-button>
        </slot>
        <slot />
      </div>
    </base-backdrop>
  </teleport>
</template>
