<script setup lang="ts">
  import { computed } from 'vue';
  import IcpFungie from './IcpFungie.vue';
  import SolanaFungie from './SolanaFungie.vue';
  import type { FungibleToken } from '../types';

  const props = defineProps<{
    tokens: FungibleToken[];
  }>();

  // TODO: get balance in USD
  const getTokenBalance = (token: FungibleToken) => {
    if (token.chain === 'icp') {
      return token.toDisplay();
    }
    return token.balance;
  };

  const sortedTokens = computed(() => {
    return [...props.tokens].sort((a, b) =>
      getTokenBalance(a) > getTokenBalance(b) ? -1 : 1,
    );
  });
</script>

<template>
  <template v-for="token in sortedTokens">
    <icp-fungie v-if="token.chain === 'icp'" :key="token.name" :token="token" />
    <solana-fungie
      v-else-if="token.chain === 'sol'"
      :key="token.address"
      :token="token"
    />
  </template>
</template>
