<template>
  <div
    v-if="open"
    class="flex flex-col items-center justify-center bg-white/[0.04] border border-[#333B47]/[0.5] rounded-xl mb-3"
  >
    <div
      class="relative flex flex-row w-full p-3 border-b border-[#333B47]/[0.5]"
    >
      <span class="w-full text-lg font-bold text-center">Add Link</span>
      <div
        class="absolute right-0 mt-0.5 p-1 mr-4 transition-all duration-300 w-8 h-8 rounded-md bg-white/[0.08] flex items-center justify-center cursor-pointer"
        @click="$emit('close')"
      >
        <base-icon name="close" />
      </div>
    </div>
    <div class="px-3 pt-4 pb-8 text-center">
      <input
        ref="linkUrl"
        type="url"
        class="w-full py-3 px-4 bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md outline-none focus:outline-none focus:ring-0"
        placeholder="Link Url"
        @keyup.enter="addLink"
      />
      <span
        class="mt-2 mb-1 text-sm text-gray-400 transition-opacity duration-300"
        :class="{
          'opacity-0': !showLinkWarning,
          'opacity-100': showLinkWarning,
        }"
      >
        {{ $t('linkWarning') }}
      </span>
      <input
        ref="linkTitle"
        type="text"
        class="w-full py-3 px-4 bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md outline-none focus:outline-none focus:ring-0"
        placeholder="Text (optional)"
        @keyup.enter="addLink"
      />
      <div class="mt-2 text-left">
        <input
          v-model="isLinkEmbed"
          type="checkbox"
          class="border-[#333B47]/[0.32] bg-black/[0.24] rounded-md outline-none focus:outline-none focus:ring-0"
        />
        <span class="mt-1 ml-1 text-sm"> Embed?</span>
      </div>

      <DefaultButton
        text="Add Link"
        extra-classes="!text-sm mt-4 px-4"
        @click.prevent.stop="addLink"
      />
    </div>
  </div>
</template>

<script>
  import enrichService from '../../services/enrich.js';
  import DefaultButton from '../buttons/DefaultButton.vue';

  export default {
    components: {
      DefaultButton,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      initialText: {
        type: String,
        default: null,
      },
    },
    emits: [
      'close',
      'insert-link',
      'embed-content',
      'embed-link',
      'update-height',
    ],
    data() {
      return {
        showLinkWarning: false,
        linkUrl: null,
        isLinkEmbed: false,
      };
    },
    computed: {
      isLinkPostOrPortal() {
        return (
          this.$refs.linkUrl?.value?.indexOf('dscvr.one/post') > -1 ||
          this.$refs.linkUrl?.value?.indexOf('dscvr.one/p/') > -1
        );
      },
    },
    watch: {
      open(isOpen) {
        if (isOpen) {
          this.isLinkEmbed = false;
          this.$nextTick(() => {
            if (this.initialText) {
              this.$refs.linkTitle.value = this.initialText;
            }
            this.$refs.linkUrl.focus();
          });
        }
      },
    },
    methods: {
      addLink() {
        if (
          !this.$refs.linkUrl.value ||
          !this.isValidUrl(this.$refs.linkUrl.value) ||
          this.$refs.linkUrl.value === ''
        ) {
          this.showLinkWarning = true;
          return;
        }

        if (this.isLinkEmbed && !this.isLinkPostOrPortal) {
          this.embedLink(this.$refs.linkUrl.value);
        } else {
          this.$emit(
            'insert-link',
            this.$refs.linkUrl.value,
            this.$refs.linkTitle.value,
          );
        }

        if (this.$refs.linkUrl.value.indexOf('dscvr.one/post') > -1) {
          const postId = this.$refs.linkUrl.value
            .replace('https://dscvr.one/post/', '')
            .split('/')[0];
          this.$emit('embed-content', 'post', postId);
        } else if (this.$refs.linkUrl.value.indexOf('dscvr.one/p/') > -1) {
          const portalId = this.$refs.linkUrl.value.replace(
            'https://dscvr.one/p/',
            '',
          );
          this.$emit('embed-content', 'portal', portalId);
        }

        this.$emit('close');
        this.showLinkWarning = false;
        this.$emit('update-height');
      },
      async embedLink(url) {
        if (url && this.isValidUrl(url)) {
          await enrichService
            .enrichUrl(url)
            .then((response) => {
              if ('html' in response.data) {
                const htmlData = response.data['html'];
                const div = document.createElement('div');

                div.innerHTML = htmlData;

                try {
                  if (div.firstElementChild.style['max-width']) {
                    div.firstElementChild.style['max-width'] = '';
                  }
                  div.firstElementChild.style['display'] = 'block';
                } catch (error) {
                  console.error(error);
                }

                return div.innerHTML;
              } else {
                return false;
              }
            })
            .then((html) => {
              if (html) {
                this.$emit(
                  'embed-link',
                  this.isLinkEmbed,
                  this.$sanitize(html),
                );
              }
            });
        }
      },
    },
  };
</script>
