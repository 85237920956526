<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { LiveChatSidePanel, liveChatModel } from '@/entities/live';
  import { useSettingsDialog } from '@/features/live/room-settings';
  import { onBeforeUnmount } from 'vue';
  import { SendGeneralMessageInput } from '@/features/live/send-general-message';
  import { GeneralMessages } from '@/features/live/general-messages';
  import { UsersStage } from '@/features/live/users-stage';
  import { ElapsedRuntime } from '@/features/live/elapsed-runtime';
  import { RoomMenu } from './components';
  import type { PanelViewMode } from './types';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useBreakpoint } from '@/shared/model';

  withDefaults(defineProps<ListenLiveRoomModalProps>(), {
    modalWidth: () => 'w-full md:w-112',
    isAuth: () => true,
  });

  const {
    isConnected,
    selectedRoom,
    peerCount,
    speakingUserUsername,
    isModerator,
    liveMessages,
    toggleRecording,
    subscribeToActions,
    leaveRoom,
    endRoom,
    isAudioEnabled,
    liveMessagesCount,
    isSpeaker,
    toggleMic,
  } = liveChatModel.use();

  const { openSettingsDialog } = useSettingsDialog();
  const { isSmallerThanLg } = useBreakpoint();

  const usersShownOnSmallScreen = computed(
    () => isSmallerThanLg.value && sidePanelMode.value === 'users',
  );

  onMounted(async () => {
    await subscribeToActions();
  });

  interface ListenLiveRoomModalProps {
    modalWidth?: string;
    isAuth?: boolean;
  }

  const sidePanelMode = ref<PanelViewMode>(null);

  /**
   *
   */
  function leaveAndClose() {
    isModerator.value ? endRoom() : leaveRoom();
  }

  onBeforeUnmount(() => {
    if (isConnected.value) leaveRoom();
  });
</script>

<template>
  <Transition>
    <div
      class="fixed z-50 flex flex-col justify-end h-screen w-screen md:w-auto text-white outline-none bottom-0 md:right-0 md:right-18 md:bottom-7 bg-opacity-20 animated fadeIn faster focus:outline-none"
    >
      <div
        class="relative flex flex-col bg-gray-990 border border-gray-785 border-opacity-50 md:shadow-lg md:rounded-2.5xl pt-4"
        :class="{
          [modalWidth]: modalWidth,
          'relative lg:w-auto w-full': !isAuth,
          'absolute bottom-0 md:relative md:bottom-auto md:top-auto': isAuth,
          'h-full md:h-auto': isConnected,
        }"
      >
        <Transition :name="isSmallerThanLg ? '' : 'fade-right'">
          <live-chat-side-panel
            v-show="isConnected && sidePanelMode && !usersShownOnSmallScreen"
            class="absolute -mt-4 -mr-4 md:rounded-2.5xl lg:rounded-r-none lg:rounded-2.5xl z-40 w-full h-full lg:right-full lg:-z-1"
            @close="sidePanelMode = null"
          >
            <template #header>
              <h2 v-if="sidePanelMode === 'chat'" class="lg:hidden">Chats</h2>
              <div class="flex items-center" v-if="sidePanelMode === 'users'">
                <span class="text-gray-300 tracking-wider">{{
                  $t('listeningNow')
                }}</span>
                <base-icon
                  name="live-users"
                  size="w-3.5 h-3.5"
                  class="inline text-indigo-300 ml-1"
                />
                <span class="text-indigo-300 ml-2">{{ peerCount }}</span>
              </div>
            </template>
            <template #default>
              <div
                v-if="sidePanelMode === 'users'"
                class="relative mt-10 overflow-y-auto thin-scrollbar max-h-[25rem]"
              >
                <!-- Users Stage -->
                <users-stage
                  :show-all-listeners="true"
                  :show-speaker-title="false"
                  :show-raised-hand-listeners="true"
                  grid-classes="grid-cols-2 col-auto grow"
                />
              </div>
              <div
                v-if="sidePanelMode === 'chat'"
                class="h-full flex flex-col justify-end"
              >
                <general-messages :messages="liveMessages" class="relative" />
                <send-general-message-input />
              </div>
            </template>
          </live-chat-side-panel>
        </Transition>
        <div class="px-4 h-full md:h-auto">
          <div v-if="!isConnected" class="z-10 relative w-full h-full py-6">
            <span
              class="spinner-big absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            ></span>
          </div>
          <div v-if="isConnected" class="p-4">
            <!-- Top Control Bar -->
            <div
              class="flex justify-between items"
              :class="{ 'mb-7': !usersShownOnSmallScreen }"
            >
              <elapsed-runtime
                v-if="!usersShownOnSmallScreen"
                @click="toggleRecording()"
              />
              <div
                v-if="!usersShownOnSmallScreen"
                class="flex space-x-4 pt-0.5"
              >
                <div v-if="false">UP</div>
                <div v-if="false">CC</div>
                <div class="cursor-pointer relative">
                  <base-dropdown placement="bottom-end">
                    <template #button="{ toggle }">
                      <base-icon
                        name="ellipsis"
                        size="w-5 h-5"
                        class="text-white"
                        @click="toggle"
                      />
                    </template>
                    <template #content="{ hide }">
                      <div
                        class="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl p-3"
                      >
                        <ul class="flex flex-col space-y-3">
                          <li
                            class="flex space-x-3 rounded-md p-2 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                            @click="hide(), openSettingsDialog()"
                          >
                            <base-icon
                              name="settings"
                              size="w-5 h-5"
                              class="text-gray-300"
                            />
                            <span class="whitespace-nowrap"
                              >Live Room Settings</span
                            >
                          </li>
                          <li
                            class="border-t border-white border-opacity-12"
                          ></li>
                          <li
                            class="p-2 cursor-pointer"
                            @click="hide(), leaveAndClose()"
                          >
                            <span class="text-red-400 whitespace-nowrap"
                              >End Session</span
                            >
                          </li>
                        </ul>
                      </div>
                    </template>
                  </base-dropdown>
                </div>
              </div>
            </div>

            <!-- Room Info -->
            <div v-if="isConnected">
              <div v-if="!usersShownOnSmallScreen" class="mb-2">
                <span v-if="selectedRoom" class="text-lg font-semibold">{{
                  selectedRoom.name
                }}</span>
              </div>
              <div class="flex items-center mt-0.5">
                <base-icon
                  v-if="usersShownOnSmallScreen"
                  name="outlined-arrow-left"
                  size="w-6 h-6"
                  class="cursor-pointer text-gray-400 mr-2 lg:hidden hover:text-white transition duration-500"
                  @click="sidePanelMode = null"
                />
                <span class="text-gray-300 tracking-wider">{{
                  $t('listeningNow')
                }}</span>
                <base-icon
                  name="live-users"
                  size="w-3.5 h-3.5"
                  class="inline text-indigo-300 ml-1"
                />
                <span class="text-indigo-300 ml-2">{{ peerCount }}</span>
              </div>
            </div>
          </div>

          <!-- Room Users -->
          <div
            v-show="isConnected"
            class="relative mt-6 overflow-y-auto thin-scrollbar ms:max-h-124 mb-12 px-1 max-h-[25rem]"
            :class="usersShownOnSmallScreen ? 'min-h-96' : 'md:min-h-72'"
          >
            <!-- Users Stage -->
            <users-stage
              :show-all-listeners="true"
              :show-speaker-title="!usersShownOnSmallScreen"
              :show-raised-hand-listeners="usersShownOnSmallScreen"
              :grid-classes="
                usersShownOnSmallScreen
                  ? 'grid-cols-2 col-auto grow'
                  : undefined
              "
            />
          </div>
        </div>

        <!-- Room Bottom Bar -->
        <div class="relative">
          <div
            v-show="isConnected && speakingUserUsername"
            class="absolute bottom-0 left-0 right-0 flex justify-between px-7 py-2 bg-gray-990 rounded-b-xl width-full"
          >
            <div v-if="speakingUserUsername" class="flex items-center">
              <div
                class="flex justify-center items-center p-1 rounded-full bg-black"
              >
                <base-icon name="audio" size="w-4 h-4" class="inline" />
              </div>
              <span class="text-xs text-gray-100 ml-3">
                {{ speakingUserUsername }}
              </span>
              <span class="text-xs text-gray-400 ml-1"> is speaking </span>
            </div>
          </div>
        </div>

        <!-- Room Menu -->
        <room-menu
          v-if="isConnected"
          v-model="sidePanelMode"
          :is-mic-on="isAudioEnabled"
          :is-speaker="isSpeaker"
          :message-count="liveMessagesCount"
          @update:is-mic-on="toggleMic"
        />
      </div>
    </div>
  </Transition>
</template>

<style scoped>
  .fade-right-enter-active,
  .fade-right-leave-active {
    transition: transform 0.5s ease-out, opacity 0.8s ease-out;
  }

  .fade-right-enter-from,
  .fade-right-leave-to {
    opacity: 0;
    transform: translateX(90%);
  }

  .fade-right-enter-to,
  .fade-right-leave-from {
    opacity: 1;
    transform: translateX(0%);
  }
  .h-max {
    height: 100vh;
  }

  @media (min-width: 768px) {
    .h-max {
      height: max-content;
    }

    .max-screen-h {
      max-height: calc(100vh - 4rem);
    }
  }

  .bg-recording-gradient {
    background: linear-gradient(297.24deg, #f5576c 8.25%, #f093fb 91.76%);
  }
</style>
