import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getUserPortalRolesByIdKey } from './keys';
import type { Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import type { PortalInfoView, PortalView, UserView } from 'dfx/edge/edge.did';
import { sortRoles } from '../lib/sort-roles';

export const useGetUserPortalRolesQuery = (
  portal: Ref<PortalInfoView | PortalView | undefined>,
  user: Ref<UserView | undefined>,
  enabled: Ref<boolean>,
) => {
  const portalId = computed(() => portal.value?.id.toString());
  const userId = computed(() => user.value?.id.toText());
  return useQuery({
    queryKey: [...getUserPortalRolesByIdKey(portalId, userId)],
    queryFn: async () => {
      if (!portal.value || !user.value) return;
      const data = await dscvrIcApi.user.getUserPortalRoles({
        portalId: portal.value.id,
        userId: user.value.id,
      });
      if (data && data[0]?.member.roles) {
        const member = data[0].member;
        const roles = sortRoles(data[0].member.roles);
        return { member, roles };
      }
      return null;
    },
    enabled,
  });
};
