import { actor } from '../base';

export const logCanvasInteraction = (appUrl: string, contentId: bigint) => {
  return actor().log_canvas_interaction(appUrl, contentId);
};

export const verifyAppTransaction = (
  appUrl: string,
  chainId: string,
  tx: string,
) => {
  return actor().verify_app_transaction({
    app_url: appUrl,
    chain_id: chainId,
    tx,
  });
};

export const signResponse = (untrusted: string) => {
  // TODO: Pending implementation: return icClientInstance.sign_response(untrusted);
  return new Promise<string>((resolve) => resolve(''));
};

export const acceptTransaction = (
  appUrl: string,
  validatorRequestId: string,
  txId: string,
) => {
  return actor().log_app_transaction({
    action: { Proceeded: null },
    app_url: appUrl,
    validator_request_id: validatorRequestId,
    tx_id: [txId],
  });
};

export const rejectTransaction = (
  appUrl: string,
  validatorRequestId: string,
  txId: string,
) => {
  return actor().log_app_transaction({
    action: { Rejected: null },
    app_url: appUrl,
    validator_request_id: validatorRequestId ?? '',
    tx_id: txId ? [txId] : [],
  });
};

export const reportTransaction = (
  appUrl: string,
  validatorRequestId: string,
  txId: string,
  reason: string,
  comments?: string,
) => {
  // TODO: reason and comment will be set on a different field on another PR
  // for now is fixed to a constant value
  const malicious = { Malicious: 'Malicious Transaction' };
  return actor().log_app_transaction({
    action: { Reported: [malicious] },
    app_url: appUrl,
    validator_request_id: validatorRequestId,
    tx_id: txId ? [txId] : [],
  });
};
