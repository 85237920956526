import { ALL, FOLLOW, HOME, PORTALS } from '@/common';
import { event, set } from 'vue-gtag';

const contentActionMap = new Map();
const CONTENT_ROUTE_WHITELIST = [HOME, FOLLOW, PORTALS, ALL];

function getPostActionTrackingId(routeName, contentId) {
  return `${routeName}_${contentId}`;
}

export function setPostActionPageIndex(routeName, contentId, index) {
  if (CONTENT_ROUTE_WHITELIST.indexOf(routeName) === -1) {
    return;
  }
  contentActionMap.set(getPostActionTrackingId(routeName, contentId), index);
}

export function trackIndexedPostAction(routeName, contentId, action) {
  if (contentActionMap.has(getPostActionTrackingId(routeName, contentId))) {
    trackEvent(
      'post_action_index',
      contentActionMap.get(getPostActionTrackingId(routeName, contentId)),
      action,
    );
  }
}

/**
 *
 * @param eventName
 * @param event_category
 * @param event_label
 */
export function trackEvent(eventName, event_category, event_label = '') {
  try {
    event(eventName, {
      event_category: event_category,
      event_label: event_label,
    });
  } catch (error) {
    console.log('tkr', error);
  }
}

/**
 *
 * @param eventName
 * @param params
 */
export function trackGAEvent(eventName, params) {
  try {
    event(eventName, params);
  } catch (error) {
    console.log('tkr', error);
  }
}

export function setGAUserId(userId) {
  try {
    set({ user_id: userId });
  } catch (error) {
    console.log('tkr', error);
  }
}

/**
 *
 * @param curreny
 * @param value
 * @param portal
 */
export function trackEventMonetize(curreny, value, portal) {
  event('purchase', {
    affiliation: portal.toString(),
    value: value,
    tax: 0,
    shipping: 0,
    currency: curreny,
    items: [],
  });
}
