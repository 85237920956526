<script setup lang="ts">
  import { onMounted, ref, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';
  import {
    EXPLORE_PEOPLE_FEATURED,
    EXPLORE_PORTALS,
    EXPLORE_PORTALS_FEATURED,
    EXPLORE_PEOPLE,
  } from '@/common';
  import { BaseLayout } from '@/layouts';
  import ExploreHeader from './components/ExploreHeader.vue';
  import FeaturedPortals from './components/FeaturedPortals.vue';
  import FeaturedPeople from './components/FeaturedPeople.vue';
  import AllPortals from './components/AllPortals.vue';
  import AllPeople from './components/AllPeople.vue';
  import type { IconName } from '@/shared/ui/base-icon';
  import { useGetRandomizedRecommendedPortalsQuery } from '@/entities/portal';
  import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';
  import { useChainBehavior } from '@/entities/user-setting';
  import { useGetRandomizedRecommendedUsersQuery } from '@/entities/user';
  import { isEqual } from 'lodash-es';

  interface TabItem {
    key: 'portals' | 'people' | 'all-portals' | 'all-people';
    icon?: IconName;
    label?: string;
    route: string;
    metaTitle?: string;
  }

  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n({ useScope: 'global' });
  const { contentPreset } = useChainBehavior();

  const isSearchVisible = ref(true);

  const tabs: TabItem[] = [
    {
      key: 'portals',
      icon: 'outline-portal',
      label: t('portals'),
      route: EXPLORE_PORTALS_FEATURED,
      metaTitle: 'Explored featured portals - DSCVR',
    },
    {
      key: 'people',
      icon: 'vuesax-linear-profile-2user',
      label: t('people'),
      route: EXPLORE_PEOPLE_FEATURED,
      metaTitle: 'Explore recommended people - DSCVR',
    },
    {
      key: 'all-portals',
      route: EXPLORE_PORTALS,
    },
    {
      key: 'all-people',
      route: EXPLORE_PEOPLE,
    },
  ];

  const selectedTab = ref<TabItem>(tabs[0]);

  const queryParams = computed<RandomizedRecommendationQuery>(() => ({
    filter_tags: [['explore-featured']],
    count: 50,
    content_preset: contentPreset.value,
  }));

  const {
    data: portalRecommendation,
    isLoading: isLoadingPortalRecommendation,
  } = useGetRandomizedRecommendedPortalsQuery(queryParams);

  const { data: userRecommendation, isLoading: isLoadingUserRecommendation } =
    useGetRandomizedRecommendedUsersQuery(queryParams);

  const loadSelectedTabByRoute = () => {
    const tab = tabs.find((tab) => tab.route === route.name);
    if (tab) {
      selectedTab.value = tab;
    }
  };

  const selectTab = (tab: TabItem) => {
    isSearchVisible.value = true;
    selectedTab.value = tab;
    router.push({
      name: tab.route,
    });
  };

  onMounted(() => {
    loadSelectedTabByRoute();
  });

  useHead({
    title: () =>
      tabs.find((tab) => tab.key === selectedTab.value.key)?.metaTitle ?? '',
  });
</script>

<template>
  <base-layout>
    <explore-header @search-visible="isSearchVisible = $event" />
    <div class="sticky top-0 w-full h-0 z-30">
      <div class="w-full h-18 bg-gray-975"></div>
    </div>
    <div
      v-if="selectedTab.key === 'portals' || selectedTab.key === 'people'"
      class="flex justify-center gap-7 text-gray-400 text-sm py-8 sticky top-18 bg-gray-975 z-40"
    >
      <base-button
        v-for="tab in tabs.slice(0, 2)"
        :key="tab.key"
        variant="custom"
        rounded="rounded-none"
        class="font-medium text-lg pb-2 border-b-2 border-transparent w-fit mb-4 min-w-24 hover:text-white transition-all duration-300 capitalize cursor-pointer gap-2"
        :class="{ 'text-white border-white': isEqual(selectedTab, tab) }"
        @click="selectTab(tab)"
      >
        <base-icon :name="tab.icon" size="w-6 h-6" />
        <span>{{ tab.label }}</span>
      </base-button>
    </div>
    <div class="md:px-8">
      <featured-portals
        v-if="selectedTab.key === 'portals'"
        :featured="portalRecommendation"
        :loading="isLoadingPortalRecommendation"
        :show-search="!isSearchVisible"
      />
      <featured-people
        v-else-if="selectedTab.key === 'people'"
        :featured="userRecommendation"
        :loading="isLoadingUserRecommendation"
        :show-search="!isSearchVisible"
      />
      <all-portals
        v-else-if="selectedTab.key === 'all-portals'"
        :show-search="!isSearchVisible"
      />
      <all-people
        v-else-if="selectedTab.key === 'all-people'"
        :show-search="!isSearchVisible"
      />
    </div>
  </base-layout>
</template>
