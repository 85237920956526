import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import type { AccountType } from '@/entities/auth';
import type { LeftSideBarState } from './types';

export const usePersistentStore = defineStore(
  'persistent',
  () => {
    const leftSideBar = reactive<LeftSideBarState>({
      contentType: 'Favorites',
    });

    const lastLoginOption = ref<AccountType | null>(null);

    const lastTimeSignUpDialogTriggered = ref<number>(0);

    const isSignUpDialogTriggered = ref(false);

    const verificationEmail = reactive({
      sent: false,
      resend: false,
      verified: false,
    });

    return {
      isSignUpDialogTriggered,
      lastLoginOption,
      lastTimeSignUpDialogTriggered,
      leftSideBar,
      verificationEmail,
    };
  },
  {
    persist: {
      key: 'dscvr-state',
    },
  },
);
