import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { ActiveStreakWindow } from 'dfx/edge/edge.did';
import { formatZeroPadding } from '@/shared/lib';

export const useStreakStore = defineStore('streak', () => {
  const isPoweredUp = ref(false); // used to manage animation in daily streak dialog
  const activeStreak = ref<ActiveStreakWindow>(); // current streak window
  const showClaimButton = ref(false); // used to show/hide claim button in daily streak dialog
  const streakCountdown = ref('--:--:--'); // used to display countdown in daily streak dialog
  // this is used to position to collect the daily streak button position during onboarding
  const activeStreakButtonPosition = ref<{
    top: number;
    right: number;
    left: number;
  }>({
    top: 0,
    right: 0,
    left: 0,
  });

  const setStreakCountdown = async (nextClaimAt: bigint) => {
    const timeDifference =
      nextClaimAt - BigInt(new Date().getTime()) * 1000000n;

    if (timeDifference >= 0) {
      const hours = timeDifference / 3600000000000n;
      const minutes = (timeDifference % 3600000000000n) / 60000000000n;
      const seconds = (timeDifference % 60000000000n) / 1000000000n;
      const formattedHours = formatZeroPadding(Number(hours));
      const formattedMinutes = formatZeroPadding(Number(minutes));
      const formattedSeconds = formatZeroPadding(Number(seconds));
      streakCountdown.value = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      showClaimButton.value = true;
    }
  };

  return {
    activeStreak,
    activeStreakButtonPosition,
    isPoweredUp,
    showClaimButton,
    streakCountdown,
    setStreakCountdown,
  };
});
