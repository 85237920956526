import { apiInstance } from '../base';
import type { AxiosResponse } from 'axios';
import type { PublicDscvrUserData } from './types';

const BASE_URL = '/users';

export const getUserByPk = (pk: string) => {
  return apiInstance
    .get<AxiosResponse<PublicDscvrUserData>>(`${BASE_URL}/by-pk/${pk}`)
    .then((res) => res.data.data);
};
