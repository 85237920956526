import { actor } from '../base';
import type {
  ReferralCommunityPaged,
  ReferralCommunityQuery,
} from 'dfx/edge/edge.did';

export const getReferralCommunity = (params: ReferralCommunityQuery) => {
  return actor()
    .get_referral_community(params)
    .then((res) => res) as Promise<ReferralCommunityPaged>;
};
