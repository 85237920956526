<script lang="ts" setup>
  import {
    getAbbreviatedMemberCount,
    getAbbreviatedContentCount,
    getPortalIcon,
    usePortalUserStatus,
  } from '@/entities/portal';
  import { PORTAL_CONTENT } from '@/common';
  import { pluralize } from '@/shared/lib';

  const { favorites } = usePortalUserStatus();
</script>

<template>
  <div
    v-if="favorites && favorites.length"
    class="flex flex-col items-start w-full gap-4"
  >
    <div class="text-base font-medium text-gray-200">Top Portals</div>
    <div
      v-for="portal in favorites.slice(0, 3)"
      :key="Number(portal.id)"
      class="flex gap-4"
    >
      <base-button
        variant="link"
        class="flex gap-4"
        :to="{
          name: PORTAL_CONTENT,
          params: { slug: portal.slug },
        }"
      >
        <img
          alt="icon"
          class="rounded-lg size-12 min-w-12"
          v-lazy="getPortalIcon(portal)"
        />
        <div class="flex flex-col items-start">
          <div class="font-semibold truncate">
            {{ portal.name }}
          </div>
          <div class="flex items-start gap-4">
            <div class="flex items-center gap-2">
              <base-icon name="outlined-users2" size="w-4 h-4" />
              {{ getAbbreviatedMemberCount(portal) }}
              {{ pluralize(Number(portal.member_count), 'member') }}
            </div>
            <div class="flex items-center gap-2">
              <base-icon name="vuesax-linear-simcard-2" size="w-4 h-4" />
              {{ getAbbreviatedContentCount(portal) }}
              {{ pluralize(Number(portal.member_count), 'post') }}
            </div>
          </div>
        </div>
      </base-button>
    </div>
  </div>
</template>
