import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { Principal } from '@dfinity/principal';

export const useSetUserTopUsersMutation = () => {
  return useMutation({
    mutationKey: ['set-user-top-users-mutation'],
    mutationFn: (params: { userIds: Principal[] }) =>
      dscvrIcApi.onboarding.setUserTopUsers(params.userIds),
  });
};
