import { client } from '../utils/dfinity';
import { callWithRetry } from '../utils/general';

export default {
  //portal_requires_phone
  requiresPhone(update) {
    return client.actor.portal_requires_phone(update.id, update.requiresPhone);
  },
  createPortal(portal) {
    return client.actor.create_portal(portal);
  },
  updatePortal(portal) {
    return client.actor.update_portal(portal);
  },
  updatePortalIcon(portalIcon) {
    return client.actor.update_portal_icon(portalIcon);
  },
  updatePortalInfoRules(portal_id, rules) {
    return client.actor.update_portal_info_rules(portal_id, rules);
  },
  updatePortalInfoLinks(portal_id, links) {
    return client.actor.update_portal_info_links(portal_id, links);
  },
  updatePortalInfoCoverPhoto(portal_id, icon_url) {
    return client.actor.update_portal_info_cover_photo(portal_id, icon_url);
  },
  getPortal(name) {
    return client.actor.get_portal(name);
  },
  getPortals(slugs) {
    return callWithRetry(() => {
      return client.edgeActor.get_portals(slugs);
    });
  },
  getPortalRoles(portal_id) {
    return client.actor.get_portal_roles(portal_id);
  },
  getPortalUserRoles(portal_id, user_id) {
    return client.actor.get_user_portal_roles(portal_id, user_id);
  },
  getPortalMembers(portal_id, page_size, page_start) {
    return client.actor.get_portal_members({
      includes_roles: false,
      page_size: page_size,
      role_ids: [],
      portal_id: portal_id,
      page_start: page_start,
    });
  },
  getPortalRoleMembers(role_id) {
    return client.actor.get_role_members(role_id);
  },
  addPortalRole(portal_id, role) {
    return client.actor.add_portal_role(portal_id, role);
  },
  deletePortalRole(role_id) {
    return client.actor.delete_portal_role(role_id);
  },
  updatePortalRolesOrdinals(portal_id, ordinals) {
    return client.actor.update_portal_role_ordinals(portal_id, ordinals);
  },
  addPortalMemberRole(portal_id, members) {
    return client.actor.add_member_role(portal_id, members);
  },
  removePortalMemberRole(portal_id, members) {
    return client.actor.remove_member_role(portal_id, members);
  },
  accept_portal_invite(query) {
    return client.actor.accept_portal_invite(query);
  },
};
