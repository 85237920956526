import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useBanUserMutation = () => {
  return useMutation({
    mutationKey: ['ban-user-mutation'],
    mutationFn: (params: dscvrIcApi.user.BanUserParams) =>
      dscvrIcApi.user.banUser(params),
  });
};
