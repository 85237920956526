<template>
  <form @submit.prevent="onSubmit">
    <div class="max-w-md">
      <div class="pt-4 pb-2">Replica URL</div>
      <input
        v-model="url"
        type="text"
        class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
        placeholder="Replica URL"
        :disabled="!enable"
      />
      <div class="pt-4 pb-2">Canister ID</div>
      <input
        v-model="canisterId"
        type="text"
        class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
        placeholder="Canster ID"
        :disabled="!enable"
      />
      <div class="pt-4 pb-2"></div>

      <span class="pt-4 pb-2">
        <input v-model="enable" type="checkbox" />
        <span class="pl-2">Enable</span>
      </span>
      <div class="flex items-center pt-4">
        <form-button text="Update" form-ready />
      </div>
    </div>
  </form>
</template>

<script>
  import FormButton from '../buttons/FormButton.vue';
  import { client } from '../../utils/dfinity';

  export default {
    name: 'test-replica-settings-form',
    components: {
      FormButton,
    },
    data() {
      return {
        url: client.testReplicaSettings.url,
        canisterId: client.testReplicaSettings.canisterId,
        enable: client.testReplicaSettings.enable,
      };
    },
    methods: {
      onSubmit() {
        client.setTestReplicaSettings(this.url, this.canisterId, this.enable);
      },
    },
  };
</script>
