<script setup lang="ts">
  import { computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { HOME } from '@/common';
  import { useNavigation } from '../model/composables/use-navigation';

  const { direction, isRunningStandalone } = useNavigation();
  const route = useRoute();
  const router = useRouter();

  const showBackButton = computed(() => {
    return isRunningStandalone && route.name !== HOME;
  });

  watch(
    () => direction.value,
    (direction) => {
      if (direction === 'right') {
        router.go(-1);
      }
      if (direction === 'left') {
        router.go(1);
      }
    },
  );
</script>

<template>
  <base-button
    v-if="showBackButton"
    variant="custom"
    class="flex lg:hidden items-center justify-center bg-blur bg-black bg-opacity-20 fixed z-40 left-6 bottom-18 rounded-2xl p-2 back-button"
    @click="$router.go(-1)"
  >
    <base-icon name="arrow-left" size="size-6" />
  </base-button>
</template>

<style scoped>
  .back-button {
    display: none;
  }
  @media all and (display-mode: fullscreen) {
    .back-button {
      display: block;
    }
  }
</style>
