export type CollectionItem =
  | { slug: string }
  | { username: string }
  | { key: string };
export type CollectionType = 'grid' | 'list';

export const getCollectionItemKey = (item: CollectionItem) => {
  if ('slug' in item) {
    return item.slug;
  }
  if ('username' in item) {
    return item.username;
  }
  return item.key;
};
