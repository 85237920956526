<script setup lang="ts">
  import { useBreakpoint } from '@/shared/model';
  import { useInvite } from '@/features/invite';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { canInvite } from '@/shared/lib/permissions';
  import { useUser } from '@/entities/user';

  defineProps<{
    portalView: PortalView;
  }>();

  const { openInvitePortalDialog } = useInvite();
  const { isSmallerThanMd } = useBreakpoint();
  const { isLoggedIn } = useUser();
</script>

<template>
  <base-button
    v-if="isLoggedIn && canInvite(portalView)"
    size="small"
    :variant="isSmallerThanMd ? 'glass' : 'custom'"
    :custom-classes="
      isSmallerThanMd
        ? undefined
        : 'gap-x-2 flex items-center min-h-8 rounded-2xl hover:bg-gray-820 ease-transition-all px-4 py-2 justify-center'
    "
    @click="openInvitePortalDialog(portalView)"
  >
    <base-icon name="directbox" size="w-5 h-5" />
    {{ $t('invite') }}
  </base-button>
</template>
