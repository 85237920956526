<template>
  <span class="btn-comment-section" @click="refresh">
    <base-icon name="reload" class="mr-2" />
    Refresh
  </span>
</template>

<script>
  export default {
    name: 'refresh-button',
    emits: ['refresh'],
    methods: {
      refresh() {
        this.$emit('refresh');
      },
    },
  };
</script>
