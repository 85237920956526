<script setup lang="ts">
  import { liveChatModel } from '@/entities/live';
  import { useVModel } from '@vueuse/core';

  const { emojis, chooseEmoji, currentUserEmoji } = liveChatModel.use();
  defineEmits<{
    (e: 'hide'): void;
  }>();
</script>

<template>
  <div
    id="emoji-picker"
    ref="emojiPickerRef"
    class="divide-gray-100 animate-fade-up"
  >
    <ul
      class="flex items-center text-sm text-white rounded-full border border-gray-785 shadow bg-gray-975 px-2 p-0.5"
      aria-labelledby="dropdownHoverButton"
    >
      <li
        v-for="emoji in emojis"
        :key="emoji.name"
        class="cursor-pointer transition-all duration-200 hover:scale-[200%] hover:drop-shadow-[0_8px_16px_rgba(0,0,0,0.4)] relative z-1 hover:z-10"
        :class="{
          'scale-[200%] drop-shadow-[0_8px_16px_rgba(0,0,0,0.4)] z-10':
            emoji.name === currentUserEmoji?.name,
        }"
      >
        <a
          class="block px-2 py-1"
          @click="
            chooseEmoji(emoji);
            $emit('hide');
          "
        >
          <base-icon :name="emoji.icon" size="w-6 h-6" />
        </a>
      </li>

      <li class="border-l border-gray-785 pl-1 ml-2">
        <div class="cursor-pointer" @click="$emit('hide')">
          <slot name="action"></slot>
        </div>
      </li>
    </ul>
  </div>
</template>
