import { HTML_PREFIX } from '@/common';

export const hasHTMLPrefix = (body: string): boolean => {
  return body.substring(0, 5) === HTML_PREFIX;
};

export const stripHTMLPrefix = (body: string) => {
  return body.replace(new RegExp(HTML_PREFIX, 'g'), '');
};

export const htmlToText = (html: string) => {
  return html
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/ +/g, ' ')
    .trim();
};

export const getH1Matches = (html: string) => {
  const h1TagRegExp = /<h1[^>]*>(?<content>[^<]+)<\/h1>/g;
  return [...html.matchAll(h1TagRegExp)];
};

export const convertH1Tags = (html: string, newTag = 'h2') => {
  const matches = getH1Matches(html);

  return matches.reduce((acc, match) => {
    if (!match.groups) return acc;
    return acc.replace(
      match[0],
      `<${newTag}>${match.groups.content}</${newTag}>`,
    );
  }, html);
};
