<script setup lang="ts">
  import { computed } from 'vue';
  import PostFriends from '../PostFriends.vue';
  import type { ContentView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    item: ContentView;
  }>();

  const count = computed(
    () =>
      props.item.frame_interaction_count -
      BigInt(props.item.frame_interaction_friends?.length ?? 0),
  );
</script>

<template>
  <post-friends
    v-if="item.frame_interaction_friends?.length"
    :max-icons-display="2"
    class="flex-1 cursor-auto"
    :item="item"
    :friends="item.frame_interaction_friends"
  >
    <span v-if="count" class="text-indigo-300"> +{{ count }} </span>
    <span class="text-gray-400">{{ $t('postFeature.interacted') }}</span>
  </post-friends>
  <div v-else class="flex items-center gap-2">
    <div
      class="flex items-center justify-center size-6 rounded-full overflow-hidden bg-gray-900"
    >
      <base-icon
        name="vuesax-bulk-programming-arrows"
        size="size-4"
        class="text-white"
      />
    </div>
    <span v-if="count" class="text-indigo-300">
      {{ count }}
    </span>
    <span class="text-gray-400">{{ $t('postFeature.interacted') }}</span>
  </div>
</template>
