import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { contentKey } from '@/shared/model';
import { dscvrIcApi } from '@/shared/api';
import type { ContentView } from 'dfx/edge/edge.did';

export const useGetContentRanksQuery = (content: Ref<ContentView>) => {
  return useQuery({
    queryKey: [
      contentKey,
      'get-content-ranks-by-id',
      content.value.id.toString(),
    ],
    queryFn: async () => {
      const data = await dscvrIcApi.post.getContentRanks(
        BigInt(content.value.id),
      );
      if (data && data[0] && data[0][0]) {
        return data[0][0];
      } else {
        return null;
      }
    },
  });
};
