<script setup lang="ts">
  import { useUser } from '@/entities/user';
  import { usePostDialog } from '@/features/post';
  import { UserAvatar } from '@/shared/ui/user-avatar';

  const { currentUser } = useUser();
  const { openCreateEditPostDialog } = usePostDialog();
</script>

<template>
  <div
    class="inline-flex items-center px-4 py-3 mb-3 shadow-sm bg-gray-950 md:mb-4 w-fill md:rounded-xl bg-createpostbar"
  >
    <user-avatar
      v-if="currentUser"
      :item="currentUser"
      size="w-10 h-10"
      class="flex-none"
    />
    <div
      class="w-full px-4 py-2.5 ml-3 text-sm font-medium text-gray-400 bg-gray-900 rounded-xl cursor-pointer hover:bg-gray-700"
      @click="openCreateEditPostDialog()"
    >
      Create a post...
    </div>
  </div>
</template>
