<script setup lang="ts">
  import { ref } from 'vue';
  import { fetchMedia } from '@/shared/lib';

  withDefaults(
    defineProps<{
      src: string;
      fitContain?: boolean;
    }>(),
    {
      fitContain: false,
    },
  );

  const emit = defineEmits<{
    (e: 'loaded'): void;
  }>();

  const isError = ref(false);

  /**
   *
   */
  function onError() {
    emit('loaded');
    isError.value = true;
  }
</script>

<template>
  <div
    v-if="isError"
    class="error w-full h-full flex items-center justify-center text-white text-opacity-70 p-7"
  >
    <div class="flex justify-center items-center flex-col">
      <base-icon
        name="error-bot"
        class="opacity-60"
        size="w-60 h-60 xl:w-80 xl:h-80"
      />
      <span class="relative right-2 xl:right-5 xl:text-xl">{{
        $t('errorLoadingImage')
      }}</span>
    </div>
  </div>
  <img
    v-else
    :src="fetchMedia(src)"
    class="w-full h-full max-h-160"
    :class="fitContain ? 'contain' : 'cover'"
    alt="image"
    @error="onError"
    @load="$emit('loaded')"
  />
</template>

<style scoped>
  img.cover {
    object-fit: cover;
  }
  img.contain {
    object-fit: contain;
  }
  .error {
    background: linear-gradient(
      161.15deg,
      rgba(103, 20, 204, 0.2) 12.73%,
      rgba(46, 104, 245, 0.2) 72.95%
    );
  }
</style>
