import type { Principal } from '@dfinity/principal';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import { MutationTypes } from '@/store/mutation-types';
import userService from '@/services/users';
import type { UsersState } from './types';
import type {
  RandomizedRecommendationQuery,
  UserView,
  PortalView,
  UserPlatformPair,
  UserSearchQuery,
  UserSearchResult,
} from 'dfx/edge/edge.did';

const FOLLOWING_CACHE_TIMEOUT_MS = 1000 * 60 * 5; // every 5 minutes

// initial state
const state = (): UsersState => ({
  userProfile: null,
  isBlocked: false,
  isLoading: false,
  userPortals: null,
  pairedPlatformsHash: new Map(),
  hotspots: [],
  feedRecommendations: [],
  exploreRecommendations: [],
  spotlightUsers: [],
  allUsersPaginated: {
    items: [],
    currentPage: 0n,
  },
  userReferralCode: '',
  followingHash: new Map(),
});

// getters
const getters: GetterTree<UsersState, unknown> = {
  userProfile: (state: UsersState) => {
    return state.userProfile;
  },
  isBlocked: (state: UsersState) => {
    return state.isBlocked;
  },
  isLoading: (state: UsersState) => {
    return state.isLoading;
  },
  userPortals: (state: UsersState) => {
    return state.userPortals;
  },
  pairedPlatformsHash: (state: UsersState) => {
    return state.pairedPlatformsHash;
  },
  hotspots: (state: UsersState) => {
    return state.hotspots;
  },
  feedRecommendations: (state: UsersState) => {
    return state.feedRecommendations;
  },
  exploreRecommendations: (state: UsersState) => {
    return state.exploreRecommendations;
  },
  spotlightUsers: (state: UsersState) => {
    return state.spotlightUsers;
  },
  allUsersPaginated: (state: UsersState) => {
    return state.allUsersPaginated;
  },
  userReferralCode: (state: UsersState) => {
    return state.userReferralCode;
  },
  followingHash: (state: UsersState) => {
    return state.followingHash;
  },
};

// actions
const actions: ActionTree<UsersState, unknown> = {
  [ActionTypes.SET_USER]({ commit }, user: UserView) {
    commit(MutationTypes.SET_USER_PROFILE, user);
  },
  [ActionTypes.TOGGLE_FOLLOW_USER](
    { commit, dispatch, state },
    user: UserView,
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    userService
      .toggleFollowUser(user.id)
      .then((response) => {
        if (response.status === 'happy') {
          const followingUserState = state.followingHash.get(
            user.id.toString(),
          );
          if (followingUserState) {
            commit(MutationTypes.SET_USER_FOLLOWING_STATUS, {
              userId: user.id.toString(),
              isFollowing: !followingUserState.isFollowing,
            });
          }
        }
        commit(MutationTypes.SET_IS_LOADING, false);
        dispatch(`auth/${ActionTypes.GET_USER}`, user.username, {
          root: true,
        });
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.IS_USER_FOLLOWING]({ commit, state }, userId: Principal) {
    const followingUserState = state.followingHash.get(userId.toString());
    if (
      followingUserState &&
      followingUserState.updatedAt >
        new Date(Date.now() - FOLLOWING_CACHE_TIMEOUT_MS)
    ) {
      return followingUserState.isFollowing;
    }
    return userService
      .isUserFollowing(userId)
      .then((response) => {
        commit(MutationTypes.SET_USER_FOLLOWING_STATUS, {
          userId,
          isFollowing: response,
        });
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  [ActionTypes.TOGGLE_BLOCK_USER]({ commit }, userId: Principal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    userService.blockUserToggle(userId).then(() => {
      commit(MutationTypes.TOGGLE_BLOCK);
      commit(MutationTypes.SET_IS_LOADING, false);
    });
  },
  [ActionTypes.IS_USER_BLOCKED]({ commit }, userId: Principal) {
    userService
      .getUserBlocked({
        page: 0n,
        page_size: 500n,
      })
      .then((response) => {
        const userIsBlocked =
          response.users.filter((user) => user.id.toText() === userId.toText())
            .length > 0;
        commit(MutationTypes.SET_USER_BLOCKED_STATUS, userIsBlocked);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  [ActionTypes.GET_USER_PORTALS]({ commit }, userId: Principal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    userService
      .getUserPortals(userId)
      .then((response) => {
        commit(MutationTypes.SET_USER_PORTALS, response);
        commit(MutationTypes.SET_IS_LOADING, false);
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PAIRED_PLATFORMS]({ commit, state }, userId: Principal) {
    if (state.pairedPlatformsHash.has(userId.toString())) {
      return;
    }
    userService.getPairedPlatformsByUser(userId).then((response) => {
      commit(MutationTypes.SET_USER_PAIRED_PLATFORMS, {
        userId,
        platforms: response,
      });
    });
  },
  [ActionTypes.GET_HOTSPOTS]({ commit }, query: RandomizedRecommendationQuery) {
    userService.getRandomizedRecommendedUsers(query).then((response) => {
      commit(MutationTypes.SET_HOTSPOTS, response);
    });
  },
  [ActionTypes.GET_FEED_RECOMENDATIONS](
    { commit },
    query: RandomizedRecommendationQuery,
  ) {
    userService.getRandomizedRecommendedUsers(query).then((response) => {
      commit(MutationTypes.SET_FEED_RECOMENDATIONS, response);
    });
  },
  [ActionTypes.GET_EXPLORE_RECOMENDATIONS](
    { commit },
    query: RandomizedRecommendationQuery,
  ) {
    userService.getRandomizedRecommendedUsers(query).then((response) => {
      commit(MutationTypes.SET_EXPLORE_RECOMENDATIONS, response);
    });
  },
  [ActionTypes.GET_SPOTLIGHT_USERS](
    { commit },
    query: RandomizedRecommendationQuery,
  ) {
    userService.getRandomizedRecommendedUsers(query).then((response) => {
      commit(MutationTypes.SET_SPOTLIGHT_USERS, response);
    });
  },
  async [ActionTypes.GET_USER_REFERRAL_CODE]({ commit }) {
    try {
      let response = await userService.getUserReferralCode();
      commit(MutationTypes.SET_USER_REFERRAL_CODE, response);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async [ActionTypes.ACCEPT_USER_INVITE](_, inviteCode: string) {
    try {
      let response = await userService.acceptUserInvite(inviteCode);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async [ActionTypes.GET_ALL_PAGINATED](
    { commit },
    payload: { request: UserSearchQuery; pagesCount?: bigint },
  ) {
    try {
      const response = await userService.searchPaginated({
        ...payload.request,
        page_size:
          payload.request.page_size * (payload.pagesCount ?? BigInt(1)),
      });
      if (response.status === 'happy' && response.result.length > 0) {
        commit(MutationTypes.SET_ALL_PAGINATED, {
          result: response.result[0],
          pagesCount: payload.pagesCount,
        });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

// mutations
const mutations: MutationTree<UsersState> = {
  [MutationTypes.SET_USER_PROFILE](state, user: UserView) {
    state.userProfile = user;
  },
  [MutationTypes.SET_USER_FOLLOWING_STATUS](state, { userId, isFollowing }) {
    state.followingHash.set(userId.toString(), {
      isFollowing,
      updatedAt: new Date(),
    });
  },
  [MutationTypes.TOGGLE_BLOCK](state) {
    state.isBlocked = !state.isBlocked;
  },
  [MutationTypes.SET_USER_BLOCKED_STATUS](state, isBlocked: boolean) {
    state.isBlocked = isBlocked;
  },
  [MutationTypes.SET_IS_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_USER_PORTALS](state, portals: PortalView[]) {
    state.userPortals = portals;
  },
  [MutationTypes.SET_USER_PAIRED_PLATFORMS](
    state,
    { userId, platforms }: { userId: Principal; platforms: UserPlatformPair[] },
  ) {
    const pairedPlatformsHash = state.pairedPlatformsHash;
    pairedPlatformsHash.set(userId.toString(), platforms);
  },
  [MutationTypes.SET_HOTSPOTS](state, hotspots: UserView[]) {
    state.hotspots = hotspots;
  },
  [MutationTypes.SET_FEED_RECOMENDATIONS](
    state,
    feedRecommendations: UserView[],
  ) {
    state.feedRecommendations = feedRecommendations;
  },
  [MutationTypes.SET_EXPLORE_RECOMENDATIONS](
    state,
    exploreRecommendations: UserView[],
  ) {
    state.exploreRecommendations = exploreRecommendations;
  },
  [MutationTypes.SET_SPOTLIGHT_USERS](state, spotlightUsers: UserView[]) {
    state.spotlightUsers = spotlightUsers;
  },
  [MutationTypes.SET_ALL_PAGINATED](
    state,
    { result, pagesCount }: { result: UserSearchResult; pagesCount?: bigint },
  ) {
    if (result.page === 0n) {
      state.allUsersPaginated.items = result.items;
      state.allUsersPaginated.currentPage = pagesCount ?? BigInt(0n);
    } else {
      const newPage = result.items.filter(
        (item) =>
          !state.allUsersPaginated.items.find(
            (user) => user.id.toText() === item.id.toText(),
          ),
      );
      state.allUsersPaginated.items =
        state.allUsersPaginated.items.concat(newPage);
      state.allUsersPaginated.currentPage =
        result.page + (pagesCount ?? BigInt(0n));
    }
  },
  [MutationTypes.SET_USER_REFERRAL_CODE](state, code: string) {
    state.userReferralCode = code;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
