<script setup lang="ts">
  import { computed, onMounted, ref, type Ref } from 'vue';
  import { useForm } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import * as yup from 'yup';
  import { type ChainOption, chainOptionsRestricted } from '@/utils';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import {
    SelectFormField,
    TextFormField,
    CheckboxFormField,
  } from '@/shared/ui/form-fields';
  import { useNFTGatingSetup } from '../../model/composables/use-nft-gating-setup';
  import EntrepotItem from './EntrepotItem.vue';
  import MultichainCollectionPreview from './MultichainCollectionPreview.vue';
  import type { CreateICPGateDto } from '../../types.ts';
  import type { PortalView, RoleView } from 'dfx/edge/edge.did';
  import {
    type SelectFieldOptionType,
    SelectFieldItem,
    FieldWrapper,
  } from '@/shared/ui/fields';
  import type { dscvrApi } from '@/shared/api';
  import { config } from '@/shared/lib';
  import { useChainBehavior } from '@/entities/user-setting';

  interface FormFields {
    chain?: ChainOption;
    collection_address?: string;
    nft_type?: SelectFieldOptionType;
    collection_name?: string;
    canister_id?: string;
    collection?: dscvrApi.multichainGating.EntrepotCollectionItem;
    role?: RoleView | string;
    new_role_name?: string;
    show_in_marketplace?: boolean;
  }

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const emit = defineEmits<{
    (e: 'saved'): void;
  }>();

  const {
    roleOptions,
    entrepotCollections,
    createNftCollection,
    fetchEntrepotCollections,
    validateMintAddress,
    getNftCollectionDetailsByAddress,
    validatePrincipalId,
    validateRoleName,
    createNewRole,
    fetchPortalRoles,
    NFT_TYPE_EXT,
    nftTypeOptions,
  } = useNFTGatingSetup(props.portalView);
  const { selectedChainType } = useChainBehavior();
  const { t } = useI18n({ useScope: 'global' });

  const schema = yup.object({
    chain: yup.object<ChainOption>().required(t('chainErrorRequired')),
    collection_address: yup.string().when('chain', {
      is: (chainOption?: ChainOption) =>
        chainOption && chainOption.chain !== 'icp',
      then: () =>
        yup
          .string()
          .required(t('collectionAddressErrorRequired'))
          .test({
            name: 'collection_address',
            message: t('collectionAddressErrorInvalid'),
            test: async (value) => {
              if (!value || !validateMintAddress(value)) {
                multichainNFTCollection.value = [];
                return false;
              }
              multichainNFTCollection.value =
                await getNftCollectionDetailsByAddress(value);
              return multichainNFTCollection.value.length > 0;
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
    nft_type: yup.object<SelectFieldOptionType>().when('chain', {
      is: (chainOption?: ChainOption) => chainOption?.chain === 'icp',
      then: () =>
        yup.object<SelectFieldOptionType>().required(t('nftTypeErrorRequired')),
      otherwise: () => yup.object<SelectFieldOptionType>().notRequired(),
    }),
    collection_name: yup.string().when(['chain', 'nft_type'], {
      is: (chainOption?: ChainOption, nft_type?: SelectFieldOptionType) =>
        chainOption &&
        chainOption.chain === 'icp' &&
        nft_type &&
        nft_type.value !== NFT_TYPE_EXT,
      then: () => yup.string().required(t('collectionNameErrorRequired')),
      otherwise: () => yup.string().notRequired(),
    }),
    canister_id: yup.string().when(['chain', 'nft_type'], {
      is: (chainOption?: ChainOption, nft_type?: SelectFieldOptionType) =>
        chainOption?.chain === 'icp' &&
        nft_type &&
        nft_type.value !== NFT_TYPE_EXT,
      then: () =>
        yup
          .string()
          .required(t('canisterIdErrorRequired'))
          .test({
            name: 'canister_id',
            message: t('canisterIdErrorInvalid'),
            test: (value) => {
              if (!value) {
                return false;
              }
              return validatePrincipalId(value);
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
    collection: yup
      .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
      .when(['chain', 'nft_type'], {
        is: (chainOption?: ChainOption, nft_type?: SelectFieldOptionType) =>
          chainOption?.chain === 'icp' && nft_type?.value === NFT_TYPE_EXT,
        then: () =>
          yup
            .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
            .required(t('collectionErrorRequired')),
        otherwise: () =>
          yup
            .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
            .notRequired(),
      }),
    role: yup.mixed<RoleView | string>().required(t('roleErrorRequired')),
    new_role_name: yup.string().when('role', {
      is: (role?: RoleView | string) => role && typeof role === 'string',
      then: () =>
        yup
          .string()
          .required(t('roleNameErrorRequired'))
          .max(20, t('roleNameErrorLength'))
          .test({
            name: 'new_role_name',
            message: t('roleNameErrorDuplicated'),
            test: (value) => {
              if (!value) {
                return false;
              }
              return validateRoleName(value);
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
    show_in_marketplace: yup.boolean().notRequired().default(true),
  });

  const defaultChainOption = computed(() => {
    if (selectedChainType.value === 'all') {
      return undefined;
    }
    return chainOptionsRestricted.find(
      (chainOption) => chainOption.chain === selectedChainType.value,
    );
  });

  const initialValues = computed(() => ({
    chain: defaultChainOption.value,
    collection_address: '',
    nft_type: undefined,
    collection_name: '',
    canister_id: '',
    collection: undefined,
    role: undefined,
    new_role_name: '',
    show_in_marketplace: config.ENABLE_MARKETPLACE_TAB,
  }));

  const { handleSubmit, controlledValues, errors } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });

  const multichainNFTCollection: Ref<
    dscvrApi.multichainGating.MultichainCollectionDetailDto[]
  > = ref([]);

  const isChainSet = computed(() => controlledValues.value.chain);

  const isICPChain = computed(
    () =>
      controlledValues.value.chain &&
      controlledValues.value.chain.chain === 'icp',
  );

  const isNotICPChain = computed(
    () =>
      controlledValues.value.chain &&
      controlledValues.value.chain.chain !== 'icp',
  );

  const isNFTTypeEXT = computed(
    () =>
      controlledValues.value.nft_type &&
      controlledValues.value.nft_type.value === NFT_TYPE_EXT,
  );

  const isNFTTypeNotEXT = computed(
    () =>
      controlledValues.value.nft_type &&
      controlledValues.value.nft_type.value !== NFT_TYPE_EXT,
  );

  const isNewRole = computed(
    () =>
      controlledValues.value.role &&
      typeof controlledValues.value.role === 'string',
  );

  const getRequest = async (
    values: FormFields,
  ): Promise<
    | CreateICPGateDto
    | dscvrApi.multichainGating.CreateMultichainGateDto
    | undefined
  > => {
    let roleId: string;
    if (typeof values.role === 'string') {
      const newRole = await createNewRole(values.new_role_name!);
      if (!newRole) {
        return;
      }
      roleId = newRole.id.toString();
    } else {
      roleId = values.role!.id!.toString();
    }

    if (values.chain?.chain !== 'icp') {
      return {
        portalSlug: props.portalView.slug,
        name: multichainNFTCollection.value[0].name,
        network: 'solana',
        nftCollectionAddress: values.collection_address,
        roleId,
        showMarketplaceListings: values.show_in_marketplace,
        rules: [],
      };
    }

    if (values.nft_type?.value !== NFT_TYPE_EXT) {
      return {
        portalSlug: props.portalView.slug,
        type: values.nft_type!.value,
        name: values.collection_name!,
        canisterId: values.canister_id!,
        roleId,
      };
    }

    return {
      portalSlug: props.portalView.slug,
      type: values.nft_type!.value,
      name: values.collection!.name,
      canisterId: values.collection!.id,
      roleId,
    };
  };

  const submit = handleSubmit(async (values) => {
    const request = await getRequest(values);
    if (!request) {
      return;
    }
    const response = await createNftCollection(request);
    if (response) {
      emit('saved');
    }
    if (typeof values.role === 'string') {
      fetchPortalRoles();
    }
  });

  onMounted(() => {
    fetchEntrepotCollections();
  });
</script>

<template>
  <form class="mt-6" @submit="submit">
    <div class="mt-4 mb-5 text-gray-400 text-center">
      Provide the NFT collection details below and assign a role to enable NFT
      gating for your portal.
    </div>
    <div class="flex flex-col gap-10 my-8">
      <div>
        <select-form-field
          name="chain"
          value-attr="chain"
          :items="chainOptionsRestricted"
          :label="$t('chain')"
          :error="errors.chain"
        />
      </div>

      <div v-if="isNotICPChain">
        <text-form-field
          name="collection_address"
          :label="$t('collectionAddress')"
          autocomplete="off"
          :error="errors.collection_address"
        >
          <template #suffix>
            <base-icon
              v-if="multichainNFTCollection.length"
              name="filled-check-circle"
              size="w-5 h-5"
              class="text-green-300"
            />
            <base-tooltip v-else :content="$t('collectionAddressLegend')">
              <base-icon
                name="filled-exclamation-triangle"
                size="w-5 h-5"
                class="text-orange-300"
              />
            </base-tooltip>
          </template>
        </text-form-field>
      </div>

      <div v-if="isICPChain">
        <select-form-field
          name="nft_type"
          :items="nftTypeOptions"
          :label="$t('nftType')"
          :error="errors.nft_type"
        />
      </div>

      <div v-if="isNotICPChain && multichainNFTCollection.length" class="-mt-6">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ $t('collectionName') }}
        </label>
        <field-wrapper>
          <div class="text-gray-400 flex-1">
            <multichain-collection-preview :item="multichainNFTCollection[0]" />
          </div>

          <base-icon
            name="filled-check-circle"
            size="w-5 h-5"
            class="text-green-300"
          />
        </field-wrapper>
      </div>

      <div v-if="isICPChain && isNFTTypeNotEXT">
        <text-form-field
          name="collection_name"
          :label="$t('collectionName')"
          autocomplete="off"
          :error="errors.collection_name"
        />
      </div>

      <div v-if="isICPChain && isNFTTypeNotEXT">
        <text-form-field
          name="canister_id"
          :label="$t('canisterPrincipalId')"
          autocomplete="off"
          :error="errors.canister_id"
        />
      </div>

      <div v-if="isICPChain && isNFTTypeEXT">
        <select-form-field
          name="collection"
          :items="entrepotCollections"
          :label="$t('searchNftCollection')"
          :error="errors.collection"
        >
          <template #label="{ item }">
            <entrepot-item :item="item" />
          </template>
        </select-form-field>
      </div>

      <div v-if="isChainSet">
        <select-form-field
          name="role"
          :items="roleOptions"
          :label="$t('nftHolderRole')"
          :error="errors.role"
        >
          <template #label="{ item }">
            <template v-if="typeof item === 'string'">
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ item }}</span>
            </template>
            <span v-else>{{ item.name }}</span>
          </template>
          <template #item="{ item }">
            <select-field-item v-if="typeof item === 'string'" separator>
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ item }}</span>
            </select-field-item>
            <select-field-item v-else>
              <span>{{ item.name }}</span>
            </select-field-item>
          </template>
        </select-form-field>
      </div>

      <div v-if="isChainSet && isNewRole">
        <text-form-field
          name="new_role_name"
          :label="$t('newRoleName')"
          autocomplete="off"
          :error="errors.new_role_name"
        />
      </div>

      <div v-if="isNotICPChain && config.ENABLE_MARKETPLACE_TAB" class="-mt-4">
        <checkbox-form-field name="show_in_marketplace" variant="toggle">
          {{ $t('showInMarketplace') }}
        </checkbox-form-field>
      </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex justify-end">
        <base-button variant="primary" size="small" class="w-auto">
          {{ $t('create') }}
        </base-button>
      </div>
    </div>
  </form>
</template>
