import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyTwitterProfileKey } from './keys';

export const useGetMyTwitterProfileQuery = () => {
  return useQuery({
    queryKey: getMyTwitterProfileKey,
    queryFn: dscvrApi.twitter.getMyTwitterProfile,
  });
};
