import { useQuery } from '@tanstack/vue-query';
import { enrichApi } from '@/shared/api';
import type { Ref } from 'vue';
import type { ContentView } from 'dfx/edge/edge.did';

export const useEnrichUrlQuery = (content: Ref<ContentView>) => {
  return useQuery({
    queryKey: ['enrich-url', content.value.url],
    queryFn: () => enrichApi.enrichUrl(content.value.url),
  });
};
