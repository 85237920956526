<script setup lang="ts">
  import { start as memeFighterStart } from '@memefighter/maker-core';
  import { nextTick, ref, watch } from 'vue';
  const props = defineProps<{
    open: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'paste-image', value: string): void;
  }>();

  const memeFigtherIframe = ref<HTMLIFrameElement | null>(null);
  const renderIframe = () => {
    if (memeFigtherIframe.value) {
      memeFighterStart({
        iframe: memeFigtherIframe.value,
      }).then((imgUrl) => {
        emit('paste-image', imgUrl);
        emit('close');
      });
    }
  };

  watch(
    () => props.open,
    () => {
      nextTick(() => {
        renderIframe();
      });
    },
  );
</script>

<template>
  <div
    v-if="open"
    class="flex flex-col items-center justify-center bg-white/[0.04] border border-[#333B47]/[0.5] rounded-xl mb-3"
  >
    <div
      class="relative flex flex-row w-full p-3 border-b border-[#333B47]/[0.5]"
    >
      <span class="w-full text-lg font-bold text-center">Meme Fighter</span>
      <div
        class="absolute right-0 mt-0.5 p-1 mr-4 transition-all duration-300 w-8 h-8 rounded-md bg-white/[0.08] flex items-center justify-center cursor-pointer"
        @click="$emit('close')"
      >
        <base-icon name="close" />
      </div>
    </div>
    <div class="px-3 pt-4 pb-8 text-center">
      <iframe
        ref="memeFigtherIframe"
        style="max-width: 100%; aspect-ratio: 1; width: auto; height: auto"
      ></iframe>
    </div>
  </div>
</template>
