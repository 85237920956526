import pkceChallenge from 'pkce-challenge';

const PKCE_CHALLENGE_LOCALSTORAGE_KEY = 'pkce-challenge';

export const usePkce = () => {
  const generatePkce = async () => {
    return await pkceChallenge();
  };

  const getLocalPkce = () => {
    return localStorage.getItem(PKCE_CHALLENGE_LOCALSTORAGE_KEY);
  };

  const setLocalPkce = (pkce: string) => {
    localStorage.setItem(PKCE_CHALLENGE_LOCALSTORAGE_KEY, pkce);
  };

  const generateAndStorePkce = async () => {
    const pkce = await generatePkce();
    setLocalPkce(pkce.code_verifier);
    return pkce;
  };
  return {
    generatePkce,
    getLocalPkce,
    setLocalPkce,
    generateAndStorePkce,
  };
};
