import { config } from '@/shared/lib';
import { client } from '@/utils/dfinity';

export default {
  async get_enabled_beta_features(): Promise<string | string[] | null> {
    if (!client.edgeActor) {
      return null;
    }

    if (config.BETA_FEATURES === 'all') {
      return 'all';
    } else if (config.BETA_FEATURES === 'auto') {
      // all beta features are enabled when the Pioneer role is set. In the
      // future different feature flags can be gated by different roles.
      try {
        return (await client.edgeActor.get_enabled_beta_features()) as string[];
      } catch (e) {
        console.error(`Error checking beta features: ${e}`);
      }
    }
    return null;
  },
};
