import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useSetPortalMemberStatusMutation = () => {
  return useMutation({
    mutationKey: ['set-portals-member-status-mutation'],
    mutationFn: (params: dscvrIcApi.portal.PortalMembersStatusMutationParams) =>
      dscvrIcApi.portal.setPortalMemberStatus(params),
  });
};
