<script setup lang="ts">
  import { toRef } from 'vue';
  import { PortalInfo } from '@/features/portal';
  import { LivePortalRoomPanel } from '@/widgets/live-portal-room-panel';
  import { Block as PwaBlock } from '@/widgets/pwa';
  import { CorporateLinks } from '@/widgets/corporate-links';
  import { useGetPortalBySlugQuery } from '@/entities/portal';

  const props = defineProps<{
    slug: string;
  }>();

  const { data: portalView } = useGetPortalBySlugQuery(
    toRef(() => props.slug),
    true,
  );
</script>

<template>
  <div class="w-full">
    <live-portal-room-panel />
    <portal-info v-if="portalView" :portal-view="portalView" />
    <pwa-block />
    <corporate-links />
  </div>
</template>
