import { unescape } from 'lodash-es';

export const userMentionRegExp =
  /<span data-type="mention"[^>]*?data-id="(?<id>[^"]+)"[^>]*>[^<]*?<\/span>/gm;
export const lightUserMentionRegExp = /\B@(?<id>[\w-]{3,})/g;
export const postMentionRegExp =
  /<preview[^>]*?type="post"[^>]*?id="(?<id>[^"]+)"[^>]*><\/preview>/gm;
export const portalMentionRegExp =
  /<preview[^>]*?type="portal"[^>]*?id="(?<id>[^"]+)"[^>]*><\/preview>/gm;
export const lazyImageRegExp =
  /<img loading="lazy" v-lazy="{ src: '(?<src>data:image\/[a-z]+;base64,[^']*)'[^}]+}"[^>]*\/>/g;
export const imageRegExp = /<img.*?src="(?<src>[^"]*)"[^>]*>/g;
export const iframeRegExp =
  /<div[^>]*><iframe.*?src="(?<src>[^"]*)"[^>]*><\/iframe><\/div>/g;
export const videoRegExp = /<video.*?src="(?<src>[^"]*)"[^>]*><\/video>/g;
export const magicEdenRegExp =
  /<a target="_blank" href="https:\/\/magiceden\.io\/item-details\/(?<id>[^?"]+)[^>]*>(?:<u>)?https:\/\/magiceden\.io\/item-details\/[^<]+(?:<\/u>)?<\/a>/g;
export const magicEdenPreviewRegExp =
  /<preview data-embed="[^"]*" type="nft" id="(?<id>[^"]*)"><\/preview>/g;
export const previewRegExp =
  /<preview data-embed="[^"]*" type="(?<type>[^"]*)" id="(?<id>[^"]*)"><\/preview>/g;
export const streakPreviewRegExp =
  /<embedded-streak days="(?<days>[^'']*)" multiplier="(?<multiplier>[^'']*)" points="(?<points>[^'']*)" username="(?<username>[^'']*)"><\/embedded-streak>/gm;
export const framePreviewRegExp =
  /<embedded-frame url="(?<url>[^"]*)"><\/embedded-frame>/gm;
export const canvasPreviewRegExp =
  /<embedded-canvas url="(?<url>[^"]*)"><\/embedded-canvas>/gm;
export const liteYoutubeRegExp =
  /<embedded-lite-youtube src="(?<src>[^"]*)"><\/embedded-lite-youtube>/g;
export const gimluckReviewRegExp =
  /<embedded-gimluck-reward points="(?<points>[^"]*)" username="(?<username>[^"]*)"(?: loss-message(?:="(?<lossMessage>[^"]*)")?)?><\/embedded-gimluck-reward>/gm;
/**
 *
 * @param username
 */
export function getUserMentionRegExp(username: string): RegExp {
  return new RegExp(
    `<span data-type="mention"[^>]*?data-id="${username}"[^>]*>[^>]*?</span>`,
    'gm',
  );
}

export function getLightUserMentionRegExp(username: string): RegExp {
  return new RegExp(`\\B@${username}`, 'gm');
}

/**
 *
 * @param postId
 */
export function getPostMentionRegExp(postId: string): RegExp {
  return new RegExp(
    `<preview[^>]*?type="post"[^>]*?id="${postId}"[^>]*></preview>`,
    'gm',
  );
}

/**
 *
 * @param portalSlug
 */
export function getPortalMentionRegExp(portalSlug: string): RegExp {
  return new RegExp(
    `<preview[^>]*?type="portal"[^>]*?id="${portalSlug}"[^>]*></preview>`,
    'gm',
  );
}

/**
 *
 * @param body
 */
export function removePreCodeTags(body: string): string {
  const preCodeRegExp =
    /<pre[^>]*><code[^>]*>(?<code>[\s\S]*?)<\/code><\/pre>/g;
  return body.replace(preCodeRegExp, '');
}

/**
 * Adding #t=0.1 allow previewing the video in IOS safari
 */
export function enrichVideoUrl(url: string | undefined): string {
  let src = unescape(url || '');
  if (!src.endsWith('#t=0.1')) {
    src = `${src}#t=0.1`;
  }
  return src;
}
