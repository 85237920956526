<script setup lang="ts">
  import { societyRsIdl, getEnv, pairWallet } from '@/utils/dfinity';
  import { trackEvent } from '@/utils/tracker';
  import { PairButton } from '@/entities/user-setting';
  import { useIcUnpairWalletMutation } from '@/entities/wallets';

  const props = defineProps<{
    isPaired: boolean;
    infinitySwapAddress: string;
  }>();

  const emit = defineEmits<{
    (event: 'unpair-start', value: boolean): void;
    (event: 'pair-start', value: boolean): void;
  }>();

  const unpair = async () => {
    emit('unpair-start', true);

    await useIcUnpairWalletMutation({ InfinitySwap: null });

    emit('unpair-start', false);
  };
  const login = async () => {
    if (!window.ic?.infinityWallet) {
      window.open('https://wallet.infinityswap.one/', '_blank');

      return;
    }
    trackEvent('user_settings_action', 'wallet_pair', 'infinity_swap');
    emit('pair-start', true);

    try {
      const canisterId = getEnv().canisterId;
      const whitelist = [canisterId];
      const host = getEnv().url;

      const publicKey = await window.ic.infinityWallet.requestConnect({
        whitelist,
        host,
      });

      console.log(`The connected user's public key is:`, publicKey);
      // Unfortunately even though we pass the "host" in, infinity wallet complains about it
      // It seems like "ic0.app" is hardcoded in the extension.
      const infinitySwapActor = await window.ic.infinityWallet.createActor({
        canisterId,
        interfaceFactory: societyRsIdl(),
        whitelist,
        host,
      });
      await pairWallet(
        { InfinitySwap: null },
        await window.ic.infinityWallet.getPrincipal(),
        infinitySwapActor,
      );
    } catch (error) {
      console.error(error);
    }
    emit('pair-start', false);
  };

  const togglePair = () => {
    if (props.isPaired) {
      unpair();
    } else {
      login();
    }
  };
</script>

<template>
  <pair-button
    :connected-id="isPaired ? infinitySwapAddress : undefined"
    @toggle="togglePair"
  >
    <base-icon name="infinity-swap" size="size-10" />
    <template #name>Infinity Swap</template>
    <template #connection>
      {{ $t('copyPrincipal') }}: {{ infinitySwapAddress }}
    </template>
  </pair-button>
</template>
