<script setup lang="ts">
  import { ref } from 'vue';

  defineProps<{
    ban?: boolean;
  }>();

  defineEmits<{
    (e: 'close'): void;
    (e: 'submit', reason: string): void;
  }>();

  const reason = ref<string>('');
</script>
<template>
  <div class="flex flex-col gap-4 p-6">
    <div>
      <div class="text-xs mt-4 mb-2 ml-1">{{ $t('reason') }}</div>
      <textarea
        v-model="reason"
        :placeholder="$t('reason')"
        class="block w-full bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
        rows="3"
        required
      />
    </div>

    <div class="flex justify-end gap-2 pt-8 w-full">
      <base-button
        variant="tertiary"
        size="medium"
        @click.stop="$emit('close')"
      >
        {{ $t('cancel') }}
      </base-button>
      <base-button
        variant="primary"
        size="medium"
        @click.stop="$emit('submit', reason)"
      >
        {{ ban ? $t('ban') : $t('kick') }}
      </base-button>
    </div>
  </div>
</template>
