<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { Tippy } from 'vue-tippy';
  import { useDocumentScroll } from '../../model/composables/use-document-scroll';
  import type { TippyRef } from './types';
  import type { Props, Instance } from 'tippy.js';

  const props = withDefaults(
    defineProps<Partial<Instance<Props> & { appendToBody: boolean }>>(),
    {
      placement: 'bottom',
      maxWidth: 'none',
      delay: 0,
      offset: [0, 0],
      appendToBody: false,
      showOnCreate: false,
    },
  );

  defineEmits<{
    (e: 'show'): void;
    (e: 'hide'): void;
  }>();

  const tippyRef = ref<TippyRef>();

  const appendTo = computed(() =>
    props.appendToBody ? document.body : undefined,
  );

  const hide = () => {
    if (tippyRef.value) {
      tippyRef.value.hide();
    }
  };

  useDocumentScroll({ hideOnScroll: hide });

  defineExpose({ hide });
</script>

<template>
  <tippy
    ref="tippyRef"
    v-bind="props"
    :append-to="appendTo"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <template #default="{ state }">
      <slot :hide="hide" :state="state" />
    </template>
    <template v-if="$slots.content" #content="{ state }">
      <slot
        name="content"
        :is-shown="state.isShown"
        :is-visible="state.isVisible"
      />
    </template>
  </tippy>
</template>
