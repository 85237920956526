import type { SocietyRsErrorType } from '../../model/edge';
import type { SortColumnDirection } from '../../ui/base-table-view';
import type { SocietyRsError, SortDirection } from 'dfx/edge/edge.did';

export const getSortDirection = (
  field: SortColumnDirection,
): [] | [SortDirection] => {
  if (field === 'asc') {
    return [{ Ascending: null }];
  }
  if (field === 'desc') {
    return [{ Descending: null }];
  }
  return [];
};

export const parseSocietyRsErrorType = (
  errorCode: [] | [SocietyRsError],
): SocietyRsErrorType => {
  if (errorCode.length === 0) {
    return 'InternalError';
  }
  if ('NotFound' in errorCode[0]) {
    return 'NotFound';
  }
  if ('Unauthorized' in errorCode[0]) {
    return 'Unauthorized';
  }
  if ('BadRequest' in errorCode[0]) {
    return 'BadRequest';
  }
  return 'InternalError';
};
