<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps<{
    count: number;
  }>();

  const isUserOnItsFirstStreakWeek = computed(() => props.count < 7);
</script>

<template>
  <div class="flex items-center gap-1">
    <base-icon
      :name="
        isUserOnItsFirstStreakWeek ? 'vuesax-bold-flash-only' : 'filled-flash'
      "
      size="w-4 h-4"
      :class="{
        'text-blue-600': isUserOnItsFirstStreakWeek,
      }"
    />
    <span class="tracking-[0.16px] font-medium">
      {{ isUserOnItsFirstStreakWeek ? '<7' : count }} {{ $t('days') }}
    </span>
  </div>
</template>
