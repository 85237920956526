<script lang="ts" setup generic="T extends SelectFieldBaseType">
  import { computed, ref } from 'vue';
  import { uniqueId } from 'lodash-es';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { SelectFieldItem } from '@/shared/ui/fields';
  import type { SelectFieldProps, SelectFieldBaseType } from '../types';
  import { objectPropertyToString } from '@/utils';
  import { BaseButton } from '@/shared/ui/base-button';

  const props = withDefaults(defineProps<SelectFieldProps<T>>(), {
    tabindex: 1,
  });

  const emit = defineEmits<{
    (e: 'update:modelValue', value: T): void;
    (e: 'blur', value: FocusEvent): void;
  }>();

  const selectFieldId = `selectfield-${uniqueId()}`;
  const opened = ref(false);
  const focused = ref(false);
  const buttonRef = ref<InstanceType<typeof BaseButton>>();
  const focusItemRef = ref<InstanceType<typeof BaseButton>>();
  const itemRefs = ref<InstanceType<typeof BaseButton>[]>([]);

  const inputWrapperStyle = computed(() => {
    return [
      props.disabled && '[&_*]:cursor-default opacity-50',
      props.customWrapperClasses,
      'flex justify-center items-center gap-2 bg-transparent appearance-none border-0 grow p-0 autofill:!bg-red relative',
    ];
  });
  const inputFieldStyle = computed(() => {
    return `select-field-button flex justify-between items-center gap-2 w-full bg-transparent appearance-none border-0 grow p-0 autofill:!bg-red ${props.customFieldClasses}`;
  });

  const valueFn = (item?: T): string | undefined => {
    if (item === null || item === undefined) {
      return undefined;
    }

    return objectPropertyToString(item, props.valueAttr, ['id', 'value']);
  };

  const labelFn = (item?: T): string | undefined => {
    if (item === null || item === undefined) {
      return undefined;
    }

    return objectPropertyToString(item, props.labelAttr, [
      'label',
      'text',
      'name',
      'title',
    ]);
  };

  const updateValue = (item: T) => {
    focusItemRef.value = undefined;
    emit('update:modelValue', item as T);
  };

  const focus = () => {
    focused.value = true;
  };

  const blur = (e: FocusEvent) => {
    focused.value = false;
    emit('blur', e);
  };

  const selectNext = () => {
    if (opened.value) {
      focusItemRef.value = itemRefs.value[0];
      focusItemRef.value.focus();
      return;
    }
    let index = 0;
    if (props.modelValue) {
      index = props.items.findIndex(
        (item) => valueFn(item) === valueFn(props.modelValue),
      );
      index++;
      index = index > props.items.length - 1 ? 0 : index;
    }
    const item = props.items[index];
    emit('update:modelValue', item);
  };

  const selectPrev = () => {
    if (opened.value) {
      focusItemRef.value = itemRefs.value[itemRefs.value.length - 1];
      focusItemRef.value.focus();
      return;
    }
    let index = props.items.length - 1;
    if (props.modelValue) {
      index = props.items.findIndex(
        (item) => valueFn(item) === valueFn(props.modelValue),
      );
      index--;
      index = index < 0 ? props.items.length - 1 : index;
    }
    const item = props.items[index];
    emit('update:modelValue', item);
  };

  const focusNext = () => {
    if (opened.value && focusItemRef.value) {
      let focusIndex = itemRefs.value.findIndex(
        (item) => item === focusItemRef.value,
      );
      focusIndex++;

      if (focusIndex >= itemRefs.value.length) {
        return;
      }
      focusItemRef.value = itemRefs.value[focusIndex];
      focusItemRef.value.focus();
    }
  };

  const focusPrev = () => {
    if (opened.value && focusItemRef.value) {
      let focusIndex = itemRefs.value.findIndex(
        (item) => item === focusItemRef.value,
      );
      focusIndex--;

      if (focusIndex < 0) {
        return;
      }
      focusItemRef.value = itemRefs.value[focusIndex];
      focusItemRef.value.focus();
    }
  };

  const focusOnItem = (index: number) => {
    if (opened.value && index >= 0 && index < itemRefs.value.length) {
      focusItemRef.value = itemRefs.value[index];
      focusItemRef.value.focus();
    }
  };
</script>

<template>
  <div :class="inputWrapperStyle">
    <slot name="prefix" />
    <div
      class="flex-1 select-field"
      :class="{
        'not-empty': modelValue && modelValue !== '',
        focused: opened || focused,
      }"
    >
      <base-dropdown
        tag="div"
        match-content-width
        :disabled="disabled"
        custom-classes="w-full"
        custom-content-classes="flex flex-col w-full max-h-60 overflow-y-auto thin-scrollbar bg-gray-725 border border-gray-785 border-opacity-50 right-0 top-full -mt-4"
        v-model:opened="opened"
        @update:opened="focusItemRef = undefined"
      >
        <template #button="{ toggle }">
          <base-button
            ref="buttonRef"
            variant="custom"
            type="button"
            :id="selectFieldId"
            :custom-classes="inputFieldStyle"
            :disabled="disabled"
            @click="toggle"
            @focus="focus"
            @blur="blur"
            @keydown.up.prevent="selectPrev"
            @keydown.down.prevent="selectNext"
            @keydown.escape="opened = false"
          >
            <div class="flex items-center gap-2 flex-1">
              <slot v-if="modelValue" name="label" :item="(modelValue as T)">
                <span>{{ labelFn(modelValue as T) }}</span>
              </slot>
            </div>
            <base-icon
              name="chevron-down"
              size="w-5 h-5"
              class="text-gray-400"
            />
          </base-button>
        </template>
        <template #content="{ hide, isVisible }">
          <template v-if="isVisible">
            <base-button
              v-for="(item, index) in items"
              variant="custom"
              type="button"
              custom-classes="select-field-option flex items-center w-full group"
              ref="itemRefs"
              :key="valueFn(item)"
              @click="hide(), updateValue(item)"
              @keydown.up.prevent="focusPrev"
              @keydown.down.prevent="focusNext"
              @keydown.escape="opened = false"
              @mouseover="focusOnItem(index)"
            >
              <slot name="item" :item="item">
                <select-field-item>
                  <slot name="label" :item="item">
                    <span>{{ labelFn(item) }}</span>
                  </slot>
                </select-field-item>
              </slot>
            </base-button>
          </template>
        </template>
      </base-dropdown>
    </div>
    <label
      :for="name"
      class="absolute top-[50%] left-0 -translate-y-2/4 pointer-events-none duration-200 ease-in-out text-gray-400"
    >
      {{ label }}
    </label>
    <slot name="suffix" />
  </div>
</template>

<style scoped>
  .select-field:focus,
  .select-field-button:focus,
  .select-field-button:focus-visible,
  .select-field-option:focus,
  .select-field-option:focus-visible {
    outline: none !important;
    border: 0px !important;
    box-shadow: none;
  }

  .select-field.focused + label,
  .select-field.not-empty + label {
    @apply -top-6 -left-4 text-sm text-gray-400;
  }
</style>
