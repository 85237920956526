import { Token } from './token';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';
import { IDL } from '@dfinity/candid';

/**
 *
 * @param memo
 */
function encodeMemo(memo) {
  return [
    ...new Uint8Array(
      IDL.encode(
        [
          IDL.Record({
            to: IDL.Principal,
            contentID: IDL.Opt(IDL.Nat64),
            from: IDL.Principal,
          }),
        ],
        [memo],
      ),
    ),
  ];
}

const idlFactory = ({ IDL }) => {
  const TxReceipt = IDL.Variant({
    Ok: IDL.Nat,
    Err: IDL.Variant({
      InsufficientAllowance: IDL.Null,
      InsufficientBalance: IDL.Null,
      ErrorOperationStyle: IDL.Null,
      Unauthorized: IDL.Null,
      LedgerTrap: IDL.Null,
      TipTooBig: IDL.Null,
      ErrorTo: IDL.Null,
      Other: IDL.Text,
      BlockUsed: IDL.Null,
      AmountTooSmall: IDL.Null,
    }),
  });
  const Metadata = IDL.Record({
    fee: IDL.Nat,
    decimals: IDL.Nat8,
    owner: IDL.Principal,
    logo: IDL.Text,
    name: IDL.Text,
    totalSupply: IDL.Nat,
    symbol: IDL.Text,
  });
  const Time = IDL.Int;
  const TokenInfo = IDL.Record({
    holderNumber: IDL.Nat,
    deployTime: Time,
    metadata: Metadata,
    historySize: IDL.Nat,
    cycles: IDL.Nat,
    feeTo: IDL.Principal,
  });
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const HttpRequest = IDL.Record({
    url: IDL.Text,
    method: IDL.Text,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
  });
  const StreamingCallbackToken = IDL.Record({
    key: IDL.Text,
    sha256: IDL.Opt(IDL.Vec(IDL.Nat8)),
    index: IDL.Nat,
    content_encoding: IDL.Text,
  });
  const StreamingCallbackResponse = IDL.Record({
    token: IDL.Opt(StreamingCallbackToken),
    body: IDL.Vec(IDL.Nat8),
  });
  const StreamingStrategy = IDL.Variant({
    Callback: IDL.Record({
      token: StreamingCallbackToken,
      callback: IDL.Func(
        [StreamingCallbackToken],
        [StreamingCallbackResponse],
        ['query'],
      ),
    }),
  });
  const HttpResponse = IDL.Record({
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HeaderField),
    streaming_strategy: IDL.Opt(StreamingStrategy),
    status_code: IDL.Nat16,
  });
  const TokenIdentifier = IDL.Text;
  const ExtMetadata = IDL.Variant({
    fungible: IDL.Record({
      decimals: IDL.Nat8,
      metadata: IDL.Opt(IDL.Vec(IDL.Nat8)),
      name: IDL.Text,
      symbol: IDL.Text,
    }),
    nonfungible: IDL.Record({ metadata: IDL.Opt(IDL.Vec(IDL.Nat8)) }),
  });
  const CommonError = IDL.Variant({
    InvalidToken: TokenIdentifier,
    Other: IDL.Text,
  });
  const Result = IDL.Variant({ ok: ExtMetadata, err: CommonError });
  const Memo = IDL.Vec(IDL.Nat8);
  return IDL.Service({
    CAPCanister: IDL.Func([], [IDL.Opt(IDL.Text)], ['query']),
    acceptCycles: IDL.Func([], [], []),
    airdropSize: IDL.Func([], [IDL.Nat], ['query']),
    airdrops: IDL.Func([IDL.Principal, IDL.Nat, IDL.Text], [TxReceipt], []),
    allowance: IDL.Func([IDL.Principal, IDL.Principal], [IDL.Nat], ['query']),
    approve: IDL.Func([IDL.Principal, IDL.Nat], [TxReceipt], []),
    approveExclude: IDL.Func([IDL.Principal], [], ['oneway']),
    availableCycles: IDL.Func([], [IDL.Nat], ['query']),
    balanceOf: IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    burn: IDL.Func([IDL.Nat], [TxReceipt], []),
    burnTokens: IDL.Func([IDL.Principal, IDL.Nat], [], ['oneway']),
    burntTokens: IDL.Func([], [IDL.Nat], []),
    decimals: IDL.Func([], [IDL.Nat8], ['query']),
    getAllowanceSize: IDL.Func([], [IDL.Nat], ['query']),
    getBurnRate: IDL.Func([], [IDL.Nat], ['query']),
    getHolders: IDL.Func(
      [IDL.Nat, IDL.Nat],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    getMetadata: IDL.Func([], [Metadata], ['query']),
    getTokenFee: IDL.Func([], [IDL.Nat], ['query']),
    getTokenInfo: IDL.Func([], [TokenInfo], ['query']),
    getUserApprovals: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    get_cycles: IDL.Func([], [IDL.Nat], ['query']),
    historySize: IDL.Func([], [IDL.Nat], ['query']),
    holdersSize: IDL.Func([], [IDL.Nat], ['query']),
    http_request: IDL.Func([HttpRequest], [HttpResponse], ['query']),
    id: IDL.Func([], [IDL.Principal], []),
    init: IDL.Func([], [], []),
    logo: IDL.Func([], [IDL.Text], ['query']),
    metadata: IDL.Func([TokenIdentifier], [Result], ['query']),
    mint: IDL.Func([IDL.Principal, IDL.Nat], [TxReceipt], []),
    name: IDL.Func([], [IDL.Text], ['query']),
    setBurnRate: IDL.Func([IDL.Nat], [], ['oneway']),
    setEXT: IDL.Func([], [], ['oneway']),
    setFee: IDL.Func([IDL.Nat], [], ['oneway']),
    setFeeTo: IDL.Func([IDL.Principal], [], ['oneway']),
    setLogo: IDL.Func([IDL.Text], [], ['oneway']),
    setName: IDL.Func([IDL.Text], [], ['oneway']),
    setOwner: IDL.Func([IDL.Principal], [], ['oneway']),
    setSymbol: IDL.Func([IDL.Text], [], ['oneway']),
    symbol: IDL.Func([], [IDL.Text], ['query']),
    tipSize: IDL.Func([], [IDL.Nat], ['query']),
    tips: IDL.Func(
      [IDL.Principal, IDL.Principal, IDL.Nat, Memo],
      [TxReceipt],
      [],
    ),
    totalSupply: IDL.Func([], [IDL.Nat], ['query']),
    transfer: IDL.Func([IDL.Principal, IDL.Nat], [TxReceipt], []),
    transferFrom: IDL.Func(
      [IDL.Principal, IDL.Principal, IDL.Nat],
      [TxReceipt],
      [],
    ),
    verifyCanister: IDL.Func([IDL.Principal], [IDL.Text], []),
    wallet_receive: IDL.Func([], [IDL.Record({ accepted: IDL.Nat64 })], []),
  });
};

export class DIP20Land {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  async getMetadata() {
    if (!this.actor) {
      await this.create();
    }
    const data = await this.actor.getMetadata();
    return {
      cid: this.canisterId,
      fee: data.fee,
      name: data.name,
      symbol: data.symbol,
      standard: 'dip20',
      decimals: data.decimals,
      icpTicker: '',
      icon: data.logo,
    };
  }

  async tip(to, amount, contentId) {
    const serviceAddress = Principal.fromText('ykaf2-xqaaa-aaaaj-qayaa-cai');

    const memo = encodeMemo({
      to: Principal.fromText(to),
      contentID: [contentId],
      from: this.identity.getPrincipal(),
    });

    console.log(amount);

    //https://codeshare.io/3AJYj6
    return await this.actor.tips(
      this.identity.getPrincipal(),
      serviceAddress,
      amount,
      memo,
    );
  }

  async balance(principal) {
    const tokens = await this.actor.balanceOf(principal);
    return new Token({
      balance: tokens,
    });
  }

  async transfer(to, amount) {
    return await this.actor.transfer(Principal.fromText(to), amount);
  }
}
