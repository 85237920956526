<script lang="ts" setup>
  // TODO: fix FSD
  import { ref, onBeforeMount, computed, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import type { PortalView } from 'dfx/edge/edge.did';
  import type { ButtonSize } from '@/shared/ui/base-button';
  import { useUser } from '@/entities/user';
  import { usePortalDialog } from '../model/composables/use-portal-dialog';
  import type { JoinState } from '../types';
  import { PORTAL_SETTINGS_PAGE_OVERVIEW } from '@/common';
  import { isPortalOwner } from '@/shared/lib';
  import { useQueryClient } from '@tanstack/vue-query';
  import { portalUserStatusKey } from '../api/keys';
  import { usePortalUserStatus } from '../model/composables/use-portal-user-status';

  const props = withDefaults(
    defineProps<{
      portalView: PortalView;
      isPortalList?: boolean;
      classes?: string;
      size?: ButtonSize;
      joinClass?: string;
    }>(),
    {
      isPortalList: false,
      classes: '',
      size: 'small',
      hideLabel: false,
    },
  );

  const emit = defineEmits<{
    (e: 'on-joined'): void;
  }>();

  const router = useRouter();
  const { currentUser } = useUser();
  const { openLeaveDialog } = usePortalDialog();
  const queryClient = useQueryClient();
  const { isPortalJoined, onJoin, onMutateFollowPortalToggle } =
    usePortalUserStatus();

  const joinState = ref<JoinState>('join');

  const isLoading = ref(false);

  const isJoinOrManageState = computed(() =>
    ['join', 'manage'].includes(joinState.value),
  );

  const baseIconName = computed(() => {
    if (joinState.value === 'manage') return 'settings';
    if (['leave', 'joined!'].includes(joinState.value)) return 'portal';
    return 'plus-circle';
  });

  const onManage = () => {
    router.push({
      name: PORTAL_SETTINGS_PAGE_OVERVIEW,
      params: {
        slug: props.portalView.slug,
      },
    });
  };

  const onLeave = () => {
    openLeaveDialog().then(async (leaved) => {
      if (leaved) {
        const mutation = await onMutateFollowPortalToggle(props.portalView);
        if (mutation.status === 'happy') {
          if (mutation.result[0]) {
            joinState.value = 'join';
            queryClient.refetchQueries({
              queryKey: [portalUserStatusKey],
            });
          }
        }
      }
    });
  };

  const onClick = () => {
    if (joinState.value === 'manage') {
      return onManage();
    } else if (joinState.value === 'join') {
      return onJoin(props.portalView);
    } else if (joinState.value === 'leave') {
      return onLeave();
    }
  };

  const onMouseLeaveJoin = () => {
    if (joinState.value !== 'join') return;
    joinState.value = 'join';
  };

  const onMouseOverJoin = () => {
    if (joinState.value !== 'leave') return;
    joinState.value = 'leave';
  };

  onBeforeMount(() => {
    if (
      currentUser.value &&
      props.portalView.owner.username &&
      isPortalOwner(props.portalView, currentUser.value)
    ) {
      joinState.value = 'manage';
    } else {
      joinState.value = isPortalJoined(props.portalView) ? 'leave' : 'join';
    }
  });

  // TODO: this is a hacky way to update the joinState when the portal is updated
  // (possible race condition somewhere where portal is updated after the button is loaded)
  watch(
    () => isPortalJoined(props.portalView),
    (isFollowing) => {
      joinState.value = isFollowing ? 'leave' : 'join';
    },
  );
</script>

<template>
  <base-button
    :variant="isJoinOrManageState ? 'primary' : 'glass'"
    :rounded="isPortalList ? 'rounded-lg' : 'rounded-xl'"
    :size="size"
    :disabled="isLoading"
    :class="[classes, 'min-h-8 !gap-0', joinClass]"
    @click.prevent.stop="onClick"
    @mouseover="onMouseOverJoin"
    @mouseleave="onMouseLeaveJoin"
  >
    <base-icon
      v-if="!isPortalList"
      :name="baseIconName"
      :size="size === 'x-small' ? 'w-4 h-4' : 'w-5 h-5'"
      class="flex-none"
    />
    <slot name="label" :join-state="joinState">
      <span
        class="capitalize"
        :class="{
          'ml-2': !isPortalList,
        }"
      >
        {{ joinState }}
      </span>
    </slot>
  </base-button>
</template>
