<script lang="ts" setup>
  import { onBeforeMount, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL, USER_WALLETS } from '@/common';
  import { parseNftName } from '@/entities/token';
  import { useOnboardingStateMachine } from '../../model/composables/use-onboarding-state-machine';
  import { useRouter } from 'vue-router';
  import { useUser } from '@/entities/user';
  import { useDialog, OnboardingStep } from '@/shared/model';
  import {
    useOpenLootboxMutation,
    useClaimLootboxItemMutation,
  } from '@/entities/lootbox';
  import { dscvrApi } from '@/shared/api';
  import { useGetOnboardingLootboxQuery } from '../../api/use-get-onboarding-lootbox.query';
  import { Loader } from '@/shared/ui/loader';

  const { t } = useI18n({
    useScope: 'global',
  });
  const router = useRouter();
  const { closeDialog } = useDialog();
  const { currentUser } = useUser();
  const { states } = useOnboardingStateMachine();
  const {
    data: onboardingLootbox,
    refetch: refetchGetOnboardingLootboxQuery,
    isLoading: isOnboardingLootboxLoading,
  } = useGetOnboardingLootboxQuery(currentUser);
  const {
    mutateAsync: openLootboxMutation,
    isPending: isPendingOpenLootboxMutation,
  } = useOpenLootboxMutation();
  const {
    mutateAsync: claimLootboxItemMutation,
    isPending: isPendingClaimLootboxItemMutation,
  } = useClaimLootboxItemMutation();

  const isLoading = computed(
    () =>
      isOnboardingLootboxLoading.value ||
      isPendingOpenLootboxMutation.value ||
      isPendingClaimLootboxItemMutation.value,
  );

  const lootboxes = ref<dscvrApi.lootbox.ILootboxItem[]>([]);

  const nftInfo = ref<{
    name: string;
    numberOrName: string;
    number?: string;
  }>();

  onBeforeMount(async () => {
    await refetchGetOnboardingLootboxQuery();
    if (onboardingLootbox.value?.id) {
      const response = await openLootboxMutation(onboardingLootbox.value.id);
      if (response.status === dscvrApi.lootbox.LootboxOpenStatus.OPENED) {
        if (response.lootbox && response.lootbox.items.length > 0) {
          await claimLootboxItemMutation({
            lootboxId: response.lootbox.id,
            tokenType: response.lootbox.items[0].tokenType,
          });
          lootboxes.value = response.lootbox.items;
          nftInfo.value = parseNftName(response.lootbox.items[0].token.name);
        }
      }
    }
    states.value[OnboardingStep.DONE].on('continue');
  });

  const onClickViewOtherNfts = async () => {
    closeDialog();
    router.push({
      name: USER_WALLETS,
      params: {
        username: currentUser.value.username,
      },
    });
  };
</script>

<template>
  <div
    class="absolute bottom-full md:bottom-1/2 left-1/2 -translate-x-1/2 w-[128%] -z-1"
  >
    <img
      :src="
        fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/confettis.gif`, {
          width: 634,
          height: 634,
        })
      "
    />
  </div>
  <div class="relative">
    <template v-if="isLoading">
      <loader variant="rainbow" size="size-10" border-width="border" />
    </template>
    <img
      v-else
      :src="lootboxes[0]?.token.image"
      alt="NFT"
      class="absolute top-0 md:-top-20 left-1/2 -translate-x-1/2 size-60 md:size-80 rounded-3xl shadow-[0px_23.442px_70.326px_0px_rgba(0,0,0,0.40)]"
    />
  </div>
  <div class="flex flex-col gap-4 mt-62 px-8 pb-6">
    <div
      class="flex justify-center gap-2 text-lg font-semibold tracking-[0.18px]"
    >
      <span>{{ nftInfo?.name }}</span>
      <span class="text-gray-400">
        {{ nftInfo?.number }}
      </span>
    </div>
    <div class="text-2xl font-bold tracking-[-0.12px]">
      {{ t('onboarding.nft.title') }}
    </div>
    <div class="flex flex-col gap-2">
      <span class="font-semibold text-lg text-gray-100 tracking[0.18px]">
        {{ t('onboarding.nft.subtitle') }}
      </span>
      <span class="font-medium text-gray-400 tracking-[-0.176px]">
        {{ t('onboarding.nft.description') }}
      </span>
    </div>
    <base-button
      variant="primary"
      size="medium"
      rounded="rounded-2xl"
      additional-classes="mt-6"
      @click="onClickViewOtherNfts"
    >
      {{ t('onboarding.nft.viewMyWallet') }}
    </base-button>
  </div>
</template>
