import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFrameStore = defineStore('frame', () => {
  const savedUrl = ref<string[]>([]);

  return {
    savedUrl,
  };
});
