export default {
  methods: {
    mixin_autoResize_resize(event) {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
    mixin_autoResize_reset(event) {
      event.target.style.height = '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.autoResize) {
        this.$refs.autoResize.style.height = 'auto';
        this.$refs.autoResize.style.height = `${this.$refs.autoResize.scrollHeight}px`;
      }
    });
  },
};
