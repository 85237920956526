import { dscvrApi } from '@/shared/api';
import { useCreateSignedQuery } from '../api/use-create-signed-query.query';
import { FRAMES_CLIENT_PROTOCOL } from '@/common';

export const signFramePostRequest = async ({
  postUrl,
  actionType,
  actionPayload,
  isDebug,
  blowfishRequestId,
}: {
  postUrl: string;
  actionType: dscvrApi.frame.FramePostRequestActionType;
  actionPayload: dscvrApi.frame.DscvrUntrustedData;
  isDebug?: boolean;
  blowfishRequestId?: string;
}): Promise<dscvrApi.frame.FramePostRequest> => {
  const signedQuery = await useCreateSignedQuery(JSON.stringify(actionPayload));

  const encodedTrustedMessageBytes =
    Buffer.from(signedQuery).toString('base64');

  return {
    postUrl,
    actionType,
    timeoutSecs: isDebug ? 60 : 5,
    blowfishRequestId,
    body: {
      clientProtocol: FRAMES_CLIENT_PROTOCOL,
      untrustedData: actionPayload,
      trustedData: {
        messageBytes: encodedTrustedMessageBytes,
      },
    },
  };
};
