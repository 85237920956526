<script setup lang="ts">
  import { onMounted, toRef } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useEmailUpdate } from '../../model/composables/use-email-update';
  import { usePersistentStore } from '@/store';
  import { TextFormField } from '@/shared/ui/form-fields';

  import type { SubmissionHandler } from 'vee-validate';
  import type { UserSelfView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    user: UserSelfView;
  }>();
  const emit = defineEmits<{
    (e: 'refresh:user'): void;
  }>();

  const currentUser = toRef(props, 'user');
  const {
    emailAddress,
    emailVerified,
    emailDirty,
    handleSubmit,
    verifyEmailAddress,
    setEmailAddress,
    resetForm,
    onInvalidSubmit,
    getEmailAddressVerificationPendingStatus,
  } = useEmailUpdate(currentUser);

  const persistentStore = usePersistentStore();
  const { verificationEmail } = storeToRefs(persistentStore);

  const onValidSubmit: SubmissionHandler<{ email: string }> = async (
    values,
  ) => {
    if (!emailVerified.value && !emailDirty.value) {
      await verifyEmailAddress(values.email);
    } else {
      await setEmailAddress(values.email, { toast: true });
    }
    emit('refresh:user');
    resetForm({ values: { email: values.email } });
  };
  const onSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);

  onMounted(async () => {
    verificationEmail.value.resend = false;
    await getEmailAddressVerificationPendingStatus();
  });
</script>

<template>
  <div class="w-full">
    <form
      class="flex flex-col lg:flex-row gap-2 w-full"
      @submit.prevent="onSubmit"
    >
      <text-form-field
        type="email"
        name="email"
        :model-value="emailAddress"
        id="username"
        inner-classes="!py-2.5"
      />
      <div class="flex flex-col lg:items-center lg:justify-center">
        <template v-if="!emailVerified && !emailDirty">
          <!-- Verify button -->
          <base-button
            v-if="!verificationEmail.sent && !verificationEmail.resend"
            variant="destructive"
            size="small"
            class="w-24 rounded-2xl"
          >
            {{ $t('verification.email.buttons.verify') }}
          </base-button>
          <!-- Sent indicator button -->
          <base-button
            v-if="verificationEmail.sent && !verificationEmail.resend"
            variant="secondary"
            size="small"
            class="w-24 rounded-2xl"
            @click.stop.prevent
          >
            {{ $t('verification.email.buttons.sent') }}
          </base-button>
          <!-- Resend button -->
          <base-button
            v-if="verificationEmail.resend"
            variant="secondary"
            size="small"
            class="w-24 rounded-2xl"
          >
            {{ $t('verification.email.buttons.resend') }}
          </base-button>
        </template>
        <template v-if="emailVerified && !emailDirty">
          <span class="inline-flex gap-1.5 items-center">
            <base-icon
              name="filled-check-circle"
              size="w-5 h-5"
              class="text-green-500"
            />
            <span class="text-sm leading-4.5 font-[500]">
              {{ $t('verification.email.buttons.verified') }}
            </span>
          </span>
        </template>
        <template v-if="emailDirty">
          <!-- Update button -->
          <base-button
            variant="secondary"
            size="small"
            class="w-24 rounded-2xl"
          >
            {{ $t('verification.email.buttons.update') }}
          </base-button>
        </template>
      </div>
    </form>
    <span
      v-if="verificationEmail.resend"
      class="inline-flex text-xs font-[500] leading-4 py-2 px-0.5 text-gray-400"
    >
      {{ $t('verification.email.resendExcerpt') }}
    </span>
  </div>
</template>
