import { assign, unescape } from 'lodash-es';
import type { dscvrApi } from '@/shared/api';
import type {
  Frame,
  FrameButton,
  FrameButtonActionType,
  FrameMetaTagPropertyType,
  FrameMetaTags,
} from '../types';
import { hasRequiredMetaTags } from './has-required-meta-tags';

export const validateAndParse = (
  url: string,
  appInfo: dscvrApi.proxy.FrameInfo,
  initialFrame: boolean = true,
): Frame | undefined => {
  // the BE endpoint normalize all frames metaTags to the open-frame standard
  const metaNamePrefix = 'of:';

  const metaTags: FrameMetaTags = {
    // TODO: use original source headers (BE dependency)
    source: JSON.stringify(appInfo.tags, null, 2),
    list: Object.entries(appInfo.tags).map(([name, content]) => ({
      name: name as FrameMetaTagPropertyType,
      content,
    })),
  };

  if (!hasRequiredMetaTags(metaTags.list)) {
    return;
  }

  const postUrl =
    unescape(
      metaTags.list.find(({ name }) => name === `${metaNamePrefix}post_url`)
        ?.content,
    ) || '';
  const imageUrl =
    metaTags.list.find(({ name }) => name === `${metaNamePrefix}image`)
      ?.content || '';
  const inputTextContent = metaTags.list.find(
    ({ name }) => name === `${metaNamePrefix}input:text`,
  )?.content;
  const state = initialFrame
    ? ''
    : metaTags.list.find(({ name }) => name === `${metaNamePrefix}state`)
        ?.content || '';
  const buttons = metaTags.list.filter(({ name }) =>
    name.startsWith(`${metaNamePrefix}button:`),
  );

  const frameButtonsMap: Map<number, Partial<FrameButton>> = new Map();

  buttons.forEach(({ name, content }) => {
    const idMatch = name?.match(new RegExp(`${metaNamePrefix}button:(\\d+)`));
    if (idMatch) {
      const id = parseInt(idMatch[1], 10);
      let frameButton = frameButtonsMap.get(id) || { index: id };

      if (name === `${metaNamePrefix}button:${id}`) {
        assign(frameButton, { label: content });
      } else if (name === `${metaNamePrefix}button:${id}:action`) {
        assign(frameButton, {
          action: content as FrameButtonActionType,
        });
      } else if (name === `${metaNamePrefix}button:${id}:target`) {
        assign(frameButton, { target: content });
      } else if (name === `${metaNamePrefix}button:${id}:post_url`) {
        assign(frameButton, { post_url: unescape(content) });
      } else if (name === `${metaNamePrefix}button:${id}:icon_name`) {
        assign(frameButton, {
          icon: {
            name: content,
          },
        });
      } else if (name === `${metaNamePrefix}button:${id}:icon_position`) {
        assign(frameButton, {
          icon: {
            position: content,
          },
        });
      }
      frameButtonsMap.set(id, frameButton as FrameButton);
    }
  });

  const frameButtons = Array.from(frameButtonsMap.values()) as FrameButton[];

  return {
    url,
    metaTags,
    postUrl,
    imageUrl,
    source: 'dscvr.one',
    ...(inputTextContent && {
      inputText: {
        placeholder: inputTextContent || '',
        value: '',
      },
    }),
    buttons: frameButtons,
    state,
  };
};
