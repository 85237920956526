<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { USER_SETTINGS_INTEGRATIONS } from '@/common';
  import {
    useOauth2QueryParams,
    useOauth2State,
    useToast,
  } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { useLinkTwitterMutation } from '@/entities/user-setting';
  import { trackEvent } from '@/utils/tracker';

  const { getLocalOauth2State } = useOauth2State();
  const { t } = useI18n({ useScope: 'global' });
  const router = useRouter();
  const { currentUser } = useUser();
  const { showToast } = useToast();
  const { mutateAsync: mutateLinkTwitter } = useLinkTwitterMutation();

  const TWITTER_ERROR_MESSAGE = t('twitter.oauthError');

  onMounted(async () => {
    const { code, state, error } = useOauth2QueryParams();
    if (error.value) {
      trackEvent('user_settings_action', 'social_link_error', 'twitter');
      showToast({
        title: TWITTER_ERROR_MESSAGE,
        type: 'error',
      });
    }
    if (code.value && state.value) {
      if (state.value !== getLocalOauth2State()) {
        trackEvent('user_settings_action', 'social_link_error', 'twitter');
        showToast({
          title: TWITTER_ERROR_MESSAGE,
          type: 'error',
        });
      } else {
        // Link twitter
        await mutateLinkTwitter(code.value as string, {
          onError: (error) => {
            trackEvent('user_settings_action', 'social_link_error', 'twitter');
            showToast({
              title: TWITTER_ERROR_MESSAGE,
              type: 'error',
            });
          },
          onSuccess: () => {
            trackEvent(
              'user_settings_action',
              'social_link_success',
              'twitter',
            );
            showToast({
              title: t('twitter.oauthSuccess'),
              type: 'success',
            });
          },
        });
      }
      // redirect back to settings page
    }
    router.push({
      name: USER_SETTINGS_INTEGRATIONS,
      params: { username: currentUser.value.username },
    });
  });
</script>

<template>
  <div class="flex items-center justify-center h-screen p-4 text-2xl font-bold">
    <div>{{ $t('redirecting') }}</div>
  </div>
</template>
