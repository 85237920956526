<template>
  <transition name="fade">
    <div
      v-show="Boolean(showing)"
      class="loading-overlay bg-gray-950 bg-opacity-50 h-screen w-full fixed inset-0 z-1001 flex items-center justify-center"
    >
      <div class="p-8 relative">
        <!-- <img class="max-w-64" src="@/assets/img/loading.gif" alt="Loading..."> -->

        <div class="dot-falling max-w-64"></div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'loading-overlay',
    props: {
      // eslint-disable-next-line vue/require-default-prop
      showing: [Number, Boolean],
    },
  };
</script>
