<template>
  <div v-click-outside="collapse" class="relative min-w-48 flex-initial">
    <div
      class="bg-gray-900 flex items-center px-4 py-2.5 rounded-md"
      @click="expanded = !expanded"
    >
      <div class="flex-grow font-medium text-sm">
        {{ resultRoles }}
      </div>
      <base-icon name="arrow-down" size="w-3 h-3" />
    </div>
    <div
      class="absolute left-0 mt-2 p-4 rounded-xl bg-gray-785 z-10 w-full flex flex-col gap-y-4 max-h-64 overflow-y-auto thin-scrollbar"
      :class="{ hidden: !expanded }"
    >
      <label
        v-for="role in roles"
        :key="role.id"
        class="flex items-center gap-4"
      >
        <input
          type="checkbox"
          class="w-6 h-6 focus:ring-0 focus:ring-offset-0 rounded-md text-pink-620 bg-gray-500"
          :checked="isSelected(role.id)"
          @click="roleChange(role.id)"
        />
        {{ role.name }}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'roles-dropdown',
    props: {
      roles: {
        type: Array,
        required: true,
      },
      selectedRoles: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['roleClick'],
    data: function () {
      return { expanded: false };
    },
    computed: {
      resultRoles() {
        if (
          this.selectedRoles.length === 0 ||
          this.selectedRoles.length === this.roles.length
        ) {
          return 'All Roles';
        }
        return this.roles
          .filter((role) => this.isSelected(role.id))
          .map((role) => role.name)
          .join(', ');
      },
    },
    methods: {
      collapse() {
        this.expanded = false;
      },
      roleChange: function (id) {
        let resultRoles = [];
        if (this.isSelected(id)) {
          resultRoles = this.selectedRoles.filter((role) => role.id !== id);
        } else {
          resultRoles = [
            ...this.selectedRoles,
            this.roles.find((role) => role.id === id),
          ];
        }
        this.$emit('roleClick', resultRoles);
      },
      isSelected: function (id) {
        return !!this.selectedRoles.find(
          (selectedRole) => selectedRole.id === id,
        );
      },
    },
  };
</script>
