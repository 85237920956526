<script setup lang="ts">
  import { EXPLORE_PORTALS, EXPLORE_PORTALS_FEATURED, SEARCH } from '@/common';
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  defineProps<{
    small?: boolean;
  }>();

  defineEmits<{
    (e: 'search', query: string): void;
  }>();

  const route = useRoute();
  const router = useRouter();
  const inputRef = ref<HTMLInputElement>();

  const submit = () => {
    router.push({
      name: SEARCH,
      query: {
        q: inputRef.value?.value || '',
        tab:
          route.name === EXPLORE_PORTALS ||
          route.name === EXPLORE_PORTALS_FEATURED
            ? 'Portals'
            : 'Users',
      },
    });
  };
</script>

<template>
  <form
    class="flex items-center gap-1 bg-black bg-opacity-50 rounded-full text-gray-400"
    :class="
      small
        ? 'w-75 p-2.5'
        : 'min-w-80 sm:min-w-120 lg:min-w-160 px-4 py-2.5 border border-gray-785 border-opacity-50'
    "
    @submit.prevent="submit"
  >
    <base-icon name="search" :size="small ? 'w-5 h-5' : 'w-7 h-7'" />
    <input
      ref="inputRef"
      class="flex-1 bg-transparent border-none outline-none text-white focus:outline-none focus:border-none placeholder-gray-400 hover:placeholder-opacity-50 focus:placeholder-transparent"
      :class="small ? 'text-xs' : 'text-sm sm:text-base'"
      :placeholder="
        small
          ? $t('exploreSearchPlaceholderSmall')
          : $t('exploreSearchPlaceholderLarge')
      "
    />
  </form>
</template>
