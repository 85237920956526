import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { TippableTokenListItem } from '../../tip';

export const useTipStore = defineStore('tip', () => {
  const visibleContentIds = ref<Set<bigint>>(new Set());

  const addVisibleContentId = (id: bigint) => {
    visibleContentIds.value?.add(id);
  };

  const removeVisibleContentId = (id: bigint) => {
    visibleContentIds.value?.delete(id);
  };

  return {
    visibleContentIds,
    addVisibleContentId,
    removeVisibleContentId,
  };
});
