import { computed, type Ref } from 'vue';
import { useElementBounding, useMouseInElement, useScroll } from '@vueuse/core';
import { useTouchFocus } from '@/composables';
import { useBreakpoint } from '@/shared/model';

// on mobile scrollLeft usually has decimals values, so there a threshold of 1 pixel
const SNAP_THRESHOLD_PX = 1;
const ARROW_THRESHOLD_PX = 80;

/**
 *
 * @param slide
 * @param scrollableArea
 * @param itemsContainer
 * @param count
 */
export function useHorizontalSlide(
  slide: Ref<HTMLElement | null>,
  scrollableArea: Ref<HTMLElement | null>,
  itemsContainer: Ref<HTMLElement | null>,
  count: Ref<number>,
) {
  const { isSmallerThanMd } = useBreakpoint();
  const { x: scrollLeft, arrivedState } = useScroll(scrollableArea);
  const { elementX: mouseX, isOutside } = useMouseInElement(scrollableArea);
  const { width: scrollableAreaWidth } = useElementBounding(scrollableArea);
  const firstElement = computed(
    () => <HTMLElement>itemsContainer.value?.firstElementChild,
  );
  const { width: itemRawWidth } = useElementBounding(firstElement);
  const { touchFocus } = useTouchFocus(slide);

  const gap = computed(() => {
    let gap = 16; // gap-4 = 16px
    if (!isSmallerThanMd.value) {
      gap = 24; // gap-6 = 24px
    }
    return gap;
  });

  const itemWidth = computed(() => {
    return itemRawWidth.value + gap.value;
  });

  const scrollWidth = computed(() => {
    return itemWidth.value * count.value - gap.value;
  });

  const scrollable = computed(() => {
    if (scrollableArea.value) {
      return scrollWidth.value > scrollableAreaWidth.value;
    }
    return false;
  });

  const currentIndex = computed(() => {
    return scrollLeft.value / itemWidth.value + 1;
  });

  const displayIndex = computed(() => {
    const itemIndex = Math.round(currentIndex.value);
    return Math.min(Math.max(itemIndex, 1), count.value);
  });

  const sidePadding = computed(() => {
    return (scrollableAreaWidth.value - itemRawWidth.value) / 2;
  });

  const scrollReachedLeftEnd = computed(() => arrivedState.left);
  const scrollReachedRightEnd = computed(() => arrivedState.right);
  const isHoovered = computed(() => !isOutside.value);

  const showLeftArrow = computed(() => {
    if (!scrollable.value) {
      return false;
    }
    if (scrollReachedLeftEnd.value) {
      return false;
    }
    if (touchFocus.value) {
      return true;
    }

    return isHoovered.value && mouseX.value < ARROW_THRESHOLD_PX;
  });

  const showRightArrow = computed(() => {
    if (!scrollable.value) {
      return false;
    }
    if (scrollReachedRightEnd.value) {
      return false;
    }
    if (touchFocus.value) {
      return true;
    }

    return (
      isHoovered.value &&
      mouseX.value > scrollableAreaWidth.value - ARROW_THRESHOLD_PX
    );
  });

  const moveLeft = () => {
    const snapIndex = Math.floor(currentIndex.value);
    if (snapIndex > 1) {
      const delta = itemWidth.value * Math.abs(currentIndex.value - snapIndex);
      if (delta >= SNAP_THRESHOLD_PX) {
        scrollLeft.value = itemWidth.value * (snapIndex - 1);
      } else {
        scrollLeft.value = itemWidth.value * (snapIndex - 2);
      }
    } else {
      scrollLeft.value = 0;
    }
  };

  const moveRight = () => {
    const snapIndex = Math.ceil(currentIndex.value);
    if (snapIndex <= count.value) {
      const delta = itemWidth.value * Math.abs(snapIndex - currentIndex.value);
      if (delta >= SNAP_THRESHOLD_PX) {
        scrollLeft.value = itemWidth.value * (snapIndex - 1);
      } else {
        scrollLeft.value = itemWidth.value * snapIndex;
      }
    }
  };

  return {
    touchFocus,
    scrollable,
    displayIndex,
    showLeftArrow,
    showRightArrow,
    sidePadding,
    moveLeft,
    moveRight,
  };
}
