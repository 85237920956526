<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import { DynamicContent } from '@/components/dynamic-content';
  import { useHtmlClamp } from '@/composables';
  import type { PortalInfoView } from 'dfx/edge/edge.did';
  import { useI18n } from 'vue-i18n';
  import { convertH1Tags } from '@/shared/lib';

  const props = withDefaults(
    defineProps<{
      body: string;
      portalInfoView: PortalInfoView;
      maxCharacters?: number;
      maxLineBreaks?: number;
    }>(),
    {
      maxCharacters: 200,
      maxLineBreaks: 3,
    },
  );

  defineEmits<{
    (e: 'read-more'): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const { body, maxCharacters, maxLineBreaks } = toRefs(props);
  const readMore = `... <a class="read-more" v-on:click="emits('read-more')">${t(
    'readMore',
  )}</a>`;
  const options = computed(() => ({
    maxLineBreaks: maxLineBreaks.value,
    maxCharacters: maxCharacters.value,
    overflowMarker: readMore,
  }));
  const { clampedHtml } = useHtmlClamp(body, options);
</script>

<template>
  <div class="flex flex-col gap-2">
    <div ref="el">
      <dynamic-content
        in-feed
        :body="convertH1Tags(clampedHtml)"
        :portal-info-view="portalInfoView"
        @read-more="$emit('read-more')"
      />
    </div>
  </div>
</template>

<style lang="postcss">
  a.read-more {
    @apply cursor-pointer text-indigo-500 underline inline-flex items-center gap-1 text-base not-italic;
  }
</style>
