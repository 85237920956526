import { computed, type ComputedRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import type {
  FilterDropDownOptions,
  DateFilterDropDownOptions,
} from '../types';
import { trackEvent } from '@/utils/tracker';
import { compact } from 'lodash-es';
import { DATE_RANGE, HOME, PORTALS, ALL, FOLLOW } from '@/common';
import { useUser } from '@/entities/user';
import type { Sort } from 'dfx/edge/edge.did';

export const useFeedFilter = () => {
  const router = useRouter();
  const route = useRoute();

  const hideHotFilter = computed(() => route.name === FOLLOW);
  const { isLoggedIn } = useUser();

  const feedFilters = computed<FilterDropDownOptions[]>(
    () =>
      [
        {
          title: isLoggedIn.value ? 'For You' : 'Popular',
          icon: 'sparkle',
          subtitle: isLoggedIn.value
            ? 'Personalized based on your interest'
            : 'Hot and trending on DSCVR',
          route: HOME,
        },
        isLoggedIn.value && {
          title: 'Following',
          icon: 'follows',
          subtitle: 'Only includes people you follow',
          route: FOLLOW,
        },
        isLoggedIn.value && {
          title: 'Portals',
          icon: 'portal',
          subtitle: 'Show what’s happening in your portals first',
          route: PORTALS,
        },
        {
          title: 'All DSCVR',
          icon: 'dscvr-solid',
          subtitle: 'Everything that’s happening on the network',
          route: ALL,
        },
      ].filter(Boolean) as FilterDropDownOptions[],
  );

  const selectedFeedFilter = computed<FilterDropDownOptions>(
    () => feedFilters.value.filter((filter) => filter.route === route.name)[0],
  );

  const dateFilters: DateFilterDropDownOptions[] = [
    {
      title: 'Last Hour',
      value: DATE_RANGE.HOUR,
    },
    {
      title: 'Today',
      value: DATE_RANGE.DAY,
    },
    {
      title: 'This Week',
      value: DATE_RANGE.WEEK,
    },
    {
      title: 'This Month',
      value: DATE_RANGE.MONTH,
    },
    {
      title: 'This Year',
      value: DATE_RANGE.YEAR,
    },
    {
      title: 'All Time',
      value: DATE_RANGE.ALL_TIME,
    },
  ];

  const sortFilters = computed<FilterDropDownOptions[]>(() =>
    compact([
      !hideHotFilter.value && {
        title: 'hot',
        icon: 'fire',
      },
      {
        title: 'new',
        icon: 'clock-filled',
      },
      {
        title: 'top',
        icon: 'bar-graph',
      },
    ]),
  );

  const selectedSortFilter = computed(() => {
    const defaultFilter =
      hideHotFilter.value && route.query.sort !== 'top'
        ? 'new'
        : (route.query.sort as string) ?? 'hot';
    return sortFilters.value.filter(
      (filter) => filter.title === defaultFilter,
    )[0];
  });

  const selectedDateFilter = computed<DateFilterDropDownOptions>(() => {
    let filterType = selectedSortFilter.value.title;
    if (filterType == 'top') {
      return (
        dateFilters.filter(
          (filter) => filter.value === route.query?.sortDateRange,
        )[0] ?? dateFilters[1]
      );
    } else {
      return dateFilters[dateFilters.length - 1];
    }
  });

  const showSortRefinements: ComputedRef<boolean> = computed(() => {
    return route.name !== HOME;
  });

  const showTimeRefinements: ComputedRef<boolean> = computed(() => {
    return route.query?.sort === 'top';
  });

  const sortFilterQueryParameter = computed<Sort>(() => {
    if (selectedSortFilter.value.title === 'top') {
      return { Top: null };
    } else if (selectedSortFilter.value.title === 'new') {
      return { New: null };
    }
    return { Hot: null };
  });

  const dateFilterQueryParameter = computed(() => {
    return selectedDateFilter.value.value;
  });

  const onUpdateFeedFilter = (filter: FilterDropDownOptions) => {
    router.push({
      name: filter.route,
    });
    trackEvent('feed_type_sort', 'feed_sort', filter.title);
  };

  const onUpdateSortFilter = (filter: FilterDropDownOptions) => {
    router.push({
      query: { sort: filter.title },
    });
    trackEvent('feed_filter_sort', 'feed_sort', filter.title);
  };

  const onUpdateDateFilter = (filter: DateFilterDropDownOptions) => {
    router.push({
      query: {
        sort: selectedSortFilter.value.title,
        sortDateRange: filter.value,
      },
    });
    trackEvent('feed_range_sort', 'feed_sort', filter.title);
  };
  return {
    dateFilters,
    dateFilterQueryParameter,
    feedFilters,
    onUpdateDateFilter,
    onUpdateFeedFilter,
    onUpdateSortFilter,
    selectedDateFilter,
    selectedFeedFilter,
    selectedSortFilter,
    showSortRefinements,
    showTimeRefinements,
    sortFilters,
    sortFilterQueryParameter,
  };
};
