import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { GlobalLiveData } from 'dfx/edge/edge.did';

export const useGlobalStore = defineStore('global', () => {
  const globalLiveData = ref<GlobalLiveData>();

  return {
    globalLiveData,
  };
});
