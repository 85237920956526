import gatedService from '../../../../services/gated';
import { Token } from './token';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';
import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

// number to little endian byte array with 32 byte padding

const ICRC_TIP_BOT =
  'ygbxo-p3yib-tmksa-hy6vb-mzna6-5twjn-4poz6-ptbwb-bzqzs-dw722-2ae';

/**
 *
 * @param x
 */
function getInt64Bytes(x) {
  const bytes = Buffer.alloc(8);
  bytes.writeBigInt64LE(x);
  return bytes;
}

export const idlFactory = ({ IDL }) => {
  const Value = IDL.Variant({
    Int: IDL.Int,
    Nat: IDL.Nat,
    Blob: IDL.Vec(IDL.Nat8),
    Text: IDL.Text,
  });
  const Subaccount = IDL.Vec(IDL.Nat8);
  const Account = IDL.Record({
    owner: IDL.Principal,
    subaccount: IDL.Opt(Subaccount),
  });
  const Tokens = IDL.Nat;
  const Timestamp = IDL.Nat64;
  const TransferArg = IDL.Record({
    to: Account,
    fee: IDL.Opt(Tokens),
    memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
    from_subaccount: IDL.Opt(Subaccount),
    created_at_time: IDL.Opt(Timestamp),
    amount: Tokens,
  });
  const BlockIndex = IDL.Nat;
  const TransferError = IDL.Variant({
    GenericError: IDL.Record({
      message: IDL.Text,
      error_code: IDL.Nat,
    }),
    TemporarilyUnavailable: IDL.Null,
    BadBurn: IDL.Record({ min_burn_amount: Tokens }),
    Duplicate: IDL.Record({ duplicate_of: BlockIndex }),
    BadFee: IDL.Record({ expected_fee: Tokens }),
    CreatedInFuture: IDL.Record({ ledger_time: IDL.Nat64 }),
    TooOld: IDL.Null,
    InsufficientFunds: IDL.Record({ balance: Tokens }),
  });
  const TransferResult = IDL.Variant({
    Ok: BlockIndex,
    Err: TransferError,
  });
  return IDL.Service({
    icrc1_balance_of: IDL.Func([Account], [Tokens], ['query']),
    icrc1_decimals: IDL.Func([], [IDL.Nat8], ['query']),
    icrc1_fee: IDL.Func([], [Tokens], ['query']),
    icrc1_metadata: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, Value))],
      ['query'],
    ),
    icrc1_minting_account: IDL.Func([], [IDL.Opt(Account)], ['query']),
    icrc1_name: IDL.Func([], [IDL.Text], ['query']),
    icrc1_supported_standards: IDL.Func(
      [],
      [IDL.Vec(IDL.Record({ url: IDL.Text, name: IDL.Text }))],
      ['query'],
    ),
    icrc1_symbol: IDL.Func([], [IDL.Text], ['query']),
    icrc1_total_supply: IDL.Func([], [Tokens], ['query']),
    icrc1_transfer: IDL.Func([TransferArg], [TransferResult], []),
  });
};

export class ICRC1 {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  isValidPrincipal(val) {
    try {
      Principal.fromText(val);
      return true;
    } catch (e) {
      return false;
    }
  }

  async tip(to, amount, contentId) {
    amount = Math.floor(amount);

    await gatedService.verifyUser();
    const results = await gatedService.tip({
      to: to,
      from: this.identity.getPrincipal().toText(),
      amount: amount,
      contentId: contentId.toString(),
      tokenType: this.canisterId,
    });

    const memo = Array.from(getInt64Bytes(BigInt(results.data)));

    const transferResults = await this.actor.icrc1_transfer({
      to: {
        owner: Principal.fromText(ICRC_TIP_BOT),
        subaccount: [],
      },
      fee: [],
      memo: [memo],
      from_subaccount: [],
      created_at_time: [],
      amount: amount,
    });

    return transferResults;
  }

  _getMetaData(data, fieldName) {
    const result = data.find((item) => item[0] === fieldName);
    if (result) {
      return result[1];
    }
    return null;
  }

  async getMetadata() {
    if (!this.actor) {
      //await this.create();
    }
    const data = await this.actor.icrc1_metadata();

    let logo = this._getMetaData(data, 'icrc1:logo');

    if (!logo?.Text) {
      if (this.canisterId === '2ouva-viaaa-aaaaq-aaamq-cai') {
        logo = {
          Text: fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/chat.png`),
        };
      } else if (this.canisterId === 'zfcdd-tqaaa-aaaaq-aaaga-cai') {
        logo = {
          Text: fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/sns.png`),
        };
      } else if (this.canisterId === '73mez-iiaaa-aaaaq-aaasq-cai') {
        logo = {
          Text: fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/kinic-dao.png`,
          ),
        };
      } else if (this.canisterId == '4c4fd-caaaa-aaaaq-aaa3a-cai') {
        logo = {
          Text: fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/ghost.png`),
        };
      }
    }

    //3e3x2-xyaaa-aaaaq-aaalq-cai

    return {
      cid: this.canisterId,
      fee: this._getMetaData(data, 'icrc1:fee').Nat,
      name: this._getMetaData(data, 'icrc1:name').Text,
      symbol: this._getMetaData(data, 'icrc1:symbol').Text,
      standard: 'icrc1',
      decimals: this._getMetaData(data, 'icrc1:decimals').Nat,
      icpTicker: '',
      icon:
        logo?.Text ??
        fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/sns.png`),
    };
  }

  async transfer(to, amount) {
    if (!this.isValidPrincipal(to)) {
      return {
        error: 'Invalid wallet principal',
      };
    }

    return await this.actor.icrc1_transfer({
      to: {
        owner: Principal.fromText(to),
        subaccount: [],
      },
      fee: [],
      memo: [],
      from_subaccount: [],
      created_at_time: [],
      amount: amount,
    });
  }

  async balance(principal) {
    const tokens = await this.actor.icrc1_balance_of({
      owner: principal,
      subaccount: [],
    });
    return new Token({
      balance: tokens,
    });
  }
}
