<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { LiveChatMessage, LiveChatMessageItem } from '@/entities/live';
  import { toRef } from '@vueuse/core';

  interface GeneralMessagesProps {
    messages: LiveChatMessage[];
    classes?: string;
  }

  const props = withDefaults(defineProps<GeneralMessagesProps>(), {
    classes: '',
  });

  const messages = toRef(props, 'messages');
  const messageContainerRef = ref<HTMLElement | null>(null);
  const bottomOfChatRef = ref<HTMLElement | null>(null);
  const messageCount = computed(() => messages.value.length);

  /**
   *
   */
  function scrollToBottom() {
    setTimeout(() => {
      if (bottomOfChatRef.value === null) return;
      bottomOfChatRef.value.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  watch(
    () => messageCount.value,
    () => {
      scrollToBottom();
    },
  );
</script>

<template>
  <div
    ref="messageContainerRef"
    class="grow scrollbar-hide overflow-auto max-h-120 mb-10"
    :class="classes.split(' ')"
  >
    <live-chat-message-item
      v-for="message in messages"
      :key="message.formattedTime"
      class="py-2"
      :message="message"
    />
    <div ref="bottomOfChatRef"></div>
  </div>
</template>

<style scoped>
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
