<template>
  <div
    class="relative flex flex-col justify-center items-center gap-6 p-5 cursor-pointer rounded-xl"
  >
    <div
      class="absolute top-0 left-0 size-full overflow-hidden before:absolute before:content-[''] before:brightness-50 before:size-full rounded-xl before:backdrop-blur-[2px]"
    >
      <slot name="cover" />
    </div>
    <div class="relative flex items-center gap-2 w-full">
      <div class="flex-none rounded-xl overflow-hidden">
        <slot name="avatar" />
      </div>
      <div class="flex flex-col gap-2">
        <div
          class="font-semibold tracking-[0.18px] w-34 overflow-hidden truncate"
        >
          <slot name="name" />
        </div>
        <div class="flex flex-none gap-2 justify-center items-center w-full">
          <base-icon name="outlined-users2" size="size-4" />
          <span class="flex gap-1 font-medium tracking-[0.14px] lowercase">
            <slot name="followers" />
          </span>
        </div>
      </div>
    </div>
    <div
      class="relative flex flex-col w-full h-10 text-sm text-white leading-5 tracking-[0.14px] text-ellipsis overflow-hidden"
    >
      <slot name="bio" />
    </div>
  </div>
</template>
