<script setup lang="ts">
  import { Tabs } from '@/shared/ui/tabs';
  import { usePreferenceSettings } from '@/entities/user-setting';
  import {
    ChainsPreference,
    GeneralPreference,
  } from '@/features/user-settings';
  import type { UserSelfView } from 'dfx/edge/edge.did';

  defineProps<{
    user: UserSelfView;
    username: UserSelfView['username'];
  }>();

  const { tabItems, activeTabItem, setActiveTab } = usePreferenceSettings();
</script>

<template>
  <section class="max-w-[638px]">
    <h1 class="font-bold leading-7 text-2xl">Preferences</h1>
    <section class="w-full h-full">
      <tabs
        type="underlined"
        direction="horizontal"
        class="w-full"
        tabs-classes="w-full mt-8"
        off-color="text-white hover:text-gray-300 p-0 pb-2"
        on-color="border-b-2 p-0 pb-2"
      >
        <template #tabs="{ onColor, offColor }">
          <ul class="flex w-full border-transparent space-x-4">
            <li
              v-for="tab in tabItems"
              :key="tab.id"
              variant="custom"
              :class="[tab.title === activeTabItem.title ? onColor : offColor]"
              class="cursor-pointer"
              @click.stop="setActiveTab(tab)"
            >
              <span>{{ tab.title }}</span>
            </li>
          </ul>
        </template>
        <template #content>
          <section ref="content" class="flex-1 h-full mt-8">
            <template v-if="activeTabItem.title === 'General'">
              <general-preference :username="username" />
            </template>
            <template v-if="activeTabItem.title === 'Chains'">
              <chains-preference />
            </template>
          </section>
        </template>
      </tabs>
    </section>
  </section>
</template>
