import { inject, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { ContentView } from 'dfx/edge/edge.did';
import { dscvrApi } from '@/shared/api';
import { useConfirmationDialog } from '@/shared/ui/confirmation';
import { useToast } from '@/shared/model';
import { useUser } from '@/entities/user';
import { useGetFrameQuery } from '../../api/use-get-frame.query';
import { useFramePostMutation } from '../../api/use-frame-post.mutation';
import type { Frame, FrameButton, FrameActionPayload } from '../../types';
import { createFrameFromHtml } from '../../lib/create-frame-from-html';
import { createHtmlDocument } from '../../lib/create-html-document';
import { signFramePostRequest } from '../../lib/sign-frame-post-request';
import { useFrameTransaction } from './use-frame-transaction';
import { useAuth } from '@/entities/auth';

export const useHandleFrameAction = ({
  url,
  currentFrame,
  isDebug,
  startFrameLoading,
  endFrameLoading,
  actionCallback,
}: {
  url: Ref<string>;
  currentFrame: Ref<Frame | undefined>;
  isDebug: Ref<boolean | undefined>;
  startFrameLoading: () => void;
  endFrameLoading: (frame?: Frame) => void;
  actionCallback: (payload: FrameActionPayload) => void;
}) => {
  const content = inject<Ref<ContentView | undefined> | undefined>(
    'content',
    undefined,
  );
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn, currentUserPrincipal } = useUser();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });
  const {
    reportCount,
    transactedCount,
    connectedWalletAddress,
    connectWallet,
    handleTransaction,
  } = useFrameTransaction({ url, currentFrame, content, isDebug });
  const { openConfirmationDialog, closeDialog } = useConfirmationDialog();
  const { refetch: getFrame } = useGetFrameQuery(url);

  const loadFirstFrame = async () => {
    const requestTimestamp = Date.now();

    try {
      startFrameLoading();
      const { data: introFrame } = await getFrame();
      if (!introFrame) {
        throw new Error(t('frame.frameNotFoundError'));
      }
      await endFrameLoading(introFrame);

      actionCallback({
        frame: introFrame,
        type: 'intro',
        requestTimestamp,
        responseTimestamp: Date.now(),
      });
    } catch (error) {
      await endFrameLoading();
      actionCallback({
        type: 'error',
        error: error as Error,
        requestTimestamp,
        responseTimestamp: Date.now(),
      });
    }
  };

  const handleImageClick = (url: string) => {
    if (isLoggedIn.value) {
      window.open(url, '_blank');
    } else {
      showLoginSignUpDialog();
    }
  };

  const redirectTo = (link: string, onSubmit: () => void) => {
    openConfirmationDialog({
      headerTitle: t('walletTransaction.redirectMessageTitle'),
      contentSlots: {
        default: {
          template: t('walletTransaction.redirectMessageDescription'),
        },
        cancelLabel: { template: t('cancel') },
        submitLabel: { template: t('continue') },
      },
      submit: () => {
        onSubmit();
        window.open(link, '_link');
        closeDialog();
      },
    });
    return;
  };

  const handleButtonClick = async (button: FrameButton, inputText?: string) => {
    if (!currentFrame.value) {
      throw new Error('Frame not found');
    }
    if (!button.action) {
      throw new Error('Invalid button action');
    }
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    }
    const requestTimestamp = Date.now();
    let request: dscvrApi.frame.FramePostRequest | undefined = undefined;

    const registerAction = async (
      payload: Partial<FrameActionPayload> = {},
    ) => {
      actionCallback({
        frame: currentFrame.value,
        type: button.action!,
        request,
        requestTimestamp:
          request?.body.untrustedData.timestamp ?? requestTimestamp,
        responseTimestamp: Date.now(),
        ...payload,
      });
    };

    if (button.action === 'link') {
      redirectTo(button.target!, () => {
        registerAction({
          redirectUrl: button.target!,
        });
      });
      return;
    }

    let walletAddress: string | undefined = undefined;
    if (button.action === 'tx') {
      const connectedWallet = await connectWallet(button);
      if (!connectedWallet) {
        return;
      }
      walletAddress = connectedWalletAddress.value;
    }

    try {
      startFrameLoading();

      // according to point 2 of https://docs.farcaster.xyz/reference/frames/spec#handling-clicks
      const postUrl =
        button.target || currentFrame.value.postUrl || currentFrame.value.url;

      request = await signFramePostRequest({
        postUrl,
        actionType: button.action,
        isDebug: isDebug.value,
        actionPayload: {
          url: currentFrame.value.url,
          contentId: content?.value?.id.toString(),
          state: currentFrame.value.state,
          dscvrId: currentUserPrincipal.value?.toText(),
          timestamp: Number(Date.now()),
          buttonIndex: button.index,
          inputText,
          address: walletAddress,
        },
      });

      const response = await useFramePostMutation(request);
      if (response.type === 'redirect') {
        await endFrameLoading();
        redirectTo(response.location, () => {
          registerAction({
            frame: undefined,
            redirectUrl: response.location,
          });
        });
        return;
      }

      if (response.type === 'tx') {
        await endFrameLoading();
        registerAction({
          frame: undefined,
          transactionResponse: response.transactionResponse,
        });

        const transactionResult = await handleTransaction(
          button,
          request,
          response.transactionResponse,
          startFrameLoading,
        );

        await endFrameLoading(transactionResult?.nextFrame);

        if (transactionResult?.nextFrame) {
          registerAction({
            request: transactionResult.request,
            frame: transactionResult.nextFrame,
            requestTimestamp:
              transactionResult.request.body.untrustedData.timestamp,
          });
        }
        return;
      }

      if (response.type === 'post') {
        const nextFrame = createFrameFromHtml(
          createHtmlDocument(response.html),
          currentFrame.value.url,
        );
        await endFrameLoading(nextFrame);
        registerAction({
          frame: nextFrame,
        });
        return;
      }

      throw new Error('Invalid frame response');
    } catch (error) {
      await endFrameLoading();
      registerAction({
        frame: undefined,
        type: 'error',
        error: error as Error,
      });

      showToast({
        title: t('error'),
        description: `${t('frame.errorPost')}`,
        type: 'error',
        durationSeconds: 3,
      });
    }
    return;
  };

  return {
    reportCount,
    transactedCount,
    loadFirstFrame,
    handleImageClick,
    handleButtonClick,
  };
};
