<script lang="ts" setup>
  import { onMounted, onUnmounted, useSlots, ref } from 'vue';

  defineProps<{
    countdown: string;
  }>();

  const emits = defineEmits<{
    (e: 'running'): void;
  }>();

  const slots = useSlots();

  const timer = ref<NodeJS.Timeout>();

  onMounted(() => {
    timer.value = setInterval(() => emits('running'), 1000);
  });

  onUnmounted(() => {
    clearInterval(timer.value);
  });
</script>

<template>
  <div class="flex justify-center items-center gap-1.5 font-normal text-lg">
    <slot v-if="slots.prefix" name="prefix" />
    <slot name="countdown" :countdown="countdown">
      <span
        class="font-semibold text-transparent bg-clip-text bg-gradient-to-t to-[#00ECBC] from-[#007ADF]"
      >
        {{ countdown }}
      </span>
    </slot>
    <slot v-if="slots.suffix" name="suffix" />
  </div>
</template>
