<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { useUserIntegrations } from '@/entities/user';
  import { PairButton } from '@/entities/user-setting';
  import PairPlatformModal from '@/components/PairPlatformModal.vue';

  const {
    unpair,
    getCurrentUserPlatformUsername,
    getCurrentUserPairedPlatforms,
  } = useUserIntegrations();

  const discordUsername = computed(() => {
    return getCurrentUserPlatformUsername('Discord');
  });

  const showModal = ref(false);
  const isLoading = ref(false);

  const refresh = async () => {
    isLoading.value = true;
    await getCurrentUserPairedPlatforms();
    isLoading.value = false;
  };

  const togglePair = async () => {
    if (discordUsername.value) {
      isLoading.value = true;
      await unpair('Discord');
      isLoading.value = false;
    } else {
      showModal.value = true;
    }
  };

  onMounted(() => {
    refresh();
  });
</script>

<template>
  <pair-platform-modal :is-open="showModal" @close-modal="showModal = false" />

  <pair-button
    :is-loading="isLoading"
    :connected-id="discordUsername"
    @toggle="togglePair"
  >
    <base-icon name="social-discord" size="size-10" class="inline" />
    <template #name>Discord</template>
    <template #connection>
      {{ $t('userSettings.username') }}: {{ discordUsername }}
    </template>
  </pair-button>
</template>
