import { useMutation } from '@tanstack/vue-query';
import type { CreateContent } from 'dfx/edge/edge.did';
import { dscvrIcApi } from '@/shared/api';

export const useCreateContentMutation = () => {
  return useMutation({
    mutationKey: ['create-content-mutation'],
    mutationFn: (params: CreateContent) =>
      dscvrIcApi.post.createContent(params),
  });
};
