import { useMediaStore, type PlaybackElement } from '@/shared/model';
import { storeToRefs } from 'pinia';

export const useRegisterPlayback = () => {
  const mediaStore = useMediaStore();
  const {
    setMediaPlayback: setMediaPlaybackStore,
    unsetMediaPlayback: unsetMediaPlaybackStore,
  } = useMediaStore();
  const { mediaPlaybackHash } = storeToRefs(mediaStore);

  const pauseEveryOtherVideo = (id: string) => {
    Array.from(mediaPlaybackHash.value.entries()).forEach(
      async ([playbackId, playbackElement]) => {
        if (playbackId !== id) {
          playbackElement.pause();
        }
      },
    );
  };

  const setMediaPlayback = (id: string, playback: PlaybackElement) => {
    setMediaPlaybackStore(id, playback);
  };

  const unsetMediaPlayback = (id: string) => {
    unsetMediaPlaybackStore(id);
  };

  return {
    pauseEveryOtherVideo,
    setMediaPlayback,
    unsetMediaPlayback,
    mediaPlaybackHash,
  };
};
