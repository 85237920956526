<script lang="ts" setup>
  import { ref, computed, watch, toRef, watchEffect } from 'vue';
  import MemberRow from './portalSettings/MemberRow.vue';
  import RolesDropdown from './portalSettings/RolesDropdown.vue';
  import { ROLE_KIND_FIELD_EVERYONE } from '@/common';
  import {
    usePortalDialog,
    useSetPortalMemberStatusMutation,
  } from '@/entities/portal';
  import {
    useGetPortalMembersQuery,
    useGetPortalRolesQuery,
  } from '@/entities/portal';
  import type {
    MemberListItemView,
    PortalMemberQuery,
    PortalView,
    RoleView,
  } from 'dfx/edge/edge.did';
  import { uniqBy } from 'lodash-es';
  import { refDebounced } from '@vueuse/core';
  import { dscvrIcApi } from '@/shared/api';
  import { useToast } from '@/shared/model';
  import { useVirtualizer } from '@tanstack/vue-virtual';

  const props = defineProps<{
    slug: string;
    portalView: PortalView;
  }>();

  const { openKickBanDialog, closeDialog } = usePortalDialog();
  const { showToast } = useToast();
  const { mutate: setPortalMembersStatusMutation } =
    useSetPortalMemberStatusMutation();
  const { data: roles } = useGetPortalRolesQuery(toRef(() => props.portalView));

  const membersPanel = ref<HTMLDivElement | null>(null);
  const searchedMember = ref('');
  const searchedMemberDebounced = refDebounced(searchedMember, 500);
  const selectedRoles = ref<RoleView[]>([]);

  const selectedRolesIds = computed(() =>
    selectedRoles.value.map((role) => role.id),
  );

  const queryParams = computed<PortalMemberQuery>(() => ({
    includes_roles: true,
    page_size: BigInt(50),
    page_start: BigInt(0),
    portal_id: props.portalView.id,
    role_ids: selectedRolesIds.value,
    filter_order: [{ Ascending: null }],
    username_filter: [searchedMemberDebounced.value],
  }));

  const {
    data: getPortalMemberPageDto,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetPortalMembersQuery(
    toRef(() => props.portalView),
    queryParams,
  );

  const members = computed(() => {
    const pages = getPortalMemberPageDto.value?.pages ?? [];
    const membersList = pages.flatMap((page) => page?.members ?? []);
    return uniqBy(membersList, 'id');
  });

  const filteredRoles = computed(() => {
    return roles.value?.filter(
      (role) => !(ROLE_KIND_FIELD_EVERYONE in role.kind),
    );
  });

  const totalMembers = computed(() => {
    const total = getPortalMemberPageDto.value?.pages[0]?.total_members;
    return total ? Number(total) : 0;
  });

  const filteredMembersIDs = computed(() => {
    return members.value.map((member) => ({
      id: member.user.id.toString(),
    }));
  });

  const virtualizerOptions = computed(() => ({
    count: members.value.length,
    estimateSize: () => 65,
    getScrollElement: () => membersPanel.value,
  }));

  const rowVirtualizer = useVirtualizer(virtualizerOptions);

  const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());

  const totalSize = computed(() => rowVirtualizer.value.getTotalSize());

  watchEffect(() => {
    const [lastItem] = [...virtualRows.value].reverse();
    if (!lastItem) {
      return;
    }
    if (lastItem.index >= members.value.length - 1) {
      if (!isFetchingNextPage.value && hasNextPage.value) {
        fetchNextPage();
      }
    }
  });

  const kickMember = (member: MemberListItemView) => {
    openKickBanDialog((reason) => submitKickModal(member, reason), false);
  };

  const banMember = (member: MemberListItemView) => {
    openKickBanDialog((reason) => submitBankModal(member, reason), true);
  };

  const csvExport = (arrData: { id: string }[]) => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += [
      Object.keys(arrData[0]).join(';'),
      ...arrData.map((item) => Object.values(item).join(';')),
    ]
      .join('\n')
      .replace(/(^\[)|(\]$)/gm, '');

    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', 'export.csv');
    link.click();
  };

  const onUpdateMembersStatus = (
    params: dscvrIcApi.portal.PortalMembersStatusMutationParams,
  ) => {
    setPortalMembersStatusMutation(params, {
      onSuccess: () => {
        showToast({
          title: 'Member updated successfully!',
          type: 'success',
          durationSeconds: 3,
        });
        refetch();
      },
      onSettled: () => {
        closeDialog();
      },
    });
  };

  const submitBankModal = (member: MemberListItemView, reason: string) => {
    onUpdateMembersStatus({
      portalId: props.portalView.id,
      memberId: member.id,
      kind: { Banned: null },
      reason,
    });
  };

  const submitKickModal = (member: MemberListItemView, reason: string) => {
    onUpdateMembersStatus({
      portalId: props.portalView.id,
      memberId: member.id,
      kind: { Kicked: null },
      reason,
    });
  };

  const selectedRolesChange = (roles: RoleView[]) => {
    selectedRoles.value = roles;
  };

  watch(
    filteredRoles,
    (filteredRoles) => {
      if (filteredRoles && filteredRoles.length > 0) {
        selectedRoles.value = [...filteredRoles];
      }
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <div v-if="portalView" class="relative">
    <div class="flex flex-col justify-between md:flex-row">
      <div>
        <div class="my-1 text-lg font-bold text-white">
          Members ({{ totalMembers }})
        </div>
        <div class="text-sm text-gray-400">
          You can see all of your members here and assign them roles.
        </div>
      </div>

      <button
        class="px-4 py-3 mt-2 text-sm font-medium text-white rounded-lg btn-tertiary"
        @click="csvExport(filteredMembersIDs)"
      >
        Get Airdrop Principals
      </button>
    </div>

    <div class="flex flex-col gap-3 mt-6 mb-2 md:flex-row">
      <div class="flex items-center w-full bg-gray-900 rounded-lg flex-nowrap">
        <base-icon
          name="search"
          size="w-5 h-5"
          class="ml-4 text-gray-400 cursor-pointer"
        />
        <input
          v-model="searchedMember"
          type="text"
          class="flex-grow block h-full text-sm font-medium text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
          placeholder="Search Members..."
        />
      </div>

      <roles-dropdown
        v-if="filteredRoles"
        :roles="filteredRoles"
        :selected-roles="selectedRoles"
        @role-click="selectedRolesChange"
      />
    </div>

    <div class="my-4 text-white">
      <p v-if="!members.length" class="p-3 mt-3 text-center text-small">
        No added members
      </p>
      <div
        v-else
        ref="membersPanel"
        class="max-h-80 px-2 my-5 overflow-y-auto thin-scrollbar"
      >
        <div
          class="relative w-full"
          :style="{
            height: `${totalSize}px`,
          }"
        >
          <div
            v-for="virtualRow in virtualRows"
            :key="virtualRow.index"
            :data-index="virtualRow.index"
            class="absolute top-0 left-0 w-full"
            :style="{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }"
          >
            <member-row
              :member="members[virtualRow.index]"
              :portal-view="portalView"
              @kick-member="kickMember(members[virtualRow.index])"
              @ban-member="banMember(members[virtualRow.index])"
              @refetch="refetch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
