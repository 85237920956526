import { useQuery } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import { getTippedContentsPollingQueryKey } from './keys';
import { dscvrApi } from '@/shared/api';

export function useGetTippedContentsPollingQuery(
  visibleContentIds: Ref<Set<bigint>>,
  pollingInterval = 1500,
) {
  return useQuery({
    queryKey: getTippedContentsPollingQueryKey,
    queryFn: () =>
      dscvrApi.tip.getContentTips(Array.from(visibleContentIds.value.values())),
    refetchOnMount: false,
    enabled: () => visibleContentIds.value.size > 0,
    refetchInterval: pollingInterval,
  });
}
