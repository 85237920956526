import { getInitialAvatar, fetchMedia } from '@/shared/lib';
import { isValidUrl } from '@/utils';
import type { PortalView, PortalInfoView } from 'dfx/edge/edge.did';
import type { RequestInitCfPropertiesImage } from '@cloudflare/workers-types';

export const getPortalIcon = (
  portalView: PortalView | PortalInfoView,
  params?: RequestInitCfPropertiesImage,
) => {
  if (!portalView) return;
  return typeof portalView === 'object' && isValidUrl(portalView.icon_url)
    ? fetchMedia(portalView.icon_url, {
        fit: 'scale-down',
        width: 148,
        height: 148,
        ...params,
      })
    : getInitialAvatar({
        name: portalView.name,
        size: 100,
        initial_bg: '#2564eb',
        initial_fg: '#fff',
        initial_size: 0,
        initial_weight: 400,
        initial_font_family: 'Inter',
      });
};
