import { useQuery } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getTippableTokensQueryKeys } from './keys';
import type { TippableTokenListItem } from '@/shared/model/tip';

export const useGetTippableTokensQuery = () => {
  return useQuery({
    queryKey: getTippableTokensQueryKeys,
    queryFn: dscvrApi.tip.getTippableTokens,
    select: (dto) => dto as TippableTokenListItem[],
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // 24 hours
  });
};
