import {
  createRouter,
  createWebHistory,
  type NavigationGuard,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from 'vue-router';
import store from '@/store';
import * as routeNames from '@/common';
import DebugPage from '@/pages/DebugPage.vue';
import SearchPage from '@/pages/search/index.vue';
import LandingPage from '@/pages/LandingPage.vue';
import ModPage from '@/pages/ModPage.vue';
import PortalSettingsPage from '@/pages/p/[portal]/settings/index.vue';
import PostDetailPage from '@/pages/post/[id]/[title]/index.vue';
import ReportsPage from '@/pages/ReportsPage.vue';
import { ArcadePage } from '@/pages/arcade';
import { InvitePage } from '@/pages/invite';
import { JoinPage } from '@/pages/join';
import TermsAndConditionsPage from '@/pages/terms-and-conditions/index.vue';
import PortalDetailPage from '@/pages/p/[portal]/index.vue';
import PortalDetailContentPage from '@/pages/p/[portal]/content/index.vue';
import PortalDetailMarketplacePage from '@/pages/p/[portal]/marketplace/index.vue';
import PortalDetailAboutPage from '@/pages/p/[portal]/about/index.vue';
import PortalSettingsBans from '@/components/PortalSettingsBans.vue';
import PortalSettingsRoleDetails from '@/components/PortalSettingsRoleDetails.vue';
import PortalSettingsMembers from '@/components/PortalSettingsMembers.vue';
import PortalSettingsGates from '@/components/PortalSettingsGates.vue';
import PortalSettingsRoles from '@/components/PortalSettingsRoles.vue';
import PortalSettingsDetails from '@/components/PortalSettingsDetails.vue';
import PortalSettingsOverview from '@/components/PortalSettingsOverview.vue';
import ProposalCreatePage from '@/pages/ProposalCreatePage.vue';
import UserDetailPage from '@/pages/u/[username]/index.vue';
import UserPortalList from '@/pages/u/[username]/portals/index.vue';
import { FeedPage } from '@/pages/feed';
import UserNotifications from '@/pages/u/[username]/notifications/index.vue';
import UserProfileWallets from '@/pages/u/[username]/wallets/index.vue';
import UserProfileIndexPage from '@/pages/u/[username]/settings/index.vue';
import UserProfilePage from '@/pages/u/[username]/settings/profile/index.vue';
import UserProfilePreferencesPage from '@/pages/u/[username]/settings/preferences.vue';
import UserProfileNotificationsPage from '@/pages/u/[username]/settings/notifications.vue';
import UserProfileIntegrationsPage from '@/pages/u/[username]/settings/integrations.vue';
import UserProfilePortalsPage from '@/pages/u/[username]/settings/portals/index.vue';
import UserProfilePeoplePage from '@/pages/u/[username]/settings/people.vue';
import MobileWalletPairingPage from '@/pages/mobile/WalletPairPage.vue';
import MatricaOauthRedirectPage from '@/pages/oauth2/MatricaOauthRedirectPage.vue';
import { ExplorePage } from '@/pages/explore';
import { FramesPage } from '@/pages/dev/frames';
import { EclipseSignupPage } from '@/pages/eclipse-signup';

import { client } from '@/utils';
import {
  BLANK_LAYOUT,
  PROFILE_LAYOUT,
  SETTINGS_LAYOUT,
  DEVELOPMENT_LAYOUT,
} from '@/common';
import {
  useAuth,
  AUTH_ENTITY_PARAM,
  AUTH_RESPONSE_PARAM,
} from '@/entities/auth';
import TwitterOauthRedirectPage from '@/pages/oauth2/TwitterOauthRedirectPage.vue';

/** ROUTER GUARDS SECTION */
// router guards
const guardIsAuthenticated: NavigationGuard = () => {
  if (!store.state.auth.me) {
    return { name: routeNames.HOME };
  }
  return true;
};
const guardIsAuthenticatedAndAdmin: NavigationGuard = () => {
  if (!store.state.auth.me || store.state.auth.me.user_type != 2) {
    return { name: routeNames.HOME };
  }
  return true;
};
const guardRoot: NavigationGuard = async (to) => {
  const authResponseParam = to.query[AUTH_RESPONSE_PARAM];
  const authEntityParam = to.query[AUTH_ENTITY_PARAM];

  if (
    authResponseParam &&
    authEntityParam &&
    typeof authResponseParam === 'string' &&
    typeof authEntityParam === 'string'
  ) {
    const { onAuthorizeRedirect } = useAuth();
    const authClient = await client.getOrCreateAuthClient();
    if (authClient.redirectResponse()) {
      await onAuthorizeRedirect(authClient, authEntityParam);
    } else {
      console.error('No redirect response present in auth client');
    }
    return {
      ...to,
      query: {
        ...to.query,
        [AUTH_RESPONSE_PARAM]: undefined,
        [AUTH_ENTITY_PARAM]: undefined,
      },
    };
  }
  return true;
};
const guardIsCurrentUser: NavigationGuard = (to) => {
  const username = to.params['username'];
  if (username && store.state.auth.me.username === username) {
    return true;
  } else {
    return { name: routeNames.HOME };
  }
};

/** END ROUTER GUARDS SECTION */

export const routes: RouteRecordRaw[] = [
  {
    name: routeNames.MAIN,
    path: '/',
    beforeEnter: guardRoot,
    children: [
      {
        name: routeNames.ABOUT,
        path: '/about',
        component: LandingPage,
        meta: {
          layout: BLANK_LAYOUT,
        },
        props: (route: RouteLocationNormalized) => ({
          ...route.query,
          ...route.params,
        }),
      },
      {
        name: routeNames.HOME,
        path: '',
        component: FeedPage,
      },
      {
        name: routeNames.ALL,
        path: '/all',
        component: FeedPage,
      },
      {
        name: routeNames.ARCADE,
        path: '/games',
        component: ArcadePage,
        props: (route: RouteLocationNormalized) => ({
          ...route.query,
          ...route.params,
        }),
        meta: {
          layout: BLANK_LAYOUT,
        },
      },
      {
        name: routeNames.FOLLOW,
        path: '/follow',
        component: FeedPage,
        beforeEnter: [guardIsAuthenticated],
      },
      {
        name: routeNames.PORTALS,
        path: '/portals',
        component: FeedPage,
        beforeEnter: [guardIsAuthenticated],
      },
      {
        name: routeNames.SEARCH,
        path: '/search',
        component: SearchPage,
      },
      {
        name: routeNames.EXPLORE,
        path: '/explore',
        redirect: { name: routeNames.EXPLORE_PORTALS_FEATURED },
        component: ExplorePage,
        meta: {
          layout: BLANK_LAYOUT,
        },
        children: [
          {
            name: routeNames.EXPLORE_PORTALS,
            path: 'portals',
            children: [
              {
                name: routeNames.EXPLORE_PORTALS_FEATURED,
                path: 'featured',
                component: ExplorePage,
              },
            ],
          },
          {
            name: routeNames.EXPLORE_PEOPLE,
            path: 'people',
            component: ExplorePage,
            meta: {
              layout: BLANK_LAYOUT,
            },
            children: [
              {
                name: routeNames.EXPLORE_PEOPLE_FEATURED,
                path: 'featured',
                component: ExplorePage,
              },
            ],
          },
        ],
      },
      {
        name: routeNames.REPORTS,
        path: '/reports',
        component: ReportsPage,
      },
      {
        name: routeNames.TERMS,
        path: '/terms',
        component: TermsAndConditionsPage,
      },
      {
        name: routeNames.DEBUG,
        path: '/debug',
        component: DebugPage,
      },
      {
        name: routeNames.MATRICA_OAUTH_REDIRECT,
        path: '/oauth2/matrica/callback',
        component: MatricaOauthRedirectPage,
        beforeEnter: [guardIsAuthenticated],
        meta: {
          layout: BLANK_LAYOUT,
        },
      },
      {
        name: routeNames.TWITTER_OAUTH_REDIRECT,
        path: '/oauth2/twitter/callback',
        component: TwitterOauthRedirectPage,
        beforeEnter: [guardIsAuthenticated],
        meta: {
          layout: BLANK_LAYOUT,
        },
      },
      {
        name: routeNames.JOIN,
        path: '/join/:slug',
        component: JoinPage,
        props: true,
        meta: {
          layout: BLANK_LAYOUT,
        },
      },
      {
        name: routeNames.INVITE,
        path: '/invite/:user',
        component: InvitePage,
        props: true,
        meta: {
          layout: BLANK_LAYOUT,
        },
      },
      {
        name: routeNames.PROPOSAL_CREATE_PAGE,
        path: '/sns/neuron/:neuronId/create',
        component: ProposalCreatePage,
        props: true,
      },
      {
        name: routeNames.POST_DETAIL_ID_ONLY,
        path: '/post/:id',
        component: PostDetailPage,
        props: true,
        children: [
          {
            name: routeNames.POST_DETAIL,
            path: ':title',
            component: PostDetailPage,
            props: true,
            children: [
              {
                name: routeNames.POST_DETAIL_COMMENT,
                path: '#:comment',
                component: PostDetailPage,
                props: true,
              },
            ],
          },
          {
            name: routeNames.POST_DETAIL_COMMENT_NO_TITLE,
            path: '#:comment',
            component: PostDetailPage,
            props: true,
          },
        ],
      },
      {
        name: routeNames.PORTAL_PAGE,
        path: '/p/:slug',
        component: PortalDetailPage,
        props: true,
        meta: {
          layout: PROFILE_LAYOUT,
        },
        children: [
          {
            name: routeNames.PORTAL_CONTENT,
            path: '',
            component: PortalDetailContentPage,
            props: true,
          },
          {
            name: routeNames.PORTAL_MARKETPLACE,
            path: 'marketplace',
            component: PortalDetailMarketplacePage,
            props: true,
          },
          {
            name: routeNames.PORTAL_ABOUT,
            path: 'about',
            component: PortalDetailAboutPage,
            props: true,
          },
        ],
      },
      {
        name: routeNames.PORTAL_SETTINGS_PAGE,
        path: '/p/:slug/settings',
        component: PortalSettingsPage,
        props: true,
        meta: {
          layout: SETTINGS_LAYOUT,
        },
        beforeEnter: [guardIsAuthenticated],
        children: [
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
            path: 'role/:roleId?',
            component: PortalSettingsRoleDetails,
            props: true,
          },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_MEMBERS,
            path: 'members',
            component: PortalSettingsMembers,
            props: true,
          },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_BANS,
            path: 'bans',
            component: PortalSettingsBans,
            props: true,
          },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_GATES,
            path: 'gates',
            component: PortalSettingsGates,
            props: true,
          },
          // {
          //   name: "portal-settings-page.invites",
          //   path: "invites",
          //   component: PortalSettingsInvites,
          //   props: true,
          // },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_ROLES,
            path: 'roles',
            component: PortalSettingsRoles,
            props: true,
          },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_DETAILS,
            path: 'details',
            component: PortalSettingsDetails,
            props: true,
          },
          {
            name: routeNames.PORTAL_SETTINGS_PAGE_OVERVIEW,
            path: 'overview',
            component: PortalSettingsOverview,
            props: true,
          },
        ],
      },
      {
        name: routeNames.MOD_PAGE,
        path: '/mod',
        component: ModPage,
        props: true,
        beforeEnter: guardIsAuthenticatedAndAdmin,
      },
      {
        name: routeNames.USER_SETTINGS,
        path: '/u/:username/settings',
        component: UserProfileIndexPage,
        beforeEnter: [guardIsAuthenticated, guardIsCurrentUser],
        props: true,
        meta: {
          layout: SETTINGS_LAYOUT,
        },
        children: [
          {
            name: routeNames.USER_SETTINGS_PROFILE,
            path: '',
            component: UserProfilePage,
            props: true,
          },
          {
            name: routeNames.USER_SETTINGS_PREFERENCES,
            path: 'preferences',
            component: UserProfilePreferencesPage,
            props: true,
          },
          {
            name: routeNames.USER_SETTINGS_NOTIFICATIONS,
            path: 'notifications',
            component: UserProfileNotificationsPage,
            props: true,
          },
          {
            name: routeNames.USER_SETTINGS_INTEGRATIONS,
            path: 'integrations',
            component: UserProfileIntegrationsPage,
            props: true,
          },
          {
            name: routeNames.USER_SETTINGS_PEOPLE,
            path: 'people',
            component: UserProfilePeoplePage,
            props: true,
          },
          {
            name: routeNames.USER_SETTINGS_PORTALS,
            path: 'portals',
            component: UserProfilePortalsPage,
            props: true,
            children: [
              {
                name: routeNames.USER_SETTINGS_PORTALS_FAVORITES,
                path: 'favorites',
                component: UserProfilePortalsPage,
                props: true,
              },
              {
                name: routeNames.USER_SETTINGS_PORTALS_MANAGED,
                path: 'managed',
                component: UserProfilePortalsPage,
                props: true,
              },
            ],
          },
        ],
      },
      {
        name: routeNames.USER,
        path: '/u/:username',
        component: UserDetailPage,
        props: true,
        meta: {
          layout: PROFILE_LAYOUT,
        },
        children: [
          {
            name: routeNames.USER_CONTENT,
            path: '',
            component: FeedPage,
            props: true,
          },
          {
            name: routeNames.USER_PORTALS,
            path: 'portals',
            component: UserPortalList,
            props: true,
          },
          {
            name: routeNames.USER_MANAGE_PORTALS,
            path: 'manage-portals',
            component: UserProfilePortalsPage,
            beforeEnter: [guardIsAuthenticated],
            props: true,
          },
          {
            name: routeNames.USER_NOTIFICATIONS,
            path: 'notifications',
            component: UserNotifications,
            beforeEnter: [guardIsAuthenticated],
            props: true,
          },
          {
            name: routeNames.USER_WALLETS,
            path: 'wallets',
            component: UserProfileWallets,
            beforeEnter: [guardIsAuthenticated, guardIsCurrentUser],
            props: true,
          },
        ],
      },
    ],
  },
  {
    name: routeNames.MOBILE_WALLET_PAIRING,
    path: '/mobile/wallet-pairing',
    component: MobileWalletPairingPage,
    meta: {
      layout: BLANK_LAYOUT,
    },
  },
  {
    name: routeNames.FRAMES_VALIDATOR,
    path: '/dev/frames',
    component: FramesPage,
    beforeEnter: [guardIsAuthenticated],
    meta: {
      layout: DEVELOPMENT_LAYOUT,
    },
  },
  {
    name: routeNames.ECLIPSE_SIGNUP,
    path: '/eclipse-signup',
    component: EclipseSignupPage,
    meta: {
      layout: BLANK_LAYOUT,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { top: 0, left: 0 };
  },
});

export { router };
