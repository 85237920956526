import { Emoji } from '../model/types';

export const DEFAULT_EMOJI_LIST: Emoji[] = [
  {
    name: 'heart',
    icon: 'emoji-like',
  },
  {
    name: 'laugh',
    icon: 'emoji-haha',
  },
  {
    name: 'wave',
    icon: 'emoji-clap',
  },
  {
    name: 'fire',
    icon: 'emoji-fire',
  },
  {
    name: 'lovehands',
    icon: 'emoji-care',
  },
  {
    name: 'cry',
    icon: 'emoji-sad',
  },
  {
    name: 'angry',
    icon: 'emoji-angry',
  },
];
