import type { IconName } from '@/shared/ui/base-icon/types';
import {
  HOME,
  FOLLOW,
  ALL,
  EXPLORE_PEOPLE,
  EXPLORE_PEOPLE_FEATURED,
  EXPLORE_PORTALS,
  EXPLORE_PORTALS_FEATURED,
  ARCADE,
} from '@/common/route-names';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useMainMenu = () => {
  const route = useRoute();
  const mainMenuLinks = computed<{
    [key: string]: {
      label: string;
      name?: string;
      icon: IconName;
      isHidden?: boolean;
      isBeta?: boolean;
      isHighlighted?: boolean;
      mobileOnly?: boolean;
    };
  }>(() => {
    const activeRoute = route.name;
    const highlightHome =
      activeRoute === HOME || activeRoute === FOLLOW || activeRoute === ALL;
    const highlightExplorePortals =
      activeRoute === EXPLORE_PORTALS ||
      activeRoute === EXPLORE_PORTALS_FEATURED;
    const highlightExplorePeople =
      activeRoute === EXPLORE_PEOPLE || activeRoute === EXPLORE_PEOPLE_FEATURED;
    const highlightArcade = activeRoute === ARCADE;

    return {
      home: {
        label: 'Feeds',
        name: HOME,
        icon: highlightHome ? 'home' : 'outline-home',
        isHighlighted: highlightHome,
      },
      portals: {
        label: 'Portals',
        name: EXPLORE_PORTALS_FEATURED,
        icon: highlightExplorePortals ? 'portal' : 'outline-portal',
        isHighlighted: highlightExplorePortals,
      },
      people: {
        label: 'People',
        name: EXPLORE_PEOPLE_FEATURED,
        icon: highlightExplorePeople
          ? 'vuesax-bold-frame'
          : 'vuesax-linear-profile',
        isHighlighted: highlightExplorePeople,
      },
      games: {
        label: 'Games',
        name: ARCADE,
        icon: highlightArcade ? 'vuesax-bold-game' : 'vuesax-linear-game',
        isHighlighted: highlightArcade,
        mobileOnly: true,
      },
      spaces: {
        label: 'Spaces',
        icon: 'spaces',
        isHidden: true,
      },
      airdrops: {
        label: 'Collections',
        icon: 'outline-airdrops',
        isHidden: true,
      },
    };
  });

  return {
    mainMenuLinks,
  };
};
