<script setup lang="ts">
  import { onBeforeMount, ref } from 'vue';
  import type { UserView } from 'dfx/edge/edge.did';
  import InviteDialogContents from './InviteDialogContents.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useUser } from '@/entities/user';

  const props = defineProps<{
    user: UserView;
  }>();

  const { getFormattedTokens, followers, postsCount, getReferralLink } =
    useUser();

  const referralLink = ref('');

  onBeforeMount(() => {
    getReferralLink(props.user).then((link) => {
      referralLink.value = link;
    });
  });
</script>

<template>
  <div class="p-8 pt-4 md:p-0">
    <invite-dialog-contents
      v-if="user && referralLink"
      :referent="user"
      :invite-link="referralLink"
      :name="user.username"
    >
      <template #avatar>
        <user-avatar :item="user" size="w-34 h-34" rounded="rounded-full" />
      </template>
      <template #name>
        {{ user.username }}
      </template>
      <template #description>
        {{ user.bio }}
      </template>
      <template #membersOrFollowers>
        {{ followers }}
      </template>
      <template #posts>
        {{ postsCount }}
      </template>
      <template #points>
        {{ getFormattedTokens(user.rights) }}
      </template>
    </invite-dialog-contents>
  </div>
</template>
