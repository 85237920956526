import { marked } from 'marked';
import { sanitizeSettings } from '@/utils';
import { lazyLoading } from '@/utils/marked/lazyLoading';
import hljs from 'highlight.js';
import sanitizeHtml from 'sanitize-html';
import { unescape } from 'lodash-es';

/**
 *
 * @param body
 */
function enrichUsers(body: string): string {
  const mention = /(^|\s)@([a-zA-Z0-9_-]+)/g;
  return body.replace(
    mention,
    `$1<span data-type="mention" data-id="$2">@$2</span>`,
  );
}

/**
 *
 * @param inputBody
 */
function compiledMarkdownAsHTML(inputBody: string): string {
  let body = sanitizeHtml(inputBody, sanitizeSettings);
  body = enrichUsers(body);
  marked.use({ extensions: [lazyLoading] });
  marked.setOptions({
    highlight: function (code, lang) {
      const useLang = lang.length ? lang : 'markdown';
      return hljs.highlight(unescape(code), { language: useLang }).value;
    },
  });
  const renderer = new marked.Renderer();
  return marked(body, { renderer });
}

/**
 *
 */
export function useFormatMarkdownContent() {
  return {
    compiledMarkdownAsHTML,
  };
}
