<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { Loader } from '@/shared/ui/loader';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import type { NonFungibleToken } from '@/entities/token';
  import { EmptyWallet, useWalletOptions } from '@/features/wallets';

  defineEmits<{
    (e: 'update:selected-nft', nft: NonFungibleToken): void;
  }>();

  const { nonFungibleTokens, isNFTsLoading, fetchWalletOptions } =
    useWalletOptions({
      enableFTs: false,
      enableNFTs: true,
    });

  const noNfts = computed(() => nonFungibleTokens.value.length === 0);

  onMounted(fetchWalletOptions);
</script>

<template>
  <loader
    v-if="isNFTsLoading"
    variant="rainbow"
    border-width="border"
    size="size-10"
  />
  <div
    v-else
    class="flex justify-center items-center w-full h-[323px] px-8 overflow-y-auto thin-scrollbar"
  >
    <empty-wallet v-if="noNfts" />
    <div
      v-else
      class="grid grid-cols-3 md:grid-cols-4 gap-4 auto-rows-[97px] size-full"
    >
      <div
        v-for="(nft, index) in nonFungibleTokens"
        :key="index"
        class="cursor-pointer w-[97px] overflow-hidden rounded-xl hover:border hover:border-gray-950 hover:box-border"
        @click="$emit('update:selected-nft', nft)"
      >
        <template v-if="nft.chain === 'sol'">
          <img
            v-lazy="{
              src: nft.image_url,
              error: fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
              ),
            }"
            class="aspect-square"
            :alt="nft.name"
          />
        </template>
        <template v-else-if="nft.chain === 'icp'">
          <img
            v-if="!(nft.marketplace === 'yumi')"
            v-lazy="{
              src: nft.tokenDisplayUrl,
              error: fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
              ),
            }"
            v-show="nft.tokenDisplayUrl"
            class="aspect-square"
          />
          <iframe
            v-else
            :src="nft.tokenUrl"
            class="aspect-square h-full -z-1 pointer-events-none"
          />
        </template>
      </div>
    </div>
  </div>
</template>
