import type { MaybeRef } from 'vue';
import type { Principal } from '@dfinity/principal';
import type { IcpFungibleToken } from '../types';

export const tokenQueryKey = 'tokenQueryKey';
export const tokenIcpQueryKey = 'tokenIcpQueryKey';
export const tokenSolanaQueryKey = 'tokenSolanaQueryKey';
export const tokenFungibleQueryKey = 'tokenFungibleQueryKey';
export const tokenNonFungibleQueryKey = 'tokenNonFungibleQueryKey';

export const getIcpFTsQueryKey = (
  principal: MaybeRef<Principal | undefined>,
) => [
  tokenQueryKey,
  tokenIcpQueryKey,
  tokenFungibleQueryKey,
  'get-icp-fts',
  principal,
];
export const getIcpNFTsQueryKey = (
  principal: MaybeRef<Principal | undefined>,
) => [
  tokenQueryKey,
  tokenIcpQueryKey,
  tokenNonFungibleQueryKey,
  'get-icp-nfts',
  principal,
];
export const getSolanaFTsQueryKey = (
  principal: MaybeRef<Principal | undefined>,
) => [
  tokenQueryKey,
  tokenSolanaQueryKey,
  tokenFungibleQueryKey,
  'get-solana-fts',
  principal,
];
export const getSolanaNFTsQueryKey = (
  principal: MaybeRef<Principal | undefined>,
  addresses: MaybeRef<string[]>,
) => [
  tokenQueryKey,
  tokenSolanaQueryKey,
  tokenNonFungibleQueryKey,
  'get-solana-nfts',
  principal,
  addresses,
];

export const getIcpBalanceQueryKey = (token: MaybeRef<string>) => [
  tokenQueryKey,
  tokenIcpQueryKey,
  tokenFungibleQueryKey,
  'get-icp-balance',
  token,
];
