import { useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';

export const useSetClaimedOnboardingStreakMutation = () => {
  return useMutation({
    mutationKey: ['set-claimed-onboarding-streak-mutation'],
    mutationFn: (claimedAt: Date) =>
      dscvrApi.me.setClaimedOnboardingStreak(claimedAt),
    retry: true,
    retryDelay: 1000 * 60,
  });
};
