import { ref, onMounted, computed, type ComputedRef } from 'vue';
import type {
  PersonalizedFeedV1Query,
  PagedQuery,
  ContentViewPage,
  ContentView,
  ActionResultContent,
  UserView,
} from 'dfx/edge/edge.did';
import { lang as langUtil, trackEvent } from '@/utils';
import cms from '@/services/cms';
import { useRoute } from 'vue-router';
import {
  forYouFeedParameters,
  followingFeedParameters,
  type FeedOptions,
  type FeedFilter,
  userPostsListKey,
  contentKey,
  getFeedByNameKey,
  listContentKey,
  forYouFeedKey,
  followingFeedKey,
  portalFeedKey,
} from '../feed';
import type { TranslatedContentView } from '../content';
import { useToast } from './use-toast';
import {
  PORTAL_CONTENT,
  USER_CONTENT,
  HOME,
  PORTALS,
  ALL,
  SEARCH,
  FOLLOW,
} from '@/common';
import { portalContentKey, useGetPortalContentQuery } from '@/entities/portal';
import {
  useForYouFeedQuery,
  useTranslateMutation,
  useListContentQuery,
  useFollowingFeedQuery,
  useUserPostsListQuery,
  useDeleteContentMutation,
  useRemoveContentMutation,
  usePinUserContentMutation,
  useContentPinSetMutation,
  useContentNsfwSetMutation,
  useSetContentRankMutation,
} from '@/entities/post';
import { useUser } from '@/entities/user';
import { useQueryClient, type InfiniteData } from '@tanstack/vue-query';
import { uniqBy } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import { usePostDialog } from '@/features/post';

export const useFeed = (feedOptions?: ComputedRef<FeedOptions | undefined>) => {
  const DEFAULT_QUERY_PARAMS = {
    page_size: BigInt(10),
    lang: langUtil.getQueryLang(),
    page: BigInt(0),
    query: [],
    sort: { Hot: null },
    filter: '',
    max_age: [],
    chain_filter: [],
  } as PagedQuery;

  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const route = useRoute();
  const queryClient = useQueryClient();
  const { openCreateEditPostDialog } = usePostDialog();
  const { onSetUserRank } = useUser();

  const isQueryReady = ref(false);
  const buildPagedQueryParams = (params?: FeedOptions) => {
    return {
      ...DEFAULT_QUERY_PARAMS,
      query: params?.query ?? [],
      sort: params?.sort ? params.sort : { Hot: null },
      filter: params?.filter ?? '',
      max_age: params?.max_age ?? [],
      chain_filter: params?.chain_filter ?? [],
    };
  };
  const personalizedFeedV1Query = ref<PersonalizedFeedV1Query>({
    query: buildPagedQueryParams(feedOptions?.value),
    parameters: forYouFeedParameters,
  });

  const feedFilters = computed<FeedFilter>(() => {
    return {
      query: personalizedFeedV1Query.value.query.query[0] ?? '',
      filter: personalizedFeedV1Query.value.query.filter,
      max_age: personalizedFeedV1Query.value.query.max_age[0]?.toString() ?? '',
      sort: personalizedFeedV1Query.value.query.sort,
    };
  });

  onMounted(async () => {
    if (!feedOptions?.value) return;
    const algorithm = feedOptions.value.algorithm;
    if (algorithm) {
      if (algorithm === 'MyFeed') {
        personalizedFeedV1Query.value.parameters = followingFeedParameters;
      }
      const supabaseParameters = await cms.getFeedAlgorithmV1(
        feedOptions.value.algorithm,
      );
      if (supabaseParameters) {
        personalizedFeedV1Query.value.parameters = supabaseParameters;
      }
    }

    isQueryReady.value = true;
  });

  const isUserPostList = computed(() => route.name === USER_CONTENT);

  const isFeed = computed(() =>
    [HOME, PORTALS, ALL, SEARCH, FOLLOW].includes(route.name as string),
  );

  const isPortalPage = computed(() => route.name === PORTAL_CONTENT);

  const queryKeyByContext = computed(() =>
    isFeed.value
      ? [feedOptions?.value?.key]
      : isUserPostList.value
      ? [contentKey, feedOptions?.value?.key, userPostsListKey]
      : isPortalPage.value
      ? [contentKey, feedOptions?.value?.key, portalContentKey]
      : [contentKey],
  );

  const getQueryKey = () => {
    // if no feed options we are in post detail page
    if (!feedOptions) return [contentKey, 'get-content-by-id', route.params.id];
    // we are in feed page
    const filters =
      route.name === USER_CONTENT
        ? [feedOptions?.value?.username?.value]
        : route.name === PORTAL_CONTENT
        ? [feedOptions?.value?.slug?.value, feedFilters.value]
        : [feedFilters.value];
    return [...getFeedByNameKey(feedOptions?.value?.type ?? ''), ...filters];
  };

  const isForYouFeedQueryReady = computed(
    () => isQueryReady.value && feedOptions?.value?.type === forYouFeedKey,
  );

  const isFollowingFeedQueryReady = computed(
    () => isQueryReady.value && feedOptions?.value?.type === followingFeedKey,
  );

  const isListContentFeedQueryReady = computed(
    () => isQueryReady.value && feedOptions?.value?.type === listContentKey,
  );

  const isUserPostListQueryReady = computed(
    () => isQueryReady.value && feedOptions?.value?.type === userPostsListKey,
  );

  const isGetPortalContentQueryReady = computed(
    () => isQueryReady.value && feedOptions?.value?.type === portalFeedKey,
  );

  const {
    data: forYouFeedDto,
    isLoading: isLoadingForYouFeed,
    isFetching: isFetchingForYouFeed,
    fetchNextPage: fetchNextPageForYouFeed,
    isFetchingNextPage: isFetchingNextPageForYouFeed,
    hasNextPage: hasNextPageForYouFeed,
    refetch: refetchForYouFeed,
  } = useForYouFeedQuery(
    feedFilters,
    personalizedFeedV1Query,
    isForYouFeedQueryReady,
  );

  const {
    data: listContentDto,
    isLoading: isLoadingListContent,
    isFetching: isFetchingListContent,
    fetchNextPage: fetchNextPageListContent,
    isFetchingNextPage: isFetchingNextPageListContent,
    hasNextPage: hasNextPageListContent,
    refetch: refetchListContent,
  } = useListContentQuery(
    feedFilters,
    personalizedFeedV1Query,
    isListContentFeedQueryReady,
  );

  const {
    data: followingFeedDto,
    isLoading: isLoadingFollowingFeed,
    isFetching: isFetchingFollowingFeed,
    fetchNextPage: fetchNextPageFollowingFeed,
    isFetchingNextPage: isFetchingNextPageFollowingFeed,
    hasNextPage: hasNextPageFollowingFeed,
    refetch: refetchFollowingFeed,
  } = useFollowingFeedQuery(
    feedFilters,
    personalizedFeedV1Query,
    isFollowingFeedQueryReady,
  );

  const {
    data: userPostListDto,
    isLoading: isLoadingUserPostList,
    isFetching: isFetchingUserPostList,
    isFetchingNextPage: isFetchingNextPageUserPostList,
    hasNextPage: hasNextPageUserPostList,
    fetchNextPage: fetchNextPageUserPostList,
    refetch: refetchUserPostList,
  } = useUserPostsListQuery(
    feedOptions?.value?.username,
    personalizedFeedV1Query,
    isUserPostListQueryReady,
  );

  const {
    data: portalContentDto,
    isLoading: isLoadingPortalContent,
    isFetching: isFetchingPortalContent,
    isFetchingNextPage: isFetchingNextPagePortalContent,
    hasNextPage: hasNextPagePortalContent,
    fetchNextPage: fetchNextPagePortalContent,
    refetch: refetchPortalContent,
  } = useGetPortalContentQuery(
    feedOptions?.value?.slug,
    feedFilters,
    personalizedFeedV1Query,
    isGetPortalContentQueryReady,
  );

  const { mutateAsync: translateContentMutation } = useTranslateMutation();
  const { mutateAsync: translateTitleMutation } = useTranslateMutation();
  const { mutate: deleteContentMutation } = useDeleteContentMutation();
  const { mutate: removeContentMutation } = useRemoveContentMutation();
  const { mutateAsync: pinUserContentMutation } = usePinUserContentMutation();
  const { mutateAsync: contentPinSetMutation } = useContentPinSetMutation();
  const { mutate: contentNsfwSetMutation } = useContentNsfwSetMutation();
  const { mutate: setContentRankMutation } = useSetContentRankMutation();

  const feedDto = computed(() => {
    if (!feedOptions?.value) return null;
    return {
      [forYouFeedKey]: {
        data: forYouFeedDto.value,
        isLoading: isLoadingForYouFeed.value,
        isFetching: isFetchingForYouFeed.value,
        fetchNextPage: fetchNextPageForYouFeed,
        isFetchingNextPage: isFetchingNextPageForYouFeed.value,
        hasNextPage: hasNextPageForYouFeed.value,
        refetch: refetchForYouFeed,
      },
      [listContentKey]: {
        data: listContentDto.value,
        isLoading: isLoadingListContent.value,
        isFetching: isFetchingListContent.value,
        fetchNextPage: fetchNextPageListContent,
        isFetchingNextPage: isFetchingNextPageListContent.value,
        hasNextPage: hasNextPageListContent.value,
        refetch: refetchListContent,
      },
      [followingFeedKey]: {
        data: followingFeedDto.value,
        isLoading: isLoadingFollowingFeed.value,
        isFetching: isFetchingFollowingFeed.value,
        fetchNextPage: fetchNextPageFollowingFeed,
        isFetchingNextPage: isFetchingNextPageFollowingFeed.value,
        hasNextPage: hasNextPageFollowingFeed.value,
        refetch: refetchFollowingFeed,
      },
      [userPostsListKey]: {
        data: userPostListDto.value,
        isLoading: isLoadingUserPostList.value,
        isFetching: isFetchingUserPostList.value,
        fetchNextPage: fetchNextPageUserPostList,
        isFetchingNextPage: isFetchingNextPageUserPostList.value,
        hasNextPage: hasNextPageUserPostList.value,
        refetch: refetchUserPostList,
      },
      [portalFeedKey]: {
        data: portalContentDto.value,
        isLoading: isLoadingPortalContent.value,
        isFetching: isFetchingPortalContent.value,
        fetchNextPage: fetchNextPagePortalContent,
        isFetchingNextPage: isFetchingNextPagePortalContent.value,
        hasNextPage: hasNextPagePortalContent.value,
        refetch: refetchPortalContent,
      },
    }[feedOptions.value.type];
  });

  const posts = computed(() => {
    const pages = feedDto.value?.data?.pages ?? [];
    const contents = pages.flatMap((page) => page?.contents ?? []);
    return uniqBy(contents, 'id');
  });

  const onTranslateContent = async (params: {
    content: TranslatedContentView;
    lang: string;
  }) => {
    const queryKey = getQueryKey();
    let translatedBody: TranslatedContentView['translatedBody'] = null;
    let translatedTitle: TranslatedContentView['translatedTitle'] = null;
    if (!params.content.translatedBody || !params.content.translatedTitle) {
      await translateTitleMutation(
        { text: params.content.title, lang: params.lang },
        {
          onSuccess: async (response) => {
            if (response.data) {
              translatedTitle = response.data.translations[0].translatedText;
            }
          },
        },
      );
      await translateContentMutation(
        { text: params.content.body, lang: params.lang },
        {
          onSuccess: async (response) => {
            if (response.data) {
              translatedBody = response.data.translations[0].translatedText;
            }
          },
        },
      );
    }
    await queryClient.cancelQueries({
      queryKey,
    });
    queryClient.setQueryData(
      queryKey,
      (
        oldData:
          | InfiniteData<ContentViewPage | null, number>
          | ActionResultContent,
      ) => {
        // We are in post detail page
        if ('result' in oldData) {
          return {
            ...oldData,
            result: oldData.result.map((content: TranslatedContentView) => ({
              ...content,
              translatedBody: content.translatedBody ? null : translatedBody,
              translatedTitle: content.translatedTitle ? null : translatedTitle,
            })),
          };
        }
        // We are in feed page
        if ('pages' in oldData) {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              contents: page?.contents.map((content: TranslatedContentView) => {
                if (content.id === params.content.id) {
                  return {
                    ...content,
                    translatedBody: content.translatedBody
                      ? null
                      : translatedBody,
                    translatedTitle: content.translatedTitle
                      ? null
                      : translatedTitle,
                  };
                } else {
                  return content;
                }
              }),
            })),
          };
        }
      },
    );
  };

  const cancelAndSetQueryData = async (updatedContent: ContentView[]) => {
    const queryKey = getQueryKey();
    await queryClient.cancelQueries({
      queryKey,
    });
    queryClient.setQueryData(
      queryKey,
      (
        oldData:
          | InfiniteData<ContentViewPage | null, number>
          | ActionResultContent,
      ) => {
        if ('result' in oldData) {
          return {
            ...oldData,
            result: updatedContent,
          };
        }
        if ('pages' in oldData) {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              contents: updatedContent,
            })),
          };
        }
      },
    );
  };

  const onDeleteContent = (content: ContentView) => {
    cancelAndSetQueryData(posts.value.filter((c) => c.id !== content.id));
    deleteContentMutation(content.id, {
      onSuccess: () => {
        trackEvent('post_action', 'deleted');
      },
      onError: () => {
        showToast({
          description: t('postFeature.errorDelete'),
          type: 'error',
          durationSeconds: 5,
        });
      },
    });
  };

  const onRemoveContent = (content: ContentView) => {
    cancelAndSetQueryData(posts.value.filter((c) => c.id !== content.id));
    removeContentMutation(content.id, {
      onSuccess: () => {
        trackEvent('post_action', 'removed');
      },
      onError: () => {
        showToast({
          description: t('postFeature.errorRemove'),
          type: 'error',
          durationSeconds: 5,
        });
      },
    });
  };

  const onEditContent = (content: ContentView) => {
    openCreateEditPostDialog({ editPost: content });
  };

  const onPinContent = (params: {
    content: ContentView;
    isPinned: boolean;
  }) => {
    contentPinSetMutation({
      contentId: params.content.id,
      isPinned: params.isPinned,
    });
  };

  const onPinContentToProfile = (params: {
    content: ContentView;
    isPinned: boolean;
  }) => {
    pinUserContentMutation({
      contentId: params.content.id,
      isPinned: params.isPinned,
    });
  };

  const onSetNsfw = (params: { content: ContentView; isNsfw: boolean }) => {
    contentNsfwSetMutation({
      contentId: params.content.id,
      isNsfw: params.isNsfw,
    });
  };

  const onGoodbye = (user: UserView) => {
    onSetUserRank(user, BigInt(0));
  };

  const onSetRank = (params: { content: ContentView; amount: bigint }) => {
    setContentRankMutation(
      {
        contentId: params.content.id,
        rank: BigInt(params.amount),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              contentKey,
              'get-content-ranks-by-id',
              params.content.id.toString(),
            ],
          });
        },
      },
    );
  };

  const onComment = (content: ContentView) => {
    const updatedPost = posts.value.map((c) => {
      if (c.id === content.parent_id[0]) {
        return {
          ...c,
          children_count: c.children_count + 1n,
          relevant_children: [...c.relevant_children, content],
        };
      }
      return c;
    });
    cancelAndSetQueryData(updatedPost);
  };

  const onPollVote = (content: ContentView) => {
    if (posts.value.length === 0) {
      cancelAndSetQueryData([content]);
      return;
    }
    const updatedPost = posts.value.map((c) => {
      if (c.id === content.id) {
        return {
          ...c,
          poll: content.poll,
        };
      }
      return c;
    });
    cancelAndSetQueryData(updatedPost);
  };

  const onFetchNextPage = () => {
    if (!feedDto.value?.isFetchingNextPage && feedDto.value?.hasNextPage) {
      const currentPage = feedDto.value?.data?.pageParams.length ?? 0;
      trackEvent('load_more_posts', route.name, currentPage.toString());
      feedDto.value.fetchNextPage();
    }
  };

  const isLoading = computed(
    () => feedDto.value?.isLoading || feedDto.value?.isFetching,
  );

  return {
    buildPagedQueryParams,
    cancelAndSetQueryData,
    feedDto,
    feedFilters,
    forYouFeedDto,
    getQueryKey,
    isFeed,
    isLoading,
    isPortalPage,
    isQueryReady,
    isUserPostList,
    onComment,
    onDeleteContent,
    onEditContent,
    onFetchNextPage,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
    personalizedFeedV1Query,
    posts,
    queryKeyByContext,
  };
};
