const tipKey = 'tip';

export const getUserWalletsQueryKey = (dscvrPk: string) => [
  tipKey,
  'get-user-wallets',
  dscvrPk,
];

export const getTippableTokensQueryKeys = [tipKey, 'get-tippable-tokens'];
export const getTippedContentsPollingQueryKey = [
  tipKey,
  'get-tipped-contents-polling',
];
