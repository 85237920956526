import { NFT } from './nft';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';

const idlFactory = ({ IDL }) => {
  return IDL.Service({
    getTokenIdsForUserDip721: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(IDL.Nat64)],
      ['query'],
    ),
  });
};
export class DIP721 {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  async getKey() {
    return (await client.getIdentity()).getPrincipal().toText();
  }

  async get_my_tokens() {
    return this.get_tokens(this.identity.getPrincipal());
  }

  async getTokens(pid) {
    const tokens = await this.actor.getTokenIdsForUserDip721(pid);
    const results = [];
    for (let i = 0; i < tokens.length; i++) {
      const tokenUrl = `https://${this.canisterId}.raw.icp0.io/${tokens[i]}`;
      const nft = new NFT({
        tokenUrl: tokenUrl,
        tokenDisplayUrl: tokenUrl,
        cid: this.canisterId,
        tokenIndex: tokens[i],
        tokenHash: '',
      });
      results.push(nft);
    }
    return results;
  }
}
