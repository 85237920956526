<script setup lang="ts">
  import { computed, onMounted } from 'vue';
  import { useForm } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import * as yup from 'yup';
  import { SelectFormField, TextFormField } from '@/shared/ui/form-fields';
  import { useNFTGatingSetup } from '../../model/composables/use-nft-gating-setup';
  import EntrepotItem from './EntrepotItem.vue';
  import type { UpdateICPGateDto } from '../../types.ts';
  import type { RoleView, PortalView } from 'dfx/edge/edge.did';
  import {
    type SelectFieldOptionType,
    SelectFieldItem,
    FieldWrapper,
  } from '@/shared/ui/fields';
  import { dscvrApi } from '@/shared/api';

  interface FormFields {
    nft_type?: SelectFieldOptionType;
    collection_name?: string;
    canister_id?: string;
    collection?: dscvrApi.multichainGating.EntrepotCollectionItem;
    role?: RoleView | string;
    new_role_name?: string;
  }

  const props = defineProps<{
    portalView: PortalView;
    item: dscvrApi.multichainGating.ICPGateDto;
  }>();

  const emit = defineEmits<{
    (e: 'saved'): void;
  }>();

  const {
    portalRoles,
    fetchPortalRoles,
    roleOptions,
    updateNftCollection,
    entrepotCollections,
    fetchEntrepotCollections,
    validatePrincipalId,
    validateRoleName,
    createNewRole,
    NFT_TYPE_EXT,
    nftTypeOptions,
  } = useNFTGatingSetup(props.portalView);

  const { t } = useI18n({ useScope: 'global' });

  const schema = yup.object({
    nft_type: yup
      .object<SelectFieldOptionType>()
      .required(t('nftTypeErrorRequired')),
    collection_name: yup.string().when('nft_type', {
      is: (nft_type?: SelectFieldOptionType) =>
        nft_type && nft_type.value !== NFT_TYPE_EXT,
      then: () => yup.string().required(t('collectionNameErrorRequired')),
      otherwise: () => yup.string().notRequired(),
    }),
    canister_id: yup.string().when('nft_type', {
      is: (nft_type?: SelectFieldOptionType) =>
        nft_type && nft_type.value !== NFT_TYPE_EXT,
      then: () =>
        yup
          .string()
          .required(t('canisterIdErrorRequired'))
          .test({
            name: 'canister_id',
            message: t('canisterIdErrorInvalid'),
            test: (value) => {
              if (!value) {
                return false;
              }
              return validatePrincipalId(value);
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
    collection: yup
      .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
      .when('nft_type', {
        is: (nft_type?: SelectFieldOptionType) =>
          nft_type?.value === NFT_TYPE_EXT,
        then: () =>
          yup
            .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
            .required(t('collectionErrorRequired')),
        otherwise: () =>
          yup
            .object<dscvrApi.multichainGating.EntrepotCollectionItem>()
            .notRequired(),
      }),
    role: yup.mixed<RoleView | string>().required(t('roleErrorRequired')),
    new_role_name: yup.string().when('role', {
      is: (role?: RoleView | string) => role && typeof role === 'string',
      then: () =>
        yup
          .string()
          .required(t('roleNameErrorRequired'))
          .max(20, t('roleNameErrorLength'))
          .test({
            name: 'new_role_name',
            message: t('roleNameErrorDuplicated'),
            test: (value) => {
              if (!value) {
                return false;
              }
              return validateRoleName(value);
            },
          }),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const initialValues = computed(() => ({
    nft_type: props.item.type
      ? nftTypeOptions.find((option) => option.value === props.item.type)
      : undefined,
    collection_name: props.item.name,
    canister_id: props.item.canisterId,
    collection:
      props.item.type === NFT_TYPE_EXT
        ? entrepotCollections.value.find(
            (collection) => collection.id === props.item.canisterId,
          )
        : undefined,
    role: props.item.roleId
      ? portalRoles.value?.find(
          (role) => role.id.toString() === props.item.roleId,
        )
      : undefined,
  }));

  const { handleSubmit, controlledValues, errors } = useForm<FormFields>({
    validationSchema: schema,
    initialValues,
  });

  const isNFTTypeEXT = computed(
    () =>
      controlledValues.value.nft_type &&
      controlledValues.value.nft_type.value === NFT_TYPE_EXT,
  );

  const isNFTTypeNotEXT = computed(
    () =>
      controlledValues.value.nft_type &&
      controlledValues.value.nft_type.value !== NFT_TYPE_EXT,
  );

  const isNewRole = computed(
    () =>
      controlledValues.value.role &&
      typeof controlledValues.value.role === 'string',
  );

  const getRequest = async (
    values: FormFields,
  ): Promise<UpdateICPGateDto | undefined> => {
    let roleId: string;
    if (typeof values.role === 'string') {
      const newRole = await createNewRole(values.new_role_name!);
      if (!newRole) {
        return;
      }
      roleId = newRole.id.toString();
    } else {
      roleId = values.role!.id!.toString();
    }

    if (values.nft_type?.value !== NFT_TYPE_EXT) {
      return {
        portalSlug: props.portalView.slug,
        type: values.nft_type!.value,
        name: values.collection_name!,
        canisterId: values.canister_id!,
        roleId,
      };
    }

    return {
      portalSlug: props.portalView.slug,
      type: values.nft_type!.value,
      name: values.collection!.name,
      canisterId: values.collection!.id,
      roleId,
    };
  };

  const submit = handleSubmit(async (values) => {
    const request = await getRequest(values);
    if (!request) {
      return;
    }
    const response = await updateNftCollection(props.item.id, request);
    if (response) {
      emit('saved');
    }
    if (typeof values.role === 'string') {
      fetchPortalRoles();
    }
  });

  onMounted(() => {
    fetchEntrepotCollections();
  });
</script>

<template>
  <form class="mt-6" @submit="submit">
    <div class="flex flex-col gap-10 my-8">
      <div class="-mt-6">
        <label class="text-sm pointer-events-none text-gray-400 mt-4">
          {{ t('itemId') }}
        </label>
        <field-wrapper>
          <span class="text-gray-400">{{ item.id }}</span>
        </field-wrapper>
      </div>

      <div>
        <select-form-field
          name="nft_type"
          :items="nftTypeOptions"
          :label="t('nftType')"
          :error="errors.nft_type"
        />
      </div>

      <div v-if="isNFTTypeNotEXT">
        <text-form-field
          name="collection_name"
          :label="t('collectionName')"
          autocomplete="off"
          :error="errors.collection_name"
        />
      </div>

      <div v-if="isNFTTypeNotEXT">
        <text-form-field
          name="canister_id"
          :label="t('canisterPrincipalId')"
          autocomplete="off"
          :error="errors.canister_id"
        />
      </div>

      <div v-if="isNFTTypeEXT">
        <select-form-field
          name="collection"
          :items="entrepotCollections"
          :label="t('searchNftCollection')"
          :error="errors.collection"
        >
          <template #label="{ item: collectionItem }">
            <entrepot-item :item="collectionItem" />
          </template>
        </select-form-field>
      </div>
      <div>
        <select-form-field
          name="role"
          :items="roleOptions"
          :label="t('nftHolderRole')"
          :error="errors.role"
        >
          <template #label="{ item: roleOption }">
            <template v-if="typeof roleOption === 'string'">
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ roleOption }}</span>
            </template>
            <span v-else>{{ roleOption.name }}</span>
          </template>
          <template #item="{ item: roleOption }">
            <select-field-item v-if="typeof roleOption === 'string'" separator>
              <base-icon name="plus" size="w-4 h-4" />
              <span>{{ roleOption }}</span>
            </select-field-item>
            <select-field-item v-else>
              <span>{{ roleOption.name }}</span>
            </select-field-item>
          </template>
        </select-form-field>
      </div>

      <div v-if="isNewRole">
        <text-form-field
          name="new_role_name"
          :label="t('newRoleName')"
          autocomplete="off"
          :error="errors.new_role_name"
        />
      </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex justify-end">
        <base-button variant="primary" size="small" class="w-auto">
          {{ t('update') }}
        </base-button>
      </div>
    </div>
  </form>
</template>
