import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi, dscvrApi } from '@/shared/api';
import type { ReportTransactionPayload } from '@/entities/wallet-transaction';

export const useReportTransactionMutation = () => {
  return useMutation({
    mutationKey: ['report-canvas-transaction-mutation'],
    mutationFn: ({
      appUrl,
      scanResponse,
      signedTx,
      payload,
    }: {
      appUrl: string;
      scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response;
      signedTx: string;
      payload: ReportTransactionPayload;
    }) =>
      dscvrIcApi.canvas.reportTransaction(
        appUrl,
        scanResponse.requestId,
        signedTx,
        payload.reason,
        payload.comments,
      ),
  });
};
