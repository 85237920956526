<script setup lang="ts">
  import { onMounted } from 'vue';

  onMounted(() => {
    const typeFormScript = document.createElement('script');
    typeFormScript.setAttribute(
      'src',
      'https://embed.typeform.com/next/embed.js',
    );
    document.head.appendChild(typeFormScript);
  });
</script>
<template>
  <slot />
</template>
