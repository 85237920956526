import { dscvrApi } from '@/shared/api';

export interface CampaignMeta {
  version: string;
  pages: CampaignMetaPage[];
}

export interface CampaignMetaPage {
  index: number;
  body: string;
  action: CampaignMetaPageAction;
}

export interface CampaignMetaPageAction {
  type: CampaignMetaPageActionType;
  target: string;
  closable?: boolean;
}

export enum CampaignMetaPageActionType {
  UserFollow = 'user-follow',
  PortalJoin = 'portal-join',
}

export interface LootboxDialogContentItem {
  type: LootboxDialogContentItemType;
  item: dscvrApi.lootbox.ILootboxItem | CampaignMetaPage;
}

export enum LootboxDialogContentItemType {
  Lootbox = 1,
  CampaignMetaPage = 2,
}
