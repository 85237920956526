<script setup lang="ts">
  import { BaseLayout } from '@/layouts';
  import { Arcade } from '@/features/arcade';
  import { useHead } from '@unhead/vue';

  useHead({
    title: 'Arcade games - DSCVR',
  });
</script>

<template>
  <base-layout>
    <arcade />
  </base-layout>
</template>
