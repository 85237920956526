<script setup lang="ts">
  // TODO: Fix FSD
  import { computed, onMounted, ref, type Ref } from 'vue';
  import { useAsyncState, useScroll } from '@vueuse/core';
  import { dscvrApi, solApi } from '@/shared/api';
  import { useNFTGating } from '@/entities/nft-gate';
  import { useUser } from '@/entities/user';
  import { Loader } from '@/shared/ui/loader';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useWalletPairing } from '@/entities/wallets';
  import { usePairDialog } from '@/features/wallets';
  import { useSolanaListings } from '../model/composables/use-solana-listings';
  import TensorNftCollectionItemCard from './TensorNftCollectionItemCard.vue';
  import { useTensorNft } from '../model/composables/use-tensor-nft';
  import { useAuth } from '@/entities/auth';
  import type { PortalView } from 'dfx/edge/edge.did';
  const props = defineProps<{
    portalView: PortalView;
    collectionGate: dscvrApi.multichainGating.MultichainGateDto;
    collectionDetail: dscvrApi.multichainGating.MultichainCollectionDetailDto;
    stats: solApi.collection.MagicEdenCollectionsStats;
  }>();

  defineEmits<{
    (e: 'back'): void;
  }>();

  const PAGE_SIZE = 20;
  const cursor = ref<string | undefined>(undefined);
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { openAddWalletDialog } = usePairDialog();
  const { openBuyNftDialog } = useTensorNft();
  const { isGatedNftCollection } = useNFTGating();
  const { getTensorNftCollectionListings } = useSolanaListings();
  const { arrivedState } = useScroll(document, {
    onScroll: () => {
      if (arrivedState.bottom && !isLoading.value && cursor.value !== null) {
        loadNextPage();
      }
    },
  });

  const listings: Ref<solApi.tensor.TensorNft[]> = ref([]);
  const selectedItem = ref<solApi.tensor.TensorNft>();
  const purchasedItems: Ref<Map<string, boolean>> = ref(new Map());
  const sortColumnDirection = ref<'asc' | 'desc'>('asc');

  const sortParam = computed(() => {
    return sortColumnDirection.value === 'asc'
      ? 'ListingPriceAsc'
      : 'ListingPriceDesc';
  });

  const { isLoading, execute: loadNextPage } = useAsyncState(async () => {
    const result = await getTensorNftCollectionListings(
      props.collectionDetail,
      PAGE_SIZE,
      cursor.value,
      sortParam.value,
    );
    console.log(result);
    listings.value = [...listings.value, ...result.data];
    if (result.page.hasMore) {
      cursor.value = result.page.endCursor;
    }
    return listings.value;
  }, []);

  const showBuyDialog = (listing: solApi.tensor.TensorNft) => {
    openBuyNftDialog({
      nft: listing,
      purchased: () => purchasedItems.value.set(listing.mint, true),
    });
  };

  const openBuyDialogModal = async (item: solApi.tensor.TensorNft) => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    } else if (!firstPairedSolanaWallet.value) {
      const added = await openAddWalletDialog();
      if (added) {
        showBuyDialog(item);
      }
    } else {
      showBuyDialog(item);
    }
  };

  const sort = (sortDirection: 'asc' | 'desc') => {
    sortColumnDirection.value = sortDirection;
    cursor.value = undefined;
    listings.value = [];
    loadNextPage();
  };

  onMounted(() => {
    fetchPairedWallets();
  });
</script>

<template>
  <div class="flex flex-col w-full">
    <div
      class="flex flex-col gap-4 sm:gap-0 sm:flex-row items-start sm:items-center justify-between mb-2 px-4 lg:p-0"
    >
      <base-button
        variant="custom"
        custom-classes="flex gap-1.5 text-base font-medium items-center md:flex-1 w-full sm:w-auto"
        @click="$emit('back')"
      >
        <base-icon
          name="outlined-arrow-left"
          size="w-5 h-5"
          class="text-gray-300"
        />
        <span>{{ collectionDetail.name }}</span>
        <span
          v-if="isGatedNftCollection(portalView, collectionGate)"
          class="hidden sm:block ml-4 text-gray-300 text-sm"
        >
          {{ $t('marketplaceCollectionItemLegend') }}
        </span>
      </base-button>
      <div class="flex items-center w-full sm:w-auto">
        <span class="sm:hidden text-gray-300 text-sm flex-1">{{
          $t('marketplaceCollectionItemLegend')
        }}</span>

        <base-dropdown
          append-to-body
          custom-content-classes="flex flex-col border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden "
        >
          <template #button="{ isVisible, toggle }">
            <base-button variant="tertiary" size="small" @click="toggle">
              <base-icon name="vuesax-bulk-arrow-3" size="w-5 h-5" />
              <span class="capitalize">{{ $t('sort') }}</span>
              <base-icon
                :name="isVisible ? 'chevron-up-filled' : 'chevron-down-filled'"
                size="w-5 h-5"
              />
            </base-button>
          </template>
          <template #content="{ hide }">
            <base-button
              variant="custom"
              size="small"
              :custom-classes="
                sortColumnDirection == 'asc'
                  ? 'w-full px-4 py-3 text-left text-white font-bold'
                  : 'w-full px-4 py-3 text-left text-gray-300'
              "
              @click="hide(), sort('asc')"
            >
              Price asc
            </base-button>
            <base-button
              variant="custom"
              size="small"
              :custom-classes="
                sortColumnDirection == 'desc'
                  ? 'w-full px-4 py-3 text-left text-white font-bold'
                  : 'w-full px-4 py-3 text-left text-gray-300'
              "
              @click="hide(), sort('desc')"
            >
              Price desc
            </base-button>
          </template>
        </base-dropdown>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
      <tensor-nft-collection-item-card
        v-for="item in listings"
        :key="item.mint"
        :item="item"
        :selected="selectedItem === item"
        :purchased="purchasedItems.get(item.mint)"
        @buy="openBuyDialogModal(item)"
        @select="selectedItem = $event ? item : undefined"
      />
    </div>
    <div v-if="isLoading" class="relative w-full min-h-32">
      <loader variant="rainbow" border-width="border" size="w-10 h-10" />
    </div>
  </div>
</template>
