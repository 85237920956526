import { defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import type { PortalView } from 'dfx/edge/edge.did';
import { PORTAL_PAGE, USER_CONTENT } from '@/common';
import { useDialog } from './use-dialog';

export const useShareDialog = () => {
  const router = useRouter();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const { t } = useI18n({ useScope: 'global' });

  const openShareDialog = (shareLink: string) => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/share-link/ShareLink.vue'),
        ),
        props: {
          shareLink,
        },
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/TitleHeader.vue'),
        ),
        props: {
          title: t('share'),
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-125`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openShareUserProfile = (username: string) => {
    const route = router.resolve({
      name: USER_CONTENT,
      params: { username },
    });
    openShareDialog(new URL(route.href, window.location.origin).href);
  };

  const openSharePortalProfile = (portal: PortalView) => {
    const route = router.resolve({
      name: PORTAL_PAGE,
      params: { slug: portal.slug },
    });
    openShareDialog(new URL(route.href, window.location.origin).href);
  };

  return {
    openShareDialog,
    openShareUserProfile,
    openSharePortalProfile,
    closeDialog,
  };
};
