<script lang="ts" setup>
  import { computed } from 'vue';
  import { useIgc } from '../model/composables/use-igc';
  import { useAuth } from '../../auth';
  import { useUser } from '../../user';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { maxBy, sumBy } from 'lodash-es';
  import { fetchMedia } from '@/shared/lib';

  const props = defineProps<{
    contentView: ContentView;
  }>();

  const { isLoggedIn } = useUser();
  const { showLoginSignUpDialog } = useAuth();
  const { openConfiguredAwardsSelectionDialog } = useIgc(props.contentView);

  const hasAwards = computed(() => props.contentView.post_awards.length > 0);

  const awardsCount = computed(() =>
    sumBy(props.contentView.post_awards, 'count'),
  );

  const mostAwardedView = computed(() =>
    maxBy(props.contentView.post_awards, 'award_view.id'),
  );
</script>

<template>
  <base-button
    :variant="hasAwards ? 'primary' : 'secondary'"
    size="x-small"
    additional-classes="md:ml-2"
    @click="
      isLoggedIn
        ? openConfiguredAwardsSelectionDialog()
        : showLoginSignUpDialog('signup')
    "
  >
    <template v-if="hasAwards">
      <img
        v-if="mostAwardedView?.award_view.icon_url"
        :src="fetchMedia(mostAwardedView.award_view.icon_url)"
        :alt="mostAwardedView?.award_view.name"
        class="size-5"
      />
      <span> {{ awardsCount }}</span>
    </template>
    <base-icon v-else name="award" size="size-4.5" class="text-gray-400" />
  </base-button>
</template>
