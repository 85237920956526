<script lang="ts" setup>
  import { ref } from 'vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { useI18n } from 'vue-i18n';
  import { useWalletDialog, useConnectSolanaWallets } from '@/entities/wallets';
  import { useGimluck } from './model/composables/use-gimluck';
  import { useToast } from '@/shared/model';
  import { encode as bs58encode } from 'bs58';

  const { t } = useI18n({
    useScope: 'global',
  });

  const { openConnectWalletDialog } = useWalletDialog();
  const { wallet, signMessage, connectedWalletAddress } =
    useConnectSolanaWallets();
  const { openGimluckDialog } = useGimluck();
  const { showToast } = useToast();
  const canClaim = ref(true);

  const onClickClaim = async () => {
    canClaim.value = false;
    const result = await openConnectWalletDialog();

    if (
      !result ||
      !wallet.value ||
      !connectedWalletAddress.value ||
      !signMessage.value
    ) {
      showToast({
        title: t('wallets.connectWallet.errorConnectingWallet'),
        type: 'error',
        durationSeconds: 3,
      });
      return;
    }

    const message = `https://dscvr.one wants you to sign with your Solana account: ${connectedWalletAddress.value} \n
  Signing this message proves you own this wallet and nothing else. This is not a transaction, so you will not be charged any fees.

  URI: https://dscvr.one
`;

    try {
      const response = await signMessage.value(
        new TextEncoder().encode(message),
      );

      const signature = bs58encode(response);

      openGimluckDialog({
        signature: signature,
        wallet_public_key: connectedWalletAddress.value,
      });
    } catch (e: unknown) {
      showToast({
        title: t('tip.signatureFailed'),
        type: 'error',
        durationSeconds: 3,
      });
    }
  };
</script>

<template>
  <div
    class="flex justify-between items-center gap-4 w-full py-4 pl-4 pr-6 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.24)] bg-gray-785 bg-opacity-50 border border-[rgba(51,59,71,0.50)] rounded-xl"
  >
    <div class="flex items-center gap-6">
      <img
        :src="
          fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/gimluck/gimluck-snail-small.gif`,
          )
        "
        class="size-15 md:size-25 flex-none"
        alt="Gimluck"
      />
      <div class="flex flex-col gap-2">
        <span class="font-semibold tracking-[0.16px]">
          {{ t('gimluck.banner.title') }}
        </span>
        <span class="text-sm text-gray-400 tracking-[0.14px]">
          {{ t('gimluck.banner.description') }}
        </span>
      </div>
    </div>
    <base-button
      variant="primary"
      size="medium"
      :disabled="!canClaim"
      @click="onClickClaim"
    >
      <span class="font-medium tracking-[0.14px]">
        {{ t('gimluck.banner.button') }}
      </span>
    </base-button>
  </div>
</template>
