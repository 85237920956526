import { apiInstance } from '../base';
import type {
  CursorDataResponse,
  DataResponse,
  GetListingParams,
  GetStatsParams,
  TensorCollection,
  TensorNft,
} from './types';

const BASE_URL = '/tensor/collection';

export const getListings = (params: GetListingParams) => {
  return apiInstance
    .get(`${BASE_URL}/listings/${params.collectionId}`, {
      params,
    })
    .then((response) => response.data) as Promise<
    CursorDataResponse<TensorNft[]>
  >;
};

export const getStats = (params: GetStatsParams) => {
  return apiInstance
    .get(`${BASE_URL}/stats/${params.collectionSlug}`, {
      params,
    })
    .then((response) => response.data) as Promise<
    DataResponse<TensorCollection>
  >;
};
