<script setup lang="ts">
  import { createSlider } from '@typeform/embed';
  import { USER_SETTINGS_INTEGRATIONS } from '@/common';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useBreakpoint } from '@/shared/model';
  import { useChainBehavior } from '@/entities/user-setting';
  import { useWalletPairing } from '@/entities/wallets';

  defineEmits<{
    (e: 'add-wallet'): void;
  }>();

  const { solanaPairedWallets } = useWalletPairing();
  const { isSmallerThanSm, isSmallerThanLg } = useBreakpoint();
  const { isSolanaUxChainEnabled } = useChainBehavior();
  const { toggle: openCreateAirdropForm } = createSlider('oc5JXFi5');
</script>
<template>
  <div v-if="isSmallerThanSm">
    <section>
      <base-dropdown
        placement="bottom-start"
        custom-content-classes="md:min-w-[306px] w-full rounded-xl bg-gray-950 text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none text-sm md:text-base"
      >
        <template #button="{ toggle }">
          <base-button
            variant="secondary"
            @click="toggle"
            class="flex items-center cursor-pointer px-3 py-1.5"
          >
            <base-icon name="vertical-dots" class="mr-1" size="size-4" />
            <span class="text-sm leading-4.5"> {{ $t('more') }} </span>
          </base-button>
        </template>
        <template #content="{ hide }">
          <base-button
            v-if="solanaPairedWallets.length"
            variant="dark"
            class="px-3.5 py-2 cursor-pointer"
            :to="{ name: USER_SETTINGS_INTEGRATIONS }"
            @click="hide"
          >
            <base-icon name="wallet-2" class="mr-2" size="size-4" />
            {{ $t('manageWallets') }}
          </base-button>
          <base-button
            v-else
            variant="dark"
            class="px-3.5 py-2 cursor-pointer"
            @click="hide(), $emit('add-wallet')"
          >
            <base-icon name="wallet-2" class="mr-2" size="size-4" />
            {{ $t('addWallet') }}
          </base-button>
          <base-button
            variant="dark"
            class="px-3.5 py-2 cursor-pointer"
            @click="hide(), openCreateAirdropForm"
          >
            <base-icon name="airdrop-meteor" class="mr-2" size="size-4" />
            {{ $t('createAirdrop') }}
          </base-button>
        </template>
      </base-dropdown>
    </section>
  </div>
  <div v-else class="flex items-center gap-6">
    <base-button
      v-if="solanaPairedWallets.length"
      variant="secondary"
      :custom-classes="isSmallerThanLg ? undefined : 'flex gap-2 items-center'"
      size="x-small"
      class="hidden sm:flex text-gray-400 !p-2"
      :to="{ name: USER_SETTINGS_INTEGRATIONS }"
    >
      <base-icon name="wallet" size="size-4" />
      <span class="hidden lg:block">
        {{ $t('manageWallets') }}
      </span>
    </base-button>
    <base-button
      v-else-if="isSolanaUxChainEnabled"
      variant="secondary"
      :custom-classes="isSmallerThanLg ? undefined : 'flex gap-2 items-center'"
      size="x-small"
      class="hidden sm:flex text-gray-400 !p-2"
      @click="$emit('add-wallet')"
    >
      <base-icon name="wallet" size="size-4" />
      <span class="hidden lg:block">
        {{ $t('addWallet') }}
      </span>
    </base-button>

    <base-button
      variant="custom"
      @click="openCreateAirdropForm"
      class="ease-transition-all flex items-center cursor-pointer justify-center text-sm font-medium leading-4.5 bg-gray-40 bg-opacity-40 hover:bg-indigo-700 active:bg-indigo-800 px-3 py-2 h-full !rounded-xl ease-transition-all"
    >
      <base-icon name="airdrop-meteor" class="mr-2" size="size-4" />
      {{ $t('createAirdrop') }}
    </base-button>
  </div>
</template>
