<script setup lang="ts">
  import { toRef } from 'vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useNotification } from '../model/composables/use-notification';
  import type { AppNotificationPayload } from '../types';

  const props = defineProps<{
    notification: AppNotificationPayload;
  }>();
  const notification = toRef(props, 'notification');

  const {
    redirect,
    notifier,
    icon,
    content,
    timestamp,
    isUnread,
    isTypeFollower,
  } = useNotification(notification);
</script>

<template>
  <section
    class="flex gap-4 py-4 px-6 text-sm cursor-pointer hover:bg-white hover:bg-opacity-3"
    :class="{ 'bg-[#605BAC] bg-opacity-8': isUnread }"
    @click.prevent="redirect()"
  >
    <!-- Reaction Type -->
    <section class="flex flex-col items-center justify-center w-6 h-8 py-1">
      <base-icon :name="icon" size="w-[27px] h-[27px]" class="text-gray-500" />
    </section>
    <div class="flex flex-col space-y-3">
      <!-- Avatar -->
      <user-avatar
        v-if="notification"
        :item="notification"
        size="w-8 h-8"
        class="mr-1 flex-shrink-0"
      />
      <div class="text-white">
        <base-button
          variant="custom"
          custom-classes="inline font-[500] hover:underline"
          class="font-[500] hover:underline"
          @click.stop="redirect(notifier)"
        >
          {{ notifier }}
        </base-button>
        <span class="mx-1">
          {{ isTypeFollower ? 'followed you' : content }}
        </span>
        <span class="text-gray-500">· {{ timestamp }}</span>
      </div>
    </div>
  </section>
</template>
