import { extractMetaTagContent } from '@/entities/opengraph';
import { extractFrameMetaTags } from '../lib/extract-frame-meta-tags';
import type { Frame, FrameButton, FrameButtonActionType } from '../types';
import { assign } from 'lodash-es';

export const createFrameFromHtml = (
  html: Document,
  url: string,
  initialFrame = false,
): Frame => {
  const isOpenFrame =
    html.querySelectorAll(
      'meta[property^="of:version"], meta[name^="of:version"]',
    ).length > 0;
  const metaTags = extractFrameMetaTags(html);
  const frameSpec = isOpenFrame ? 'of' : `fc:frame`;
  const inputTextContent = extractMetaTagContent(
    html,
    `${frameSpec}:input:text`,
  );
  const buttons = html.querySelectorAll(
    `meta[property^="${frameSpec}:button:"], meta[name^="${frameSpec}:button:"]`,
  );

  const frameButtonsMap: Map<number, Partial<FrameButton>> = new Map();

  buttons.forEach((button) => {
    const metaAttributeValue = button.hasAttribute('property')
      ? button.getAttribute('property')
      : button.getAttribute('name');
    const content = button.getAttribute('content');
    const idMatch = metaAttributeValue?.match(
      new RegExp(`${frameSpec}:button:(\\d+)`),
    );
    if (idMatch) {
      const id = parseInt(idMatch[1], 10);
      let frameButton = frameButtonsMap.get(id) || { index: id };

      if (metaAttributeValue === `${frameSpec}:button:${id}`) {
        assign(frameButton, { label: content });
      } else if (metaAttributeValue === `${frameSpec}:button:${id}:action`) {
        assign(frameButton, { action: content as FrameButtonActionType });
      } else if (metaAttributeValue === `${frameSpec}:button:${id}:target`) {
        assign(frameButton, { target: content });
      } else if (metaAttributeValue === `${frameSpec}:button:${id}:post_url`) {
        assign(frameButton, { post_url: content });
      } else if (metaAttributeValue === `${frameSpec}:button:${id}:icon_name`) {
        assign(frameButton, {
          icon: {
            name: content,
          },
        });
      } else if (
        metaAttributeValue === `${frameSpec}:button:${id}:icon_position`
      ) {
        assign(frameButton, {
          icon: {
            position: content,
          },
        });
      }
      frameButtonsMap.set(id, frameButton as FrameButton);
    }
  });

  const frameButtons = Array.from(frameButtonsMap.values()) as FrameButton[];

  return {
    url,
    metaTags,
    postUrl: extractMetaTagContent(html, `${frameSpec}:post_url`) || '',
    imageUrl: extractMetaTagContent(html, `${frameSpec}:image`) || '',
    source: 'dscvr.one',
    ...(inputTextContent && {
      inputText: {
        placeholder: inputTextContent || '',
        value: '',
      },
    }),
    buttons: frameButtons,
    state: initialFrame
      ? ''
      : extractMetaTagContent(html, `${frameSpec}:state`) || '',
  };
};
