import { type Ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { type FrameActionPayload } from '@/features/frame';

export const useFrameAction = (action: Ref<FrameActionPayload>) => {
  const { t } = useI18n({ useScope: 'global' });

  const isLink = computed(() => action.value.type === 'link');
  const isRedirect = computed(() => action.value.type === 'post_redirect');

  const title = computed(() => {
    switch (action.value.type) {
      case 'intro':
        return t('frame.frameIntroduction');
      case 'error':
        return t('error');
      case 'link':
      case 'post_redirect':
        return t('frame.frameLink');
      default:
        return t('frame.frameAction');
    }
  });

  const link = computed(() => {
    if (isLink.value || isRedirect.value) {
      return action.value.redirectUrl || '';
    }
    return action.value.request?.postUrl;
  });

  const durationSeconds = computed(() => {
    if (action.value.responseTimestamp) {
      return (
        (action.value.responseTimestamp - action.value.requestTimestamp) / 1000
      );
    }
    return 0;
  });

  const requestJson = computed(() => {
    if (!action.value.request) {
      return null;
    }
    return JSON.stringify(action.value.request, null, 2);
  });

  return {
    isLink,
    title,
    link,
    durationSeconds,
    requestJson,
  };
};
