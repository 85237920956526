import type { ContentView } from 'dfx/edge/edge.did';
import {
  POST_DETAIL,
  POST_DETAIL_COMMENT,
  POST_DETAIL_COMMENT_NO_TITLE,
  POST_DETAIL_ID_ONLY,
} from '@/common';
import { formatAsUrlParam } from '@/shared/lib';
import { generatePostTitle } from './generate-post-title';

export const resolvePostDetailRoute = (post: ContentView, comment?: string) => {
  const postTitle = generatePostTitle(post);
  const title = postTitle ? formatAsUrlParam(postTitle) : undefined;
  if (!title) {
    return {
      name: comment ? POST_DETAIL_COMMENT_NO_TITLE : POST_DETAIL_ID_ONLY,
      params: { id: post.id.toString(), comment },
    };
  }

  return {
    name: comment ? POST_DETAIL_COMMENT : POST_DETAIL,
    params: { id: post.id.toString(), title, comment },
  };
};
