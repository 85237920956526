import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { dscvrApi } from '@/shared/api';
import { getMyTwitterProfileKey } from './keys';

export const useUnlinkTwitterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['unlink-twitter-mutation'],
    mutationFn: dscvrApi.oauth2.unlinkTwitter,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getMyTwitterProfileKey,
      });
    },
  });
};
