import { actor, edgeActor } from '../base';
import type {
  PortalMemberView,
  MemberView,
  RoleView,
  ActionResultBanUser,
  RandomizedRecommendationQuery,
  UserView,
} from 'dfx/edge/edge.did';
import type {
  PortalId,
  UserPrincipal,
  AddRemoveMemberRoleMutationParams,
  GetUserPortalRolesQueryParams,
  BanUserParams,
  TokenGiftUserParams,
} from './types';

export type {
  PortalId,
  UserPrincipal,
  AddRemoveMemberRoleMutationParams,
  GetUserPortalRolesQueryParams,
  BanUserParams,
  TokenGiftUserParams,
};

export const setUserRank = (id: UserPrincipal, rank: bigint) => {
  return actor().set_user_rank([[id, rank]]);
};

export const getUserRank = (id: UserPrincipal) => {
  return actor().get_user_ranks(id) as Promise<[[bigint, bigint]]>;
};

export const isUserFollowing = (id: UserPrincipal) => {
  return actor()
    .is_user_following(id)
    .then((res) => res) as Promise<boolean>;
};

export const getUserPortalRoles = (params: GetUserPortalRolesQueryParams) => {
  return actor().get_user_portal_roles(
    params.portalId,
    params.userId,
  ) as Promise<[] | [PortalMemberView]>;
};

export const getAssignablePortalRoles = (portalId: PortalId) => {
  return actor()
    .get_assignable_portal_roles(portalId)
    .then((res) => res) as Promise<[] | [[MemberView, RoleView[]]]>;
};

export const addMemberRole = (params: AddRemoveMemberRoleMutationParams) => {
  return actor().add_member_role(params.portalId, [params.members]) as Promise<
    MemberView[]
  >;
};

export const banUser = (params: BanUserParams) => {
  return actor().ban_user(
    params.userId,
    BigInt(params.length),
    params.why,
  ) as Promise<ActionResultBanUser>;
};

export const unbanUser = (userId: UserPrincipal) => {
  return actor().unban_user(userId) as Promise<undefined>;
};

export const tokenGiftUser = (params: TokenGiftUserParams) => {
  return actor().token_gift_user(
    params.userId,
    params.amount,
  ) as Promise<undefined>;
};

export const setUserInterests = (interests: string[]) => {
  return edgeActor().set_user_interests(interests);
};

export const getUserInterests = () => {
  return edgeActor().get_user_interests() as Promise<string[]>;
};

export const getRandomizedRecommendedUsers = (
  query: RandomizedRecommendationQuery,
) => {
  return actor()
    .get_randomized_recommended_users(query)
    .then((res) => res) as Promise<UserView[]>;
};
