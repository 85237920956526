import { getActor } from './dfx_external';
import { client, getIdentity } from './dfinity';

const idlFactory = ({ IDL }) => {
  const BoolResult = IDL.Variant({ ok: IDL.Bool, err: IDL.Text });
  const NatResult = IDL.Variant({ ok: IDL.Nat, err: IDL.Text });
  const Address__1 = IDL.Text;
  const ResponseResult = IDL.Variant({
    ok: IDL.Vec(IDL.Text),
    err: IDL.Text,
  });
  const TransactionType = IDL.Variant({
    fee: IDL.Null,
    burn: IDL.Null,
    claim: IDL.Null,
    mint: IDL.Null,
    swap: IDL.Null,
    addLiquidity: IDL.Null,
    removeLiquidity: IDL.Null,
    refreshIncome: IDL.Null,
    transfer: IDL.Null,
    collect: IDL.Null,
  });
  const Address = IDL.Text;
  const SwapRecordInfo = IDL.Record({
    to: IDL.Text,
    feeAmount: IDL.Int,
    action: TransactionType,
    feeAmountTotal: IDL.Int,
    token0Id: Address,
    token1Id: Address,
    token0AmountTotal: IDL.Nat,
    liquidityTotal: IDL.Nat,
    from: IDL.Text,
    tick: IDL.Int,
    feeTire: IDL.Nat,
    recipient: IDL.Text,
    token0ChangeAmount: IDL.Nat,
    token1AmountTotal: IDL.Nat,
    liquidityChange: IDL.Nat,
    token1Standard: IDL.Text,
    TVLToken0: IDL.Int,
    TVLToken1: IDL.Int,
    token0Fee: IDL.Nat,
    token1Fee: IDL.Nat,
    timestamp: IDL.Int,
    token1ChangeAmount: IDL.Nat,
    token0Standard: IDL.Text,
    price: IDL.Nat,
    poolId: IDL.Text,
  });
  const PushError = IDL.Record({ time: IDL.Int, message: IDL.Text });
  const TxStorageCanisterResponse = IDL.Record({
    errors: IDL.Vec(PushError),
    retryCount: IDL.Nat,
    canisterId: IDL.Text,
  });
  return IDL.Service({
    addAdmin: IDL.Func([IDL.Text], [BoolResult], []),
    clearTxStorageErrors: IDL.Func([], [IDL.Nat], []),
    cycleAvailable: IDL.Func([], [NatResult], []),
    cycleBalance: IDL.Func([], [NatResult], ['query']),
    exactInput: IDL.Func(
      [Address__1, IDL.Principal, IDL.Nat, IDL.Text, IDL.Text],
      [NatResult],
      [],
    ),
    exactInputSingle: IDL.Func(
      [Address__1, IDL.Principal, IDL.Nat, IDL.Text, IDL.Text, IDL.Text],
      [NatResult],
      [],
    ),
    exactOutput: IDL.Func(
      [Address__1, IDL.Principal, IDL.Nat, IDL.Text, IDL.Text],
      [NatResult],
      [],
    ),
    exactOutputSingle: IDL.Func(
      [Address__1, IDL.Principal, IDL.Nat, IDL.Text, IDL.Text, IDL.Text],
      [NatResult],
      [],
    ),
    getAdminList: IDL.Func([], [ResponseResult], ['query']),
    getCachedSwapRecord: IDL.Func([], [IDL.Vec(SwapRecordInfo)], ['query']),
    getIntervalTime: IDL.Func([], [IDL.Int], ['query']),
    getMaxRetrys: IDL.Func([], [IDL.Nat], ['query']),
    getTxStorage: IDL.Func([], [IDL.Opt(TxStorageCanisterResponse)], ['query']),
    getUnitPrice: IDL.Func([IDL.Text, IDL.Text], [NatResult], []),
    isTxStorageAvailable: IDL.Func([], [IDL.Bool], ['query']),
    quoteExactInput: IDL.Func([IDL.Text, IDL.Text], [NatResult], []),
    quoteExactInputSingle: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Text],
      [NatResult],
      [],
    ),
    quoteExactOutput: IDL.Func([IDL.Text, IDL.Text], [NatResult], []),
    recoverTxStorage: IDL.Func([], [IDL.Nat], []),
    removeAdmin: IDL.Func([IDL.Text], [BoolResult], []),
    removeTxStorage: IDL.Func([], [], []),
    setAvailable: IDL.Func([IDL.Bool], [], ['oneway']),
    setBaseDataStructureCanister: IDL.Func([IDL.Text], [], []),
    setIntervalTime: IDL.Func([IDL.Int], [], []),
    setMaxRetrys: IDL.Func([IDL.Nat], [], []),
    setTxStorage: IDL.Func([IDL.Opt(IDL.Text)], [IDL.Nat], []),
  });
};
export class ICPSwap {
  constructor() {
    this.canisterId = 'enmhi-4yaaa-aaaan-qanpq-cai';
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    //console.log("ID",this.identity.getPrincipal().toText());
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  async getICPAmount(ticker, amount) {
    try {
      return this.actor.quoteExactInput(ticker, amount.toString());
    } catch (err) {
      console.log('ICPSwap getICPAmount', err);
    }
    return {
      ok: undefined,
    };
  }
}
