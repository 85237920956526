import { VersionedTransaction, Transaction } from '@solana/web3.js';
import type { Adapter } from '@solana/wallet-adapter-base';
import { solApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useSignAndSendTransaction = () =>
  useMutation({
    mutationKey: ['sign-and-send-wallet-transaction-mutation'],
    mutationFn: ({
      chainId,
      unsignedTx,
      walletAdapter,
    }: {
      chainId: string;
      unsignedTx: Transaction | VersionedTransaction;
      walletAdapter: Adapter;
    }) => {
      const rpcConnection = solApi.rpc.getConnection(chainId);
      return walletAdapter.sendTransaction(unsignedTx, rpcConnection);
    },
  });
