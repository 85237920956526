import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { ImageHeader } from '@/shared/ui/base-dialog';
import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import {
  OnboardingStep,
  useOnboardingStore,
  useDialog,
  OnboardingLootboxStep,
} from '@/shared/model';
import IntroductoryDialogContent from '../../components/introductory/Content.vue';
import InterestDialogContent from '../../components/interest/Content.vue';
import InterestDialogHeader from '../../components/interest/Header.vue';
import DiscoverUsersDialogContent from '../../components/discover-users/Content.vue';
import DiscoverUsersDialogHeader from '../../components/discover-users/Header.vue';
import JoinPortalsDialogContent from '../../components/join-portals/Content.vue';
import JoinPortalsDialogHeader from '../../components/join-portals/Header.vue';
import RewardDscvrPointsDialogContent from '../../components/reward-dscvr-points/Content.vue';
import ClaimStreakIntroductionDialogContent from '../../components/claim-streak-introduction/Content.vue';
import CompleteOnboardingStepsDialogContent from '../../components/complete-onboarding-steps/Content.vue';
import CompleteOnboardingStepsDialogHeader from '../../components/complete-onboarding-steps/Header.vue';
import NftRewardDialogContent from '../../components/nft-reward/Content.vue';
import ConnectYourWalletDialogHeader from '../../components/connect-your-wallet/Header.vue';
import ConnectYourWalletDialogContent from '../../components/connect-your-wallet/Content.vue';

export const useOnboarding = () => {
  const { openConfiguredDialog, dialogDefaults, closeDialog } = useDialog();
  const onboardingStore = useOnboardingStore();
  const { onboardingState } = storeToRefs(onboardingStore);

  const defaultDialogProps = {
    showCloseButton: false,
    closeOnClickOutside: false,
  };

  const defaultDrawerProps = {
    closeOnClickOutside: false,
  };

  const openIntroductoryDialog = () =>
    openConfiguredDialog({
      content: {
        component: IntroductoryDialogContent,
      },
      header: {
        component: ImageHeader,
        props: {
          showCloseButton: false,
        },
        slots: {
          media: {
            template: `<img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/registration/registration-dialog-banner.png`,
            )}" alt="Welcome" class="size-full rounded-t-xl" />`,
          },
        },
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[480px]`,
      },
      drawer: {
        closeOnClickOutside: false,
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });

  const openInterestDialog = () =>
    openConfiguredDialog({
      content: {
        component: InterestDialogContent,
      },
      header: {
        component: InterestDialogHeader,
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[650px] py-8`,
      },
      drawer: {
        ...defaultDrawerProps,
        customClasses: `${dialogDefaults.drawer.customClasses} p-2`,
      },
    });

  const openDiscoverUsersDialog = (isCompletingSteps = false) =>
    openConfiguredDialog({
      content: {
        component: DiscoverUsersDialogContent,
        props: {
          isCompletingSteps,
        },
      },
      header: {
        component: DiscoverUsersDialogHeader,
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[710px] py-8`,
      },
      drawer: {
        ...defaultDrawerProps,
        customClasses: `${dialogDefaults.drawer.customClasses} p-2`,
      },
    });

  const openJoinPortalsDialog = (isCompletingSteps = false) =>
    openConfiguredDialog({
      content: {
        component: JoinPortalsDialogContent,
        props: {
          isCompletingSteps,
        },
      },
      header: {
        component: JoinPortalsDialogHeader,
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[834px] py-8`,
      },
      drawer: {
        ...defaultDrawerProps,
        customClasses: `${dialogDefaults.drawer.customClasses} p-2`,
      },
    });

  const openRewardDscvrPointsDialog = () =>
    openConfiguredDialog({
      content: {
        component: RewardDscvrPointsDialogContent,
      },
      header: {
        component: ImageHeader,
        props: {
          showCloseButton: false,
        },
        slots: {
          media: {
            template: `<img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/love-rocket.gif`,
            )}" alt="Rewards" class="size-68 mx-auto pb-4" />`,
          },
        },
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-124 pb-8 px-8`,
      },
      drawer: {
        ...defaultDrawerProps,
        customClasses: `${dialogDefaults.drawer.customClasses} p-4 `,
      },
    });

  const openClaimStreakIntroductionDialog = () =>
    openConfiguredDialog({
      content: {
        component: ClaimStreakIntroductionDialogContent,
      },
      dialog: {
        ...defaultDialogProps,
        backdropBlur: 'backdrop-blur-none',
        margin: 'mt-18',
        dialogClasses: 'size-full relative',
      },
      drawer: {
        ...defaultDrawerProps,
        showHeader: false,
        backdropBlur: 'backdrop-blur-none',
        margin: 'mt-18',
        customClasses: 'z-30',
        contentClasses: 'w-full relative h-screen',
      },
    });

  const openCompleteOnboardingStepsDialog = () => {
    openConfiguredDialog({
      content: {
        component: CompleteOnboardingStepsDialogContent,
      },
      header: {
        component: CompleteOnboardingStepsDialogHeader,
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px]`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openNftRewardDialog = () => {
    openConfiguredDialog({
      content: {
        component: NftRewardDialogContent,
      },
      dialog: {
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] px-8 pb-8 relative`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openConnectYourWalletDialog = () => {
    openConfiguredDialog({
      content: {
        component: ConnectYourWalletDialogContent,
      },
      header: {
        component: ConnectYourWalletDialogHeader,
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        backdropBlur: 'backdrop-blur-lg',
        contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[500px] py-8 relative`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openConfiguredOnboardingDialog = (onboardingStep: OnboardingStep) => {
    switch (onboardingStep) {
      case OnboardingStep.INTRODUCTORY:
        return openIntroductoryDialog();
      case OnboardingStep.INTERESTS:
        return openInterestDialog();
      case OnboardingStep.DISCOVER_USERS:
        return openDiscoverUsersDialog();
      case OnboardingStep.JOIN_PORTALS:
        return openJoinPortalsDialog();
      case OnboardingStep.REWARD_DSCVR_POINTS:
        return openRewardDscvrPointsDialog();
      case OnboardingStep.CLAIM_STREAK:
        return openClaimStreakIntroductionDialog();
      default:
        return;
    }
  };

  const openConfiguredOnboardingLootboxDialog = (
    onboardingLootboxStep: OnboardingLootboxStep,
  ) => {
    switch (onboardingLootboxStep) {
      case OnboardingLootboxStep.COMPLETE_ONBOARDING_STEPS:
        return openCompleteOnboardingStepsDialog();
      case OnboardingLootboxStep.MISSING_DISCOVER_USERS:
        return openDiscoverUsersDialog(true);
      case OnboardingLootboxStep.MISSING_JOIN_PORTALS:
        return openJoinPortalsDialog(true);
      case OnboardingLootboxStep.ONBOARDING_STEPS_COMPLETED:
        return closeDialog();
      case OnboardingLootboxStep.MISSING_WALLET:
        return openConnectYourWalletDialog();
      case OnboardingLootboxStep.DONE:
        return openNftRewardDialog();
      default:
        return;
    }
  };

  const isUserDiscoverStepMissing = computed(
    () =>
      !onboardingState.value.completedSteps.includes(
        OnboardingStep.DISCOVER_USERS,
      ),
  );

  const isUserJoinPortalsStepMissing = computed(
    () =>
      !onboardingState.value.completedSteps.includes(
        OnboardingStep.JOIN_PORTALS,
      ),
  );

  const isOnboardingLootboxStep = (
    currentStep: OnboardingStep | OnboardingLootboxStep,
  ): currentStep is OnboardingLootboxStep =>
    Object.values(OnboardingLootboxStep).includes(
      currentStep as OnboardingLootboxStep,
    );

  return {
    isOnboardingLootboxStep,
    isUserDiscoverStepMissing,
    isUserJoinPortalsStepMissing,
    onboardingState,
    openConfiguredOnboardingDialog,
    openConfiguredOnboardingLootboxDialog,
  };
};
