<script setup lang="ts">
  import {
    createSocietyRsActorFromIdentity,
    pairWallet,
  } from '@/utils/dfinity';
  import { stoicClient } from '@/utils/stoic_wallet';
  import { trackEvent } from '@/utils/tracker';
  import { PairButton } from '@/entities/user-setting';
  import { useIcUnpairWalletMutation } from '@/entities/wallets';

  const props = defineProps<{
    isPaired: boolean;
    stoicAddress: string;
  }>();

  const emit = defineEmits<{
    (event: 'unpair-start', value: boolean): void;
    (event: 'pair-start', value: boolean): void;
  }>();

  const unpair = async () => {
    emit('unpair-start', true);

    await useIcUnpairWalletMutation({ Stoic: null });

    emit('unpair-start', false);
  };
  const login = async () => {
    emit('pair-start', true);
    trackEvent('user_settings_action', 'wallet_pair', 'stoic');
    try {
      const identity = await stoicClient.login();
      if (identity) {
        const stoicDscvrActor = await createSocietyRsActorFromIdentity(
          identity,
        );

        await pairWallet({ Stoic: null }, identity._principal, stoicDscvrActor);
      }
    } catch (error) {
      console.error(error);
    }

    emit('pair-start', false);
  };

  const togglePair = () => {
    if (props.isPaired) {
      unpair();
    } else {
      login();
    }
  };
</script>

<template>
  <pair-button
    :connected-id="isPaired ? stoicAddress : undefined"
    @toggle="togglePair"
  >
    <base-icon name="stoic" size="size-10 min-w-10" />
    <template #name>Stoic</template>
    <template #connection>
      {{ $t('copyPrincipal') }}: {{ stoicAddress }}
    </template>
  </pair-button>
</template>
