import { NFT } from './nft';
import { getActor } from '../../../dfx_external';
import { client, getIdentity } from '../../../dfinity';
import { Principal } from '@dfinity/principal';

const idlFactory = ({ IDL }) => {
  const TokenIndex__1 = IDL.Nat;
  const TokenIndex = IDL.Nat;
  const TransferResponse = IDL.Variant({
    ok: TokenIndex,
    err: IDL.Variant({
      ListOnMarketPlace: IDL.Null,
      NotAllowTransferToSelf: IDL.Null,
      NotOwnerOrNotApprove: IDL.Null,
      Other: IDL.Null,
    }),
  });
  const BuyRequest = IDL.Record({
    tokenIndex: TokenIndex,
    price: IDL.Nat,
    marketFeeRatio: IDL.Nat,
    feeTo: IDL.Principal,
  });
  const BuyResponse = IDL.Variant({
    ok: TokenIndex,
    err: IDL.Variant({
      NotAllowBuySelf: IDL.Null,
      InsufficientBalance: IDL.Null,
      AlreadyTransferToOther: IDL.Null,
      NotFoundIndex: IDL.Null,
      Unauthorized: IDL.Null,
      Other: IDL.Null,
      LessThanFee: IDL.Null,
      AllowedInsufficientBalance: IDL.Null,
    }),
  });
  const ListResponse = IDL.Variant({
    ok: TokenIndex,
    err: IDL.Variant({
      NotApprove: IDL.Null,
      NotNFT: IDL.Null,
      NotFoundIndex: IDL.Null,
      SamePrice: IDL.Null,
      NotOwner: IDL.Null,
      Other: IDL.Null,
      MarketNotOpen: IDL.Null,
      AlreadyList: IDL.Null,
    }),
  });
  const CanvasIdentity = IDL.Record({
    photoLink: IDL.Opt(IDL.Text),
    videoLink: IDL.Opt(IDL.Text),
    index: TokenIndex,
  });
  const AirDropResponse = IDL.Variant({
    ok: CanvasIdentity,
    err: IDL.Variant({
      AlreadyCliam: IDL.Null,
      NotInAirDropListOrAlreadyCliam: IDL.Null,
      NotEnoughToMint: IDL.Null,
    }),
  });
  const Time = IDL.Int;
  const NFTLinkInfo = IDL.Record({
    id: TokenIndex,
    photoLink: IDL.Text,
    videoLink: IDL.Text,
  });
  const NFTStoreInfo = IDL.Record({
    photoLink: IDL.Opt(IDL.Text),
    videoLink: IDL.Opt(IDL.Text),
    index: TokenIndex,
  });
  const Listings__1 = IDL.Record({
    tokenIndex: TokenIndex,
    time: Time,
    seller: IDL.Principal,
    price: IDL.Nat,
  });
  const GetListingsRes = IDL.Record({
    listings: Listings__1,
    rarityScore: IDL.Float64,
  });
  const AttrStru = IDL.Record({ attrIds: IDL.Vec(IDL.Nat) });
  const Listings = IDL.Record({
    tokenIndex: TokenIndex,
    time: Time,
    seller: IDL.Principal,
    price: IDL.Nat,
  });
  const SoldListings = IDL.Record({
    lastPrice: IDL.Nat,
    time: Time,
    account: IDL.Nat,
  });
  const GetSoldListingsRes = IDL.Record({
    listings: SoldListings,
    rarityScore: IDL.Float64,
  });
  const ComponentAttribute = IDL.Record({
    name: IDL.Text,
    traitType: IDL.Text,
  });
  const TokenDetails = IDL.Record({
    id: IDL.Nat,
    attrArr: IDL.Vec(ComponentAttribute),
    rarityScore: IDL.Float64,
  });
  const GetTokenResponse = IDL.Variant({
    ok: TokenDetails,
    err: IDL.Variant({ NotFoundIndex: IDL.Null }),
  });
  const ListRequest = IDL.Record({
    tokenIndex: TokenIndex,
    price: IDL.Nat,
  });
  const PreMint = IDL.Record({ user: IDL.Principal, index: IDL.Nat });
  const AirDropStruct = IDL.Record({
    user: IDL.Text,
    remainTimes: IDL.Nat,
  });
  const NFT = IDL.Service({
    approve: IDL.Func([IDL.Principal, TokenIndex__1], [IDL.Bool], []),
    balanceOf: IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    batchTransferFrom: IDL.Func(
      [IDL.Principal, IDL.Vec(IDL.Principal), IDL.Vec(TokenIndex__1)],
      [TransferResponse],
      [],
    ),
    buyNow: IDL.Func([BuyRequest], [BuyResponse], []),
    cancelList: IDL.Func([TokenIndex__1], [ListResponse], []),
    clearAirDrop: IDL.Func([], [IDL.Bool], []),
    cliamAirdrop: IDL.Func([], [AirDropResponse], []),
    deleteAirDrop: IDL.Func([IDL.Text], [IDL.Bool], []),
    getAirDropLeft: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat))],
      ['query'],
    ),
    getAirDropRemain: IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    getAirDropStartTime: IDL.Func([], [Time], ['query']),
    getAll: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(TokenIndex__1, IDL.Principal))],
      ['query'],
    ),
    getAllHistoryStorageCanisterId: IDL.Func(
      [],
      [IDL.Vec(IDL.Principal)],
      ['query'],
    ),
    getAllNFT: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(IDL.Tuple(TokenIndex__1, IDL.Principal))],
      ['query'],
    ),
    getAllNftCanister: IDL.Func([], [IDL.Vec(IDL.Principal)], []),
    getAllNftLinkInfo: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(TokenIndex__1, NFTLinkInfo))],
      ['query'],
    ),
    getAllUserNFT: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(NFTStoreInfo)],
      ['query'],
    ),
    getApproved: IDL.Func([TokenIndex__1], [IDL.Opt(IDL.Principal)], ['query']),
    getAvailableMint: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(TokenIndex__1, IDL.Bool))],
      ['query'],
    ),
    getCirculation: IDL.Func([], [IDL.Nat], ['query']),
    getCycles: IDL.Func([], [IDL.Nat], ['query']),
    getLinkInfoByIndex: IDL.Func(
      [TokenIndex__1],
      [IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)],
      ['query'],
    ),
    getLinkInfoByIndexArr: IDL.Func(
      [IDL.Vec(TokenIndex__1)],
      [IDL.Vec(IDL.Tuple(IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)))],
      ['query'],
    ),
    getListings: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(NFTStoreInfo, GetListingsRes))],
      ['query'],
    ),
    getListingsByAttr: IDL.Func(
      [IDL.Vec(AttrStru)],
      [IDL.Vec(IDL.Tuple(NFTStoreInfo, Listings))],
      ['query'],
    ),
    getMaxMarketFeeRatio: IDL.Func([], [IDL.Nat], ['query']),
    getMintAccount: IDL.Func([], [IDL.Nat], ['query']),
    getNftHoldInfo: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    getNftLinkInfo: IDL.Func(
      [TokenIndex__1],
      [IDL.Opt(NFTLinkInfo)],
      ['query'],
    ),
    getNftStoreCIDByIndex: IDL.Func(
      [TokenIndex__1],
      [IDL.Principal],
      ['query'],
    ),
    getOwnerSize: IDL.Func([], [IDL.Nat], ['query']),
    getRegistry: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Vec(NFTStoreInfo)))],
      ['query'],
    ),
    getRoyaltyFeeRatio: IDL.Func([], [IDL.Nat], ['query']),
    getRoyaltyFeeTo: IDL.Func([], [IDL.Principal], ['query']),
    getSaleInfo: IDL.Func([], [IDL.Nat, IDL.Nat], ['query']),
    getSoldListings: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(NFTStoreInfo, GetSoldListingsRes))],
      ['query'],
    ),
    getStorageCanisterId: IDL.Func([], [IDL.Opt(IDL.Principal)], ['query']),
    getSuppy: IDL.Func([], [IDL.Nat], ['query']),
    getTokenById: IDL.Func([IDL.Nat], [GetTokenResponse], ['query']),
    getWICPCanisterId: IDL.Func([], [IDL.Principal], ['query']),
    getbOpenMarket: IDL.Func([], [IDL.Bool], ['query']),
    isApprovedForAll: IDL.Func(
      [IDL.Principal, IDL.Principal],
      [IDL.Bool],
      ['query'],
    ),
    isList: IDL.Func([TokenIndex__1], [IDL.Opt(Listings)], ['query']),
    list: IDL.Func([ListRequest], [ListResponse], []),
    newStorageCanister: IDL.Func([IDL.Principal], [IDL.Bool], []),
    ownerOf: IDL.Func([TokenIndex__1], [IDL.Opt(IDL.Principal)], ['query']),
    preMint: IDL.Func([IDL.Vec(PreMint)], [IDL.Nat], []),
    proAvailableMint: IDL.Func([], [IDL.Bool], []),
    setApprovalForAll: IDL.Func([IDL.Principal, IDL.Bool], [IDL.Bool], []),
    setCapacity: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setCyclesCreate: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setMaxMarketFeeRatio: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setMintAccount: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setNftCanister: IDL.Func([IDL.Vec(IDL.Principal)], [IDL.Bool], []),
    setNftLinkInfo: IDL.Func([IDL.Vec(NFTLinkInfo)], [IDL.Bool], []),
    setOwner: IDL.Func([IDL.Principal], [IDL.Bool], []),
    setRoyaltyFeeTo: IDL.Func([IDL.Principal], [IDL.Bool], []),
    setRoyaltyfeeRatio: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setStorageCanisterId: IDL.Func([IDL.Opt(IDL.Principal)], [IDL.Bool], []),
    setSuppy: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setTime: IDL.Func([Time], [IDL.Bool], []),
    setWICPCanisterId: IDL.Func([IDL.Principal], [IDL.Bool], []),
    setbOpenMarket: IDL.Func([IDL.Bool], [IDL.Bool], []),
    transferFrom: IDL.Func(
      [IDL.Principal, IDL.Principal, TokenIndex__1],
      [TransferResponse],
      [],
    ),
    updateList: IDL.Func([ListRequest], [ListResponse], []),
    uploadAirDropList: IDL.Func([IDL.Vec(AirDropStruct)], [IDL.Bool], []),
    wallet_receive: IDL.Func([], [IDL.Nat], []),
  });
  return NFT;
};

export class C3 {
  constructor(canisterId) {
    this.canisterId = canisterId;
    this.debug = import.meta.env.DEV;
  }
  async create() {
    if (this.debug) {
      this.identity = getIdentity();
    } else {
      this.identity = await client.getIdentity();
    }
    this.actor = getActor(idlFactory, this.canisterId, this.identity);
  }

  isValidPrincipal(val) {
    try {
      Principal.fromText(val);
      return true;
    } catch (e) {
      return false;
    }
  }

  async claim() {
    return await this.actor.cliamAirdrop();
  }

  async getTokens(principal) {
    const results = [];
    const tokens = await this.actor.getAllNFT(principal);
    if (this.canisterId == '2x3lw-tiaaa-aaaah-qcvca-cai') {
      for (let i = 0; i < tokens.length; i++) {
        const nft = new NFT({
          tokenUrl:
            `https://${tokens[i][1].toText()}.raw.ic0.app/token/` +
            tokens[i][0],
          tokenDisplayUrl:
            `https://${tokens[i][1].toText()}.raw.ic0.app/token/` +
            tokens[i][0],
          cid: this.canisterId,
          tokenIndex: tokens[i][0],
          tokenHash: '',
        });
        results.push(nft);
      }
    } else {
      for (let i = 0; i < tokens.length; i++) {
        const linkInfo = await this.actor.getNftLinkInfo(tokens[i][0]);
        const nft = new NFT({
          tokenUrl:
            `https://gateway.filedrive.io/ipfs/` + linkInfo[0].photoLink,
          tokenDisplayUrl:
            `https://gateway.filedrive.io/ipfs/` + linkInfo[0].photoLink,
          cid: this.canisterId,
          tokenIndex: tokens[i][0],
          tokenHash: '',
        });
        results.push(nft);
      }
    }

    return results;
  }

  async getLinkInfo(token) {
    return await this.actor.getNftLinkInfo(token);
  }

  async get_claims() {
    return await this.actor.getAirDropRemain(this.identity.getPrincipal());
  }

  async transfer(to, token_id) {
    return await this.actor.transferFrom(
      this.identity.getPrincipal(),
      Principal.fromText(to),
      token_id,
    );
  }
}
