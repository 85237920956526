import { useRouter } from 'vue-router';
import { USER_CONTENT } from '@/common';
import { computed, toRefs, ref, toRef } from 'vue';
import {
  useSocialSphere,
  useUser,
  type UserTooltipProps,
} from '@/entities/user';
import { useAddPortalRoleToUserMutation } from '../../api/use-add-portal-role-to-user.mutation';
import { useGetUserPortalRolesQuery } from '../../api/use-get-user-portal-roles.query';
import { useGetAssignableUserPortalRolesQuery } from '../../api/use-get-assignable-user-portal-roles.query';
import { useIsUserFollowingQuery } from '../../api/use-is-user-following.query';
import { getUserPortalRolesByIdKey } from '../../api/keys';
import type { RoleView } from 'dfx/edge/edge.did';
import { trackEvent } from '@/utils';
import { useQueryClient } from '@tanstack/vue-query';
import { useBreakpoint } from '@/shared/model';
import { useUserRole } from './use-user-role';

export const useTooltip = (props: UserTooltipProps) => {
  const router = useRouter();
  const { currentUser, onSetUserRank, getFormattedTokens } = useUser();
  const { showReferralCommunityDialog } = useSocialSphere();
  const { user, portalInfoView } = toRefs(props);
  const queryClient = useQueryClient();
  const { isSmallerThanMd } = useBreakpoint();
  const { isAdmin } = useUserRole();

  const isEnabled = ref(false);
  const showAssignableRolesList = ref(false);

  const { data: isUserFollowing, refetch: refetchIsUserFollowingQuery } =
    useIsUserFollowingQuery(user, isEnabled);
  const { data: userPortalRolesDto } = useGetUserPortalRolesQuery(
    portalInfoView,
    user,
    isEnabled,
  );
  const { data: assignableRoles } = useGetAssignableUserPortalRolesQuery(
    portalInfoView,
    isEnabled,
  );
  const { mutate: addPortalRoleToUserMutation } =
    useAddPortalRoleToUserMutation();

  const size = computed(() => {
    if (props.avatarSize == 'xl') {
      return 'size-10';
    } else if (props.avatarSize == 'md') {
      return 'size-6';
    } else if (props.avatarSize == 'sm') {
      return 'size-4';
    }
    return 'size-8';
  });

  const isSelf = computed(
    () =>
      currentUser.value &&
      user.value &&
      currentUser.value.id.toText() === user.value.id.toText(),
  );

  const goToProfile = () => {
    router.push({
      name: USER_CONTENT,
      params: { username: user.value.username },
    });
  };

  const onClickFollow = (link: 'followers' | 'following') => {
    router.push({
      name: USER_CONTENT,
      params: { username: user.value.username },
    });
    showReferralCommunityDialog(link, isSelf.value);
  };

  const onAddNewRoleToUser = (role: RoleView) => {
    if (!userPortalRolesDto.value?.member) {
      return;
    }
    addPortalRoleToUserMutation(
      {
        portalId: portalInfoView.value.id,
        members: [userPortalRolesDto.value.member.id, role.id],
      },
      {
        onSettled: () => {
          showAssignableRolesList.value = false;
          queryClient.invalidateQueries({
            queryKey: [
              ...getUserPortalRolesByIdKey(
                toRef(() => portalInfoView.value.id.toString()),
                toRef(() => user.value.id.toString()),
              ),
            ],
          });
        },
        onSuccess: () => {
          trackEvent('portal_action', 'set_member_role');
        },
      },
    );
  };

  const onClickGoToProfile = () => {
    if (!isSmallerThanMd.value) {
      goToProfile();
    }
  };

  const onClickFollowingFollower = (label: 'followers' | 'following') => {
    onClickFollow(label);
  };

  const onGoodbye = () => {
    onSetUserRank(user.value, BigInt(0));
  };

  const onHide = () => {
    isEnabled.value = false;
    showAssignableRolesList.value = false;
  };

  return {
    assignableRoles,
    getFormattedTokens,
    goToProfile,
    isAdmin,
    isEnabled,
    isUserFollowing,
    onAddNewRoleToUser,
    onClickFollow,
    onClickFollowingFollower,
    onClickGoToProfile,
    onGoodbye,
    onHide,
    portalInfoView,
    refetchIsUserFollowingQuery,
    size,
    user,
    userPortalRolesDto,
  };
};
