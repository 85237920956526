<script setup lang="ts">
  import { useVModel } from '@vueuse/core';
  import { useUserRole } from '@/entities/user';

  const props = defineProps<{
    editing: boolean;
    isNsfw: boolean;
    disableComments: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'update:isNsfw'): void;
    (e: 'update:disable-comments'): void;
  }>();

  const isNsfwModel = useVModel(props, 'isNsfw', emit);
  const disableCommentsModel = useVModel(props, 'disableComments', emit);
  const { isAdmin } = useUserRole();
</script>
<template>
  <div class="border border-gray-785 border-opacity-50 rounded-xl">
    <div
      class="flex items-center h-12 px-[14px] text-gray-400 text-sm font-medium gap-[14px] cursor-pointer"
      :class="{
        'opacity-50 pointer-events-none select-none': editing && !isAdmin,
      }"
      @click.stop="isNsfwModel = !isNsfwModel"
    >
      <div
        class="w-5 h-5 border border-white border-opacity-8 rounded-md flex items-center justify-center"
        :class="{
          'bg-indigo-850': isNsfwModel,
          'bg-white bg-opacity-8': !isNsfwModel,
        }"
      >
        <base-icon v-if="isNsfwModel" size="w-4 h-4" name="tick" />
      </div>
      {{ $t('matureContent') }}
    </div>
    <div
      class="flex items-center h-12 px-[14px] text-gray-400 text-sm font-medium gap-[14px] cursor-pointer opacity-50 pointer-events-none select-none"
      @click.stop="disableCommentsModel = !disableCommentsModel"
    >
      <div
        class="w-5 h-5 border border-white/[0.08] rounded-md flex items-center justify-center"
        :class="{
          'bg-indigo-850': disableCommentsModel,
          'bg-white bg-opacity-8': !disableCommentsModel,
        }"
      >
        <base-icon v-if="disableCommentsModel" size="w-4 h-4" name="tick" />
      </div>
      {{ $t('disableComments') }}
    </div>
  </div>
</template>
