<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import type { FrameActionPayload } from '@/features/frame';
  import { useClipboardToast } from '@/shared/model';
  import StackTraceItemProp from './StackTraceItemProp.vue';
  import { lowlight } from 'lowlight/lib/core';
  import { toHtml } from 'hast-util-to-html';

  const props = defineProps<{
    action: FrameActionPayload;
  }>();

  const { action } = toRefs(props);

  const { copyToClipboard } = useClipboardToast();

  const highlightedMetaTags = computed(() => {
    const content = action.value.frame?.metaTags.source.trim();
    if (!content) {
      return undefined;
    }

    return toHtml(lowlight.highlight('html', content));
  });

  const jsonResponse = computed(() => {
    if (!action.value.transactionResponse) {
      return undefined;
    }

    return JSON.stringify(action.value.transactionResponse, null, 2);
  });

  const highlightedJsonResponse = computed(() => {
    if (!jsonResponse.value) {
      return undefined;
    }

    return toHtml(lowlight.highlight('json', jsonResponse.value));
  });
</script>

<template>
  <template v-if="action.frame">
    <div
      v-if="action.frame?.metaTags.list"
      class="flex flex-col gap-4 lg:gap-2 text-sm lg:text-base border-t border-gray-785 border-opacity-50 pt-6"
    >
      <h4 class="flex gap-4 items-center mb-4">
        <span class="font-bold text-lg">{{ $t('frame.response') }}</span>

        <base-button
          variant="secondary"
          size="x-small"
          @click="copyToClipboard(action.frame.metaTags.source, $t('copied'))"
        >
          <base-icon name="copy-documents" size="size-4" />
          <span>HTML</span>
        </base-button>
      </h4>
      <stack-trace-item-prop
        v-for="(tag, index) in action.frame.metaTags.list"
        :key="index"
        :name="tag.name"
        :value="tag.content"
      />
    </div>
    <div
      v-if="action.frame?.metaTags.source"
      class="flex text-sm lg:text-base bg-gray-900 p-4"
    >
      <pre class="overflow-auto"><code v-html="highlightedMetaTags" /></pre>
    </div>
  </template>
  <template v-else-if="jsonResponse">
    <div
      class="flex flex-col gap-4 lg:gap-2 text-sm lg:text-base border-t border-gray-785 border-opacity-50 pt-6"
    >
      <h4 class="flex gap-4 items-center mb-4">
        <span class="font-bold text-lg">{{ $t('frame.response') }}</span>

        <base-button
          variant="secondary"
          size="x-small"
          @click="copyToClipboard(jsonResponse, $t('copied'))"
        >
          <base-icon name="copy-documents" size="size-4" />
          <span>JSON</span>
        </base-button>
      </h4>
    </div>
    <div class="flex text-sm lg:text-base bg-gray-900 p-4">
      <pre class="overflow-auto"><code v-html="highlightedJsonResponse" /></pre>
    </div>
  </template>
</template>
