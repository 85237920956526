<script setup lang="ts">
  import { ref, computed } from 'vue';
  import type { SocialNetwork } from '@/utils';
  import { useSocialPresence } from '@/composables';
  import { useToast } from '@/shared/model';
  import { SingleLinkFrame } from '.';

  const props = defineProps<{
    defaultSocialNetwork?: SocialNetwork;
  }>();

  const emit = defineEmits<{
    (e: 'submit', value: string, socialNetwork: SocialNetwork): void;
  }>();

  const link = ref<string>('');
  const invalid = ref<boolean>(false);
  const { matchedSocialNetwork, doesLinkMatchesSocialNetwork } =
    useSocialPresence(link);
  const { showToast } = useToast();

  const icon = computed(() =>
    props.defaultSocialNetwork
      ? props.defaultSocialNetwork.iconDesaturated
      : matchedSocialNetwork.value?.iconDesaturated,
  );

  const name = computed(() =>
    props.defaultSocialNetwork
      ? props.defaultSocialNetwork.type
      : matchedSocialNetwork.value?.type,
  );

  const submitLink = () => {
    if (!link.value?.trim()) return;
    if (props.defaultSocialNetwork) {
      invalid.value = !doesLinkMatchesSocialNetwork(
        link.value,
        props.defaultSocialNetwork,
      );
      if (!invalid.value) {
        emit('submit', link.value.trim(), props.defaultSocialNetwork);
      } else {
        showToast({
          type: 'error',
          title: `Invalid url for social network ${props.defaultSocialNetwork.type}. i.e "${props.defaultSocialNetwork.example}"`,
          durationSeconds: 5,
        });
      }
      return;
    }
    emit('submit', link.value.trim(), matchedSocialNetwork.value);
  };
</script>

<template>
  <single-link-frame
    :icon="icon"
    :name="name"
    button-text="Add"
    @submit="submitLink"
  >
    <input
      v-model="link"
      type="text"
      class="block w-full text-white bg-black bg-opacity-32 border border-gray-785 border-opacity-40 rounded-xl"
      :class="invalid ? 'border-red-600' : 'border-gray-600'"
      required
      placeholder="Link URL..."
    />
  </single-link-frame>
</template>
