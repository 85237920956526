<template>
  <default-button
    v-if="!isSelf"
    :text="text"
    :dimensions="size"
    :has-loader="true"
    :has-icon="false"
    :is-loading="isLoading"
    :style="{
      backgroundColor: text === 'follow' ? '#4F46E5' : '#374151',
    }"
    @click="handleButton"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  />
</template>

<script>
  import { mapGetters } from 'vuex';
  import { client } from '@/utils/dfinity';
  import { trackEvent } from '@/utils/tracker';
  import DefaultButton from './DefaultButton.vue';
  import { useAuth } from '@/entities/auth';

  export default {
    name: 'follow-button',
    components: { DefaultButton },
    props: {
      isFollowing: {
        type: Boolean,
        default: false,
      },
      userId: {
        type: Object,
        default: () => null,
      },
      size: {
        type: String,
        default: 'sm',
      },
    },
    emits: ['follow-user', 'login-modal-shown'],
    setup() {
      const { showLoginSignUpDialog } = useAuth();
      return { showLoginSignUpDialog };
    },
    data() {
      return {
        isLoading: false,
        text: this.isFollowing ? 'following' : 'follow',
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
      isSelf() {
        return (
          this.currentUser &&
          this.currentUser.id.toText() == this.userId.toText()
        );
      },
    },
    watch: {
      isFollowing() {
        this.text = this.isFollowing ? 'following' : 'follow';
      },
    },
    methods: {
      mouseOver() {
        if (this.isFollowing) this.text = 'unfollow';
      },
      mouseLeave() {
        if (this.isFollowing) this.text = 'following';
      },
      async handleButton() {
        if (!this.currentUser) {
          this.$emit('login-modal-shown');
          this.showLoginSignUpDialog();
        } else if (!this.isLoading) {
          trackEvent('user_follow', 'follow');
          this.isLoading = true;

          const { status, result } = await client.actor.follow_user_toggle(
            this.userId,
          );

          if (status == 'happy') this.$emit('follow-user', result[0]);

          this.isLoading = false;
        }
      },
    },
  };
</script>
