import type { ResponseObject } from '../types';
import { apiInstance } from '../base';
import type { AxiosPromise } from 'axios';
import type { IICRCToken } from './types';

const BASE_URL = '/sns';

export const getICRCTokens = (): AxiosPromise<ResponseObject<IICRCToken[]>> => {
  return apiInstance.get(`${BASE_URL}/list/icrc`);
};
