<script setup lang="ts">
  import { ref } from 'vue';
  import { useElementBounding } from '@vueuse/core';

  const line = ref<HTMLElement>();
  const { width, height } = useElementBounding(line);
</script>

<template>
  <div
    class="w-0 relative overflow-hidden transition-[width,margin] duration-500"
    :style="{
      height: `${height}px`,
      width: `${width}px`,
    }"
  >
    <span ref="line" class="absolute left-0 whitespace-nowrap">
      <slot></slot>
    </span>
  </div>
</template>
