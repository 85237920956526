<script setup lang="ts">
  import { useSlots } from 'vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import type { TokenChain } from '../types';

  defineProps<{
    chain: TokenChain;
  }>();

  defineEmits<{
    (e: 'action'): void;
  }>();

  const slots = useSlots();
</script>

<template>
  <article
    class="flex items-center gap-2 text-sm md:text-base bg-gray-950 p-4 rounded-2xl border border-gray-785 border-opacity-80"
  >
    <section class="relative size-12 min-w-12">
      <slot name="icon" />
      <div class="absolute -bottom-2 -right-2">
        <div
          class="flex justify-center items-center rounded-full bg-gray-900 size-6"
        >
          <base-icon
            :name="chain === 'sol' ? 'solana' : 'chain-icp'"
            size="size-4"
          />
        </div>
      </div>
    </section>

    <section class="flex-1 flex flex-col gap-1.5 items-center pl-2">
      <div class="flex justify-between items-center w-full">
        <div>
          <slot name="name" />
        </div>
        <div>
          <slot name="balance" />
        </div>
      </div>
      <div
        v-if="slots.description || slots.swapBalance"
        class="flex justify-between items-center w-full text-sm text-gray-400"
      >
        <div>
          <slot name="description" />
        </div>
        <div><slot name="swapBalance" /></div>
      </div>
    </section>

    <base-dropdown
      v-if="slots.action"
      placement="left-start"
      custom-content-classes="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden drop-shadow-light"
      class="self-start"
      :distance="-5"
    >
      <template #button="{ toggle }">
        <base-button
          variant="custom"
          size="small"
          @click="toggle"
          custom-classes="px-2 py-1"
        >
          <base-icon name="vertical-dots" size="size-5" class="text-white" />
        </base-button>
      </template>
      <template #content="{ hide }">
        <ul class="flex flex-col">
          <li
            class="flex px-5 py-4 cursor-pointer bg-gradient-to-r from-indigo-850 via-transparent to-transparent transition-all"
            @click="hide(), $emit('action')"
          >
            <span class="whitespace-nowrap"><slot name="action" /></span>
          </li>
        </ul>
      </template>
    </base-dropdown>
  </article>
</template>
