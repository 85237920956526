import { computed, type Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import { useQuery } from '@tanstack/vue-query';
import { getUserByIdKey } from './keys';
import type { UserSelfView } from 'dfx/edge/edge.did';

export const useGetOnboardingPortalRecommendationsQuery = (
  user: Ref<UserSelfView>,
) => {
  const userId = computed(() => user.value.id.toText());
  return useQuery({
    queryKey: [...getUserByIdKey(userId), 'portal-recommendations'],
    queryFn: () => dscvrIcApi.onboarding.getOnboardingPortalRecommendations(),
  });
};
