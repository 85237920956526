<script lang="ts" setup>
  import type { BannerProps } from './types';
  import { Toast } from '@/shared/ui/toast';
  import { computed } from 'vue';

  const props = withDefaults(defineProps<BannerProps>(), {
    hideBanner: true,
  });

  defineEmits<{
    (e: 'close'): void;
  }>();

  const toastHeight = computed(() => {
    if (props.size === 'small') {
      return 'h-28';
    } else if (props.size === 'medium') {
      return 'h-[170px]';
    }
    return 'h-[88px]';
  });
</script>

<template>
  <toast v-if="!hideBanner" v-bind="props" :class="toastHeight" class="mx-auto">
    <template #icon>
      <slot name="photo" />
    </template>
    <template #content>
      <div
        class="flex gap-2 h-full w-full"
        :class="[
          size !== 'large'
            ? 'flex-col justify-end'
            : 'justify-between items-center',
        ]"
      >
        <div v-if="$slots.title" class="font-semibold text-white break-words">
          <slot name="title" />
        </div>
        <div
          v-if="size === 'large' && $slots.description"
          class="flex gap-4 flex-1 pl-20"
        >
          <slot name="description" />
        </div>
        <div v-if="$slots.button">
          <slot name="button" />
        </div>
      </div>
      <div
        v-if="showCloseButton"
        class="absolute top-2 right-2 bg-black bg-opacity-[0.24] z-1 drop-shadow-md p-1 rounded-full"
        @click="$emit('close')"
      >
        <base-icon
          name="close"
          size="w-4 h-4"
          class="cursor-pointer text-white"
        />
      </div>
    </template>
  </toast>
</template>
